import React from 'react';
import {Row, Col, InputGroup, FloatingLabel, Form} from 'react-bootstrap';

import InputFloating from '../Shared/Inputs/InputFloating';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';


export default function AdditionalInfoFields({
    register,
    errors,
    s_registerNameForRent,
    s_registerNameForPaymentDetails,
    s_registerNameForPaymentDueDate,
    s_registerNameForNotes
}) {
    return (
        <Row className="m-0 p-0 row-cols-1 row-cols-lg-3">
            <Col className="m-0 p-0 w-100" xs={12}>
                <h3 className="m-0 p-0 mb-2 fw-bold">Additional information</h3>
            </Col>

            <Col className="m-0 p-0">
                <Row className="m-0 p-0 mb-3 rounded shadow-sm ">
                    <Col className="m-0 p-0" xs={'auto'}>
                        <InputGroup.Text className="h-100 rounded-0 border-0">$</InputGroup.Text>
                    </Col>
                    <Col className="m-0 p-0">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Price Per Month"
                        >
                            <Form.Control {...register(s_registerNameForRent)} type="text" placeholder="Rent" className="border-0 rounded"/>
                        </FloatingLabel>
                    </Col>
                </Row>

                <p className="custom_error">
                    {errors[s_registerNameForRent]?.message}
                </p>
            </Col>
            <Col className="m-0 p-0 ps-lg-3 mb-3">
                <InputFloating 
                    label="Accepted Means of Payment"
                    register={register(s_registerNameForPaymentDetails)}
                    showError={true}
                    errorMessage={errors[s_registerNameForPaymentDetails]?.message}
                />
            </Col>
            <Col className="m-0 p-0 ps-lg-3 mb-3">
                <InputFloating 
                    label="Payment schedule details"
                    register={register(s_registerNameForPaymentDueDate)}
                    showError={true}
                    errorMessage={errors[s_registerNameForPaymentDueDate]?.message}
                />
            </Col>
            <Col className="m-0 p-0 mt-1 mb-3 w-100" xs={12}>
                <TextAreaFloating
                    placeholder={"Additional Notes..."}
                    label={"Additional Notes"}
                    register={register(s_registerNameForNotes)}
                />
            </Col>
        </Row>
    )
}
