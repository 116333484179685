import React, { useState, useRef } from 'react'
import { Container, Modal } from 'react-bootstrap'
import useOnClickOutside from '../../hooks/useOnClickOutside';
import LazyImage from './LazyImage'

export default function ThumbnailVideo(props) {


    const { link, cta = true, ctaSize = "70px", ctaTransition = 5000, customImage = false, ...rest } = props;
    const videoId = link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();

    const [show, setShow] = useState(false);

    const playerRef = useRef();

    useOnClickOutside(playerRef, ()=>setShow(false))

    return (
        <Container fluid {...rest} >

            <div className='position-relative d-flex justify-items-center cursor-pointer' onClick={() => setShow(true)}>
                <LazyImage
                    src={ customImage ? customImage : `http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                    style={{ margin: "0 auto", width: "100%" }}
                    className="grayToHover"
                />
                {cta && (
                    <div
                        className='thumbnail-video-cta position-absolute top-50 start-50'
                    >
                        <svg
                            viewBox="100 100 300 350"
                            xmlns="http://www.w3.org/2000/svg"
                            width={ctaSize}
                            height={ctaSize}
                            className="position-absolute top-50 start-50 translate-middle ctaStroke"
                            fill="currentColor"
                        >
                            <path
                                d="M 127.279 300.949 C 127.279 300.949 126.597 302.152 126.198 302.877 C 125.819 303.561 125.43 304.274 125.07 304.948 C 124.341 306.311 123.601 307.752 122.948 309.065 C 121.636 311.722 120.35 314.555 119.319 317.072 C 117.243 322.208 115.54 327.754 114.618 332.367 C 113.052 342.23 114.472 353.422 118.178 360.996 L 118.221 361.081 L 118.253 361.145 L 118.278 361.192 L 118.314 361.261 L 118.342 361.314 L 118.417 361.437 L 118.457 361.497 L 118.494 361.552 L 118.529 361.604 L 118.575 361.669 C 127.835 374.786 148.595 383.526 174.973 383.993 L 328.343 383.993 C 354.672 383.545 375.337 375.157 384.645 362.021 L 384.701 361.938 L 384.753 361.857 L 384.757 361.851 L 384.795 361.796 L 384.847 361.72 L 384.893 361.651 L 384.939 361.582 L 384.982 361.518 L 385.014 361.449 L 385.047 361.378 L 385.074 361.318 L 385.099 361.268 L 385.135 361.19 L 385.154 361.148 L 385.173 361.11 L 385.238 360.972 C 391.791 346.374 388.848 324.128 376.091 300.848 L 299.41 166.327 C 286.03 143.61 268.15 127.973 251.712 127.973 C 235.273 127.973 217.439 143.528 204.061 166.244 L 127.381 300.763"
                                id="path"
                                fill="none"
                            />
                        </svg>
                        <svg
                            viewBox="100 100 300 350"
                            xmlns="http://www.w3.org/2000/svg"
                            width={ctaSize}
                            height={ctaSize}
                            className="position-absolute top-50 start-50 translate-middle ctaStroke"
                        >
                            <path
                                d="M 127.279 300.949 C 127.279 300.949 126.597 302.152 126.198 302.877 C 125.819 303.561 125.43 304.274 125.07 304.948 C 124.341 306.311 123.601 307.752 122.948 309.065 C 121.636 311.722 120.35 314.555 119.319 317.072 C 117.243 322.208 115.54 327.754 114.618 332.367 C 113.052 342.23 114.472 353.422 118.178 360.996 L 118.221 361.081 L 118.253 361.145 L 118.278 361.192 L 118.314 361.261 L 118.342 361.314 L 118.417 361.437 L 118.457 361.497 L 118.494 361.552 L 118.529 361.604 L 118.575 361.669 C 127.835 374.786 148.595 383.526 174.973 383.993 L 328.343 383.993 C 354.672 383.545 375.337 375.157 384.645 362.021 L 384.701 361.938 L 384.753 361.857 L 384.757 361.851 L 384.795 361.796 L 384.847 361.72 L 384.893 361.651 L 384.939 361.582 L 384.982 361.518 L 385.014 361.449 L 385.047 361.378 L 385.074 361.318 L 385.099 361.268 L 385.135 361.19 L 385.154 361.148 L 385.173 361.11 L 385.238 360.972 C 391.791 346.374 388.848 324.128 376.091 300.848 L 299.41 166.327 C 286.03 143.61 268.15 127.973 251.712 127.973 C 235.273 127.973 217.439 143.528 204.061 166.244 L 127.381 300.763"
                                id="path"
                                fill="none"
                            />
                        </svg>
                        <svg
                            viewBox="100 100 300 350"
                            xmlns="http://www.w3.org/2000/svg"
                            width={ctaSize}
                            height={ctaSize}
                            className="position-absolute top-50 start-50 translate-middle ctaFill"
                        >
                            <path
                                d="M 127.279 300.949 C 127.279 300.949 126.597 302.152 126.198 302.877 C 125.819 303.561 125.43 304.274 125.07 304.948 C 124.341 306.311 123.601 307.752 122.948 309.065 C 121.636 311.722 120.35 314.555 119.319 317.072 C 117.243 322.208 115.54 327.754 114.618 332.367 C 113.052 342.23 114.472 353.422 118.178 360.996 L 118.221 361.081 L 118.253 361.145 L 118.278 361.192 L 118.314 361.261 L 118.342 361.314 L 118.417 361.437 L 118.457 361.497 L 118.494 361.552 L 118.529 361.604 L 118.575 361.669 C 127.835 374.786 148.595 383.526 174.973 383.993 L 328.343 383.993 C 354.672 383.545 375.337 375.157 384.645 362.021 L 384.701 361.938 L 384.753 361.857 L 384.757 361.851 L 384.795 361.796 L 384.847 361.72 L 384.893 361.651 L 384.939 361.582 L 384.982 361.518 L 385.014 361.449 L 385.047 361.378 L 385.074 361.318 L 385.099 361.268 L 385.135 361.19 L 385.154 361.148 L 385.173 361.11 L 385.238 360.972 C 391.791 346.374 388.848 324.128 376.091 300.848 L 299.41 166.327 C 286.03 143.61 268.15 127.973 251.712 127.973 C 235.273 127.973 217.439 143.528 204.061 166.244 L 127.381 300.763"
                                id="path"
                                fill="none"
                            />
                        </svg>
                    </div>
                )}
            </div>



            <Modal
                show={show}
                centered
                onHide={() => setShow(false)}
                className="d-flex align-content-center justify-content-center"
                fullscreen
                contentClassName="thumbnail-video"
            >
                {/* <Modal.Header closeButton className="thumbnail-video-header"></Modal.Header> */}
                <Modal.Body className="p-0 m-0 d-flex flex-column justify-content-center m-auto">

                    <iframe
                        width="1000"
                        height="600"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
                        allowfullscreen="true"
                        style={{ transform: "scale(1)" }}
                        ref={playerRef}
                    >

                    </iframe>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
