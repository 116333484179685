import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';


export default function TextAreaFloating({
    label,
    errorClasses="custom_error pt-1",
    formClasses="rounded border border-wissp-light-gray shadow-sm h-100 resize-none",
    labelClasses="",
    controlId="floatingInput",
    errorMessage,
    showError=false,
    register,
    style={ height: 100 },
    placeholder="0",
    disabled=false,
}) {
    return (
        <FloatingLabel
            controlId={controlId}
            label={label}
            className={labelClasses}
            style={style}
        >
            <Form.Control className={formClasses}
                as="textarea"
                placeholder={placeholder}
                {...register}
                disabled={disabled}
            />

            { showError && (
                    <p className={errorClasses}>
                        { errorMessage }
                    </p>
                )
            }
        </FloatingLabel>
    )
}