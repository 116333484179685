import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Tab, Form } from 'react-bootstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { 
    object as YupObject, 
    string as YupString
} from "yup";

import InputFloating from '../Shared/Inputs/InputFloating';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';
import SelectFloating from '../Shared/Inputs/SelectFloating';
import PhoneWithMask from '../Shared/Inputs/PhoneWithMask';
import Chip from '../CommandCenter/Chip';

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../../constants/client';

import { STATES } from '../../constants/Shared/states';

const TABS = Object.freeze({
    CLIENT: 'client',
    ADD_GROUP: 'addGroup'
});
const BTN = Object.freeze({
    BACK: 'Back',
    CANCEL: 'Cancel'
});
export const CLIENT = Object.freeze({
    CLIENT_ID: 'ClientID',
    // CLIENT_NAME: 'clientName',
    CLIENT_FIRST_NAME: "FirstName",
    CLIENT_LAST_NAME: "LastName",
    CLIENT_EMAIL: 'Email',
    CLIENT_PHONE: 'Mobile',
    CLIENT_GENDER: 'clientGender',
    CLIENT_AGE: 'Age',
    CLIENT_CITY: 'City',
    CLIENT_STATE: 'State',
    CLIENT_TYPE: 'Type',
    CLIENT_NOTES: 'Notes',
    CLIENT_GROUP: 'Groups',
    CLIENT_VERIFIED: 'Verified',
    GROUP_NAME: 'GroupName',
    GROUP_DESC: 'GroupDesc',
    GROUP_CLIENTS: 'Clients'
})
const clientSchema = YupObject().shape({
    [CLIENT.CLIENT_FIRST_NAME]: YupString().required('Field is required'),
    [CLIENT.CLIENT_LAST_NAME]: YupString().required('Field is required'),
    [CLIENT.CLIENT_EMAIL]: YupString().email('Email is invalid').required('Field is required'),
    [CLIENT.CLIENT_PHONE]: YupString()
        .required("Phone number field is required")
        .transform(value => value.trim())
        .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
            "Provide a valid phone number"),
    [CLIENT.CLIENT_GENDER]: YupString().required('Field is required'),
    [CLIENT.CLIENT_AGE]: YupString().required('Field is required'),
    [CLIENT.CLIENT_CITY]: YupString().required('Field is required'),
    [CLIENT.CLIENT_STATE]: YupString().required('Field is required'),
    [CLIENT.CLIENT_TYPE]: YupString().required('Field is required'),
    [CLIENT.CLIENT_NOTES]: YupString().notRequired(),
    // [CLIENT.CLIENT_GROUP]: YupString().notRequired(),

});
const groupSchema = YupObject().shape({
    [GROUP_NAME]: YupString().required('Field is required'),
    [GROUP_DESC]: YupString().notRequired('Field is required'),
});

const defaultClientValues = {
    [CLIENT.CLIENT_FIRST_NAME]: '',
    [CLIENT.CLIENT_LAST_NAME]: '',
    [CLIENT.CLIENT_EMAIL]: '',
    [CLIENT.CLIENT_PHONE]: '',
    [CLIENT.CLIENT_GENDER]: '',
    [CLIENT.CLIENT_AGE]: '',
    [CLIENT.CLIENT_CITY]: '',
    [CLIENT.CLIENT_STATE]: '',
    [CLIENT.CLIENT_TYPE]: '',
    [CLIENT.CLIENT_NOTES]: '',
    [CLIENT.CLIENT_GROUP]: ''
};
const defaultGroupValues = {
    [GROUP_NAME]: '',
    [GROUP_DESC]: ''
}


export default function AddClientModal({
    show=true,
    onHide,
    onAddGroup,
    onAddClient,
    groups,

    clients,
    editClientWithId,
    onEditClient
}) {

    const [currentTab, setCurrentTab] = useState(TABS.CLIENT);
    const [cancelBtnTitle, setCancelBtnTitle] = useState(BTN.CANCEL);
    const [clientTitle, setClientTitle] = useState('Add New Client');
    const [clientGroups, setClientGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    const {
        register: registerClientField,
        handleSubmit: handleSubmitClientForm,
        formState: formStateClient,
        reset: resetClientForm,
        // setValue,
        watch
    } = useForm({ resolver: yupResolver(clientSchema), mode: "all", defaultValues: defaultClientValues });
    const { errors: errorsClient } = formStateClient;
 
    const {
        register: registerGroupField,
        handleSubmit: handleSubmitGroupForm,
        formState: formStateGroup,
        reset: resetGroupForm,
    } = useForm({ resolver: yupResolver(groupSchema), mode: "all", defaultValues: defaultGroupValues});
    const { errors: errorsGroup } = formStateGroup;


    // const selectedGroupId = watch(CLIENT.CLIENT_GROUP , '');

    useEffect(() => {
        if(editClientWithId) {
            const defaultData = clients.find( client => client[CLIENT.CLIENT_ID] === editClientWithId);
            setClientGroups(defaultData[CLIENT.CLIENT_GROUP] || []);
            resetClientForm(defaultData);
            resetGroupForm(defaultGroupValues);
            setClientTitle('Edit Client');
        } else {
            setSelectedGroupId(null);
            setClientGroups([]);
            setClientTitle('Add New Client');
        }
    }, [show, editClientWithId, clients, resetClientForm, resetGroupForm]);

    const switchTab = (tabName, cancelBtnTitle) => {
        if(!tabName || !cancelBtnTitle) throw new Error('Missing argument!');
        setCurrentTab(tabName);
        setCancelBtnTitle(cancelBtnTitle);
    }
    const onHideHandler = () => {
        resetClientForm(defaultClientValues);
        resetGroupForm(defaultGroupValues);
        onHide();
    }
    const cancelBtnHandler = () => {
        if(cancelBtnTitle === BTN.BACK) {
            switchTab(TABS.CLIENT, BTN.CANCEL);
        } else if(cancelBtnTitle === BTN.CANCEL) {
            onHideHandler();
        }
    }

    const onFormValid = async (data) => {
        if(currentTab === TABS.CLIENT && !editClientWithId) {
            const result = await onAddClient({...data, [CLIENT.CLIENT_GROUP]: clientGroups});
            if( result.success ) {
                resetClientForm(defaultClientValues);
            }
        } else if(currentTab === TABS.ADD_GROUP) {
            const result = await onAddGroup({...data, [CLIENTS]: []});
            if( result.success ) {
                resetGroupForm(defaultGroupValues);

                // setValue(CLIENT.CLIENT_GROUP, result.data.id);
                setClientGroups( prevState => ([...prevState, result.data[GROUP_ID]]));
                setSelectedGroupId( result.data[GROUP_ID] );

                switchTab(TABS.CLIENT, BTN.CANCEL);
            }
        } else if(currentTab === TABS.CLIENT && editClientWithId) {
            const result = await onEditClient({...data, [CLIENT.CLIENT_GROUP]: clientGroups});
            if( result.success ) {
                resetClientForm(defaultClientValues);
            }
        }
    }

    const getGroupName = (id, groups) => {
        if(!id || !groups) return "";
        return groups.find( group => String(group[GROUP_ID]) === String(id))?.[GROUP_NAME] || '';
    }

    const getGroupDescritpion = (id, groups) => {
        if(!id || !groups) return "";
        return groups.find( group => String(group[GROUP_ID]) === String(id))?.[GROUP_DESC] || '';
    }

    const deleteGroupFromClientGroups = (groupId) => {
        setClientGroups( prevState => (
            prevState.filter( id => String(id) !== String(groupId) )
        ));
    }

    const addSelectedGroupHandler = ( groupId ) => {
        setClientGroups( prevState => {
            if( prevState.some( id => String(id) === String(groupId)) ) {
                return prevState;
            }
            return [...prevState, groupId]
        });
    }

    return(
        <Modal
            show={show}
            onHide={onHideHandler}
            centered
            fullscreen
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="m-0 p-0" >
                <Form className="m-0 p-0">
                    <Container className="pt-3 pt-sm-4 pt-md-5 pb-3 px-2 px-sm-3 px-lg-5">
                        <Tab.Container
                            id="addClientModalTab"
                            activeKey={currentTab}
                            navbar={false}
                        >
                                <Tab.Content>
                                    <Tab.Pane eventKey={TABS.CLIENT}>
                                        <Row className="m-0 mb-3">
                                            <Col className="p-0">
                                                <div className="darkerGray py-2 rounded">
                                                    <h5 className="m-0 p-0 fw-bold text-center text-uppercase">{clientTitle}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="m-0 mb-3">
                                            <Col className="p-0 mb-3 pe-sm-2" xs={12} sm={6} md={4}>
                                                <InputFloating 
                                                    label="First Name"
                                                    register={registerClientField(CLIENT.CLIENT_FIRST_NAME)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_FIRST_NAME]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                            <Col className="p-0 mb-3 pe-md-2" xs={12} sm={6} md={4}>
                                                <InputFloating 
                                                    label="Last Name"
                                                    register={registerClientField(CLIENT.CLIENT_LAST_NAME)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_LAST_NAME]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                            <Col className="p-0 mb-3 pe-sm-2 pe-md-0" xs={12} sm={6} md={4}>
                                                <InputFloating 
                                                    label="Email"
                                                    type="email"
                                                    register={registerClientField(CLIENT.CLIENT_EMAIL)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_EMAIL]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                            
                                            <Col className="p-0 mb-3 pe-md-2" xs={12} sm={6} md={4}>
                                                <PhoneWithMask 
                                                    label="Phone"
                                                    register={registerClientField(CLIENT.CLIENT_PHONE)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_PHONE]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                            <Col xs={8} sm={6} md={'auto'} lg={2} className="p-0 mb-3 pe-2 pe-sm-2">
                                                <SelectFloating 
                                                    dataArray={['Male', 'Female', 'Other']}
                                                    label="Gender"
                                                    register={registerClientField(CLIENT.CLIENT_GENDER)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_GENDER]?.message}
                                                    showError={true}
                                                    optionEmpty={true}
                                                />
                                            </Col>
                                            <Col xs={4} sm={6} md={2} lg={2} className="p-0 mb-3 mb-sm-0 pe-md-2">
                                                <InputFloating 
                                                    label="Age"
                                                    type="number"
                                                    register={registerClientField(CLIENT.CLIENT_AGE)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_AGE]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={'auto'} lg={2} className="p-0 pe-md-2 mb-3">
                                                <SelectFloating 
                                                    dataArray={['Tenant', 'Landlord', 'Other']}
                                                    label="Client Type"
                                                    register={registerClientField(CLIENT.CLIENT_TYPE)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_TYPE]?.message}
                                                    showError={true}
                                                    optionEmpty={true}
                                                />
                                            </Col>
                                            <Col xs={12} class="p-0"></Col>
                                            <Col xs={4} sm={6} md={"auto"} lg={2} className="p-0 pe-2 pe-sm-2">
                                                <SelectFloating 
                                                    dataArray={STATES.split(',')}
                                                    label="State"
                                                    register={registerClientField(CLIENT.CLIENT_STATE)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_STATE]?.message}
                                                    showError={true}
                                                    optionEmpty={true}
                                                />
                                            </Col>
                                            <Col xs={8} sm={6} md={"auto"} lg={4} className="p-0 mb-3 mb-md-0 pe-md-2">
                                                <InputFloating 
                                                    label="City"
                                                    register={registerClientField(CLIENT.CLIENT_CITY)}
                                                    errorMessage={errorsClient[CLIENT.CLIENT_CITY]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="m-0 mb-4">
                                            <Col className="p-0">
                                                <TextAreaFloating 
                                                    label="Notes"
                                                    register={registerClientField(CLIENT.CLIENT_NOTES)}
                                                />
                                            </Col>
                                        </Row>

                                        {/* <Row className="m-0 mb-3">
                                            <Col className="p-0">
                                                <div className="darkerGray py-2 rounded">
                                                    <h5 className="m-0 p-0 fw-bold text-center text-uppercase">Associated Group</h5>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="m-0 mb-3">
                                            <Col xs={12} className="d-flex align-items-center p-0 p-2 mb-3 rounded border border-wissp-light-gray shadow-sm" style={{minHeight: 60}}>
                                                { clientGroups && clientGroups.map( groupId => (
                                                    <Chip icon={"bi-collection-fill"} isSelected={true} text={getGroupName(groupId, groups)} exit={true} canSelect={false} onDelete={() => deleteGroupFromClientGroups(groupId)}/>
                                                )) }
                                            </Col>


                                            <Col xs={12} sm={5} md={4} className="p-0 pe-2">
                                                <SelectFloating 
                                                    label="Select Existing Group"
                                                    // register={registerClientField(CLIENT.CLIENT_GROUP)}
                                                    // errorMessage={errorsClient[CLIENT.CLIENT_GROUP]?.message}

                                                    register={{
                                                        value: selectedGroupId,
                                                        onChange: (e) => setSelectedGroupId(e.target.value)
                                                    }}

                                                    showError={false}
                                                    optionEmpty={true}

                                                    

                                                >
                                                    { groups && groups.map((group) => (
                                                        <option key={group[GROUP_ID]} value={group[GROUP_ID]}>{group[GROUP_NAME]}</option>
                                                    ))}
                                                </SelectFloating>
                                            </Col>
                                            <Col xs={12} sm={'auto'} className="d-flex align-items-center p-0">
                                                <ButtonPrimary
                                                    onClick={() => addSelectedGroupHandler(selectedGroupId)}
                                                    additionalClassName=""
                                                    type="button"
                                                >
                                                    <i className="bi bi-plus-lg fs-6 me-1"></i>Add
                                                </ButtonPrimary>
                                            </Col>
                                            <Col xs={'auto'} className="d-flex align-items-center p-0">
                                                &nbsp;-&nbsp;or&nbsp;-&nbsp;
                                            </Col>
                                            <Col xs={12} sm={'auto'} className="d-flex align-items-center p-0">
                                                <ButtonPrimary
                                                    onClick={() => switchTab(TABS.ADD_GROUP, BTN.BACK)}
                                                    additionalClassName=""
                                                    type="button"
                                                >
                                                    <i className="bi bi-plus-lg fs-6 me-1"></i>Add To New Group
                                                </ButtonPrimary>
                                            </Col>
                                        </Row>
                                        <Row className="m-0 mb-3">
                                            <Col className="p-0">
                                                <TextAreaFloating 
                                                    disabled={true}
                                                    label="Group Description"
                                                    register={{
                                                        value: getGroupDescritpion(selectedGroupId, groups)
                                                    }}
                                                    placeholder={null}
                                                />
                                            </Col>
                                        </Row> */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={TABS.ADD_GROUP}>
                                        <Row className="m-0 mb-3">
                                            <Col className="p-0">
                                                <div className="darkerGray py-2 rounded">
                                                    <h5 className="m-0 p-0 fw-bold text-center text-uppercase">Add New Group</h5>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="m-0 mb-3">
                                            <Col className="p-0">
                                                <InputFloating 
                                                    label="Name"
                                                    register={registerGroupField(GROUP_NAME)}
                                                    errorMessage={errorsGroup[GROUP_NAME]?.message}
                                                    showError={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="m-0 mb-4">
                                            <Col className="p-0">
                                                <TextAreaFloating 
                                                    label="Description"
                                                    register={registerGroupField(GROUP_DESC)}
                                                />
                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                </Tab.Content>
                            
                        </Tab.Container>
                    </Container>
                </Form>
            </Modal.Body>
            <Modal.Footer className='m-0 p-0'>
                <Row className="m-0 p-2 w-100 justify-content-end">
                    <Col xs={12} sm="auto" className="p-0 mb-1 me-sm-1 mb-sm-0">
                        <ButtonSecondary
                            title={cancelBtnTitle}
                            type="button"
                            onClick={cancelBtnHandler}
                        />
                    </Col>
                    <Col xs={12} sm="auto" className="p-0">
                        <ButtonPrimary 
                            title="Submit"
                            onClick={(e) => currentTab === TABS.CLIENT ? handleSubmitClientForm(onFormValid)(e) : handleSubmitGroupForm(onFormValid)(e)}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
