import { getRequest, postPutRequest, deleteRequest } from './http-common';


const getDocument = async (token) => {
    const URL = `tenant/document`;

    return await getRequest(URL, token);
}

const downloadDocument = async (tenantDocumentID, token) => {
    const URL = `tenant/document/${tenantDocumentID}/download`;

    return await getRequest(URL, token, { responseType: 'blob' });
}

const uploadDocument = async (data, token) => {
    const URL = `tenant/document/upload`;

    return await postPutRequest(URL, data, token, 'post');
}

const editDocument = async (data, DocumentID, token) => {
    const URL = `tenant/document/${DocumentID}`;

    return await postPutRequest(URL, data, token, 'patch');
}

const deleteDocument = async (DocumentIDs, token) => {
    const URL = 'tenant/document';

    return await deleteRequest(URL, DocumentIDs, token);
}

export { 
    getDocument,
    downloadDocument,
    uploadDocument,
    editDocument,
    deleteDocument
};
