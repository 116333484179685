import React, { useState } from 'react'

export default function LazyImage({className = "", alt="image", ...props }) {
    const [loading, setLoading] = useState(true);
    return (
        <>
            {
                loading && <div className="m-0 p-0 position-relative w-100 h-100 rounded darkerGray skeleton" style={{ height: props.height || 'auto' }}></div>
            }
            <img
                {...props}
                alt={alt}
                className={`rounded ${className} ${loading ? "d-none" : ""}`}
                onLoad={() => setLoading(false)}
            />
        </>
    )
}
