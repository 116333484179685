import { postPutRequest } from './http-common';

const inviteManager = async ({
    Email,
    FirstName,
    LastName,
    SendInvite=true,
    UserType='Manager'
}, token) => {
    const URL = `account/invite`;
    const body = {
        Email,
        FirstName,
        LastName,
        SendInvite,
        UserType 
    }

    const result = await postPutRequest( URL, body, token, 'post');

    return {...result, Email}; 
}

const getManagerStatuses = async ({
    emails=[],
}, token) => {
    const URL = `account/statuses`;
    const result = await postPutRequest( URL, emails, token, 'post');

    return result; 
}


export { 
    inviteManager,
    getManagerStatuses
};
