import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Row,
    Col,
    Container,
    OverlayTrigger,
    Tooltip,
    Tab
} from 'react-bootstrap';

import { BuildingsContext } from '../../contexts/BuildingsContext';
import { UserTypeContext } from '../../contexts/UserTypeContext';
import { AuthContext } from '../../contexts/AuthContext';
import useWindowSize, { GRID_BREAKPOINTS } from '../../hooks/useWindowSize';
import { favoriteActions } from '../../store/FavoriteSlice';
import ModalTourRequest from './ModalTourRequest';
import ModalStreetViewMobile from './ModalStreetViewMobile';
import BuildingInfo from '../Shared/PropertyDetails/BuildingInfo';
import UnitInfo from '../Shared/PropertyDetails/UnitInfo';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import LogoLoader from '../Shared/LogoLoader';
import {
    addFavoriteBuilding,
    deleteFavoriteBuilding,
    addFavoriteUnit,
    deleteFavoriteUnit
} from '../../services/favorites';

import ErrorPopup from '../Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../Shared/InfoPopups/SuccessPopup';

const TAB = Object.freeze({
    OCCUPANT: 'occupant',
    UNIT_INFO: 'unitInfo'
});


export default function ModalDetails({ BuildingID, onHide, alreadyRequestesUnitIDs, setAlreadyRequestesUnitIDs, UnitID, favorites, buildings }) {
    const { token } = useContext(AuthContext);
    const {
        userType
    } = useContext(UserTypeContext);

    const {
        getBuildingByID
    } = useContext(BuildingsContext);

    const dispatch = useDispatch();

    const features = useSelector(store => store.featuresStore);
    const [activeTab, setActiveTab] = useState(TAB.OCCUPANT);
    // TODO: if features is null then download features!

    // const getFeaturesHandler = useCallback( async () => {
    //     const response = await getFeatures();

    //     const { success, data } = response;
    //     if (success) { 
    //         dispatch( featuresActions.setFeatures(data) );
    //     }
    // }, [dispatch]);

    // useEffect(() => {
    //     if(features && features.isEmpty) {
    //         getFeaturesHandler();
    //     }

    // }, [features]);


    // const unitsData = useSelector(store => store.marketplaceStore.units); // may cause loading issues!
    
    const [isLoading, setIsLoading] = useState(false);
    const [isAddingToFavorites, setIsAddingToFavorites] = useState(null);
    const [showStreetView, setShowStreetView] = useState(false);
    const [streetViewClicked, setStreetViewClicked] = useState(false);
    const [selectedUnitID, setSelectedUnitID] = useState(null);
    const [selectedUnitData, setSelectedUnitData] = useState(null);
    const [showRequestTour, setShowRequestTour] = useState(false);
    const [streetViewAvailable, setStreetViewAvailable] = useState(true)
    const [selectedBuildingData, setSelectedBuildingData] = useState( null);

    const unitsData = selectedBuildingData?.Units || null;

    const size = useWindowSize();

    const onClickTabBox = ( name ) => {
        setActiveTab(name);
    }
    const openRequestATour = () => {
        setShowRequestTour(true);
    }

    const getUnitByUnitID = useCallback((UnitID, unitsData) => {
        if (!unitsData) return null;

        if( typeof UnitID !== 'number') throw new Error(`First argument have to be of type number, got ${ typeof UnitID}`);
        return unitsData.find((unit) => unit.UnitID === UnitID);
    }, []);

    const loadBuildingData = useCallback(async (id) => {
        if(!id) throw new Error("First argument not specified!");

        if(buildings) return;
        setIsLoading(true);
        const { success, data } = await getBuildingByID(id);
        if (success) {
            setSelectedBuildingData(data);
        }
        setIsLoading(false);


        //TODO: add modal with information about error
    }, [getBuildingByID]);

    useEffect(() => {
        if(buildings){
            setSelectedBuildingData(buildings?.filter(building=>building.Units.find(u=>UnitID == u.UnitID))[0]);
        }else if (BuildingID && Array.isArray(BuildingID) && !isLoading) {
            if (BuildingID.length === 1) {
                const singleID = BuildingID[0];
                loadBuildingData(singleID);
            } 
        }

        return () => {
            if(!BuildingID || BuildingID === "null") {
                setSelectedBuildingData(null);
                setSelectedUnitID(null);
                setSelectedUnitData(null);
            }
        }
    }, [BuildingID, loadBuildingData]);
    const isUnitSelected = (unit) => {
        const result = selectedUnitID === unit.UnitID;
        return result;
    }
    
    useEffect(() => {
        if (selectedBuildingData) {
            if (UnitID) {
                setSelectedUnitID(Number(UnitID));


                setSelectedUnitData(getUnitByUnitID(Number(UnitID), unitsData));
            } else {
                const firstUnitID = selectedBuildingData.Units.find(({Vacant}) => Vacant).UnitID || selectedBuildingData.Units[0].UnitID;
                setSelectedUnitID(firstUnitID);
                setSelectedUnitData(getUnitByUnitID(firstUnitID, unitsData));
            }
        }
    }, [selectedBuildingData, UnitID, unitsData])

    const showSelectedUnit = (UnitID) => {
        if(typeof UnitID !== 'number') throw new Error("Argument has to of type number");

        setSelectedUnitID(UnitID);
        setSelectedUnitData(getUnitByUnitID(UnitID, unitsData))
    }

    const isButtonDisabled = () => {
        if (!userType || userType === "Landlord") return true;

        return !!(selectedUnitData &&
            alreadyRequestesUnitIDs &&
            alreadyRequestesUnitIDs.find(id => id === selectedUnitData.UnitID));
    }

    const isButtonDisabledCompute = useMemo(() => {
        if (!userType || userType === "Landlord") return true;

        return !!(selectedUnitData &&
            alreadyRequestesUnitIDs &&
            alreadyRequestesUnitIDs.find(id => id === selectedUnitData.UnitID));
    }, [userType, selectedUnitData, alreadyRequestesUnitIDs])

    const onHideHandler = () => {
        setSelectedUnitID(null);
        setSelectedUnitData(null);
        setShowRequestTour(false);
        setSelectedBuildingData(null);
        setShowStreetView(false);
        setStreetViewClicked(false);

        onHide();
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>Sign in or register to apply for this unit</Tooltip>
    );

    const toggleStreetView = useCallback(() => {
        setShowStreetView(prevState => !prevState);
    }, []);

    const addOrDeleteFavorite = async (id, type, operation, token) => {

        if( typeof id !== 'number' ) throw new Error(`First argument (id) should be of type number received "${typeof token}"!`)
        if( typeof type !== 'string' ) throw new Error(`Second argument (type) should be of type number received "${typeof type}"!`)
        if( typeof operation !== 'string' ) throw new Error(`Third argument (operation) should be of type number received "${typeof operation}"!`)
        if( typeof token !== 'string') throw new Error(`Fourth argument (token) should be of type "string" received "${typeof token}"!`);

        // if( type.trim() !== 'unit' || type.trim() !== 'building') throw new Error('Second argument (type) should be of value either "building" or "unit"!');
        // if( operation.trim() !== 'add' || operation.trim() !== 'delete') throw new Error('Third argument (operation) should be of value either "add" or "delete"!');

        setIsAddingToFavorites(true);
        let result;

        switch(type) {
            case 'building':
                if(operation === 'add') result = await addFavoriteBuilding(token, id);
                else if (operation === 'delete') result = await deleteFavoriteBuilding(token, id);
                break;
            case 'unit':
                if(operation === 'add') result = await addFavoriteUnit(token, id);
                else if (operation === 'delete') result = await deleteFavoriteUnit(token, id);
                break;
            default:
                setIsAddingToFavorites(false);
                return;
        }

        const { success } = result;
        setIsAddingToFavorites(false);

        if(success) {
            SuccessPopup(operation === 'add' ? `Added ${type} to favorites list!` : `Removed ${type} from favorites list!`);

            switch(type) {
                case 'building':
                    if(operation === 'add') dispatch(favoriteActions.addFavoriteBuilding(selectedBuildingData));
                    else if (operation === 'delete') dispatch(favoriteActions.deleteFavoriteBuilding(selectedBuildingData.BuildingID));
                    break;
                case 'unit':
                    if(operation === 'add') dispatch(favoriteActions.addFavoriteUnit(selectedUnitData));
                    else if (operation === 'delete') dispatch(favoriteActions.deleteFavoriteUnit(selectedUnitData.UnitID));
                    break;
                default:
                    break;
            }
        } else {
            ErrorPopup(operation === 'add' ? "Something went wrong. Couldn't add to favorites..." : "Something went wrong. Couldn't delete from favorites...");
        }
    }
    return (
        <>
            <ModalTourRequest
                show={showRequestTour}
                closeHandler={() => setShowRequestTour(false)}
                unit={selectedUnitData}
                setAlreadyRequestesUnitIDs={setAlreadyRequestesUnitIDs}
            />

            <ModalStreetViewMobile
                show={showStreetView && (size && size.width <= GRID_BREAKPOINTS.MD)}
                onHide={toggleStreetView}
                buildingData={selectedBuildingData}
                streetViewAvailable={streetViewAvailable}
            />

            <Modal show={!!BuildingID && !UnitID || UnitID}
                onHide={onHideHandler}
                // size="xl"
                // aria-labelledby="contained-modal-title-vcenter"
                // centered
                fullscreen
                dialogClassName="modal-fullHeight"
                id="testMain"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row className="m-0 p-0">
                            <Col className="m-0 p-0 d-flex align-items-center">
                                <h5 className="m-0 p-0">
                                    {/* {selectedBuildingData ? `` : 'Select Building'} */}
                                    { BuildingID && !selectedBuildingData ? `` : ''}
                                </h5>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="m-0 p-0" style={{ overflowY: 'scroll' }} >
                    <Container fluid className="p-0 m-0 montserrat h-100">

                        { (!selectedUnitData) && <LogoLoader /> }

                        { selectedUnitData && (
                            <>
                                <Row className="m-0 p-0 d-md-none">
                                    <Row className="m-0 mt-1">
                                        <Col className={`p-3 text-center rounded-start ${ activeTab === TAB.OCCUPANT ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.OCCUPANT)}>
                                            <h6 className="m-0 fw-bold">Building Info</h6>
                                        </Col>
                                        <Col className={`p-3 text-center rounded-end ${ activeTab === TAB.UNIT_INFO ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.UNIT_INFO)}>
                                            <h6 className="m-0 fw-bold">Unit Info</h6>
                                        </Col>
                                    </Row>

                                    <Tab.Container activeKey={activeTab}>
                                        <Tab.Content className="pb-3">
                                            <Tab.Pane eventKey={TAB.OCCUPANT}>
                                                <BuildingInfo 
                                                    selectedBuildingData={selectedBuildingData}
                                                    streetViewAvailable={streetViewAvailable}
                                                    showStreetView={showStreetView}
                                                    setShowStreetView={setShowStreetView}
                                                    setStreetViewClicked={setStreetViewClicked}
                                                    isUnitSelected={isUnitSelected}
                                                    showSelectedUnit={showSelectedUnit}

                                                    isLoading={isLoading && !selectedUnitData}

                                                    favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                                    favorites={favorites}
                                                    features={features}
                                                    alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={TAB.UNIT_INFO}>
                                                <UnitInfo 
                                                    selectedUnitData={selectedUnitData}
                                                    selectedBuildingData={selectedBuildingData}
                                                    streetViewClicked={streetViewClicked}
                                                    showStreetView={showStreetView}
                                                    streetViewAvailable={streetViewAvailable}
                                                    toggleStreetView={toggleStreetView}

                                                    isLoading={isLoading && !selectedUnitData}

                                                    favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                                    favorites={favorites}
                                                    features={features}
                                                />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Row>

                                <Row className="m-0 p-0 h-100 d-none d-md-flex">
                                    <BuildingInfo 
                                        selectedBuildingData={selectedBuildingData}
                                        streetViewAvailable={streetViewAvailable}
                                        showStreetView={showStreetView}
                                        setShowStreetView={setShowStreetView}
                                        setStreetViewClicked={setStreetViewClicked}
                                        isUnitSelected={isUnitSelected}
                                        showSelectedUnit={showSelectedUnit}

                                        isLoading={isLoading && !selectedUnitData}

                                        favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                        favorites={favorites}
                                        features={features}
                                        alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                                    />

                                    <UnitInfo 
                                        selectedUnitData={selectedUnitData}
                                        selectedBuildingData={selectedBuildingData}
                                        streetViewClicked={streetViewClicked}
                                        showStreetView={showStreetView}
                                        streetViewAvailable={streetViewAvailable}
                                        toggleStreetView={toggleStreetView}

                                        isLoading={isLoading && !selectedUnitData}

                                        favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                        favorites={favorites}
                                        features={features}
                                    />
                                </Row>
                            </>
                        )}
                            
                    </Container>
                </Modal.Body>

                <Modal.Footer className='m-0 p-0'>
                    {(isButtonDisabledCompute) && (
                        <p className="m-0 p-0 px-2 text-danger" style={{ fontSize: '.9rem' }}>
                            {userType === "Landlord" ? "Can't send a request from Landlord account!" : ''}
                            {userType === "Manager" ? "Can't send a request from Manager account!" : ''}
                            {userType === "Tenant" ? "The request was already sent!" : ''}
                            {!userType ? "Log in to send a request!" : ''}
                        </p>
                    )}

                    {(userType ? (
                        <Col xs={'auto'}>
                            <ButtonPrimary 
                                title="Request a Tour"
                                onClick={openRequestATour}
                                disabled={isButtonDisabled()}
                            />
                        </Col>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 100, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <div>
                                <Col xs={12} sm="auto">
                                    <ButtonPrimary 
                                        title="Request a Tour"
                                        onClick={openRequestATour}
                                        disabled={isButtonDisabled()}
                                    />
                                </Col>
                            </div>
                        </OverlayTrigger>
                    ))
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
};
