import React from 'react'
import { Row } from 'react-bootstrap'

const Bar = ({ className, children }) => {

  return (
    <Row className={`darkerGray py-1 m-0 mb-3 rounded text-center text-uppercase ${className}`}>
      {children}
    </Row>
  )
}

export default Bar