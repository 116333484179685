import React, { forwardRef } from 'react';
import {
    FloatingLabel,
    Form,
    Row,
    Col,
    Button
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

export default function DateInput ({
    label,
    placeholder,
    error,
    setDate,
    date,
    showClearBtn=true,
    ...parameters
}) {
    const onChangeHandler = (date) => {
        setDate(date);
    }

    const clearDate = () => {
        setDate('');
    }

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <FloatingLabel
            controlId="floatingInput"
            label={label}
            className="mb-3"
        >
            <Form.Control type="text" 
                placeholder={placeholder}
                value={value}
                ref={ref}
                onClick={onClick}
                className={"rounded border-0 shadow-sm"}
                // readOnly
            />
            <p className="custom_error pt-1">
                {error}
            </p>
        </FloatingLabel>
    ))

    return (
        <Row className="m-0 p-0 d-flex flex-row">
            <Col className="m-0 p-0" >
                <DatePicker
                    selected={ date ? date : null}
                    onChange={onChangeHandler}
                    customInput={<CustomInput />}

                    {...parameters}
                />
                
            </Col>
            {   showClearBtn && date && (
                    <Col  className="m-0 mb-3 p-2 pe-0" xs={'auto'}>
                        <Button className="bg-danger border-0 h-100  rounded"
                            onClick={clearDate}
                        >
                            <i className="bi bi-x-lg"></i>
                        </Button>
                    </Col>
                )
            }
        </Row>
    );
}
