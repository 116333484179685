import React, { useState, useContext, useEffect, Suspense, lazy } from "react";
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { useSelector, useDispatch } from 'react-redux';

import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import DecisionModal from "../components/Shared/InfoPopups/DecisionModal";

import { arrFromObjVal, objFromArrAndFilledWithValue } from '../utils/dataTransformations';
import {
    getBuildingFeatures,
    getBuilding,
    deleteBuilding
} from '../services/building';
import Header from "../components/BuildingInfo/Header";

import BuildingInfoLoader from '../components/BuildingInfo/Loader';
import BackNav from "../components/Shared/BackNav";
import { propertiesDataActions } from "../store/PropertiesSlice";

import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';

import ModalDetails from "../components/Shared/PropertyDetails/ModalDetails";
import FloatingActionButton, {FabButton} from "../components/Shared/FloatingActionButton";
import useScrolled from "../hooks/useScrolled";

const Requests = lazy(() => import("../components/BuildingInfo/Requests"));
const Information = lazy(() => import("../components/BuildingInfo/Information"));
const Units = lazy(() => import("../components/BuildingInfo/Units"));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const TAB = {
    INFORMATION: 'information',
    UNITS: 'units',
    REQUESTS: 'requests'
}


export default function BuildingInfo() {
    const dispatch = useDispatch();
    const propertiesStore = useSelector(store => store.propertiesStore.properties);
    const propertyFeaturesStore = useSelector(store => store.propertiesStore.propertyFeatures);

    const { userType } = useContext(UserTypeContext);

    const history = useHistory();
    let query = useQuery();

    let tabQuery = query.get("active-tab");
    let buildingID = Number(query.get("building-id"));

    const { token } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(false);
    const unitsFloorArr = [];
    const [uniqueUnitsFloorArr, setUnique] = useState([]);
    const [features, setFeatures] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [tickets, setTickets] = useState(null);

    const [accessibilities, setAccessibilities] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [textAmenities, setTextAmenities] = useState("");
    const [textAccessibilities, setTextAccessibilities] = useState("");
    const [notes, setNotes] = useState("");

    const [previewModal, setPreviewModal] = useState(false);
    const openBuildingPreview = () => {
        setPreviewModal(true);
    }
    const closeBuildingPreview = () => {
        setPreviewModal(false);
    }

    const handleSelectTab = (eventKey) => {
        setActiveTab(eventKey);
    }

    const fetchedFeatures = async () => {
        if (propertyFeaturesStore) {
            setData(propertyFeaturesStore);
            return;
        }

        setLoading(true);

        const response = await getBuildingFeatures();
        const { success, data } = response;

        setLoading(false);

        if (success) {
            setData(data);
            dispatch(propertiesDataActions.setPropertyFeatures(data));
        } else {
            ErrorPopup("Error! Couldn't get building features data. Try again later.");
        }

        function setData(data) {
            const transformedObject = {
                Accessibilities: arrFromObjVal(data.Accessibilities),
                Amenities: arrFromObjVal(data.Amenities)
            }
            setFeatures(transformedObject);
        }
    }

    const fetchBuildingData = async () => {
        const findProperty = propertiesStore.find(property => property.BuildingID === Number(buildingID));
        if (findProperty) {
            setData(findProperty);
            return;
        }
        setLoading(true);

        const response = await getBuilding(buildingID, token);
        const { success, data } = response;

        if (success) {
            dispatch(propertiesDataActions.addProperty(data));
            setData(data);
        } else {
            ErrorPopup("Error! Couldn't get building features data. Try again later.");
        }

        setLoading(false);

        function setData(data) {
            const prepareAccessibilities = objFromArrAndFilledWithValue(data.Accessibilities);
            const prepareAmenities = objFromArrAndFilledWithValue(data.Amenities);

            setAccessibilities(prepareAccessibilities);
            setAmenities(prepareAmenities);
            setTextAccessibilities(data.MiscAccessibilities);
            setTextAmenities(data.MiscAmenities);
            setNotes(data.Notes);
            setProperty(data);
        }
    }

    const deleteBuildingHandler = async (BuildingID) => {
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this building!"
        });


        if (!decision.isConfirmed) return;
        
        setIsSaving(true);
        const response = await deleteBuilding(BuildingID, token);
        const { success } = response;
        setIsSaving(false);

        if (success) {
            SuccessPopup("Success! Property was deleted.");
            dispatch(propertiesDataActions.deleteProperty(BuildingID));
            history.push('/properties')
        } else {
            ErrorPopup("Error! Couldn't get building data. Try again later.");
        }
    }

    useEffect(() => {
        if (buildingID === null) {
            history.goBack();
        } else {
            fetchBuildingData();
            fetchedFeatures();
        }
    }, []);

    useEffect(() => {
        property && property.Units && property.Units.forEach((unit, index) => {
            return (
                unitsFloorArr[index] = (unit.FloorID === undefined || unit.FloorID === null) ? "Uncategorized" : unit.FloorID
            );
        })

        setUnique([...new Set(unitsFloorArr)]);
    }, [property]);

    useEffect(() => {
        var arr = uniqueUnitsFloorArr.sort((a, b) => (a - b))
        if (arr.at(-1) === "Uncategorized") {
            arr.unshift(arr.at(-1))
            arr.pop(arr.at(-1))
        }
        setUnique(arr)
    }, [uniqueUnitsFloorArr])

    const compute_defaulTab = () => {
        switch (tabQuery) {
            case "requests":
                return "requests";
            case "information":
                return "information";
            case "units":
                return "units";
            default:
                return "information"
        }
    }

    const [fabOpen, setFabOpen] = useState(false);
    const scrolled = useScrolled();

    return (
        <Container fluid style={{ maxWidth: 1200 }} className="m-auto mb-5 p-0">

            { isSaving && <LoaderWithBackdrop /> }

            <FloatingActionButton
                isOpen={fabOpen}
                setClick={() => setFabOpen(prev => !prev)}
                childrenClass="bg-primary"
                className={`d-md-none  
                ${scrolled >= .1 ? "toTop" : ""} 
                ${
                    (previewModal)
                    ?
                    "hide"
                    :
                    ""
                }
                `}
            >
                <FabButton
                    icon="bi-plus-lg"
                    title="Add New Unit"
                    onClick={() => history.push({
                        pathname: "/add-new-unit",
                        search: `?building-id=${buildingID}`
                    })}
                />
                <FabButton
                    icon="bi-plus-lg"
                    title="Import Units"
                    onClick={() => history.push({
                        pathname: "/import-units",
                        search: `?building-id=${buildingID}`
                    })}
                />
                <FabButton
                    icon="bi-pencil-fill"
                    title="Edit Property"
                    onClick={() => history.push({
                        pathname: "/building-update",
                        search: `?building-id=${buildingID}`
                    })}
                />
                <FabButton
                    icon="bi-aspect-ratio"
                    title="Preview"
                    onClick={()=> {
                        setPreviewModal(true)
                        setFabOpen(false)
                    }}
                />
                <FabButton
                    icon="bi-x-lg"
                    title="Delete"
                    onClick={()=>{
                        setFabOpen(false)
                        deleteBuildingHandler(buildingID)
                    }}
                />
            </FloatingActionButton>

            <ModalDetails
                show={previewModal}
                onHide={closeBuildingPreview}
                property={property}
            />

            <BackNav className="m-0 p-0 mb-3 px-2"
                currentPage="property"
                pages={[{
                    title: "properties",
                    goTo: "/properties"
                }]}
                disabled={loading}
            />
            {loading ? (
                <BuildingInfoLoader />
            ) : (
                <Row className="m-0 p-0 mb-5 p-2 p-lg-5 lightGray d-flex justify-content-center rounded overflow-hidden">

                    <Header
                        property={property}
                        buildingID={buildingID}
                        deleteBuilding={deleteBuildingHandler}
                        userType={userType}
                        openBuildingPreview={openBuildingPreview}
                    />

                    <Row className="m-0 p-0 mt-3 mt-md-5">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={compute_defaulTab} unmountOnExit >
                            <Row className="m-0 p-0">
                                <Col className="m-0 p-0">
                                    <Row className="m-0 p-0">
                                        <Nav variant="pills" className="text-center w-100 m-0 p-0" activeKey={activeTab} onSelect={handleSelectTab}>
                                            <Row className="m-0 p-0 w-100">
                                                <Col className="m-0 p-0">
                                                    <Nav.Item className="flex-fill rounded apartmentInfoNav w-100">
                                                        <Nav.Link className={`fw-bold rounded py-3 ${activeTab === TAB.INFORMATION ? 'text-light bg-secondary' : ''}`} eventKey={TAB.INFORMATION}>
                                                            <p className={`d-none d-sm-block m-0 p-0 fs-5`} style={{ color: 'inherit' }}>INFORMATION</p>
                                                            <i className={`d-block d-sm-none bi bi-building fs-3`}></i>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                                <Col className="m-0 p-0 border-x-light-gray">
                                                    <Nav.Item className="flex-fill rounded apartmentInfoNav w-100">
                                                        <Nav.Link className={`fw-bold rounded py-3 ${activeTab === TAB.UNITS ? 'text-light bg-secondary' : ''}`} eventKey={TAB.UNITS}>
                                                            <p className={`d-none d-sm-block m-0 p-0 fs-5`} style={{ color: 'inherit' }}>UNITS</p>
                                                            <i className={`d-block d-sm-none bi bi-info-square fs-3`} style={{ color: 'inherit' }}></i>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                                <Col className="m-0 p-0">
                                                    <Nav.Item className="flex-fill rounded apartmentInfoNav w-100">
                                                        <Nav.Link className={`fw-bold rounded py-3 ${activeTab === TAB.REQUESTS ? 'text-light bg-secondary' : ''}`} eventKey={TAB.REQUESTS}>
                                                            <p className={`d-none d-sm-block m-0 p-0 fs-5`} style={{ color: 'inherit' }}>REQUESTS</p>
                                                            <i className={`d-block d-sm-none bi bi-envelope fs-3 `} style={{ color: 'inherit' }}></i>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            </Row>
                                        </Nav>
                                    </Row>

                                    <Col className="m-0 p-0">
                                        <Suspense fallback={<Row className="m-0 p-5 d-flex justify-content-center align-items-center">
                                            {/* <Spinner animation="border" role="status"/> */}
                                        </Row>}
                                        >
                                            <Tab.Content data="tabContent" className="m-0 p-0">
                                                <Tab.Pane eventKey="information" className="m-0 p-0">

                                                    <Information
                                                        building={property}
                                                        features={features}
                                                        setAmenities={setAmenities}
                                                        amenities={amenities}
                                                        textAmenities={textAmenities}
                                                        setTextAmenities={setTextAmenities}
                                                        setAccessibilities={setAccessibilities}
                                                        accessibilities={accessibilities}
                                                        textAccessibilities={textAccessibilities}
                                                        setTextAccessibilities={setTextAccessibilities}
                                                        notes={notes}
                                                        userType={userType}
                                                    />

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="units" className="m-0 p-0">

                                                    <Units
                                                        userType={userType}
                                                        uniqueUnitsFloorArr={uniqueUnitsFloorArr}
                                                        units={property}
                                                        buildingID={buildingID}
                                                    />

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="requests" className="m-0 p-0">

                                                    <Requests
                                                        property={property}
                                                        // tickets={tickets}
                                                        // setTickets={setTickets}
                                                        token={token}
                                                    />

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Suspense>
                                    </Col>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Row>

                    <Col className="m-0 p-0">
                        <Suspense fallback={<Row className="m-0 p-5 d-flex justify-content-center align-items-center">
                            {/* <Spinner animation="border" role="status"/> */}
                        </Row>}
                        >
                            <Tab.Content data="tabContent" className="m-0 p-0">
                                <Tab.Pane eventKey="information" className="m-0 p-0">

                                    <Information
                                        units={property}
                                        features={features}
                                        setAmenities={setAmenities}
                                        amenities={amenities}
                                        textAmenities={textAmenities}
                                        setTextAmenities={setTextAmenities}
                                        setAccessibilities={setAccessibilities}
                                        accessibilities={accessibilities}
                                        textAccessibilities={textAccessibilities}
                                        setTextAccessibilities={setTextAccessibilities}
                                    />

                                </Tab.Pane>
                                <Tab.Pane eventKey="units" className="m-0 p-0">

                                    <Units
                                        userType={userType}
                                        uniqueUnitsFloorArr={uniqueUnitsFloorArr}
                                        units={property}
                                        buildingID={buildingID}
                                    />

                                </Tab.Pane>
                                <Tab.Pane eventKey="requests" className="m-0 p-0">

                                    <Requests
                                        units={property}
                                        tickets={tickets}
                                        setTickets={setTickets}
                                        token={token}
                                    />

                                </Tab.Pane>
                            </Tab.Content>
                        </Suspense>
                    </Col>

                </Row >
            )
}
        </Container >


    );
}
