import React from 'react';


export default function ButtonWithLeftBorder({
    showIcon=false,
    iconClass='bi bi-circle',
    buttonClass='p-2 darkerGray border-0 w-100 text-dark fw-bold rounded',
    // buttonClass='p-2 darkerGray border-0 w-100 text-dark on-hover-bold',
    additionalButtonClass='border-right-red',
    title='Button Title',
    titleMobile,
    onClick,
    disabled=false
}) {
    return (
        <button
            className={`${ buttonClass } ${ additionalButtonClass }`}
            onClick={onClick}
            disabled={disabled}
        >
           
            <p className={`${ titleMobile ? 'd-none d-sm-inline-block' : '' } m-0 p-0 fs-6`}>{ showIcon && <i className={`${iconClass} pe-2`}></i> }{ title }</p>
            { titleMobile && <p className="d-inline-block d-sm-none m-0 p-0 fs-6">{ titleMobile }</p> }
        </button>
    )
}