import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';


export default function FormerLandlordCardLoader() {
    return (
        <Row className="p-0 m-0 pt-2 ps-md-3">
            <Row className="m-0 p-0 position-relative row-cols-1">
                <Col className="m-0 p-0 mb-1" xs={12} md={6}>
                    <p className="m-0 darkerGray skeleton rounded" style={{width: 225}}>&nbsp;</p>
                </Col>

                <Col className="m-0 p-0">
                    <p className="m-0 darkerGray skeleton rounded" style={{width: 200}}>&nbsp;</p>
                </Col>
            </Row>
        </Row>
    )
} 
