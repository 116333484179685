import React, { useEffect, useContext} from 'react';

import { BuildingsContext } from '../../contexts/BuildingsContext';
import ModalDetails from './PropertyDetails/ModalDetails';


export default function UnitAndBuildingInfoModal({ onHide, unitAndBuildingIDs, showModal, showRequestBtn=false, favorites }) {
    const { 
        buildingsData, 
        getBuildingByID,
        isLoading
    } = useContext(BuildingsContext);

    const { BuildingID, UnitID } = unitAndBuildingIDs;

    useEffect(()=>{
        if(BuildingID) {
            getBuildingByID(BuildingID);
        }
    }, [BuildingID, getBuildingByID]);
    
    return (
        <ModalDetails 
            show={showModal}
            onHide={onHide}
            property={buildingsData?.[BuildingID] }
            showOnlyUnitWithID={UnitID}
            isLoading={isLoading}
            showRequestBtn={showRequestBtn}
            favorites={favorites}
        />
    )
}
