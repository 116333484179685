import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';

import StreetViewCard from '../Shared/StreetView/StreetViewCard';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

export default function ModalStreetViewMobile({
    show,
    onHide,
    buildingData,
    streetViewAvailable
}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            fullscreen
            dialogClassName="modal-fullHeight"
        >
            <Modal.Header closeButton />
            <Modal.Body className='m-0 p-2'>
                { show && (
                        <StreetViewCard
                            buildingData={buildingData}
                            renderCloseBtn={false}
                            streetViewAvailable={streetViewAvailable}
                        />
                    )
                }
            </Modal.Body>
            <Modal.Footer className='m-0 p-0'>
                <Row className="m-0 px-2 py-1 w-100">
                    <Col className="m-0 p-0" xs={6}>
                        <ButtonPrimary 
                            title="Back"
                            type="button"
                            onClick={onHide}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}