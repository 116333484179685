import React, { useState, useMemo, useContext } from 'react'
import { Container, Col, Row, Button } from "react-bootstrap";

import Request from '../../LL_TourRequests/Request';
import RequestLoader from '../../LL_TourRequests/RequestLoader';
import FiltersBtn from '../Filters/FiltersBtn';
import { UserTypeContext } from "../../../contexts/UserTypeContext";

import Backdrop from "../Backdrop";
import { FilterRow } from "../Filters";
import { orderBy } from 'lodash';

export const FILTER_REQUESTS_BY = Object.freeze({
    NEW: 'New',
    OPEN: 'Open',
    CLOSE: 'Close',
    ALL: 'All',
    NEW_REQ: 'NewRequests'
});

export const CURRENT_PROGRESS = Object.freeze({
    REQ_SENT: 'Request Sent', 
    LL_RES: 'Landlord Responded', 
    T_RES: 'Tenant Responded', 
    M_RES: 'Manager Responded', 
});

const FILTERS = {
    DATE: 'Date',
    STATUS: 'Status'
}

const FILTER_BY = {
    MOST_RECENT: 'mostRecent',
    MOST_PAST: 'mostPast',
    OPEN_MESSAGES: 'openMessages',
    CLOSED_MESSAGES: 'closedMessages'
}

export default function MoreRequests({tickets, selectedReqID, reqClickHandler, isLoading}) {
    const [ showFilters, setShowFilters ] = useState(false);
    const [activeSort, setActiveSort] = useState({
        Date: FILTER_BY.MOST_RECENT,
    });
    const [selectedFilter, setSelectedFilter] = useState(FILTER_REQUESTS_BY.ALL);
    const { userType } = useContext(UserTypeContext);
    const getFullName = (tenant) => {
        if (!tenant) return null;

        const { FirstName, LastName } = tenant;
        return `${FirstName.trim()} ${LastName.trim()}`;
    }

    const openFilters = () => {
        setShowFilters(true);
    }
  
      const closeFilters = () => {
        setShowFilters(false);
    }

    const clearFilterHandler = (filterName, defaultValue='') => {
        setActiveSort( prevState => ({
            ...prevState,
            [filterName]: defaultValue
        }))
    }

    const changeHandler = (value, filterName) => {
        setActiveSort( prevState => ({
            ...prevState,
            [filterName]: value
        }))
        closeFilters();
    }

    const setFilterHandler = (filterType) => {
        setSelectedFilter(filterType); 
        closeFilters(); 
      }

    const sortByTimeStamp = (arr, order) => {
        if(!arr) return [];
        const ticketsCopy = [...arr];

        return orderBy(ticketsCopy, function(dateObj) {
            let messagesLength = dateObj.Messages?.length;

            if(messagesLength === 0) messagesLength = 1;
            const timeStamp = dateObj.Messages[messagesLength - 1].TimeStamp;

            return new Date(timeStamp);
        }, [order]);
         // return orderBy(ticketsCopy, [function (ticket) { return ticket.Messages[ticket.Messages.length ? ticket.Messages.length - 1 : 0]?.TimeStamp }], [order])
    }

    function checkCurrentProgress(a_progressDescriptions, o_ticket) {
        return a_progressDescriptions.some( desc => o_ticket.CurrentProgress === desc )
    }
    
    function checkTicketStatus(b_isOpen, o_tickets) {
        return o_tickets.Status === b_isOpen;
    }

    const filterHandler = useMemo(() => {
        if(!tickets) return [];
        // let sortedAndFilteredArr = [...tickets];
        let sortedAndFilteredArr = tickets.filter( ticket => {
            switch(selectedFilter) {
                case FILTER_REQUESTS_BY.ALL:
                    return true;
                case FILTER_REQUESTS_BY.NEW_REQ:
                    return checkCurrentProgress([CURRENT_PROGRESS.REQ_SENT], ticket);
                case FILTER_REQUESTS_BY.CLOSE:
                    return checkTicketStatus(false, ticket);
                case FILTER_REQUESTS_BY.NEW:
                    if(userType === 'Tenant') {
                        return checkCurrentProgress([CURRENT_PROGRESS.LL_RES, CURRENT_PROGRESS.M_RES], ticket);
                    }
                    return checkCurrentProgress([CURRENT_PROGRESS.T_RES], ticket);
                default:
                    return false;
            }
        })
    
        if(activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT) {
            sortedAndFilteredArr = sortByTimeStamp(sortedAndFilteredArr, 'desc');
        } else if(activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST) {
            sortedAndFilteredArr = sortByTimeStamp(sortedAndFilteredArr, 'asc');
        }

        return sortedAndFilteredArr;
      }, [activeSort, tickets, selectedFilter, userType]);

    return (
        <>
            <Backdrop 
                show={showFilters}
                close={closeFilters}
            />

            <Container className={`p-3 sideDrawer sideDrawer--left d-flex flex-column ${showFilters ? 'sideDrawer--show' : ''} bg-light`}>

                <Row className={'m-0 p-0 mb-3 mb-md-5 sideDrawer__close'}>
                    <Col className={`m-0 p-0 d-flex justify-content-end align-items-center`}>
                        <Button className="m-0 p-2 border-0 bg-transparent" onClick={closeFilters}>
                            <i className="bi bi-x-lg text-dark"></i>
                        </Button>
                    </Col>
                </Row>

                <Container className="m-0 p-0 flex-grow-1">
                    
                    <FilterRow
                        clearFilter={() => clearFilterHandler(FILTERS.DATE, FILTER_BY.MOST_RECENT )}
                        title={FILTERS.DATE}
                        value={ activeSort[FILTERS.DATE] }
                    >
                        <Col xs={6} className="m-0 p-0">
                            <div onClick={() => changeHandler(FILTER_BY.MOST_RECENT, FILTERS.DATE)} className={`h-100 shadow-sm d-flex justify-content-center align-items-center m-1 p-1 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                                <i className="bi bi-arrow-up fs-6"></i>
                                <p className={`m-0 p-0 ps-3 fs-6 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT ? 'text-white' : 'text-dark' }`}>Most Recent</p>
                            </div>
                        </Col>

                        <Col xs={6} className="m-0 p-0">
                            <div onClick={() => changeHandler(FILTER_BY.MOST_PAST, FILTERS.DATE)} className={`h-100 shadow-sm d-flex justify-content-center align-items-center m-1 p-1 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                                <i className="bi bi-arrow-down fs-6"></i>
                                <p className={`m-0 p-0 ps-3 fs-6 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST ? 'text-white' : 'text-dark' }`}>Most Past</p>
                            </div>
                        </Col>
                    </FilterRow>

                    <Row className="m-0 p-0 mb-3">
                        <Row  className="m-0 p-0">
                            <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                                <h6 className="m-0 p-0 fw-normal">Filter by</h6>
                                
                            </Col>
                        </Row>
                        <Row  className="m-0 p-0 px-1">
                            <RequestTypeButton colors={'opacity-0'} isActive={selectedFilter === FILTER_REQUESTS_BY.ALL} title="All" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.ALL) }}/>
                            <RequestTypeButton colors={'bg-success'} isActive={selectedFilter === FILTER_REQUESTS_BY.NEW} title="New Messages" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.NEW) }}/>
                            <RequestTypeButton colors={'bg-primary'} isActive={selectedFilter === FILTER_REQUESTS_BY.NEW_REQ} title="New Requests" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.NEW_REQ) }}/>
                            <RequestTypeButton colors={'bg-danger'} isActive={selectedFilter === FILTER_REQUESTS_BY.CLOSE} title="Declined" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.CLOSE) }}/>
                        </Row>
                    </Row>

                    
                </Container>

            </Container>

            <Col className="p-3 d-flex flex-column lightGray inbox-element-h rounded" xs={12} xl={3}>
                <Row className={`m-0 p-0 py-3 py-xl-4`}>
                    <h3 className="d-xl-none m-0 p-0 fw-bold">Messages</h3>
                    <h3 className="d-none d-xl-block m-0 p-0 fw-bold">All Messages</h3>
                </Row>

                <Row className={`m-0 p-0 pb-3`}>
                    <FiltersBtn 
                        howManyFiltersActive={() => 0}
                        showResults={false}
                        openFilters={openFilters}
                        btnTitle="Sort"
                    />
                </Row>

                <Row className="m-0 p-0 pe-3 flex-grow flex-column flex-nowrap" style={{overflowY: "auto"}}>
                        { !isLoading && filterHandler && filterHandler.length !== 0 && filterHandler.map(({ TicketID, IssueDate, Tenant, Status, CurrentProgress }) => (
                                <Request
                                    key={TicketID}
                                    ticketID={TicketID}
                                    author={getFullName(Tenant)}
                                    date={IssueDate}
                                    readed={false}
                                    selectedReqID={selectedReqID}
                                    clickHandler={reqClickHandler}
                                    status={Status}
                                    CurrentProgress={CurrentProgress}
                                />
                        )) }
                    
                        { !isLoading && ((!isLoading && !tickets) || (filterHandler && filterHandler.length === 0)) &&  <Row
                                className={`p-2 pb-0 my-1 cursor-pointer`}
                            >
                                <p className={`m-0 p-0 text-center`} style={{
                                    fontSize: '1rem',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}>No Messages...</p>
                            </Row>
                        }

                        { isLoading && (
                            <>
                                <RequestLoader />
                                <RequestLoader />
                            </>
                        )}
                    
                </Row>
            </Col>

        </>
    )
}


function RequestTypeButton ({onClick, title, children, isActive, colors}) {
    return (
        <Row className={`m-0 p-1 px-2 py-2 mb-1 cursor-pointer fs-6 on-hover-scale ${ isActive ? 'bg-primary text-light' : ''} rounded`} onClick={onClick}>
            <div className="m-0 p-0 d-flex flex-row align-items-center justify-content-start h-100">
                { children || (
                <>
                    <div className={`m-0 p-0 rounded-circle me-1 me-md-2 ${colors} border border-light`} style={{width: '1rem', height: '1rem'}}></div>
                    <span className="d-inline">{title}</span>
                </>
            )}</div>
        </Row>
    )
}
