import React from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "react-bootstrap";

import LazyImage from "../../Shared/LazyImage";

export default function PropertiesUnit() {
    return (
    <Container fluid className="m-0 p-0 mb-3">

          <Card className="m-0 p-0 border-0 width-1-1 clickable lightGray">
              <Card.Body className="m-0 p-0 overflow-hidden rounded">

                  <Row className="m-0 p-0 d-block d-md-none rounded overflow-hidden">
                      <Col className="m-0 p-0" style={{ height: 200, width: "100%" }} >
                          <LazyImage src={null} style={{ objectFit: "cover", height: 200, width: "100%" }} />
                      </Col>
                  </Row>

                  <Row className="m-0 p-0">
                      <Col xs="auto" className="m-0 p-0 d-none d-md-block rounded overflow-hidden" style={{ height: 200, width: 300 }}>
                          <LazyImage src={null} style={{ objectFit: "cover", height: 200, width: 300 }} />
                      </Col>
                      <Col className="m-0 p-0 px-2 px-sm-4">
                          <Row className="m-0 p-0 d-flex align-self-center py-3">
                              <Col className="m-0 p-0" xs={12}>
                                <h5 className="m-0 p-0 fw-bold darkerGray skeleton rounded w-50" >&nbsp;</h5>
                              </Col>
                              
                              <Col className="m-0 p-0" xs={12}>
                                <h6 className="m-0 p-0 mt-2 darkerGray skeleton rounded w-75">&nbsp;</h6>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </Card.Body>
          </Card>
  
    </Container>
  )
  }
