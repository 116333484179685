import React, {useState, useEffect, useContext} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
    Container,
    Col,
} from 'react-bootstrap';

import useQuery from '../hooks/useQuery';

import ResponseModal from '../components/LL_TourRequests/ResponseModal';
import MessageContainer from '../components/Shared/Inbox/MessageContainer';
import Inbox, { SHOW_COMPONENT } from '../components/Shared/Inbox/Inbox';
import TenantProfileModal from '../components/LL_TourRequests/TenantProfileModal';
import { AuthContext } from "../contexts/AuthContext";
import { CreatePortfolioContext } from '../contexts/CreatePortfolioContext';
import { BuildingContext } from '../contexts/BuildingContext';
import { getUnitTickets } from '../services/tickets';
import { getTenantProfile } from '../services/tenantProfile';
import { convertDateToMs } from '../utils/dateTransformations';
import BackNav from '../components/Shared/BackNav';

import MoreRequests from '../components/Shared/Inbox/MoreRequests';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import { getBuilding } from '../services/building';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';


// TODO: Add context for informations about Unit! And API request if this context is empty
// in case when user turned on this page starting from address related to this component

export default function LL_TourRequests() {
    const { token } = useContext(AuthContext);
    const { setTenantData } = useContext(CreatePortfolioContext);
    const { buildingInfo, setBuildingInfo } = useContext(BuildingContext);

    const location = useLocation();
    const history = useHistory();
    const { buildingID, unitID } = useParams();
    let query = useQuery();
    const requestIdFromURL = query.get("ticket");
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [selectedReqID, setSelectedReqID] = useState(0);
    const [selectedReq, setSelectedReq] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const [doesProfileExists, setDoesProfileExists] = useState(null);
    const [positions, setPositions] = useState([]);
    const [evictions, setEvictions] = useState([]);
    const [formerLandlords, setFormerLandlords] = useState([]);
    const [unitInfo, setUnitInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [tickets, setTickets] = useState(null);
    const [messages, setMessages] = useState(null);
    const [inboxActiveComponent, setInboxActiveComponent] = useState(SHOW_COMPONENT.MESSAGES);

    const getUnitTicketsHandler = async () => {
        const response = await getUnitTickets( token, unitID);

        if (response.success) {
            const tickets = response.data;

            let messages = tickets[0];
            if(requestIdFromURL) {
                messages = tickets.find( ticket => String(ticket.TicketID) === String(requestIdFromURL));
            }

            const unitInfo = messages && messages.UnitInfo;
            if(!messages) {
                setTickets(null);
                setMessages(null);
                setSelectedReqID(null);
                setSelectedReq(null);
                setError(null);
                return;
            }

            setSelectedReqID(messages.TicketID);
            setIsLoading(false);
            setTickets(tickets);
            setMessages(messages);
            setUnitInfo(unitInfo);

            if(messages.Messages.length !== 0) {
                const preparedMessage = prepareMessage(messages.Messages[latestMessageIndex(messages.Messages)], messages);
                setSelectedReq(preparedMessage);
            }

            setError(null);
        } else {
            const error = response.data;
            ErrorPopup();
            setError(error);
        }
    }

    const reqClickHandler = (TicketID) => {
        if(!tickets)return;

        history.replace( {pathname: location.pathname, search: new URLSearchParams({ticket: TicketID}).toString() } )

        setSelectedReqID(TicketID);
        const selectedReq = tickets.find( req => req.TicketID === TicketID);
        const messages = selectedReq;
        setShowProfile(false);

        const preparedMessage = prepareMessage(messages.Messages[latestMessageIndex(messages.Messages)], selectedReq);

        setSelectedReq(preparedMessage);
        setMessages(messages);

        setInboxActiveComponent(SHOW_COMPONENT.MESSAGE); // ---
    }

    function latestMessageIndex(Messages) {
        if(Messages.length === 0) return 0;
        return Messages.length - 1;
    }

    const closeReplyModalHandler = () => {
        setShowReplyModal(false);
    }

    const viewTenantProfileHandler = async () => {
        setLoading(true);
        setShowProfile(true);

        const getTenantUserID = tickets.find( ticket => ticket.TicketID === selectedReqID)?.Tenant?.UserID;

        if (getTenantUserID) {
            const response = await getTenantProfile(token, getTenantUserID);

            if (response.success) {
                const defaultData = {
                    Employments: [],
                    FormerLandlords: [],
                    PreviousEvictions: [],
                    ...response.data
                }

                Object.entries(defaultData).forEach(([key, value]) => {
                    if ( value === null || value === undefined  ) {
                        if(key === 'Employments' || key === 'FormerLandlords' || key === "PreviousEvictions") {
                            defaultData[key] = [];
                        } else {
                            defaultData[key] = '';
                        }
                    }
                })
    
                const { Employments } = defaultData;
    
                if (Employments.length) {
                    const preparedEmployments = Employments && Employments.map( (employment) => {
                        const { Description, Duration, Employer, IsCurrentPoistion, Location, Position } = employment;
                        return { 
                            Description, 
                            Duration, 
                            Employer, 
                            IsCurrentPoistion, 
                            Location, 
                            Position, 
                            JobStart: splitJobDuration(Duration).JobStart,
                            JobEnd: splitJobDuration(Duration).JobEnd
                        }
                    })
    
                    defaultData.Employments = preparedEmployments;
                }

                setTenantData(defaultData);
            } else {
                ErrorPopup();
                setError(error);
            }
            const doesProfileExists = (response.data.City !== null);

            setDoesProfileExists(doesProfileExists);
        }
        
        setLoading(false);
    }

    function splitJobDuration (duration) {
        if(!duration || typeof duration !== 'string') return {JobStart: '', JobEnd: ''};
        const splittedTime = duration.split('-');
        if(splittedTime.length <= 1) return {JobStart: '', JobEnd: ''};
        let jobStart = splittedTime[0].trim();
        let jobEnd = splittedTime[1].trim();

        return {JobStart: jobStart.trim(), JobEnd: jobEnd.trim()};
    }

    const closeTenantProfileModal = () => {
        setShowProfile(false);
    }

    const prepareMessages = (data) => {
        if(!data) return null;
        const messages = data.Messages;

        const prepareData = messages && messages.map( (message) => prepareMessage(message, data));

        return prepareData;
    }

    const prepareMessage = (message, data) => {
        if(!message) return null;

        const {MessageID, Text, TimeStamp, UserID, Type} = message;

        const prepareData = {
            MessageID,
            from: getMemberData(UserID)[0],
            fromUserType: getMemberData(UserID)[1],
            date: convertDateToMs(TimeStamp),
            message: Text,
            Type,
            availableDates: extractData(Text).extractedAvailableDates,
        }

        return prepareData;

        function extractData (data) {
            const extractedMessage = splitMessage(data)[0];
            const extractedAvailableDates = splitMessage(data)[1];

            let preparedAvailableDates = null;
            if (extractedAvailableDates) {
                preparedAvailableDates = extractedAvailableDates.split('|');
            }

            return {
                extractedMessage,
                extractedAvailableDates: preparedAvailableDates
            }
        }

        function splitMessage (message) {
            return message.split('$*DATES*$');
        }

        function getMemberData(memberID) {
            if(!memberID) return [];

            const members = data.Members;
            if(!members) return [];
            if(!members[memberID]) return [];

            const {FirstName, LastName, UserType} = members[memberID];

            return [`${FirstName.trim()} ${LastName.trim()}`, UserType];
        }
    }

    const [ managerPhoneNumber, setManagerPhoneNumber ] = useState(null);

    const getBuildingInfoHandler = async () => {
        const response = await getBuilding(buildingID, token);
        const { success, data } = response;

        if (success) {
            setBuildingInfo(data);
        }

        //TODO: add modal on fail
    }

    useEffect(() => {
        prepareMessages(messages);
    }, [messages])

    useEffect(() => {
        if (!buildingInfo || (buildingInfo?.BuildingID !== buildingID)) {
            getBuildingInfoHandler();
        } else {
            const phoneNumber = buildingInfo?.Manager?.PhoneNumber;
            
            if (phoneNumber) {
                setManagerPhoneNumber(phoneNumber);
            } else {
                setManagerPhoneNumber(null);
            }
        }

        getUnitTicketsHandler();

    }, [])

    const [ isInfoCollapsed, setIsInfoCollapsed ] = useState(true);

    let MessageButtons = (
        <>
            <Col xs={4} className="m-0 mb-2 mb-md-0 pe-1 pe-md-2 p-0 d-flex justify-content-center justify-content-md-end">
                <ButtonPrimary 
                    // title="Show More Information"
                    onClick={ () => setIsInfoCollapsed( prevVal => !prevVal ) }
                    // TODO: add state that will control unit information 
                >

                    <i className="d-block d-md-none bi bi-info-circle"></i>
                    <p className='d-none d-md-block m-0 p-0 fs-6 fw-bold text-light'>{ isInfoCollapsed ? "Show More Information" : "Hide More Information" }</p>
                    
                </ButtonPrimary>
            </Col>

            <Col xs={4} className="m-0 mb-2 mb-md-0 px-1 p-0 d-flex justify-content-center justify-content-md-end">
                <ButtonPrimary 
                    // title="View Tenant Profile"
                    onClick={viewTenantProfileHandler}
                >

                    <i className="d-block d-md-none bi bi-person-lines-fill"></i>
                    <p className='d-none d-md-block m-0 p-0 fs-6 fw-bold text-light'>View Tenant Profile</p>

                </ButtonPrimary>
            </Col>

            <Col xs={4} className="m-0 mb-2 mb-md-0 px-1 p-0 d-flex justify-content-center justify-content-md-end">
                <ButtonPrimary 
                    // title="View Tenant Profile"
                    onClick={() => {
                        if(messages && !messages.Status) return;
                        setShowReplyModal(true)
                    }} 
                    disabled={messages && !messages.Status}
                >

                    <i className="d-block d-md-none bi bi-reply"></i>
                    <p className='d-none d-md-block m-0 p-0 fs-6 fw-bold text-light'>Reply to Request</p>

                </ButtonPrimary>
            </Col>
        </>
    )

    const setTicketResponse = (ticketID, ticketData) => {
        setMessages(ticketData);

        setTickets( prevTickets => prevTickets.map(prevTicket => {
                if(prevTicket.TicketID === ticketID) {
                    return ticketData;
                }
                return prevTicket;
            })
        );

        const preparedMessage = prepareMessage(ticketData.Messages[latestMessageIndex(ticketData.Messages)], ticketData);
        setSelectedReq(preparedMessage);
    }

    if(!selectedReq) MessageButtons = null;

    return (
        <>
            <Container fluid style={{maxWidth: 1200}} className="m-auto">
                <BackNav className="m-0 p-0 mb-3"
                        currentPage="Tour Request"
                        pages={[{
                            title: "properties",
                            goTo: "/properties"
                        },
                        {
                            title: "property requests",
                            goTo: `/building-info?building-id=${buildingID}&active-tab=requests`
                        } 
                        ]}
                />
            </Container>

            <Container fluid className="p-3 py-0 py-md-3 m-auto">
                <Inbox
                    activeComponent={inboxActiveComponent}
                    setActiveComponent={setInboxActiveComponent}

                    // historyComponent={
                    //     <MessageHistory 
                    //         messages={prepareMessages(messages)}
                    //         selectedMessageID={selectedMessageID}
                    //         selectMessageHandler={selectMessageHandler}
                    //         isLoading={isLoading}    
                    //     />
                    // }


                    messageComponent={
                        <MessageContainer 
                            showHeader={true}
                            unitID={unitID}
                            selectedReq={selectedReq}
                            buttons={MessageButtons}
                            UnitInfo={unitInfo}
                            isLoading={isLoading}
                            isInfoCollapsed={isInfoCollapsed}
                            messages={prepareMessages(messages)}
                            // selectedMessageID={selectedMessageID}
                        />
                    }
                    messagesComponent={
                        <MoreRequests
                            tickets={tickets}
                            selectedReqID={selectedReqID}
                            reqClickHandler={reqClickHandler}
                            isLoading={isLoading}
                        />
                    }
                />
            </Container>

            <ResponseModal 
                show={showReplyModal} 
                unitID={unitID} 
                selectedReq={selectedReq} 
                closeHandler={closeReplyModalHandler}
                ticketID={selectedReqID}
                token={token}
                setMessages={setMessages}
                unitInfo={unitInfo}
                messages={messages}
                managerPhoneNumber={managerPhoneNumber}
                setTicketResponse={setTicketResponse}
                // disableTicket={disableTicket}
            />

            <TenantProfileModal 
                positions={positions}
                setPositions={setPositions}
                evictions={evictions}
                setEvictions={setEvictions}
                formerLandlords={formerLandlords}
                setFormerLandlords={setFormerLandlords}
                doesProfileExists={doesProfileExists}

                show={showProfile} 
                closeHandler={closeTenantProfileModal} 
                loading={loading}
            />
        </>
    )
}
