import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    units: [],
    buildings: [],
    cities: [],
    states: [],
    accessibilities: [],
    amenities: []
}

export const marketplaceSlice = createSlice({
    name: 'marketplaceStore',
    initialState,
    reducers: {
        setUnits(state, action) {
            state.units = action.payload
        },
        setBuildings(state, action) {
            state.buildings = action.payload
        },
        setCities(state, action) {
            state.cities = action.payload
        },
        setStates(state, action) {
            state.states = action.payload
        },
        setAccessibilities(state, action) {
            state.accessibilities = action.payload
        },
        setAmenities(state, action) {
            state.amenities = action.payload
        },
        clearMarketplaceStore(state) {
            state.units = [];
            state.buildings = [];
            state.cities = [];
            state.states = [];
            state.accessibilities = [];
            state.amenities = [];
        }
    }
});

export const marketplaceActions = marketplaceSlice.actions;
export default marketplaceSlice.reducer;
