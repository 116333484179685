import React from "react";

import {
    Container,
} from "react-bootstrap";

import Navigation from "../components/Shared/Navigation";

export default function MyTenants() {
    return (
        <Container fluid className="m-0 p-5 pt-3 w-100">
            <Navigation />

            <Container fluid className="m-0 mt-3 p-0 w-100">
                <h5>Tenants</h5>
            </Container>

        </Container>
    );
}