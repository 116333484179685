import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import "./custom.scss";
import * as Sentry from "@sentry/react";
import Header from "./components/Shared/Header";

import Home_NEW from "./Pages/Home_NEW";
import Signup from "./Pages/Signup_NEW";
import SignupDetails from "./Pages/SignupDetails_NEW";
import Login from "./Pages/Login_NEW";
import Properties from "./Pages/Properties";
import AddNewUnit from "./Pages/AddNewUnit";
import AddNewBuildingForm from "./Pages/AddNewBuildingForm";
import BuildingUpdate from "./Pages/BuildingUpdate";
import UnitUpdate from "./Pages/UnitUpdate"
import Tenant from "./Pages/Tenant"
import LL_TourRequests from "./Pages/LL_TourRequests";
import Marketplace from "./Pages/Marketplace";
import Requests from "./Pages/PropertiesRequests";
import AddNewUnitForm from "./Pages/AddNewUnitForm";
import PrivateRoute from "./components/routing/PrivateRoute";
import ChangePassword from "./Pages/ChangePassword";
import AuthContextProvider from "./contexts/AuthContext";
import UserTypeContextProvider from "./contexts/UserTypeContext";
import BuildingContextProvider from "./contexts/BuildingContext";
import TicketsContextProvider from "./contexts/TicketsContext";
import CreatePortfolioContextProvider from './contexts/CreatePortfolioContext';
import BuildingsContextProvider from "./contexts/BuildingsContext";
import PasswordReset from "./Pages/PasswordReset";
import RequestPasswordReset from './Pages/RequestPasswordReset_NEW';
import BuildingInfo from "./Pages/BuildingInfo";
import MyTenants from './Pages/MyTenants';
import TView_ShowRequest from './Pages/TView_ShowRequest';
import ManagerProfile from "./Pages/ManagerProfile";
import TenantScreening from "./Pages/TenantScreening";
import Settings from "./Pages/Settings";
import AboutUs from "./Pages/AboutUs";
import ExistingProperties from "./Pages/ExistingProperties";
import ScrollToTop from './utils/ScrollToTop';
import StyleGuide from "./Pages/StyleGuide";
import ScrollToTopButton from "./components/Shared/ScrollToTopButton";
import NewMessages from "./Pages/NewMessages";
import Favorites from "./Pages/Favorites";
import CommandCenter from "./Pages/CommandCenter";
import Client from "./Pages/Client";
// import Team from "./Pages/Team";

import ImportUnits from './Pages/ImportUnits';


function App() {
  // hotjar.initialize(3240071, 6);
  return (
    <CreatePortfolioContextProvider>
      <UserTypeContextProvider>
        <AuthContextProvider>
          <BuildingContextProvider>
            <BuildingsContextProvider>
              <TicketsContextProvider>
                <Router>
                  <Header />
                  <ScrollToTop>
                    <Switch>
                      <Route exact path="/" component={Home_NEW} />
                      <Route path="/password-reset" component={PasswordReset} />
                      <Route path="/signup" component={Signup} />
                      <Route path="/signup-details/:accountStatusKey?" component={SignupDetails} />
                      <Route path="/login" component={Login} />
                      <Route path="/marketplace" component={Marketplace} />
                      <Route path="/password-forgot" component={RequestPasswordReset} />
                      <Route path="/about-us" component={AboutUs} />
                      <Route path="/hidden-style-guide" component={StyleGuide} />

                      {/* <PrivateRoute path="/team" component={Team} users={["Landlord", "Manager", "Tenant", "CaseManager"]} /> */}
                      <PrivateRoute path="/messages" component={NewMessages} users={["Landlord", "Manager", "Tenant"]} />
                      <PrivateRoute path="/change-password" component={ChangePassword} users={["Landlord", "Manager", "Tenant"]} />
                      <PrivateRoute path="/settings" component={Settings} users={["Landlord", "Manager", "Tenant"]} />
                      <PrivateRoute path="/add-new-unit" component={AddNewUnitForm} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/favorites" component={Favorites} users={["Landlord", "Manager", "Tenant", "CaseManager"]} />
                      <PrivateRoute path="/add-new-unit-to-existing-property" component={ExistingProperties} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/add-new-building" component={AddNewBuildingForm} users={["Landlord"]} />
                      <PrivateRoute path="/properties" component={Properties} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/requests" component={Requests} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/tourRequest/:buildingID/unit/:unitID" component={LL_TourRequests} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/tenant" exact component={() => <Tenant editable={false} isTenant={false} />} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/tenants" component={MyTenants} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/add" component={AddNewUnit} users={["Landlord"]} />
                      <PrivateRoute path="/building-info" component={BuildingInfo} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/unit-update" component={UnitUpdate} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/building-update" component={BuildingUpdate} users={["Landlord", "Manager"]} />
                      <PrivateRoute path="/tenant-screening" component={TenantScreening} users={["Tenant"]} />
                      <PrivateRoute path="/portfolio" component={Tenant} users={["Tenant"]} />
                      <PrivateRoute path="/request" component={TView_ShowRequest} users={["Tenant"]} />
                      <PrivateRoute path="/manager-profile" exact component={ManagerProfile} users={["Manager"]} />
                      <PrivateRoute path="/command-center" exact component={CommandCenter} users={["CaseManager"]} />
                      <PrivateRoute path="/clients" exact component={Client} users={["CaseManager"]} />

                      <PrivateRoute path="/import-units" component={ImportUnits} users={["Landlord", "Manager"]} />

                      <Route component={Home_NEW} />
                    </Switch>
                  </ScrollToTop>
                  <ScrollToTopButton />
                </Router>
              </TicketsContextProvider>
            </BuildingsContextProvider>
          </BuildingContextProvider>
        </AuthContextProvider>
      </UserTypeContextProvider>
    </CreatePortfolioContextProvider>
  );
}

export default Sentry.withProfiler(App);
