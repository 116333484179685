import React from 'react';
import {
    Col,
    Row,
    Button
} from 'react-bootstrap';

import StreetViewWrapper from './StreetViewWrapper';

/**
 * 
 * @param {Object} position { lat: Number, lng: Number }
 */
export default React.memo(function StreetViewCard({
    onClose,
    buildingData,
    renderCloseBtn = true
}) {
    const getLatLng = (buildingData) => {
        if (!buildingData) return null;
        const lat = buildingData.Latitude;
        const lng = buildingData.Longitude;
        if (!(lat && lng)) return null;

        return {
            lat,
            lng
        }
    };

    return (
        <Row className="m-0 p-0 h-100 flex-column">
            {renderCloseBtn && (
                <Col xs={12} className="m-0 p-0">
                    <Button className="m-0 px-3 py-1 d-flex justify-content-center align-items-center rounded"
                        onClick={onClose}
                    >
                        <i className="bi bi-arrow-left pe-2 fs-6"></i>
                        <h6 className="m-0 p-0">Close Street View</h6>
                    </Button>
                </Col>
            )
            }
            <Col xs={12} className={`m-0 p-0 ${renderCloseBtn ? 'mt-3' : ''} flex-grow-1`} style={{ minHeight: 300 }}>
                {getLatLng(buildingData) ? (
                    <StreetViewWrapper position={getLatLng(buildingData)} mapPov={buildingData.StreetPOV} />
                ) : (
                    <Row className='m-0 p-0 h-100 darkerGray justify-content-center align-items-center'>
                        <h6 className='text-center'>Couldn't get building coordinates</h6>
                    </Row>
                )
                }
            </Col>
        </Row>
    )
}
    , (prev, next) => {
        return prev.buildingData && prev.buildingData.BuildingID && next.buildingData && next.buildingData.BuildingID && prev.buildingData.BuildingID === next.buildingData.BuildingID
    }
)