import React from 'react';
import {
    Container,
    Card,
    Row,
    Col
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

import LazyImage from '../Shared/LazyImage';

// TODO: FIX IMAGE DISPLAYING (RESPONSIVE)

export default function ExistingProperty({unit}) {
    return (
        <Container fluid className="m-0 p-0 ">
            {/* TODO Remove default Link styles for Card, add Hover Effect/Action */}
                <Card className="m-0 p-0 border-0 width-1-1 lightGray">
                    <Card.Body className="m-0 p-0 overflow-hidden rounded">

                        <Row className="m-0 p-0 d-block d-md-none rounded overflow-hidden">
                            <Col className="m-0 p-0" style={{ height: 200, width: "100%" }} >
                                <LazyImage src={unit?.Photos?.[0]?.URL} style={{ objectFit: "cover", height: 200, width: "100%" }} />
                            </Col>
                        </Row>

                        <Row className="m-0 p-0">
                            <Col xs="auto" className="m-0 p-0 d-none d-md-block rounded overflow-hidden" style={{ height: 200, width: 300 }}>
                                {/* Correct src for image: units[unitsIndex].imagePath */}
                                <LazyImage src={unit?.Photos?.[0]?.URL} style={{ objectFit: "cover", height: 200, width: 300 }} />
                            </Col>
                            <Col className="m-0 p-0 px-2 px-sm-4">
                                <Row className="m-0 p-0 d-flex align-self-center py-3">
                                    <h5 className="m-0 p-0 fw-bold" >
                                        {unit.Name}
                                    </h5>
                                    <h6 className="m-0 p-0 mt-2">
                                        <span className="text-muted"><i className="bi bi-geo-alt"></i> {unit.Address.replace(/\$\*\|\*\$/, ' ')}, {unit.City}, {unit.State}</span>
                                    </h6>
                                </Row>
                                <Row className="m-0 p-0 mb-3 justify-content-center">
                                    <Col className="m-0 p-0 mb-3 mb-sm-0 pe-sm-1" xs={12} sm={6}>
                                        <Link to={{ pathname: `/add-new-unit`, search: `?building-id=${unit.BuildingID}` }}>
                                            <ButtonPrimary
                                                title="Add Single Unit"
                                                type="button"
                                            />
                                        </Link>
                                    </Col>

                                    <Col className="m-0 p-0 mb-3 mb-sm-0 ps-sm-1" xs={12} sm={6}>
                                        <Link to={{ pathname: `/import-units`, search: `?building-id=${unit.BuildingID}` }}>
                                            <ButtonPrimary
                                                title="Import Units From File"
                                                type="button"
                                            />
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
        </Container>
    )
}