import React from 'react';
import {
    Modal
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import StreetViewPicker from '../Shared/StreetView/StreetViewPicker';


export default function StreetViewPositionModal({
    openStreetViewModal,
    setOpenStreetViewModal,
    streetViewPos,
    setSVData,
    setSVSuccess,
    svSuccess,
    handleSVsubmit
}) {
    return (
        <Modal show={openStreetViewModal} onHide={() => setOpenStreetViewModal(false)} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Set up camera so that your building is best represented </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StreetViewPicker
                    pos={streetViewPos}
                    setData={setSVData}
                    setSuccess={setSVSuccess}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary onClick={() => setOpenStreetViewModal(false)} additionalClassName="" className="rounded-2">
                    Cancel
                </ButtonPrimary>
                {
                    svSuccess ?
                        (
                            <ButtonPrimary onClick={handleSVsubmit} className="rounded-2">
                                Done
                            </ButtonPrimary>
                        )
                        :
                        (
                            <>
                                <ButtonPrimary onClick={handleSVsubmit} additionalClassName="text-bg-danger" style={{
                                    borderColor: "var(--danger)"
                                }} className="rounded-2">
                                    Continue Without Streetview
                                </ButtonPrimary>
                            </>
                        )
                }
            </Modal.Footer>
        </Modal>
    )
}
