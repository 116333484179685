import React from 'react'
import {
    Row,
    Col,
} from 'react-bootstrap';

import MultiCarouselLoader from '../MultiCarouselLoader';
import ButtonWithLeftBorder from '../../Shared/Buttons/ButtonWithLeftBorder';

export default function Header() {
    return (
        <Row className="m-0 p-0">
            <Col className="m-1 m-md-0 p-0 overflow-hidden d-flex flex-column">
                <Row className="m-0 p-0">
                    <Col xs={12} className="m-0 p-0">
                        <h3 className="mx-0 px-0 fw-bold darkerGray rounded skeleton w-25">&nbsp;</h3>
                    </Col>

                    <Col xs={12} className="m-0 p-0">
                        <h6 className="mx-0 px-0 darkerGray rounded skeleton w-50">&nbsp;</h6>
                    </Col>
                </Row>
                <MultiCarouselLoader />
            </Col>

            <Col md={"auto"} className="d-none d-md-flex m-0 p-0 ps-2 flex-row flex-wrap flex-md-column align-items-center justify-content-around justify-content-sm-center justify-content-md-start">

                <div className="m-0 p-0" style={{minWidth: 190}}>
                  
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title=""
                        iconClass=""
                        additionalButtonClass="ps-3 darkerGray skeleton"
                    />
                  
                </div>

                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title=""
                        iconClass=""
                        additionalButtonClass="ps-3 darkerGray skeleton"
                    />
                </div>

                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title=""
                        iconClass=""
                        additionalButtonClass="ps-3 darkerGray skeleton"
                    />
                </div>

                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title=""
                        iconClass=""
                        additionalButtonClass="ps-3 darkerGray skeleton"
                    />
                </div>

            </Col>
        </Row>
    )
}
