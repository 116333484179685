import http, { deleteRequest } from './http-common';

const uploadFiles = async (filesData, url, token) => {
    const preparedData = filesData.map(({ src, id, fileName, fileSize, description }) => {
        return {
            src, id, fileName, fileSize, description
        }
    })

    const dataJson = JSON.stringify(preparedData);

    try {
        const response = await http.post(url, dataJson, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        )
        return { success: true, data: response };
    } catch (err) {
        return { success: false, data: err };
    }
}

const deleteFiles = async (DocumentIDs, BuildingID, token) => {
    const URL = `building/document/${BuildingID}`;
   
    return await deleteRequest(URL, DocumentIDs, token)
}

export { uploadFiles, deleteFiles };