import React from 'react';

import UploadImage from "../Shared/UploadImage";


export default function ImagesFields({
    images,
    setImages,
    downloadedImages,
    imagesToDelete,
    setImagesToDelete,
    isUpdate,
    isForm=false
}){
    const setUploadImageAttributes = () => {
        const createBuildingAttributes = {
            images,
            setImages
        }

        const updateBuildingAttributes = {
            ...createBuildingAttributes,
            downloadedPictures: downloadedImages,
            imagesToBeDeleted: imagesToDelete,
            setImagesToBeDeleted: setImagesToDelete
        }

        return isUpdate ? updateBuildingAttributes : createBuildingAttributes;
    }

    return (
        <UploadImage
            {...(setUploadImageAttributes())}
            isProperty={true}
            isForm={isForm}
        />
    )
}
