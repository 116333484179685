import React, { useState } from 'react'
import {
    Row,
    Col
} from 'react-bootstrap';
import { Link } from "react-router-dom";

import MultiCarousel from './MultiCarousel';
import ButtonWithLeftBorder from '../Shared/Buttons/ButtonWithLeftBorder';
import preparePhotos from '../../utils/preparePhotos';


export default function Header({
    property,
    buildingID,
    deleteBuilding,
    userType,
    openBuildingPreview
}) {
    const [expandOptions, setExpandOptions] = useState(false);

    const toggleExpandOptions = () => {
        setExpandOptions( prevState => !prevState);
    }

    const getFavoritePhoto = (property) => {
        if(!property || !property.Photos) return;
        const photoIndex = property.Photos.findIndex( photo => photo.IsFavorite);
        return photoIndex;
    }

    const pictures = preparePhotos(property);

    return (
        <Row className="m-0 p-0">
            <Col className="m-1 m-md-0 p-0 overflow-hidden d-flex flex-column">
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0" xs={12}>
                        <h3 className="mx-0 px-0 fw-bold">
                            {property ? property.Name : <>&nbsp;</>}
                        </h3>
                    </Col>
                    <Col className="m-0 p-0" xs={12}>
                        <h6 className="mx-0 px-0">
                            { property ? (
                            <span className="text-muted"><i className="bi bi-geo-alt"></i> {property.Address.replace(/\$\*\|\*\$/, ' ')}, {property.City}, {property.State}</span>
                            ) : <>&nbsp;</>}
                        </h6>
                    </Col>
                </Row>


                <MultiCarousel 
                    images={property && pictures}
                    favoritePhotoIndex={getFavoritePhoto(property)}
                    data={property}
                />
                
            </Col>

            {/* <Col xs={12} className="m-0 p-0 d-md-none">
                <Button
                    bsPrefix="darkerGray"
                    variant="secondary"
                    className="p-2 ps-3 mt-2 on-hover-bold w-100 rounded"
                    onClick={toggleExpandOptions}
                >
                    <i className={`bi ${expandOptions ? 'bi-arrows-collapse' : 'bi-arrows-expand'} pe-2`}></i>{expandOptions ? 'Hide Options' : 'Show Options'}
                </Button>
            </Col> */}

            <Col md={"auto"} className="d-none d-md-flex m-0 p-0 ps-2 flex-row flex-wrap flex-md-column align-items-center justify-content-around justify-content-sm-center justify-content-md-start">

                <div className="m-0 p-0" style={{minWidth: 190}}>
                    <Link to={{
                        pathname: "/add-new-unit",
                        search: `?building-id=${buildingID}`
                    }}>
                        <ButtonWithLeftBorder 
                            showIcon={true}
                            title="Add New Unit"
                            iconClass="bi-plus-lg"
                            additionalButtonClass="border-right-green text-start ps-3"
                        />
                    </Link>
                </div>

                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <Link to={{
                        pathname: "/import-units",
                        search: `?building-id=${buildingID}`
                    }}>
                        <ButtonWithLeftBorder 
                            showIcon={true}
                            title="Import Units"
                            iconClass="bi-file-earmark-arrow-up-fill"
                            additionalButtonClass="border-right-green text-start ps-3"
                        />
                    </Link>
                </div>
               
                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <Link to={{
                        pathname: "/building-update",
                        search: `?building-id=${buildingID}`
                    }}>
                        <ButtonWithLeftBorder 
                            showIcon={true}
                            title="Edit Property"
                            iconClass="bi-pencil-fill"
                            additionalButtonClass="border-right-blue text-start ps-3"
                        />
                    </Link>
                </div>

                <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        onClick={openBuildingPreview}
                        title="Preview"
                        iconClass="bi-aspect-ratio"
                        additionalButtonClass="border-right-blue text-start ps-3"
                    />
                </div>

                { userType === "Landlord" && (
                    <div className="m-0 p-0 mt-2 ms-sm-2 ms-md-0" style={{minWidth: 190}}>

                        <ButtonWithLeftBorder 
                            onClick={() => deleteBuilding(buildingID)}
                            showIcon={true}
                            title="Delete Property"
                            iconClass="bi-x-lg"
                            additionalButtonClass="border-right-red text-start ps-3"
                        />

                    </div>
                )}

            </Col>

            <Col xs={12} className={` ${expandOptions ? 'd-flex' : 'd-none'} d-md-none m-0 p-0 px-2 flex-column bg-white`}>

                <Link to={{
                    pathname: "/add-new-unit",
                    search: `?building-id=${buildingID}`
                }}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title="Add New Unit"
                        iconClass="bi-plus-lg"
                        additionalButtonClass="border-right-green text-start ps-3 m-0 mt-2"
                    />
                </Link>

                <Link to={{
                    pathname: "/building-update",
                    search: `?building-id=${buildingID}`
                }}>
                    <ButtonWithLeftBorder 
                        showIcon={true}
                        title="Edit Property"
                        iconClass="bi-pencil-fill"
                        additionalButtonClass="border-right-blue text-start ps-3 m-0 mt-2"
                    />
                </Link>

                <ButtonWithLeftBorder 
                    showIcon={true}
                    onClick={openBuildingPreview}
                    title="Preview"
                    iconClass="bi-aspect-ratio"
                    additionalButtonClass="border-right-blue text-start ps-3 mt-2"
                />

                { userType === "Landlord" && (
                    <ButtonWithLeftBorder 
                        onClick={() => deleteBuilding(buildingID)}
                        showIcon={true}
                        title="Delete Property"
                        iconClass="bi-x-lg"
                        additionalButtonClass="border-right-red text-start ps-3 m-0 my-2"
                    />
                )}

            </Col>
        </Row>
    )
}
