import { object as YupObject, string as YupString, ref as YupRef } from "yup";

export default YupObject().shape({
    newPassword: YupString()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    passwordConfirmation: YupString()
      .oneOf([YupRef("newPassword"), null], "Passwords must match")
      .required("Please confirm password"),
});
