import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";

import { managerDataActions } from "../store/ManagerDataSlice";
import { propertiesDataActions } from "../store/PropertiesSlice";
import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { getAllBuildings } from '../services/building';
import ExistingProperty from '../components/AddNewUnit/ExistingProperty';
import ExistingPropertyLoader from '../components/AddNewUnit/Loader';
import BackNav from '../components/Shared/BackNav';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';

export default function AddNewUnit() {
    const { token } = useContext(AuthContext);
    const cancelToken = useMemo(() => axios.CancelToken.source(), []);

    const dispatch = useDispatch();
    const propertiesStore = useSelector(store => store.propertiesStore.properties);
    const wereAllPropertiesFetched = useSelector(store => store.propertiesStore.wereAllPropertiesFetched);

    const {userType} = useContext(UserTypeContext);
    const [loading, setLoading] = useState(false);

    const getAllBuildingsHandler = useCallback(async () => {
        if ( wereAllPropertiesFetched ) return;
        
        setLoading(true);
    
        const settings = {
          cancelToken: cancelToken.token,
        }
    
        const response = await getAllBuildings(token, settings);
        const { success, data } = response;
    
        if(success) {
          const prepareProperties = extrudeProperties(data);
    
          if(userType === "Landlord") {
            const extrudedManagerData = extrudeManagersData(prepareProperties);
    
            const prepareManagerData = Object.entries(extrudedManagerData).map(([_, managerData]) => managerData);
            dispatch(propertiesDataActions.setProperties(prepareProperties));
            dispatch(managerDataActions.setLandlordsInformations(prepareManagerData));
            dispatch(propertiesDataActions.setFetchedAllProperties(true));
          }
        } else {
          ErrorPopup()
        }
        setLoading(false);
    
      }, [token, cancelToken, dispatch, userType]);

      const extrudeProperties = (properties) => {
        if (!properties) return null;
        if (Array.isArray(properties)) return properties;
    
        const extrudedProperties = Object.entries(properties).map( ([key, value]) => {
          if(!value) return null;
          return value.map( property => ({
          ...property,
          LandlordEmail: key 
        }))} ).flat();
        if(!extrudedProperties) return [];
        if(extrudedProperties[0] === null) return [];
    
        return extrudedProperties;
      }

      const extrudeManagersData = (properties) => {
        if(!properties || !Array.isArray(properties)) return {};
    
        return properties.reduce((acc, property) => {
          if(property.Managers) {
            const managersObject = property.Managers.reduce((acc, manager) => {
              return {
                ...acc,
                [manager.Email]: {
                  Email: manager.Email,
                  FullName: manager.FullName
                }
              }
            }, {});
            return {
              ...acc,
              ...managersObject
            }
          }
          return acc;
        }, {});
      }

    useEffect(() => {
        getAllBuildingsHandler();
    }, [getAllBuildingsHandler]);

    return (
        <Container fluid style={{maxWidth: 1200}} className="m-auto mb-5 p-0">

            <BackNav className="m-0 p-0 mb-3 px-2"
                    currentPage="Select Property"
                    pages={[{
                        title: "properties",
                        goTo: "/properties"
                    },
                    {
                        title: "add",
                        goto: "/add"
                    }]}
              />

            <Row className="m-0 p-0 w-100">
                <Col className="m-0 p-0" xs={12}>
                
                    {   loading && (
                            <Row className="p-0 m-0 px-1 ">
                                <ExistingPropertyLoader />
                                <ExistingPropertyLoader />
                            </Row>
                        )
                    }

                    {
                        propertiesStore && propertiesStore.map((item, index) => {
                            return (
                                <Row className="mb-3 mx-0 p-0 px-1" key={index}>
                                    <ExistingProperty unit={item} unitsIndex={index} unitID={item.UnitID} />
                                </Row>
                            )
                        })
                    }
                
                </Col>
            </Row>
        </Container>
    )
}
