import React, { useContext, useMemo } from "react";
import { Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";
import { UserTypeContext } from "../../contexts/UserTypeContext";
import SideNavigationButton from "./SideNavigationButton";

export default function GuardedNavLink ({
  to,
  title="Missing Title",
  allowedUserTypes,
  isSideNav,
  onClick,
  children,
  className='custom_link',
  icon,
  smallNav
}) {
    const { token } = useContext(AuthContext);
    const { userType } = useContext(UserTypeContext);

    const isAllowed = useMemo(() => {
        return token && allowedUserTypes.some( allowedUserType => allowedUserType === userType)
    }, [allowedUserTypes, token, userType]);

    return (
        <>
            { 
                (isAllowed && !isSideNav) && (
                    <Col className="m-0 p-0 h-100" xs={"auto"}>
                        <NavLink to={to} activeClassName="link_active" className={className}>
                            <div className="m-0 p-0 d-flex flex-column align-items-center justify-content-center">{ children || (
                                <>
                                    <i className={`bi ${icon}  header-animate-icon ${smallNav ? 'hide' : ''}`}></i>
                                    {title}

                                    {/* <i className={`bi ${icon} fs-4`}></i>
                                    {!smallNav && title} */}
                                </>
                            )}</div>
                        </NavLink>
                    </Col>
                )
            }

            {
                (isAllowed && isSideNav) && (
                    <SideNavigationButton
                        onClick={onClick}
                        title={title}
                        icon={icon}
                    >
                        { children }
                    </SideNavigationButton>
                )
            }
        </>
    )
}
