import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LazyImage from '../Shared/LazyImage';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

export default function UnitCardLoader() {
    return (
        <Row className="m-0 p-0 mb-2 bg-white rounded">

            <Col xs={6} sm={4}
                className="m-0 p-0 position-relative cursor-pointer" 
                style={{height: 200}}
            >
                <LazyImage
                    src={ null }
                    style={{ objectFit: "cover", height: 200, width: "100%" }}
                />
            </Col>

            <Col className="m-0 p-0 p-2 d-flex flex-column" xs={6} sm={8}>
                <Row className="m-0 p-0 align-items-center justify-content-between">
                    <Col className="m-0 p-0 me-2" xs={"auto"}>
                        <h5 className="m-0 p-0 fw-bold skeleton"><span className="opacity-0">Unit Name</span></h5>
                    </Col>
                    <Col className="m-0 p-0" xs={"auto"}>
                        <h5 className="m-0 p-0 fw-bold skeleton"><span className="opacity-0">Rent: $---</span></h5>
                    </Col>
                </Row>

                <Row className="m-0 p-0 py-1">
                    <p className="m-0 p-0 skeleton" style={{fontSize: '0.85rem', width: 100}}>
                    </p>
                </Row>

                {/* AMENITIES AND ACCESSIBILITIES */}
                <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 d-flex" xs={12}>
                        <p className="m-0 p-0 mb-1 pe-1 fs-6 skeleton w-100" style={{ color: 'inherit' }}>
                            <b className="opacity-0">Amenities: </b>
                        </p>
                       
                        
                        </Col>


                    </Row>

                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 d-flex" xs={12}>
                        <p className="m-0 p-0 pe-1 fs-6 skeleton w-100" style={{ color: 'inherit' }}>
                            <b className="opacity-0">Accessibilities: </b>
                        </p>
                       
                        
                        </Col>


                    </Row>
                </Row>

                <Row className="m-0 p-0 mt-2 flex-fill align-items-end justify-content-between">

                    <Col className="m-0 p-0" xs={"auto"}></Col>

                    <Col className="m-0 p-0" xs={"auto"}>
                        <ButtonPrimary 
                            additionalClassName="skeleton border-light"
                        >
                            <p className="m-0 p-0" style={{opacity: 0}}>Show more information</p>
                        </ButtonPrimary>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}
