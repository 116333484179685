import { getRequest, postPutRequest, deleteRequest } from './http-common';

const getAllBuildings = async (token, settings) => {
    const URL = `user/buildings`;

    return await getRequest(URL, token, settings);
}

const getBuilding = async (buildingID, token) => {
    const URL = `building/${buildingID}`;

    return await getRequest(URL, token);
}

const getBuildingFeatures = async () => {
    const URL = `building/features`;

    return await getRequest(URL);
}

const postCreateBuilding = async (data, token) => {
    const URL = `user/building/upload`;

    return await postPutRequest(URL, data, token, 'post');
}

const updateBuildingInfo = async (buildingID, data, token) => {
    const URL = `building/${buildingID}/update/info`;

    return await postPutRequest(URL, data, token, 'post');
}

const updateBuildingFeatures = async (buildingID, data, token) => {
    const URL = `building/${buildingID}/update/features`;

    return await postPutRequest(URL, data, token, 'post');
}

const deleteBuilding = async (buildingID, token) => {
    const URL = `user/building/${buildingID}/delete`;

    return await postPutRequest(URL, {}, token, 'post');
}

const removeManager = async (buildingID, managerID, token) => {
    const URL = `building/${buildingID}/${managerID}`;

    return await deleteRequest(URL, '', token);
}

const updateFileDescription = async (buildingDocumentID, data, token) => {
    const URL = `building/${buildingDocumentID}/update/description`;

    return await postPutRequest(URL, data, token, 'put');
}

const setFavoriteBuildingPhoto = async (buildingID, photoID, token) => {
    const URL = `building/${buildingID}/photos/${photoID}/favorite`;

    return await postPutRequest(URL, {}, token, 'post');
}

export { 
    getAllBuildings,
    getBuilding, 
    getBuildingFeatures, 
    postCreateBuilding,
    updateBuildingInfo,
    updateBuildingFeatures,
    deleteBuilding,
    removeManager,
    updateFileDescription,
    setFavoriteBuildingPhoto
};
