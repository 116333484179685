import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isEmpty: true,
    BuildingAccessibilities: {},
    BuildingAmenities: {},
    UnitAccessibilities: {},
    UnitAmenities: {}
}

export const featuresSlice = createSlice({
    name: 'featuresSlice',
    initialState,
    reducers: {
        setFeatures(state, action) {
            state.isEmpty = false;
            state.BuildingAccessibilities = action.payload.BuildingAccessibilities;
            state.BuildingAmenities = action.payload.BuildingAmenities;
            state.UnitAccessibilities = action.payload.UnitAccessibilities;
            state.UnitAmenities = action.payload.UnitAmenities;
        }
    }
});

export const featuresActions = featuresSlice.actions;
export default featuresSlice.reducer;
