import { postPutRequest, getRequest } from './http-common';

const getSettings = async (token) => {
    const URL = 'user/settings';

    return await getRequest(URL, token);
}

const getSettingsForUserType = async (userType) => {
    const URL = `user/settings/${userType}`;

    return await getRequest(URL);
}

const changeSettings = async (data, token) => {
    const URL = `user/settings`;

    return await postPutRequest( URL, data, token, 'post');
}

export { 
    getSettings,
    getSettingsForUserType,
    changeSettings
};
