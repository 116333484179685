import React from 'react';
import {
    Row
} from 'react-bootstrap';

export default function StreetViewError({ message = "Couldn't connect to Google Maps. Try again later.", note="" }) {
    return (
        <Row className="m-0 p-0 h-100 justify-content-center align-items-center text-center">
            <div>
                <h3>{message}</h3>
                {
                    note
                        ?
                        (<h4 className='text-muted'>{note}</h4>)
                        :
                        <></>
                }
            </div>
        </Row>
    )
}
