const determineDate = (dateInMs) => {
    if(!dateInMs) return '';

    const currentTime = new Date().getTime();
    const prevTime = new Date(dateInMs);
    const timeDifference = currentTime - dateInMs;

    const day = 24*60*60*1000;

    // If less than 24h
    if ( timeDifference < day) {
        // return `${prevTime.getHours()}:${prevTime.getMinutes()}`;
        return prevTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else if ( timeDifference < 2*day ) {
        return 'Yesterday';
    } else if ( timeDifference < 7*day) {
        return `${Math.floor(timeDifference/day)} days ago`
    } else if ( timeDifference < 14*day) {
        return `1 week ago`
    } else if ( timeDifference < 31*day) {
        return `${Math.floor(timeDifference/(day*7))} week ago`
    } else if ( timeDifference < 60*day) {  //TODO: make it more accurate
        return `1 month ago`
    } else if ( timeDifference < 365*day) {
        return `${Math.floor(timeDifference/(day*30))} months ago`
    } else if ( timeDifference < 2 * 365*day) {
        return `One year ago`
    } else {
        return `${Math.floor(timeDifference/(day*30*12))} years ago`
    }
}

const determineHowLongAgo = (dateInMs) => {
    if(!dateInMs) return '';

    const currentTime = new Date().getTime();
    const timeDifference = currentTime - dateInMs;

    const hour = 60*60*1000;
    const day = 24*60*60*1000;

    if ( timeDifference < day) {
        return `${Math.round(timeDifference / hour)} hour${ timeDifference / hour <= 1? '' : "s" } ago`
    } else if ( timeDifference < 31*day) {
        return `${Math.floor(timeDifference/day)} day${ timeDifference / day <= 1? '' : "s" } ago`
    } else if ( timeDifference < 60*day) {  //TODO: make it more accurate
        return `1 month ago`
    } else if ( timeDifference < 365*day) {
        return `${Math.floor(timeDifference/(day*30))} months ago`
    } else if ( timeDifference < 2 * 365*day) {
        return `One year ago`
    } else {
        return `${Math.floor(timeDifference/(day*30*12))} years ago`
    }
}

const convertDateToMs = (date) => {
    const convertedDate = new Date(date).getTime();
    return convertedDate;
}

const formatDateUS = (dateInMs, dateStyle="full", withoutTime=false) => {
    let localeOptions = { dateStyle: dateStyle, timeStyle:"short", hour12: true };

    if(withoutTime) {
        localeOptions = { dateStyle: dateStyle, hour12: true };
    }

    return new Date(dateInMs).toLocaleString('en-US', localeOptions);
}

const getDateString = (dateInMs) => {
    return new Date(dateInMs).toLocaleDateString();
}

export { determineDate, convertDateToMs, formatDateUS, getDateString, determineHowLongAgo };
