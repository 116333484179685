import { getRequest, postPutRequest } from './http-common';

const searchUnit = async (data) => {
    const URL = `unit/search`;

    return await postPutRequest(URL, data, '', 'post');
}

const geoStates = async () => {
    const URL = `geo/states`;

    return await getRequest(URL);
}

const activeCities = async (state) => {
    const URL = `geo/${state}/active_cities`;

    return await getRequest(URL);
}

const getFeatures = async () => {
    const URL = 'unit/features/search';

    return await getRequest(URL);
}

export { 
    searchUnit,
    geoStates,
    activeCities,
    getFeatures
};
