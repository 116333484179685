import React, { useEffect, useState, useCallback } from 'react'

import {
    Row,
    Col,
    Container,
    Button
} from 'react-bootstrap';

export default React.memo(function BasicInfo({selectedReq, UnitInfo, classNameContainer, showButton=true, setCollapsed=true}) {
    const [address, setAddress] = useState(null);
    const [unitData, setUnitData] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const getAddress = useCallback (() => {
        if(!UnitInfo) return null;
        
        const Address = UnitInfo.Address;
        const City = UnitInfo.City;
        const State = UnitInfo.State;
        
        const prepareAddress = Address.replace('$*|*$', ' ');

        return {
            Address: prepareAddress,
            City,
            State
        }
    }, [UnitInfo]);

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState)
    }

    useEffect(() => {
        if(showButton === false) {
            setIsCollapsed(setCollapsed);
        }
    }, [setCollapsed, showButton]);

    useEffect(() => {
        if(UnitInfo) {
            setAddress(getAddress());
            setUnitData(UnitInfo);
        }
    }, [UnitInfo, getAddress]);

    return (
        <Container className="m-0 p-0 mb-3">
            { unitData && (
                <>
                    { showButton && <Row className={`m-0 p-0 row-cols-auto justify-content-start justify-content-between ${classNameContainer ? classNameContainer : ""}`}>
                    
                        <Col className="m-0 me-3 p-0" >
                            
                            <Button className="m-0 p-0 px-2 border-0 shadow-none w-100 text-start rounded" onClick={toggleCollapse}>
                                <p className={`m-0 p-0 text-light fs-6`}>{isCollapsed ? 'Show More Information' : 'Hide More Information'} <i className={`bi ${isCollapsed ? 'bi-arrow-down-short' : 'bi-arrow-up-short'}`}></i></p>
                            </Button>
                    
                        </Col>

                        <Col className="m-0 p-0 " >
                            <p className="m-0 p-0 d-inline fs-6" ><strong>Unit: </strong>{UnitInfo.Name}</p>
                        </Col>

                    </Row>}
                    
                    { !isCollapsed && (
                        <Row className={`d-flex m-0 ${ showButton ? "p-2" : "p-0"} px-0  pb-3 bg-light rounded-bottom row-cols-1 row-cols-md-auto justify-content-start justify-content-md-between ${classNameContainer ? classNameContainer : ""}`}>
                            
                            <Col className="m-0 p-0" xs={12}>
                                { unitData &&
                                    <p className="m-0 p-0 d-inline fs-6 "><span className="fw-bold">Property:</span> {unitData.BuildingName}</p>
                                }
                            </Col>
    
                            <Col className="m-0 p-0" xs={12}>
                                {   address &&
                                    <p className="m-0 p-0 d-inline fs-6"><span className="fw-bold">Address:</span> {address?.Address}{', ' + address?.City}{', ' + address?.State}</p>
                                }
                            </Col>
                            <Col className="m-0 p-0" xs={12}>
                        
                                <p className="m-0 p-0 d-inline fs-6" ><strong>Unit: </strong>{UnitInfo.Name} {unitData && '- '}
                                { unitData &&
                                    (`listed at $${unitData.Rent} per month, ${unitData.BedroomCount}BR/${unitData.BathroomCount}BA`)
                                }
                                </p>
                            
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </Container>

    )
});