import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from "react";
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import swal from 'sweetalert';
import { debounce, orderBy } from "lodash";

import { v4 as uuidv4 } from 'uuid';
import Bar from "../components/Shared/CaseManager/Bar";
import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";
import Swal from 'sweetalert2';
import { AuthContext } from "../contexts/AuthContext";
import TenantProfileModal from '../components/LL_TourRequests/TenantProfileModal';
import { getTenantProfile } from '../services/tenantProfile';
import { CreatePortfolioContext } from '../contexts/CreatePortfolioContext';
import AddClientModal from "../components/Clients/AddClientModal";
import LoaderWithBackdrop from "../components/Shared/LoaderWithBackdrop";
import { caseManagerActions } from '../store/CaseManagerSlice';
import { useSelector, useDispatch } from 'react-redux';
import { CLIENT } from '../components/Clients/AddClientModal';
import Loader from "../components/Shared/Loader";
import Backdrop from "../components/Shared/Backdrop";
import FiltersBtn from "../components/Shared/Filters/FiltersBtn";
import Filters, { FilterRow } from "../components/Shared/Filters";

import PortfolioModal from "../components/Clients/PortfolioModal";


import { inviteManager } from '../services/account';

import AccountExistsStatusModal from "../components/Shared/AccountExistsStatusModal";

import { STATES } from "../constants/Shared/states";

import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../constants/client';

import {    
    serviceGetGroups,
  } from '../services/group';

import {
    serviceGetClients,
    serviceAddClient,
    serviceEditClient,
    serviceDeleteClient
} from '../services/client';


const SORT_DIR = Object.freeze({
    ASC: 'asc',
    DESC: 'desc'
})
const MANAGER_STATUS_FIELDS = Object.freeze({
    ACCOUNT_EXISTS: 'AccountExists',
    ERROR: 'Error',
    INVITE_SENT: 'InviteSent'
});

export default function Client() {
    const { token } = useContext(AuthContext);
    const [showProfile, setShowProfile] = useState(false);

    const tableBarRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const {setTenantData} = useContext(CreatePortfolioContext);
    const [doesProfileExists, setDoesProfileExists] = useState(null);
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [editClientWithId, setEditClientWithId] = useState(null);

    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [isLoadingClients, setIsLoadingClients] = useState(false);

    const [searchPhrase, setSearchPhrase] = useState('');

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(SORT_DIR.ASC);
    const [activeFilters, setActiveFilters] = useState({
        Group: '',
        City: '',
        State: '',
        Verified: '',
        ClientType: '',
    });

    const dispatch = useDispatch();
    const clients = useSelector(store => store.caseManagerStore.clients);
    // console.log(clients);
    const groups = useSelector(store => store.caseManagerStore.groups);
    const wereClientsDownloaded = useSelector(store => store.caseManagerStore.wereClientsDownloaded);
    const wereNotificationsDownloaded = useSelector(store => store.caseManagerStore.wereNotificationsDownloaded);

    // TODO: change names!
    const [openManagersModal, setOpenManagersModal] = useState(false);
    const [managerAccountStatus, setManagerAccountStatus] = useState({});
    const [clientBasicDataForStatusModal, setClientBasicDataForStatusModal] = useState({});
    const closeManagerModalHandler = () => {
        setOpenManagersModal(false);
    }
    // -------------------

    const getClientsHandler = useCallback(async () => {
        if(wereClientsDownloaded) return;
        setIsLoadingClients(true);
        const { data, success } = await serviceGetClients(token);
    
        if(success) {
          dispatch(caseManagerActions.setClients(data));
          dispatch(caseManagerActions.setWereClientsDownloaded(true));
        } else {
          ErrorPopup();
        }
        setIsLoadingClients(false);
    
    }, [dispatch, token, wereClientsDownloaded]);

    const getGroupsHandler = useCallback(async () => {
        if(wereNotificationsDownloaded) return;
        setIsLoadingGroups(true);
        const { data, success } = await serviceGetGroups(token);
    
        if(success) {
          dispatch(caseManagerActions.setGroups(data));
          dispatch(caseManagerActions.setWereGroupsDownloaded(true));
        } else {
          ErrorPopup();
        }
        setIsLoadingGroups(false);
    
      }, [dispatch, token, wereNotificationsDownloaded]);

    const availableCities = useMemo(() => {
        if(!clients) return [];

        const onlyUniqueCities = new Set(clients.map( client => client.clientCity));
        return Array.from(onlyUniqueCities);
    }, [clients]);

    function splitJobDuration (duration) {
        if(!duration || typeof duration !== 'string') return {JobStart: '', JobEnd: ''};
        const splittedTime = duration.split('-');
        if(splittedTime.length <= 1) return {JobStart: '', JobEnd: ''};
        let jobStart = splittedTime[0].trim();
        let jobEnd = splittedTime[1].trim();

        return {JobStart: jobStart.trim(), JobEnd: jobEnd.trim()};
    }

    const openFilters = () => {
        setShowFilters(true);
    }
  
    const closeFilters = () => {
        setShowFilters(false);
    }

    const openAddClientModal = () => {
        setShowAddClientModal(true);
    }
    const closeAddClientModal = () => {
        setShowAddClientModal(false);
        setEditClientWithId(null);
    }

    const viewTenantProfileHandler = async () => {
        setIsLoading(true);
        setShowProfile(true);

        const getTenantUserID = 68;

        if (getTenantUserID) {
            const response = await getTenantProfile(token, getTenantUserID);

            if (response.success) {
                const defaultData = {
                    Employments: [],
                    FormerLandlords: [],
                    PreviousEvictions: [],
                    ...response.data
                }

                Object.entries(defaultData).forEach(([key, value]) => {
                    if ( value === null || value === undefined  ) {
                        if(key === 'Employments' || key === 'FormerLandlords' || key === "PreviousEvictions") {
                            defaultData[key] = [];
                        } else {
                            defaultData[key] = '';
                        }
                    }
                })
    
                const { Employments } = defaultData;
    
                if (Employments.length) {
                    const preparedEmployments = Employments && Employments.map( (employment) => {
                        const { Description, Duration, Employer, IsCurrentPoistion, Location, Position } = employment;
                        return { 
                            Description, 
                            Duration, 
                            Employer, 
                            IsCurrentPoistion, 
                            Location, 
                            Position, 
                            JobStart: splitJobDuration(Duration).JobStart,
                            JobEnd: splitJobDuration(Duration).JobEnd
                        }
                    })
    
                    defaultData.Employments = preparedEmployments;
                }

                setTenantData(defaultData);
            } else {
                swal("Something went wrong...", "Please try again later", {
                    icon: "error"
                });
            }
            const doesProfileExists = (response.data.City !== null);

            setDoesProfileExists(doesProfileExists);
        }
        
        setIsLoading(false);
    }

    const closeTenantProfileModal = () => {
        setShowProfile(false);
    }
    const addNewClientHandler = async (data) => {
        setIsSaving(true);

        const result = await serviceAddClient(data, token);
        
        setIsSaving(false);

        if(result.success) {
            dispatch(caseManagerActions.addClient({
                ...result.data,
                Groups: [], // TODO: delete when endpoint is fixed,
                Notes: "",
                State: "",
                Type: "",
                clientGender: "",
                City: ""
            }));
            SuccessPopup("Success! New client was added!");
            closeAddClientModal();

            setClientBasicDataForStatusModal(result.data);
            setOpenManagersModal(true)
            checkManagerAccountStatus(result.data);
        } else {
            ErrorPopup();
        }
        return result;
    }

    const editClientHandler = async (data) => {

        // console.log('[Client] data: ', data);

        setIsSaving(true);

        const result = await serviceEditClient(data, token);
        
        setIsSaving(false);

        if(result.success) {
            dispatch(caseManagerActions.editClient({
                ...result.data,
                Groups: [], // TODO: delete when endpoint is fixed,
                Notes: "",
                State: "",
                Type: "",
                clientGender: "",
                City: ""
            }));
            SuccessPopup("Success! Client was edited!");
            setEditClientWithId(null);
            closeAddClientModal();
        } else {
            ErrorPopup();
        }
        return result;
    }
    const deleteClientHandler = async (id) => {
        const decision = await Swal.fire({
            title: 'Do you want to delete the client?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger text-light me-2',
                cancelButton: 'btn btn-secondary '
            },
            
        })
        // console.log('decision: ', decision);

        if(decision.isDismissed) return;

        setIsSaving(true);

        const result = await serviceDeleteClient(id, token);
        
        setIsSaving(false);

        if(result.success) {
            dispatch(caseManagerActions.deleteClient(id));
            SuccessPopup("Success! Client was deleted from database!");
        } else {
            ErrorPopup();
        }
    }
    const addNewGroupHandler = async (data) => {
        // console.log('[Client][addNewGroupHandler] data: ', data);
        setIsSaving(true);

        const dummyData = {...data, [GROUP_ID]: uuidv4() };
        const result = await new Promise( resolve => setTimeout(() => resolve({success: true, data: dummyData }), 500))
        
        setIsSaving(false);

        if(result.success) {
            dispatch(caseManagerActions.addGroup(dummyData));
            SuccessPopup("Success! New group was added!");
        } else {
            ErrorPopup();
        }
        return result;
    }
    const onClickEditClientBtn = (id) => {
        setShowAddClientModal(true);
        setEditClientWithId(id);
    }



    const prepareDetailedInfo = (client) => {
        const genderShort = client[CLIENT.CLIENT_GENDER]?.[0] || "";
        return `${genderShort}${genderShort ? ", ": ""}${client[CLIENT.CLIENT_AGE] || ""}${client[CLIENT.CLIENT_AGE] ? "y, " : ""}${client[CLIENT.CLIENT_CITY] || ""}${client[CLIENT.CLIENT_CITY] ? " " : ""}${client[CLIENT.CLIENT_STATE] || ""}`;
    }

    const getGroupNameForClient = (client, groups) => {
        if(client && !client[CLIENT.CLIENT_GROUP]) return '';
        if(client && Array.isArray(client[CLIENT.CLIENT_GROUP]) && !client[CLIENT.CLIENT_GROUP].length ) return '';
        const clientGroupsById = client[CLIENT.CLIENT_GROUP];
        const clientGroupNames = clientGroupsById.map( groupId => groups.find( group => group[GROUP_ID] === groupId)[GROUP_NAME]);
        return clientGroupNames;
    }

    const sortByHandler = (item) => {
        if(!item) throw new Error("Missing argument!");
        setSortBy(() => item);

        if( item === sortBy) {
            setSortDirection( prevValue => prevValue === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC);
        } else {
            setSortDirection(SORT_DIR.ASC);
        }
    }

    const sort = (array, sortByItem, sortDir) => {
        if(!array) return array;

        return orderBy(array, [sortByItem], [sortDir])
    }

    
    const onChangeSearchHandler = (event) => {
        setSearchPhrase(event.target.value);
    }

    const resetFilters = () => {
        setActiveFilters({
            Group: '',
            City: '',
            State: '',
            Verified: '',
            ClientType: '',
        })
      }
    const clearFilterHandler = (filterName, defaultValue='') => {
        setActiveFilters( prevState => ({
            ...prevState,
            [filterName]: defaultValue
        }))
    }

    const debouncedSearchHandler = useMemo(
        () => debounce(onChangeSearchHandler, 300)
    , [])

    const changeHandler = (value, filterName) => {
        setActiveFilters( prevState => ({
          ...prevState,
          [filterName]: value
        }))
    }
    
    const filterHandler = useMemo(() => {
        if(!clients) return [];
        if(Array.isArray(clients) && !clients.length) return [];

        let sortedAndFilteredArr = [...clients];

        // FILTER
        if(activeFilters.Group) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientGroup === activeFilters.Group);
        }
        if(activeFilters.City) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientCity === activeFilters.City);
        }
        if(activeFilters.State) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientState === activeFilters.State);
        }
        if(activeFilters.Verified) {
            // console.log(sortedAndFilteredArr);
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => {
                if(activeFilters.Verified === 'true') {
                    return String(data.clientVerified) === activeFilters.Verified
                } else {
                    return !data.clientVerified
                }
            });
        }
        if(activeFilters.ClientType) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientType === activeFilters.ClientType);
        }

        // SORT
        if(sortBy === CLIENT.CLIENT_FIRST_NAME) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }
        if(sortBy === CLIENT.CLIENT_LAST_NAME) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }
        if(sortBy === CLIENT.CLIENT_TYPE) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }
        if(sortBy === CLIENT.CLIENT_VERIFIED) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }

        if(searchPhrase){
            sortedAndFilteredArr = sortedAndFilteredArr.filter( client => {
                const doesContain = [CLIENT.CLIENT_FIRST_NAME, CLIENT.CLIENT_LAST_NAME, CLIENT.CLIENT_EMAIL, CLIENT.CLIENT_PHONE,  CLIENT.CLIENT_GENDER, CLIENT.CLIENT_AGE, CLIENT.CLIENT_CITY, CLIENT.CLIENT_STATE, CLIENT.CLIENT_TYPE, CLIENT.CLIENT_VERIFIED].some( key => {

                    // TODO: if key === true or false then replace it with 'no' or 'yes'

                    if( String(client[key]).toLocaleLowerCase()?.includes(searchPhrase.toLocaleLowerCase()) ) return true;
                    return false
                })
                
                return doesContain;
            })
        }
    
        return sortedAndFilteredArr;
    }, [sortBy, sortDirection, clients, searchPhrase, activeFilters]);

    const howManyFiltersActive = () => {
        return Object.entries(activeFilters).filter( ([_, value]) => {
          if(Array.isArray(value)) return value.length > 0;
          return !!value;
        }).length;
    }

    const handleTableScroll = (e) => {
        // console.log(e.currentTarget.scrollWidth);
        // console.log(e.currentTarget.scrollLeft);
        // console.log(e.currentTarget.scrollWidth - e.currentTarget.clientWidth);

        // tableBarRef.current.style.scrollLeft = e.currentTarget.scrollLeft;
        // console.log(document.querySelector('#tableBar'));
        document.querySelector('#tableBar').scrollLeft = e.currentTarget.scrollLeft;
    }

    const checkManagerAccountStatus = async (clientData) => {
        const requestBody = {
            FirstName: clientData[CLIENT.CLIENT_NAME],
            LastName: clientData[CLIENT.CLIENT_NAME],
            Email: clientData[CLIENT.CLIENT_EMAIL]
        }

        // console.log('[Client] checking user account status...');

        // const result = await inviteManager(clientData, token);

        // setManagerAccountStatus( result.reduce((acc, { data, Email }) => {
        //     return {...acc, [Email]: data};
        // }, {}) );
    }


    const [showPortfolio, setShowPortfolio] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const showPortfolioHandler = (id) => {
        setSelectedClient(clients.find( client => client[CLIENT_ID] === id));
        setShowPortfolio(true);
    }
    const closePortfolioHandler = () => {
        setSelectedClient(null);
        setShowPortfolio(false);
    }

    useEffect(() => {
        getGroupsHandler();
        getClientsHandler();
    }, []);

    useEffect(() => {
        // document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'scroll';
        }
    }, [])



    return (
        <>
            { isSaving && <LoaderWithBackdrop/> }

            <Backdrop 
                show={showFilters}
                close={closeFilters}
            />

            { howManyFiltersActive &&  (
                <Filters 
                    show={showFilters}
                    close={closeFilters}
                    resetFilters={resetFilters}
                    howManyFiltersActive={howManyFiltersActive}
                >
                    <FilterRow
                        clearFilter={() => clearFilterHandler('Group')}
                        title={"Group"}
                        value={ activeFilters.Group }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.Group }
                            onChange={ (e) => changeHandler(e.target.value, 'Group') }
                        >
                            <option value='' defaultChecked>All</option>
                            {groups && groups.map( group => (
                                <option key={group[GROUP_ID]} value={group[GROUP_ID]}>{group[GROUP_NAME]}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>


                    <FilterRow
                        clearFilter={() => clearFilterHandler('City')}
                        title={"City"}
                        value={ activeFilters.City }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.City }
                            onChange={ (e) => changeHandler(e.target.value, 'City') }
                        >
                            <option value='' defaultChecked>All</option>
                            {availableCities && availableCities.map( city => (
                                <option key={city} value={city}>{city}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>

                    <FilterRow 
                        clearFilter={() => clearFilterHandler('State')}
                        title={'State'}
                        value={ activeFilters.State }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select state" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.State }
                            onChange={ (e) => changeHandler(e.target.value, 'State') }
                        >
                            <option value='' defaultChecked>All</option>
                            {STATES.split(",").map((state) => {
                                return (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FilterRow>

                    <FilterRow 
                        clearFilter={() => clearFilterHandler('Verified')}
                        title={'Verified'}
                        value={ activeFilters.Verified }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select verified" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.Verified }
                            onChange={ (e) => changeHandler(e.target.value, 'Verified') }
                        >
                            <option value='' defaultChecked>All</option>
                            {[{ name: 'No', val: false }, { name: 'Yes', val: true}].map((verified) => {
                                return (
                                    <option key={verified.name} value={verified.val}>
                                        {verified.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FilterRow>

                    <FilterRow
                        clearFilter={() => clearFilterHandler('ClientType')}
                        title={"ClientType"}
                        value={ activeFilters.ClientType }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.ClientType }
                            onChange={ (e) => changeHandler(e.target.value, 'ClientType') }
                        >
                            <option value='' defaultChecked>All</option>
                            {['Tenant', 'Landlord'].map( type => (
                                <option key={type} value={type}>{type}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>

                </Filters>
                )
            }
            
            <AddClientModal 
                show={showAddClientModal}
                onHide={closeAddClientModal}
                onAddGroup={addNewGroupHandler}
                onAddClient={addNewClientHandler}
                isLoading={isLoading}

                editClientWithId={editClientWithId}
                onEditClient={editClientHandler}

                groups={groups}
                clients={clients}
            />

            <PortfolioModal 
                show={showPortfolio}
                onHide={closePortfolioHandler}
            
                firstName={ selectedClient && selectedClient[CLIENT_FIRST_NAME] }
                lastName={ selectedClient && selectedClient[CLIENT_LAST_NAME] }
                phone={ selectedClient && selectedClient[CLIENT_MOBILE] }
                email={ selectedClient && selectedClient[CLIENT_EMAIL] }
                id={ selectedClient && selectedClient[CLIENT_ID] }
        
                onShowExamplePortfolio={viewTenantProfileHandler}
            />

            <TenantProfileModal           
                doesProfileExists={doesProfileExists}
                show={showProfile} 
                closeHandler={closeTenantProfileModal} 
                loading={isLoading}
            />

            <AccountExistsStatusModal 
                openManagersModal={openManagersModal}
                setOpenManagersModal={closeManagerModalHandler}
                modalTitle={'Client Status'}
            >
                { clientBasicDataForStatusModal && (
                            <Row className="m-0 border-bottom">
                                <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">
                                    {clientBasicDataForStatusModal[CLIENT.CLIENT_FIRST_NAME]} {clientBasicDataForStatusModal[CLIENT.CLIENT_LAST_NAME]}
                                </Col>
                                <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">
                                    {clientBasicDataForStatusModal[CLIENT.CLIENT_EMAIL]}
                                </Col>
                                <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">

                                    { managerAccountStatus[clientBasicDataForStatusModal[CLIENT.CLIENT_EMAIL]]?.[MANAGER_STATUS_FIELDS.ACCOUNT_EXISTS] === true && (
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-person-check text-success fs-5 pe-2"></i>User exists and will be authorized to edit your property</p>
                                        </div>
                                    )}

                                    { (managerAccountStatus[clientBasicDataForStatusModal[CLIENT.CLIENT_EMAIL]]?.[MANAGER_STATUS_FIELDS.ACCOUNT_EXISTS] === false) && (
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-person-fill-add text-danger fs-5 pe-2"></i>User does not exist, invitation has been sent</p>
                                        </div>
                                    )}

                                    { !managerAccountStatus[clientBasicDataForStatusModal[CLIENT.CLIENT_EMAIL]] && (
                                        <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-question-square-fill text-secondary fs-5 pe-2"></i>Loading...</p>
                                    )}

                                </Col>
                            </Row>
                )}
            </AccountExistsStatusModal>

            <Container fluid className="p-3 p-lg-4 d-flex flex-column" style={{height: 'calc(100vh - var(--top-menu-height))'}}>

                { (isLoadingGroups || isLoadingClients) && <div className="h-100" style={{overflowX: 'hidden'}}><Loader /></div> }
                { !isLoadingGroups && !isLoadingClients && (<>
                    <Row className="mb-3 justify-content-center align-items-center">
                        <Col xs={6} xl={4} className="flex-grow-1 flex-md-grow-0">
                            <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                                <input 
                                    onChange={onChangeSearchHandler}
                                    value={searchPhrase}
                                    placeholder="Type to search..." className="form-control border-0 px-0 shadow-none" />
                                <i className="d-flex bi bi-search fs-5 text-dark"></i>
                            </div>
                        </Col>

                        <Col xs={'auto'} className="d-flex ps-0">

                            { howManyFiltersActive && (
                        
                                <FiltersBtn 
                                    howManyFiltersActive={howManyFiltersActive}
                                    showResults={false}

                                    resetFilters={resetFilters}
                                    openFilters={openFilters}
                                    disabled={isLoading}
                                />
                            )}

                        </Col>

                    </Row>
                    
                    <Row className="m-0 overflow-hidden flex-grow-1">
                        <Col className="p-0 position-relative">
                            <div className="table-responsive pe-1" style={{height: '100%'}}>
                                <table className="table table-borderless">
                                    <thead className="position-sticky top-0 left-0">
                                        <tr >
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_FIRST_NAME)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>First Name</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_FIRST_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_FIRST_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_LAST_NAME)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>Last Name</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_LAST_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_LAST_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>

                                            <th scope="col" className="table-header__wrapper flex-grow-1">
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-card-text me-2"></i>Details</p>
                                                    <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_TYPE)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-people-fill me-2"></i>Client Type</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_TYPE ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_TYPE ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_VERIFIED)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-patch-check-fill me-2"></i>Verified</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_VERIFIED ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_VERIFIED ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1">
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-exclamation-circle-fill me-2"></i>Associated Group</p>
                                                    <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1">
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-wrench me-2"></i>Actions</p>
                                                    <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { filterHandler && filterHandler.map((client)=>(
                                        <ClientRow 
                                            key={client[CLIENT.CLIENT_ID]}
                                            id={client[CLIENT.CLIENT_ID]}

                                            details={prepareDetailedInfo(client)}
                                            clientType={client[CLIENT.CLIENT_TYPE]}
                                            verified={client[CLIENT.CLIENT_VERIFIED] ? 'Yes' : 'No'}
                                            associatedGroup={getGroupNameForClient(client, groups)}
                                            viewTenantProfileHandler={showPortfolioHandler}
                                            onDelete={deleteClientHandler}
                                            onEdit={onClickEditClientBtn}

                                            firstName={client[CLIENT.CLIENT_FIRST_NAME]}
                                            lastName={client[CLIENT.CLIENT_LAST_NAME]}
                                            email={client[CLIENT.CLIENT_EMAIL]}
                                            mobile={client[CLIENT.CLIENT_PHONE]}
                                        />
                                    ))}

                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>

                    <Row className="m-0 mt-3 justify-content-end">
                        <Col className="p-0 ps-2" xs="12" sm="auto">
                            <ButtonPrimary onClick={openAddClientModal}>
                                <i className="bi bi-plus-lg"></i> Add Client
                            </ButtonPrimary>
                        </Col>
                    </Row>
                </>
                )}

                {/* <div className="overflow-hidden position-relative">
                    <div className="mt-5 d-flex flex-column pe-2 overflow-scroll-y overflow-scroll-x wissp-scroll-box" 
                        style={{maxHeight: 100}}
                        onScroll={handleTableScroll}
                    >
                        <Row id="tableBar" ref={tableBarRef} className={`position-absolute top-0 left-0 m-0 mb-3 rounded text-center text-uppercase me-17 flex-nowrap`}>
                            <Col xs={2} style={{minWidth: 230}} onClick={() => sortByHandler(CLIENT.CLIENT_NAME)} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>Name</p>
                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                </div>
                            </Col>
                            <Col xs={2} style={{minWidth: 230}} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-card-text me-2"></i>Details</p>
                                </div>
                            </Col>
                            <Col xs={2} style={{minWidth: 230}} onClick={() => sortByHandler(CLIENT.CLIENT_TYPE)} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-people-fill me-2"></i>Client Type</p>
                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_TYPE ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_TYPE ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                </div>
                            </Col>
                            <Col xs={2} style={{minWidth: 230}} onClick={() => sortByHandler(CLIENT.CLIENT_VERIFIED)} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-patch-check-fill me-2"></i>Verified</p>
                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_VERIFIED ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_VERIFIED ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                </div>
                            </Col>
                            <Col xs={2} style={{minWidth: 230}} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-exclamation-circle-fill me-2"></i>Associated Group</p>
                                </div>
                            </Col>
                            <Col xs={2} style={{minWidth: 230}} className="rounded darkerGray text-nowrap p-0 text-center fw-bold cursor-pointer">
                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-wrench me-2"></i>Actions</p>
                                </div>
                            </Col>
                        </Row>

                        { filterHandler && filterHandler.map((client)=>(
                            <ClientRow 
                                key={client.id}
                                id={client.id}
                                name={client[CLIENT.CLIENT_NAME]}
                                details={prepareDetailedInfo(client)}
                                clientType={client[CLIENT.CLIENT_TYPE]}
                                verified={client[CLIENT.CLIENT_VERIFIED] ? 'Yes' : 'No'}
                                associatedGroup={getGroupNameForClient(client, groups)}
                                viewTenantProfileHandler={viewTenantProfileHandler}
                                onDelete={deleteClientHandler}
                                onEdit={onClickEditClientBtn}
                            />
                        ))}
                    </div>
                </div> */}
              

                

            </Container>
        </>
    );
};

export function ClientRow({
    id,
    firstName,
    lastName,
    details,
    clientType,
    verified,
    associatedGroup,
    viewTenantProfileHandler,
    onDelete,
    onEdit,
    showActions=true,
    onClickRow,
    className="lightGray",
    email,
    mobile,

    temp_showEditActions=false,
    temp_onAddClient,
    temp_onDeleteClient,
    isIncludedInGroup
}){
    return (
        // <Row className="m-0 border-bottom flex-nowrap">
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center border-end border-1 d-flex align-items-center">
        //         {name}
        //     </Col>
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center border-end border-1 d-flex align-items-center">
        //         {details}
        //     </Col>
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center border-end border-1 d-flex justify-content-center align-items-center">
        //         {clientType}
        //     </Col>
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center border-end border-1 d-flex justify-content-center align-items-center">
        //         {verified}
        //     </Col>
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center border-end border-1 d-flex justify-content-center align-items-center">
        //         {associatedGroup}
        //     </Col>
        //     <Col xs={2} style={{minWidth: 230}} className="px-2 py-2 text-center d-flex justify-content-around align-items-center">
        //         <Row className="m-0 flex-nowrap">
        //             <Col className="p-0 me-2" xs="auto">
        //                 <ButtonSecondary
        //                     onClick={viewTenantProfileHandler}
        //                 >
        //                     <i className="bi bi-person-badge fs-6"></i>
        //                 </ButtonSecondary>
        //             </Col>
        //             <Col className="p-0 me-2">
        //                 <ButtonPrimary onClick={() => onEdit(id)}>
        //                     <i className="bi bi-pencil-fill fs-6"></i>
        //                 </ButtonPrimary>
        //             </Col>
        //             <Col className="p-0">
        //                 <ButtonPrimary onClick={() => onDelete(id)} variant="danger">
        //                     <i className="bi bi-x-circle-fill fs-6"></i>
        //                 </ButtonPrimary>
        //             </Col>
        //         </Row>
        //     </Col>
        // </Row>

        <tr onClick={onClickRow}>
            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex flex-column h-100">
                    <div className="d-flex justify-content-center align-items-center p-2">
                        <p className="table-body__text">{firstName}</p>
                    </div>
                </div>
            </td>
            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex flex-column h-100">
                    <div className="d-flex justify-content-center align-items-center p-2">
                        <p className="table-body__text">{lastName}</p>
                    </div>
                </div>
            </td>

            {/* <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex justify-content-center align-items-center p-2">
                    <p className="table-body__text">{details}</p>
                </div>
            </td> */}

            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex flex-column justify-content-center align-items-center p-2">
                    <p className="table-body__text d-block">{email}</p>
                    <p className="table-body__text d-block">{mobile}</p>
                </div>
            </td>

            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex justify-content-center align-items-center p-2">
                    <p className="table-body__text">{clientType}</p>
                </div>
            </td>
            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="d-flex justify-content-center align-items-center p-2">
                    <p className="table-body__text">{verified}</p>
                </div>
            </td>
            <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                <div className="table-body__group-wrapper position-relative d-flex justify-content-center align-items-center p-2">

                    { associatedGroup && associatedGroup.length >= 1 && (
                        <p className="table-body__text">
                            { associatedGroup.length !== 1 && (
                                <i className="bi bi-chevron-expand me-1 px-1 bg-primary rounded text-light" style={{paddingTop: 2, paddingBottom: 2}}></i>
                            )}

                            {associatedGroup[0]}
                            
                            { associatedGroup.length > 1 && (
                                <span> and {associatedGroup.length - 1} more</span>
                            )}
                        </p>
                    )}

                { associatedGroup && associatedGroup.length > 1 && (
                    <div className="table-body__more-groups-popup position-absolute bg-light rounded w-100 p-2 px-3 shadow">

                    
                        { associatedGroup.map( (groupName, index) => (
                            <p className="m-0" style={{fontSize: '0.85rem'}} >{index+1}. {groupName}</p>
                        ))}
                    </div>
                )}

                </div>

            </td>
            { showActions && !temp_showEditActions && (
                <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer ${className}`}>
                    <div className="d-flex justify-content-center align-items-center p-2">
                    <Row className="m-0 flex-nowrap">
                        <Col className="p-0 me-2" xs="auto">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="profile-button-tooltip">
                                        Show Profile
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonSecondary onClick={() => viewTenantProfileHandler(id)}>
                                        <i className="bi bi-person-badge"></i>
                                    </ButtonSecondary>
                                </div>
                            </OverlayTrigger>

                        </Col>
                        <Col className="p-0 me-2">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="edit-button-tooltip">
                                        Edit Client
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonPrimary onClick={() => onEdit(id)}> 
                                        <i className="bi bi-pencil-fill"></i>
                                    </ButtonPrimary>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col className="p-0">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="delete-button-tooltip">
                                        Delete Client
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonPrimary variant="danger" onClick={() => onDelete(id)}>
                                        <i className="bi bi-x-circle-fill"></i>
                                    </ButtonPrimary>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    </div>
                </td>
            )}
            { showActions && temp_showEditActions && (
                <td className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer`}>
                    <div className="d-flex justify-content-center align-items-center p-2">
                    <Row className="m-0 flex-nowrap">
                        {/* <Col className="p-0 me-2" xs="auto">

                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="profile-button-tooltip">
                                        Show Profile
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonSecondary onClick={() => viewTenantProfileHandler(id)}>
                                        <i className="bi bi-person-badge"></i>
                                    </ButtonSecondary>
                                </div>
                            </OverlayTrigger>
                        </Col> */}
                        <Col className="p-0 me-2">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="edit-button-tooltip">
                                        Add To Group
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonPrimary onClick={() => temp_onAddClient(id)} disabled={isIncludedInGroup}> 
                                        <i className="bi bi-plus-square"></i>
                                    </ButtonPrimary>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col className="p-0">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip id="delete-button-tooltip">
                                        Remove From Group
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <ButtonPrimary variant="danger" onClick={() => temp_onDeleteClient(id)} disabled={!isIncludedInGroup}>
                                        <i className="bi bi-x-circle-fill"></i>
                                    </ButtonPrimary>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    </div>
                </td>
            )}
        </tr>
    )
}
