import React from 'react';
import { Row, Col, FloatingLabel, Form } from 'react-bootstrap';

import InputFloating from '../Shared/Inputs/InputFloating';


export default function BasicForm({
    o_property,
    b_editMode=true,
    f_loadDataFromAutofill,
    f_register,
    o_errors,
    s_registerNameForUnitName,
    s_registerNameForFootage,
    s_registerNameForBedroomCount,
    s_registerNameForBathRoomCount,
    s_registerNameForFloorID
}){
    return (
        <Row className="p-0 m-0 mt-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            <Col className="m-0 p-0 w-100" xs={12}>
                <h3 className="fw-bolder">Unit Basics</h3>
            </Col>

            { b_editMode && (o_property && o_property.Units && o_property.Units.length !== 0) && (
                <Col className="m-0 p-0 mb-3 w-100" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 py-sm-1" xs={12}>
                            <FloatingLabel
                                controlId={"inputGroupSelect01"}
                                label={"Autofill with information from Unit (optional)"}
                                >
                                <Form.Select 
                                    size={"lg"}
                                    aria-label={"Select"}
                                    className={"rounded border-0 shadow-sm customSelect fs-6"}
                                    onChange={(event) => f_loadDataFromAutofill(event)}
                                >
                                    <option key="autofill-0" value={null}>None</option>
                                    {o_property && o_property.Units && o_property.Units.map((item) => {
                                        return (<option value={item.UnitID} key={item.UnitID}>{item.Name}</option>)
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Col>
            )}

            <Col className="m-0 p-0 pe-sm-2 mb-3" >
                <InputFloating 
                    label="Unit name"
                    register={f_register(s_registerNameForUnitName)}
                    showError={true}
                    errorMessage={o_errors[s_registerNameForUnitName]?.message}
                />
            </Col>
            <Col className="m-0 p-0 pe-md-2 mb-3" >
                <InputFloating 
                    label="Square Footage"
                    type="text"
                    register={f_register(s_registerNameForFootage)}
                    showError={true}
                    errorMessage={o_errors[s_registerNameForFootage]?.message}
                />
            </Col>
            <Col className="m-0 p-0 pe-sm-2 pe-md-0 pe-lg-2 mb-3">
                <InputFloating 
                    label="Bedrooms"
                    type="number"
                    min="0" 
                    max="5"
                    register={f_register(s_registerNameForBedroomCount)}
                    showError={true}
                    errorMessage={o_errors[s_registerNameForBedroomCount]?.message}
                />
            </Col>
            <Col className="m-0 p-0 pe-sm-0 pe-md-2 mb-3">
                <InputFloating 
                    label="Bathrooms"
                    type="number"
                    min="0" 
                    max="5"
                    register={f_register(s_registerNameForBathRoomCount)}
                    showError={true}
                    errorMessage={o_errors[s_registerNameForBathRoomCount]?.message}
                />
            </Col>
            <Col className="m-0 p-0 pe-sm-2 pe-lg-0 mb-3" >
                <InputFloating 
                    label="Floor"
                    type="number"
                    min="0" 
                    max="100"
                    register={f_register(s_registerNameForFloorID)}
                    showError={true}
                    errorMessage={o_errors[s_registerNameForFloorID]?.message}
                />
            </Col>
        </Row>
    )
}
