export function arrFromObjVal(obj){
    return Object.keys(obj).map( key => obj[key] );
}

export function objFromArrAndFilledWithValue(arr, value=true){
    const obj = {};

    for ( const key of arr ) {
        obj[key] = value;
    }

    return obj;
}

/**
 * Create Array based on Object keys, filtered by keys values
 * @param {Object} obj object to be transformed
 * @param {Boolean} filter value to be filtered by (default is true)
 */
export function arrFromObjKeysFilteredByVal(obj, filter=true){
    if (isEmpty(obj)) {
        return [];
    }

    if (!(typeof obj === 'object' && obj !== null && !Array.isArray(obj))) {
        console.error('arrFromObjKeysFilteredByVal function received non object value!');
        return [];
    }

    return Object.keys(obj).filter( key => obj[key] === filter);
}

export function isEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }

  export function writeEmptyValues(obj, writeWith='') {
    const objShallowCopy = {...obj};

    Object.keys(objShallowCopy).forEach( key => {
        if(!objShallowCopy[key]) {
            objShallowCopy[key] = writeWith;
        }
    } )

    return objShallowCopy;
  }