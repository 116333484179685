import React from "react";
import {
    Row,
    Col
} from "react-bootstrap";

import ButtonWithLeftBorder from "../../Shared/Buttons/ButtonWithLeftBorder";
import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";
import BasicFieldsLoader from "../../UnitFormElements/BasicsFieldsLoader";


export default function AddNewUnitFormLoader({
    editUnitLoader=false
}) {

    return (
        <Row className="m-0 p-0 p-2 p-md-5 lightGray rounded overflow-hidden">
            <Row className="m-0 p-0 justify-content-sm-between">
                <Col className="m-0 p-0 flex-grow-1 order-1 " xs="12" md="auto">
                    <h2 className="m-0 p-0 mb-4 darkerGray skeleton rounded" style={{width: 250}}>&nbsp;</h2>
                </Col>

                { !editUnitLoader && (
                    <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2" xs={12} md={3} xl={2}>
                        <Row className="m-0 p-0 justify-content-end">
                            <Col className="m-0 p-0" xs="6" md={12}>
                                <ButtonWithLeftBorder 
                                    showIcon={true}
                                    title=""
                                    iconClass=""
                                    additionalButtonClass="ps-3 darkerGray skeleton"
                                />
                            </Col>
                        </Row>
                    </Col>
                )}


            { editUnitLoader && (
                    <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2 flex-grow" xs={12} md={6} lg={5}>
                        <Row className="m-0 p-0 mb-3 mb-sm-0 justify-content-end">
                            <Col className="m-0 p-0 pe-1" xs={6}>
                                <ButtonWithLeftBorder 
                                    showIcon={true}
                                    title=""
                                    iconClass=""
                                    additionalButtonClass="ps-3 darkerGray skeleton"
                                />
                            </Col>

                            <Col className="m-0 p-0 ps-1" xs={6}>
                            <ButtonWithLeftBorder 
                                    showIcon={true}
                                    title=""
                                    iconClass=""
                                    additionalButtonClass="ps-3 darkerGray skeleton"
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>

            <Row className="m-0 p-0">
                    <BasicFieldsLoader existingUnits={!editUnitLoader}/>

                    { editUnitLoader && (
                        <Row className="m-0 p-0 mb-3">
                            <Col className="m-0 p-0">
                                <ButtonPrimary
                                    title={<>&nbsp;</>}
                                    additionalClassName="darkerGray skeleton border-light"
                                />
                            </Col>
                        </Row>
                    )}

                    <Row className="m-0 p-0 mb-3">
                        <Col className="m-0 p-0" xs={12}>
                            <h3 className="m-0 p-0 mb-2 skeleton darkerGray rounded" style={{width: 200}}>&nbsp;</h3>
                        </Col>
                        <Col className="m-0 p-0 mb-2 skeleton darkerGray rounded" xs={12} style={{height: 184}}></Col>
                        <Col className="m-0 p-0 mb-2 skeleton darkerGray rounded" xs={12} style={{height: 100}}></Col>
                    </Row>

                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12}>
                            <h3 className="m-0 p-0 mb-2 skeleton darkerGray rounded" style={{width: 200}}>&nbsp;</h3>
                        </Col>
                        <Col className="m-0 p-0 mb-2 skeleton darkerGray rounded" xs={12} style={{height: 184}}></Col>
                        <Col className="m-0 p-0 mb-2 skeleton darkerGray rounded" xs={12} style={{height: 100}}></Col>
                    </Row>

                    <Row className="p-0 m-0 mt-3 justify-content-center">
                        <Col className="p-0 m-0" xs={12} sm={6} md={5} lg={4} xl={3}>
                            <ButtonPrimary
                                title={<>&nbsp;</>}
                                additionalClassName="darkerGray skeleton border-light"
                            />
                        </Col>
                    </Row>
            </Row>
        </Row>
    )
}
