export default function NoStreetViewPhoto() {
    return (
        <svg viewBox="0 0 160 115" xmlns="http://www.w3.org/2000/svg" className="noStreetView rounded">
            <g>
                <text
                    x="50%"
                    y="40%"
                    textAnchor="middle"
                // width="100%"
                // textLength='100%'
                // lengthAdjust="spacing"
                >
                    Neither Photos
                </text>
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                >
                    nor Street View
                </text>
                <text
                    x="50%"
                    y="60%"
                    textAnchor="middle"
                >
                    of the property are available!
                </text>
            </g>
        </svg>
    )
}
