import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Row
} from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';

import { AuthContext } from '../contexts/AuthContext';
import Portfolio from '../components/Tenant/Portfolio';

import { CreatePortfolioContext } from '../contexts/CreatePortfolioContext';

import { 
    getOwnProfile,
    createTenantProfile, 
    editTenantProfile, 
    putTenantPicture,
    deleteTenantPicture
} from '../services/tenantProfile';

import useUpdateFiles from '../hooks/useUpdateFiles';
import { uploadDocument, editDocument, deleteDocument } from '../services/tenantDocument';

import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import DecisionModal from '../components/Shared/InfoPopups/DecisionModal';

export default function Tenant() {
    const { token } = useContext(AuthContext);
    const { tenantData, 
            setTenantData, 
            image,
            setImageHandler,
    } = useContext(CreatePortfolioContext);

    const [forceEditMode, setForceEditMode] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [doesProfileExists, setDoesProfileExists] = useState(null);
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [positions, setPositions] = useState([]);
    const [evictions, setEvictions] = useState([]);
    const [formerLandlords, setFormerLandlords] = useState([]);

    const {
        files,
        setFiles,
        setFilesToDelete,
        onlyNewFiles,
    } = useUpdateFiles();

    const cancelAddDocumentHandler = () => {
        setFiles([]);
    }

    const saveDocumentsHandler = async () => {
        const files = onlyNewFiles();
        const prepareData = files.map( file => {
            return {
                src: file.src,
                id: file.id,
                fileName: file.fileName,
                fileSize: file.fileSize,
                Description: file.description
            }
        })
        setIsLoading(true);
        const result = await uploadDocument(prepareData, token);
        const { success, data } = result;

        if(success) {
            if (data) {
                setFiles([]);
                setTenantData( prevState => {
                    return {
                        ...prevState,
                        TenantDocuments: data
                    }
                })
            }
        }
        setIsLoading(false);
    }

    const changeFileDescriptionHandler = async (Description, DocumentID) => {
        setIsLoading(true);
        const response = await editDocument(Description, DocumentID, token);
        const { success } = response;
        
        if (success) {
            SuccessPopup("Success! Document description was saved.");

            setTenantData( prevData => {
                const TenantDocuments = prevData.TenantDocuments.map( tenantDocument => {
                    if(tenantDocument.DocumentID !== DocumentID) return tenantDocument;
                    return {
                        ...tenantDocument,
                        Description
                    }
                })

                return {
                    ...prevData,
                    TenantDocuments
                }
            });
        } else {
            ErrorPopup();
        }
        setIsLoading(false);
    }

    const deleteFileHandler = async (DocumentID) => {
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this document!"
        });

        if(!decision.isConfirmed) return false;

        setIsLoading(true);
        const response = await deleteDocument([DocumentID], token);
        const { success } = response;
        if (success) {
            setTenantData( prevData => {
                const TenantDocuments = prevData.TenantDocuments.filter( tenantDocument => (
                    tenantDocument.DocumentID !== DocumentID
                ));

                return {
                    ...prevData,
                    TenantDocuments
                }
            });
            SuccessPopup("Success! Deleted document.");
        } else {
            ErrorPopup("Error! Could not delete document. Try again later.");
        }
        setIsLoading(false);
        return success;
    }

    const prepareDataHandler = (data) => {
        const defaultData = {
            Employments: [],
            FormerLandlords: [],
            PreviousEvictions: [],
            ...data
        }

        Object.entries(defaultData).forEach(([key, value]) => {
            if ( value === null || value === undefined  ) {
                if(key === 'Employments' || key === 'FormerLandlords' || key === "PreviousEvictions") {
                    defaultData[key] = [];
                } else {
                    defaultData[key] = '';
                }
            }
        })

        const { Employments } = defaultData;

        if (Employments.length) {
            const preparedEmployments = Employments && Employments.map( (employment) => {
                const { Description, Duration, Employer, IsCurrentPoistion, Location, Position } = employment;
                return { 
                    Description, 
                    Duration, 
                    Employer, 
                    IsCurrentPoistion, 
                    Location, 
                    Position, 
                    JobStart: splitJobDuration(Duration).JobStart,
                    JobEnd: splitJobDuration(Duration).JobEnd
                }
            })

            defaultData.Employments = preparedEmployments;
        }

        return defaultData;
    }

    const getOwnProfileHandler = async () => {
        const response = await getOwnProfile(token);
        
        if (response.success) {
            setTenantData(prepareDataHandler(response.data));
        } else {
            ErrorPopup();
        }

        const doesProfileExists = (response.data.City !== null);

        setDoesProfileExists(doesProfileExists);
        setFirstLoading(false);

        if (doesProfileExists) {
            setForceEditMode(false);
            setCanEdit(true);
        } else {
            setForceEditMode(true);
            setCanEdit(false);
        }
    }

    function splitJobDuration (duration) {
        if(!duration || typeof duration !== 'string') return {JobStart: '', JobEnd: ''};
        const splittedTime = duration.split('-');
        if(splittedTime.length <= 1) return {JobStart: '', JobEnd: ''};
        let jobStart = splittedTime[0].trim();
        let jobEnd = splittedTime[1].trim();

        return {JobStart: jobStart.trim(), JobEnd: jobEnd.trim()};
    }

    const editBasics = async (data) => {
        const prepareData = {
            ...tenantData,
            ...data
        }

        // If data changed then make request
        if (compareObjectsShallow(data, tenantData)) {
            setIsLoading(true);
            const response = await editTenantProfile(token, prepareData);

            responseModal(response.success);
            if (response.success) {
                setTenantData(response.data);
            }

            setIsLoading(false);
        }
    }

    const editEmployment = async (data, index) => {
        // If data changed then make request
        if (compareObjectsShallow(data.Employments[index], tenantData.Employments[index])) {
            setIsLoading(true);

            const prepareData = {
                ...data
            }

            const response = await editTenantProfile(token, prepareData);

            responseModal(response.success);
            if (response.success) {
                setTenantData(data);
            }

            setIsLoading(false);
        }
    }

    const editResidentialHistory = async (data) => {
        setIsLoading(true);

        const prepareData = {
            ...data
        }

        const response = await editTenantProfile(token, prepareData);

        responseModal(response.success);
        if (response.success) {
            setTenantData(prepareDataHandler(response.data));
        }

        setIsLoading(false);
    }

    const deleteEmployment = async (index) => {
        setIsLoading(true);

        const newEmployments = tenantData.Employments.filter( (_, filterIndex) => index !== filterIndex);

        const prepareData = {
            ...tenantData,
            Employments: newEmployments
        }

        const response = await editTenantProfile(token, prepareData);
        
        responseModal(response.success);
        if (response.success) {
            setTenantData(response.data);
            setIsLoading(false);
            return true;
        }

        setIsLoading(false);
        return false;
    }

    function compareObjectsShallow (data, compareTo)  {

        const keys = Object.keys(data);
        const changed = keys.some((key, index) => {
            if(!compareTo) return true;
            return (data[key] !== compareTo[key])
        });

        return changed;
    }

    useEffect(()=>{
        if(doesProfileExists) {
            setForceEditMode(false);
            setCanEdit(true);
        }

    }, [doesProfileExists]);

    useEffect( () => {
        if(tenantData && tenantData.City) {
            setDoesProfileExists(true);
            setForceEditMode(false);
            setCanEdit(true);
            setFirstLoading(false);
        } else {
            getOwnProfileHandler();
        }
    }, []);

    const uploadBasic = async (data) => {
        const prepareData = {
            ...data,
            ProfilePhotoBlob: image
        }
        const {success} = await createTenantProfile(token, prepareData);
        
        await responseModal(
            success,
            "Portfolio has been created.",
            "Could not create portfolio. Try again later."
        );

        if(success) {
            const defaultData = {
                Employments: [],
                FormerLandlords: [],
                PreviousEvictions: [],
                ...data,
                ProfilePhotoUrl: image && image.src
            }

            setTenantData( defaultData );
            setImageHandler( _ => null);
            setDoesProfileExists(true);
        }
    }

    const createPortfolio = async (data) => {

        setIsLoading(true);
        await uploadBasic(data);
        await saveDocumentsHandler();
        setIsLoading(false);

        scroll.scrollToTop();
    }

    async function responseModal (isSuccess, successMessage = "Success! Portfolio has been saved.", errorMessage = "Error! Could not save portfolio. Try again later.") {
        if (isSuccess) {
            SuccessPopup(successMessage);
        } else {
            ErrorPopup(errorMessage);
        }
    }

    const uploadPicture = async (blobData) => {
        setIsLoading(true);
        const response = await putTenantPicture(blobData, token);
        const { success } = response;

        if(success) {
            setTenantData( prevTenantData => ({
                ...prevTenantData,
                ProfilePhotoUrl: image.src,
            }));
            setImageHandler(null);
        } else {
            ErrorPopup("Error! Could not upload image. Try again later.");
        }

        setIsLoading(false)
    }

    const deletePicture = async () => {
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this image"
        });

        if(!decision.isConfirmed) return;

        if(image) {
            setImageHandler(null);
            return;
        }

        setIsLoading(true);
        const response = await deleteTenantPicture(token);
        const { success } = response;

        if(success) {
            setTenantData( prevTenantData => ({
                ...prevTenantData,
                ProfilePhotoUrl: null
            }))
            setImageHandler(null);
        } else {
            ErrorPopup("Error! Could not delete image. Try again later.");
        }

        setIsLoading(false)
        
    }

    return (
        <Container fluid className="p-0 m-0 my-3 my-sm-5 mx-auto" style={{maxWidth: "1200px"}}>

            { isLoading && (
                <LoaderWithBackdrop />
                )
            }

            <Row className="p-0 m-0 mt-3"></Row>

            <Portfolio 
                forceEditMode={forceEditMode} 
                canEdit={canEdit} 
                setDoesProfileExists={setDoesProfileExists} 
                editBasics={editBasics} 
                editEmployment={editEmployment} 
                deleteEmployment={deleteEmployment} 
                createPortfolio={createPortfolio}
                deletePicture={deletePicture}
                editResidentialHistory={editResidentialHistory}

                positions={positions}
                setPositions={setPositions}
                evictions={evictions}
                setEvictions={setEvictions}
                formerLandlords={formerLandlords}
                setFormerLandlords={setFormerLandlords}

                uploadPicture={uploadPicture}
                doesProfileExists={doesProfileExists}

                setIsLoading={setIsLoading}

                saveDocumentsHandler={saveDocumentsHandler}
                files={files}
                setFiles={setFiles}
                setFilesToDelete={setFilesToDelete}
                onlyNewFiles={onlyNewFiles}
                changeFileDescriptionHandler={changeFileDescriptionHandler}
                deleteFileHandler={deleteFileHandler}
                cancelAddDocumentHandler={cancelAddDocumentHandler}

                isLoading={firstLoading}
            />
            
        </Container>
    )
}
