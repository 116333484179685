import React from 'react';
import {
    Container,
    Row, 
    Col
} from 'react-bootstrap';

import ButtonPrimary from '../Buttons/ButtonPrimary';

export const SHOW_COMPONENT = {
    HISTORY: "messageHistory",
    MESSAGE: "messageContainer",
    MESSAGES: "moreRequests",
    ALL: "all"
}

export const MENU_ELEMENTS = {
    HISTORY: "history",
    MESSAGES: "messages"
}

const Inbox = ({ 
    historyComponent, 
    messageComponent, 
    messagesComponent,
    activeComponent,
    setActiveComponent,
    mobileMenuElements=[MENU_ELEMENTS.MESSAGES]
}) => {

    return (
        <Container fluid className="m-0 p-0 w-100">
            {/* MOBILES */}
            <Row className="d-xl-none mx-auto p-0 flex-nowrap " style={{maxHeight: 'calc(100vh - 54px - 16px)', marginBottom: '54px'}}>
                { activeComponent === SHOW_COMPONENT.MESSAGE && messageComponent }
                { activeComponent === SHOW_COMPONENT.MESSAGES && messagesComponent }
            </Row>

            <Row className="d-xl-none m-0 p-3 py-2 w-100 position-fixed bottom-0 start-0 bg-light rounded">
                { mobileMenuElements && mobileMenuElements.includes(MENU_ELEMENTS.MESSAGES) && (
                    <Col className="m-0 me-2 p-0">
                        <ButtonPrimary 
                            className={`w-100 rounded ${ [SHOW_COMPONENT.MESSAGES].includes(activeComponent) ? 'fw-bolder' : 'fw-normal' }`}
                            onClick={() => setActiveComponent(SHOW_COMPONENT.MESSAGES)}
                            title="Messages"
                        />

                    </Col>
                )}
            </Row>

            {/* DESKTOP from extra large screens */}
            <Row className="d-none d-xl-flex m-0 p-0 flex-nowrap">
                { messagesComponent }
                { messageComponent }
                
            </Row>
        </Container>
    )
}

export default Inbox;
