import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


export default function FeaturesChecklist({
    o_allfeatures,
    a_selectedFeatures,
    b_areSelectedFeaturesANumber=false
}){
    const isChecked = ( a_selectedFeatures, featureToFind, key, b_areSelectedFeaturesANumber) => {
        if(!a_selectedFeatures || !featureToFind) return false; 
        if(b_areSelectedFeaturesANumber) {
            return a_selectedFeatures && a_selectedFeatures.find( feature => +feature === +key )
        } 
        return a_selectedFeatures && a_selectedFeatures.find( feature => feature.toLowerCase() === featureToFind.toLowerCase() )
    }

    return(
        <Container className='m-0 p-0' fluid>
            { o_allfeatures && Object.entries(o_allfeatures).map(([ key, value ]) => (
                <Row key={key} className="m-0 p-0 mb-1">
                    <Col className="m-0 p-0" xs={'auto'}>
                        <div className="m-0 p-0 border rounded d-flex align-items-center justify-content-center" style={{height: 24, width: 24}}>
                            <i className={`bi bi-check-lg text-success ${ isChecked(a_selectedFeatures, value, key, b_areSelectedFeaturesANumber) ? 'd-block' : 'd-none' }`}></i>
                        </div>
                    </Col>
                    <Col className="m-0 p-0 ps-2">
                        <p className="m-0 p-0 fs-6">{ value }</p>
                    </Col>
                </Row>
            ))}
        </Container>
    )
}
