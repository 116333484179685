import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import DecisionModal from "../components/Shared/InfoPopups/DecisionModal";
import { AuthContext } from "../contexts/AuthContext";
import { arrFromObjVal, arrFromObjKeysFilteredByVal } from '../utils/dataTransformations';

import useUpdateImages from "../hooks/useUpdateImages";
import { inviteManager } from '../services/account';
import { uploadImages, deleteImages } from "../services/imagesRequests";
import { uploadFiles, deleteFiles } from "../services/filesRequests";

import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';

import { geocodeByAddressHandler } from '../services/googleAPI';
import useGoogleAutocomplete from "../hooks/useGoogleAutocomplete";

import ManagerModal from "../components/Shared/AccountExistsStatusModal";
import StreetViewPositionModal from "../components/BuildingFormElements/StreetViewPositionModal";

import BackNav from "../components/Shared/BackNav";
import FormSection1Loader from "../components/BuildingAdd/FormSection1Loader";
import ButtonWithLeftBorder from "../components/Shared/Buttons/ButtonWithLeftBorder";

import {
    getBuilding,
    getBuildingFeatures,
    updateBuildingInfo,
    updateBuildingFeatures,
    removeManager as removeManagerRequest,
    updateFileDescription,
    setFavoriteBuildingPhoto,
    deleteBuilding
} from '../services/building';

import {
    GOOGLE_AUTOCOMPLETE,
    NAME,
    ADDRESS_LINE_1,
    ADDRESS_LINE_2,
    CITY,
    STATE,
    ZIP,
    AMENITIES,
    ACCESSIBILITIES,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    LANDLORD_CONTACT_FULLNAME,
    LANDLORD_CONTACT_EMAIL,
    LANDLORD_CONTACT_PHONE,
    MANAGERS,
    MANAGER_ID,
    MANAGER_FULL_NAME,
    MANAGER_EMAIL,
    MANAGER_PHONE,
    LANDLORD_CONTACT_ID,
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME
} from '../constants/buildingFormConstants';
import useUpdateFiles from "../hooks/useUpdateFiles";

import { propertiesDataActions } from "../store/PropertiesSlice";

import BasicInfoForm from "../components/BuildingUpdate/BasicInfoForm";
import ManagersForm from "../components/BuildingUpdate/ManagersForm";
import FeaturesForm from "../components/BuildingUpdate/FeaturesForm";
import DocumentsForm from "../components/BuildingUpdate/DocumentsForm";
import NotesForm from "../components/BuildingUpdate/NotesForm";
import UploadImage from "../components/Shared/UploadImage";
import { UserTypeContext } from '../contexts/UserTypeContext';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function BuildingUpdate() {
    const { userType } = useContext(UserTypeContext);
    const propertiesStore = useSelector( store => store.propertiesStore.properties );
    const propertyFeaturesStore = useSelector( store => store.propertiesStore.propertyFeatures );
    const history = useHistory();
    const dispatch = useDispatch();
    const { token } = useContext(AuthContext);
    const userEmail = localStorage.getItem('userEmail');

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionFocus, setSectionFocus] = useState(null)
    const [features, setFeatures] = useState(null);

    const [openStreetViewModal, setOpenStreetViewModal] = useState(false);
    const [streetViewPos, setStreetViewPos] = useState();
    const [preparedBuildingData, setPreparedBuildingData] = useState(null);
    const [svdata, setSVData] = useState(null);
    const [svSuccess, setSVSuccess] = useState(false);

    const [openManagersModal, setOpenManagersModal] = useState(false);
    const [managerAccountStatus, setManagerAccountStatus] = useState({});

    let query = useQuery();
    const buildingID = Number(query.get('building-id'));

    const buildingData = useMemo(() => {
        const findProperty = propertiesStore.find( property => property.BuildingID === Number(buildingID));

        if(findProperty) {
            const separateAddress = findProperty.Address.split('$*|*$');

            const transformedBuildingData = {
                ...findProperty,
                [ADDRESS_LINE_1]: separateAddress[0],
                [ADDRESS_LINE_2]: separateAddress[1],
            };
            return transformedBuildingData;
        }

        return null;
    }, [buildingID, propertiesStore]);

    const {
        googleValue,
        autocompleteError,
        lattitudeAndLongitude,
        addressFromAutocomplete,
        setGoogleValue,
        setAutocompleteError,
        googleAutocompleteClearState
    } = useGoogleAutocomplete();

    const {
        images,
        setImages,
        setImagesToDelete,
        imagesToDeleteIDs,
        imagesToDelete,
        onlyNewImages,
        downloadedImages,
        setDownloadedImages,
    } = useUpdateImages();

    const {
        files,
        setFiles,
        filesToDelete,
        setFilesToDelete,
        filesToDeleteIDs,
        onlyNewFiles,
        downloadedFiles,
        setDownloadedFiles
    } = useUpdateFiles();

    function prepareManagers(data) {
        let Managers = [];

        if (data && Array.isArray(data[MANAGERS])) {
            Managers = data[MANAGERS].map( manager => ({
                [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
                [MANAGER_FULL_NAME]: `${manager[MANAGER_FIRST_NAME]}$*|*$${manager[MANAGER_LAST_NAME]}`,
                [MANAGER_PHONE]: manager[MANAGER_PHONE]
            }))
        }

        if (data[LANDLORD_CONTACT_PHONE]) {
            const LandlordData = {
                [MANAGER_FULL_NAME]: data[LANDLORD_CONTACT_FULLNAME],
                [MANAGER_EMAIL]: data[LANDLORD_CONTACT_EMAIL],
                [MANAGER_PHONE]: data[LANDLORD_CONTACT_PHONE]
            }

            if (data[LANDLORD_CONTACT_ID]) {
                LandlordData[MANAGER_ID] = data[LANDLORD_CONTACT_ID];
            }

            Managers.push(LandlordData);
        }

        return Managers;
    }

    const fetchBuildingData = useCallback( async () => {
        const findProperty = propertiesStore.find( property => property.BuildingID === Number(buildingID));
        if(findProperty) {
            setData(findProperty);
            return;
        }

        setIsLoading(true);
        const response = await getBuilding(buildingID, token);
        const { success, data } = response;
        if (success) {
            dispatch(propertiesDataActions.addProperty(data));
            setData(data);
        } else {
            ErrorPopup("Error! Could not get building data. Try again later.");
        }
        setIsLoading(false);

        function setData(data) {
            setDownloadedImages(data.Photos);
            setDownloadedFiles(data.Documents);
        }
    }, [buildingID, dispatch, propertiesStore, setDownloadedFiles, setDownloadedImages, token]);

    const fetchedFeatures = useCallback(async () => {
        if(propertyFeaturesStore) {
            setData(propertyFeaturesStore);
            return;
        }

        setIsLoading(true);
        
        const response = await getBuildingFeatures();
        const { success, data } = response;

        setIsLoading(false);

        if (success) {
            setData(data);
            dispatch(propertiesDataActions.setPropertyFeatures(data));
        } else {
            ErrorPopup("Error! Could not get building features. Try again later.");
        }

        function setData(data) {
            const transformedObject = {
                [ACCESSIBILITIES]: arrFromObjVal(data[ACCESSIBILITIES]),
                [AMENITIES]: arrFromObjVal(data[AMENITIES])
            }

            setFeatures(transformedObject);
        }
    }, [dispatch, propertyFeaturesStore]);

    const removeManagerFromDB = async (managerID) => {
        setIsSaving(true);
        const result = await removeManagerRequest(buildingID, managerID, token);
        setIsSaving(false);

        const { success } = result;
        if (success) {
            dispatch(propertiesDataActions.removeManagerFromProperty({ BuildingID: buildingID, BuildingManagerID: managerID}));
            SuccessPopup('Success! Manager was removed');
        } else {
            ErrorPopup();
        }

        return success;
    }

    const saveDescriptionHandler = async (updatedFile) => {
        const { description: Description, id: BuildingDocumentID } = updatedFile;

        setIsSaving(true);
        const result = await updateFileDescription(updatedFile.id, Description, token);
        const { success } = result;

        if(success) {
            dispatch(propertiesDataActions.editDocument({
                BuildingID: buildingID,
                BuildingDocumentID,
                Description
            }))
            SuccessPopup('Success! Changes saved.');
        } else {
            ErrorPopup();
        }
        setIsSaving(false);
    }

    const cancelEditHandler = () => {
        history.push(`/building-info?building-id=${buildingID}`);
    }

    const deletePropertyHandler = async () => {
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this building!"
        });
        if(decision.isDenied || decision.isDismissed) return;

        const response = await deleteBuilding(buildingID, token);
        const {success} = response;
    
        if (success) {
          SuccessPopup('Success! Property deleted.');
          dispatch(propertiesDataActions.deleteProperty(buildingID));
          history.push('/properties')
        } else {
          ErrorPopup();
        }
    }
    const checkManagerAccountStatus = async (managers) => {
        const preparedRequests = managers
            .filter(manager => manager.FullName !== 'self')
            .map( manager => {
                const splittedName = manager[MANAGER_FULL_NAME].split("$*|*$");
                return {
                    [MANAGER_FIRST_NAME]: splittedName[0] || manager[MANAGER_FULL_NAME],
                    [MANAGER_LAST_NAME]: splittedName[1] || "",
                    [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
                }}
            )
            .map( manager => inviteManager(manager, token));

        const result = await axios.all(preparedRequests);

        setManagerAccountStatus( result.reduce((acc, { data, Email }) => {
            return {...acc, [Email]: data};
        }, {}) );
    }
    const preparedDataForUpdate = (o_newData) => {
        const preparedData = {
            [NAME]: buildingData[NAME],
            BuildingType: "Co-op",
            BuildingID: buildingID,
            Address: buildingData[ADDRESS_LINE_2] ? `${buildingData[ADDRESS_LINE_1]}$*|*$${buildingData[ADDRESS_LINE_2]}` : buildingData[ADDRESS_LINE_1],
            [CITY]: addressFromAutocomplete ? addressFromAutocomplete.City : buildingData[CITY],
            [STATE]: addressFromAutocomplete ? addressFromAutocomplete.State : buildingData[STATE],
            [ZIP]: addressFromAutocomplete ? addressFromAutocomplete.Zip : buildingData[ZIP],
            Longitude: buildingData.Longitude,
            Latitude: buildingData.Latitude,
            [MANAGERS]: buildingData.Managers,
            [MISC_AMENITIES]: buildingData[MISC_AMENITIES],
            [MISC_ACCESSIBILITIES]: buildingData[MISC_ACCESSIBILITIES],
            Notes: buildingData.Notes
        }

        return {
            ...preparedData,
            ...o_newData
        };
    }

    const extrudeLatLng = async (data, lattitudeAndLongitude) => {
        if (!lattitudeAndLongitude) {
            const combinedAddress = `United States 
            ${data[ADDRESS_LINE_1] === null ? "" : data[ADDRESS_LINE_1]}
            ${data[ADDRESS_LINE_2] === null ? "" : data[ADDRESS_LINE_2]} 
            ${data[CITY] === null ? "" : data[CITY]} 
            ${data[STATE] === null ? "" : data[STATE]}`

            const geoResponse = await geocodeByAddressHandler(combinedAddress);
            const { success: geoSuccess, data: geoData } = geoResponse;
            if (geoSuccess) {
                return geoData.LatLong;
            } else {
                return;
            }
        } else {
            return lattitudeAndLongitude;
        }
    }

    const onSubmitBasics = async (formData) => {
        if(!formData) return;
        if (formData[GOOGLE_AUTOCOMPLETE] && !googleValue) {
            setAutocompleteError(['Required']);
            return;
        } else {
            setAutocompleteError(null);
        }
        if (autocompleteError) {
            return;
        }

        setIsSaving(true);
        const latLong = await extrudeLatLng(formData, lattitudeAndLongitude);
        if(!latLong) {
            ErrorPopup("Error! Couldn't get your property coordinates!");
            setIsSaving(false);
            return;
        } 
        setIsSaving(false);

        const preparedAddress = addressFromAutocomplete
        ? addressFromAutocomplete.Address
        : (formData[ADDRESS_LINE_2]
            ? `${formData[ADDRESS_LINE_1]}$*|*$${formData[ADDRESS_LINE_2]}`
            : formData[ADDRESS_LINE_1]);
            
        const prepareUpdatedValues = {
            Address: preparedAddress,
            [NAME]: formData[NAME],
            [CITY]: addressFromAutocomplete ? addressFromAutocomplete.City : formData[CITY],
            [STATE]: addressFromAutocomplete ? addressFromAutocomplete.State : formData[STATE],
            [ZIP]: addressFromAutocomplete ? addressFromAutocomplete.Zip : formData[ZIP],
            Longitude: latLong.lng,
            Latitude: latLong.lat,
        };

        const preparedData = preparedDataForUpdate(prepareUpdatedValues);

        //TODO: check if data 
        const checkIfAddressIsDifferent = (oldValues, newValues) => {
            return Object.entries(newValues).some( ([key, value]) => {
                if( key === NAME || key === 'Longitude' || key === 'Latitude' ) return false;

                if( oldValues[key] !== value ) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        const isAddressDifferent = checkIfAddressIsDifferent(buildingData, prepareUpdatedValues);

        if(isAddressDifferent) {
            setPreparedBuildingData(preparedData);
            setStreetViewPos(latLong);
            setOpenStreetViewModal(true);

            return;
        } 

        setIsSaving(true);
        const { success } = await updateBuildingInfo(buildingID, preparedData, token);
        setIsSaving(false);

        if(success) {
            SuccessPopup('Property Contact Info Updated!');
            dispatch(propertiesDataActions.editProperty(preparedData));
            
            // TODO: After update Google Autocomplete changes its value from true to false
            googleAutocompleteClearState();
        } else {
            ErrorPopup();
        }
    }


    const onSubmitStreetViewModal = async () => {
        const finalData = {
            ...preparedBuildingData,
            Latitude: svdata.lat,
            Longitude: svdata.lng,
            StreetPov: {
                Heading: svdata.heading,
                Pitch: svdata.pitch,
                Zoom: svdata.zoom
            }
        }

        setIsSaving(true);
        const { success } = await updateBuildingInfo(buildingID, finalData, token);
        setIsSaving(false);

        if(success) {
            SuccessPopup('Property Contact Info Updated!');
            dispatch(propertiesDataActions.editProperty(finalData));
            
            // TODO: After update Google Autocomplete changes its value from true to false
            googleAutocompleteClearState();
            setOpenStreetViewModal(false);
            setPreparedBuildingData(null);
        } else {
            ErrorPopup();
        }
    }

    const onSubmitManagersInfo = async (formData) => {
        // TODO: check if anything has changed, if not then don't send request
        if(formData.Managers && !formData.Managers.length) return;

        const Managers = { Managers: prepareManagers(formData) };
        const postBody = preparedDataForUpdate( Managers );
        
        setIsSaving(true);
        const {success, data} = await updateBuildingInfo(buildingID, postBody, token);
        setIsSaving(false);

        if(success) {
            SuccessPopup('Property Contact Info Updated!');
            dispatch(propertiesDataActions.editProperty({ BuildingID: buildingID, Managers: data.Managers }));

            checkManagerAccountStatus(data.Managers);
            setOpenManagersModal(true);
        } else {
            ErrorPopup();
        }
        // TODO: show popup only when there were new managers added? Check if in formData there are managers without BuildingManagerID
    }
    const favoriteChangeHandler = async (imageID) => {
        const updatedImages = images.map((image) => {
            if(image.id === imageID) { 
                return {
                    ...image,
                    isFavorite: true
                }
            }
            return {
                ...image,
                isFavorite: false
            }
        })                                       
        const findProperty = propertiesStore.find( property => property.BuildingID === Number(buildingID));

        if( !findProperty.Photos || !findProperty.Photos.length ) return;

        const getOldFavoritePhoto = findProperty.Photos.find( photo => photo.IsFavorite);
        const getNewFavoritePhoto = updatedImages.find( photo => photo.isFavorite);

        if(!getOldFavoritePhoto || !getNewFavoritePhoto) return;
        if((getOldFavoritePhoto && getNewFavoritePhoto) && (getOldFavoritePhoto.PhotoID === getNewFavoritePhoto.id)) return;
        if(!getNewFavoritePhoto.isInDB) {
            setImages( updatedImages );
            return;
        }

        const newFavoritePhotoID = getNewFavoritePhoto.id;

        setIsSaving(true);
        const result = await setFavoriteBuildingPhoto(buildingID, newFavoritePhotoID, token);
        setIsSaving(false);

        if (result.success) {
            setImages( updatedImages );
            SuccessPopup("Success! Changes favorite photo.");
            dispatch(propertiesDataActions.setPropertyFavoritePhotos({
                buildingID,
                photoID: newFavoritePhotoID
            }));
        }
    }

    const onSubmitImages = async () => {
        const uploadImagesURL = `building/${buildingID}/upload/photos`;
        const deleteImagesURL = `building/${buildingID}/delete/photos`;

        const imgToUpload = onlyNewImages();
        const imgToDelete = imagesToDeleteIDs();

        const isToUploadImagesEmpty = !imgToUpload.length;
        const isToDeleteImagesEmpty = !imgToDelete.length;

        if (!isToDeleteImagesEmpty) {
            setIsSaving(true);
            const { success } = await deleteImages(imgToDelete, buildingID, deleteImagesURL, token);
            setIsSaving(false);
            
            if(success) {
                dispatch(propertiesDataActions.deletePropertyPhotos({
                    BuildingID: buildingID,
                    PhotoIDs: imgToDelete
                }))
                setImagesToDelete([]);
                SuccessPopup('Success! Images deleted!');
            } else {
                ErrorPopup('Error! Could not delete images. Please try again later.');
            }
        }
        if (!isToUploadImagesEmpty) {
            setIsSaving(true);
            const { success, data } = await uploadImages(imgToUpload, uploadImagesURL, token);
            setIsSaving(false);

            if (success) {
                setDownloadedImages(data.data);
                dispatch(propertiesDataActions.editProperty({
                    BuildingID: buildingID,
                    Photos: data.data
                }));
                SuccessPopup('Success! Images updated!');
            } else {
                ErrorPopup('Error! Could not upload images. Please try again later.');
            }
        }

        
    }

    const onSubmitFeatures = async (data, amenities, accessibilities) => {
        if(!data) return;

        const preparedData = {
            BuildingID: buildingID,
            [AMENITIES]: arrFromObjKeysFilteredByVal(amenities),
            [ACCESSIBILITIES]: arrFromObjKeysFilteredByVal(accessibilities),
            [MISC_AMENITIES]: data[MISC_AMENITIES],
            [MISC_ACCESSIBILITIES]: data[MISC_ACCESSIBILITIES]
        }

        setIsSaving(true);
        const { success } = await updateBuildingFeatures(buildingID, preparedData, token);
        setIsSaving(false);

        if( success ) {
            SuccessPopup('Property Contact Info Updated!');
            dispatch(propertiesDataActions.editProperty(preparedData));
        } else {
            ErrorPopup();
        }
    }

    const onSubmitDocuments = async () => {
        const uploadFilesURL = `building/${buildingID}/upload/document`;

        const fileToUpload = onlyNewFiles();
        const fileToDelete = filesToDeleteIDs();

        const isToUploadFilesEmpty = !fileToUpload.length;
        const isToDeleteFilesEmpty = !fileToDelete.length;

        if (!isToDeleteFilesEmpty) {
            setIsSaving(true);
            const { success } = await deleteFiles(fileToDelete, buildingID, token);
            setIsSaving(false);

            if(success) {
                dispatch(propertiesDataActions.deleteDocuments({
                    BuildingID: buildingID,
                    DocumentsIDs: fileToDelete
                }))
                setFilesToDelete([]);
                SuccessPopup('Success! Documents deleted!');
            } else {
                ErrorPopup('Error! Could not delete files. Please try again later.');
            }
        }
        if (!isToUploadFilesEmpty) {
            setIsSaving(true);
            const { success, data } = await uploadFiles(fileToUpload, uploadFilesURL, token);
            setIsSaving(false);

            if (success) {
                setDownloadedFiles( prevDocs => ([...prevDocs, ...data.data]));
                dispatch(propertiesDataActions.addDocument({
                    BuildingID: buildingID,
                    NewDocuments: data.data
                }));
                SuccessPopup('Success! Documents updated!');
            } else {
                ErrorPopup('Error! Could not upload files. Please try again later.');
            }
        }
    }
    const onSubmitNotes = async (formData) => {
        if(!formData && !formData.Notes) return;

        const preparedData = preparedDataForUpdate({
            Notes: formData.Notes
        });

        setIsSaving(true);
        const {success} = await updateBuildingInfo(buildingID, preparedData, token);
        setIsSaving(false);

        if(success) {
            SuccessPopup('Property notes were updated!');
            dispatch(propertiesDataActions.editProperty(preparedData)); 
        } else {
            ErrorPopup();
        }
    }

    useEffect(() => {
        fetchBuildingData();
        fetchedFeatures();
    }, [token]);

    if (!buildingID) {
        history.replace('/properties');
    }

    return (
        <>
            { isSaving && <LoaderWithBackdrop /> }

            <StreetViewPositionModal 
                openStreetViewModal={openStreetViewModal}
                setOpenStreetViewModal={setOpenStreetViewModal}
                streetViewPos={streetViewPos}
                setSVData={setSVData}
                setSVSuccess={setSVSuccess}
                svSuccess={svSuccess}
                handleSVsubmit={onSubmitStreetViewModal}
            />

            <Container fluid style={{maxWidth: 1200}} className="p-0 m-auto mb-5 overflow-hidden">

                <ManagerModal 
                    openManagersModal={openManagersModal}
                    setOpenManagersModal={setOpenManagersModal}
                    building={buildingData}
                    userEmail={userEmail}
                    managerAccountStatus={managerAccountStatus}
                />
                
                <BackNav className="m-0 p-0 mb-3 px-2"
                    disabled={isLoading}
                    currentPage="edit property"
                    pages={[{
                        title: "properties",
                        goTo: "/properties"
                    },
                    {
                        title: "property info",
                        goTo: `/building-info?building-id=${buildingID}`
                    }]}
                />

                { isLoading && (<FormSection1Loader editBuildingLoader={true}/>)}

                { !isLoading && (
                    <Row className="m-0 p-0 position-relative">
                        <Col className="m-0 p-0 p-2 p-md-5 lightGray">
                            <Row className="m-0 p-0 justify-content-end justify-content-lg-between">
                                <Col className="m-0 p-0 flex-grow-1 order-1" xs="12" lg="auto">
                                    <h2 className="fw-bold m-0 p-0 mb-4">Edit Property Information</h2>
                                </Col>

                                <Col className="m-0 mb-3 p-0 order-0 order-lg-2" xs={12} md={'auto'}>
                                    <Row className="m-0 p-0 justify-content-end">
                                        <Col className="m-0 p-0 pe-sm-1 mb-2 mb-sm-0" style={{minWidth: 190}}>
                                            <ButtonWithLeftBorder 
                                                title='Back'
                                                additionalButtonClass='border-right-blue'
                                                titleMobile={'Back'}

                                                onClick={cancelEditHandler}
                                            />
                                        </Col>

                                        { userType === "landlord" && (
                                            <Col className="m-0 p-0 ps-sm-1" style={{minWidth: 190}}>
                                                <ButtonWithLeftBorder 
                                                    title='Delete Property'
                                                    titleMobile={'Delete'}

                                                    onClick={deletePropertyHandler}
                                                />
                                            </Col>
                                        )}
                                        
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="m-0 p-0">
                                <BasicInfoForm 
                                    sectionFocus={sectionFocus}
                                    setSectionFocus={setSectionFocus}
                                    onSubmitBasics={onSubmitBasics}
                                    autocompleteError={autocompleteError}
                                    setGoogleValue={setGoogleValue}
                                    googleValue={googleValue}
                                    building={buildingData}
                                />

                                { userType === "Landlord" && (
                                    <ManagersForm 
                                        sectionFocus={sectionFocus}
                                        setSectionFocus={setSectionFocus}
                                        onSubmitManagersInfo={onSubmitManagersInfo}
                                        building={buildingData}

                                        onRemoveManager={removeManagerFromDB}
                                    />
                                )}

                                <Row className="m-0 p-0 mb-3">
                                    <Col className="p-0">
                                        <UploadImage 
                                            onSubmitImages={onSubmitImages}
                                            sectionFocus={sectionFocus}
                                            setSectionFocus={setSectionFocus}
                                            isForm={true}
                                            downloadedPictures={downloadedImages}
                                            images={images}
                                            setImages={setImages}
                                            setImagesToBeDeleted={setImagesToDelete}
                                            isProperty={true}

                                            onFavoriteClick={favoriteChangeHandler}
                                            imagesToDelete={imagesToDelete}
                                            isImagesToUpload={!!onlyNewImages().length}
                                        />
                                    </Col>
                                </Row>

                                <FeaturesForm 
                                    sectionFocus={sectionFocus}
                                    setSectionFocus={setSectionFocus}
                                    features={features}
                                    onSubmitFeatures={onSubmitFeatures}
                                    building={buildingData}
                                />

                                <DocumentsForm 
                                    saveDescriptionHandler={saveDescriptionHandler}
                                    downloadedFiles={downloadedFiles}
                                    setFilesToDelete={setFilesToDelete}
                                    filesToDelete={filesToDelete}
                                    setFiles={setFiles}
                                    files={files}
                                    onSubmitDocuments={onSubmitDocuments}
                                    sectionFocus={sectionFocus}
                                    setSectionFocus={setSectionFocus}

                                    isFilesToUpload={!!onlyNewFiles().length}
                                />

                                <NotesForm 
                                    sectionFocus={sectionFocus}
                                    setSectionFocus={setSectionFocus}
                                    onSubmitNotes={onSubmitNotes}
                                    building={buildingData}
                                />
                            </Row>
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    );
}
