import React, {useState} from 'react';
import { Form, FloatingLabel, Container, Row, Col } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import schema from '../schemas/requestPasswordResetSchema';
import { initializeResetRequest } from '../services/user';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";
import Footer from '../components/Shared/Footer';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';


export default function RequestPasswordReset() {
    const history = useHistory();
    const [buttonClicked, setButtonClicked] = useState(false);
    const formOptions = { resolver: yupResolver(schema), mode: "all"};
    const goBackHandler = () => {
        history.push('/login');
      }
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
      } = useForm(formOptions);
    
    const onSubmit = async (formData) => {
        if(buttonClicked) return;
        setButtonClicked( true );

        const body = {
            Email: formData.email
        }

        const response = await initializeResetRequest(body);
        const { success, data } = response;

        if(success) {

            if(data === "User does not exist") {
                ErrorPopup(data);
                setButtonClicked( false );
                return;
            }

            SuccessPopup("Success! Password reset link was sent to your email address.");
            history.replace('/login');
            return;
        } else {
            ErrorPopup();
        }
        reset()
        setButtonClicked( false );
    };

    return (
        <>
            <div className="custom_bg d-flex align-items-center justify-content-center">
                <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
                    <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
            
                        <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center position-relative" xs={12} sm={10} md={8} lg={7}>
                            <Row className="m-0 p-0 mb-4">
                                <h3 className="m-0 p-0 fw-bold text-center">Reset Password</h3>
                            </Row>

                            <Row className="m-0 p-0" xs={12}>
                                <Form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0 w-100">   
                                    
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0 mb-3" xs={12}>
                                            <p className="p-0 m-0 text-center">Please enter your email address. You will receive a link to create a new password via email.</p>
                                        </Col>

                                        <Col className="m-0 p-0" xs={12}>
                                            <FloatingLabel
                                                controlId="Email"
                                                label="Email address"
                                            >
                                                <Form.Control 
                                                type="text" 
                                                placeholder="0"
                                                className="rounded shadow-sm border-0"
                                                {...register("email")} 
                                                />
                                                <p className="custom_error">
                                                    {errors.email?.message}
                                                </p>
                                            </FloatingLabel>
                                        </Col>
                    


                                        <Row className="m-0 p-0 mt-4 justify-content-between"> 
                                            <Col className="m-0 p-0 pe-1" xs={6} >
                                                <ButtonSecondary title="Back" onClick={goBackHandler}/>
                                            </Col>
                                
                                            <Col className="m-0 p-0 ps-1" xs={6}>
                                                <ButtonPrimary title='Reset Password'/>
                                            </Col>
                                        </Row>
                            
                                    </Row>
                                </Form>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
