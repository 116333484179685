import React from 'react'
import { Row } from 'react-bootstrap'

const Bar = ({ className, children }) => {

  return (
    <Row className={`justify-content-between align-items-center darkerGray py-1 m-0 mb-3 rounded text-center text-uppercase ${className}`} style={{minHeight: 46}}>
      {children}
    </Row>
  )
}

export default Bar