import React from "react";
import {
    Row,
    Col
} from "react-bootstrap";

import Features from "../Shared/Features";
import {
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES
} from '../../constants/buildingFormConstants';
import TextAreaFloating from "../Shared/Inputs/TextAreaFloating";

export default function FeaturesFields({
    features,
    setAmenities,
    setAccessibilities,
    amenities,
    accessibilities,
    register
}) {
    return (
        <>
            <h3 className="fw-bolder">
                Amenities
            </h3>
            <p>
                <span >
                    Please select all that apply.
                </span>
            </p>
            <Row className="m-0 p-0">
                <Features
                    dataset={features && features.Amenities}
                    changeHandler={setAmenities}
                    values={amenities}
                />
            </Row>
            <Row className="m-0 p-0">
                <Col className="m-0 p-0 mb-3">
                    <TextAreaFloating
                        label="Additional Amenities..."
                        placeholder="Additional Amenities..."
                        register={register(MISC_AMENITIES)}
                    />
                </Col>
            </Row>

            <h3 className="fw-bolder">
                Accessibilities
            </h3>
            <p>
                <span >
                    Please select all that apply.
                </span>
            </p>
            <Row className="m-0 p-0">
                <Features
                    dataset={features && features.Accessibilities}
                    changeHandler={setAccessibilities}
                    values={accessibilities}
                />
            </Row>
            <Row className="m-0 p-0">
                <Col className="m-0 p-0 mb-3">
                    <TextAreaFloating
                        label="Additional Accessibilities..."
                        placeholder="Additional Accessibilities..."
                        register={register(MISC_ACCESSIBILITIES)}
                    />
                </Col>
            </Row>
        </>
    )
}
