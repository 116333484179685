import React, { useEffect, useState } from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';

import PhoneWithMask from '../Shared/Inputs/PhoneWithMask';
import InputFloating from '../Shared/Inputs/InputFloating';
import SelectFloating from '../Shared/Inputs/SelectFloating';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';

const states = 'AL,AK,AZ,AR,CA,CO,CT,DE,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY';

export default function BasicInfoForm({ isLoading, tenantData, forceEditMode, canEdit, register, errors, watch, BioLength, onSubmitBasic, resetForm, currentlyEditedSectionHandler }) {
    const [editMode, setEditMode] = useState(null);
    const BasicBioWatch = watch('Bio', '');
    const BasicCityWatch = watch('City', '');
    
    useEffect(() => {
        setEditMode(forceEditMode);
    }, [forceEditMode])

    const clickHandler = async (mode) => {
        if(mode === 'save') {
            const result = await onSubmitBasic();
            currentlyEditedSectionHandler(null);
            setEditMode( result );
        } else {
            currentlyEditedSectionHandler("Basic");
            setEditMode( true )
        }
    }

    const cancelBtnHandler = () => {
        setEditMode( false )
        currentlyEditedSectionHandler(null);
        resetForm();
    }

    return (
        <>
            { !editMode && !isLoading &&
                <Col xs={12} sm={'auto'} className="position-relative flex-grow-1 p-0 montserrat" >
                    { canEdit && (
                        <div className="position-absolute top-0 end-0" >
                            <ButtonPrimary onClick={() => clickHandler('edit')}>
                                <i className="bi bi-pencil-fill me-md-2"></i><span className="d-none d-md-inline">Edit Basics</span>
                            </ButtonPrimary>
                        </div> 
                    )}
                    <h5 className="fw-bold">{tenantData && (tenantData.FirstName || '')} {tenantData && (tenantData.LastName || '')}</h5>
                    <p className="p-0 m-0">{tenantData && (tenantData.City || '')} {tenantData && (tenantData.State || '')}</p>
                    <p className="p-0 m-0">{tenantData && tenantData.Age ? `${tenantData.Age} Years Old` : ''}</p>
                    <p className="p-0 m-0">{tenantData && tenantData.Gender}</p>
                    <p className="p-0 m-0">{tenantData && tenantData.MaritalStatus} {tenantData && tenantData.ChildrenInfo ? `, ${tenantData.ChildrenInfo} Children` : ''}</p>
                    <p className="p-0 m-0">{tenantData && tenantData.Phone}</p>
                    <p className="p-0 m-0">{tenantData && tenantData.Email}</p>
                    <p className="p-0 mt-3">{tenantData && tenantData.Bio}</p>
                </Col>
            }

            { isLoading && (
                <Col xs={12} sm={'auto'} className="position-relative flex-grow-1 p-0 montserrat" >
                    <div className="position-absolute top-0 end-0" >
                        <ButtonPrimary
                            title={<>&nbsp;</>}
                            additionalClassName='darkerGray skeleton border-light'
                            style={{width: 142}}
                            className="rounded fw-bold"
                        />
                    </div> 
                    <h5 className="fw-bold darkerGray skeleton rounded" style={{width: 100}}>&nbsp;</h5>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 125}}>&nbsp;</p>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 125}}>&nbsp;</p>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 75}}>&nbsp;</p>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 175}}>&nbsp;</p>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 125}}>&nbsp;</p>
                    <p className="p-0 m-0 darkerGray skeleton rounded mb-1" style={{width: 200}}>&nbsp;</p>
                    <p className="p-0 mt-3 darkerGray skeleton rounded mb-1" >&nbsp;</p>
                </Col>
            )}
            
            { editMode && !isLoading &&
                <Col className="position-relative flex-grow-1 p-0 montserrat">    
                    <Row className="m-0 p-0 w-100">

                        <Col xs={12} sm={6} lg={4} className="m-0 p-0 pe-sm-2 pb-2">
                            <InputFloating 
                                label="First Name"
                                register={register("FirstName")}
                                showError={true}
                                errorMessage={errors.FirstName?.message}
                            />
                        </Col>

                        <Col xs={12} sm={6} lg={4} className="m-0 p-0 pb-2">
                            <InputFloating 
                                label="Last Name"
                                register={register("LastName")}
                                showError={true}
                                errorMessage={errors.LastName?.message}
                            />
                        </Col>

                        <Col xs={12}></Col>

                        <Col xs={12} sm={8} lg={6} className="m-0 p-0 pe-sm-2 pb-2">
                            <InputFloating 
                                label={BasicCityWatch ? "City" :"Where do you live (city)?" }
                                register={register("City")}
                                showError={true}
                                errorMessage={errors.City?.message}
                            />
                        </Col>

                        <Col xs={12} sm={4} lg={2} className="m-0 p-0 pb-2">
                            <SelectFloating 
                                label="State"
                                aria-label="Select state" 
                                register={register("State")}
                                showError={true}
                                errorMessage={errors.State?.message}
                                optionEmpty={true}
                                dataArray={states.split(",")}
                            />
                        </Col>
                        
                        <Col xs={12}></Col>

                        <Col xs={12} sm={6} lg={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <InputFloating 
                                label="Age"
                                type="number"
                                min="0"
                                max="199"
                                register={register("Age")}
                                showError={true}
                                errorMessage={errors.Age?.message}
                            />
                        </Col>

                        <Col xs={12} sm={6} lg={3} className="m-0 p-0 pe-lg-2 pb-2">
                            <SelectFloating 
                                label="Gender"
                                aria-label="Select gender" 
                                register={register("Gender")}
                                showError={true}
                                errorMessage={errors.Gender?.message}
                                optionEmpty={true}
                                dataArray={['Male', 'Female', 'Other']}
                            />
                        </Col>

                        <Col xs={12} sm={6} lg={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <SelectFloating 
                                label="Relationship"
                                aria-label="Select MaritalStatus" 
                                register={register("MaritalStatus")}
                                showError={true}
                                errorMessage={errors.MaritalStatus?.message}
                                optionEmpty={true}
                                dataArray={['Married', 'Single', 'Divorced', 'Widowed']}
                            />
                        </Col>

                        <Col xs={12} sm={6} lg={3} className="m-0 p-0 pb-2">
                            <InputFloating 
                                label="Children"
                                type="number"
                                min="0"
                                max="64"
                                register={register("ChildrenInfo")}
                                showError={true}
                                errorMessage={errors.ChildrenInfo?.message}
                            />
                        </Col>

                        <Col xs={12}></Col>

                        <Col xs={12} sm={6} className="m-0 p-0 pe-sm-2 pb-2">
                            <PhoneWithMask 
                                register={register("Phone")}
                                label="Phone Number"
                                showError={true}
                                errorMessage={errors.Phone?.message}
                            />
                        </Col>
                        <Col xs={12} sm={6} className="m-0 p-0 pb-2">
                            <InputFloating 
                                type="email"
                                label="Email address"
                                register={register("Email")}
                                showError={true}
                                errorMessage={errors.Email?.message}
                            />
                        </Col>
                        <Col xs={12} className="m-0 p-0 pb-2">
                            <TextAreaFloating 
                                label={BasicBioWatch ? "Background Bio" : "Describe Yourself in a few sentences (optional)" }
                                register={register("Bio")}
                                showError={true}
                                errorMessage={errors.Bio?.message}
                                style={{ height: 170 }}
                            />

                            <p className="p-0 m-0 pe-2 pt-1 text-end" style={{fontSize: '.85rem'}}>
                                {BasicBioWatch ? BasicBioWatch.length : '0'} / {BioLength.current}
                            </p>
                        </Col>
                    </Row>
                    { canEdit && (
                            <>
                                <Row className="m-0 p-0 d-flex d-lg-none justify-content-center align-items-center" >
                                    <Col className="m-0 p-0 me-2" xs="auto">
                                        <ButtonSecondary onClick={cancelBtnHandler}  title="Cancel"/>
                                    </Col>                                    
                                    <Col className="m-0 p-0" xs="auto">
                                        <ButtonPrimary onClick={() => clickHandler('save')}>
                                            <i className="bi bi-arrow-up me-2"></i>Save Basics
                                        </ButtonPrimary>
                                    </Col>
                                </Row>

                                <div className="d-none d-lg-block position-absolute top-0 end-0" >
                                    <ButtonPrimary onClick={() => clickHandler('save')}>
                                       <i className="bi bi-arrow-up me-2"></i>Save Basics
                                    </ButtonPrimary>

                                    <ButtonSecondary onClick={cancelBtnHandler} additionalClassName="mt-2" title="Cancel"/>
                                </div>
                            </>
                        )
                    }
                </Col>  
            } 

        </>    
    )
}
