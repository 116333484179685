import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import { convertDateToMs, formatDateUS } from '../../utils/dateTransformations';
import { CURRENT_PROGRESS } from '../../Pages/NewMessages';


export default function Request({ selectedReqID, clickHandler, ticketID, date, author, status, CurrentProgress }) {

    const messageStatus = (status, currentProgress) => {

        let className = 'border-right-secondary';
        let messageStatusDescription = 'Read Message';
        
        switch(true) {
            case !status && !currentProgress:
                className = 'border-right-secondary';
                messageStatusDescription = 'Read Message';
                break;
            case  status === false:
                className = 'border-right-red';
                messageStatusDescription = 'Declinded';
                break;
            case currentProgress === CURRENT_PROGRESS.REQ_SENT:
                className = 'border-right-blue';
                messageStatusDescription = 'New Request';
                break;
            case currentProgress === CURRENT_PROGRESS.T_RES:
                className = 'border-right-green';
                messageStatusDescription = 'New Message';
                break;
            default:
                className = 'border-right-secondary';
                messageStatusDescription = 'Read Message';
        }

        return [className, messageStatusDescription];
    }

    return (
        <Row className={`m-0 p-0 mb-2 rounded shadow-sm w-100 ${selectedReqID === ticketID ? 'darkerGray ' : 'bg-white'} ${messageStatus(status, CurrentProgress)[0]}`}>
            <Container fluid
                className="m-0 p-0 "
                style={{ cursor: 'pointer' }}
                onClick={() => clickHandler(ticketID)}
            >
                <Row className="m-0 p-2 pe-3">
                    
                    <Col className="m-0 p-0">
                        <Row className="m-0 p-0"><h6 className="m-0 p-0 fw-bold" style={{color: 'inherit'}}>{author}</h6></Row>
                        <Row className="m-0 p-0"><p className="m-0 p-0 fs-6" style={{color: 'inherit'}}>{messageStatus(status, CurrentProgress)[1]}</p></Row>
                        <Row className="m-0 p-0"><p className="m-0 p-0 text-end" style={{ fontSize: '.85rem', color: 'inherit' }}>{formatDateUS(convertDateToMs(date), 'full', true)}</p></Row>
                    </Col>

                   </Row>
            </Container>
        </Row >
    )
}
