import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import useUpdateImages from '../components/../hooks/useUpdateImages';
import { arrFromObjVal, objFromArrAndFilledWithValue, arrFromObjKeysFilteredByVal } from '../utils/dataTransformations';
import { getUnitFeatures, uploadUnit } from '../services/units';
import { getBuilding } from '../services/building';
import LoaderWithBackdrop from "../components/Shared/LoaderWithBackdrop";
import AddNewUnitFormLoader from "../components/AddNewUnitForm/Loader";
import BackNav from "../components/Shared/BackNav";
import { propertiesDataActions } from "../store/PropertiesSlice";
import ButtonWithLeftBorder from "../components/Shared/Buttons/ButtonWithLeftBorder";
import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";

import BasicFields from "../components/UnitFormElements/BasicsFields";
import FeaturesFields from "../components/UnitFormElements/FeaturesFields";
import ImagesFields from "../components/UnitFormElements/ImagesFields";
import AdditionalInfoFields from "../components/UnitFormElements/AdditionalInfoFields";
import OccupantFields from "../components/UnitFormElements/OccupantFields";

import {
    NAME,
    SQ_FOOTAGE,
    BEDROOM_COUNT,
    BATHROOM_COUNT,
    FLOOR_ID,
    RENT,
    PAYMENT_DETAILS,
    PAYMENT_DUE_DATE,
    VACANT,
    OCCUPANT_FULL_NAME,
    OCCUPANT_EMAIL,
    OCCUPANT_PHONE_NUMBER,
    OCCUPANT_LEASE_START,
    OCCUPANT_LEASE_END,
    OCCUPANT_NOTES,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    MISC_NOTES
} from "../constants/unitFormConstants";
import schema from '../schemas/newUnitSchema';
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Apartment() {
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const propertiesStore = useSelector(store => store.propertiesStore.properties);
    const unitFeaturesStore = useSelector(store => store.propertiesStore.unitFeatures);

    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState,
        watch,
    } = useForm(formOptions);
    const { errors } = formState;

    const watchOccupantLeaseStart = watch(OCCUPANT_LEASE_START, '');
    const watchOccupantLeaseEnd = watch(OCCUPANT_LEASE_END, '');

    let query = useQuery();
    const buildingID = query.get("building-id");

    const [features, setFeatures] = useState(null);
    const [property, setProperty] = useState(null);

    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [amenities, setAmenities] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);

    const vacantWatch = watch(VACANT, true);

    useEffect(() => {
        if (vacantWatch === undefined) {
            setValue(VACANT, true);
        }

    }, [vacantWatch, setValue])

    const deleteOccupantHandler = () => {
        setValue(VACANT, true);
        setValue(OCCUPANT_NOTES, '');
        setValue(OCCUPANT_LEASE_END, '');
        setValue(OCCUPANT_LEASE_START, '');
        setValue(OCCUPANT_PHONE_NUMBER, '');
        setValue(OCCUPANT_EMAIL, '');
        setValue(OCCUPANT_FULL_NAME, '');
    }

    const {
        images,
        setImages
    } = useUpdateImages();

    const fetchedFeatures = useCallback(async () => {
        if (unitFeaturesStore) {
            setData(unitFeaturesStore);
            return;
        }

        setLoading(true);

        const response = await getUnitFeatures();
        const { success, data } = response;

        if (success) {
            if (!data) {
                setFeatures([]);
                ErrorPopup("Error! Something went wrong. Received an empty features object.");
            }
            dispatch(propertiesDataActions.setUnitFeatures(data));
            setData(data);
        } else {
            ErrorPopup();
        }
        setLoading(false);

        function setData(data) {
            const transformedObject = {
                Accessibilities: arrFromObjVal(data.Accessibilities),
                Amenities: arrFromObjVal(data.Amenities)
            }
            setFeatures(transformedObject);
        }
    }, [dispatch, unitFeaturesStore]);

    const getPropertyHandler = useCallback(async () => {
        const doesPropertyExists = propertiesStore.find(property => property.BuildingID === Number(buildingID));
        if (doesPropertyExists) {
            setProperty(doesPropertyExists);
            return;
        }

        setLoading(true);

        const response = await getBuilding(buildingID, token);
        const { success, data } = response;

        if (success) {
            dispatch(propertiesDataActions.addProperty(data));
            setProperty(data);
        } else {
            ErrorPopup();
        }

        setLoading(false);
    }, [dispatch, token, buildingID, propertiesStore]);

    useEffect(() => {
        fetchedFeatures();
        getPropertyHandler();
    }, [fetchedFeatures, getPropertyHandler]);

    const uploadUnitHandler = async (body) => {
        setIsSaving(true);
        const response = await uploadUnit(body, token);
        const { success, data } = response;

        setIsSaving(false);

        if (success) {
            dispatch(propertiesDataActions.addUnitToProperty(data));
            SuccessPopup("Success! Unit Added Successfully.");
            history.replace(`/building-info?building-id=${buildingID}`);
        } else {
            ErrorPopup();
        }
    }

    const prepareImages = (images) => {
        const only10Images = images.slice(0, 10);
        const temp_images = only10Images.map(({ id, fileName, fileSize, isFavorite, show, src }) => ({
            id,
            fileName,
            fileSize,
            IsFavorite: isFavorite,
            show,
            src
        }))

        return temp_images;
    }

    // TODO: add FloorID
    const onSubmit = (buildingData) => {
        // if (images && images.length === 0) return;
        let body = {
            Name: buildingData[NAME],
            Rent: buildingData[RENT],
            Vacant: (buildingData[VACANT] === null || buildingData[VACANT] === undefined) ? true : false,
            BedroomCount: buildingData[BEDROOM_COUNT],
            BathroomCount: buildingData[BATHROOM_COUNT],
            Amenities: arrFromObjKeysFilteredByVal(amenities),
            Accessibilities: arrFromObjKeysFilteredByVal(accessibilities),
            Photoblobs: prepareImages(images),
            BuildingID: parseInt(buildingID),
            SquareFootage: buildingData[SQ_FOOTAGE],
            PaymentDueDate: buildingData[PAYMENT_DUE_DATE],
            PaymentDetails: buildingData[PAYMENT_DETAILS],
            MiscAccessibilities: buildingData[MISC_ACCESSIBILITIES],
            MiscAmenities: buildingData[MISC_AMENITIES],
            FloorID: buildingData[FLOOR_ID],
            Notes: buildingData[MISC_NOTES],

            BuildingName: property.Name,
            Address: property.Address,
            City: property.City,
            Latitude: property.Latitude,
            Longitude: property.Longitude,
            State: property.State,


        }

        if (buildingData[VACANT] === false) {
            body = {
                ...body,
                Tenant: {
                    FullName: buildingData[OCCUPANT_FULL_NAME],
                    Email: buildingData[OCCUPANT_EMAIL],
                    Phone: buildingData[OCCUPANT_PHONE_NUMBER],
                    Comments: buildingData[OCCUPANT_NOTES],
                    LeaseStart: buildingData[OCCUPANT_LEASE_START] ? new Date(buildingData[OCCUPANT_LEASE_START]).toLocaleDateString('en-US') : null,
                    LeaseEnd: buildingData[OCCUPANT_LEASE_END] ? new Date(buildingData[OCCUPANT_LEASE_END]).toLocaleDateString('en-US') : null,
                }
            }
        }
        uploadUnitHandler(body);
    };

    function loadDataFromAutofill(event) {
        const UnitID = parseInt(event.target.value);

        if (!property) return;

        // If user selected blank option field in select then reset all properties
        if (isNaN(UnitID)) {
            reset();
            setAccessibilities({});
            setAmenities({});
            return;
        };

        const searchedUnit = property.Units.find(unit => {
            return unit.UnitID === UnitID;
        });

        setValue(SQ_FOOTAGE, searchedUnit.SquareFootage || '');
        setValue(BEDROOM_COUNT, searchedUnit.BedroomCount || '');
        setValue(BATHROOM_COUNT, searchedUnit.BathroomCount || '');
        setValue(RENT, searchedUnit.Rent || '');
        setValue(PAYMENT_DETAILS, searchedUnit.PaymentDetails || '');
        setValue(PAYMENT_DUE_DATE, searchedUnit.PaymentDueDate || '');
        setValue(MISC_ACCESSIBILITIES, searchedUnit.MiscAccessibilities || '');
        setValue(MISC_AMENITIES, searchedUnit.MiscAmenities || '');
        setValue(FLOOR_ID, searchedUnit.FloorID || '');
        setValue(VACANT, true);

        const prepareAccessibilities = searchedUnit.Accessibilities ? objFromArrAndFilledWithValue(searchedUnit.Accessibilities) : {};
        const prepareAmenities = searchedUnit.Amenities ? objFromArrAndFilledWithValue(searchedUnit.Amenities) : {};

        setAccessibilities(prepareAccessibilities);
        setAmenities(prepareAmenities);
    }

    const cancelEditHandler = () => {
        history.push(`/building-info?building-id=${buildingID}&active-tab=units`);
    }

    return (
        <>
            {isSaving && <LoaderWithBackdrop />}

            <Container fluid style={{ maxWidth: 1200 }} className="m-auto mb-5 p-0">

                <BackNav className="m-0 p-0 mb-3 px-2"
                    currentPage="add new unit"
                    disabled={isSaving || loading}
                    pages={[{
                        title: "properties",
                        goTo: "/properties"
                    },
                    {
                        title: "property units",
                        goTo: `/building-info?building-id=${buildingID}&active-tab=units`
                    }]}
                />

                {loading && (<AddNewUnitFormLoader />)}
                {!loading && (
                    <Row className="m-0 p-0 p-2 p-md-5 lightGray rounded overflow-hidden">
                        <Row className="m-0 p-0 justify-content-sm-between">
                            <Col className="m-0 p-0 flex-grow-1 order-1 " xs="12" md="auto">
                                <h2 className="fw-bold m-0 p-0 mb-4">Add New Unit</h2>
                            </Col>

                            <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2" xs={12} md={3} xl={2}>
                                <Row className="m-0 p-0 justify-content-end">
                                    <Col className="m-0 p-0" xs="6" md={12}>
                                        <ButtonWithLeftBorder
                                            title='Cancel'
                                            additionalButtonClass='border-right-blue'
                                            titleMobile={'Cancel'}

                                            onClick={cancelEditHandler}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="m-0 p-0">
                            <Form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0">

                                <BasicFields
                                    o_property={property}
                                    f_loadDataFromAutofill={loadDataFromAutofill}
                                    f_register={register}
                                    o_errors={errors}
                                    s_registerNameForUnitName={NAME}
                                    s_registerNameForFootage={SQ_FOOTAGE}
                                    s_registerNameForBedroomCount={BEDROOM_COUNT}
                                    s_registerNameForBathRoomCount={BATHROOM_COUNT}
                                    s_registerNameForFloorID={FLOOR_ID}
                                />

                                <FeaturesFields
                                    features={features}
                                    amenities={amenities}
                                    setAmenities={setAmenities}
                                    accessibilities={accessibilities}
                                    setAccessibilities={setAccessibilities}
                                    f_register={register}
                                    s_registerNameMiscAmenities={MISC_AMENITIES}
                                    s_registerNameMiscAccessibilities={MISC_ACCESSIBILITIES}
                                />

                                <ImagesFields
                                    images={images}
                                    setImages={setImages}
                                />

                                <AdditionalInfoFields
                                    register={register}
                                    errors={errors}
                                    s_registerNameForRent={RENT}
                                    s_registerNameForPaymentDetails={PAYMENT_DETAILS}
                                    s_registerNameForPaymentDueDate={PAYMENT_DUE_DATE}
                                    s_registerNameForNotes={MISC_NOTES}
                                />

                                <OccupantFields
                                    deleteOccupantHandler={deleteOccupantHandler}
                                    vacantWatch={vacantWatch}
                                    setValue={setValue}
                                    register={register}
                                    errors={errors}
                                    watchOccupantLeaseStart={watchOccupantLeaseStart}
                                    watchOccupantLeaseEnd={watchOccupantLeaseEnd}
                                    s_registerNameForVacant={VACANT}
                                    s_registerNameForOccupantFullName={OCCUPANT_FULL_NAME}
                                    s_registerNameForOccupantPhoneNumber={OCCUPANT_PHONE_NUMBER}
                                    s_registerNameForOccupantEmail={OCCUPANT_EMAIL}
                                    s_registerNameForOccupantLeaseStart={OCCUPANT_LEASE_START}
                                    s_registerNameForOccupantLeaseEnd={OCCUPANT_LEASE_END}
                                    s_registerNameForOccupantNotes={OCCUPANT_NOTES}
                                />

                                <Row className="p-0 m-0 mt-3 justify-content-center">
                                    <Col className="p-0 m-0" xs={12} sm={6} md={5} lg={4} xl={3}>
                                        <ButtonPrimary
                                            title="Save"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </Row>
                )}
            </Container>
        </>
    )
}
