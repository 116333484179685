import React, { useState, useContext, useEffect } from "react";
import { Form, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";

import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import Footer from '../components/Shared/Footer';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});


export default function Login() {
  const { login, token } = useContext(AuthContext);
  const { userType } = useContext(UserTypeContext);
  const [isRemember, setIsRemember] = useState(window.localStorage.getItem('rememberUser') ? true : false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: 
      yupResolver(loginSchema), 
      defaultValues: {
        email: window.localStorage.getItem('rememberUser') ? window.localStorage.getItem('rememberEmail') : ''
      }
  });

  const handleChange = (e) => {
    setIsRemember( prev => !prev );
  }

  const onSubmit = (credentials) => {
    if(isRemember) {
      window.localStorage.setItem('rememberUser', true);
      window.localStorage.setItem('rememberEmail', credentials.email);
    }
    login(credentials);
  };

  useEffect(() => {
    if(isRemember === false) {
      window.localStorage.removeItem('rememberUser');
      window.localStorage.removeItem('rememberEmail');
    }
  }, [isRemember, setValue]);

  if (token) {
    if(userType === "Landlord" || userType === "Manager") {
      return <Redirect to="/properties" />;
    } else if( userType === "Tenant") {
      return <Redirect to="/portfolio" />;
    } else if( userType === "CaseManager") {
      return <Redirect to="/command-center" />;
    } else {
      <Redirect to="/"/>;
    }
  }

  return (
    <>
      <div className="custom_bg d-flex align-items-center justify-content-center">
        <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
          <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >

            <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12} sm={10} md={8} lg={7}>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0 mb-4" xs={12}>
                  <h3 className="m-0 p-0 fw-bold text-center">
                    Login
                  </h3>
                </Col>

                <Col className="m-0 p-0 mb-4" xs={12}>
                  <Form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0">
                    <Row className="m-0 p-0" >
                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Email"
                            label="Email address"
                            className="mb-3 "
                        >
                          <Form.Control 
                            type="text" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            autoComplete="username"
                            {...register("email")} 
                          />
                          <p className="custom_error">
                              {errors.email?.message}
                          </p>
                        </FloatingLabel>
                      </Col>
                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Password"
                            label="Password"
                            className="mb-3"
                        >
                          <Form.Control 
                            type="password" 
                            autoComplete="current-password"
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("password")} 
                          />
                          <p className="custom_error">
                              {errors.password?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Col className="m-0 p-0" xs={12} sm={6}>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check checked={isRemember} onChange={ handleChange } type="checkbox" label="Remember me" />
                        </Form.Group>
                      </Col>

                      <Col className="m-0 p-0 text-end order-1 order-sm-0" xs={12} sm={6}>
                        <Link to={'/password-forgot'} className="text-dark">
                          Forgot your password?
                        </Link>
                      </Col>

                      <Col className="m-0 p-0 mb-3 mt-4 mb-sm-0" xs={12}>
                        <ButtonPrimary title="Login"/>
                      </Col>
                      
                    </Row>
                  </Form>
                </Col>

                <Col className="m-0 p-0" xs={12}>
                  <p className="m-0 p-0 fs-6">Don’t have an account?</p>
                </Col>

                <Col className="m-0 p-0 mt-4" xs={12}>
                  <Link to="/signup">
                    <ButtonPrimary title="Register"/>
                  </Link>
                </Col>
              </Row>
            </Col>
              
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
