import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    FloatingLabel,
    Form
} from 'react-bootstrap';
import { saveAs } from "file-saver";
import ButtonPrimary from '../Buttons/ButtonPrimary';

const charactersLimit = 150;

export default function FileRow ({
    index,

    id,
    src,
    fileName,
    fileSize,
    isInDB,
    description,

    removeFileHandler,
    changeFileDescription
}) {
    const [isEditing, setIsEditing] = useState(!isInDB);
    const [fileDescription, setFileDecription] = useState(description || '');    

    const downloadFileHandler = (fileSrc, fileName) => {
        saveAs( fileSrc, fileName)
    }

    const descripitonChangeHandler = (e) => {
        const text = e.target.value;
        if(text.length > charactersLimit) return;
        setFileDecription(text);
    }

    const onEditHandler = (fileDescription, id) => {
        if( !isEditing ) setIsEditing(true);
        if( isEditing ) {
            setIsEditing(false);
            changeFileDescription(fileDescription, id);
        }
    }

    useEffect(() => {
        if(!isInDB && fileDescription.length) {
            changeFileDescription(fileDescription, id, false);
        }
    }, [fileDescription])

    useEffect(() => {
        if(isInDB) {
            setIsEditing(false);
        }
    }, [isInDB])

    return (
        <Row className="m-0 p-3 px-sm-3 mb-2 darkerGray w-100 align-items-center rounded" key={index}>
            <Col className="m-0 p-0" xs={"auto"}>
                <h6 className="m-0 p-0">{index + 1}. {fileName}&nbsp;</h6>
            </Col>

            <Col className="m-0 p-0">
            { fileSize && (
                <h6 className="m-0 p-0">({fileSize} MB)</h6>)
            }
            </Col>

            <Col className="m-0 p-0" xs={"auto"}>
                <Row className="m-0 p-0">
                    { isInDB && (
                        <Col className="m-0 p-0 pe-1" xs={"auto"}>
                            <ButtonPrimary
                                onClick={() => downloadFileHandler(src, fileName)}
                                type='button'
                            >
                                <i className="d-block d-sm-none bi bi-file-earmark-arrow-down"></i>
                                <span className="d-none d-sm-block">Download</span>
                            </ButtonPrimary>
                        </Col>)
                    }

                    { isInDB && (
                            <Col className="m-0 p-0 pe-1" xs={"auto"}>
                                <ButtonPrimary
                                    onClick={() => onEditHandler(fileDescription, id)}
                                    type='button'
                                >
                                    <i className={`d-block d-sm-none bi ${isEditing ? 'bi-arrow-up' : 'bi-pencil'}`}></i>
                                    <span className="d-none d-sm-block">{ isEditing ? 'Save' : 'Edit'}</span>
                                </ButtonPrimary>
                            </Col>
                        )
                    }   

                    <Col className="m-0 p-0" xs={"auto"}>
                        <ButtonPrimary
                            onClick={() => removeFileHandler(id)}
                            type='button'
                            additionalClassName='bg-danger border-danger'
                        >
                            <i className="bi bi-x-lg"></i>
                        </ButtonPrimary>
                    </Col>
                </Row>
            </Col>

            { isEditing ? (
                    <Col xs={12} className="p-0 mt-2">
                        <FloatingLabel 
                            controlId="floatingTextarea2" 
                            label="Document description (optional)"
                        >
                            <Form.Control
                                className="rounded border-0 shadow-sm"
                                as="textarea"
                                placeholder="0"
                                style={{ height: '100px' }}

                                value={fileDescription}
                                onChange={descripitonChangeHandler}


                            />
                        </FloatingLabel>

                        <p className="p-0 m-0 pe-2 text-end fs-6">
                            { (fileDescription && fileDescription.length) || '0' } / { charactersLimit }
                        </p>
                    </Col>
                ) : (
                    <Col xs={12} className="p-0 mt-2">
                        <h6 className="p-0 m-0 p-3 bg-light fs-6 fw-normal rounded">
                            { fileDescription || 'This file has no description' }
                        </h6>
                    </Col>
                )
            }
        </Row>
    )
}
