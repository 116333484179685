import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";

export const CURRENT_PROGRESS = Object.freeze({
  REQ_SENT: "Request Sent",
  T_RES: "Tenant Responded",
});

export default function UnitFlat({ flat }) {
  const history = useHistory();
  const {
    BuildingID,
    UnitID,
    CurrentProgress,
    TicketID,
    Status,
    UnitInfo: { Name, BedroomCount, BathroomCount, Rent },
  } = flat;

  const clickHandler = () => {
    history.push(
      `/tourRequest/${BuildingID}/unit/${UnitID}?ticket=${TicketID}`
    );
  };

  const computeName = (unitName) => {
    if (!unitName) return "";
    if (unitName.length <= 15) return unitName;

    const shortName = unitName.slice(0, 15).trim();
    return `${shortName}... `;
  };

  const isNewMessage = () =>
    [CURRENT_PROGRESS.REQ_SENT, CURRENT_PROGRESS.T_RES].some(
      (desc) => CurrentProgress === desc
    ) && Status;

  return (
    <Row className="m-0 p-0 mb-2">
      <Col className="m-0 p-0">
        <Row className="m-0 p-1 p-md-0 d-flex darkerGray rounded">
          <Col className="m-0 px-0  ">
            <Row className="m-0 p-1 px-2 d-flex align-items-center h-100">
              <Col>
                <span className="fw-bold fs-7">{computeName(Name)}:</span>{" "}
              </Col>
              <Col xs='auto'>
                <span className="ms-auto text-end fs-7">${Rent} per month</span>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex p-0 position-relative" xs={12} md={"auto"}>
            <ButtonPrimary
              variant="primary"
              additionalClassName="fs-7"
              onClick={clickHandler}
              type="button"
              title="Requests"
            />

            <span
              className={`${
                isNewMessage() ? "d-block" : "d-none"
              } position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle fs-7`}
            >
              <span className="visually-hidden">New messages</span>
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
