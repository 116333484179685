import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Modal, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { read, utils } from "xlsx";
import { useLocation, useHistory } from "react-router-dom";
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../components/Shared/Buttons/ButtonSecondary';
import { getUnitFeatures, uploadUnit } from '../services/units';
import BackNav from "../components/Shared/BackNav";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import { propertiesDataActions } from "../store/PropertiesSlice";
import { arrFromObjVal } from '../utils/dataTransformations';
import { getBuilding } from '../services/building';
import { AuthContext } from "../contexts/AuthContext";
import InputBtn from '../components/Shared/InputBtn';

import preparePhotos from '../utils/preparePhotos';
import LoaderWithBackdrop from "../components/Shared/LoaderWithBackdrop";

import InputFloating from '../components/Shared/Inputs/InputFloating';
import SelectFloating from '../components/Shared/Inputs/SelectFloating';

import LazyImage from "../components/Shared/LazyImage";
import DecisionModal from '../components/Shared/InfoPopups/DecisionModal';

import { CustomAccordionElement,
    Accordion as CustomAccordion,
    useCustomAcordionContext } from "../components/Shared/Accordion";

import { orderBy } from "lodash";

const COL_NAMES = [
    {
        name: "Status",
        type: "selector",
        id: "select"
    },
    {
        name: "Unit Name",
        type: "edit",
        id: "name",
        reqName: "Name",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Bedrooms",
        type: "edit",
        id: "bedrooms",
        reqName: "BedroomCount",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Bathrooms",
        type: "edit",
        id: "bathrooms",
        reqName: "BathroomCount",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Rent Price",
        type: "edit",
        id: "rent",
        reqName: "Rent",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Vacant",
        type: "edit",
        id: "vacant",
        reqName: "Vacant",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Floor",
        type: "edit",
        id: "floor",
        reqName: "FloorID",
        isSortable: true,
        showInMobile: true
    },
    {
        name: "Additional notes",
        type: "edit",
        id: "notes",
        reqName: "Notes",
        showInMobile: true
    },
    {
        name: "Amenities",
        type: "edit",
        id: "amenities",
        reqName: "Amenities",
        showInMobile: true
    },
    {
        name: "Accessibilities",
        type: "edit",
        id: "accessibilities",
        reqName: "Accessibilities",
        showInMobile: true
    },
    {
        name: "List additional amenities",
        type: "edit",
        id: "additional",
        reqName: "MiscAmenities",
        showInMobile: true
    }
]

const FILE_COL_INDEXES = Object.freeze({
    Name: [0],
    BedroomCount: [1],
    BathroomCount: [2],
    Rent: [3],
    Vacant: [4],
    FloorID: [5],
    Notes: [6],
    Amenities: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    MiscAmenities: [22]
});

const ACTION_TYPES = Object.freeze({
    CLEAR: "clear",
    EDIT: "edit"
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ImportUnits(){
    const uploadInputRef = useRef(null);
    const { token } = useContext(AuthContext);
    const dispatch = useDispatch();
    const propertiesStore = useSelector(store => store.propertiesStore.properties);
    const unitFeaturesStore = useSelector(store => store.propertiesStore.unitFeatures);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // const [loadedFileData, setLoadedFileData] = useState(null);
    const history = useHistory();
    let query = useQuery();
    const buildingID = query.get("building-id");
    const [features, setFeatures] = useState(null);
    const [property, setProperty] = useState(null);
    const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
    const [units, setUnits] = useState(null);

    const [showInitSortModal, setShowInitSortModal] = useState(false);

    // sort direction can have 3 values: "asc", "desc" and null
    const [tableSort, setTableSort] = useState({
        colName: null,
        direction: null
    });

    const tableSortHandler = (sortName, units, isSortable) => {
        if(!isSortable) return;
        if(!sortName) { 
            throw new Error("Missing argument: sortNmae!");
        }
        
        let prevSortDirection = null;
        let newSortDirection = null;
        if(tableSort.colName === sortName){
            prevSortDirection = tableSort.direction;
        }
        if(prevSortDirection === null) {
            newSortDirection = "asc";
        } else if(prevSortDirection === "asc") {
            newSortDirection = "desc";
        } 
        setTableSort({ 
            colName: sortName,
            direction: newSortDirection
        });

        let sortedUnits = orderBy(units, ["defaultIndex"], "asc");
        if(newSortDirection !== null) {
            sortedUnits = orderBy(units, [sortName], [newSortDirection]);
        }
        setUnits(sortedUnits);
    }

    const resetTableSortState = () => {
        setTableSort({
            colName: null,
            direction: null
        })
    };

    const selectRowHandler = (index) => {
        setSelectedRowIndexes( prevState => {
            if(prevState.includes(index)) {
                return prevState.filter( prevRowIndex => prevRowIndex !== index);
            }
            return [...prevState, index]
        });
    }

    const pictures = preparePhotos(property);

    const fetchedFeatures = useCallback(async () => {
        if (unitFeaturesStore) {
            setData(unitFeaturesStore);
            return;
        }

        setLoading(true);

        const response = await getUnitFeatures();
        const { success, data } = response;

        if (success) {
            if (!data) {
                setFeatures([]);
                ErrorPopup("Error! Something went wrong. Received an empty features object.");
            }
            dispatch(propertiesDataActions.setUnitFeatures(data));
            setData(data);
        } else {
            ErrorPopup();
        }
        setLoading(false);

        function setData(data) {
            const transformedObject = {
                Accessibilities: arrFromObjVal(data.Accessibilities),
                Amenities: arrFromObjVal(data.Amenities)
            }
            setFeatures(transformedObject);
        }
    }, [dispatch, unitFeaturesStore]);

    const getPropertyHandler = useCallback(async () => {
        const doesPropertyExists = propertiesStore.find(property => property.BuildingID === Number(buildingID));
        if (doesPropertyExists) {
            setProperty(doesPropertyExists);
            return;
        }

        setLoading(true);

        const response = await getBuilding(buildingID, token);
        const { success, data } = response;

        if (success) {
            dispatch(propertiesDataActions.addProperty(data));
            setProperty(data);
        } else {
            ErrorPopup();
        }

        setLoading(false);
    }, [dispatch, token, buildingID, propertiesStore]);

    useEffect(() => {
        fetchedFeatures();
        getPropertyHandler();
    }, [fetchedFeatures, getPropertyHandler]);

    async function handleFileAsync(file) {
        if(!file) return;
        const extension = file.name.split('.').pop(); 
        if(extension !== "xlsx") {
            ErrorPopup("Please provide excel file");
            return;
        }

        const data = await file.arrayBuffer();
        const workbook = read(data);
      
        const aoa = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        const removeEmptyRows = aoa.reduce( (acc, row) => {
            if(row.length) {
                return [...acc, [...row]];
            }
            return acc;
        }, []);

        let extrudedUnitsRows;
        if(removeEmptyRows && removeEmptyRows.length >= 2) {
            extrudedUnitsRows = removeEmptyRows.slice(1);
        } 
        let formattedUnits;
        if(Array.isArray(extrudedUnitsRows) && extrudedUnitsRows.length ) {
            const unitNames = [];

            formattedUnits = extrudedUnitsRows.map( (unitData, index) => {
                const sortedFeatures = FILE_COL_INDEXES.Amenities.reduce((acc, index) => {
                    if(unitData[index]) {
                        if( features.Accessibilities.includes(unitData[index]) ) {
                            acc.Accessibilities.add(unitData[index]);
                        } else if (features.Amenities.includes(unitData[index])) {
                            acc.Amenities.add(unitData[index]);
                        }
                    }
                    return acc;
                }, {Accessibilities: new Set(), Amenities: new Set()});

                const isNameTaken = unitNames.includes(unitData[FILE_COL_INDEXES.Name]);
                unitNames.push(unitData[FILE_COL_INDEXES.Name]);

                return {
                    defaultIndex: index,
                    success: isNameTaken ? true : null,
                    Error: isNameTaken ? "Unit Name Already Exists" : null,

                    Name: unitData[FILE_COL_INDEXES.Name],
                    Rent: unitData[FILE_COL_INDEXES.Rent],
                    Vacant: unitData[FILE_COL_INDEXES.Vacant] === "Yes" ? true : false,
                    BedroomCount: unitData[FILE_COL_INDEXES.BedroomCount],
                    BathroomCount: unitData[FILE_COL_INDEXES.BathroomCount],
                    MiscAmenities: unitData[FILE_COL_INDEXES.MiscAmenities],
                    FloorID: unitData[FILE_COL_INDEXES.FloorID],
                    Amenities: Array.from(sortedFeatures.Amenities),
                    Accessibilities: Array.from(sortedFeatures.Accessibilities),
                    Notes: unitData[FILE_COL_INDEXES.Notes]
                }
            })

        }

        resetTableSortState();
        setUnits(formattedUnits);
        // setLoadedFileData(removeEmptyRows);
        setShowInitSortModal(true);
    }

    const cancelEditHandler = () => {
        history.push(`/building-info?building-id=${buildingID}&active-tab=units`);
    }

    const [isErrorInSave, setIsErrorInSave] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const onCloseStatusModal = () => {
        if(isErrorInSave){
            setShowStatusModal(false);
        } else {
            history.push(`/building-info?building-id=${buildingID}&active-tab=units`);
        }
    }

    const saveImportedUnitsHandler = async () => {
        if(!units) return;

        const uploadUnitHandler = async (body) => {
            const response = await uploadUnit(body, token);
            const { success } = response;
    
            setIsSaving(false);
    
            if (success) {
                return response;
            } else {
                return response;
            }
        }

        const arrOfBodyRequest = units.reduce( (acc, unitData) => {
            if(unitData.success === true && !unitData.Error) { return acc; }

            acc.push({
                ...unitData,

                Error: null,
                success: false,

                Amenities: unitData.Amenities || [],
                Accessibilities: unitData.Accessibilities || [],

                Photoblobs: null,
                SquareFootage: null,
                PaymentDueDate: null,
                PaymentDetails: null,
                MiscAccessibilities: null,

                BuildingID: parseInt(buildingID),
                BuildingName: property.Name,
                Address: property.Address,
                City: property.City,
                Latitude: property.Latitude,
                Longitude: property.Longitude,
                State: property.State,
            });
            return acc;
        }, [])

        setIsSaving(true);
        const result = await Promise.all(arrOfBodyRequest.map( reqBody => uploadUnitHandler(reqBody)));
        setIsSaving(false);

        // TODO: save units in redux store
        if(result) {
            const extrudeSuccessfullyAddedUnits = result.filter((data) => {
                if(data.success === false) return false;
                if(data.success === true && data.data.Error) return false;

                return true;
            }).map( res => res.data);

            dispatch(propertiesDataActions.addUnitsToProperty({Units: extrudeSuccessfullyAddedUnits, BuildingID: parseInt(buildingID)}));
        }
        // TODO: save Errors and statuses in units state to easier process
        setIsErrorInSave(result.some( unit => ((unit.success === false) || (unit.data.Error))));


        // Identify units by name
        // setUnitsSaveStatuses( prevUnits => {
        //     if(Array.isArray(prevUnits) && prevUnits.length) {
        //         return prevUnits.map( prevUnit => {

        //             const findUnit = result.find( unit => unit.data.Name === prevUnit.data.Name );
        //             if(findUnit) {
        //                 return findUnit;
        //             }
        //             return prevUnit;
        //         })
        //     }
        //     return result;
        // } );

        setUnits( prevUnits => prevUnits.map ( prevUnit => {
                const findUnit = result.find( unit => unit.data.Name === prevUnit.Name);
                if(findUnit) {
                    return {
                        ...prevUnit,
                        Error: findUnit.data.Error,
                        success: findUnit.success
                    };
                }
                return prevUnit;
            })
        );

        setShowStatusModal(true);
    }

    const [showEditModal, setShowEditModal] = useState(false);
    const [editedRowData, setEditedRowData] = useState({colId: null, colType: null, actionType: null, colName: null, unitVar: null});
    const actionHandler = ({ colId, colType, actionType, colName, unitVar}) => {
        setShowEditModal(true);
        setEditedRowData({ colId, colType, actionType, colName, unitVar});
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setEditedRowData({colId: null, colType: null, actionType: null, colName: null, unitVar: null});
        setSelectedRowIndexes( [] );
    }

    const onChangeValue = ({ key, value, indexesOfUnits }) => {
        if(!Array.isArray(indexesOfUnits)) {
            throw new Error(`Argument should be of type Array - ${typeof indexesOfUnits} given.`);
        };

        if(value === null || value === undefined || !key) return;

        setUnits( prevUnits => prevUnits.map( (unit, index) => {
            if(indexesOfUnits.includes(index)) {
                if(key === "Name") {
                    if(typeof unit.Name !== 'string'){
                        return {
                            ...unit,
                            [key]: value,
                            Error: "Missing Name",
                            success: null
                        }
                    }

                    const isNameDifferent = value.trim() !== unit.Name.trim();
                    return {
                        ...unit,
                        [key]: value,
                        Error: isNameDifferent ? null : unit.Error,
                        success: isNameDifferent ? null : null
                    }
                } else {
                    return {
                        ...unit,
                        [key]: value
                    }
                }
            }
            return unit;
        }))
    }

    // const onClearValueHandler = async ({keyNameInUnit}) => {
    //     const userDecision = await DecisionModal({
    //         confirmButtonText: "Clear",
    //         message: "Do you really want to clear this data?"
    //     });
    //     if ( !userDecision.isConfirmed ) return;

    //     setUnits( prevUnits => prevUnits.map( (unit, index) => {
    //         if(selectedRowIndexes.includes(index)) {
    //             return {
    //                 ...unit,
    //                 [keyNameInUnit]: null
    //             }
    //         }
    //         return unit;
    //     }))
    // }

    const initialSortModalDefaultSortHandler = () => {
        setShowInitSortModal(false);
    }

    const initialSortModalAscSortHandler = () => {
        tableSortHandler("Name", units, true);
        setShowInitSortModal(false);
    }

    const downloadExampleFileHandler = () => {
        const element = document.createElement('a');
        element.setAttribute('href', 'https://wissp-public.s3.us-east-2.amazonaws.com/templates/UnitsExportExample.xlsx');
        element.setAttribute('download', "UnitsExportExample");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const [isDrag, setIsDrag] = useState(false);

    const dragEndHandler = () => {
        if(isDrag){
            setIsDrag(false);
        }
    }

    const dragOverHandler = (e) => {
        e.preventDefault(); 
        e.stopPropagation();

        if(!isDrag){
            setIsDrag(true);
        }
    }

    const dropHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        dragEndHandler();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file
            if (ev.dataTransfer.items[0].kind === "file") {
                const file = ev.dataTransfer.items[0].getAsFile();
                handleFileAsync(file);
            }
          } else {
            // Use DataTransfer interface to access the file
            const file = ev.dataTransfer.files[0];
            handleFileAsync(file);
          }
    }

    return (
        <>
            {isSaving && <LoaderWithBackdrop />}

            <InitialSortModal 
                show={showInitSortModal}
                onDefaultSort={initialSortModalDefaultSortHandler}
                onAscSort={initialSortModalAscSortHandler}
            />
        
            <EditModal 
                show={showEditModal}
                onClose={closeEditModal}
                colId={editedRowData.colId}
                colName={editedRowData.colName}
                actionType={editedRowData.actionType}
                selectedRowIndexes={selectedRowIndexes}
                units={units}
                unitVar={editedRowData.unitVar}
                onSave={onChangeValue}
                features={features}
                // unitsSaveStatuses={unitsSaveStatuses}
            />

            <SaveStatusModal 
                show={showStatusModal}
                onClose={onCloseStatusModal}
                // unitsStatus={unitsSaveStatuses}
                units={units}
            />

            <main className="my-2 mx-2 my-sm-4 mx-sm-3">
                <Form.Control
                    ref={uploadInputRef}
                    hidden={true}
                    size="lg"
                    aria-label="Choose file"
                    aria-describedby="inputGroup-sizing-sm"
                    type="file"
                    onChange={(e) => {handleFileAsync(e.target.files[0])}}
                />

                <BackNav className="m-0 p-0 mb-3 px-2"
                    currentPage="import units"
                    disabled={isSaving || loading}
                    pages={[{
                        title: "properties",
                        goTo: "/properties"
                    },
                    {
                        title: "property units",
                        goTo: `/building-info?building-id=${buildingID}&active-tab=units`
                    }]}
                />

                {!loading && (
                    <>
                        <Row className="m-0 p-0 p-2 p-md-4 mb-3 lightGray rounded align-items-center justify-content-between">
                            <Col className="p-0" xs={12} sm="auto">
                                <h1 className="text-center fs-3 fw-bold mb-2 mb-sm-0">Import Units</h1>
                            </Col>
                            <Col className="p-0" xs={12} sm="auto">
                                <ButtonPrimary
                                    onClick={downloadExampleFileHandler}
                                >
                                    <p className="m-0 p-0 fs-6 text-white">Download Formatted Template</p>
                                </ButtonPrimary>
                            </Col>
                        </Row>

                        <Row className="m-0 p-0 mb-3">
                            <Col xs={12} md={6} lg={5} className="m-0 p-0">
                                <Row className="m-0 p-0 p-2 p-md-4 lightGray rounded align-items-center h-100">
                                    <Col className="m-0 p-0 h-100">
                                        <Row className="m-0 p-0">
                                            <Col className="m-0 p-0" xs="auto">
                                                <LazyImage src={pictures && pictures[0]} style={{ objectFit: "cover", aspectRatio: '1/1', width: "100px" }} />
                                            </Col>
                                            <Col className="m-0 ms-2 p-0">
                                                <Row className="m-0 p-0">
                                                    <Col className="m-0 p-0" xs={12}>
                                                        <h2 className="mx-0 px-0 fw-bold fs-5">
                                                            {property ? property.Name : <>&nbsp;</>}
                                                        </h2>
                                                    </Col>
                                                    <Col className="m-0 p-0" xs={12}>
                                                        <h6 className="mx-0 px-0">
                                                            { property ? (
                                                            <span className="text-muted"><i className="bi bi-geo-alt"></i> {property.Address.replace(/\$\*\|\*\$/, ' ')}, {property.City}, {property.State}</span>
                                                            ) : <>&nbsp;</>}
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md={6} lg={7} className="m-0 p-0 mt-3 mt-md-0">
                                <Row className="m-0 ms-md-3 p-2 lightGray rounded align-items-center h-100">
                                    <Col className={`m-0 p-3 rounded h-100 cursor-pointer import-units__download-box ${isDrag ? "import-units__download-box--drag": ""} d-flex align-items-center justify-content-center`}
                                        onClick={() => { uploadInputRef.current.click() }} 
                                        onDrop={ dropHandler }
                                        onDragOver={ dragOverHandler }
                                        onDragLeave={dragEndHandler}
                                    >
                                        <Row className="m-0 p-0 align-items-center justify-content-center">
                                            <Col className="m-0 p-0" xs="auto" style={{userSelect: 'none'}}>
                                                <h3 className="fs-6 text-center fw-bold mb-1"><span className="d-md-none">Click here to select a file</span><span className="d-none d-md-inline">Drop a file or click here to select one</span></h3>
                                                <p className="m-0 p-0 text-center text-sm-start" style={{fontSize: "0.85rem", color: "rgba(0,0,0,0.7)"}}>Accepted file format: .xlsx</p>
                                            </Col>
                                            <Col className="m-0 p-0 ps-3" xs="auto" style={{userSelect: 'none'}}>
                                                <i className="d-flex bi bi-box-arrow-in-down fs-1"></i>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        { units && (
                            <>
                                {/* MOBILE */}
                                <div className="m-0 mb-3 d-md-none">
                                    <Row className="m-0 mb-2">
                                        <Col className="p-0 me-1" xs="auto" style={{width: 90}}>
                                            <div className="darkerGray rounded p-2 fw-bold text-uppercase">
                                                <p className="table-header__text user-select-none">Status</p>
                                            </div>
                                        </Col>
                                        <Col className="p-0">
                                            <div className="darkerGray rounded p-2 fw-bold text-uppercase d-flex align-items-center cursor-pointer"
                                                onClick={() => tableSortHandler("Name", units, true)}
                                            >
                                                <p className="table-header__text user-select-none">Unit Name</p>
                                                <span className="ms-1">
                                                    { (tableSort.colName !== "Name" || (tableSort.colName === "Name" && tableSort.direction === null)) && (<i className="bi bi-dot d-flex"></i>) }
                                                    { (tableSort.colName === "Name" && tableSort.direction === "desc") && (<i className="bi bi-chevron-down d-flex"></i>) }
                                                    { (tableSort.colName === "Name" && tableSort.direction === "asc") && (<i className="bi bi-chevron-up d-flex"></i>) }
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="m-0 p-0 mb-3">
                                        <CustomAccordion defaultActiveKey="999" accordionClasses="p-0">
                                            { units.map((unit, i) => {
                                                return (
                                                    <CustomAccordionElement
                                                        className="m-0 p-0"
                                                        key={i}
                                                        eventKey={i}
                                                        customHeaderElement={
                                                            <CustomManagerHeader
                                                                unit={unit}
                                                                eventKey={i}
                                                                className="mb-1"
                                                            />
                                                        }
                                                    >
                                                        
                                                        <div className=" border rounded mb-2 mt-1">
                                                            {
                                                                COL_NAMES.map( (col, index) => {
                                                                    if(col.showInMobile) {
                                                                        return (
                                                                            <Row className={`m-0 p-2 rounded
                                                                                ${ (unit.success === true && !unit.Error) ? 'import-added' : ''}
                                                                                ${ col.id === "name" && (unit.Error === "Unit Name Already Exists") ? 'import-cell-error' : ''}
                                                                            `}
                                                                                onClick={ () => {
                                                                                    // Disable editing when unit was successfully added
                                                                                    if((unit.success === true && !unit.Error)) {
                                                                                        return;
                                                                                    }
                                                                                    actionHandler({ colId: col.id, colType: col.type, colName: col.name, actionType: ACTION_TYPES.EDIT, unitVar: col.reqName});
                                                                                    selectRowHandler(i);
                                                                                }}
                                                                            >
                                                                                <Col className="p-0 d-flex fs-7 flex-grow-1 fw-bold" xs={["amenities", "accessibilities", "additional"].includes(col.id) ? "12": "auto"}>
                                                                                    {col.name}:
                                                                                </Col>
                                                                                <Col className="p-0 d-flex" xs={["amenities", "accessibilities", "additional", "notes"].includes(col.id) ? "12": "auto"}>
                                                                                    <p className="m-0 p-0 fs-7" style={{whiteSpace: "nowrap"}}>
                                                                                        { col.id === "vacant" && (unit[col.reqName] === true ? "Yes" : "No") }
                                                                                        { col.id === "rent" && `$${unit[col.reqName]}` }
                                                                                        { ["bedrooms", "bathrooms", "floor", "name"].includes(col.id) && unit[col.reqName]}
                                                                                    </p>

                                                                                    {
                                                                                        ["amenities", "accessibilities", "additional", "notes"].includes(col.id) && (
                                                                                            <>
                                                                                                {(col.id === "amenities") &&
                                                                                                    (
                                                                                                        <div className="d-flex flex-wrap">
                                                                                                            {
                                                                                                                Array.isArray(unit.Amenities) && unit.Amenities.map( (amenity, index) => (
                                                                                                                    <div className="d-flex ps-1 pb-1" key={index}>
                                                                                                                        <p className="m-0 p-0 text-white d-inline-block bg-success px-1 rounded fs-7">{amenity}</p>
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                }

                                                                                                {(col.id === "accessibilities") &&
                                                                                                    (
                                                                                                        <div className="d-flex flex-wrap">
                                                                                                            {
                                                                                                                Array.isArray(unit.Accessibilities) && unit.Accessibilities.map( (accessibility, index) => (
                                                                                                                    <div className="d-flex ps-1 pb-1" key={index}>
                                                                                                                        <p className="m-0 p-0 text-white d-inline-block bg-success px-1 rounded fs-7">{accessibility}</p>
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                }

                                                                                                {((col.id === "additional") || (col.id === "notes")) &&
                                                                                                    (
                                                                                                        <div className="d-flex flex-wrap ps-1 pb-1">
                                                                                                             <p className="m-0 p-0 fs-7">
                                                                                                                { unit[col.reqName] ?? "-" }
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                        </div>

                                                    </CustomAccordionElement>
                                                )
                                            })}
                                        </CustomAccordion>
                                    </Row>
                                </div>

                                {/* DESKTOP */}
                                <Row className="m-0 overflow-hidden flex-grow-1 mb-3 d-none d-md-flex">
                                    <Col className="p-0 position-relative">
                                        <div className="table-responsive" style={{height: '100%'}}>
                                            <table className="table table-borderless mb-0">
                                                <thead className="position-sticky top-0 left-0">
                                                    <tr>
                                                        { COL_NAMES.map( (col, index) => (
                                                            <th key={index} scope="col" className="table-header__wrapper flex-grow-1">
                                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 px-3 rounded border border-1 border-light"
                                                                    onClick={() => tableSortHandler(col.reqName, units, col.isSortable)}
                                                                >
                                                                    <p className="table-header__text user-select-none">{col.name}</p>
                                                                    { col.isSortable && (
                                                                        <span className="ms-1">
                                                                            { (tableSort.colName !== col.reqName || (tableSort.colName === col.reqName && tableSort.direction === null)) && (<i className="bi bi-dot d-flex"></i>) }
                                                                            { (tableSort.colName === col.reqName && tableSort.direction === "desc") && (<i className="bi bi-chevron-down d-flex"></i>) }
                                                                            { (tableSort.colName === col.reqName && tableSort.direction === "asc") && (<i className="bi bi-chevron-up d-flex"></i>) }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {units && units.map( (unit, indexOfUnit) => (
                                                        <tr key={indexOfUnit}>{
                                                            COL_NAMES.map( (col, index) => {
                                                                if(col.type === "selector") {
                                                                    return (
                                                                        <td key={index} className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer lightGray ${ (unit.success === true && !unit.Error) ? 'import-added' : ''}`}>
                                                                            <div className="d-flex flex-column h-100">
                                                                                <div className="d-flex justify-content-center align-items-center p-2">
                                                                                    { unit.success === true && !unit.Error && (
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            delay={{ show: 100 }}
                                                                                            overlay={(
                                                                                                <Tooltip>
                                                                                                    Added
                                                                                                </Tooltip>
                                                                                            )}
                                                                                        >
                                                                                            <div className="p-1 bg-success rounded me-1">
                                                                                                <i className="d-flex bi bi-cloud-plus text-light fs-5"></i>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                    
                                                                                        {/*  <OverlayTrigger
                                                                                             placement="top"
                                                                                             delay={{ show: 100 }}
                                                                                             overlay={(
                                                                                                 <Tooltip>
                                                                                                     Verified
                                                                                                 </Tooltip>
                                                                                             )}
                                                                                         >
                                                                                             <div className="p-1 bg-primary rounded me-1">
                                                                                                 <i className="d-flex bi bi-check2 text-light fs-5"></i>
                                                                                             </div>
                                                                                         </OverlayTrigger> */}
                                                                                    
                                                                                    { ( unit.success === false ||
                                                                                        (unit.Error)) && (
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            delay={{ show: 100 }}
                                                                                            overlay={(
                                                                                                <Tooltip>
                                                                                                    Invalid value(s)
                                                                                                </Tooltip>
                                                                                            )}
                                                                                        >
                                                                                            <div className="p-1 bg-danger rounded">
                                                                                                <i className="d-flex bi bi-exclamation-lg text-light fs-5"></i>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } 
                                                                if(col.id === "amenities") {
                                                                    return (
                                                                        <td key={index} 
                                                                            className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer lightGray ${ (unit.success === true && !unit.Error) ? 'import-added' : ''}`}
                                                                            onClick={ () => {
                                                                                // Disable editing when unit was successfully added
                                                                                if((unit.success === true && !unit.Error)) {
                                                                                    return;
                                                                                }
                                                                                actionHandler({ colId: col.id, colType: col.type, colName: col.name, actionType: ACTION_TYPES.EDIT, unitVar: col.reqName});
                                                                                selectRowHandler(indexOfUnit);
                                                                            }}
                                                                        >
                                                                            <div className="d-flex flex-column h-100">
                                                                                <div className="d-flex flex-column justify-content-center p-2">
                                                                                    {
                                                                                        Array.isArray(unit.Amenities) && unit.Amenities.map( (amenity, index) => (
                                                                                            <div className="" key={index}>
                                                                                                <p className="m-0 p-0 text-white d-inline-block bg-success px-1 rounded" style={{fontSize: "0.85rem", whiteSpace: "nowrap"}}>{amenity}</p>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                }
                                                                if(col.id === "accessibilities") {
                                                                    return (
                                                                        <td key={index} 
                                                                            className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer lightGray ${(unit.success === true && !unit.Error) ? 'import-added' : ''}`}
                                                                            onClick={ () => {
                                                                                if((unit.success === true && !unit.Error)) {
                                                                                    return;
                                                                                }
                                                                                actionHandler({ colId: col.id, colType: col.type, colName: col.name, actionType: ACTION_TYPES.EDIT, unitVar: col.reqName});
                                                                                selectRowHandler(indexOfUnit);
                                                                            }}
                                                                        >
                                                                            <div className="d-flex flex-column h-100">
                                                                                <div className="d-flex flex-column justify-content-center p-2">
                                                                                    {
                                                                                        Array.isArray(unit.Accessibilities) && unit.Accessibilities.map( (accessibility, index) => (
                                                                                            <div className="" key={index}>
                                                                                                <p  className="m-0 p-0 text-white d-inline-block bg-success px-1 rounded" style={{fontSize: "0.85rem", whiteSpace: "nowrap"}}>{accessibility}</p>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                }
                                                                return (
                                                                    <td key={index} 
                                                                        className={`table-body__wrapper align-middle border border-1 border-white rounded cursor-pointer 
                                                                            ${ selectedRowIndexes.includes(indexOfUnit) && editedRowData.colId === col.id  ? 'bg-light-yellow' : 'lightGray' } 
                                                                            ${ (unit.success === true && !unit.Error) ? 'import-added' : ''}
                                                                            ${ col.id === "name" && (unit.Error === "Unit Name Already Exists") ? 'import-cell-error' : ''}
                                                                            `}
                                                                        onClick={ () => {
                                                                            if((unit.success === true && !unit.Error)) {
                                                                                return;
                                                                            }
                                                                            actionHandler({ colId: col.id, colType: col.type, colName: col.name, actionType: ACTION_TYPES.EDIT, unitVar: col.reqName});
                                                                            selectRowHandler(indexOfUnit);
                                                                        }}
                                                                    >
                                                                        <div className="d-flex flex-column h-100">
                                                                            <div className="d-flex justify-content-center align-items-center p-2">
                                                                                <p className="m-0 p-0" style={{fontSize: "0.85rem"}}>{
                                                                                    col.id === "vacant" ?
                                                                                        (unit[col.reqName] === true ? "Yes" : "No")
                                                                                        :
                                                                                        unit[col.reqName]
                                                                                }</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                        
                        <Row className="m-0 p-0 p-2 p-md-4 lightGray rounded justify-content-end">
                            <Col className="p-0 m-0 pe-sm-1 mb-1 mb-sm-0" xs={12} sm={4} md={3} lg={"2"}>
                                <ButtonSecondary
                                    title="Cancel"
                                    onClick={cancelEditHandler}
                                />
                            </Col>

                            { units && (
                                <Col className="p-0 m-0 ps-sm-1" xs={12} sm={4} md={3} lg={"2"}>
                                    <ButtonPrimary
                                        onClick={ saveImportedUnitsHandler }
                                        title="Save"
                                    />
                                </Col>
                            )}
                        </Row>
                    </>
                )}
            </main>
        </>
    )
}

function CustomManagerHeader({
    children,
    eventKey,
    className = "",
    unit,
    ...props
  }) {
    const [opened] = useCustomAcordionContext();
    const isOpen = opened.includes(eventKey);
    return (
      <div
        className={`m-0 p-0 border-0 w-100 h-100 rounded ${className}`}
        {...props}
      >
        <Container className="rounded text-start cursor-pointer m-0 p-0">
          <Row className="m-0" style={{height: 44}}>

            <Col
              xs="auto"
              className={`p-0 p-2 me-1 lightGray rounded h-100`}
              style={{width: 90}}
            >
                <div className="d-flex align-items-center">
                    { unit.success === true && !unit.Error && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 100 }}
                            overlay={(
                                <Tooltip>
                                    Added
                                </Tooltip>
                            )}
                        >
                            <div className="p-1 bg-success rounded me-1">
                                <i className="d-flex bi bi-cloud-plus text-light fs-5"></i>
                            </div>
                        </OverlayTrigger>
                    )}
                    
                        {/*  <OverlayTrigger
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={(
                                    <Tooltip>
                                        Verified
                                    </Tooltip>
                                )}
                            >
                                <div className="p-1 bg-primary rounded me-1">
                                    <i className="d-flex bi bi-check2 text-light fs-5"></i>
                                </div>
                            </OverlayTrigger> */}
                    
                    { ( unit.success === false ||
                        (unit.Error)) && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 100 }}
                            overlay={(
                                <Tooltip>
                                    Invalid value(s)
                                </Tooltip>
                            )}
                        >
                            <div className="p-1 bg-danger rounded">
                                <i className="d-flex bi bi-exclamation-lg text-light fs-5"></i>
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
            </Col>

            <Col className={`p-2 rounded-start h-100 d-flex align-items-center
                ${ (unit.success === true && !unit.Error) ? 'import-added' : 'lightGray'}
                ${ (unit.Error === "Unit Name Already Exists") ? 'import-cell-error' : ''}
            `}>
              <p className="m-0 fs-7">{unit.Name}</p>
            </Col>

            <Col xs="auto" className={`p-2 lightGray rounded-end h-100 d-flex align-items-center
                ${ (unit.success === true && !unit.Error) ? 'import-added' : 'lightGray'}
                ${ (unit.Error === "Unit Name Already Exists") ? 'import-cell-error' : ''}
            `}>
              <i
                className={`d-flex bi bi-chevron-down rotate180 ${
                  isOpen ? "rotate" : ""
                } fs-4 text-muted`}
              ></i>
            </Col>

          </Row>
        </Container>
      </div>
    );
  }

function InitialSortModal({
    show,
    onDefaultSort,
    onAscSort
}){
    return (
        <Modal show={show} onHide={onDefaultSort} size="lg" centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className="text-center fs-5">Your file has been loaded. Please choose if you would like to retain the view as you had it in the excel file or if you would like to sort by the unit names alphabetically</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0 ps-1" xs="auto">
                        <ButtonSecondary onClick={onDefaultSort}> 
                            Keep
                        </ButtonSecondary>
                    </Col>
                    <Col className="m-0 p-0 ps-1" xs="auto">
                        <ButtonPrimary onClick={onAscSort}> 
                            Sort Alphabetically
                        </ButtonPrimary>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

function EditModal({
    show,
    onClose,
    colId,
    colName,
    selectedRowIndexes,
    units,
    unitVar,
    features,
    onSave
}){
    const [amenities, setAmenities] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);

    const [newValue, setNewValue] = useState(null);

    const onChangeHandler = (e) => {
        e.preventDefault();

        let value = e.target.value;
        if(value === "false") { value = false }
        if(value === "true") { value = true }

        setNewValue(value);
    }

    const onCloseHandler = () => {
        setNewValue(null);
        onClose();
    }

    const onSaveHandler = () => {
        let valueToSave = newValue;

        if(colId === "amenities") {
            valueToSave = amenities;
        }
        if(colId === "accessibilities") {
            valueToSave = accessibilities;
        }

        onSave({ key: unitVar, value: valueToSave, indexesOfUnits: selectedRowIndexes });
        setNewValue(null);
        onCloseHandler();
    }

    const toggleFeature = (featureName, setFunction) => {
        setFunction( prevState => {
            if(prevState.includes(featureName)){
                return prevState.filter( feature => feature !== featureName)
            } else {
                return [...prevState, featureName]
            }
        })
    }

    useEffect(() => {
        if(show) {
            setAmenities([]);
            setAccessibilities([]);
            setNewValue(null);
        }
    }, [show])

    const indexOfUnit = selectedRowIndexes[0];

    return (
        <Modal show={show} onHide={onCloseHandler} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{colName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='m-0 p-0'>
                    <Col className='m-0 p-0 pe-1' xs="12" md="6">
                        <p className="m-0 fs-5 px-2">Previous value{Array.isArray(selectedRowIndexes) && selectedRowIndexes.length > 1 ? "s" : ""}</p>
                        <div className="wissp-scroll-box px-2 mt-2" style={{maxHeight: 400, overflow: "auto"}}>
                            { Array.isArray(selectedRowIndexes) && selectedRowIndexes.map( rowIndex => {
                                // TODO: add features display
                                if(colId === "vacant") {
                                    return (<div className="mb-2" key={rowIndex}>
                                        <SelectFloating 
                                            disabled={true}
                                            value={ units[rowIndex][unitVar] }
                                            label={ units[rowIndex].Name }
                                            labelClasses="text-black"
                                            dataArrayWithCustomVal={[['Yes', true], ['No', false]]}
                                        />
                                    </div>)
                                }

                                if((colId === "amenities") || (colId === "accessibilities")) {
                                    return (
                                        <Container fluid className="m-0 mb-2 p-0 border p-2 rounded" key={rowIndex}>
                                            <Row className="m-0 p-0 features-spacing ">
                                                {<p className="p-0 m-0 px-2 fs-7">Name: <b>{units[rowIndex].Name}</b></p> }

                                                <div className="d-flex flex-row flex-wrap justify-content-start p-2">
                                                    {
                                                        units[rowIndex][unitVar].map( (accessibility, index) => (
                                                            <div className="me-1 " key={index}>
                                                                <p className="m-0 p-0 px-2 text-white d-inline-block bg-success px-1 rounded" style={{fontSize: "0.85rem", whiteSpace: "nowrap"}}>{accessibility}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                {/* {units[rowIndex][unitVar].map((data, index) => {
                                                    if (index % 3 !== 0) return (
                                                        <InputBtn 
                                                            key={index}
                                                            index={index}
                                                            name={data}
                                                            onChange={() => {}}
                                                            checked={true}
                                                            disabled={true}
                                                            additionalLabelClass="fs-7"
                                                        />
                                                    )
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="w-100"></div>
                                                            <InputBtn 
                                                                index={index}
                                                                name={data}
                                                                onChange={() => {}}
                                                                checked={true}
                                                                disabled={true}
                                                                additionalLabelClass="fs-7"
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })} */}
                                            </Row>
                                        </Container>

                                    )
                                }

                                return (
                                    <div className="mb-2" key={rowIndex}>
                                        <InputFloating 
                                            disabled={true}
                                            value={ units[rowIndex][unitVar] }
                                            label={ units[rowIndex].Name }
                                            labelClasses="text-black"
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    <Col className='m-0 p-0 ps-1' xs="12" md="6">
                        <p className="m-0 fs-5 px-2"><i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Changes will be applied to the selected units"></i> New value</p>
                        <div className="wissp-scroll-box px-2 mt-2" style={{maxHeight: 400, overflow: "auto"}}>
                            {
                                // TODO: add features display
                                (colId === "vacant") ? (
                                    <SelectFloating 
                                        value={newValue}
                                        onChange={onChangeHandler}
                                        label={colName}
                                        labelClasses="text-black"
                                        dataArrayWithCustomVal={[['', null], ['Yes', true], ['No', false]]}
                                    />
                                ) : (colId === "amenities") ? (
                                    <Container fluid className="m-0 p-0">
                                        <Row className="m-0 p-0 features-spacing ">
                                            {features && features.Amenities && features.Amenities.map((data, index) => {
                                                if (index % 3 !== 0) return (
                                                    <InputBtn 
                                                        key={index}
                                                        index={index}
                                                        name={data}
                                                        onChange={() => toggleFeature(data, setAmenities)}
                                                        checked={amenities.includes(data)}
                                                        additionalLabelClass="fs-7 cursor-pointer"
                                                        containerClasses="mb-1 ms-1"
                                                    />
                                                )
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="w-100" key={index+"amn-s"}></div>
                                                        <InputBtn key={index+"amn-e"}
                                                            index={index}
                                                            name={data}
                                                            onChange={() => toggleFeature(data, setAmenities)}
                                                            checked={amenities.includes(data)}
                                                            additionalLabelClass="fs-7 cursor-pointer"
                                                            containerClasses="mb-1 ms-1"
                                                        />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Row>
                                    </Container>
                                ) : (colId === "accessibilities") ? (
                                    <Container fluid className="m-0 p-0">
                                        <Row className="m-0 p-0 features-spacing ">
                                            {features && features.Accessibilities && features.Accessibilities.map((data, index) => {
                                                if (index % 3 !== 0) return (
                                                    <InputBtn 
                                                        key={index}
                                                        index={index}
                                                        name={data}
                                                        onChange={() => toggleFeature(data, setAccessibilities)}
                                                        checked={accessibilities.includes(data)}
                                                        additionalLabelClass="fs-7 cursor-pointer"
                                                    />
                                                )
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="w-100" key={index+"acc-s"}></div>
                                                        <InputBtn 
                                                            key={index+"acc-e"}
                                                            index={index}
                                                            name={data}
                                                            onChange={() => toggleFeature(data, setAccessibilities)}
                                                            checked={accessibilities.includes(data)}
                                                            additionalLabelClass="fs-7 cursor-pointer"
                                                        />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Row>
                                    </Container>
                                ) : (
                                    <InputFloating 
                                        value={newValue}
                                        onChange={onChangeHandler}
                                        label={colName}
                                        labelClasses="text-black"
                                        type={ (colId === "bedrooms" || colId === "bathrooms" || colId === "rent" || colId === "floor") ? "number" : "text" }
                                    />
                                )
                            }


                        </div>
                    </Col>
                </Row>
                <Row className='m-0'>
                    <Col className="p-0 px-2">
                        {colId === "name" && units && units[indexOfUnit] && (units[indexOfUnit].Error === "Unit Name Already Exists") ? (
                            <p className="m-0 custom_error pt-1">Unit Name Already Exists</p>
                        ) : null}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="m-0 p-0 w-100 justify-content-sm-end">
                    <Col className="m-0 p-0 ps-1 mb-1 mb-sm-0" xs="12" sm="auto">
                        <ButtonSecondary onClick={onCloseHandler}> 
                            Cancel
                        </ButtonSecondary>
                    </Col>
                    <Col className="m-0 p-0 ps-1" xs="12" sm="auto">
                        <ButtonPrimary onClick={onSaveHandler}> 
                            Save
                        </ButtonPrimary>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

function SaveStatusModal({
    show,
    onClose,
    units
}){
    return (
        <Modal show={show} onHide={onClose} size="xl" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Row className='m-0 p-0'>
                    <table className="table table-borderless mb-0">
                        <thead className="position-sticky top-0 left-0">
                            <tr>
                                { [{name: "Unit Name"}, {name: "Status"}].map( (col, index) => (
                                    <th key={index} scope="col" className="table-header__wrapper flex-grow-1">
                                        <div className="d-flex justify-content-center align-items-center darkerGray p-2 px-3 rounded border border-1 border-light">
                                            <p className="table-header__text">{col.name}</p>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            { units && units.map( (unit, index) => (
                                <tr key={index}>
                                    <td className={`table-body__wrapper align-middle border border-1 border-white rounded lightGray`}>
                                        <div className="d-flex flex-column h-100">
                                            <div className="d-flex justify-content-center align-items-center p-2">
                                                <p className="m-0 p-0" style={{fontSize: "0.85rem", whiteSpace: "nowrap"}}>
                                                    { unit.Name }
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`table-body__wrapper align-middle border border-1 border-white rounded lightGray`}>
                                        <div className="d-flex flex-column h-100">
                                            <div className="d-flex justify-content-start align-items-center p-2">
                                                <Row className="m-0 p-0">
                                                    <Col className="m-0 p-0 pe-2 d-flex align-items-center" xs="auto">
                                                        {((unit.success === false) || ((unit.success === true) && unit.Error)) && (<i className="d-flex bi bi-x-square text-danger"></i>) }
                                                        {((unit.success === true) && !unit.Error) && (<i className="d-flex bi bi-check-square text-success"></i>) }
                                                    </Col>
                                                    <Col className="m-0 p-0">
                                                        <p className="m-0 p-0" style={{fontSize: "0.85rem", whiteSpace: "nowrap"}}>
                                                            {(unit.success === false) && (<p className="m-0 p-0 fs-6">Error occured. Please try again later.</p>) }
                                                            {((unit.success === true) && unit.Error) && (<p className="m-0 p-0 fs-6">{unit.Error}</p>) }
                                                            {((unit.success === true) && !unit.Error) && (<p className="m-0 p-0 fs-6">Unit added</p>) }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0" xs="auto">
                        <ButtonSecondary onClick={onClose}> 
                            Close
                        </ButtonSecondary>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
