export const GOOGLE_AUTOCOMPLETE = 'googleAutocomplete';
export const NAME = 'Name';
export const ADDRESS_LINE_1 = 'AddressLine1';
export const ADDRESS_LINE_2 = 'AddressLine2';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP = 'Zip';
export const MISC_AMENITIES = 'MiscAmenities';
export const MISC_ACCESSIBILITIES = 'MiscAccessibilities';
export const MISC_NOTES = 'Notes';
export const LANDLORD_CONTACT_FULLNAME = 'LandlordContactFullName';
export const LANDLORD_CONTACT_EMAIL = 'LandlordContactEmail';
export const LANDLORD_CONTACT_PHONE = 'LandlordContactPhone';
export const LANDLORD_CONTACT_ID = 'LandlordContactID';

export const MANAGERS = 'Managers';
export const MANAGER_ID = 'BuildingManagerID';
export const MANAGER_FULL_NAME = 'FullName';
export const MANAGER_FIRST_NAME = 'FirstName';
export const MANAGER_LAST_NAME = 'LastName';
export const MANAGER_EMAIL = 'Email';
export const MANAGER_PHONE = 'Phone';
export const AMENITIES = 'Amenities';
export const ACCESSIBILITIES = 'Accessibilities';
