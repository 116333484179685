import React from 'react';
import { Row } from 'react-bootstrap';

export default function SideNavigationButton ({
    onClick,
    title="Missing Title",
    children,
    icon
}) {
    return (
        <Row className={`custom_link sidebar_link`} onClick={onClick}>
            {/* { children || title } */}
            <div className="m-0 p-0 d-flex flex-row align-items-center justify-content-start">{ children || (
                <>
                    <i className={`bi ${icon} fs-4 me-2`}></i> 
                    {title}
                </>
            )}</div>
        </Row>
    )
}
