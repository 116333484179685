import React, {useEffect, useState, useContext, useCallback} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Modal,
    Spinner,
    Form
} from 'react-bootstrap';
import Inbox, { SHOW_COMPONENT } from '../components/Shared/Inbox/Inbox';
import MessageContainer from '../components/Shared/Inbox/MessageContainer';
import { AuthContext } from "../contexts/AuthContext";
import { TicketsContext } from '../contexts/TicketsContext';
import { respondToTicket, getTickets } from '../services/tickets';
import { convertDateToMs } from '../utils/dateTransformations';
import BackNav from '../components/Shared/BackNav';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../components/Shared/Buttons/ButtonSecondary';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function LL_TourRequests() {
    const { tickets, setTickets } = useContext(TicketsContext);
    const { token } = useContext(AuthContext);
    let query = useQuery();
    const history = useHistory();
    const ticketID = query.get("request-id");

    const [messages, setMessages] = useState(null);
    const [ticket, setTicket] = useState();
    const [selectedReq, setSelectedReq] = useState(null);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [unitID, setUnitID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ isInfoCollapsed, setIsInfoCollapsed ] = useState(true);

    const getTicketsHandler = async () => {
        setIsLoading(true);
        const response = await getTickets(token, 'tenant');
        
        if (response.success) {
            setTickets(response.data);
        } else {
            ErrorPopup();
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getTicketsHandler();
    }, [])

    const prepareMessage = (message, data) => {
        if(!message) return null;

        const {MessageID, Text, TimeStamp, UserID, Type} = message;

        const prepareData = {
            MessageID,
            from: getMemberData(UserID)[0],
            fromUserType: getMemberData(UserID)[1],
            date: convertDateToMs(TimeStamp),
            message: Text,
            Type
        }

        return prepareData;

        function getMemberData(memberID) {
            const members = data.Members;
            const {FirstName, LastName, UserType} = members[memberID];

            return [`${FirstName.trim()} ${LastName.trim()}`, UserType];
        }
    }

    const prepareMessages = useCallback((data) => {
        if(!data) return null;
        const messages = data.Messages;

        const prepareData = messages && messages.map( (message) => prepareMessage(message, data));

        return prepareData;
    }, []);

    useEffect(() => {
        if(!ticketID || ticketID === 'undefined') history.goBack();
    }, []);

    useEffect(() => {
        if ( tickets && ticketID !== 'undefined') {
            const searchTicket = tickets.find( ticket => ticket.TicketID == ticketID);

            if(!searchTicket) return;

            const Messages = searchTicket.Messages;
            const preparedMessage = prepareMessage( Messages[ latestMessageIndex(Messages) ], searchTicket);

            setSelectedReq(preparedMessage);
            setUnitID(searchTicket.UnitID);

            setTicket(searchTicket);


            setMessages(prepareMessages(searchTicket));
        }


    }, [tickets, ticketID, prepareMessages])

    const closeReplyModalHandler = () => {
        setShowReplyModal(false);
    }

    function latestMessageIndex(Messages) {
        if(Messages.length === 0) return 0;
        return Messages.length - 1;
    }


    let MessageButtons = (
        <Row className="m-0 p-0 justify-content-center">
            <Col xs={6} className="m-0 mb-2 mb-md-0 pe-1 pe-md-2 p-0 d-flex justify-content-center justify-content-md-end">
                <ButtonPrimary 
                    onClick={ () => setIsInfoCollapsed( prevVal => !prevVal ) }
                >

                    <i className="d-block d-md-none bi bi-info-circle"></i>
                    <p className='d-none d-md-block m-0 p-0 fs-6 fw-bold text-light'>{ isInfoCollapsed ? "Show More Information" : "Hide More Information" }</p>
                    
                </ButtonPrimary>
            </Col>

            <Col xs={6} className="m-0 mb-2 mb-md-0 ps-1 p-0 d-flex justify-content-center justify-content-md-end">
                <ButtonPrimary 
                    onClick={() => setShowReplyModal(true)} 
                    disabled={ticket && !ticket.Status}
                >

                    <i className="d-block d-md-none bi bi-reply"></i>
                    <p className='d-none d-md-block m-0 p-0 fs-6 fw-bold text-light'>Reply</p>

                </ButtonPrimary>
            </Col>
        </Row>
    )

    const sendMessageHandler = async (message) => {
        setIsLoading(true);

        const preparedData = {
            Text: message,
            Type: "Response"
        }

        const response = await respondToTicket(token, ticketID, preparedData);

        if (response.success) {
            SuccessPopup("Success! Message has been sent.");

            setMessages(prepareMessages(response.data));

            setTicket(response.data);
            const Messages = response.data.Messages;
            const preparedMessage = prepareMessage( Messages[ latestMessageIndex(Messages) ], response.data);
            setSelectedReq(preparedMessage);
            closeReplyModalHandler();
        } else {
            ErrorPopup("Error! Could not send message. Try again later.");
        }

        setIsLoading(false);
    }

    if(!selectedReq) MessageButtons = null;

    return (
        <>
            <Container fluid style={{maxWidth: 1200}} className="m-auto">
                <BackNav className="m-0 p-0 mb-3"
                    currentPage="tour request"
                    pages={[{
                        title: "Requests",
                        goTo: "/messages"
                    }
                    ]}
                />
            </Container>

            <Container fluid className="p-3 pt-0 m-auto">
                <Inbox 
                    mobileMenuElements={null}
                    activeComponent={SHOW_COMPONENT.MESSAGE}
                    messageComponent={
                        <MessageContainer 
                            showHeader={true}
                            unitID={unitID}
                            buttons={MessageButtons}
                            selectedReq={selectedReq}
                            UnitInfo={ticket && ticket.UnitInfo}
                            isInfoCollapsed={isInfoCollapsed}
                            messages={messages}
                            ticket={ticket}

                            isTenant={true}
                            isLoading={isLoading}
                        />
                    }
                />
            </Container>

            <Modal 
                onHide={closeReplyModalHandler}
                show={showReplyModal} 
                size="xl" 
                aria-labelledby="contained-modal-title-vcenter" 
                centered
            >
                <Container className="m-0 p-0 px-4 position-relative w-100">
                    <BasicMessageGenerator 
                        title="Add any additional details if neccessary:"
                        customMessage="[Input your message here]"
                        requestData={selectedReq}
                        sendMessageHandler={sendMessageHandler}
                        isInput={true}
                        inputMinRows={6}
                        onCancel={closeReplyModalHandler}
                    />
                </Container>
                { isLoading && (
                    <Row className="position-absolute w-100 h-100 m-0 p-0 d-flex justify-content-center align-items-center" style={{background: 'rgba(0,0,0,0.1)'}}>
                        <Spinner animation="border" role="status"/>
                    </Row> 
                    )
                }
            </Modal>
        </>
    )
}


function BasicMessageGenerator({
    isInput=false, 
    title, 
    requestData,
    customMessage="",
    UnitID,
    sendMessageHandler,
    inputMinRows=8,
    onCancel
}) {

    const [message, setMessage] = useState("");
    useEffect(() => {
        const defaultMessage = `Hello,\n\n${customMessage + "\n\n"}Thank you!`;

        setMessage(defaultMessage);
        
    }, [requestData, UnitID]);

    const onClickSendBtnHandler = () => {

      
        sendMessageHandler(message);
    }

    let messageInContainer = (
        <Col className="border border-4 m-0 p-4 pt-3">
            <p className="m-0 p-0 fs-6">Hello,</p>
            <br/>
            <p className="m-0 p-0 fs-6">{customMessage}</p>
            <br/>
            <p className="m-0 p-0 fs-6">Thank you!</p>
        </Col>
    )

    return (
        <>
            <Container className="m-0 my-5 p-0 montserrat">
                <Row className="m-0 p-0">
                    <h6 className="m-0 mb-4 p-0 fw-bold">{title}</h6>
                </Row>
                <Row className="m-0 p-0">

                    {   !isInput && messageInContainer  }

                    {   isInput &&
                        <Form.Control 
                            onChange={(e) => setMessage(e.target.value)}
                            rows={inputMinRows}
                            className="p-2 px-4 fs-6"
                            as="textarea"  
                            value={message}   
                        />
                    }

                </Row>
                
                <Row className="m-0 mt-4 p-0 justify-content-end">
                    <Col className="m-0 p-0 pe-2" xs="auto">
                        <ButtonSecondary
                            title="Cancel"
                            onClick={onCancel}
                        />
                    </Col>
                    <Col className="m-0 p-0" xs="auto">
                        <ButtonPrimary 
                            title="Send Message"
                            onClick={onClickSendBtnHandler}
                        />
                    </Col>
                </Row>

            </Container>
        </>
    )
}
