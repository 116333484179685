import React from 'react';

const Backdrop = ({show, close, style, className, children}) => {
    return (
        show ? (
        <div className={`backdrop ${className}`}
            onClick={close}
            style={style}
        >{children}</div>) : null
    )
}

export default Backdrop;