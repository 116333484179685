export const NOTIFICATION_ID = "NotificationID";
export const START_DATE = "StartDate";
export const END_DATE = "EndDate";
export const NOTIFICATION_TYPE_ID = "NotificationTypeID";
export const SEND_NOW = "SendNow";
export const HOUR = "Hour";
export const DAY = "Day";
export const GROUPS = "Groups";
export const GROUP_IDS = "GroupIDs";
export const CLIENT_IDS = "ClientIDs";
export const MESSAGE_SPEC = "MessageSpec";
export const MESSAGE_TYPE = "MessageType";
export const MESSAGE = "Message";
export const TITLE = "Title";
export const IMAGE_BLOB = "ImageBlob";
export const FREQUENCY_PLAN = "FrequencyPlan";
export const FREQUENCY = "Frequency";
export const DAYS_OF_THE_WEEK = "DaysOfTheWeek";
export const DESCRIPTION = "Description";
export const NAME = "Name";
export const ENABLED = "Enabled";
