import React from 'react';
import {
    Row,
    Col,
    Form
} from 'react-bootstrap';

import PhoneWithMask from '../Shared/Inputs/PhoneWithMask';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import InputFloating from '../Shared/Inputs/InputFloating';
import DecisionModal from '../Shared/InfoPopups/DecisionModal';


export default function FormerLandlordCard({
    index, 
    editMode,
    data, 
    onDelete, 
    canDelete=true,
    prefix,
    register,
    errors,
    watch
}) {
    const DescriptionWatch = watch(`${prefix}Description`, '');

    const getError = errors.FormerLandlords?.[index];

    const deleteHandler = async () => {
        const userDecision = await DecisionModal({});

        if ( !userDecision.isConfirmed ) return;

        onDelete(index);
    }

    return (
        <Row className="p-0 m-0 pt-2 ps-md-3">
            { !editMode &&
                <Row className="m-0 p-0 position-relative row-cols-1">
                
                    <Col className="m-0 p-0" xs={12} md={6}>
                        <p className="m-0 "><b>{data && data.Name && `${data.Name}: `}</b>{data && data.Duration}, {data && data.Number}</p>
                    </Col>

                    <Col className="m-0 p-0">
                        <p className="m-0 ">{data && data.Description}</p>
                    </Col>

                </Row>
            }

            { editMode &&
                // <Row className="m-0 p-3 position-relative">
                <Row className="m-0 p-0 py-3">
                    <Col className="m-0 p-0 me-2" xs={12}>
                        <Row className="justify-content-end mb-2">
                            <Col xs="auto">
                                { (canDelete && editMode) &&
                                    <ButtonPrimary 
                                        onClick={ deleteHandler }
                                        additionalClassName="bg-danger border-danger"
                                        type="button"
                                    >
                                        <i className="bi bi-x-lg"></i>
                                    </ButtonPrimary>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Form className="m-0 p-0">
                        <Row className="m-0 p-0">
                            <Col xs={12} sm={6} md={3} className="m-0 p-0 pe-sm-2 pb-2">
                                <InputFloating 
                                    label="Landlord Name"
                                    register={register(`${prefix}Name`)}
                                    showError={true}
                                    errorMessage={getError?.Name?.message}
                                />
                            </Col>

                            <Col xs={12} sm={6} md={4} className="m-0 p-0 pe-sm-2 pb-2">
                                <PhoneWithMask 
                                    register={register(`${prefix}Number`)}
                                    label="Landlord Phone Number"
                                    showError={true}
                                    errorMessage={getError?.Number?.message}
                                />
                            </Col>

                            <Col xs={12} md={5} className="m-0 p-0 pb-2">
                                <InputFloating 
                                    label="How long have you lived at this place"
                                    register={register(`${prefix}Duration`)}
                                    showError={true}
                                    errorMessage={getError?.Duration?.message}
                                />
                            </Col>
                            
                            <Col className="m-0 p-0" xs={12}>
                                <InputFloating 
                                    label={DescriptionWatch ? "Description: " : "Please describe your living experience (optional)"}
                                    register={register(`${prefix}Description`)}
                                    showError={true}
                                    errorMessage={getError?.Description?.message}
                                />
                            </Col>
                        </Row>   
                    </Form>
            </Row>
            }
        </Row>
    )
} 
