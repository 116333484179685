import React from 'react';
import {Row, Col} from 'react-bootstrap';

import InputFloating from '../Shared/Inputs/InputFloating';
import PhoneWithMask from '../Shared/Inputs/PhoneWithMask';
import DateInput from '../Shared/Inputs/DateInput';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

export default function OccupantFields({
    deleteOccupantHandler,
    vacantWatch,
    setValue,
    register,
    errors,
    watchOccupantLeaseStart,
    watchOccupantLeaseEnd,
    isEditMode=false,
    s_registerNameForVacant,
    s_registerNameForOccupantFullName,
    s_registerNameForOccupantPhoneNumber,
    s_registerNameForOccupantEmail,
    s_registerNameForOccupantLeaseStart,
    s_registerNameForOccupantLeaseEnd,
    s_registerNameForOccupantNotes
}) {
    return (
        <Row className="m-0 p-0">
            <Col className="m-0 p-0" xs={12}>
                <h3 className="p-0 m-0 mb-2 fw-bold">Occupant Information</h3>
            </Col>
        
            <Col className="m-0 p-0" xs={12}>
                <Row className="m-0 p-0 justify-content-center flex-column flex-sm-row">
                    <Col className="m-0 mb-2 me-sm-2 p-0 text-center">
                        <input className="acc-variant-primary"
                            id="Vacant"
                            type="radio"
                            name="isVacant"
                            onChange={deleteOccupantHandler}
                            checked={vacantWatch}
                        />
                        <label htmlFor="Vacant" className="w-100 fw-bolder darkerGray rounded fs-6 d-flex justify-content-center align-items-center" style={{height: 38}}>Vacant</label>
                    </Col>

                    <Col className="m-0 mb-2 p-0 text-center">
                        <input className="acc-variant-primary"
                            id="Occupied"
                            type="radio"
                            name="isVacant"
                            onChange={() => setValue(s_registerNameForVacant, false)}
                            checked={!vacantWatch}
                        />
                        <label htmlFor="Occupied" className="w-100 fw-bolder darkerGray rounded fs-6 d-flex justify-content-center align-items-center" style={{height: 38}}>Occupied</label>
                    </Col>

                    <p className="custom_error text-center">
                        {errors[s_registerNameForVacant]?.message}
                    </p>
                </Row>
            </Col>

          
            <Col className={`m-0 p-0 mt-2 ${!vacantWatch ? "" : "d-none"}`} xs={12}>
                <Row className="m-0 p-0 row-cols-1 row-cols-sm-2 row-cols-md-3">
                    <Col className="m-0 p-0 pe-sm-1 mb-3">
                        <InputFloating 
                            label="Full Name"
                            register={register(s_registerNameForOccupantFullName)}
                            showError={true}
                            errorMessage={errors[s_registerNameForOccupantFullName]?.message}
                        />
                    </Col>
                    <Col className="m-0 p-0 ps-sm-1 pe-md-1 mb-3">
                        <PhoneWithMask
                            label="Phone Number"
                            register={register(s_registerNameForOccupantPhoneNumber)}
                            showError={true}
                            errorMessage={errors[s_registerNameForOccupantPhoneNumber]?.message}
                        />
                    </Col>
                    <Col className="m-0 p-0 ps-md-1 mb-3" xs={12} md={4}>
                        <InputFloating
                            type="email" 
                            label="Email address"
                            register={register(s_registerNameForOccupantEmail)}
                            showError={true}
                            errorMessage={errors[s_registerNameForOccupantEmail]?.message}
                        />
                    </Col>
                    <Col className="m-0 p-0 pe-sm-1" md={6}>
                        <DateInput
                            label="Lease Start (optional)"
                            placeholder="Lease Start"
                            setDate={(value) => setValue(s_registerNameForOccupantLeaseStart, value)}
                            date={watchOccupantLeaseStart}
                            error={errors[s_registerNameForOccupantLeaseStart]?.message}
                        />
                    </Col>
                    <Col className="m-0 p-0 ps-sm-1" md={6}>
                        <DateInput
                            label="Lease End (optional)"
                            placeholder="Lease End"
                            setDate={(value) => setValue(s_registerNameForOccupantLeaseEnd, value)}
                            date={watchOccupantLeaseEnd}
                            error={errors[s_registerNameForOccupantLeaseEnd]?.message}
                        />
                    </Col>

                </Row>
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        <TextAreaFloating 
                            placeholder="Notes on occupant"
                            label="Notes on occupant (optional)"
                            register={register(s_registerNameForOccupantNotes)}
                            showError={true}
                            errorMessage={errors[s_registerNameForOccupantNotes]?.message}
                        />
                    </Col>
                </Row>

                { isEditMode && (
                    <Row className="mt-3">
                        <Col className="text-center">
                            <ButtonPrimary 
                                title="Update Occupant Information"
                            />
                        </Col>
                    </Row>
                )}

            </Col>
        </Row>
    )
}
