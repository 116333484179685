import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


export default function BackNav({
    pages,
    currentPage='Test',
    disabled=false,
    className="m-0 p-0 mx-md-4 px-md-4"
}) {
    const history = useHistory();

    const clickHandler = (goTo) => {
        if(goTo) {
            history.push(goTo);
            return;
        }
        history.goBack();
    }

    return (
        <Row className={`${className} pt-3 montserrat`} style={{maxWidth: 1200}}>
            { pages && pages.map( ({title, goTo}) => (
                <Col className="m-0 p-0 pe-2" xs={"auto"} key={title}>
                    <Button className="m-0 p-0 border-0" style={{background: 'transparent'}} onClick={() => clickHandler(goTo)} disabled={disabled}>
                        <h6 className="m-0 p-0 text-dark fw-bold d-flex align-items-center text-capitalize">
                            { title }
                        </h6>
                    </Button>
                    <i className="bi bi-caret-right-fill ps-2" style={{fontSize: ".8rem"}}></i>
                </Col>
            ))
            }

            { currentPage && (  
                <Col className="m-0 p-0" xs={"auto"}>

                    <Button disabled className="h-100 m-0 p-0 border-0" style={{background: 'transparent'}}>

                        <h6 className="m-0 p-0 text-dark fw-bold d-flex align-items-center text-capitalize">
                            { currentPage }
                        </h6>
                    </Button>
                </Col>
            )}

        </Row>
    )
}