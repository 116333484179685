import React, { useState, useContext, useEffect } from "react";
import {
    Container,
    Button,
    Row,
    Col,
    Nav,
    NavDropdown,
    Offcanvas,
    Card,

} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import Loader from "../components/Shared/Loader";

export default function Requests() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [sortBy, setSorting] = useState("Requests");
    const { token } = useContext(AuthContext);
    const [units, setUnits] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchedUnits();
    }, [token]);

    async function fetchedUnits() {
        try {
            const response = await fetch(
                "https://api.wissp.services/api/user/buildings",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    redirect: "follow",
                }
            );
            let fetchedUnits = await response.json();
            setUnits(JSON.parse(fetchedUnits));
            setLoading(false);

        } catch (err) {
          
        }
    }


    if (loading) return (<Loader />);
    
    return (
        <Container fluid className="p-5 pt-3">
            <Offcanvas show={show} onHide={handleClose} bg="primary">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h1 className="mb-0">
                            <Link to="/" className="custom_title">
                                WISSP
                            </Link>
                        </h1>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <Nav className="d-flex flex-column align-items-center ">
                        <NavLink
                            to="/properties"
                            className="wNav w-100 mb-3"
                            activeClassName="activeNav"
                        >
                            <Button className="w-100">Properties</Button>
                        </NavLink>

                        <NavLink
                            to="/requests"
                            className="wNav w-100 mb-3"
                            activeClassName="activeNav"
                        >
                            <Button className="w-100">Requests</Button>
                        </NavLink>

                        <NavLink
                            to="/tenant"
                            className="wNav w-100 mb-3"
                            activeClassName="activeNav"
                        >
                            <Button className="w-100">Tenant</Button>
                        </NavLink>
                    </Nav>
                    <Link to="/add" className="">
                        <Button
                            bsPrefix="darkerGray"
                            variant="secondary"
                            className="fw-bold p-2 mb-3"
                        >
                            + Add New Unit
                        </Button>
                    </Link>
                    <span className="fw-bold d-block">Sort By:</span>
                    <NavDropdown
                        title={sortBy}
                        id="basic-nav-dropdown"
                        className="d-inline w-25"
                    >
                        <NavDropdown.Item href="#" onClick={() => setSorting("Requests")}>
                            Requests
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#" onClick={() => setSorting("Price")}>
                            Price
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href="#"
                            onClick={() => setSorting("Unit Number")}
                        >
                            Unit Number
                        </NavDropdown.Item>
                    </NavDropdown>
                    
                </Offcanvas.Body>
            </Offcanvas>

            <Row className="pt-3">
                <Col>
                    <Button
                        className="fw-bold d-md-none d-sm-flex"
                        variant="primary"
                        onClick={handleShow}
                    >
                        =
                    </Button>
                    <Nav className="d-flex align-items-center d-none d-md-flex ">
                        <NavLink
                            to="/properties"
                            className="wNav"
                            activeClassName="activeNav"
                        >
                            Properties
                        </NavLink>
                        <hr className="verticalDivider"></hr>
                        <NavLink
                            to="/requests"
                            className="wNav"
                            activeClassName="activeNav"
                        >
                            Requests
                        </NavLink>
                        <hr className="verticalDivider"></hr>
                        <NavLink to="/tenants" className="wNav" activeClassName="activeNav">
                            Tenants
                        </NavLink>
                    </Nav>
                </Col>
                <Col className="d-flex align-items-center justify-content-end d-none d-md-flex">
                    <span className="fw-bold">Sort By:</span>
                    <NavDropdown title={sortBy} id="basic-nav-dropdown">
                        <NavDropdown.Item href="#" onClick={() => setSorting("Requests")}>
                            Requests
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#" onClick={() => setSorting("Price")}>
                            Price
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href="#"
                            onClick={() => setSorting("Unit Number")}
                        >
                            Unit Number
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Link to="/add">
                        <Button
                            bsPrefix="darkerGray"
                            variant="secondary"
                            className="fw-bold p-2"
                        >
                            + Add New Unit
                        </Button>
                    </Link>
                </Col>
            </Row>

            {units && units.map((building, buildingIndex) => {
                return building.Units.map((unit, unitsIndex) => {
                    return (
                        <Row key={unitsIndex}>
                            <PropertiesUnit unitsIndex={unitsIndex} unit={unit} building={building} />
                        </Row>
                    )
                })
            })
            }
        </Container>
    )
}

export function PropertiesUnit({ unitsIndex, unit, building }) {
    return (
        <Container fluid className="p-3 d-flex justify-content-center">
            <Card className="border-0 shadow w-100" style={{ height: "100%" }}>
                <Card.Body className="p-0 lightGray overflow-hidden" >
                    <Row className="mt-3 mx-3" >
                        <Col >
                            <h4 className="mb-0"><span className="fw-bold">Unit {unit.UnitID}:</span>&nbsp; listed
                                at ${unit.Rent} per month, {unit.Name}</h4>

                            <h4><span className="fw-bold">Name:</span> Tom Maren</h4>
                        </Col>
                        <Col className="text-end">
                            <h4 className="mb-0"><span className="fw-bold">
                                {building.Name}
                            </span></h4>
                            <h4><span>{building.Address.replace(/\$\*\|\*\$/, ' ')}, {building.City}, {building.State}</span></h4>
                        </Col>
                    </Row>
                    <Row className="mx-3">
                        <Col className="mt-2">
                            <h4><span className="fw-bold">Message:</span>{" "}Hello! My name is Tom and I am interested in looking at your unit 2034 at the West Village Apartments, I have a housing voucher that I plan on using. When would you be available for me to come…</h4>
                        </Col>
                    </Row>
                    <Row className="mx-1">
                        <Col>

                        </Col>
                        <Col className="text-center">
                            {/* <Link to="/SingleProperty">See More</Link> */}
                            <Link to={`/tourRequest/${unit.BuildingID}/unit/${unit.UnitID}`}>See More</Link>
                        </Col>
                        <Col className="text-end">
                            <p className="text-muted">Monday, April 19th 2020 - 7:11 am</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}
