import React, { useEffect } from "react";
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString, number as YupNumber } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import { RENT, PAYMENT_DETAILS, PAYMENT_DUE_DATE, MISC_NOTES} from '../../constants/unitFormConstants';
import AdditionalInfoFields from "../UnitFormElements/AdditionalInfoFields";

const schema = YupObject().shape({
    [RENT]: YupNumber()
        .required('Required')
        .transform(value => (isNaN(value) ? undefined: value))
        .min(0, 'Rent price can\'t be less than 0')
        .max(99999, 'Rent price can\'t be greater than 99999')
        .test(
            "maxDigitsAfterDecimal",
            "Rent field must have 2 digits after decimal or less",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
    [PAYMENT_DETAILS]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [PAYMENT_DUE_DATE]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [MISC_NOTES]: YupString()
        .notRequired(),
});


export default function AdditionalInformation({
    onSubmitAdditional,
    sectionFocus,
    setSectionFocus,
    unit
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all"};
    const { register, setValue, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        if(unit){
            setValue(RENT, unit.Rent);
            setValue(PAYMENT_DETAILS, unit.PaymentDetails || '');
            setValue(PAYMENT_DUE_DATE, unit.PaymentDueDate || '');
            setValue(MISC_NOTES, unit.Notes || '');
        }
    }, [unit, setValue])

    return (
        <Form
            onSubmit={handleSubmit(onSubmitAdditional)}
            className={sectionFocus && sectionFocus !== "Additional Information" ? "d-blurred prev-blur m-0 p-0 mt-3" : "prev-blur m-0 p-0 mt-3"}
            onMouseEnter={() => { setSectionFocus("Additional Information") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >

            <AdditionalInfoFields 
                register={register}
                errors={errors}
                s_registerNameForRent={RENT}
                s_registerNameForPaymentDetails={PAYMENT_DETAILS}
                s_registerNameForPaymentDueDate={PAYMENT_DUE_DATE}
                s_registerNameForNotes={MISC_NOTES}
            />

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        title="Update Additional Information"
                    />
                </Col>
            </Row>
        </Form>
    )
}
