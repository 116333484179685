import React from 'react';
import { Row, Col, Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {ReactComponent as DragRightIcon} from '../../assets/icons/drag-right.svg';

export default function CategoryItem({
    title,
    name = "Test Name",
    description = "Description",
    children,
    deleteBtnHandler,
    editBtnHandler,
    showGripIcon=false,
    index=0
  }) {
    return (
        <Accordion.Item eventKey={index} className="mb-2 shadow-sm">
            <Accordion.Header className="wissp-accordion-header">
                <Row className="w-100 align-items-center">
                    { showGripIcon && (
                        <Col xs="auto" className="d-none d-md-block">
                            {/* <i className="bi bi-grip-vertical"></i> */}
                            {/* <div style={{width: 32, height: 32, overflow: 'hidden'}}> */}
                                <DragRightIcon />
                            {/* </div> */}
                            
                        </Col>
                    )}
                    <Col>
                        <h5 className="text-black fs-6 fs-lg-5">
                            <span className="fw-bold">{title} Name:</span> {name}
                        </h5>
                        <p className="m-0 fs-6">
                            <span className="fw-bold">Description:</span> {description}
                        </p>
                    </Col>
                    <Col xs="auto me-2">
                        <Row className="m-0 flex-column flex-sm-row flex-md-column flex-lg-row">
                            <Col xs="auto" className="p-0 pe-2 pb-1 pb-sm-0 pb-md-1 pb-lg-0">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100 }}
                                    overlay={(
                                        <Tooltip>
                                            Delete
                                        </Tooltip>
                                    )}
                                >
                                    <div>
                                        <Button onClick={deleteBtnHandler} className="btn-danger"><i className="bi bi-x-lg text-light"></i></Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                            <Col xs="auto" className="p-0">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100 }}
                                    overlay={(
                                        <Tooltip>
                                            Edit
                                        </Tooltip>
                                    )}
                                >
                                    <div>
                                        <Button onClick={editBtnHandler}><i className="bi bi-pencil-fill"></i></Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Accordion.Header>
            <Accordion.Body className="lightGray p-0 p-md-2 p-lg-3">
                <div className="p-1 rounded lightGray">
                    {children}
                </div>
            </Accordion.Body>
        </Accordion.Item>
   
    );
  }
