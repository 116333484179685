import React, {useState} from 'react';
import { Modal, Row, Col, Form, Tab, Nav } from "react-bootstrap";

import FeaturesFilter from "./FeaturesFilter";
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

const TAB = Object.freeze({
  BUILDING: 'building',
  UNIT: 'unit'
})

export default function ModalFeatures ({
  show,
  setShow,
  selectedFeatures,
  setSelectedFeatures,
  features,
  children
}) {
  const [ buildingAccessibilities, setBuildlingAccessibilities ] = useState([]);
  const [ buildingAmenities, setBuildlingAmenities ] = useState([]);
  const [ unitAccessibilities, setUnitAccessibilities ] = useState([]);
  const [ unitAmenities, setUnitAmenities ] = useState([]);
  const [ selectedTab, setSelectedTab ] = useState(TAB.BUILDING);

  const handleClose = () => {
    if(!selectedFeatures) {
      setShow(false);
      return;
    }
    
    setBuildlingAccessibilities( selectedFeatures.building.accessibilities );
    setBuildlingAmenities( selectedFeatures.building.amenities );
    setUnitAccessibilities( selectedFeatures.unit.accessibilities );
    setUnitAmenities( selectedFeatures.unit.amenities );

    setShow(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  
    setSelectedFeatures({
      building: { amenities: buildingAmenities, accessibilities: buildingAccessibilities },
      unit: { amenities: unitAmenities, accessibilities: unitAccessibilities }
    })

    setShow(false);
  };

  const changeHandler = (f_setter, id) => {
    f_setter( prevState => prevState.includes(id) ? prevState.filter( featureId => featureId !== id) : [...prevState, id])
  }

  const isFeatureSelected = () => {
    switch(selectedTab) {
      case TAB.BUILDING:
        return !!( buildingAccessibilities.length + buildingAmenities.length );
      case TAB.UNIT:
        return !!( unitAccessibilities.length + unitAmenities.length );
      default:
        break;
    }
  }

  const extrudeKeys = ( a_features ) => {
    const result = a_features.map( (feature) => Number(Object.keys(feature)[0]));
    return result;
  }

  const selectOrDeselectAllHandler = () => {
    switch(selectedTab) {
      case TAB.BUILDING:
        if(isFeatureSelected()) {
          setBuildlingAccessibilities([]);
          setBuildlingAmenities([]);
        } else {
          setBuildlingAccessibilities(extrudeKeys(features.buildingAccessibilitiesOptions));
          setBuildlingAmenities(extrudeKeys(features.buildingAmenitiesOptions));
        } 
        break;
      case TAB.UNIT:
        if(isFeatureSelected()) {
          setUnitAccessibilities([]);
          setUnitAmenities([]);
        } else {
          setUnitAccessibilities(extrudeKeys(features.unitAccessibilitiesOptions));
          setUnitAmenities(extrudeKeys(features.unitAmenitiesOptions));
        } 
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton />
          <Modal.Body>

            { children }

            <Tab.Container defaultActiveKey={ selectedTab }>
              <Row className="m-0 p-0 mb-3">
                <h6 className='m-0 p-0 fw-bold'>Select additional search features</h6>
              </Row>
              <Row className="m-0 p-0  mb-3">
                <Col className="m-0 p-0">
                    <Nav variant="pills" 
                      className="d-flex flex-row justify-content-between text-center rounded overflow-hidden" 
                      onSelect={(selectedKey) => setSelectedTab(selectedKey)}
                    >
                        <Nav.Item className="flex-fill apartmentInfoNav w-50">
                            <Nav.Link className="fw-bold rounded fs-6" eventKey={ TAB.BUILDING } >
                              Building
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="flex-fill apartmentInfoNav w-50">
                            <Nav.Link className="fw-bold rounded fs-6" eventKey={ TAB.UNIT }>
                              Unit
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                  </Col>
                </Row>

              <Tab.Content> 
                <Tab.Pane eventKey="building">
                  
                  <FeaturesFilter 
                    accessibilitiesOptions={features.buildingAccessibilitiesOptions}
                    amenitiesOptions={features.buildingAmenitiesOptions}
                    selectedAccessibilities={buildingAccessibilities}
                    onChangeAccessibilities={(id) => changeHandler(setBuildlingAccessibilities, id)}
                    selectedAmenities={buildingAmenities}
                    onChangeAmenities={(id) => changeHandler(setBuildlingAmenities, id)}
                  />

                </Tab.Pane>

                <Tab.Pane eventKey="unit">
                  <FeaturesFilter 
                    accessibilitiesOptions={features.unitAccessibilitiesOptions}
                    amenitiesOptions={features.unitAmenitiesOptions}
                    selectedAccessibilities={unitAccessibilities}
                    onChangeAccessibilities={(id) => changeHandler(setUnitAccessibilities, id)}
                    selectedAmenities={unitAmenities}
                    onChangeAmenities={(id) => changeHandler(setUnitAmenities, id)}
                  />
                </Tab.Pane>

              </Tab.Content>
            </Tab.Container>

          </Modal.Body>
          <Modal.Footer>

            <Row className="m-0 p-0 w-100">

              <Col className="m-0 p-0 pe-2" xs={'auto'}>
                <ButtonPrimary 
                  type="button"
                  onClick={ selectOrDeselectAllHandler }
                  // variant="danger"
                  // additionalClassName="text-light"
                >

                  { isFeatureSelected( selectedTab )
                    ? (
                      <>
                        <span className="d-none d-sm-inline">Deselect All </span>
                        <i className="bi bi-arrow-clockwise"></i>
                      </>
                    ) : ( 
                      <>
                        <span className="d-none d-sm-inline">Select All </span>
                        <i className="bi bi-list-check"></i>
                      </>
                    )
                  }
    
                </ButtonPrimary>
              </Col>

              <Col className='m-0 p-0'></Col>

              <Col className="m-0 p-0 pe-2" xs={'auto'}>
                <ButtonSecondary 
                  type="button"
                  onClick={handleClose}
                >
                  <span className="d-none d-sm-inline">Cancel </span>
                  <i className="bi bi-x-lg"></i>
                </ButtonSecondary>
              </Col>

              <Col className="m-0 p-0" xs={'auto'}>
                <ButtonPrimary variant='success'>
                  <span className="d-none d-sm-inline">Save Changes </span>
                  <i className="bi bi-check2"></i>
                </ButtonPrimary>
              </Col>
            </Row>

          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
