import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Logo from '../../assets/logos/logo_primary.svg';

export default function Loader() {
    return (
        <Container fluid className="d-flex justify-content-center align-content-center" style={{ height: "100%" }}>
            
            <div className="d-flex justify-content-center align-content-center flex-column">

                <Image className="m-0 p-0" 
                    style={{ objectFit: "cover", height: 120, width: "auto" }}
                    src={Logo} 
                    alt="Loader"></Image>  
                <div className="loader__logo mx-auto my-0"><span className="visually-hidden">Loading...</span></div>

            </div>

        </Container>
    )
}
