import React, { useEffect, useCallback, useMemo, useContext} from "react";
import axios from "axios";
import { Container, Card } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from 'react-redux';

import { managerDataActions } from "../store/ManagerDataSlice";
import { getLandlordsInfo } from "../services/manager";

import { AuthContext } from "../contexts/AuthContext";

// import BasicInfoForm from "../components/ManagerProfile/BasicInfoForm";
import LandlordList from "../components/ManagerProfile/LandlordList";
// import schema from "../schemas/managerProfileSchema";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";

// const dummyManagerData = {
//   FirstName: "FirstName",
//   LastName: "LastName",
//   Phone: "(000)-000-0000",
//   Email: "email@address.com",
// };

export default function ManagerProfile() {
  const landlordsInformations = useSelector(store => store.managerDataStore.landlordsInformations);
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  // const formOptions = {
  //   resolver: yupResolver(schema),
  //   mode: "all",
  // };
  // const { register, handleSubmit, reset, formState } = useForm(formOptions);
  // const { errors } = formState;

  // const saveBasicsHandler = async () => {
  //   let result = true;

  //   await handleSubmit((d) => {
  //     result = false;

  //     reset({}, { keepValues: true });
  //   })();

  //   return result;
  // };

  const cancelToken = useMemo(() => axios.CancelToken.source(), []);

  const getLandlordsInformationHandler = useCallback( async () => {
    const response = await getLandlordsInfo(token);
    const { success, data } = response;

    if(success) {
      dispatch(managerDataActions.setLandlordsInformations(data));
    } else {
      ErrorPopup();
    }
  }, [token, dispatch]);

  // After clicking on PROPERTIES route, if user
  // immidiately change route, then async has to be cancelled
  // to prevent memory leakage
  useEffect(() => {
    // getAllBuildingsHandler();

    if(!landlordsInformations) {
      getLandlordsInformationHandler();
    }

    return () => {
        cancelToken.cancel("Cancel request")
    }

  }, [token, 
    // getAllBuildingsHandler, 
    landlordsInformations, getLandlordsInformationHandler, cancelToken]);

  return (
    <Container
      fluid
      className="p-0 m-0 mt-4 mb-5 mx-auto"
      style={{ maxWidth: "1200px" }}
    >
      <Card className="border-0 w-100" style={{ height: "100%" }}>
        <Card.Body className="m-0 p-2 py-3 p-sm-5 lightGray rounded overflow-hidden">
          {/* <BasicInfoForm
            managerData={dummyManagerData}
            canEdit={true}
            register={register}
            errors={errors}
            onSubmitBasic={saveBasicsHandler}
          /> */}
          <LandlordList 
            landlordsInformations={landlordsInformations}
          />
        </Card.Body>
      </Card>
    </Container>
  );
}
