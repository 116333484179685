import React from "react";
import { Col, Row } from "react-bootstrap";

export default function LandlordList({ landlordsInformations }) {
  const prepareFullName = (fullName) => {
    const regex = /\s+/;
    const splitNames = fullName.split(regex);
    const joinNames = splitNames.join(' ');
    return joinNames.trim();
  }

  return (
    <Col xs={12} className="mb-4 bg-white rounded overflow-hidden">
      <Row className="py-2 text-center darkerGray">
        <h5 className="fw-bold m-0 p-0 text-uppercase">Landlords</h5>
      </Row>

      {landlordsInformations &&
        landlordsInformations.map((landlord) => (
          <Row key={landlord.Email} className="row-cols-1 m-0 p-3">
            <Col xs={12} md={6} lg={7} className="m-0 mb-3 mb-md-0 p-0 montserrat">
              <h5 className="fw-bold">
                {landlord && landlord.FullName ? prepareFullName(landlord.FullName) : ""}
              </h5>
              <p className="m-0">
                {landlord && (landlord.Mobile || "")}
              </p>
              <p className="m-0">
                {landlord && (landlord.Email || "")}
              </p>
            </Col>

            <Col xs={12} md={6} lg={5} className="d-flex flex-column m-0 p-0 montserrat">
              <h5 className="fw-bold">Basic Statistics:</h5>
              <Row className="m-0 p-0 d-flex flex-row justify-content-between ">
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">Properties: </p>
                  </Col>
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">{landlord.Properties}</p>
                  </Col>
              </Row>
              <Row className="m-0 p-0 d-flex flex-row justify-content-between ">
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">Total Units: </p>
                  </Col>
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">{landlord.TotalUnits}</p>
                  </Col>
              </Row>
              <Row className="m-0 p-0 d-flex flex-row justify-content-between ">
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">Occupied Units: </p>
                  </Col>
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">{landlord.OuccupiedUnits}</p>
                  </Col>
              </Row>
              <Row className="m-0 p-0 d-flex flex-row justify-content-between ">
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">Vacant Units: </p>
                  </Col>
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">{landlord.VacantUnits}</p>
                  </Col>
              </Row>
              <Row className="m-0 p-0 d-flex flex-row justify-content-between ">
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">Units Vacant In Next 60 Days: </p>
                  </Col>
                  <Col className="m-0 p-0" xs={'auto'}>
                  <p className="m-0">{landlord.UnitsVacantInNext60Days}</p>
                  </Col>
              </Row>
            </Col>

            <Col className="d-block d-md-none m-0 p-1 border-bottom border-2 border-dark" xs={12}></Col>

          </Row>
        ))}
    </Col>
  );
}
