import { useState } from "react";

export default function useUpdateFiles() {
    const [files, setFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [downloadedFiles, setDownloadedFiles] = useState([]);


    function filesToDeleteIDs() {
        const filesIDs = filesToDelete.map(image => image.id);
        return filesIDs;
    }

    function onlyNewFiles() {
        if (!files) return [];

        const newFiles = files.filter(file => !file.isInDB);
        return newFiles;
    }

    return {
        files,
        setFiles,
        filesToDelete,
        setFilesToDelete,
        filesToDeleteIDs,
        onlyNewFiles,
        downloadedFiles,
        setDownloadedFiles,
    }
}
