import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function PositionCardFormLoader() {
    return (
    <>
        <Row className="m-0 p-1 position-relative">

                <Row className="d-flex d-lg-none m-0 p-0 justify-content-end">
                    <Col xs={12} className="m-0 p-0">
                        <ButtonPrimary 
                          
                            title={<>&nbsp;</>}
                            additionalClassName='darkerGray skeleton border-light'
                        />
                    </Col>
                </Row>

                <Row className="m-0 p-0 pt-3">
                    <Col xs={12} md={6} className="m-0 p-0">
                        <p className="my-1 darkerGray skeleton rounded" style={{width: 200}}>&nbsp;</p>
                        <p className="my-1 darkerGray skeleton rounded" style={{width: 225}}>&nbsp;</p>
                    </Col>
                    <Col xs={12} md={6} className="m-0 p-0">
                        <p className="my-1 darkerGray skeleton rounded" style={{width: 225}}>&nbsp;</p>
                        <p className="my-1 darkerGray skeleton rounded" style={{width: 200}}>&nbsp;</p>
                    </Col>
                    <Col xs={12} className="m-0 p-0">
                        <p className="my-1 darkerGray skeleton rounded">&nbsp;</p>
                    </Col>
                </Row>
            
                <Row className="m-0 p-0 pe-2 d-none d-lg-flex position-absolute justify-content-end">
                    <Col className="m-0 p-0" xs="auto">
                        <ButtonPrimary 
                            style={{width: 156}}
                            title={<>&nbsp;</>}
                            className="rounded fw-bold"
                            additionalClassName='darkerGray skeleton border-light'
                        />
                    </Col>
                </Row>

        </Row>
    </>            
    )
}
