import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LazyImage from "../Shared/LazyImage";


export default function MultiCarouselLoader() {

    return (
        <Row className="m-0 p-0 multiCarousel__container justify-content-center overflow-hidden flex-grow-1" style={{minHeight: 100}}>
            <Col className="m-0 p-0 multiCarousel__handle multiCarousel__handle-left skeleton darkerGray">
                <i className="bi bi-chevron-compact-left"></i>
            </Col>

            <Col className="m-0 p-0 multiCarousel__slider">
              
                <div className="multiCarousel__imgContainer position-relative">
                    <LazyImage
                        style={{ objectFit: "cover", height: '100%', width: '100%' }}
                        src={null}
                    />
                </div>

                <div className="multiCarousel__imgContainer position-relative">
                    <LazyImage
                        style={{ objectFit: "cover", height: '100%', width: '100%' }}
                        src={null}
                    />
                </div>

                <div className="multiCarousel__imgContainer position-relative">
                    <LazyImage
                        style={{ objectFit: "cover", height: '100%', width: '100%' }}
                        src={null}
                    />
                </div>
               
            </Col>

            <Col className="m-0 p-0 multiCarousel__handle multiCarousel__handle-right skeleton darkerGray">
                <i className="bi bi-chevron-compact-right"></i>
            </Col>
        </Row>
    )
}
