import React, { useState } from 'react';
import { Modal, Container, Row, Col, } from 'react-bootstrap';

import Backdrop from '../Shared/Backdrop';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import moment from 'moment';

const MERIDIEM = Object.freeze({
    AM : 'AM',
    PM: 'PM'
})

export default function TimePicker( {show, setTime, closeTimePicker, HourRange={Min: 0, Max: 24}} ){
    const [hour, setHour] = useState(10);
    const [meridiem, setMridiem] = useState(MERIDIEM.AM);
    const [error, setError] = useState();

    // console.log(HourRange);

    const checkIfNotInBoundaries = (hour, meridiem) => {
        const timeString = `${hour} ${meridiem}`;
        const transformto24 = Number(moment(timeString, 'h A').format('H'));
        if( transformto24 >= HourRange.Min && transformto24 <= HourRange.Max) {
            return false;
        } else {
            return true;
        }
    }

    const onChangeMeridiemHandler = (e) => {
        const value = e.target.value;
        setError(checkIfNotInBoundaries(hour, value));
        setMridiem(value);
    }

    const onChangeHourHandler = (e) => {
        const value = e.target.value;
        setError(checkIfNotInBoundaries(value, meridiem));
        setHour(value);
    }

    const onSaveButtonHandler = () => {
        if(+hour < 1 || +hour > 12) return;
        if(error) return;

        const timeString = `${hour}:00:00 ${meridiem}`;
        const transformTime = moment(timeString, 'h:m:s A').format('H');
        setTime(transformTime);
    }

    return(
        <>
            <Backdrop 
                show={show}
                close={closeTimePicker}
            />

            <Modal
                show={show}
                onHide={closeTimePicker}
                centered
                size="md"
            >
                <Modal.Body className="m-0 p-0" >
                    <Container fluid className="p-0 m-0">
                        <Row className="m-0 p-3 pb-0">
                            <Col className="p-0">
                                <span className="text-uppercase" style={{fontSize: '0.85rem'}}>Select Time</span>
                            </Col>
                        </Row>
                        <Row className="m-0 p-3 pb-1 justify-content-center">

                            <Col xs="auto" className="p-0">
                                <input type="number" min="1" max="12" value={hour} onChange={onChangeHourHandler}
                                    className={`fs-2 py-2 rounded border border-1 shadow-sm text-center time-picker__time-input ${ error ? 'time-picker__time-input--error' : '' }`} 
                                />
                            </Col>
                            <Col xs="auto" className="p-0 px-2 d-flex align-items-center"><span className="fs-2">:</span></Col>
                            <Col xs="auto" className="p-0 pe-3" >
                                <input disabled readOnly type="number" min="0" max="59" value="00" className=" fs-2 py-2 rounded border border-1 shadow-sm text-center" />
                            </Col>
                            <Col xs="auto" className="p-0">
                                <select className="fs-2 p-2 rounded border border-1 shadow-sm h-100" value={meridiem} onChange={onChangeMeridiemHandler}>
                                    <option value="am">AM</option>
                                    <option value="pm">PM</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="m-0 p-3 py-0 pb-3" >
                            <Col className="p-0 text-center">
                                <span className={`text-uppercase ${error ? 'text-danger' : ''}`} style={{fontSize: '0.80rem'}}>Select Time between 7 AM - 7 PM</span>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>

                <Modal.Footer className='m-0 p-0'>
                    <Row className="m-0 p-2 w-100 justify-content-end">
                        <Col xs="auto" className="p-0 mb-1 me-1 mb-sm-0">
                            <ButtonSecondary
                                title="Close"
                                onClick={closeTimePicker}
                            />
                        </Col>
                        <Col xs="auto" className="p-0">
                            <ButtonPrimary 
                                disabled={error}
                                title={ 'Ok' }
                                onClick={onSaveButtonHandler}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
