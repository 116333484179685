import React, {useState} from 'react';
import {
    Row,
    Col,
    Modal,
} from "react-bootstrap";

import cross_icon from "../../assets/icons/cross_icon.svg";
import star_icon from "../../assets/icons/star_icon.svg";
import starFilled_icon from "../../assets/icons/starFilled_icon.svg";

import LazyImage from './LazyImage';

export default function ImageCard({
    item,
    toggleStar,
    removeImage
}) {
    const [showImage, setShowImage] = useState(false);

    const openModal = () => {
        setShowImage(true);
    }

    const closeModal = () => {
        setShowImage(false);
    }

    const deleteImageHandler = (e) => {
        e.preventDefault();
        removeImage(item.id);
    }

    return (
        <>
            <Col className="d-flex">
                <Row className="pb-2 p-lg-1 mb-3 rounded d-lg-flex shadow-sm w-100" bsPrefix="white">
                    <Col xs={12} md={12} lg={3} className="position-relative" style={{height: 140}}>
                        
                       
                        <LazyImage onClick={() => openModal(item.id)} src={item.src} style={{ objectFit: "cover", height: "100%", width: "100%" }} className="d-block rounded-top rounded-lg" />
                      

                        <Row className="d-lg-none position-absolute top-0 left-0 w-100 m-0 d-flex justify-content-between align-items-center p-1 row-cols-auto">
                            <Col className="d-flex justify-content-center align-items-center p-1 m-0 bg-light rounded">
                                <LazyImage className="img-fluid w-75" src={cross_icon} onClick={deleteImageHandler} />
                            </Col>

                            <Col className="d-flex justify-content-center align-items-center p-1 m-0 bg-light rounded ">
                                <LazyImage className="img-fluid w-75" src={item.isFavorite ? starFilled_icon : star_icon} onClick={() => toggleStar(item.id)} />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={12} lg={7} className="p-1 ps-lg-3 d-flex justify-content-center justify-content-lg-start">

                        <div style={{textDecoration: 'none'}} className="align-self-center d-flex justify-content-center justify-content-lg-start flex-wrap flex-grow-1">
                            <h3 className="m-0 me-2 resFontSize" style={{ lineBreak: "strict"}} >
                                {item.fileName}
                            </h3>
                            <span>{item.fileSize !== null && ` (${item.fileSize} MB)`}</span>
                        </div>
                    
                    </Col>

                    <Col xs={12} md={12} lg={2} className="d-none d-lg-flex align-items-center justify-content-around pe-2">
                        <Row >
                            <Col className="d-flex justify-content-end align-items-center">
                                <LazyImage className="d-block  img-fluid" src={cross_icon} onClick={() => removeImage(item.id)} />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <LazyImage className="d-block  img-fluid " src={item.isFavorite ? starFilled_icon : star_icon} onClick={() => toggleStar(item.id)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Modal show={showImage} onHide={() => closeModal(item.id)} size="lg">
                <Modal.Header onClick={() => closeModal(item.id)} closeButton>
                    <Modal.Title className="d-flex w-100" >
                        <div>
                            {item.fileName}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LazyImage src={item.src} style={{ width: "100%" }} />
                </Modal.Body>
            </Modal>

        </>
    )
}