import React, { useEffect} from 'react';
import {Row, Col, Form} from 'react-bootstrap';

import { object as YupObject, string as YupString } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FeaturesFields from '../UnitFormElements/FeaturesFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import { MISC_AMENITIES, MISC_ACCESSIBILITIES} from '../../constants/unitFormConstants';

const schema = YupObject().shape({
    [MISC_AMENITIES]: YupString()
        .notRequired(),
    [MISC_ACCESSIBILITIES]: YupString()
        .notRequired()
});


export default function FeaturesInformation ({
    features,
    amenities,
    setAmenities,
    accessibilities,
    setAccessibilities,
    unit,
    onSubmit,
    sectionFocus, 
    setSectionFocus
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, setValue, handleSubmit } = useForm(formOptions);

    useEffect(() => {
       
        if (unit) {
            setValue(MISC_AMENITIES, unit.MiscAmenities);
            setValue(MISC_ACCESSIBILITIES, unit.MiscAccessibilities);
        }

    }, [unit, setValue]);

    return (
    <Form
        onSubmit={handleSubmit(onSubmit)}
        className={sectionFocus && sectionFocus !== "Amenities" ? "d-blurred prev-blur" : "prev-blur"}
        onMouseEnter={() => { setSectionFocus("Amenities") }}
        onMouseLeave={() => { setSectionFocus(null) }}
    >
        <FeaturesFields 
            features={features}
            amenities={amenities}
            setAmenities={setAmenities}
            accessibilities={accessibilities}
            setAccessibilities={setAccessibilities}
            f_register={register}
            s_registerNameMiscAmenities={MISC_AMENITIES}
            s_registerNameMiscAccessibilities={MISC_ACCESSIBILITIES}
        />

        <Row className="m-0 p-0 mt-3">
            <Col className="m-0 p-0 text-center">
                <ButtonPrimary 
                    title="Update Amenities and Accessibilities"
                />
            </Col>
        </Row>
    </Form>
    )
}
