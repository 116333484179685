import React, { createContext, useState } from "react";

export const BuildingContext = createContext();

const BuildingContextProvider = (props) => {
  const [buildingInfo, setBuildingInfo] = useState(null);


  return (
    <BuildingContext.Provider
      value={{
        buildingInfo,
        setBuildingInfo,
      }}
    >
      {props.children}
    </BuildingContext.Provider>
  );
};

export default BuildingContextProvider;