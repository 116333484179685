import React, {useEffect} from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString, boolean as YupBoolean } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import BasicFields from '../BuildingFormElements/BasicFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import {
    NAME,
    GOOGLE_AUTOCOMPLETE,
    ADDRESS_LINE_1,
    ADDRESS_LINE_2,
    CITY,
    STATE,
    ZIP
} from '../../constants/buildingFormConstants';

const schema = YupObject().shape({
    [GOOGLE_AUTOCOMPLETE]: YupBoolean()
        .notRequired(),
    [NAME]: YupString()
        .required("Required")
        .transform(value => value.trim()),
    [ADDRESS_LINE_1]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required")
                .transform(value => value.trim()),
    }),
    [ADDRESS_LINE_2]: YupString()
        .notRequired()
        .transform(value => value.trim()),
    [CITY]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required")
                .transform(value => value.trim()),
    }),
    [STATE]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required"),
    }),
    [ZIP]: YupString()
    .when(GOOGLE_AUTOCOMPLETE, {
        is: false,
        then: YupString()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits"),
    }),
});


export default function BasicInfoForm({
    sectionFocus, 
    setSectionFocus, 
    onSubmitBasics,
    autocompleteError,
    setGoogleValue,
    googleValue,
    building
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, reset, handleSubmit, formState: { errors, isDirty } } = useForm(formOptions);

    useEffect(() => {
        if (building) {
            reset({
                [NAME]: building[NAME],
                [GOOGLE_AUTOCOMPLETE]: false,
                [ADDRESS_LINE_1]: building[ADDRESS_LINE_1],
                [ADDRESS_LINE_2]: building[ADDRESS_LINE_2],
                [CITY]: building[CITY],
                [STATE]: building[STATE],
                [ZIP]: building[ZIP]
            })
        }
    }, [building, reset]);

    return (
        <Form
            onSubmit={handleSubmit(onSubmitBasics)}
            className={sectionFocus && sectionFocus !== "Basics" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus("Basics") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >   
            <BasicFields 
                f_register={register}
                o_errors={errors}
                s_autocompleteError={autocompleteError}
                f_setGoogleValue={setGoogleValue}
                o_googleValue={googleValue}
                s_name={NAME}
                s_googleAutocomplete={GOOGLE_AUTOCOMPLETE}
                s_addressLine1={ADDRESS_LINE_1}
                s_addressLine2={ADDRESS_LINE_2}
                s_city={CITY}
                s_state={STATE}
                s_zip={ZIP}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary
                        disabled={ !isDirty }
                        title="Update Property Basics"
                    />
                </Col>
            </Row>
        </Form>
    )
}
