import React from 'react';
import { Container, Accordion } from 'react-bootstrap';

export default function Category({
    title,
    name = "Test Name",
    description = "Description",
    children,
    className,
    style,
    deleteBtnHandler,
    editBtnHandler,
    showGripIcon=false,
    defaultActiveKey,
    ...props
  }) {
    return (
      <Container fluid className={`mb-4 p-0 ${className}`} style={style}>
        <Accordion className="h-100" defaultActiveKey={defaultActiveKey}>
            { children }
        </Accordion>
      </Container>
    );
  }
