import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json"
    }
})

const getRequest = async (URL, token='', settings) => {
    try {
        const response = await axiosInstance.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            ...settings
        });
        return {success: true, data: response.data, headers: response.headers};

    } catch (err) {
        return errorLogic(err);
    }
}

const postPutRequest = async (URL, data, token, type) => {
    const dataJson = JSON.stringify(data);

    const headers = token ? {
        Authorization: `Bearer ${token}`
    } : {};

    try {
        const response = await axiosInstance[type](URL, dataJson, { headers });
        return {success: true, data: response.data};

    } catch (err) {
        return errorLogic(err);
    }
}

const signInPostRequest = async (URL, attributes) => {
    try {
        const response = await axios.request({
            method: "post",
            url: BASE_URL + URL,
            data: "grant_type=password",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              grant_type: "password",
              ...attributes
            },
          });
        return {success: true, data: response.data};

    } catch (err) {
        return errorLogic(err);
    }
}

const deleteRequest = async (URL, data, token) => {
    const dataJson = JSON.stringify(data);

    try {
        const response = await axiosInstance.delete(URL,
            {
                data: dataJson,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'application/json'
                }
            });
        return {success: true, data: response.data};

    } catch (err) {
        return errorLogic(err);
    }
}

function errorLogic (err) {
    let errorData;
    if (err.response){
        errorData = {
            headers: err.response.headers,
            data: err.response.data,
            status: err.response.status
        }
    } else if (err.request) {
        errorData = {
            data: {
                Message: 'Request was made but no response was received!'
            },
            headers: null,
            status: null
        }
    } else {
        errorData = err;
    }

    return {success: false, data: errorData};
}

export { getRequest, postPutRequest, deleteRequest, signInPostRequest };
export default axiosInstance;
