import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal
} from "react-bootstrap";

import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";
import FeaturesChecklist from "../Shared/FeaturesChecklist";

import DecisionModal from "../Shared/InfoPopups/DecisionModal";

export default function HistoryBoxQuery({
    data,
    deleteHandler,
    features,
    onOpen
  }) {
    const [showFeaturesModal, setShowFeaturesModal] = useState(false);

    const onDelete = async () => {
      const userDecision = await DecisionModal({});

      if ( !userDecision.isConfirmed ) return;

      deleteHandler();
    }

    const computePrice = (minRentPrice, maxRentPrice) => {
      if(!minRentPrice && maxRentPrice) return `to $${maxRentPrice}`;
      if(minRentPrice && !maxRentPrice) return `from $${minRentPrice}`;
      if(minRentPrice && maxRentPrice) return `from $${minRentPrice} to $${maxRentPrice}`;
      if(!minRentPrice && !maxRentPrice) return `any`;
    }

    const computeAddress = (zip, state, city) => {
      if(!zip && !state && !city) return 'Missing address';
      if(zip) return `Zip code: ${zip}`;
      return `${city}, ${state}`;
    }

    const computeBathsAndBeds = (minBathroomCount, maxBathroomCount, minBedroomCount, maxBedroomCount) => {
      if(!minBathroomCount && !maxBathroomCount && !minBedroomCount && !maxBedroomCount) return '';
      return `Bedrooms: from ${minBedroomCount || 0}${maxBedroomCount ? ` to ${maxBedroomCount}`: ''} | Bathrooms: from ${minBathroomCount || 0}${maxBathroomCount ? ` to ${maxBathroomCount}`: ''}`
    }

    const doesFeaturesExists = () => {
      if((data.BuildingAmenityIDs && data.BuildingAmenityIDs.length) ||
        (data.BuildingAccessibilityIDs && data.BuildingAccessibilityIDs.length) ||
        (data.UnitAmenityIDs && data.UnitAmenityIDs.length) ||
        (data.UnitAccessibilityIDs && data.UnitAccessibilityIDs.length)
      ) return true;

      return false;
    }

    return (
    <>

      <Modal 
        show={showFeaturesModal} 
        onHide={() => setShowFeaturesModal(false)} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />


        <Modal.Body className="mb-4">

          <Row className="m-0 p-0 mb-2 bg-white rounded overflow-hidden">
              <Col className="m-0 py-2 text-center darkerGray" xs={12}>
                  <h5 className="m-0 p-0 fw-bolder text-uppercase">Building Amenities</h5>
              </Col>
              <Col className="m-0 pt-2 px-0 px-sm-2 px-md-2" xs={12}>

                  <FeaturesChecklist 
                    o_allfeatures={ features.buildingAmenitiesOptions }
                    a_selectedFeatures={ data.BuildingAmenityIDs }
                    b_areSelectedFeaturesANumber={ true }
                  />

              </Col>
          </Row>

          <Row className="m-0 p-0 mb-2 bg-white rounded overflow-hidden">
              <Col className="m-0 py-2 text-center darkerGray" xs={12}>
                  <h5 className="m-0 p-0 fw-bolder text-uppercase">Building Accessibilities</h5>
              </Col>
              <Col className="m-0 pt-2 px-0 px-sm-2 px-md-2" xs={12}>

                  <FeaturesChecklist 
                    o_allfeatures={ features.buildingAccessibilitiesOptions }
                    a_selectedFeatures={ data.BuildingAccessibilityIDs }
                    b_areSelectedFeaturesANumber={ true }
                  />

              </Col>
          </Row>

          <Row className="m-0 p-0 mb-2 bg-white rounded overflow-hidden">
              <Col className="m-0 py-2 text-center darkerGray" xs={12}>
                  <h5 className="m-0 p-0 fw-bolder text-uppercase">Unit Amenities</h5>
              </Col>
              <Col className="m-0 pt-2 px-0 px-sm-2 px-md-2" xs={12}>

                  <FeaturesChecklist 
                    o_allfeatures={ features.unitAmenitiesOptions }
                    a_selectedFeatures={ data.UnitAmenityIDs }
                    b_areSelectedFeaturesANumber={ true }
                  />

              </Col>
          </Row>

          <Row className="m-0 p-0 bg-white rounded overflow-hidden">
              <Col className="m-0 py-2 text-center darkerGray" xs={12}>
                  <h5 className="m-0 p-0 fw-bolder text-uppercase">Unit Accessibilities</h5>
              </Col>
              <Col className="m-0 pt-2 px-0 px-sm-2 px-md-2" xs={12}>

                  <FeaturesChecklist 
                    o_allfeatures={ features.unitAccessibilitiesOptions }
                    a_selectedFeatures={ data.UnitAccessibilityIDs }
                    b_areSelectedFeaturesANumber={ true }
                  />

              </Col>
          </Row>

        </Modal.Body>
      </Modal>



      <Container fluid className="m-0 p-0 mb-3 d-flex justify-content-center shadow-sm">
        <Card className="m-0 p-0 border-0  w-100" style={{ height: "100%" }}>
          <Card.Body className={`m-0 p-0 lightGray rounded overflow-hidden w-100`} style={{ transition: '.3s'}}>
            <Row className="m-0 p-0 w-100">

              {/* <Col className="d-flex  align-items-center justify-content-center m-0 p-0 ps-2 ps-sm-3" xs={'auto'}>
                <i className={`bi ${(data && ( data.type === 'unit' ? 'bi-house-heart' : data.type === 'building' ? 'bi-building' : 'bi-list-stars')) || 'bi-house-heart'} fs-3`}></i> 
              </Col> */}


              <Col className="px-2 px-sm-3 m-0 d-flex flex-column justify-content-start ">
                <Row className="p-0 m-0 d-flex align-items-center pt-3">
                  
                    <Row className="m-0 p-0">

                      <Col className="m-0 p-0">

                        <h5 className="p-0 m-0 text-dark">
                          Price: {data && computePrice(data.MinRentPrice, data.MaxRentPrice)}
                        </h5>
                        <p className="m-0 p-0 fs-6 text-muted">
                          <span className="text-muted">{data && computeBathsAndBeds(data.MinBathroomCount, data.MaxBathroomCount, data.MinBedroomCount, data.MaxBedroomCount)}</span>
                        </p>
                        <p className="m-0 p-0 text-muted" style={{ fontSize: '0.85rem'}}>
                          <span className="text-muted"><i className="bi bi-geo-alt"></i>&nbsp;{data && computeAddress(data.Zip, data.State, data.City)}</span>
                        </p>

                      </Col>

                    </Row>

                </Row>
              </Col>

              <Col className="d-flex jusity-content-center align-items-center m-0 p-0 px-2 px-sm-3" xs={'auto'}>
                
                { doesFeaturesExists() && (
                  <ButtonPrimary 
                    title='Show&nbsp;feature&nbsp;filters'
                    onClick={() => setShowFeaturesModal(true) }
                  />
                )}

                  <ButtonPrimary 
                    title={"Open"}
                    additionalClassName="ms-1"
                    onClick={() => onOpen(data)}
                  />

                  <ButtonPrimary 
                    onClick={onDelete}
                    additionalClassName="ms-1"
                    variant="danger"
                  > 
                    <i className="bi bi-x-lg text-white"></i>
                  </ButtonPrimary>
                
              </Col>
            </Row>

            <Row className="m-0 p-0">
              <Col className="m-0 py-1 px-2 px-sm-3"> 
                <p className="m-0 p-0 text-end text-muted " style={{ fontSize: '0.80rem'}}>
                  { data && data.TimeStamp && new Date(data.TimeStamp).toLocaleString() }
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

    </>
    );
  }
