import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Tab,
    Nav
} from "react-bootstrap";

import DecisionModal from "../components/Shared/InfoPopups/DecisionModal";
import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { propertiesDataActions } from "../store/PropertiesSlice";
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from "../contexts/AuthContext";
import useUpdateImages from "../hooks/useUpdateImages";
import useUpdateFiles from "../hooks/useUpdateFiles";
import BackNav from "../components/Shared/BackNav";

import { arrFromObjVal, arrFromObjKeysFilteredByVal } from '../utils/dataTransformations';
import { getBuildingFeatures, postCreateBuilding } from '../services/building';
import { geocodeByAddressHandler } from '../services/googleAPI';

import FormSection1 from "../components/BuildingAdd/FormSection1";
import FormSection2 from "../components/BuildingAdd/FormSection2";

import useGoogleAutocomplete from "../hooks/useGoogleAutocomplete";
import FormSection1Loader from "../components/BuildingAdd/FormSection1Loader";
import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';
import { uploadFiles } from "../services/filesRequests";
import schema from '../schemas/buildingSchema';
import { inviteManager } from '../services/account';
import ButtonWithLeftBorder from '../components/Shared/Buttons/ButtonWithLeftBorder';

import ManagerModal from "../components/Shared/AccountExistsStatusModal";

import StreetViewPositionModal from "../components/BuildingFormElements/StreetViewPositionModal";

import {
    GOOGLE_AUTOCOMPLETE,
    NAME,
    ADDRESS_LINE_1,
    ADDRESS_LINE_2,
    CITY,
    STATE,
    ZIP,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    LANDLORD_CONTACT_FULLNAME,
    LANDLORD_CONTACT_EMAIL,
    LANDLORD_CONTACT_PHONE,
    MANAGERS,
    MANAGER_ID,
    MANAGER_FULL_NAME,
    MANAGER_EMAIL,
    MANAGER_PHONE,
    LANDLORD_CONTACT_ID,
    MISC_NOTES,
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME
} from '../constants/buildingFormConstants';
import axios from "axios";

export default function AddNewUnitForm() {
    const history = useHistory();
    const { token } = useContext(AuthContext);
    const dispatch = useDispatch();
    const propertyFeaturesStore = useSelector(store => store.propertiesStore.propertyFeatures);
    const [amenities, setAmenities] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [eventKey, setKey] = useState("first");
    const [features, setFeatures] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [openStreetViewModal, setOpenStreetViewModal] = useState(false);
    const [streetViewPos, setStreetViewPos] = useState();
    const [svdata, setSVData] = useState(null);

    const [openManagersModal, setOpenManagersModal] = useState(false);
    const [preparedBuildingData, setPreparedBuildingData] = useState(null);

    const {
        googleValue,
        autocompleteError,
        lattitudeAndLongitude,
        addressFromAutocomplete,
        setGoogleValue,
        setAutocompleteError,
        googleAutocompleteClearState
    } = useGoogleAutocomplete();

    const {
        images,
        setImages
    } = useUpdateImages();

    const {
        files,
        setFiles
    } = useUpdateFiles();

    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const {
        register,
        control,
        handleSubmit,
        formState,
        watch
    } = useForm(formOptions);
    const { errors } = formState;
    const { fields: managerFields,
        append: managerAppend,
        remove: managerRemove } = useFieldArray({ name: MANAGERS, control })

    const watchGoogleAutocomplete = watch(["googleAutocomplete", false]);
    useEffect(() => {
        if (watchGoogleAutocomplete[0] === false) {
            googleAutocompleteClearState();
        }
    }, [watchGoogleAutocomplete, googleAutocompleteClearState]);

    const prepareImages = (images) => {
        const only10Images = images.slice(0, 10);
        const temp_images = only10Images.map(({ id, fileName, fileSize, isFavorite, show, src }) => ({
            id,
            fileName,
            fileSize,
            IsFavorite: isFavorite,
            show,
            src
        }))

        return temp_images;
    }

    const extrudeLatLng = async (data, lattitudeAndLongitude) => {
        if (!lattitudeAndLongitude) {
            const combinedAddress = `United States 
            ${data[ADDRESS_LINE_1] === null ? "" : data[ADDRESS_LINE_1]}
            ${data[ADDRESS_LINE_2] === null ? "" : data[ADDRESS_LINE_2]} 
            ${data[CITY] === null ? "" : data[CITY]} 
            ${data[STATE] === null ? "" : data[STATE]}`

            const geoResponse = await geocodeByAddressHandler(combinedAddress);
            const { success: geoSuccess, data: geoData } = geoResponse;
            if (geoSuccess) {
                return geoData.LatLong;
            } else {
                return;
            }
        } else {
            return lattitudeAndLongitude;
        }
    }


    const onSubmit = async (data) => {
        const userEmail = localStorage.getItem('userEmail');

        if (data[GOOGLE_AUTOCOMPLETE] && !googleValue) {
            setAutocompleteError(['Required']);
            return;
        } else {
            setAutocompleteError(null);
        }

        if (autocompleteError) {
            return;
        }

        setIsSaving(true);
        const latLong = await extrudeLatLng(data, lattitudeAndLongitude);
        if(!latLong) {
            ErrorPopup("Error! Couldn't get your property coordinates!");
            setIsSaving(false);
            return;
        } 
        setIsSaving(false);

        setStreetViewPos(latLong);
        setOpenStreetViewModal(true);
        
        const preparedAddress = addressFromAutocomplete
            ? addressFromAutocomplete.Address
            : (data[ADDRESS_LINE_2]
                ? `${data[ADDRESS_LINE_1]}$*|*$${data[ADDRESS_LINE_2]}`
                : data[ADDRESS_LINE_1]);

        const dataForManagersProperty = {
            ...data,
            [LANDLORD_CONTACT_FULLNAME]: 'self',
            [LANDLORD_CONTACT_EMAIL]: userEmail,
        }
        const Managers = prepareManagers(dataForManagersProperty);

        const preparedData = {
            [NAME]: data[NAME],
            BuildingType: "Co-op",
            Address: preparedAddress,
            [CITY]: addressFromAutocomplete ? addressFromAutocomplete.City : data[CITY],
            [STATE]: addressFromAutocomplete ? addressFromAutocomplete.State : data[STATE],
            [ZIP]: addressFromAutocomplete ? addressFromAutocomplete.Zip : data[ZIP],
            [MANAGERS]: Managers,
            PhotoBlobs: prepareImages(images),
            Amenities: arrFromObjKeysFilteredByVal(amenities),
            Accessibilities: arrFromObjKeysFilteredByVal(accessibilities),
            [MISC_AMENITIES]: data[MISC_AMENITIES],
            [MISC_ACCESSIBILITIES]: data[MISC_ACCESSIBILITIES],
            Notes: data[MISC_NOTES],
            Units: null
        }

        setPreparedBuildingData(preparedData);
    };

    const [BuildingID, setBuildingID] = useState(null);

    async function addBuilding() {
        setIsSaving(true);
        const finalData = {
            ...preparedBuildingData,
            Latitude: svdata.lat,
            Longitude: svdata.lng,
            StreetPov: {
                Heading: svdata.heading,
                Pitch: svdata.pitch,
                Zoom: svdata.zoom
            }
        }

        const response = await postCreateBuilding(finalData, token);
        const { success, data: responseData } = response;
        const { BuildingID } = responseData;

        if (success) {
            dispatch(propertiesDataActions.addProperty(responseData));

            if (files.length) {
                await uploadFilesHandler(BuildingID, files);
            }

            setIsSaving(false);
            googleAutocompleteClearState();

            setBuildingID(BuildingID);

            // TODO uncomment
            // history.push(`/building-info?building-id=${BuildingID}`);

            function checkManagers(managers) {
                if(!managers) return true;
                return !managers.filter( manager => manager.FullName !== 'self').length;
                
            }

            if(checkManagers(responseData.Managers)) {
                history.push(`/building-info?building-id=${BuildingID}`);
            }

        } else {
            ErrorPopup("Error! Couldn't create building. Try again later.");

            setIsSaving(false);
            googleAutocompleteClearState();
        }
    }

    async function uploadFilesHandler(BuildingID, files) {
        const URL = `building/${BuildingID}/upload/document`;
        const result = await uploadFiles(files, URL, token);
        const { success, data } = result;

        if (success) {
            dispatch(propertiesDataActions.editProperty({
                BuildingID,
                Documents: data.data
            }));
        } else {
            ErrorPopup("Error! Couldn't upload files. Try again later.");
        }
    }

    function prepareManagers(data) {
        let Managers = [];

        if (data && Array.isArray(data[MANAGERS])) {
            Managers = data[MANAGERS].map( manager => ({
                [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
                [MANAGER_FULL_NAME]: `${manager[MANAGER_FIRST_NAME]}$*|*$${manager[MANAGER_LAST_NAME]}`,
                [MANAGER_PHONE]: manager[MANAGER_PHONE]
            }))
        }

        if (data[LANDLORD_CONTACT_PHONE]) {
            const LandlordData = {
                [MANAGER_FULL_NAME]: data[LANDLORD_CONTACT_FULLNAME],
                [MANAGER_EMAIL]: data[LANDLORD_CONTACT_EMAIL],
                [MANAGER_PHONE]: data[LANDLORD_CONTACT_PHONE] || ""
            }

            if (data[LANDLORD_CONTACT_ID]) {
                LandlordData[MANAGER_ID] = data[LANDLORD_CONTACT_ID];
            }

            Managers.push(LandlordData);
        }

        return Managers;
    }

    useEffect(() => {
        onErrorRedirectToTabPane();

        function onErrorRedirectToTabPane() {
            if (errors.name ||
                errors.managerEmail ||
                errors.managerName ||
                errors.managerPhone ||
                errors.zipCode ||
                errors.state ||
                errors.addressLine ||
                errors.city ||
                // (images.length === 0) ||
                (autocompleteError && autocompleteError.length)
            ) {
                setKey("first");
                //TODO: add scroll to top of the Tab or to field with error
            }
        };

    }, [errors, images, autocompleteError])

    const fetchedFeatures = useCallback(async () => {
        if (propertyFeaturesStore) {
            setData(propertyFeaturesStore);
            return;
        }

        setLoading(true);

        const response = await getBuildingFeatures();
        const { success, data } = response;

        setLoading(false);

        if (success) {
            dispatch(propertiesDataActions.setPropertyFeatures(data));
            setData(data);
        } else {
            ErrorPopup("Error! Could not get building features data. Try again later.");
        }

        function setData(data) {
            const transformedObject = {
                Accessibilities: arrFromObjVal(data.Accessibilities),
                Amenities: arrFromObjVal(data.Amenities)
            }

            setFeatures(transformedObject);
        }
    }, [dispatch, propertyFeaturesStore]);

    useEffect(() => {
        fetchedFeatures();
    }, [fetchedFeatures]);

    const addManager = () => {
        managerAppend({
            [MANAGER_FULL_NAME]: '',
            [MANAGER_EMAIL]: '',
            [MANAGER_PHONE]: ''
        })
    }

    const removeManager = (index) => {
        managerRemove(index);
    }

    const removeManagerFromDB = async (managerID, index) => {
        removeManager(index);
    }

    const cancelEditHandler = () => {
        history.push(`/add`);
    }

    const userEmail = localStorage.getItem('userEmail');
    const [managerAccountStatus, setManagerAccountStatus] = useState({});

    const checkManagerAccountStatus = async (managers) => {
        const preparedRequests = managers
            .filter(manager => manager.FullName !== 'self')
            .map( manager => {
                const splittedName = manager[MANAGER_FULL_NAME].split("$*|*$");
                return {
                    [MANAGER_FIRST_NAME]: splittedName[0] || manager[MANAGER_FULL_NAME],
                    [MANAGER_LAST_NAME]: splittedName[1] || "",
                    [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
                }}
            )
            .map( manager => inviteManager(manager, token));

        const result = await axios.all(preparedRequests);

        setManagerAccountStatus( result.reduce((acc, { data, Email }) => {
            return {...acc, [Email]: data};
        }, {}) );
    }

    const handleSVsubmit = async () => {
        await addBuilding();
        setOpenStreetViewModal(false);
        
        if(preparedBuildingData.Managers?.some( manager => manager[MANAGER_FULL_NAME] !== 'self')) {
            setOpenManagersModal(true);

            const prepareManagers = preparedBuildingData.Managers.filter( manager => manager[MANAGER_FULL_NAME] !== 'self');
            await checkManagerAccountStatus( prepareManagers );
        }
    }
    
    const [svSuccess, setSVSuccess] = useState(false);

    const closeManagerModalHandler = () => {
        setOpenManagersModal(false);
        history.push(`/building-info?building-id=${BuildingID}`);
    }

    return (
        <>
            {isSaving && <LoaderWithBackdrop />}

            <ManagerModal 
                openManagersModal={openManagersModal}
                setOpenManagersModal={closeManagerModalHandler}
                building={preparedBuildingData}
                userEmail={userEmail}
                managerAccountStatus={managerAccountStatus}
            />

            <Container fluid style={{ maxWidth: 1200 }} className="p-0 m-auto mb-5 overflow-hidden">

                <BackNav className="m-0 p-0 mb-3 px-2"
                    disabled={loading}
                    currentPage="property"
                    pages={[{
                        title: "properties",
                        goTo: "/properties"
                    },
                    {
                        title: "add",
                        goTo: "/add"
                    }]}
                />

                {loading && (<FormSection1Loader />)}

                {!loading && (
                    <Row className="m-0 p-0 p-2 p-md-5 lightGray">

                        <StreetViewPositionModal 
                            openStreetViewModal={openStreetViewModal}
                            setOpenStreetViewModal={setOpenStreetViewModal}
                            streetViewPos={streetViewPos}
                            setSVData={setSVData}
                            setSVSuccess={setSVSuccess}
                            svSuccess={svSuccess}
                            handleSVsubmit={handleSVsubmit}
                        />

                        <Col className="m-0 p-0">
                            <Row className="m-0 p-0 justify-content-end justify-content-sm-between">
                                <Col className="m-0 p-0 flex-grow-1 order-1" xs="12" sm="auto">
                                    <h2 className="fw-bold m-0 p-0 mb-4">Add New Property</h2>
                                </Col>

                                <Col className="m-0 mb-3 p-0 order-0 order-sm-2" xs={12} sm={'auto'}>
                                    <Row className="m-0 p-0 justify-content-end">
                                        <Col className="m-0 p-0 pe-1" style={{ minWidth: 190 }}>
                                            <ButtonWithLeftBorder
                                                title='Cancel'
                                                additionalButtonClass='border-right-blue'
                                                titleMobile={'Cancel'}

                                                onClick={cancelEditHandler}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="m-0 p-0">
                                <Form
                                    className="d-flex flex-column p-0"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Tab.Container
                                        activeKey={eventKey}
                                    >
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <FormSection1
                                                    title="Add New Property"
                                                    images={images}
                                                    setImages={setImages}
                                                    errors={errors}
                                                    register={register}
                                                    googleValue={googleValue}
                                                    setGoogleValue={setGoogleValue}
                                                    autocompleteError={autocompleteError}

                                                    managerFields={managerFields}
                                                    addManager={addManager}
                                                    removeManager={removeManager}
                                                    removeManagerFromDB={removeManagerFromDB}
                                                    streetViewPos={streetViewPos}
                                                    setOpenStreetViewModal={setOpenStreetViewModal}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <FormSection2
                                                    files={files}
                                                    setFiles={setFiles}

                                                    errors={errors}
                                                    register={register}
                                                    eventKey={eventKey}
                                                    features={features}
                                                    amenities={amenities}
                                                    accessibilities={accessibilities}
                                                    setAmenities={setAmenities}
                                                    setAccessibilities={setAccessibilities}
                                                />
                                            </Tab.Pane>
                                        </Tab.Content>

                                        <Nav>
                                            <Row className="w-100 mt-3 mx-0 p-0">

                                                <Col className="d-none d-sm-block m-0 p-1">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first"
                                                            className={`btn btn-primary m-0 rounded ${eventKey === "first" ? "disabled" : ""}`}
                                                            onClick={() => setKey("first")}
                                                            style={{ height: 38 }}
                                                        >
                                                            <h6 className="m-0 p-0 fw-bolder link-light">Previous</h6> </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                                <Col className="d-block d-sm-none m-0 p-1">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first"
                                                            className={`btn btn-primary m-0 rounded ${eventKey === "first" ? "disabled" : ""}`}
                                                            onClick={() => setKey("first")}
                                                            style={{ height: 38 }}
                                                        >
                                                            <h6 className="m-0 p-0 fw-bolder link-light">&lt;</h6> </Nav.Link>
                                                    </Nav.Item>
                                                </Col>

                                                <Col className="m-0 p-1">
                                                    <Nav.Item>
                                                        <Button
                                                            className={`btn btn-primary m-0 rounded w-100 ${eventKey === "first" ? "disabled" : ""}`}
                                                            type="submit"
                                                            variant="primary"
                                                            style={{ height: 38 }}
                                                        >
                                                            <h6 className="m-0 p-0 fw-bolder link-light">Save</h6>
                                                        </Button>
                                                    </Nav.Item>
                                                </Col>

                                                <Col className="d-none d-sm-block m-0 p-1">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second"
                                                            className={`btn btn-primary m-0 rounded w-100 ${eventKey === "second" ? "disabled" : ""}`}
                                                            onClick={() => setKey("second")}
                                                            style={{ height: 38 }}
                                                        >
                                                            <h6 className="m-0 p-0 fw-bolder link-light">Next</h6> </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                                <Col className="d-block d-sm-none m-0 p-1">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second"
                                                            className={`btn btn-primary m-0 rounded w-100 ${eventKey === "second" ? "disabled" : ""}`}
                                                            onClick={() => setKey("second")}
                                                            style={{ height: 38 }}
                                                        >
                                                            <h6 className="m-0 p-0 fw-bolder link-light">&gt;</h6> </Nav.Link>
                                                    </Nav.Item>
                                                </Col>
                                            </Row>
                                        </Nav>
                                    </Tab.Container>
                                </Form>
                            </Row>
                        </Col>
                    </Row>
                )}

            </Container>
        </>
    );
}
