import React from 'react';
import {
    Container,
    Row,
    Col,
    Button
} from 'react-bootstrap';

import ButtonPrimary from '../Buttons/ButtonPrimary';

export default function Filters ({
    show,
    close,
    resetFilters,
    howManyFiltersActive,
    children
}) {
    return (
        <Container className={`py-3 ps-3 sideDrawer sideDrawer--right d-flex flex-column ${show ? 'sideDrawer--show' : ''} bg-light`}>

            <Row className={'m-0 p-0 mb-3 sideDrawer__close'}>
                <Col className={`m-0 p-0 d-flex ${howManyFiltersActive && howManyFiltersActive() > 0 ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
                    { howManyFiltersActive && howManyFiltersActive() !== 0 ? (
                            <Button onClick={resetFilters} className="d-flex justify-content-between align-items-center m-0 p-2 border-0 bg-transparent">
                                <i className="bi bi-arrow-clockwise text-danger pe-2"></i>
                                <p className="m-0 p-0" style={{fontSize: '0.85rem'}}>Reset Filters</p>
                            </Button>
                        ) : null
                    }

                    <Button className="m-0 p-2 border-0 bg-transparent" onClick={close}>
                        <i className="bi bi-x-lg text-dark"></i>
                    </Button>
                </Col>
            </Row>
            
            <Container className="m-0 p-0 pe-3 pt-1 flex-grow-1" style={{ overflowY: 'auto'}}>
                { children }
            </Container>

            
            <Row className={'m-0 p-0 justify-content-center'}>
                <Col className="m-0 p-0" xs="auto">
                   
                    <ButtonPrimary 
                        title="OK"
                        onClick={close}
                        additionalClassName="px-5"
                    />
                </Col>
            </Row>

        </Container>
    )
}

export function FilterRow ({ clearFilter, title, children, value}) {
    return (
        <Row className="m-0 p-0 mb-3 position-relative">
            <Row  className="m-0 p-0">
                <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                    <h6 className="m-0 p-0 fw-normal">{title}</h6>
                    <Button className="m-0 p-0 bg-transparent px-2 border-0 rounded" disabled={!value} onClick={() => clearFilter(title)}>
                        <p className="m-0 p-0" style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                    </Button>
                </Col>
            </Row>
            <Row  className="m-0 p-0">
                { children }
            </Row>
        </Row>
    )
}
