import { 
    object as YupObject, 
    string as YupString, 
} from "yup";

const schema = YupObject().shape({
    // BASIC INFORMATION
    FirstName: YupString()
        .transform(value => value.trim())
        .required('First name is required'),
    LastName: YupString()
        .transform(value => value.trim())
        .required('Last name is required'),

    AddressLine1: YupString()
            .required("Address is required")
            .transform(value => value.trim()),
    AddressLine2: YupString()
        .notRequired()
        .transform(value => value.trim()),

    City: YupString()
        .transform(value => value.trim())
        .required('City is required'),
    State: YupString()
        .transform(value => value.trim())
        .required('State is required'),
    Zip: YupString()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits"),
    Phone: YupString()
        .required("Phone number is required")
        .transform(value => value.trim())
        .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
            "Provide a valid phone number"),
    Email: YupString()
        .transform(value => value.trim())
        .email('Invalid email address')
        .required('Email is required'),
});

export default schema;
