import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import { BuildingContext } from "../contexts/BuildingContext"; 
import { BuildingsContext } from "../contexts/BuildingsContext";
import { CreatePortfolioContext } from '../contexts/CreatePortfolioContext';
import { TicketsContext } from '../contexts/TicketsContext';
import { UserTypeContext } from "../contexts/UserTypeContext";

import { propertiesDataActions } from '../store/PropertiesSlice';
import { requestsActions } from '../store/RequestsSlice';
import { marketplaceActions } from '../store/MarketplaceSlice';
import { userActions } from "../store/UserSlice";
import { managerDataActions } from "../store/ManagerDataSlice";


export default function useLogout () {
    const dispatch = useDispatch();
    const history = useHistory();

    const { logout } = useContext(AuthContext);
    const { setBuildingInfo } = useContext(BuildingContext); //TODO: delete
    const { setBuildingsData } = useContext(BuildingsContext); //TODO: delete
    const { resetCreatePortfolio } = useContext(CreatePortfolioContext); //TODO: delete
    const { setTickets } = useContext(TicketsContext); //TODO: delete
    const { setUserType } = useContext(UserTypeContext); //TODO: delete

    const logoutHandler = () => {
        dispatch( propertiesDataActions.clearPropertiesStore() );
        dispatch( requestsActions.clearRequestsStore() );
        dispatch( marketplaceActions.clearMarketplaceStore() );
        dispatch( userActions.clearUserStore() );
        dispatch( managerDataActions.clearManagerStore() );

        setBuildingInfo(null);
        setBuildingsData({});
        resetCreatePortfolio();
        setTickets(null);
        setUserType("");
    
        logout();
    
        history.replace('/');

        return true;
    }

    return {
        logoutHandler
    }
}
