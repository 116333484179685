import React, { useState, useMemo } from 'react';
import {
    Row,
    Col,
    Modal
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';

const messages = {
    no_message: "Screening report haven't been generated yet",
    preparing: "Report is not ready yet",
    not_accessible_yet: "Report is not accessible yet",
    ready: "Report is ready"
}


export default function Screening({
    onlyView,
    reportInfo
}) {
    const history = useHistory();
    const [reportReady, setReportReady] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const generateAReportHandler = () => {
        history.push('/tenant-screening')
    }

    const compute_message = useMemo(() => {
        const status = reportInfo.TenantTrackReport;

        if(!status) {
            setReportReady(false);
            return messages.no_message
        }

        switch(status) {
            case "Report Not Ready":
                setReportReady(false);
                return messages.preparing
            case "report is not accessible yet":
                setReportReady(false);
                return messages.not_accessible_yet
            default: 
                setReportReady(true);
                return messages.ready
        }

    }, [reportInfo]);

    return (
        <>
            <Modal show={showModal} fullscreen={true} onHide={closeModal}>

                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Screening Report</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="m-0 p-0 justify-content-center">
                        <Col xs={12} lg={10} xl={8} dangerouslySetInnerHTML={{__html: reportInfo && reportInfo.TenantTrackReport}}></Col>
                    </Row>
                </Modal.Body>

            </Modal>

            <Row className={`p-0 pb-5 m-0 row-cols-1 montserrat`}>
                {/* <Col className="darkerGray p-2 text-center m-0 position-relative rounded">
                    <h5 className="fw-bold m-0 p-0 text-uppercase">Screening</h5>
                </Col> */}

                { !onlyView && (
                    <>
                        <Col className="m-0 mt-3 p-0 text-center" >
                            <h6 className="m-0 p-0 fw-bold">{compute_message}</h6>
                        </Col>
                        { (reportInfo && !reportReady) &&
                            <Col className="m-0 p-0 mt-3">
                                <Row className="justify-content-center">
                                    <Col xs="auto">
                                        <ButtonPrimary onClick={generateAReportHandler}>
                                            <i className="bi bi-journal-check ms-2"></i>{ !reportInfo.TenantTrackReport ? ' Generate a Report' : ' Open Screening'}
                                        </ButtonPrimary>
                                    </Col>
                                </Row>

                            </Col>
                        }

                        { (reportInfo && reportReady) &&
                            <Col className="m-0 p-0 mt-3">
                                <Row className="justify-content-center">
                                    <Col xs="auto">
                                        <ButtonPrimary onClick={openModal}>
                                            <i className="bi bi-journal-check ms-2"></i> Show Report
                                        </ButtonPrimary>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </>
                )}

                { onlyView && (
                    <Col className="m-0 mt-3 p-0 text-center" >
                        <h6 className="m-0 p-0 fw-bold">{compute_message}</h6>
                    </Col>
                )}

                {(onlyView && reportInfo && reportReady) && (
                    <Col className="m-0 mt-3 p-0 text-center">
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <ButtonPrimary onClick={openModal}>
                                    <i className="bi bi-journal-check ms-2"></i> Show Report
                                </ButtonPrimary>
                            </Col>
                        </Row>
                    </Col>
                )}

            </Row>
        </>
    )
}
