import React from 'react';
import LogoLight from '../../assets/logos/logo_light.svg';

import {
    Row,
    Col,
    Image
} from 'react-bootstrap';


export default function LoaderWithBackdrop() {
    return (
        <div className='d-flex justify-content-center align-items-center backdrop-loader'>
            <Row className="m-0 p-0 ">
                <Col className="m-0 p-0 d-flex justify-content-center align-content-center flex-column">
                    <Image className="m-0 p-0" 
                        style={{ objectFit: "cover", height: 120, width: "auto" }}
                        src={LogoLight} 
                        alt="Loader"></Image>  
                    <div className="loader__logo loader__logo--light mx-auto my-0"><span className="visually-hidden">Loading...</span></div>
                </Col>
            </Row>
        </div>
    )
}
