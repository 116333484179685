import React from "react";
import { Container, Col, Row } from "react-bootstrap";


export const MarketplaceNoMatch = () => {
  return (
    <Container
      fluid
      className="d-flex row justify-content-center align-content-center"
      style={{ height: "90vh" }}
    >
      {/* <Row>
        <Col className="mb-5 d-flex justify-content-center">
          <LazyImage src={sadFace} alt="nothing-found" className="no-match-icon" />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <h5 style={{ textAlign: "center" }}>
            Nothing found. Please change your search criteria.
          </h5>
        </Col>
      </Row>
    </Container>
  );
};
