import { postPutRequest, getRequest, deleteRequest } from './http-common';


const getFavorites = async (token) => {
    const URL = 'user/favorites';

    return await getRequest(URL, token);
}

const addFavoriteBuilding = async (token, buildingID) => {
    const URL = `user/favorite/building/${buildingID}`;

    return postPutRequest(URL, {}, token, 'post');
}

const deleteFavoriteBuilding = async (token, buildingID) => {
    const URL = `user/favorite/building/${buildingID}`;

    return await deleteRequest( URL, {}, token);
}

const addFavoriteUnit = async (token, unitID) => {
    const URL = `user/favorite/unit/${unitID}`;

    return postPutRequest(URL, {}, token, 'post');
}

const deleteFavoriteUnit = async (token, unitID) => {
    const URL = `user/favorite/unit/${unitID}`;

    return await deleteRequest( URL, {}, token);
}

/**
 * 
 * @param {String} token 
 * @param {Object} data
 * @param {String} data.City
 * @param {String} data.Zip
 * @param {Int} data.MinBedroomCount
 * @param {Int} data.MaxBedroomCount
 * @param {Int} data.MinBathroomCount
 * @param {Int} data.MaxBathroomCount
 * @param {Float} data.MinRentPrice
 * @param {Float} data.MaxRentPrice
 * @param {Array<Int>} data.BuildingAmenityIDs
 * @param {Array<Int>} data.BuildingAccessibilityIDs
 * @param {Array<Int>} data.UnitAmenityIDs
 * @param {Array<Int>} data.UnitAccessibilityIDs
 * @returns 
 */
const addFavoriteQuery = async (token, data) => {
    const URL = 'user/favorite/query';

    return postPutRequest(URL, data, token, 'post');
}

const deleteFavoriteQuery = async (token, queryID) => {
    const URL = `user/favorite/search/${queryID}`;

    return await deleteRequest( URL, {}, token);
}

export { 
    getFavorites,
    addFavoriteBuilding,
    deleteFavoriteBuilding,
    addFavoriteUnit,
    deleteFavoriteUnit,
    addFavoriteQuery,
    deleteFavoriteQuery,
};
