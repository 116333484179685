import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { orderBy } from "lodash";
import { 
    object as YupObject, 
    string as YupString
} from "yup";

import { useSelector } from 'react-redux';
import InputFloating from '../Shared/Inputs/InputFloating';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';
import Backdrop from "../Shared/Backdrop";
import FiltersBtn from "../Shared/Filters/FiltersBtn";
import Filters, { FilterRow } from "../Shared/Filters";
import { ClientRow } from '../../Pages/Client';

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../../constants/client';

import { STATES } from '../../constants/Shared/states';

import { CLIENT } from '../Clients/AddClientModal';

const groupSchema = YupObject().shape({
    [GROUP_NAME]: YupString().required('Field is required'),
    [GROUP_DESC]: YupString().notRequired('Field is required'),
});

const SORT_DIR = Object.freeze({
    ASC: 'asc',
    DESC: 'desc'
})

export default function AddGroupModal({
    show,
    onHide=() => {},
    onCancel=() => {},
    editGroupWithId,
    onEditGroup,
    onAddGroup,

    temp_onAddClient,
    temp_onDeleteClient
}) {
    const groups = useSelector(store => store.caseManagerStore.groups);
    const clients = useSelector(store => store.caseManagerStore.clients);
    
    const [selectedClients, setSelectedClients] = useState([]);
    const [groupModalTitle, setGroupModalTitle] = useState('Add Group');
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(SORT_DIR.ASC);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [activeFilters, setActiveFilters] = useState({
        Group: '',
        City: '',
        State: '',
        Verified: '',
        ClientType: '',
    });
    const [ showFilters, setShowFilters ] = useState(false);

    const {
        register: registerGroupField,
        handleSubmit: handleSubmitGroupForm,
        formState: formStateGroup,
        reset: resetGroupForm
    } = useForm({ resolver: yupResolver(groupSchema), mode: "all", defaultValues: {
        [GROUP_NAME]: '',
        [GROUP_DESC]: ''
    } });

    const { errors: errorsGroup } = formStateGroup;

    const prepareDetailedInfo = (client) => {
        const genderShort = client[CLIENT.CLIENT_GENDER]?.[0] || "";
        return `${genderShort}${genderShort ? ", ": ""}${client[CLIENT.CLIENT_AGE] || ""}${client[CLIENT.CLIENT_AGE] ? "y, " : ""}${client[CLIENT.CLIENT_CITY] || ""}${client[CLIENT.CLIENT_CITY] ? " " : ""}${client[CLIENT.CLIENT_STATE] || ""}`;
    }
    
    const getGroupNameForClient = (client, groups) => {
        if(client && !client[CLIENT.CLIENT_GROUP]) return '';
        if(client && Array.isArray(client[CLIENT.CLIENT_GROUP]) && !client[CLIENT.CLIENT_GROUP].length ) return '';
        const clientGroupsById = client[CLIENT.CLIENT_GROUP];
        const clientGroupNames = clientGroupsById.map( groupId => groups.find( group => group[GROUP_ID] === groupId)[GROUP_NAME]);
        return clientGroupNames;
    }
    const toggleSelectedClient = (client) => {
        setSelectedClients( prevState => {
            if( prevState.includes(client[CLIENT.CLIENT_ID]) ) {
                return prevState.filter( id => id !== client[CLIENT.CLIENT_ID] );
            }
            return [...prevState, client[CLIENT.CLIENT_ID]];
        })
    }
    const onFormValid = async (data) => {
        const formattedData = {
            ...data,
            [CLIENTS]: selectedClients
        }

        if(editGroupWithId) {
            onEditGroup(formattedData);
            return;
        }

        const result = await onAddGroup(formattedData);
        if( result.success ) {
            resetGroupForm();
        }
    }
    const onHideHandler = () => {
        resetGroupForm();
        onHide();
    }

    const sortByHandler = (item) => {
        if(!item) throw new Error("Missing argument!");
        setSortBy(() => item);

        if( item === sortBy) {
            setSortDirection( prevValue => prevValue === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC);
        } else {
            setSortDirection(SORT_DIR.ASC);
        }
    }

    const sort = (array, sortByItem, sortDir) => {
        if(!array) return array;

        return orderBy(array, [sortByItem], [sortDir])
    }

    const onChangeSearchHandler = (event) => {
        setSearchPhrase(event.target.value);
    }

    const resetFilters = () => {
        setActiveFilters({
            Group: '',
            City: '',
            State: '',
            Verified: '',
            ClientType: '',
        })
      }
    const clearFilterHandler = (filterName, defaultValue='') => {
        setActiveFilters( prevState => ({
            ...prevState,
            [filterName]: defaultValue
        }))
    }
    const changeHandler = (value, filterName) => {
        setActiveFilters( prevState => ({
          ...prevState,
          [filterName]: value
        }))
    }
    const openFilters = () => {
        setShowFilters(true);
    }
    const availableCities = useMemo(() => {
        if(!clients) return [];

        const onlyUniqueCities = new Set(clients.map( client => client.clientCity));
        return Array.from(onlyUniqueCities);
    }, [clients]);

    const closeFilters = () => {
        setShowFilters(false);
    }
    const filterHandler = useMemo(() => {
        if(!clients) return [];
        if(Array.isArray(clients) && !clients.length) return [];

        let sortedAndFilteredArr = [...clients];

        // FILTER
        if(activeFilters.Group) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientGroup === activeFilters.Group);
        }
        if(activeFilters.City) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientCity === activeFilters.City);
        }
        if(activeFilters.State) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientState === activeFilters.State);
        }
        if(activeFilters.Verified) {
            // console.log(sortedAndFilteredArr);
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => {
                if(activeFilters.Verified === 'true') {
                    return String(data.clientVerified) === activeFilters.Verified
                } else {
                    return !data.clientVerified
                }
            });
        }
        if(activeFilters.ClientType) {
            sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.clientType === activeFilters.ClientType);
        }

        // SORT
        if(sortBy === CLIENT.CLIENT_NAME) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }
        if(sortBy === CLIENT.CLIENT_TYPE) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }
        if(sortBy === CLIENT.CLIENT_VERIFIED) {
            sortedAndFilteredArr = sort(sortedAndFilteredArr, sortBy, sortDirection);
        }

        if(searchPhrase){
            sortedAndFilteredArr = sortedAndFilteredArr.filter( client => {
                const doesContain = [CLIENT.CLIENT_NAME, CLIENT.CLIENT_GENDER, CLIENT.CLIENT_AGE, CLIENT.CLIENT_CITY, CLIENT.CLIENT_STATE, CLIENT.CLIENT_TYPE, CLIENT.CLIENT_VERIFIED].some( key => {

                    // TODO: if key === true or false then replace it with 'no' or 'yes'

                    if( String(client[key]).toLocaleLowerCase()?.includes(searchPhrase.toLocaleLowerCase()) ) return true;
                    return false
                })
                
                return doesContain;
            })
        }
    
        return sortedAndFilteredArr;
    }, [sortBy, sortDirection, clients, searchPhrase, activeFilters]);

    const howManyFiltersActive = () => {
        return Object.entries(activeFilters).filter( ([_, value]) => {
          if(Array.isArray(value)) return value.length > 0;
          return !!value;
        }).length;
    }

    useEffect(() => {
        if(editGroupWithId) {
            const defaultData = groups.find( group => String(group[GROUP_ID]) === String(editGroupWithId));
            resetGroupForm(defaultData);
            setGroupModalTitle('Edit Group');
            setSelectedClients(defaultData?.[CLIENTS] || [])
        } else {
            // console.log('[AddGroupModa][useEffect] else condition was triggered!');
            resetGroupForm();
            setGroupModalTitle('Add Group');
        }
    }, [editGroupWithId, groups, resetGroupForm]);

    return(
        <>
            <Backdrop 
                show={showFilters}
                close={closeFilters}
            />

            { howManyFiltersActive &&  (
                <Filters 
                    show={showFilters}
                    close={closeFilters}
                    resetFilters={resetFilters}
                    howManyFiltersActive={howManyFiltersActive}
                >
                    <FilterRow
                        clearFilter={() => clearFilterHandler('Group')}
                        title={"Group"}
                        value={ activeFilters.Group }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.Group }
                            onChange={ (e) => changeHandler(e.target.value, 'Group') }
                        >
                            <option value='' defaultChecked>All</option>
                            {groups && groups.map( group => (
                                <option key={group[GROUP_ID]} value={group[GROUP_ID]}>{group[GROUP_NAME]}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>


                    <FilterRow
                        clearFilter={() => clearFilterHandler('City')}
                        title={"City"}
                        value={ activeFilters.City }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.City }
                            onChange={ (e) => changeHandler(e.target.value, 'City') }
                        >
                            <option value='' defaultChecked>All</option>
                            {availableCities && availableCities.map( city => (
                                <option key={city} value={city}>{city}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>

                    <FilterRow 
                        clearFilter={() => clearFilterHandler('State')}
                        title={'State'}
                        value={ activeFilters.State }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select state" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.State }
                            onChange={ (e) => changeHandler(e.target.value, 'State') }
                        >
                            <option value='' defaultChecked>All</option>
                            {STATES.split(",").map((state) => {
                                return (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FilterRow>

                    <FilterRow 
                        clearFilter={() => clearFilterHandler('Verified')}
                        title={'Verified'}
                        value={ activeFilters.Verified }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select verified" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.Verified }
                            onChange={ (e) => changeHandler(e.target.value, 'Verified') }
                        >
                            <option value='' defaultChecked>All</option>
                            {[{ name: 'No', val: false }, { name: 'Yes', val: true}].map((verified) => {
                                return (
                                    <option key={verified.name} value={verified.val}>
                                        {verified.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FilterRow>

                    <FilterRow
                        clearFilter={() => clearFilterHandler('ClientType')}
                        title={"ClientType"}
                        value={ activeFilters.ClientType }
                    >
                        <Form.Select 
                            style={{height: 46}}
                            aria-label="Select group" 
                            className="rounded border-0 customSelect shadow-sm fs-6"
                            value={ activeFilters.ClientType }
                            onChange={ (e) => changeHandler(e.target.value, 'ClientType') }
                        >
                            <option value='' defaultChecked>All</option>
                            {['Tenant', 'Landlord'].map( type => (
                                <option key={type} value={type}>{type}</option>
                            ) )}
                        </Form.Select>

                    </FilterRow>

                </Filters>
                )
            }
        
            <Modal
                show={show}
                onHide={onHideHandler}
                centered
                fullscreen
            >
                <Modal.Header closeButton>
                    <h6 className="m-0 fw-bold">{groupModalTitle}</h6>
                </Modal.Header>
                <Modal.Body className="m-0 p-0" >
                    <Container fluid className="pt-3 pt-sm-4 pt-md-5 pb-3 px-2 px-sm-3 px-lg-5">
                        
                        <Form className="m-0 p-0">
                            <Row className="m-0 mb-3">
                                <Col className="p-0">
                                    <InputFloating 
                                        label="Name"
                                        disabled={editGroupWithId}
                                        register={registerGroupField(GROUP_NAME)}
                                        errorMessage={errorsGroup[GROUP_NAME]?.message}
                                        showError={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="m-0 mb-4">
                                <Col className="p-0">
                                    <TextAreaFloating 
                                        disabled={editGroupWithId}
                                        label="Description"
                                        register={registerGroupField(GROUP_DESC)}
                                    />
                                </Col>
                            </Row>
                        </Form>
                            
                        <Row className="m-0 mb-2">
                            <Col className="p-0">
                                { !editGroupWithId && (
                                    <p className="m-0 fs-6">Add clients to the group by selecting them in the table below</p>
                                )}
                            </Col>
                        </Row>

                        {/* <Row className="m-0 mb-3 justify-content-center align-items-center">
                            <Col xs={6} xl={4} className="p-0 flex-grow-1 flex-md-grow-0">
                                <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                                    <input 
                                        onChange={onChangeSearchHandler}
                                        value={searchPhrase}
                                        placeholder="Type to search..." className="form-control border-0 px-0 shadow-none" />
                                    <i className="d-flex bi bi-search fs-5 text-dark"></i>
                                </div>
                            </Col>

                            <Col xs={'auto'} className="p-0 d-flex ps-2">

                                { howManyFiltersActive && (
                            
                                    <FiltersBtn 
                                        howManyFiltersActive={howManyFiltersActive}
                                        showResults={false}

                                        resetFilters={resetFilters}
                                        openFilters={openFilters}
                                        disabled={false}
                                    />
                                )}

                            </Col>

                        </Row> */}

                        <Row className="m-0 overflow-hidden flex-grow-1">
                            <Col className="p-0 position-relative">
                                <div className="table-responsive pe-1" style={{height: 245}}>
                                    <table className="table table-borderless">
                                        <thead className="position-sticky top-0 left-0" style={{zIndex: 1}}>
                                            <tr >
                                                {/* <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_NAME)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>Name</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th> */}

                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_FIRST_NAME)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>First Name</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_FIRST_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_FIRST_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_LAST_NAME)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-file-person-fill me-2"></i>Last Name</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_LAST_NAME ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_LAST_NAME ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1">
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-card-text me-2"></i>Details</p>
                                                    <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>

                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_TYPE)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-people-fill me-2"></i>Client Type</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_TYPE ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_TYPE ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            
                                            <th scope="col" className="table-header__wrapper flex-grow-1" onClick={() => sortByHandler(CLIENT.CLIENT_VERIFIED)}>
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-patch-check-fill me-2"></i>Verified</p>
                                                    <i className={`table-header__icon ps-1 bi ${ sortDirection === SORT_DIR.DESC && sortBy === CLIENT.CLIENT_VERIFIED ? 'bi-caret-down-fill' : 'bi-caret-up-fill'} ${ sortBy === CLIENT.CLIENT_VERIFIED ? "text-success" : ""}`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            <th scope="col" className="table-header__wrapper flex-grow-1">
                                                <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                    <p className="table-header__text"><i className="table-header__text-icon bi bi-exclamation-circle-fill me-2"></i>Associated Group</p>
                                                    <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                </div>
                                            </th>
                                            { !!editGroupWithId && (
                                                <th scope="col" className="table-header__wrapper flex-grow-1">
                                                    <div className="d-flex justify-content-center align-items-center darkerGray p-2 rounded border border-1 border-light">
                                                        <p className="table-header__text"><i className="table-header__text-icon bi bi-wrench me-2"></i>Actions</p>
                                                        <i className={`table-header__icon ps-1 bi`} style={{color: "rgba(0, 0, 0, 0.5)"}}></i>
                                                    </div>
                                                </th>
                                            )}
                                            </tr>
                                        </thead>
                                        <tbody>

                                        { filterHandler && filterHandler.map((client)=>(

                                            <ClientRow 
                                                key={client[CLIENT.CLIENT_ID]}
                                                id={client[CLIENT.CLIENT_ID]}
                 
                                                details={prepareDetailedInfo(client)}
                                                clientType={client[CLIENT.CLIENT_TYPE]}
                                                verified={client[CLIENT.CLIENT_VERIFIED] ? 'Yes' : 'No'}
                                                associatedGroup={getGroupNameForClient(client, groups)}
                                                showActions={!!editGroupWithId}
                                                onClickRow={ !!editGroupWithId ? () => {} : () => toggleSelectedClient(client) }
                                                className={ selectedClients.includes(client[CLIENT.CLIENT_ID]) ? "bg-success text-light" : "lightGray" }
                                                isIncludedInGroup={ selectedClients.includes(client[CLIENT.CLIENT_ID]) }

                                                firstName={client[CLIENT.CLIENT_FIRST_NAME]}
                                                lastName={client[CLIENT.CLIENT_LAST_NAME]}
                                                email={client[CLIENT.CLIENT_EMAIL]}
                                                mobile={client[CLIENT.CLIENT_PHONE]}

                                                temp_showEditActions={true}
                                                temp_onAddClient={ () => temp_onAddClient(client[CLIENT.CLIENT_ID], editGroupWithId) }
                                                temp_onDeleteClient={ () => temp_onDeleteClient(client[CLIENT.CLIENT_ID], editGroupWithId) }
                                            />

                                        ))}

                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>


                    </Container>
                </Modal.Body>
                <Modal.Footer className='m-0 p-0'>
                    <Row className="m-0 p-2 w-100 justify-content-end">
                        <Col xs={12} sm="auto" className="p-0 mb-1 me-sm-1 mb-sm-0">
                            <ButtonSecondary
                                title="Close"
                                onClick={onHideHandler}
                            />
                        </Col>
                        { !editGroupWithId && (
                            <Col xs={12} sm="auto" className="p-0">
                                <ButtonPrimary 
                                    title={ 'Save' }
                                    onClick={(e) => handleSubmitGroupForm(onFormValid)(e)}
                                />
                            </Col>
                        )}
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
