import React, { useState, useMemo } from 'react';
import {
    Container,
    Row,
    // Col
} from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
// import {AnimatePresence, motion} from 'framer-motion';

import UnitCard from './UnitCard';
import UnitCardLoader from './UnitCardLoader';

// const animations = {
//     initial: {opacity: 0},
//     animate: {opacity: 1},
//     exit: {opacity: 0},
//     transition: {duration: .500, transition: 'ease-in-out'}
// }
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
export default function PropertyUnits({
    buildings,
    unitsForPropertyId,
    alreadyRequestesUnitIDs,
    selectUnitHandler,
    favorites,
    isLoading,
    activeFilters
}) {
    const isMap = useLocation().pathname.split('/').at(-1) === 'map';

    const [activeUnitID, setActiveUnitID] = useState(null);
    const units = buildings && buildings.find(({ BuildingID }) => BuildingID == unitsForPropertyId)?.Units.filter(({ Vacant }) => isMap ? true : Vacant);

    const StreetPOV = buildings && buildings.find(({ BuildingID }) => BuildingID == unitsForPropertyId)?.StreetPOV;

    const sortFromLowest = (propertyArray, key, transformFcn = (x) => x) => {
        if (!propertyArray) return propertyArray;

        return orderBy(propertyArray, [function (a) { return transformFcn(a[key]) }], ['asc'])
    }

    const sortFromHighest = (propertyArray, key, transformFcn = (x) => x) => {
        if (!propertyArray) return propertyArray;

        return orderBy(propertyArray, [function (a) { return transformFcn(a[key]) }], ['desc'])
    }
    const sortedUnits = useMemo(() => {
        if (!units) return [];
        let sortedAndFilteredArr = [...units];

        if (activeFilters.Price === 'fromLowest') {
            sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'Rent');
        }

        if (activeFilters.Price === 'fromHighest') {
            sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'Rent');
        }

        // if (activeFilters.Vacant === 'fromLowest') {
        //   sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'numberOfVacant');
        // }

        // if (activeFilters.Vacant === 'fromHighest') {
        //   sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'numberOfVacant');
        // }

        if (activeFilters.Date === 'fromLowest') {
            sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'LastVacantDate', (a) => new Date(a).getTime());
        }

        if (activeFilters.Date === 'fromHighest') {
            sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'LastVacantDate', (a) => new Date(a).getTime());
        }

        // setResultCount(sortedAndFilteredArr.length);
        return sortedAndFilteredArr;
    }, [activeFilters.Price, activeFilters.Vacant, activeFilters.Date, units]);



    return (
        <Container className="m-0 p-0" fluid>
            <Row className="m-0 p-0">
                {/* <AnimatePresence>
                    <motion.div {...animations} layout className="w-100 m-0 p-0"> */}

                {
                    !isLoading && sortedUnits && sortedUnits.map((unit, index) => {
                        return (
                            <UnitCard
                                alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                                showUnitID={true}
                                unit={unit}
                                StreetPOV={StreetPOV}

                                key={unit.UnitID}
                                selectUnitHandler={selectUnitHandler}
                                activeUnitID={activeUnitID}
                                setActiveUnitID={setActiveUnitID}
                                building={buildings.filter(building=>building.Units.find(u=>unit.UnitID == u.UnitID))[0]||null}
                                favorites={favorites}
                            />
                        );
                    })
                }

                {
                    isLoading && (
                        <>
                            <UnitCardLoader />
                        </>
                    )
                }

                {/* </motion.div>
                </AnimatePresence> */}
            </Row>
        </Container>
    )
}
