import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { signupRequest, getUnregisteredAccountData } from '../services/user';
import { getSettingsForUserType } from '../services/settings';

import { UserTypeContext } from '../contexts/UserTypeContext';
import schema from '../schemas/signUpSchema';

import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";
import Footer from '../components/Shared/Footer';

import Backdrop from "../components/Shared/Backdrop";
import LogoLoader from "../components/Shared/LogoLoader";
import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";


export default function SignupDetails() {
  const [ isLoading, setIsLoading ] = useState(false);
  const { userType, setUserType } = useContext(UserTypeContext);
  const [settings, setSettings] = useState(null);
  const { accountStatusKey } = useParams();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors }, 
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  const goBackHandler = () => {
    history.push('/signup');
  }

  const getSettingsHandler = async (userType) => {
    setIsLoading(true);

    const {success, data} = await getSettingsForUserType(userType.toLowerCase());

    if(success) {
      setSettings( Object.entries(data).reduce((acc, [SettingID, Description]) => {
        acc[SettingID] = {
          Description,
          IsActive: false
        }
        return acc;
      } , {}));
    } else {
      // ErrorPopup("Error! Could not get settings.");
    }

    setIsLoading(false);
  }

  const signup = async (userData) => {
    const { email, password, firstName, lastName } = userData;
    const prepareSettings = Object.entries(settings).reduce( (acc, [key, value]) => {
      acc[key] = value.IsActive;
      return acc;
    }, {});

    let postData = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Password: password,
      UserType: userType === 'Casemanager' ? 'CaseManager' : userType,
      // UserType: userType,
      Settings: prepareSettings
    };

    setIsLoading(true);
    const response = await signupRequest(postData);
    const {success, data} = response;
    setIsLoading(false);

    if(success) {
      SuccessPopup("Success! Account was created. Please log in.");
      history.replace('/login');
    } else {
      setValue("password", '');
      setValue("passwordConfirmation", '');

      ErrorPopup(data && data?.data ? data?.data : "Account creation failed");
    }
  };

  const toggleSettingHandler = (settingID) => {
    if( !settingID || !settings) return;

    setSettings( prevVal =>  ({
        ...prevVal,
        [settingID]: {
            Description: prevVal[settingID].Description,
            IsActive: !prevVal[settingID].IsActive
        }
    }));
  }

  const getUnregisteredAccountDataHandler = useCallback( async (accountStatusKey) => {
    if(!accountStatusKey) return;

    setIsLoading(true);
    const {success, data} = await getUnregisteredAccountData(accountStatusKey);
    setIsLoading(false);

    if(success) {
      setUserType(data.UserType);

      reset({
        email: data.Email,
        firstName: data.FirstName,
        lastName: data.Lastname
      });
    } else {
      ErrorPopup();
    }
  }, [reset, setUserType]);

  useEffect( () => {
    if (!userType && !accountStatusKey) {
      history.replace('/signup');
    } else if (accountStatusKey && !userType) {
      getUnregisteredAccountDataHandler(accountStatusKey);
    } else {
      getSettingsHandler(userType);
    }
  }, [userType, history, accountStatusKey, getUnregisteredAccountDataHandler])

  return (
    <>
      <div className="custom_bg d-flex align-items-center justify-content-center">
        <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">

          <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center position-relative" >

            <Row className="position-absolute w-100" style={{zIndex: 1000}}>
              <Backdrop show={isLoading}>
                <LogoLoader />
              </Backdrop>
            </Row>

            <Col className="m-0 p-3 py-3 py-sm-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12} sm={10} md={8} lg={7}>

              <Row className="m-0 p-0 mb-4">
                <Col className="m-0 p-0" xs={12}>
                { userType && (
                  <h3 className="m-0 p-0 fw-bold text-center">
                    Create a { userType === 'Manager' ? 'property manager' : ( userType.toLowerCase() || 'WISSP' ) } account
                  </h3>
                )}
                </Col>
              </Row>

              <Row className="m-0 p-0">
                <Col className="m-0 p-0" xs={12}>
                  <Form onSubmit={handleSubmit(signup)}>
                    <Row className="m-0 p-0">

                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="First Name"
                            label="First Name"
                            className="mb-3"
                        >
                          <Form.Control 
                            type="text" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("firstName")} 
                          />
                          <p className="custom_error">
                              {errors.firstName?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Last Name"
                            label="Last Name"
                            className="mb-3"
                        >
                          <Form.Control 
                            type="text" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("lastName")} 
                          />
                          <p className="custom_error">
                              {errors.lastName?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Email Address"
                            label="Email Address"
                            className="mb-3"
                        >
                          <Form.Control 
                            type="email" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("email")} 
                          />
                          <p className="custom_error">
                              {errors.email?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Password"
                            label="Password"
                            className="mb-3"
                        >
                          <Form.Control 
                            type="password" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("password")} 
                          />
                          <p className="custom_error">
                              {errors.password?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Col className="m-0 p-0" xs={12}>
                        <FloatingLabel
                            controlId="Confirm Password"
                            label="Confirm Password"
                        >
                          <Form.Control 
                            type="password" 
                            placeholder="0"
                            className="rounded shadow-sm border-0"
                            {...register("passwordConfirmation")} 
                          />
                          <p className="custom_error">
                              {errors.passwordConfirmation?.message}
                          </p>
                        </FloatingLabel>
                      </Col>

                      <Row className="m-0 p-0 mt-4 justify-content-between"> 
                        <Col className="m-0 p-0 pe-1" xs={6} >
                          <ButtonSecondary title="Back" onClick={goBackHandler}/>
                        </Col>
          
                        <Col className="m-0 p-0 ps-1" xs={6}>
                          <ButtonPrimary title="Sign up"/>
                        </Col>
                      </Row>

                        {/* <Col className="m-0 p-0 mt-4" xs={12}>
                          <ButtonPrimary title="Sign up"/>
                        </Col> */}
                    
                    </Row>
                  </Form>
                </Col>
              </Row>

              <Row className="m-0 p-0 mt-3">
              { settings && Object.entries(settings).map(([settingID, { Description, IsActive }]) => (
                  <Form.Check
                      key={`accessiblity ${settingID}`}
                      id={`accessiblity ${settingID}`}
                      value={settingID}
                      type="checkbox"
                      label={Description || '-'}
                      htmlFor={`notification_setting_${settingID}`}
                      onChange={() => toggleSettingHandler(settingID)}
                      checked={ IsActive }
                  />
              ))}
              </Row>

            </Col>
          </Row>

      </Container>
      </div>
      <Footer />
    </>
  );
};
