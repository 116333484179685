import React, {useEffect} from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString, number as YupNumber } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import BasicFields from '../UnitFormElements/BasicsFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import { NAME, SQ_FOOTAGE, BEDROOM_COUNT, BATHROOM_COUNT, FLOOR_ID } from '../../constants/unitFormConstants';

const schema = YupObject().shape({
    [NAME]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [SQ_FOOTAGE]: YupNumber()
        .required("Required")
        .transform(value => (isNaN(value) ? undefined: value))
        .min(0, 'Value can\'t be less than 0')
        .max(99999, 'Value can\'t be greater than 99999')
        .test(
            "maxDigitsAfterDecimal",
            "Field must have 2 digits after decimal or less",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
    [BEDROOM_COUNT]: YupNumber()
        .transform(value => (isNaN(value) ? undefined: value))
        .min(0, 'Value can\'t be less than 0')
        .max(5, 'Value can\'t be greater than 5')
        .required('Required'),
    [BATHROOM_COUNT]: YupNumber()
        .transform(value => (isNaN(value) ? undefined: value))
        .min(0, 'Value can\'t be less than 0')
        .max(5, 'Value can\'t be greater than 5')
        .required('Required'),
    [FLOOR_ID]: YupNumber()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Value can\'t be less than 0')
        .notRequired()
});


export default function UpdateUnitBasic({
    sectionFocus, 
    setSectionFocus, 
    unit, 
    onSubmitBasics
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, setValue, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        if (unit) {
            setValue(NAME, unit.Name);
            setValue(SQ_FOOTAGE, unit.SquareFootage || 0);
            setValue(BEDROOM_COUNT, unit.BedroomCount);
            setValue(BATHROOM_COUNT, unit.BathroomCount);
            setValue(FLOOR_ID, unit.FloorID)
        }

    }, [unit, setValue]);

    return (
        <Form data={"Unit Basics"}
            onSubmit={handleSubmit(onSubmitBasics)}
            className={sectionFocus && sectionFocus !== "Unit Basics" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus("Unit Basics") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >   
            <BasicFields 
                b_editMode={false}
                f_register={register}
                o_errors={errors}
                s_registerNameForUnitName={NAME}
                s_registerNameForFootage={SQ_FOOTAGE}
                s_registerNameForBedroomCount={BEDROOM_COUNT}
                s_registerNameForBathRoomCount={BATHROOM_COUNT}
                s_registerNameForFloorID={FLOOR_ID}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        title="Update Unit Basics"
                    />
                </Col>
            </Row>
        </Form>
    )
}
