import React, { useState, useEffect, useMemo, useContext } from 'react'

import {
    Modal,
    Container,
    Row,
    Col,
    Tab,
    Form,
    Spinner,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';

import SuccessPopup from '../Shared/InfoPopups/SuccessPopup';
import ErrorPopup from '../Shared/InfoPopups/ErrorPopup';

import BasicInfo from './BasicInfo';
import TimeRangePicker from '../Shared/TimeRangePicker';
import { respondToTicket } from '../../services/tickets';

import { BuildingContext } from '../../contexts/BuildingContext'
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function ResponseModal({
    show, 
    closeHandler, 
    unitID, 
    selectedReq, 
    ticketID, 
    token, 
    setMessages,
    unitInfo,
    managerPhoneNumber,
    setTicketResponse,
    // disableTicket
}) {
    
    // activeTabs: select, schedule Tour, sendMessage
    const [ activeTab, setActiveTab ] = useState("select");
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(()=>{
        return () => {
            setSelectedOption(null);
            setSelectedDate(null);
            setActiveTab('select')
        }
    },[show])

    const tabSelectionHandler = (tabName) => {
        if( tabName === 'decline' || tabName === 'scheduleTour') setSelectedOption(null);
        setActiveTab(tabName);
    }

    const sendMessageHandler = async (message) => {
        setIsLoading(true);

        const preparedData = {
            Text: message,
            Type: "Accepted"
        }

        if( activeTab === 'declineMessage' ) {
            preparedData.Type = "Declined";
        }

        const response = await respondToTicket(token, ticketID, preparedData);

        if (response.success) {
            SuccessPopup("Success! Message has been sent.");

            setTicketResponse(ticketID, response.data);
            closeHandler();
            tabSelectionHandler('');
            setSelectedOption(null);
            setActiveTab('select');
        } else {
            ErrorPopup("Error! Could not send message. Try again later.");
        }

        setIsLoading(false);
    }

    return (
        <Modal
            show={show} 
            onHide={closeHandler} 
            size="xl" 
            aria-labelledby="contained-modal-title-vcenter" 
            // backdrop={false} 
            centered 
        >
            <Modal.Header closeButton>
                {/* <Modal.Title className="w-100 text-center">Response</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Container className="m-0 p-0 px-0 px-sm-4 position-relative w-100">
                
                <BasicInfo 
                    unitID={unitID} 
                    selectedReq={selectedReq} 
                    UnitInfo={unitInfo}
                    classNameContainer="mt-3"
                />

                <DecisionSelection 
                    btn1Title="Schedule Tour"
                    btn2Title="Decline"
                    clickHandlerBtn1={() => tabSelectionHandler('scheduleTour')}
                    clickHandlerBtn2={() => tabSelectionHandler('decline')}
                />

                <Tab.Container activeKey={activeTab}>
                    <Tab.Content>
                        <Tab.Pane eventKey="scheduleTour">
                            <OptionsPicker 
                                selectedOption={selectedOption} 
                                selectedOptionHandler={setSelectedOption}
                                nextSection={() => tabSelectionHandler('scheduleMessage')}

                                noneOption={true}
                                optionsArray={selectedReq && selectedReq.availableDates}
                                title={selectedReq && selectedReq.availableDates ? "Please select from the dates provided:" : ""}
                                noneBtnName={selectedReq && selectedReq.availableDates ? "None of the above" : "Write message" } 
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="decline">
                            <OptionsPicker 
                                selectedOption={selectedOption}
                                selectedOptionHandler={setSelectedOption}
                                nextSection={() => tabSelectionHandler('declineMessage')}

                                optionsArray={["Unit is filled.", "Unavailable to provide tours.", "Tenant is not qualified."]}
                                title="Please select reason(s) for declining:"
                            />
                        </Tab.Pane>

                        {/* MESSAGES PANEs */}
                        <Tab.Pane eventKey="scheduleMessage">
                            {/* <ScheduleMessage /> */}

                            {(selectedOption === "none" || !selectedOption) ? (
                                <BasicMessageGenerator 
                                    title="Add any additional details if neccessary:"
                                    customMessage=""
                                    requestData={selectedReq}
                                    sendMessageHandler={sendMessageHandler}
                                    isInput={true}
                                    inputMinRows={6}
                                    unitInfo={unitInfo}
                                    // availableOn={selectedOption}
                                />
                            ) : (
                                <BasicMessageGenerator 
                                    title="Add any additional details, including times that are available:"
                                    messageType="scheduleMessage"
                                    requestData={selectedReq}
                                    UnitID={unitID}
                                    availableOn={selectedOption}
                                    sendMessageHandler={sendMessageHandler}
                                    managerPhoneNumber={managerPhoneNumber}
                                    unitInfo={unitInfo}
                                />
                            )}

                        </Tab.Pane>

                        <Tab.Pane eventKey="declineMessage">
                           {(selectedOption === "Tenant is not qualified.") && <MessageGenerator sendMessageHandler={sendMessageHandler}/>}

                           {(selectedOption === "Unavailable to provide tours.") && 
                                <BasicMessageGenerator 
                                    title="Add any additional details, including times that are available:"
                                    messageType="unavailableMessage"
                                    requestData={selectedReq}
                                    sendMessageHandler={sendMessageHandler}
                                    isInput={false}
                                    inputMinRows={10}
                                    managerPhoneNumber={managerPhoneNumber}
                                    unitInfo={unitInfo}
                                />
                            }

                            {(selectedOption === "Unit is filled.") && 
                                <BasicMessageGenerator 
                                    title="Add any additional details if neccessary:"
                                    customMessage="I am really sorry, but this unit is already filled."
                                    requestData={selectedReq}
                                    sendMessageHandler={sendMessageHandler}
                                    isInput={true}
                                    inputMinRows={6}
                                    unitInfo={unitInfo}
                                />
                            }

                            {(selectedOption === "none") && 
                                <BasicMessageGenerator 
                                    title="Add any additional details if neccessary:"
                                    customMessage="I am really sorry, but this unit is unavailable."
                                    requestData={selectedReq}
                                    sendMessageHandler={sendMessageHandler}
                                    isInput={true}
                                    inputMinRows={6}
                                    unitInfo={unitInfo}
                                />
                            }

                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>

            </Container>
            </Modal.Body>
            { isLoading && (
                <Row className="position-absolute w-100 h-100 m-0 p-0 d-flex justify-content-center align-items-center" style={{background: 'rgba(0,0,0,0.1)'}}>
                    <Spinner animation="border" role="status"/>
                </Row> 
                )
            }

        </Modal>
    )
}

function DecisionSelection({clickHandlerBtn1, clickHandlerBtn2, btn1Title, btn2Title}) {
    const [ selectedBtn, setSelectedBtn ] = useState(null);

    const clickHandler = (btnName, callback) => {
        setSelectedBtn(btnName);
        callback();
    }

    return (
        <Container className="m-0 mb-3 p-0">
            <Row className="m-0 p-0 justify-content-center">
                <Col xs={12} md={4} xl={5} className="m-0 mb-2 mb-md-0 me-0 me-md-2 p-0 d-flex justify-content-center justify-content-md-end">
                    <ButtonPrimary 
                        title={btn1Title}
                        onClick={() => clickHandler(btn1Title, clickHandlerBtn1)}
                        additionalClassName={(selectedBtn !== btn1Title) ? 'veryDarkGray border-secondary' : ''}
                    />
                </Col>
                <Col xs={12} md={4} xl={5} className="m-0 p-0 d-flex justify-content-center justify-content-md-start">
                    <ButtonPrimary 
                        title={btn2Title}
                        onClick={() => clickHandler(btn2Title, clickHandlerBtn2)}
                        additionalClassName={(selectedBtn !== btn2Title) ? 'veryDarkGray border-secondary' : ''}
                    />
                </Col>
            </Row>
        </Container>
    )
}

function OptionsPicker({selectedOption, selectedOptionHandler, nextSection, optionsArray, title, noneOption=true, noneBtnName="None of the above"}) {
    const selectionStyles = {
        selected: "bg-success text-white",
        notSelected: "darkerGray text-dark",
    }

    const nextSectionHandler = () => {
        if(!selectedOption) return;
        nextSection();
    }

    return (
        <Container className="m-0 p-0 montserrat">
            <Row className="m-0 p-0 d-flex flex-column  align-items-center text-center">
                <h5 className="m-0 mb-3 p-0 fw-bold">{title}</h5>

                { optionsArray && optionsArray.map( (option, index) => (
                    <Col xs={12} sm={8} lg={5} xl={4} key={index} className="m-0 mb-3 p-0">
                        <Row 
                            className={`m-0 p-0 w-100 p-2 justify-content-center cursor-pointer rounded ${selectedOption === option ? selectionStyles.selected : selectionStyles.notSelected}`}
                            // style={{cursor: 'pointer', width: '350px'}}
                            onClick={() => selectedOptionHandler(option)}
                        >
                            <Col className="m-0 p-0">
                                <h6 className="m-0 p-0 fw-bold">{option}</h6>
                            </Col>
                        </Row>
                    </Col>
                ))}
                
                {/* NONE BUTTON */}
                {   noneOption &&
                    <Col xs={12} sm={8} lg={5} xl={4} className="m-0 p-0">
                        <Row 
                            className={`m-0 p-0 w-100 p-2 justify-content-center cursor-pointer rounded ${selectedOption === 'none' ? selectionStyles.selected : selectionStyles.notSelected}`}
                            // style={{cursor: 'pointer', width: '350px'}}
                            onClick={() => selectedOptionHandler('none')}
                        >
                            <Col className="m-0 p-0">
                                <h6 className="m-0 p-0 fw-bold">{noneBtnName}</h6>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
            <Row className="m-0 my-5 p-0 justify-content-center">
                <Col className="m-0 p-0" xs={12} sm={8} lg={5} xl={4}>
                    <ButtonPrimary 
                        title="Next"
                        onClick={nextSectionHandler}
                        disabled={!selectedOption}
                    />
                </Col>
            </Row>
        </Container>
    )
}

function MessageGenerator({isSendMessage, sendMessageHandler}) {
    // TODO: Display warning message if user didn't picked any option!

    const messagesOptions = useMemo(() => ({
        "Income is too low/no guarantor": "Your income is too low",
        "Lack of rental history": "Your rental history is not complete.",
        "Problematic eviction history": "Previous eviction history is problematic",
        "History of poor rent payment": "History of poor rent payment",
        "Problematic criminal history": "Criminal history is problematic",
        "Provided false information": "You provided false information",
        "Tenant is a smoker": "You are smoker",
        "Low credit score": "Your credit score is low",
        "Tenant has a pet": "Pets are not allowed",
        "Previous bankruptcies": "Previous bankruptcies",
        "History of damaging property": "You have history of damaging property",
        "Negative reference history": "You have negative reference history"
    }), []);

    const [shareContact, setShareContact] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [showTimePicker, setShowTimePicker] = useState(false);

    const messageTemplate = `Unfortunately, you are not qualified for this apartment because:`;

    const [isValid, setIsValid] = useState(true);
    const [message, setMessage] = useState(messageTemplate);


    const checkHandler = (e, messageOption) => {
        const checked = e.target.checked;
        if (checked) {
            setIsValid(true);
            setSelectedOptions( prevValue => [...prevValue, messageOption])
        } else {
            // delete messageOption from array
            setSelectedOptions( prevValue => prevValue.filter( val => val !== messageOption));
        }
    }

    const onClickSendBtnHandler = () => {
        if (selectedOptions.length === 0) {
            setIsValid(false);

            return;
        }
        sendMessageHandler(message);
    }

    useEffect(() => {
        const getSelectedMessages = selectedOptions.map( selectedOption => messagesOptions[selectedOption]);
        const generateMessage = `Hello,\n\n${messageTemplate}\n${'-' + getSelectedMessages.join('\n-')}\n\nI am soryy for the inconvenience and wish you luck.\n\nThank you!`;
        
        setMessage(generateMessage);

    }, [messagesOptions, messageTemplate, selectedOptions])

    return (
        <>
            <Container className="m-0 my-5 p-0 montserrat">
                <Row className="m-0 p-0">
                    <h6 className="m-0 mb-4 p-0 fw-bold">Please select why tenant is unqualified and customize the message belwo:</h6>
                </Row>

                <Row className="m-0 p-0">
                {
                    Object.keys(messagesOptions).map( messageOption => (
                        <Col className="m-0 mb-3 p-0 " xs={12} lg={6} key={messageOption}>
                            <label className="checkboxContainer fs-6">{messageOption}
                                <input type="checkbox" name="isCurrentPosition" onChange={(e) => checkHandler(e, messageOption)}/>
                                <span className="customCheckmark"></span>
                            </label>
                        </Col>
                    ))
                }
                </Row>

                <Row className="m-0 mt-3 p-0">
        
                    <Col className="border border-2 m-0 p-4 pt-3 rounded">
                        <p className="m-0 p-0 fs-6">Hello,</p>
                        <br/>
                        <p className="m-0 p-0 fs-6">Unfortunately, you are not qualified for this apartment because:</p>

                        {
                            selectedOptions.map( selectedOption => (
                                <p className="m-0 p-0 fs-6" key={selectedOption}>- {messagesOptions[selectedOption]}</p>
                            ))
                        }
                        <br/>
                        <p className="m-0 p-0 fs-6">I am sorry for the inconvenience and wish you luck.</p>
                        <br/>
                        <p className="m-0 p-0 fs-6">Thank you!</p>

                    </Col>

                    {/* <Form.Control 
                        // type="mrkdwn"
                        rows={8}
                        className="p-2 px-4 fs-4"
                        // style={{fontSize: '1.5rem'}}
                        as="textarea"  
                        value={message}   
                    /> */}

                </Row>

                { isSendMessage &&
                    <Row className="m-0 mt-3 p-0">
                        <Col className="m-0 p-0 " xs={12}>
                            <label className="checkboxContainer fs-4">Share contact number with applicant.
                                <input type="checkbox" name="isCurrentPosition" onChange={checkHandler}/>
                                <span className="customCheckmark"></span>
                            </label>
                        </Col>
                    </Row>
                }
                
                {/* {   selectedOptions.length === 0 && */}
                {    !isValid &&
                    <Row className="m-0 mt-3 p-0">
                        <Col className="m-0 p-0">
                            <h6 className="text-center text-danger fw-bold">Select at least one option!</h6>
                        </Col>
                    </Row>
                }

                <Row className="m-0 mt-5 p-0 justify-content-end">
                    <Col className="m-0 p-0" xs={"auto"}>
                        <ButtonPrimary 
                            title="Send Message"
                            onClick={onClickSendBtnHandler}
                        />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

function BasicMessageGenerator({
    availableOn="",
    isInput=false, 
    title, 
    messageType, 
    requestData,
    customMessage="",
    UnitID,
    sendMessageHandler,
    inputMinRows=8,
    unitInfo
}) {
    const { buildingInfo } = useContext(BuildingContext);

    const [managerPhoneNumber, setManagerPhoneNumber] = useState(null);
    const [shareContact, setShareContact] = useState(false);
    const [canTenantApply, setCanTenantApply] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [inputTimes, setInputTimes] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');

        let userDetails;
        if(buildingInfo && buildingInfo.Managers && userEmail) {
            const managers = buildingInfo.Managers;
            const searchedManagerData = managers.find( manager => manager.Email === userEmail);
            userDetails = searchedManagerData;
        }
        
        if(userDetails && userDetails.Phone) {
            setManagerPhoneNumber(userDetails.Phone);
        } else {
            setManagerPhoneNumber(null);
        }
    }, [ buildingInfo ])

    //TODO: check contactMessage
    const contactMessage = `If you need to reach me, my contact number is: ${managerPhoneNumber}`;
    
    const [message, setMessage] = useState("");
    useEffect(() => {
        const scheduleMessage = `Hello,\n\nI am available on ${availableOn} for you to tour Unit '${unitInfo && unitInfo.Name}'${ unitInfo && unitInfo.BuildingName ? ` - at ${unitInfo.BuildingName}` : ''}. I am available from ${inputTimes}. ${shareContact ? ('\n\n' + contactMessage + '.\n\n') : ''}Thank you!`;

        const unavailableMessage = `Hello,\n\nUnfurtunately, I am unavailable to provide tours for this unit at this time. However, the unit is still available and if interested you may still apply. ${canTenantApply ? "Please begin by completing the following verification: www.link.com/here." : ""}${shareContact ? `\n\nIf you need to reach me, my contact number is: ${managerPhoneNumber}.` : ""}\n\nThank you!`;

        const defaultMessage = `Hello,\n\n${customMessage + "\n\n"}Thank you!`;

        if (messageType === "scheduleMessage") {
            setMessage(scheduleMessage);
        } else if (messageType === "unavailableMessage") {
            setMessage(unavailableMessage);
        } else {

            if(message && shareContact){
                if(message.search('Thank you!') === -1) {
                    setMessage( prevMessage =>prevMessage + contactMessage);
                } else {
                    setMessage( prevMessage => prevMessage.replace(/\nThank you!/gi, contactMessage + '\n\nThank you!'));
                }
            } else if (message && !shareContact) {
                setMessage( prevMessage => prevMessage.replace(contactMessage, ''));
            } else {
                setMessage(defaultMessage);
            }
        }

    }, [shareContact, canTenantApply, messageType, inputTimes, availableOn, requestData, contactMessage, UnitID, unitInfo]);

    const getTimeRange = (timeRangesObject) => {
        const prepareTimeRange = timeRangesObject.map( timeRange => `${timeRange.start} - ${timeRange.end}`);

        const prepareTimeRangeString = prepareTimeRange.length > 1 ? prepareTimeRange.join('; ') : prepareTimeRange[0];
        setInputTimes(prepareTimeRangeString);
    }

    const onClickSendBtnHandler = () => {
        if(!inputTimes && messageType === "scheduleMessage") {
            setErrorMessage('Provide available time!');
            return;
        }

        setErrorMessage(null);
        sendMessageHandler(message);
    }

    let messageInContainer;

    switch(messageType) {
        case "scheduleMessage":
            messageInContainer = (
                <Col className="border border-2 m-0 p-4 pt-3 rounded">
                    <p className="m-0 p-0 fs-6">Hello,</p>
                    <br/>
                    <p className="m-0 p-0 fs-6">I am available on {availableOn} for you to tour Unit {unitInfo && unitInfo.Name}{(unitInfo && unitInfo.BuildingName) && ` at ${unitInfo.BuildingName}`}.
                    {" "}I am available from <strong style={{cursor: 'pointer'}} onClick={() => setShowTimePicker(true)}>{inputTimes || 'input times here'}.</strong></p>
                    <br/>
                    {shareContact && (<><p className="m-0 p-0 fs-6">{contactMessage}</p><br/></>)}
                    <p className="m-0 p-0 fs-6">Thank you!</p>
                </Col>
            );
            break;
        case "unavailableMessage":
            messageInContainer = (
                <Col className="border border-2 m-0 p-4 pt-3 rounded">
                    <p className="m-0 p-0 fs-6">Hello,</p>
                    <br/>
                    <p className="m-0 p-0 fs-6">Unfurtunately, I am unavailable to provide tours for this unit at this time. However, the unit is still available and if interested you may still apply. {canTenantApply ? 'Please begin by completing the following verification: www.link.com/here.' : ''}</p>
                    <br/>
                    {shareContact && (<><p className="m-0 p-0 fs-6">{contactMessage}</p><br/></>)}
                    <p className="m-0 p-0 fs-6">Thank you!</p>
                </Col>
            );
            break;
        default: 
            messageInContainer= (
                <Col className="border border-4 m-0 p-4 pt-3 rounded">
                    <p className="m-0 p-0 fs-6">Hello,</p>
                    <br/>
                    <p className="m-0 p-0 fs-6">{customMessage}</p>
                    <br/>
                    {shareContact && (<><p className="m-0 p-0 fs-6">{contactMessage}</p><br/></>)}
                    <p className="m-0 p-0 fs-6">Thank you!</p>
                </Col>
            )
            break;
    }

    const renderTooltip = (props) => (
       <Tooltip id="button-tooltip" {...props}>Missing Phone Number</Tooltip>
    );

    return (
        <>
            <TimeRangePicker show={showTimePicker} closeHandler={() => setShowTimePicker(false)} getTimeRange={getTimeRange}/>

            <Container className="m-0 my-3 my-sm-5 p-0 montserrat">
                <Row className="m-0 p-0">
                    <h5 className="m-0 mb-4 p-0 fw-bold">{title}</h5>
                </Row>
                <Row className="m-0 p-0">

                    {   !isInput && messageInContainer  }

                    {   isInput &&
                        <Form.Control 
                            onChange={(e) => setMessage(e.target.value)}
                            rows={inputMinRows}
                            className="p-2 px-4 fs-6 rounded"
                            as="textarea"  
                            value={message}   
                        />
                    }

                </Row>
                <Row className="m-0 my-3 p-0">
                    {/* TODO: disabled - generate link */}
                    {/* {   messageType === "unavailableMessage" &&
                        <Col className="m-0 p-0 " xs={12}>
                            <label className="checkboxContainer fs-6">Tenant can still apply to unit, include link to verification.
                                <input type="checkbox" name="canTenantApply" onChange={ (e) => {
                                    setCanTenantApply(e.target.checked)}}/>
                                <span className="customCheckmark"></span>
                            </label>
                        </Col>
                    } */}

                    {/* {   ((messageType === "unavailableMessage") || (messageType === "scheduleMessage")) && */}
                        <Col className="m-0 mt-4 p-0" xs={12}>

                        {
                            !managerPhoneNumber ? (
                            <OverlayTrigger 
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <label className={`checkboxContainer fs-6 text-secondary`}>Share contact number with applicant.
                                    <input 
                                        disabled={ !managerPhoneNumber }
                                        type="checkbox" 
                                        name="shareContact-disabeld" 
                                      />
                                    <span className="customCheckmark"></span>
                                </label>
                            </OverlayTrigger>
                            ) : (
                                <label className={`checkboxContainer fs-6`}>Share contact number with applicant.
                                    <input 
                                        type="checkbox" 
                                        name="shareContact" 
                                        onChange={ (e) => {
                                            setShareContact(e.target.checked)}}/>
                                    <span className="customCheckmark"></span>
                                </label>
                            )
                        }

                        </Col>
                    {/* } */}

                </Row>
                <Row className="m-0 p-0 justify-content-center justify-content-sm-end align-items-center">
                    { errorMessage && (
                        <Col className="m-0 p-0 pe-3">
                            <p className="m-0 p-0 text-danger text-end" style={{fontSize: '0.85rem'}}>{ errorMessage }</p>
                        </Col>
                    )}

                    <Col className="m-0 p-0" xs={'auto'}>
                        <ButtonPrimary 
                            title="Send Message"
                            onClick={onClickSendBtnHandler}
                        />
                    </Col>
                </Row>

            </Container>
        </>
    )
}
