import React from 'react';
import {Col} from 'react-bootstrap';

export default function InputBtn({
    index, 
    name, 
    onChange, 
    checked, 
    disabled=false,
    labelClass="w-100 p-2 fw-bolder rounded d-flex align-items-center justify-content-center text-center h-100",
    additionalLabelClass="",
    containerClasses="mb-2 ms-2"
}) {
    return (
        <Col className={`darkerGray rounded ${containerClasses} p-0`} key={index} style={{opacity: disabled && !checked ? '0.5' : '1.0'}}>
            <input type="checkbox" 
                name={name} 
                id={`${name}${index}`} 
                className="acc"
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            ></input>
            <label htmlFor={`${name}${index}`} className={`${labelClass} ${additionalLabelClass}`}>{name}</label>
        </Col>
    )
}
