import React from 'react';

export default function Chip({
    icon="bi-person-fill", 
    text, 
    exit=false, 
    onDelete=()=>{}, 
    isSelected=false, 
    onSelect=()=>{},
    canSelect=true,
    disabled 
}) {
    return (
      <div className={`d-flex chip flex-grow-0 justify-content-between align-items-center me-2 my-1 ${disabled ? "chip--disabled" : ""} ${canSelect ? "cursor-pointer" : ""} ${isSelected ? "bg-success text-light" : ""}`}
        onClick={ disabled ? ()=>{} : onSelect}
      >
        <i className={`bi ${icon} me-2`}></i>
        <span>{text}</span>
        { exit && <i className="bi bi-x-circle-fill ms-2 cursor-pointer" onClick={onDelete}></i> }
      </div>
    )
}
