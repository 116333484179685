import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import Logo from '../../assets/logos/logo_primary.svg';

export default function LogoLoader({
    height="100vh"
}) {
    return (
        <Container fluid className="d-flex justify-content-center align-content-center" style={{ height }}>

            <Row className="m-0 p-0 ">

                <Col className="m-0 p-0 d-flex justify-content-center align-content-center flex-column">
                    <Image className="m-0 p-0" 
                        style={{ objectFit: "cover", height: 120, width: "auto" }}
                        src={Logo} 
                        alt="Loader..."></Image>  

                    <div className="loader__logo mx-auto my-0"></div>
                </Col>

            </Row>
        </Container>
    )
}
