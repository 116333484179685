import React from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "react-bootstrap";

import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";
import DecisionModal from "../Shared/InfoPopups/DecisionModal";


export default function PropertiesUnit({
    data,
    deleteHandler,
    openHandler
  }) {
    const openFavorite = () => {
      openHandler();
    }

    const onDelete = async () => {
      const userDecision = await DecisionModal({});

      if ( !userDecision.isConfirmed ) return;
      deleteHandler();
    }

    const getBuildingLowestPrice = (data) => {
      if(!data || !data.Units) return " -";
      if(Array.isArray(data.Units) && !data.Units.length) return " -";
      return data.Units.reduce( (acc, unit) => {
        if( unit.Rent < acc ) acc = unit.Rent;
        return acc;
      }, Number.MAX_VALUE)
    }

    return (
      <Container fluid className="m-0 p-0 mb-3 d-flex justify-content-center shadow-sm">
        <Card className="m-0 p-0 border-0  w-100" style={{ height: "100%" }}>
          <Card.Body className={`m-0 p-0 lightGray rounded overflow-hidden w-100`} style={{ transition: '.3s'}}>
            <Row className="m-0 p-0 w-100">

              <Col className="d-flex position-relative align-items-center justify-content-center m-0 p-0 ps-2 ps-sm-3" xs={'auto'}>
                <i className={`bi ${(data && ( data.type === 'unit' ? 'bi-house-heart' : data.type === 'building' ? 'bi-building' : 'bi-list-stars')) || 'bi-house-heart'} fs-3`}></i> 
               
                { data && data.isVacant === false && (
                    <div className="d-flex align-items-center m-0 p-0 position-absolute border border-3 border-danger rounded-circle" style={{width: '2.5rem', height: '2.5rem'}}>
                      <div className="m-0 p-0 w-100 bg-danger" style={{height: 3, transform: 'rotate(45deg)'}}></div>
                    </div>
                  )
                }
              </Col>

              <Col className="px-2 px-sm-3 m-0 d-flex flex-column justify-content-start ">
                <Row className="p-0 m-0 d-flex align-items-center py-3">
                  
                    <Row className="m-0 p-0">

                      <Col className="m-0 p-0">
                        <h5 className="d-inline p-0 m-0 text-dark cursor-pointer" onClick={ data && data.Vacant && openFavorite}>
                          {data.name}
                        </h5>
                    
                        <p className="m-0 p-0 text-muted" style={{ fontSize: '0.85rem' }}>
                          <span className="text-muted"><i className="bi bi-geo-alt"></i>&nbsp;{data && data.address && data.address.replace('$*|*$', ' ')}</span>
                        </p>

                        <p className="m-0 p-0 fs-6 text-dark">Rent{ data.type === 'unit' ? ': $' + data.Rent :  ' from: $' + getBuildingLowestPrice(data)}</p>
                      </Col>

                    </Row>

                </Row>
              </Col>

              <Col className="d-flex flex-column flex-sm-row justify-content-center align-items-center m-0 p-0 px-2 px-sm-3" xs={'auto'}>

                <ButtonPrimary 
                  onClick={openFavorite}
                  additionalClassName="mb-1 mb-sm-0"
                  disabled={data && data.isVacant === false }
                >
                  <i className="bi bi-arrow-up-right-square d-sm-none"></i><span className="d-none d-sm-block">Open</span>
                </ButtonPrimary>

                <ButtonPrimary 
                  onClick={onDelete}
                  additionalClassName="ms-sm-1"
                  variant="danger"
                > 
                  <i className="bi bi-x-lg text-white"></i>
                </ButtonPrimary>

              </Col>

        
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
