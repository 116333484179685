import React from "react";
import { Accordion, Card, Row, Col } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";

function CustomHeader({ children, eventKey, className = '', ...props }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    // console.log(`Event Key: ${eventKey}`)
    console.log()
  );

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className={`darkerGray p-1 text-center m-0 p-0 rounded w-100 h-100 border-0 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}

export default function SectionAccordion({
  title,
  children,
  eventKey = 0,
  className = "",
  icon = "",
  isOpen = "false",
}) {
  return (
    <Card className={`${className} position-relative`}>
      <Card.Header
        style={{ background: "transparent", border: "", outline: "0", borderBottom: isOpen?"6px solid black":"0px solid transparent", transition: '100ms'}}
        className="p-0 rounded-3 border-primary"
        // style={{
        //   borderBottom: "10px solid black"
        // }}
      >
        <CustomHeader eventKey={eventKey} className={isOpen?'lightGray':''}>
          <Row className="">
            <Col
              className="d-flex justify-content-center align-items-center"
              xs="1"
            >
              <i className={`bi ${icon} mx-2 fs-4  ${isOpen?'text-primary':'text-dark'}`}></i>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <h5 className={`fw-bold m-0 p-0 ${isOpen?'text-primary':''}`}>{title}</h5>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              xs="1"
            >
              <i
                className={`bi bi-chevron-down mx-2 fs-5 text-dark rotate180 ${
                  isOpen ? "rotate" : ""
                }`}
              ></i>
            </Col>
          </Row>
        </CustomHeader>
      </Card.Header>
      <div>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="my-3">{children}</Card.Body>
        </Accordion.Collapse>
      </div>
    </Card>
  );
}
// export default function SectionAccordion({
//   title,
//   children,
//   eventKey = 0,
//   clickHandler,
//   activeId
// }) {
//   return (
//     <div
//       className={activeId == "0" ? "panel-wrap active-panel" : "panel-wrap"}
//     >
//       <div className="panel-header">
//         <Accordion.Toggle
//           onClick={() => clickHandler(eventKey)}
//           className="panel-toggle darkerGray p-2 text-center m-0 p-0 rounded"
//           variant="link"
//           eventKey={eventKey}
//         >
//           <h5 className="fw-bold m-0 p-0">{title}</h5>
//         </Accordion.Toggle>
//       </div>
//       <div className="">
//         <Accordion.Collapse eventKey={eventKey}>{children}</Accordion.Collapse>
//       </div>
//     </div>
//   );
// }
