import React, { useState } from 'react';
import { Form, FloatingLabel, Container, Row, Col } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import schema from '../schemas/resetPasswordSchema';
import { resetPassword } from '../services/user';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import Footer from '../components/Shared/Footer';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function PasswordReset() {
    const history = useHistory();
    let query = useQuery();
    const resetCode = query.get("reset-token");
    const [buttonClicked, setButtonClicked] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
    });
    
    const onSubmit = async (formData) => {
        if(buttonClicked) return;
        setButtonClicked( true );

        const body = {
            ResetCode: resetCode,
            NewPassword: formData.newPassword
        }

        const response = await resetPassword(body);
        const { success, data } = response;

        if(success) {
            if(data === "reset code is not valid") {
                ErrorPopup("Error! This password reset token is invalid");
            } else {
                SuccessPopup("Success! Your password has been successfully changed.");
            }
            history.replace('/login');
            return;
        } else {
            ErrorPopup();
            setButtonClicked( false );
        }
    };

    return (
        <>
            <div className="custom_bg d-flex align-items-center justify-content-center">
                <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
                    <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
                        <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12} sm={10} md={8} lg={7}>
                            <Row className="m-0 p-0 mb-4">
                                <h3 className="m-0 p-0 fw-bold text-center">Reset Password</h3>
                            </Row>

                            <Row className="m-0 p-0" xs={12}>

                            <Form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0 w-100">   
                                <Row className="m-0 p-0">

                                        <Col className="m-0 p-0 mb-3" xs={12}>
                                            <FloatingLabel
                                                controlId="newPassword"
                                                label="New Password"
                                            >
                                                <Form.Control 
                                                type="password" 
                                                placeholder="0"
                                                className="rounded shadow-sm border-0"
                                                {...register("newPassword")} 
                                                />
                                                <p className="custom_error">
                                                    {errors.newPassword?.message}
                                                </p>
                                            </FloatingLabel>
                                        </Col>

                                        <Col className="m-0 p-0" xs={12}>
                                            <FloatingLabel
                                                controlId="confirmPassword"
                                                label="Confirm Password"
                                            >
                                                <Form.Control 
                                                type="password" 
                                                placeholder="0"
                                                className="rounded shadow-sm border-0"
                                                {...register("passwordConfirmation")} 
                                                />
                                                <p className="custom_error">
                                                    {errors.passwordConfirmation?.message}
                                                </p>
                                            </FloatingLabel>
                                        </Col>

                                        <Col className="m-0 p-0 mt-4" xs={12}>
                                            <ButtonPrimary title="Confirm"/>
                                        </Col>

                                    </Row>
                                </Form>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
