import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Form, Row, Col, Button, FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import CategoryItem from './CategoryItem';
import Bar from '../Shared/CaseManager/Bar';
import Chip from './Chip';
import TextAreaFloating from "../Shared/Inputs/TextAreaFloating";
import InputFloating from '../Shared/Inputs/InputFloating';
import SelectFloating from '../Shared/Inputs/SelectFloating';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import BluredSection from '../Shared/BluredSection';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import Toggle from '../Shared/Inputs/Toggle';

import DateInput from '../Shared/Inputs/DateInput';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../../constants/client';

export default function Notification({ 
    id,
    name,
    description,
    contentType,
    notificationText,
    notificationTitle,
    sendVia,
    recipientsIDs,
    scheduling,
    addToRecipients,
    // selectContentType,
    deleteRecipient,
    // selectSendVia,
    // setNotificationText,
    enableSchedulingBtn,
    selectSchedulingWeekDay,
    selectRepeat,
    setSchedulingDate,
    deleteBtnHandler,
    editBtnHandler,
    index,
    isDragging,
    editScheduling,
    isNew,

    saveNewNotification,
    cancelAddNewNotification,
    onClickTimePicker,
    sendNotificationImmediately,
    changeHandler,
    sendNowBtnHandler,
    onSaveContentType,
    onCancelContentType,

    onCancelScheduling,
    onSaveScheduling,
    saveNewNotificationAndSaveNow
}) {
    const defaultNotificationFormValues = useSelector(store => store.caseManagerStore.notificationFormDefaultValues);
    const groups = useSelector(store => store.caseManagerStore.groups);
    const dropSection = useRef(null);

    const [selectedGroup, setSelectedGroup] = useState('');

    const [isEditingScheduling, setIsEditingScheduling] = useState(false);
    const [isEditingContent, setIsEditingContent] = useState(false);

    const [sectionFocus, setSectionFocus] = useState(null);

    const [wasSaveButtonClicked, setWasSaveButtonClicked] = useState(false);

    const [wasSaveClicked, setWasSaveClicked] = useState(false);

    const [errorMessages, setErrorMessages] = useState({
        startDate: "",
        endDate: "",
        repeatEvery: "",
        hour: "",
        days: ""
    });

    const onEditContentHandler = () => {
        setIsEditingContent(true);
        setSectionFocus('content');
    }

    const onEditSchedulingHandler = () => {
        setIsEditingScheduling(true);
        setSectionFocus('scheduling');
    }

    const validateDateFields = (startDate, endDate) => {
        let startDateErrorMessage = "";
        let endDateErrorMessage = "";

        if(!startDate) {startDateErrorMessage = "Start Date field is required."};
        if(!endDate) {endDateErrorMessage = "End Date field is required."};
        if(endDate && startDate && new Date(endDate).getTime() < new Date(startDate).getTime()) {
            endDateErrorMessage = "End Date cannot be less than Start Date.";
        }
        setErrorMessages( prevErrors => ({...prevErrors, startDate: startDateErrorMessage, endDate: endDateErrorMessage}));
        return !!startDateErrorMessage || !!endDateErrorMessage;
    }
    const validateRepeatEvery = (repeatEvery) => {
        let repeatsErrorMessage = "";
        if(!repeatEvery) repeatsErrorMessage = "Repeats field is required.";
        setErrorMessages( prevErrors => ({...prevErrors, repeatEvery: repeatsErrorMessage}));
        return !!repeatsErrorMessage;
    }
    const validateHour = (hour) => {
        let hourErrorMessage = "";
        if(!hour) hourErrorMessage = "Select notification time.";
        setErrorMessages( prevErrors => ({...prevErrors, hour: hourErrorMessage}));
        return !!hourErrorMessage;
    }
    const validateDays = (days) => {
        let daysErrorMessage = "";
        if(Array.isArray(days) && !days.length) daysErrorMessage = "Select notification days.";
        setErrorMessages( prevErrors => ({...prevErrors, days: daysErrorMessage}));
        return !!daysErrorMessage;
    }

    useEffect(()=>{
        // console.log("[Errors] enabled: ", scheduling.enabled);
        // console.log("[Errors] wasSaveClicked: ", wasSaveClicked);

        if(wasSaveClicked && scheduling.enabled) {
            console.log('[Errors] inside if');
            validateDateFields(scheduling.startDate, scheduling.endDate);
            validateRepeatEvery(scheduling.repeatEvery);
            validateHour(scheduling.hour);
            validateDays(scheduling.days);

            
        }else {
            console.log('[Errors] NOT inside if');
        }
    }, [wasSaveClicked, scheduling])

    // console.log('[Errors]: ', errorMessages);

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: "Group",
        drop: (item) => addToRecipients(id, item.id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }));

    const saveNewNotificationHandler = (type, id) => {
        if(!sendVia || 
           !contentType ||
           (Array.isArray(recipientsIDs) && !recipientsIDs.length) ||

           validateDateFields(scheduling.startDate, scheduling.endDate) ||
           validateRepeatEvery(scheduling.repeatEvery) ||
           validateHour(scheduling.hour) ||
           validateDays(scheduling.days)
        ) {
            setWasSaveClicked(true);
            return;
        };

        if(type === "save") {
            saveNewNotification(id, false);
        } else if(type === "saveAndSend"){
            saveNewNotificationAndSaveNow(id); 
        }
        setWasSaveClicked(false);
    }

    const addToRecipientsMobile = (selectedGroup, id) => {
        if(!id || !selectedGroup) return;

        addToRecipients(id, Number(selectedGroup));

        setSelectedGroup('');
    }

    const getGroupName = (id, groups) => {
        if(!groups && !id) return '';

        return groups.find( group => group[GROUP_ID] === id)?.[GROUP_NAME] || '';
    }

    useEffect(() => {
        if(canDrop && dropSection.current) {
            dropSection.current.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }, [canDrop, dropSection]);

    const getDayName = (date, locale='en-US') => {
        return date.toLocaleDateString(locale, { weekday: 'long' });        
    }

    const monthlyDateSettings = useMemo(() => {
        const result = {
            dayOfTheMonth: null,
            dayOfTheMonthName: null,
            weekDay: null,
            weekDayName: null,
            monthDay: null
        };

        if(!scheduling || (scheduling && !scheduling.startDate)) return result;

        const ordinals = Object.freeze({
            1: 'first',
            2: 'second',
            3: 'third',
            4: 'fourth',
            5: 'fifth',
        })

        const selectedStartDate = new Date(scheduling.startDate);
       
        const dayOfTheMonth = Math.ceil(selectedStartDate.getDate() / 7);
        const weekDay = selectedStartDate.getDay();
        const monthDay = selectedStartDate.getDate();

        return {
            dayOfTheMonth,
            dayOfTheMonthName: ordinals[dayOfTheMonth],
            weekDay,
            weekDayName: getDayName(selectedStartDate),
            monthDay
        }
    }, [scheduling]);

    const getformattedTime = (hour) => {
        if(!hour) return '--:-- --';
        const timeString = `${hour}:00`;
        return moment(timeString, 'HH:mm').format('h:mm A');
    }

    const getFormattedDayNames = (a_daysNum) => {
        if(!a_daysNum) return;

        const dayShortNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return [...a_daysNum].sort().map( dayNum => dayShortNames[dayNum]).join(', ');
    }

    const onCancelSchedulingHandler = (id) => {
        setIsEditingScheduling(false);
        onCancelScheduling(id);
        setSectionFocus(null);
    }

    const onSaveSchedulingHandler = async (id) => {
        if(
            validateDateFields(scheduling.startDate, scheduling.endDate) ||
            validateRepeatEvery(scheduling.repeatEvery) ||
            validateHour(scheduling.hour) ||
            validateDays(scheduling.days)
         ) {
            setWasSaveClicked(true);
            return;
         };

        const {success} = await onSaveScheduling(id);

        if(success){
            setIsEditingScheduling(false);
            setWasSaveClicked(false);
            setSectionFocus(null);
        }
    }

    const onCancelContentTypeHandler = (id) => {
        setIsEditingContent(false);
        onCancelContentType(id);
        setSectionFocus(null);
    }

    const onSaveContentTypeHandler = async (id) => {
        setWasSaveButtonClicked(true);

        if(!notificationText || !notificationTitle) return;

        const {success} = await onSaveContentType(id);

        if(success){
            setIsEditingContent(false);
            setSectionFocus(null);
            setWasSaveButtonClicked(false);
        }
    }


    return (
        <CategoryItem 
            index={index}
            title="Notification" 
            name={name} 
            description={description} 
            style={{transform: isOver && canDrop ? "scale(0.97)" : "scale(1)"}}
            deleteBtnHandler={(e)=>deleteBtnHandler(e, id)}
            editBtnHandler={(e)=>editBtnHandler(e, id)}
        >

            <Row className="p-1 m-0 position-relative">
                <BluredSection show={canDrop || (sectionFocus !== null && sectionFocus !== "content")}/>
                <Bar>
                    <Row className="m-0 p-0 align-items-center">
                        {!isNew && (
                            <Col className="p-0" xs="auto">
                                <ButtonPrimary type="button" className="opacity-0" disabled><i className="bi bi-pencil-fill"></i></ButtonPrimary>
                            </Col>
                        )}
                        <Col className="p-0">
                            <h5 className="fw-bold m-0 fs-6 fs-lg-5">Content</h5>
                        </Col>
                        {!isNew && (
                            <Col className="p-0" xs="auto">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100 }}
                                    overlay={(
                                        <Tooltip>
                                            Edit
                                        </Tooltip>
                                    )}
                                >
                                    <div>
                                        <ButtonPrimary disabled={isEditingContent} type="button" onClick={onEditContentHandler}><i className="bi bi-pencil-fill"></i></ButtonPrimary>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        )}
                    </Row>
                </Bar>

                { !isNew && !isEditingContent && scheduling && (
                    <div className="mb-4 px-0 px-md-3">
                        {/* <Row className="m-0">
                            <Col className="p-0"><p className="fs-6 mb-0 text-capitalize"><b>Type:</b> {contentType}</p></Col>
                        </Row> */}

                        { contentType === 'text' && (
                            <>
                                { notificationTitle && (
                                    <Row className="m-0">
                                        <Col className="p-0"><p className="fs-6 mb-0"><b>Title:</b> {notificationTitle}</p></Col>
                                    </Row>
                                )}
                                
                                { notificationText && (
                                    <Row className={`m-0`}>
                                        <Col className="p-0"><p className="fs-6 mb-0"><b>Message:</b> {notificationText}</p></Col>
                                    </Row>
                                )}

                                { !notificationText && !notificationTitle && (
                                    <Row className="m-0 mt-2">
                                        <Col className="p-0"><p className="fs-6 mb-0">Neither 'Title' nor 'Message' was typed.</p></Col>
                                    </Row>
                                )}
                            </>
                        )}


                    </div>
                )}

                { (isNew || isEditingContent) && (
                    <Row className="m-0 p-0">
                        <Col className="px-0 px-md-3 mb-4">
                            <div className="d-flex flex-wrap">
                                <Chip text="Text" icon="bi-body-text" isSelected={contentType === 'text'} onSelect={() => changeHandler(id, { contentType : 'text' })}/>
                                <Chip disabled text="Image" icon="bi-card-image" isSelected={contentType === 'image'} onSelect={() => changeHandler(id, { contentType : 'image' })}/>
                                <Chip disabled text="Open Listings" icon="bi-door-open" isSelected={contentType === 'openListing'} onSelect={() => changeHandler(id, { contentType : 'openListing' })}/>
                                <Chip disabled text="Favorite Listings" icon="bi-star-fill" isSelected={contentType === 'favoriteListing'} onSelect={() => changeHandler(id, { contentType : 'favoriteListing' })}/>
                            </div>

                            { wasSaveClicked && !contentType && (
                                <Col xs={12} className="p-0">
                                        <p className={"custom_error pt-1"}>
                                            { "Select a content type." }
                                        </p>
                                </Col>
                            )}

                            { contentType === 'text' && (
                                <>
                                    <InputFloating
                                        label="Title"
                                        labelClasses="mt-3"
                                        register={{
                                            value: notificationTitle,
                                            onChange: (e) => changeHandler(id, { notificationTitle : e.target.value })
                                        }}
                                        showError={true}
                                        errorMessage={!notificationTitle && (wasSaveButtonClicked || wasSaveClicked) ? 'Title field is required.' : ""}
                                    />

                                    <TextAreaFloating
                                        label="Message:"
                                        labelClasses="my-3"
                                        register={{
                                            value: notificationText,
                                            onChange: (e) => changeHandler(id, { notificationText : e.target.value })
                                        }}
                                        showError={true}
                                        errorMessage={!notificationText && (wasSaveButtonClicked || wasSaveClicked) ? 'Message field is required.' : ""}
                                    ></TextAreaFloating>
                                </>
                            )}
                        </Col>
                        { !isNew && (
                            <Col className="px-md-3 mb-4" xs={12}>
                                <Row className="m-0">
                                    <Col className="p-0 me-2">
                                        <ButtonSecondary 
                                            title="Cancel"
                                            onClick={ () => onCancelContentTypeHandler(id) }
                                            type="button"
                                        />
                                    </Col>
                                    <Col className="p-0">
                                        <ButtonPrimary 
                                            title="Save"
                                            onClick={ () => onSaveContentTypeHandler(id) }
                                            type="button"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                )}

            </Row>

            <Row className="p-1 m-0 mb-4 position-relative">
                <BluredSection show={canDrop || (sectionFocus !== null && sectionFocus !== "sendVia")}/>
                <Bar>
                    <h5 className="fw-bold m-0 fs-6 fs-lg-5">Send Via <span className="fs-6">(select one)</span> </h5>
                </Bar>
                <div className="d-flex flex-wrap px-0 px-md-3">
                    <Chip text="Text" icon="bi-chat-left-dots-fill" isSelected={Number(sendVia) === 1} onSelect={() => changeHandler(id, { sendVia: 1}, true)}/>
                    <Chip text="Email" icon="bi-envelope-fill" isSelected={Number(sendVia) === 2} onSelect={() => changeHandler(id, { sendVia: 2}, true)}/>
                </div>
                { wasSaveClicked && !sendVia && (
                    <Col xs={12} className="p-0 px-0 px-md-3">
                            <p className={"custom_error pt-1"}>
                                { "Select a 'Send Via' type." }
                            </p>
                    </Col>
                )}
            </Row>

            <Row className="p-1 m-0 position-relative">
                <BluredSection show={(sectionFocus !== null && sectionFocus !== "recipients")}/>
                <Bar>
                    <h5 className="fw-bold m-0 fs-6 fs-lg-5">Recipients <span className="fs-6 d-none d-md-inline">(drag from the left panel)</span> </h5>
                </Bar>

                <div className="d-flex flex-wrap mb-4 px-0 px-md-3" ref={dropSection}>

                    <Col xs={12} className="p-0 mb-3 d-md-none">
                        <Row className="m-0 justify-content-between align-items-center">
                            <Col className="p-0 pe-1">
                                <Form.Select 
                                    aria-label={"Select"}
                                    className={"rounded border shadow-sm customSelect fs-6"}
                                    
                                    value={selectedGroup}
                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                >
                                    <option value={''}></option>
                                    { groups.map( group => (
                                        <option value={group[GROUP_ID]}>{group[GROUP_NAME]}</option>
                                    )) }
                                </Form.Select>
                            </Col>
                            <Col xs="auto" className="p-0">
                                <ButtonPrimary onClick={() => { addToRecipientsMobile(selectedGroup, id) }}><i className="bi bi-plus-lg"></i> Add</ButtonPrimary>
                            </Col>
                        </Row>
                    </Col>

                    { recipientsIDs && !recipientsIDs.length && (<p className="m-0 fs-6 text-center">You haven't added any recipients yet.<span className="d-none d-md-inline"> To add recipient drag group from left to right panel.</span></p>)}
                    { recipientsIDs && recipientsIDs.map( recipientID => (
                        <Chip isSelected={true} text={getGroupName(recipientID, groups)} exit={true} canSelect={false} onDelete={() => deleteRecipient(id, recipientID)}/>
                    )) }

                    {  canDrop && (<div ref={drop} className={`mt-3 command-center__drop-area rounded d-flex justify-content-center align-items-center ${isOver ? "command-center__drop-area--active" : ""}`}>
                        <p className="m-0 fw-bold"><i className="bi bi-arrow-down-circle-fill me-2"></i>Drop here</p>
                    </div>) }

                    { wasSaveClicked && recipientsIDs && !recipientsIDs.length && (
                        <Col xs={12} className="p-0">
                                <p className={"custom_error pt-1"}>
                                    { " Recipients cannot be empty." }
                                </p>
                        </Col>
                    )}
                </div>
            </Row>

            <Row className="p-1 m-0 position-relative">
                <BluredSection show={canDrop || (sectionFocus !== null && sectionFocus !== "scheduling")}/>

                <Bar>
                    <Row className="m-0 p-0 align-items-center">
                        {!isNew && (
                            <Col className="p-0" xs="auto">
                                <ButtonPrimary type="button" className="opacity-0" disabled><i className="bi bi-pencil-fill"></i></ButtonPrimary>
                            </Col>
                        )}

                        <Col className="p-0">
                            <h5 className="fw-bold m-0 fs-6 fs-lg-5">Scheduling</h5>
                        </Col>

                        {!isNew && (
                            <Col className="p-0" xs="auto">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100 }}
                                    overlay={(
                                        <Tooltip>
                                            Edit
                                        </Tooltip>
                                    )}
                                >
                                    <div>
                                        <ButtonPrimary disabled={ isEditingScheduling} type="button" onClick={onEditSchedulingHandler}><i className="bi bi-pencil-fill"></i></ButtonPrimary>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        )}
                    </Row>
                </Bar>

                { !isEditingScheduling && !isNew && scheduling && (
                    <div className="mb-4 px-0 px-md-3">
                        <Row className="m-0 mb-2">
                            <Col className="p-0">
                                <p className="fs-6 mb-0">Scheduling is {scheduling.enabled ? <span className='text-success fw-bold'>enabled</span> : <span className='text-danger fw-bold'>disabled</span>}</p>
                            </Col>
                        </Row>

                        { scheduling.enabled && (
                            <>
                                <Row className="m-0">
                                    <Col className="p-0"><p className="fs-6 mb-0"><b>From:</b> {new Date(scheduling.startDate).toLocaleDateString("en-US")}</p></Col>
                                    <Col className="p-0 ms-2"><p className="fs-6 mb-0"><b>To:</b> {new Date(scheduling.endDate).toLocaleDateString("en-US")}</p> </Col>
                                </Row>

                                <Row className="m-0">
                                    <Col className="p-0"><p className="fs-6 mb-0"><b>Repeats:</b> {['Daily', 'Weekly', 'Monthly'][scheduling.repeatEvery - 1]}</p></Col>
                                    <Col className="p-0 ms-2"><p className="fs-6 mb-0"><b>At:</b> {getformattedTime(scheduling.hour)}</p> </Col>
                                </Row>

                                <Row className="m-0">
                                    <Col className="p-0"><p className="fs-6 mb-0"><b>On:</b> {getFormattedDayNames(scheduling.days)}</p></Col>
                                </Row>
                            </>
                        )}

                    </div>
                )}

                { (isNew || isEditingScheduling) && (

    
                    <div className="mb-4 px-0 px-md-3">
                        <Row className="justify-content-between align-items-center">
                                    
                            <Col xs="12" md="auto" className="d-flex flex-column align-items-center mb-3">
                                <Row className="">
                                    <Col className="d-flex align-items-center">
                                        <Toggle 
                                            onChange={() => enableSchedulingBtn(id)}
                                            checked={scheduling && scheduling.enabled}
                                            id={`notification-enable-scheduling-${id}`}
                                        />
                                        <label className="ms-2" htmlFor={`notification-enable-scheduling-${id}`}>
                                            Enable Scheduling
                                        </label>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>


                        { scheduling && scheduling.enabled && (
                            <Row className="justify-content-start">
                                <Col xs="12" className="mb-3">
                                    <Row className="m-0 justify-content-between align-items-start mb-2">
                                        <Col xs={12} md={6} className="p-0 pe-md-1 mb-2 mb-md-0">
                                            <DateInput
                                                label="Start Date"
                                                placeholder="Start Date"
                                                setDate={(value) => setSchedulingDate(id, 'startDate' , value)}
                                                date={scheduling && scheduling.startDate && new Date(scheduling.startDate)}
                                                // error={ wasSaveClicked && scheduling && !scheduling.startDate && 'Required'}
                                                error={ errorMessages.startDate }
                                                minDate={new Date()}
                                                selectsStart
                                                endDate={scheduling && scheduling.endDate && new Date(scheduling.endDate)}
                                                showClearBtn={false}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className="p-0 ps-md-1">
                                            <DateInput
                                                label="End Date"
                                                placeholder="End Date"
                                                setDate={(value) => setSchedulingDate(id, 'endDate' , value)}
                                                date={scheduling && scheduling.endDate && new Date(scheduling.endDate)}
                                                startDate={ scheduling && scheduling.startDate && new Date(scheduling.startDate) }

                                                error={ errorMessages.endDate }

                                                minDate={ (scheduling && scheduling.startDate) ? new Date(scheduling.startDate) : new Date() }
                                                selectsEnd
                                                showClearBtn={false}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="m-0 justify-content-between align-items-start mb-2">
                                        <Col xs={12} md={6} className="p-0 pe-md-1 mb-2 mb-md-0">
                                            <SelectFloating 
                                                label="Repeats"
                                                aria-label="Select notification frequency" 
                                                showError={true}
                                                optionEmpty={true}
                                                register={{
                                                    onChange: (e) => selectRepeat(id, e.target.value),
                                                    value: scheduling && scheduling.repeatEvery
                                                }}
                                                dataArrayWithCustomVal={[['Daily', 1], ['Weekly', 2]
                                                // , ['Monthly', 3]
                                                ]}

                                                errorMessage={wasSaveClicked && errorMessages.repeatEvery}
                                            />
                                        </Col>

                                        <Col xs={12} md={6} className="p-0 ps-md-1">

                                            <FloatingLabel
                                                controlId={"floatingInput"}
                                                label={"At"}
                                            >
                                                <Form.Control
                                                    readOnly
                                                    type='text'
                                                    className='form-control--time-picker rounded border shadow-sm cursor-pointer'
                                                    value={scheduling && scheduling.hour && getformattedTime(scheduling.hour)}
                                                    onClick={() => onClickTimePicker(id)}
                                                />
                                            </FloatingLabel>

                                         
                                            <p className={"custom_error pt-1"}>
                                                { wasSaveClicked && errorMessages.hour }
                                            </p>
                                    
                                        </Col>

                                    </Row>
                        
                                    <Row className="m-0 justify-content-between align-items-center mb-2">
                                        <Col className="m-0 p-0" xs={12}>
                                            <div className="position-relative bg-white rounded border shadow-sm" style={{padding: '26px 12px 10px'}}>
                                                <div className="position-absolute top-0 start-0" style={{padding: '1rem 0.75rem 0', transform: 'scale(0.85) translateY(-0.75rem)', opacity: 0.65}}>On</div>
                                                
                                                { scheduling && Number(scheduling.repeatEvery) === 3 && (
                                                
                                                    <Row className="m-0 align-items-center justify-content-start">
                                                        <Col xs="auto" className="p-0 me-2 d-flex align-items-center">
                                                            <Form.Check id="checkbox-notification-day" 
                                                                checked={scheduling && scheduling.dayOfTheMonth} 
                                                                onChange={() => editScheduling(id, { 
                                                                    dayOfTheMonth: monthlyDateSettings.monthDay, 
                                                                    repeatOnDayOfTheWeek: null,
                                                                    repeatEveryWeekNumber: null 
                                                                })}
                                                            />
                                                            <label htmlFor='checkbox-notification-day' className="m-0 p-0 ps-2" style={{fontSize: 10}}>day {monthlyDateSettings.monthDay}</label>
                                                        </Col>

                                                        <Col xs="auto" className="p-0 d-flex align-items-center">
                                                            <Form.Check id="checkbox-notification-dayName" 
                                                                checked={scheduling && scheduling.repeatOnDayOfTheWeek && scheduling.repeatEveryWeekNumber} 
                                                                onChange={()=>editScheduling(id, {  
                                                                    dayOfTheMonth: null,
                                                                    repeatOnDayOfTheWeek: monthlyDateSettings.weekDay,
                                                                    repeatEveryWeekNumber: monthlyDateSettings.dayOfTheMonth
                                                                })}
                                                            />
                                                            <label htmlFor='checkbox-notification-dayName' className="m-0 p-0 ps-2" style={{fontSize: 10}}>the {monthlyDateSettings.dayOfTheMonthName} {monthlyDateSettings.weekDayName}</label>
                                                        </Col>
                                                    </Row>
                                                
                                                )}

                                                { scheduling && Number(scheduling.repeatEvery) !== 3 && (
                                                    <Row className="m-0 align-items-center justify-content-center">
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(1)} onChange={()=>selectSchedulingWeekDay(id, 1)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Monday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(2)} onChange={()=>selectSchedulingWeekDay(id, 2)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Tuesday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(3)} onChange={()=>selectSchedulingWeekDay(id, 3)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Wednesday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(4)} onChange={()=>selectSchedulingWeekDay(id, 4)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Thursday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(5)} onChange={()=>selectSchedulingWeekDay(id, 5)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Friday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(6)} onChange={()=>selectSchedulingWeekDay(id, 6)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Saturday</p>
                                                        </Col>
                                        
                                                        <Col xs="auto" className="text-center p-1">
                                                            <Form.Check checked={scheduling && scheduling.days.includes(0)} onChange={()=>selectSchedulingWeekDay(id, 0)}/>
                                                            <p className="m-0" style={{fontSize: 10}}>Sunday</p>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </Col>
                                        <Col className="m-0 p-0" xs={12}>
                                            <p className={"custom_error pt-1"}>
                                                { wasSaveClicked && errorMessages.days }
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        
                        {scheduling && (
                              <Row className="justify-content-start">
                                { !isNew && (
                                        <Col className="px-md-3 mb-4" xs={12}>
                                            <Row className="m-0">
                                                <Col className="p-0 me-2">
                                                    <ButtonSecondary 
                                                        title="Cancel"
                                                        onClick={ () => onCancelSchedulingHandler(id) }
                                                        type="button"
                                                    />
                                                </Col>
                                                <Col className="p-0">
                                                    <ButtonPrimary 
                                                        title="Save"
                                                        onClick={ () => onSaveSchedulingHandler(id) }
                                                        type="button"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}

                                    <Col xs="12">
                                        <Row className="justify-content-between align-items-center">
                                            { isNew && (
                                                <Col xs="12" className="mt-3">
                                                    <Row className="m-0">
                                                        <Col className="p-0 me-2">
                                                            <ButtonSecondary 
                                                                title="Cancel"
                                                                onClick={(e) => cancelAddNewNotification(e, id)}
                                                            />
                                                        </Col>

                                                        <Col className="p-0 me-2">
                                                            <ButtonPrimary 
                                                                title="Save & Send Now"
                                                                onClick={() => saveNewNotificationHandler("saveAndSend", id)}
                                                            />
                                                        </Col>
                                                        <Col className="p-0">
                                                            <ButtonPrimary 
                                                                title="Save"
                                                                onClick={() =>  saveNewNotificationHandler("save", id)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                              </Row>
                        )}

                    </div>


                )}

                { (!isNew && !isEditingScheduling) && (
                    <Row className="m-0 justify-content-end align-items-center">
                        <Col className="p-0 d-flex align-items-center" xs="auto">
                           
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 800 }}
                                overlay={(
                                    <Tooltip id="button-tooltip">
                                        Use Send Now to send the notification now. If scheduling is enabled, it will still continue to send the notification at the selected times
                                    </Tooltip>
                                )}
                                >
                                <i className="me-2 bi bi-info-circle"></i>
                            </OverlayTrigger>

                            <ButtonPrimary
                                onClick={() => sendNotificationImmediately(id)}
                            
                                // disabled={scheduling && scheduling.sendNow}
                            >Send&nbsp;Now</ButtonPrimary>
                               
                        </Col>
                    </Row>
                )}

            </Row>
    
        </CategoryItem>
    );
  }