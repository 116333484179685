import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Row,
    Col,
} from 'react-bootstrap';

import BasicInfo from '../../LL_TourRequests/BasicInfo';
import Message from './Message';
import { formatDateUS } from '../../../utils/dateTransformations';
import MessageContainerLoader from './MessageContainerLoader';

const MessageContainer = ({
    unitID,
    UnitInfo,
    buttons,
    isLoading,
    messages,
    isInfoCollapsed
}) => {
    const [preparedMessages, setPreparedMessages] = useState([]);
    const messagesRef = useRef(null);
    
    // const scrollToElementBottom = (ref) => {
    //     if(!ref) return;
       
    //     ref.scrollTop = ref.scrollHeight - ref.clientHeight;
    //     // ref.scroll({top: ref.clientHeight});
    // }

    const splitMessage = useCallback((message) => {
        return message.split('$*DATES*$');
    }, []);

    const extractData = useCallback((data) => {
        const extractedMessage = splitMessage(data)[0];
        const extractedAvailableDates = splitMessage(data)[1];

        let preparedAvailableDates = null;
        if (extractedAvailableDates) {
            preparedAvailableDates = extractedAvailableDates.split('|');
        }

        return {
            extractedMessage,
            extractedAvailableDates: preparedAvailableDates
        }
    }, [splitMessage]);

    const prepareMessage = useCallback((o_message) => {
        const {
            MessageID,
            Type,
            from,
            fromUserType,
            message,
            date
        } = o_message;

       
        const {extractedMessage, extractedAvailableDates} = extractData(message);

        return {
            MessageID,
            Type,
            from,
            fromUserType,
            message: extractedMessage,
            availableDates: extractedAvailableDates,
            date: formatDateUS(date)
        }
    }, [extractData]);

    const prepareMessagesHandler = useCallback((a_messages) => {
        if(!a_messages) return;

       
        setPreparedMessages( a_messages.map( message => prepareMessage(message) ).reverse() );



    }, [prepareMessage]);

    useEffect(() => {
        if (messages) {
           
            prepareMessagesHandler(messages);
        }
    }, [ messages, prepareMessagesHandler])



    // useEffect(() => {
    //     scrollToElementBottom(messagesRef.current);
    // }, [messagesRef, preparedMessages]);

    return (
        <Col className="py-3 ms-2  d-flex flex-column lightGray inbox-border-inside-y inbox-element-h rounded">

            { isLoading && (<MessageContainerLoader />)}

            { !isLoading && (
                <>
                    <Row className={`px-0 m-0 p-0 py-3 py-xl-4`}>
                        <h3 className="m-0 p-0 fw-bold ">Tour Request</h3>
                    </Row>

                    <Row className="m-0 p-0 mb-3 justify-content-center">
                        { buttons }                        
                    </Row>

                    <Row className="px-0 m-0 p-0">
                        {/* MESSAGE BASIC INFO */}
                        <BasicInfo 
                            showButton={false}
                            unitID={unitID}
                            selectedReq={preparedMessages && preparedMessages[0]}
                            UnitInfo={UnitInfo}
                            setCollapsed={isInfoCollapsed}
                        />
                       
                    </Row>
       
                    <Row className="m-0 p-0 px-0 flex-column flex-nowrap flex-grow-1 justify-conent-start" style={{overflowY: "auto"}} ref={messagesRef}>

                        { preparedMessages.map( message => (
                            <Message key={message.MessageID} selectedReq={message}/>
                        ))

                        }
                        
                    </Row>

                   
                </>
            )}

        </Col>
    )
};

export default MessageContainer;
