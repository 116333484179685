import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";

import LazyImage from "../Shared/LazyImage";
import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";
import { determineHowLongAgo } from "../../utils/dateTransformations";

import { getStaticStreetView } from "../../utils/preparePhotos";
import { PostedAgo } from "../Shared/PostedAgo";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5, transition: "ease-in-out" },
};

export default function PropertyCard({
  building,
  selectBuildingHandler,
  highlightBuildingWithID,
  visibleBuildingsId,
  setHighlightBuildingWithID,
  alreadyRequestesUnitIDs,
  expandPropertyHandler,
  favorites,
}) {
  const isMap = useLocation().pathname.split('/').at(-1) === 'map';
  const UnitIDs = isMap? building?.Units : building?.UnitIDs;
  const address = building?.Address;
  const addressLine1 = address.replace(/\$\*\|\*\$/, " ");
  const buildingID = building?.BuildingID;
  const unitsLength = UnitIDs?.length || 0;
  const minRentPrice = building?.MinRentPrice || building?.Units.map(u=>u.Rent).sort((a,b)=>a-b)[0] || '???';
  const clickHandler = (id) => {
    if (!id) return;
    expandPropertyHandler(id);
  };

  const isInArray = (listOfIds) => {
    if (!listOfIds) return false;
    const isInArrary = listOfIds.findIndex((el) => el === buildingID);
    return isInArrary !== -1;
  };

  const mouseEnterHandler = () => {
    setHighlightBuildingWithID([buildingID],building);
  };

  const mouseLeaveHandler = () => {
    setHighlightBuildingWithID([]);
  };

  const isFavorite = (favorites, BuildingID) => {
    if (!BuildingID || !favorites) return null;

    if (
      favorites.Buildings &&
      favorites.Buildings.some((building) => building.BuildingID === BuildingID)
    ) {
      return "building";
    }
    if (
      favorites.Units &&
      favorites.Units.some((unit) => unit.BuildingID === BuildingID)
    ) {
      return "unit";
    }
    return null;
  };

  const BuildingPhoto = building.PhotoURL;
  const SRC = getStaticStreetView(building);
  
  return (
    <>
      {isInArray(visibleBuildingsId) && (
        <Container
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className="d-flex flex-column align-items-center justify-content-center px-0"
        >
          {Number.isInteger(unitsLength) && (
            <>
              <AnimatePresence>
                <motion.div {...animations} layout className="w-100">
                  <Row
                    key={buildingID}
                    className={`p-0 m-0 mb-2 rounded ${
                      isInArray(highlightBuildingWithID) ? "" : ""
                    } bg-white text-dark w-100`}
                    style={{ minHeight: 200 }}
                  >
                    {/* IMAGE SECTION */}
                    <Col
                      className="m-0 p-0 position-relative cursor-pointer "
                      xs={4}
                      onClick={() => selectBuildingHandler(buildingID)}
                    >
                      <LazyImage
                        src={BuildingPhoto ? BuildingPhoto : SRC}
                        style={{
                          objectFit: "cover",
                          height: 200,
                          width: "100%",
                        }}
                      />

                      <Row className="m-0 p-2 position-absolute top-0 w-100 d-flex justify-content-between align-items-center">
                        <Col className="m-0 p-0">
                          {isFavorite(favorites, buildingID) === "building" && (
                            <div className="m-0 p-0 position-relative d-flex">
                              <i className="bi bi-star-fill text-warning fs-4"></i>
                              <i className="bi bi-star text-dark fs-4 position-absolute"></i>
                            </div>
                          )}

                          {isFavorite(favorites, buildingID) === "unit" && (
                            <div className="m-0 p-0 position-relative d-flex">
                              <i className="bi bi-star-fill text-primary fs-4"></i>
                              <i className="bi bi-star text-light fs-4 position-absolute"></i>
                            </div>
                          )}
                        </Col>

                        <Col className="m-0 p-0" xs="auto">
                          {building && building.LastVacantDate && (
                            <div className="m-0 p-1 bg-light rounded">
                              {/* <p className="m-0 p-0 fw-bold" style={{fontSize: '0.7rem'}}> { determineHowLongAgo( 1666100000000 ) }</p> */}
                              <p
                                className="m-0 p-0 fw-bold"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {" "}
                                {determineHowLongAgo(
                                  new Date(building.LastVacantDate).getTime()
                                )}
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    {/* INFORMATION SECTION */}
                    <Col className="m-0 p-0 p-3 d-flex flex-column" xs={8}>
                      <Row className="m-0 p-0 justify-content-between">
                        <Col
                          className="m-0 p-0 cursor-pointer"
                          xs={"auto"}
                          onClick={() => selectBuildingHandler(buildingID)}
                        >
                          <h5 className="m-0 p-0 fw-bold">
                            {building && building.Name}
                          </h5>
                        </Col>
                        <Col className="m-0 p-0" xs={"auto"}>
                          <h5 className="m-0 p-0 fw-bold">{`${
                            unitsLength > 1 ? "From" : "Rent"
                          }: $${minRentPrice}`}</h5>
                        </Col>
                      </Row>

                      <Row className="m-0 p-0 mb-1">
                        <p
                          className="m-0 p-0 text-muted"
                          style={{ fontSize: "0.85rem" }}
                        >
                          <i className="bi bi-geo-alt">&nbsp;</i>
                          {building && addressLine1}
                        </p>
                      </Row>

                      {/* AMENITIES AND ACCESSIBILITIES */}
                      <Row className="m-0 p-0">
                        <Row className="m-0 p-0">
                          <Col className="m-0 p-0 d-flex" xs={12}>
                            <p
                              className="m-0 p-0 pe-1 fs-6"
                              style={{ color: "inherit" }}
                            >
                              <b>Amenities: </b>
                            </p>
                            <p
                              className="m-0 p-0 fs-6"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                color: "inherit",
                              }}
                            >
                              {building &&
                                building.Amenities.map(
                                  (amenity, index, arr) =>
                                    `${amenity}${
                                      index < arr.length - 1 ? ", " : ""
                                    }`
                                )}
                            </p>
                          </Col>
                        </Row>

                        <Row className="m-0 p-0">
                          <Col className="m-0 p-0 d-flex" xs={12}>
                            <p
                              className="m-0 p-0 pe-1 fs-6"
                              style={{ color: "inherit" }}
                            >
                              <b>Accessibilities: </b>
                            </p>
                            <p
                              className="m-0 p-0 fs-6"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                color: "inherit",
                              }}
                            >
                              {building &&
                                building.Accessibilities.map(
                                  (accessibility, index, arr) =>
                                    `${accessibility}${
                                      index < arr.length - 1 ? ", " : ""
                                    }`
                                )}
                            </p>
                          </Col>
                        </Row>
                      </Row>
                      <Row className="">
                        <div className="d-block d-xl-none">
                          <PostedAgo
                            time="2023-03-17"
                            className="ms-auto"
                            text="Newest Listing: "
                          />
                        </div>
                      </Row>
                      <Row className="m-0 p-0 mt-2 flex-fill align-items-end justify-content-between">
                        <Col className="">
                          <div className="d-none d-xl-block">
                            <PostedAgo
                              time="2023-03-17"
                              className="ms-auto"
                              text="Newest Listing: "
                            />
                          </div>
                        </Col>
                        <Col className="m-0 p-0" xs={"auto"}>
                          {alreadyRequestesUnitIDs &&
                            UnitIDs &&
                            UnitIDs.some((UnitID) =>
                              alreadyRequestesUnitIDs.includes(UnitID)
                            ) && (
                              <i className="bi bi-check2 text-success fs-4 ps-1"></i>
                            )}
                        </Col>
                        <Col className="m-0 p-0" xs={"auto"}>
                          <ButtonPrimary
                            onClick={() => clickHandler(buildingID)}
                            title={`Show ${unitsLength} unit${
                              unitsLength > 1 ? "s" : ""
                            }`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </motion.div>
              </AnimatePresence>
            </>
          )}
        </Container>
      )}
    </>
  );
}
