import React, { useState } from 'react';
import { Container, Row, Col, Image, Modal } from "react-bootstrap";

import Footer from '../components/Shared/Footer';
import BH_Photo from '../assets/images/srcdev_bh.png';
import NL_Photo from '../assets/images/srcdev_nl.png';
import PJ_Photo from '../assets/images/srcdev_pj.png';

const BH = "bh";
const NL = "nl";
const PJ = "pj";


export default function Team () {
    const [selectedMember, setSelectedMember] = useState(null);
    const openModal = (initials) => setSelectedMember(initials);
    const closeModal = () => setSelectedMember(null);

    return (
        <>
            <div className="custom_bg d-flex align-items-center justify-content-center">
                <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
                <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
                    <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12}>
                        <Row className="m-0 p-0">
                            <Col className="m-0 p-0">
                                <h1 className="m-0 p-0 fw-bold">Meet our dedicated team</h1>
                            </Col>
                        </Row>

                        <Row className="m-0 p-0 pt-4 pt-sm-5">
                            <Col className="m-0 p-0">
                                <h5 className="m-0">
                                    The WISSP development team is a subset of <a className="text-decoration-none fw-bold" href='http://www.srcdevhub.com' target={"_blank"} rel="noreferrer">Source Development Hub</a>, an early-stage ideation venture focused on designing tech tools to address issues of social and health inequity. We are a diversely skilled team with backgrounds from research, engineering, design, and advocacy.
                                </h5>
                            </Col>
                        </Row>

                        <Row className="p-0 pt-4 pt-sm-5 justify-content-center align-self-center" style={{margin: '0 -0.5rem 0 -0.5rem'}}>

                            <TeamMember 
                                name='Billy Huang'
                                imgSrc={BH_Photo}
                                position='Executive Officer'
                                openModal={() => openModal(BH)}
                            />

                            <TeamMember 
                                name='Nelson Lau'
                                imgSrc={NL_Photo}
                                position='Technology Officer'
                                openModal={() => openModal(NL)}
                            />

                            <TeamMember 
                                name='Priyanka Junankar'
                                imgSrc={PJ_Photo}
                                position='Design Officer'
                                openModal={() => openModal(PJ)}
                            />

                        </Row>
                    </Col>
                </Row>

                    <Modal show={!!selectedMember} onHide={closeModal} centered={true} dialogClassName="modal-1200px">
                        <Modal.Header closeButton/>
                        <Modal.Body className="m-auto d-flex align-items-sm-center" style={{ maxWidth: '1200px' }}>

                            { selectedMember === BH && (
                                <TeamMemberInfo 
                                    name='Billy Huang'
                                    imgSrc={BH_Photo}
                                    position='Executive Officer'
                                    description='Billy Huang is a social entrepreneur and founder/CEO of Source Development Hub. Billy’s interests span areas related to social determinants of health, particularly at the intersection of housing and community health. The ideation and development of WISSP came from his experiences working directly with community partners engaged in homelessness prevention, affordable housing, and legal aid work. He has experience with machine-learning techniques, natural language processing, image analysis, data visualization, web development, and consulting. Among his consulting engagements included previous work with Pratham Institute, one of India’s largest non-governmental organizations, on investigating the role of FinTech vocational training programs. One of his current engagements is working with MIT Solve, a social impact marketplace, to qualify social returns on investments for their global social enterprise teams. Billy also leads and consults for several groups on an ongoing basis including (1) the New Haven Commission on Disabilities on areas of advocacy and equity, (2) New Haven’s Community Services Administration on areas of landlord engagement, and (3) the Osteogenesis Imperfecta Foundation, on areas of patient-centered heath research. He holds a Bachelor of Science from the Massachusetts Institute of Technology and an MBA from the Yale School of Management.'
                                />
                            )}

                            { selectedMember === NL && ( 
                                <TeamMemberInfo 
                                    name='Nelson Lau'
                                    imgSrc={NL_Photo}
                                    position='Technology Officer'
                                    description='Nelson Lau is a full stack engineer with particular expertise in backend systems related to complex data analysis and integration. At SDH, Nelson has led the engineering work for WISSP in areas of both design and implementation. He is also currently a software engineer at Boston Medical Data, a Boston-based healthcare startup working on developing machine-learning software to manage hospital administration. Through his work, he has domain expertise in developing machine-vision tools for feature extraction from large, secured batches of patient and administrative data. He holds a Bachelor of Science from the Massachusetts Institute of Technology.'
                                />
                            )}

                            { selectedMember === PJ && (
                                <TeamMemberInfo 
                                    name='Priyanka Junankar'
                                    imgSrc={PJ_Photo}
                                    position='Design Officer'
                                    description="Priyanka Junankar graduated from Bucknell University in 2017 with degrees in Computer Science, and Markets Innovation & Design. At SDH, Priyanka has led the design work for WISSP both on the technical side (UI) as well as on the collaborative (UX) side working with stakeholders. She began her career as a Technical Strategy Consultant at Accenture Federal Services working on both long-term strategy and systems architecture. She has also held roles at startups in brand strategy, product management, and business development. She is currently pursuing her MBA at Yale's School of Management with an interest in economic development and brand strategy."
                                />
                            )}
                            
                        </Modal.Body>
                    </Modal>

                </Container>
            </div>
            <Footer />
        </>
    )
}

const TeamMember = ({
    name,
    imgSrc,
    position,
    openModal
}) => {
    return (
        <Col className="m-0 p-0 mb-4 cursor-pointer" xs={6} md={4} onClick={openModal}>
            <Row className={`m-0 p-0 px-2`} >
                <Image className='m-0 p-0 rounded team-photo' src={imgSrc} alt="Team Member Photo"></Image>  
            </Row>
            <Row className="m-0 p-0 mt-3 text-center">
                <h4 className={`m-0 p-0  fw-bold`}>{ name }</h4>
                <h6 className={`m-0 mt-2 p-0`}>{ position }</h6>
            </Row>
        </Col>
    )
}

const TeamMemberInfo = ({
    name,
    imgSrc,
    position,
    description
}) => {
    return (
        <Row className="m-0 p-0 px-sm-2 pb-0 justify-content-center">

            <Col className="m-0 p-0 cursor-pointer" xs={12} sm={5} md={4} lg={3}>
                <Row className={`m-0 p-0`} >
                    <Image className='m-0 p-0 rounded' style={{objectFit: 'cover'}} src={imgSrc} alt="..."></Image>  
                </Row>
                <Row className="m-0 p-0 my-3 text-center">
                    <h4 className={`m-0 p-0  fw-bold`}>{ name }</h4>
                    <h6 className={`m-0 mt-2 p-0`}>{ position }</h6>
                </Row>
            </Col>

            <Col className="m-0 p-0 pb-3 ps-sm-5">
                <p className="m-0 p-0 p-0" >{ description }</p>
            </Col>
        </Row>
    )
}
