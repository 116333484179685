import React from 'react';
import {
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap';


export default function MiniSearch ({
    qState,
    states,
    qCity,
    cities,
    qMinRent,
    qMaxRent,
    handleExpandSearch,
    countOfSelectedFeatures
}) {
    return (
        <Row className="m-0 p-3 rounded justify-content-center marketplace__search-bg position-relative">
            <div className={"m-0 p-0 position-absolute top-0 w-100 h-100 rounded cursor-pointer"} onClick={handleExpandSearch}></div>

            {/* LOCATION */}
           
            <Col className="m-0 p-0 pe-3">
                <Row className={`m-0 p-0`}>
                    <Form.Select
                        aria-label="Select a state"
                        className="rounded customSelect shadow-sm border-0"
                        value={qState}
                    >
                        <option value="" hidden>
                            {qState ? qState : "Select a State"}
                        </option>
                        {states.map((state) => {
                            return (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Row>
            </Col>

            <Col className="m-0 p-0 pe-3">
                <Row className={`m-0 p-0`}>
                    <Form.Select
                        aria-label="Select a city"
                        className="rounded customSelect shadow-sm border-0"
                        value={qCity}
                    >
                        <option value="" hidden>
                            {qCity ? qCity : "Select a City"}
                        </option>
                        {cities.map((city) => {
                            return (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Row>
            </Col>

            {/* PRICE */}
            <Col className="m-0 p-0 pe-3">
                <Form.Control
                    type="text"
                    placeholder={`Min Price ${qMinRent ? "(" + qMinRent + "$)" : ""}`}
                    className="rounded shadow-sm border-0"
                    value={qMinRent}
                />
            </Col>

            <Col className="m-0 p-0 pe-3">
                <Form.Control
                    type="text"
                    placeholder={`Max Price ${qMaxRent ? "(" + qMaxRent + "$)" : ""}`}
                    className="rounded shadow-sm border-0"
                    value={qMaxRent}
                />
            </Col>
          

            {/* BUTTONS */}
            <Col className="m-0 p-0" xs={12} sm={6} md={'auto'}>
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        <Button onClick={handleExpandSearch} size="md" className="w-100 rounded px-4" style={{height: 36}}>
                            <p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>Search{ countOfSelectedFeatures ? ` (${countOfSelectedFeatures})` : '' }&nbsp;<i className="bi bi-search"></i></p>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};