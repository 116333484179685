import React from 'react'

import {
    Modal,
    Carousel,
    Row
} from 'react-bootstrap';

import LazyImage from "./LazyImage"

export default function ModalCarousel({ show, closeHandler, photos, photoIndex, onSelect, unit, building }) {
    return (
        <Modal show={show} fullscreen={true} onHide={closeHandler}>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">{unit ? unit.Name : ''}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="d-flex flex-column justify-content-between">
                <Carousel interval={null}
                    activeIndex={photoIndex}
                    onSelect={onSelect}

                    variant="dark"
                    className=" d-flex justify-content-center align-items-center"
                >
                    {
                        photos && photos.map((photo, index) => (
                            <Carousel.Item key={index} className="position-relative" style={{ height: "70vh" }}>
                                <LazyImage
                                    className="position-absolute"
                                    style={{
                                        objectFit: "cover",
                                        maxHeight: "90%",
                                        maxWidth: '100%',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                    src={photo}
                                    alt={`${index} slide`}
                                />
                            </Carousel.Item>
                        ))
                    }

                </Carousel>

                <Row className="p-0 m-0 text-center">
                    {
                        (!building && unit) && (
                            <p className="p-0 m-0">Price per month: ${unit && unit.Rent}
                                {" "}
                                (Bedrooms: {unit && unit.BedroomCount}, Bathrooms: {unit && unit.BathroomCount})
                            </p>)
                    }
                    {
                        (building && unit) && (
                            <p className="p-0 m-0">{unit.Address.replace(/\$\*\|\*\$/, " ")}, {unit.City}, {unit.State}</p>
                        )
                    }
                </Row>

            </Modal.Body>
        </Modal>
    )
}
