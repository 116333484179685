import React from 'react';
import {
    Col,
    Card
} from 'react-bootstrap';
import { Link } from "react-router-dom";

import signupIcon from "../../assets/signup-icon.svg";

import LazyImage from "../Shared/LazyImage"

export default function UserTypeTile({
    onClick,
    title,
    description
}) {
    return (
        <Col xs={12} md={4} className="m-0 p-0 px-2 mb-3">
          <Card
            className="py-3 custom_card_title h-100 shadow-sm border-0"
            onClick={onClick}
          >

            <Link to="/signup-details">
              <div className="d-flex align-items-center flex-column m-2 m-md-4 mt-md-5">
                <LazyImage className="d-none d-md-block" src={signupIcon} width="150" height="150" alt="icon" />
                <LazyImage className="d-block d-md-none" src={signupIcon} width="80" height="80" alt="icon" />
              </div>
              <Card.Body>
                <Card.Title className="m-0 p-0 mb-md-2">
                  <p className="m-0 p-0 mb-md-3">{title}</p>
                </Card.Title>
                <Card.Text className='fw-normal fs-6'>
                  { description }
                </Card.Text>
              </Card.Body>
            </Link>

          </Card>
        </Col>
    )
}
