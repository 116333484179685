import {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";

const extrudeAddress = (dataFromGoogle) => {
    if(!dataFromGoogle) return null;
    const addressComponents = {
        State: null,
        City: null,
        StreetNumber: null,
        StreetName: null,
        Zip: null
    };

    const searchedTypes = {
        State: 'administrative_area_level_1',
        City: 'locality',
        Zip: 'postal_code',
        StreetNumber: 'street_number',
        StreetName: 'route'
    }

    Object.keys(searchedTypes).forEach( key => {
        const addressComponent = dataFromGoogle.find( ({types}) => types.includes(searchedTypes[key]));
        if(!addressComponent) return;
        
        const { long_name, short_name } = addressComponent;
        
        if(key === 'State') {
            addressComponents[key] = short_name;
            return;
        }
        addressComponents[key] = long_name;
    })

    return addressComponents;
}

const geocodeByAddressHandler = async (address) => {
    try {
        const response = await geocodeByAddress(address);
        const {lat,lng} = await getLatLng(response[0]);

        const { City, State, StreetName, StreetNumber, Zip } = extrudeAddress(response[0].address_components);
        const Address = (StreetName && StreetNumber) && `${StreetName} ${StreetNumber}`;    

        return {success: true, data: {
            City,
            State,
            Zip,
            Address,
            LatLong: {
                lat,
                lng
            }
        }}
    } catch (error) {
        return {success: false, data: error}
    }
}

export { geocodeByAddressHandler }
