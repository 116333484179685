import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function FloatingSubmitBtn ({submitHandler, errors}) {

    const areErrors = () => {
        return !!Object.keys(errors).length;
    }

    return (
        <Row className="d-block w-100 m-0 p-2 d-flex darkerGray darkerGray-secondary justify-content-center  position-fixed bottom-0 start-0 rounded-top"
            style={{ boxShadow: '0 0 0px 1px darkGray' }}
        >
            { areErrors() && 
                <Col className="m-0 p-0" xs={12}>
                    <p className="m-0 mb-2 p-0 text-danger text-center fs-6">Please complete all required fields before submitting</p>
                </Col>
            }
            <Col className="m-0 p-0">
                <Row className="m-0 p-0 justify-content-center">
                    <Col className="m-0 p-0" xs={'auto'}>
                        <ButtonPrimary 
                            onClick={ submitHandler }
                            title="Submit"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
} 
