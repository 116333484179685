import React, { useState } from "react";
import {
    Row,
    Col,
    Form,
    FloatingLabel
} from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import InputFloating from "../Shared/Inputs/InputFloating";
import { STATES } from "../../constants/Shared/states";


export default function BasicFields({
    s_autocompleteError,
    o_errors,
    f_register,
    f_setGoogleValue,
    o_googleValue,

    s_name,
    s_googleAutocomplete,
    s_addressLine1,
    s_addressLine2,
    s_city,
    s_state,
    s_zip
}) {
    const [google, setGoogle] = useState(false);

    return (
        <>
            <Form.Group className="mb-3">
                <InputFloating
                    label={"Property Name"}
                    type="text"
                    register={f_register(s_name)}
                    showError={true}
                    errorMessage={o_errors[s_name]?.message}
                />
            </Form.Group>

            <h3 className="fw-bolder">Address</h3>
            <Form.Check type="checkbox"
                onClick={(e) => setGoogle(e.target.checked)}
                label="Google Autocomplete"
                {...f_register(s_googleAutocomplete)}
            />

            {google && google ?
                <Row className="mb-3">
                    <Col >
                        <GooglePlacesAutocomplete
                            apiOptions={{
                                language: 'en',
                                region: 'US',
                            }}

                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['us']
                                },
                                types: ["address"]
                            }}

                            onLoadFailed={(error) => (
                                console.error("Could not inject Google script", error)
                            )}

                            withSessionToken={true}

                            selectProps={{
                                o_googleValue,
                                onChange: f_setGoogleValue,
                                styles: {
                                    input: (provided) => ({
                                        ...provided,
                                        height: 47,
                                        padding: 10,
                                        margin: "3px 0 0 -10px",
                                    }),
                                },
                            }}
                        />
                    </Col>
                    {
                        s_autocompleteError && (
                            <p className="custom_error pb-4">{s_autocompleteError[0] === 'Required' ? s_autocompleteError[0] : `Couldn't get: ${s_autocompleteError.join(', ')}`}</p>
                        )
                    }
                </Row>
                :
                <>
                    <Row>
                        <Form.Group className="mb-3">
                            <InputFloating
                                label={"Address Line 1"}
                                type="text"
                                register={f_register(s_addressLine1)}
                                showError={true}
                                errorMessage={o_errors[s_addressLine1]?.message}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <InputFloating
                                label={"Address Line 2"}
                                type="text"
                                register={f_register(s_addressLine2)}
                                showError={true}
                                errorMessage={o_errors[s_addressLine2]?.message}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="m-0 p-0">
                        <Col xs={12} md={4} className="m-0 px-0">
                            <Form.Group className="mb-3">
                                <InputFloating
                                    label={"City"}
                                    type="text"
                                    register={f_register(s_city)}
                                    showError={true}
                                    errorMessage={o_errors[s_city]?.message}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="m-0 px-0 px-md-3">
                            <Form.Group className="mb-3">
                                <FloatingLabel label={"State"}>
                                    <Form.Select

                                        className="formInput customSelect border-0 shadow-sm rounded"
                                        aria-label="Select state"
                                        {...f_register(s_state)}
                                    >
                                        <option hidden></option>
                                        {STATES.split(",").map((state) => {
                                            return (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="custom_error">{o_errors[s_state]?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="m-0 px-0">
                            <Form.Group className="mb-3">
                                <InputFloating
                                    label={"Zip-Code"}
                                    type="text"
                                    register={f_register(s_zip)}
                                    showError={true}
                                    errorMessage={o_errors[s_zip]?.message}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}