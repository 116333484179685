import { Container,  Row } from "react-bootstrap";
import React from "react";

import InputBtn from './InputBtn';

/**
 * Function will render table of checkboxes based on dataset
 * @param {Array} dataset Names for checkboxes
 * @param {Function} changeHandler useState setter
 * @param {Object} values variable that holds state for checkboxes
 * @param {String} title title of generated table
 */

export default React.memo(function Features({ dataset, changeHandler, values, title="", disabled=false }) {
    const handleChange = event => {
        const { checked, name } = event.currentTarget;

        changeHandler( prev => ({ 
            ...prev,
            [name]: checked
        }))
    };

    return (
        <Container fluid className="m-0 p-0">
                <Row className="m-0 p-0 features-spacing ">
                    { title && <h3 className="p-0 px-2 mb-2 fw-bolder">{title}</h3> }
                    {dataset && dataset.map((data, index) => {
                        if (index % 3 !== 0) return (
                            <InputBtn 
                                key={index}
                                index={index}
                                name={data}
                                onChange={handleChange}
                                checked={values[data]}
                                disabled={disabled}
                            />
                        )
                        return (
                            <React.Fragment key={index}>
                                <div className="w-100"></div>
                                <InputBtn 
                                    index={index}
                                    name={data}
                                    onChange={handleChange}
                                    checked={values[data]}
                                    disabled={disabled}
                                />
                            </React.Fragment>
                        )
                    })}
                </Row>
        </Container>
    )
});
