import { object as YupObject, string as YupString, ref as YupRef } from "yup";

export default YupObject().shape({
    email: YupString()
      .email("Email must be a valid email")
      .required("Email is required"),
    firstName: YupString()
      .required("First Name is required"),
    lastName: YupString()
      .required("Last Name is required"),
    password: YupString()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    passwordConfirmation: YupString()
      .oneOf([YupRef("password"), null], "Passwords must match")
      .required("Please confirm password"),
});
