import React from 'react';
import {
    Col,
    Row
} from 'react-bootstrap';

export default function ContentLoader() {
    return (
        <Row className="mt-5 m-0 p-0" style={{height: '100vh'}}>
         
            <Col className="m-0 p-0 me-1 darkerGray skeleton rounded" style={{height: '3.875rem'}}></Col>
            <Col className="m-0 p-0 me-1 darkerGray skeleton rounded" style={{height: '3.875rem'}}></Col>
            <Col className="m-0 p-0 darkerGray skeleton rounded" style={{height: '3.875rem'}}></Col>
           
        </Row>
    )
}
