import React, { useContext } from "react";

import BasicFields from "../BuildingFormElements/BasicFields";
import ManagerFields from "../BuildingFormElements/ManagersFields";
import ImagesFields from "../BuildingFormElements/ImagesFields";
import { UserTypeContext } from '../../contexts/UserTypeContext';

import {
    NAME,
    GOOGLE_AUTOCOMPLETE,
    ADDRESS_LINE_1,
    ADDRESS_LINE_2,
    CITY,
    STATE,
    ZIP
} from '../../constants/buildingFormConstants';

export default function FormSection1({
    images,
    setImages,
    imagesToDelete,
    setImagesToDelete,
    downloadedImages,
    autocompleteError,
    errors,
    register,
    setGoogleValue,
    googleValue,
    isUpdate = false,

    managerFields,
    addManager,
    removeManagerFromDB
}) {
    const { userType } = useContext(UserTypeContext);

    return (
        <>
            <BasicFields 
                s_autocompleteError={autocompleteError}
                o_errors={errors}
                f_register={register}
                f_setGoogleValue={setGoogleValue}
                o_googleValue={googleValue}
                s_name={NAME}
                s_googleAutocomplete={GOOGLE_AUTOCOMPLETE}
                s_addressLine1={ADDRESS_LINE_1}
                s_addressLine2={ADDRESS_LINE_2}
                s_city={CITY}
                s_state={STATE}
                s_zip={ZIP}
            />

            { userType === "Landlord" && (
                <ManagerFields
                    f_register={register}
                    o_errors={errors}
                    a_managerFields={managerFields}
                    f_addManager={addManager}
                    f_removeManagerFromDB={removeManagerFromDB}
                />
            )}

            <ImagesFields 
                images={images}
                setImages={setImages}
                downloadedImages={downloadedImages}
                imagesToDelete={imagesToDelete}
                setImagesToDelete={setImagesToDelete}
                isUpdate={isUpdate}
            />
        </>
    )
}
