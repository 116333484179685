import React from 'react';
import { Button } from 'react-bootstrap';


export default function ButtonSecondary({
    title,
    type="submit",
    className=" w-100 rounded fw-bold",
    additionalClassName,
    onClick=()=>{},
    disabled,
    children
}) {
    return (
        <Button
            className={ `${className} ${additionalClassName}` }
            variant="secondary"
            type={ type }
            onClick={ onClick }
            disabled={disabled}
        >
            { title }
            { children }
        </Button>
    )
}