import React from 'react'

import {
    Row,
  } from "react-bootstrap";

export default function UnitImage() {
    return (
        <Row className="m-0 p-0 d-lg-none w-100 darkerGray rounded skeleton" style={{height: 200}}>
        </Row>
    )
}
