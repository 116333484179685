import { getRequest, postPutRequest, deleteRequest } from './http-common';

const getUnitFeatures = async () => {
    const URL = `unit/features`;

    return await getRequest(URL);
}

const uploadUnit = async (data, token) => {
    const URL = `user/unit/upload`;

    return await postPutRequest(URL, data, token, 'post');
}

const updateInfo = async (unitID, data, token) => {
    const URL = `unit/${unitID}/update/info`;

    return await postPutRequest(URL, data, token, 'post');
}

const updateFeatures = async (unitID, data, token) => {
    const URL = `unit/${unitID}/update/features`;

    return await postPutRequest(URL, data, token, 'post');
}

const deleteUnit = async (unitID, data, token) => {
    const URL = `user/unit/${unitID}/delete`;

    return await postPutRequest(URL, data, token, 'post');
}

const editOccupant = async (unitID, data, token) => {
    const URL = `unit/${unitID}/tenant`;

    return await postPutRequest(URL, data, token, 'patch');
};

const deleteOccupant = async (unitID, token) => {
    const URL = `unit/${unitID}/tenant`;

    return await deleteRequest(URL, '', token);
}

const setFavoriteUnitPhoto = async (unitID, photoID, token) => {
    const URL = `unit/${unitID}/photos/${photoID}/favorite`;

    return await postPutRequest(URL, {}, token, 'post');
}

export { 
    getUnitFeatures,
    uploadUnit,
    updateInfo,
    updateFeatures,
    deleteUnit,
    deleteOccupant,
    editOccupant,
    setFavoriteUnitPhoto
};
