import React, { useState, useRef } from "react";
import { Modal, Col, Row, Accordion, Container, Card, Spinner } from "react-bootstrap";

import Portfolio from "../Tenant/Portfolio";
import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";
import ButtonSecondary from "../Shared/Buttons/ButtonSecondary";
import SectionAccordion from "../Shared/SectionAccordion";
import LazyImage from "../Shared/LazyImage";
import { STATES } from "../../constants/Shared/states";

const sections = [
  {
    title: "Target Unit",
    component: <TargetUnit />,
    icon: "bi-house-heart-fill",
  },
  { title: "Employment", component: <Employment />, icon: "bi-briefcase-fill" },
  {
    title: "Residental History",
    component: <Residental />,
    icon: "bi-house-fill",
  },
  {
    title: "Documents",
    component: <Documents />,
    icon: "bi-file-earmark-pdf-fill",
  },
  {
    title: "Favorites",
    component: <Favorites />,
    icon: "bi-bookmark-heart-fill",
  },
];

const TenantProfileModal = ({
  show,
  closeHandler,
  loading = true,
  doesProfileExists,
}) => {
  return (
    <Modal show={show} onHide={closeHandler} fullscreen={true}>
      <Modal.Header closeButton />
      <Modal.Body className="p-0">
        {/* <Container className="bg-light p-5 my-5" style={{ maxWidth: 1200 }}>
          <TenantData className="mb-5" />
          <SectionAccordionWrapper defaultActiveKey={[0]} alwaysOpen>
            {sections.map((el, i) => (
              <SectionAccordion
                title={el.title}
                eventKey={i}
                className="my-3"
                icon={el.icon}
              >
                {el.component}
              </SectionAccordion>
            ))}
          </SectionAccordionWrapper>
        </Container> */}
        {!loading && (
          <>
            <Container style={{ maxWidth: 1200 }}>
              <Portfolio
                doesProfileExists={doesProfileExists}
                onlyView={true}
                forceEditMode={false}
                canEdit={false}
              />
            </Container>
          </>
        )}

        {loading && (
          // TODO Loader as Component that takes children and loading prop. Would wrap all Modal Body into 2 components: Loader around Portfolio
          <Row className="m-0 p-0 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col xs={"auto"}>
          <ButtonPrimary title="Close" onClick={closeHandler} />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default TenantProfileModal;

function TenantData({ className = "" }) {
  const [inEditMode, setInEditMode] = useState(false);
  const [name, setName] = useState("John Doe");
  const [gender, setGender] = useState("Male");
  const [age, setAge] = useState("26");
  const [city, setCity] = useState("Norwalk");
  const [state, setState] = useState("CT");
  const [email, setEmail] = useState("johndoe@example.com");
  const [phone, setPhone] = useState("(012) 345-6789");
  const [description, setDescription] =
    useState(`Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
  vel culpa dignissimos, recusandae ducimus numquam dolorum
  accusantium eos, est illum corporis, vitae iste.`);

  return (
    <Card className={`${className}`}>
      <Card.Body>
        {!inEditMode ? (
          <>
            <Row>
              <Col xs={2} className="me-3">
                <LazyImage
                  src={"https://thispersondoesnotexist.com/image"}
                  style={{ objectFit: "cover", height: 200, width: 200 }}
                />
              </Col>
              <Col>
                <p className="fs-6 my-0 text-dark">
                  Name<span className="mx-3">/</span>Gender
                  <span className="mx-3">/</span>Age
                </p>
                <p className="fs-5 text-dark">
                  {name}, {gender}, {age}
                </p>
                <p className="fs-6 my-0 text-dark">
                  City<span className="mx-3">/</span>State{" "}
                </p>
                <p className="fs-5 text-dark">
                  {city}, {state}
                </p>
                <p className="fs-6 my-0 text-dark">
                  Phone Number<span className="mx-3">/</span>Email
                </p>
                <p className="fs-5 text-dark">
                  {phone}
                  <span className="mx-3">/</span>
                  {email}
                </p>
              </Col>
              <Col>
                <p className="fs-6 my-0 text-dark">Self Description:</p>
                <p className="fs-5 text-dark">{description}</p>
              </Col>
            </Row>
            <div className="d-flex mt-3">
              <EditButtons
                inEditMode={inEditMode}
                setInEditMode={setInEditMode}
              />
            </div>
          </>
        ) : (
          <>
            <Row>
              <Col xs={2} className="me-3">
                <LazyImage
                  src={"https://thispersondoesnotexist.com/image"}
                  style={{ objectFit: "cover", height: 200, width: 200 }}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <p className="fs-6 my-0 text-dark">Name</p>
                    <InputText value={name} setValue={setName} />
                  </Col>
                  <Col>
                    <p className="fs-6 my-0 text-dark">Gender</p>
                    <InputText value={gender} setValue={setGender} />
                  </Col>
                  <Col>
                    <p className="fs-6 my-0 text-dark">Age</p>
                    <InputText value={age} setValue={setAge} />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="fs-6 my-0 text-dark">City</p>
                    <InputText value={city} setValue={setCity} />
                  </Col>
                  <Col>
                    <p className="fs-6 my-0 text-dark">State</p>
                    <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                      <select
                        className="form-control border-0 px-0 shadow-none"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      >
                        {STATES.split(",").map((el, i) => (
                          <option key={i} value={el}>
                            {el}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="fs-6 my-0 text-dark">Phone Number</p>
                    <InputText value={phone} setValue={setPhone} />
                  </Col>
                  <Col>
                    <p className="fs-6 my-0 text-dark">E-mail</p>
                    <InputText value={email} setValue={setEmail} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <p className="fs-6 my-0 text-dark">Self Description:</p>
                    <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                      <textarea
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        className="form-control border-0 px-0 shadow-none"
                        style={{ height: "15em" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex mt-3">
              <EditButtons
                inEditMode={inEditMode}
                setInEditMode={setInEditMode}
              />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

function TargetUnit() {
  const [inEditMode, setInEditMode] = useState(false);
  const [city, setCity] = useState("Norwalk");
  const [state, setState] = useState("CT");
  const [priceMin, setPriceMin] = useState(1000);
  const [priceMax, setPriceMax] = useState(2000);
  const [info, setInfo] =
    useState(`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
  iure similique quia omnis, incidunt quas praesentium error, recusandae
  tempore voluptatum possimus perspiciatis, nesciunt provident ea fuga
  pariatur cum voluptas magni!`);

  return (
    <>
      {!inEditMode ? (
        <Row>
          <Col>
            <Row>
              <Col>
                <p className="fs-6 my-0 text-dark">City</p>
                <p className="fs-5 text-dark">{city}</p>
              </Col>
              <Col>
                <p className="fs-6 my-0 text-dark">/ State</p>
                <p className="fs-5 text-dark">{state}</p>
              </Col>
              <Col>
                <p className="fs-6 my-0 text-dark">/ Price range </p>
                <p className="fs-5 text-dark">
                  {priceMin}$- {priceMax}$
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <p className="fs-6 my-0 text-dark">/ Additional Information: </p>
            <p className="fs-5 text-dark">{info}</p>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mx-2 mb-4">
            <Col>
              <p className="fs-6 my-0 text-dark ms-2">City</p>
              <InputText value={city} setValue={setCity} />
            </Col>
            <Col>
              <p className="fs-6 my-0 text-dark ms-2">State</p>
              <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                <select
                  className="form-control border-0 px-0 shadow-none"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {STATES.split(",").map((el, i) => (
                    <option key={i} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col>
              <p className="fs-6 my-0 text-dark ms-2">Price range</p>
              <Row>
                <Col>
                  <InputText
                    type="number"
                    value={priceMin}
                    setValue={setPriceMin}
                  />
                </Col>
                <Col>
                  <InputText
                    type="number"
                    value={priceMax}
                    setValue={setPriceMax}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-2">
            <div className="mx-1">
              <p className="fs-6 my-0 text-dark mb-2 ms-2">
                Additional Information:{" "}
              </p>
              <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                <textarea
                  value={info}
                  onChange={(e) => {
                    setInfo(e.target.value);
                  }}
                  className="form-control border-0 px-0 shadow-none"
                  style={{ height: "15em" }}
                />
              </div>
            </div>
          </Row>
        </>
      )}
      <div className="d-flex mt-3">
        <EditButtons inEditMode={inEditMode} setInEditMode={setInEditMode} />
      </div>
    </>
  );
}

function Employment() {
  const [positions, setPositions] = useState([
    {
      _employer: "Test Company",
      _city: "San Francisco",
      _state: "CA",
      _position: "Software Engineer",
      _time: "2012-2018",
      _description: "Lorem ipsum dolor sit amet...",
    },
  ]);
  return (
    <>
      <h4 className="fw-bold">Current Position(s):</h4>
      <Position
        _employer="Hello World Corporation"
        _city="San Francisco"
        _state="CA"
        _position="Software Engineer"
        _time="2019-Present"
        _description="Lead software engineeer in AI department."
      />
      <h4 className="fw-bold mt-4">Previous Position(s):</h4>
      {positions.map((pos) => (
        <Position {...pos} />
      ))}
      <div className="mx-2">
        <ButtonPrimary className="mt-3 w-100" onClick={() => setPositions(prev => [...prev, { edit: true }])}>Add Position</ButtonPrimary>
      </div>
    </>
  );
}

function Position({ _employer = '', _city = '', _state = '', _position = '', _time = '', _description = '', edit = false }) {
  const [employer, setEmployer] = useState(_employer);
  const [city, setCity] = useState(_city);
  const [state, setState] = useState(_state);
  const [position, setPosition] = useState(_position);
  const [time, setTime] = useState(_time);
  const [description, setDescription] = useState(_description);
  const [inEditMode, setInEditMode] = useState(edit);

  return (
    <>
      {!inEditMode ? (
        <Row className="m-0 p-0 pt-3 ms-5">
          <Col xs={12} md={6} className="m-0 p-0">
            <p className="my-1 ">
              <b>Employer: </b>
              {employer}
            </p>
            <p className="my-1 ">
              <b>Location: </b>
              {city}, {state}
            </p>
          </Col>
          <Col xs={12} md={6} className="m-0 p-0">
            <p className="my-1 ">
              <b>Position: </b>
              {position}
            </p>
            <p className="my-1 ">
              <b>Time at job: </b>
              {time}
            </p>
          </Col>
          <Col xs={12} className="m-0 p-0">
            <p className="my-1 ">
              <b>Job Description: </b>
              {description}
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="m-0 p-0 pt-3">
          <Col xs={12} md={6} className="m-0 p-0 pe-3">
            <p className="my-1 ">
              <b>Employer: </b>
            </p>
            <InputText value={employer} setValue={setEmployer} />
            <p className="my-1 ">
              <b>Location: </b>
            </p>
            <Row>
              <Col>
                <InputText value={city} setValue={setCity} />
              </Col>
              <Col>
                <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                  <select
                    className="form-control border-0 px-0 shadow-none"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {STATES.split(",").map((el, i) => (
                      <option key={i} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="m-0 p-0">
            <p className="my-1 ">
              <b>Position: </b>
            </p>
            <InputText value={position} setValue={setPosition} />
            <p className="my-1 ">
              <b>Time at job: </b>
            </p>
            <InputText value={time} setValue={setTime} />
          </Col>
          <Col xs={12} className="m-0 p-0">
            <p className="my-1 ">
              <b>Job Description: </b>
            </p>
            <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3  mb-3">
              <textarea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className="form-control border-0 px-0 shadow-none"
                style={{ height: "15em" }}
              />
            </div>
          </Col>
        </Row>
      )}
      <div className="d-flex">
        <EditButtons inEditMode={inEditMode} setInEditMode={setInEditMode} />
      </div>
    </>
  );
}

function Residental() {
  const [landlords, setLandlords] = useState([{
    name: "John Doe",
    time: "2 years",
    phone: "(000) 000-0000",
    description: "Everything was fine...",
  }])
  const [evictions, setEvictions] = useState([{
    date: "2020",
    descritption: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, deleniti."
  }])
  return (
    <>
      <div className="mx-1">
        <Row>
          <Col>
            <h4 className="fw-bold">Former Landlords:</h4>
            {landlords.map(l => <Landlord {...l} />)}
          </Col>
        </Row>
        <div className="mx-2 mb-4">
          <ButtonPrimary className="mt-3 w-100" onClick={() => setLandlords(prev => [...prev, { edit: true }])}>Add Landlord</ButtonPrimary>
        </div>
        <Row>
          <Col>
            <h4 className="fw-bold">Previous Evictions:</h4>
            {evictions.map(e => <Eviction{...e} />)}
          </Col>
        </Row>
        <div className="mx-2 mb-4">
          <ButtonPrimary className="mt-3 w-100" onClick={() => setEvictions(prev => [...prev, { edit: true }])}>Add Eviction</ButtonPrimary>
        </div>
      </div>
    </>
  );
}

function Eviction({ date: _date = '', descritption: _description = '', edit = false }) {
  const [inEditMode, setInEditMode] = useState(edit);
  const [date, setDate] = useState(_date)
  const [description, setDescription] = useState(_description)
  return (
    <>
      {!inEditMode ? (
        <div className="ms-5">
          <p>
            <span className="fw-bold me-1">Date:</span> {date}
          </p>
          <p>{description}</p>
        </div>
      ) : (
        <div className="ms-5">
          <Row>
            <Col>
              Date:
              <InputText value={date} setValue={setDate} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                <textarea
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  className="form-control border-0 px-0 shadow-none"
                  style={{ height: "15em" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="d-flex mt-3">
        <EditButtons inEditMode={inEditMode} setInEditMode={setInEditMode} />
      </div>
    </>
  )
}

function Landlord({ name: _name, time: _time, phone: _phone, description: _description, edit = false }) {
  const [inEditMode, setInEditMode] = useState(edit);
  const [name, setName] = useState(_name);
  const [time, setTime] = useState(_time);
  const [phone, setPhone] = useState(_phone);
  const [description, setDescription] = useState(_description);

  return (
    <>
      {!inEditMode ? (
        <div className="ms-5">
          <p>
            <span className="fw-bold">{name}:</span> {time}, {phone}
          </p>
          <p>{description}</p>
        </div>
      ) : (
        <div className="ms-5">
          <Row>
            <Col>
              Name:
              <InputText value={name} setValue={setName} />
            </Col>
            <Col>
              Time:
              <InputText value={time} setValue={setTime} />
            </Col>
            <Col>
              Phone:
              <InputText value={phone} setValue={setPhone} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
                <textarea
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  className="form-control border-0 px-0 shadow-none"
                  style={{ height: "15em" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="d-flex mt-3">
        <EditButtons inEditMode={inEditMode} setInEditMode={setInEditMode} />
      </div>
    </>
  );
}

function Documents() {
  return (
    <div className="mx-2">
      <DocumentFile
        docName={"TestFile.docx"}
        docDescription="Document Description Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Qui voluptate dolor corporis, repudiandae nulla ea
          voluptatem, cum possimus porro numquam placeat nihil saepe, harum
          aut veniam odit est suscipit ipsa."
      />
      <DocumentFile
        docName={"TestFile.pdf"}
        docDescription="Document Description Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Qui voluptate dolor corporis, repudiandae nulla ea
          voluptatem, cum possimus porro numquam placeat nihil saepe, harum
          aut veniam odit est suscipit ipsa."
      />
      <ButtonPrimary>Upload</ButtonPrimary>
    </div>
  );
}

function DocumentFile({ docName, docDescription }) {
  return (
    <Row className="my-4">
      <Col xs="2" className="d-flex">
        <p className="fw-bold ">{docName}</p>
        <p className="ms-auto fw-bold me-2">|</p>
      </Col>
      <Col>
        <Row>{docDescription}</Row>
        <Row className="justify-content-end">
          <ButtonPrimary className="mx-2 w-25 mt-4">
            Download
            <i className="bi bi-file-earmark-arrow-down ms-3"></i>
          </ButtonPrimary>
          <ButtonPrimary className="mx-2 w-auto mt-4 bg-danger border-0">
            <i className="bi bi-x-lg"></i>
          </ButtonPrimary>
        </Row>
      </Col>
    </Row>
  );
}

function Favorites() {
  return (
    <div className="">
      <FavoriteUnit />
      <FavoriteBuilding />
      <FavoriteBuilding />
      <FavoriteUnit />
      <FavoriteQuery />
    </div>
  );
}

function FavoriteItem({ icon, name, address, additional }) {
  return (
    <Row className="align-items-stretch mb-3">
      <Col xs="auto" className="align-items-center d-flex">
        <i className={`bi ${icon} fs-1 my-auto`}></i>
      </Col>
      <Col>
        <p className="d-inline p-0 m-0 text-dark cursor-pointer fw-bold fs-4">
          {name}
        </p>
        <p className="m-0 p-0 text-muted">
          <i className="bi bi-geo-alt me-2"></i>
          {address}
        </p>
        <p className="mb-0">{additional}</p>
      </Col>
      <Col xs="auto" className="align-items-center d-flex">
        <Row className="justify-content-end">
          <div className="">
            <ButtonPrimary className="mx-2 mt-4">Open</ButtonPrimary>
            <ButtonPrimary className="mx-2 w-auto mt-4 bg-danger border-0">
              <i className="bi bi-x-lg"></i>
            </ButtonPrimary>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

function FavoriteUnit({
  name = "C201",
  address = "55 N Water St, Norwalk CT",
  additional = "Rent: 2150$",
}) {
  return (
    <FavoriteItem
      icon="bi-house-heart"
      name={`Unit: ${name}`}
      address={address}
      additional={additional}
    />
  );
}

function FavoriteBuilding({
  name = "St Gregorys Apartments",
  address = "55 N Water St, Norwalk CT",
  additional = "Rent: 2150$",
}) {
  return (
    <FavoriteItem
      icon="bi-building"
      name={`Property: ${name}`}
      address={address}
      additional={additional}
    />
  );
}
function FavoriteQuery({
  name = "Price: from 2000$ to 2200$",
  address = "Norwalk CT",
  additional = "Bedrooms: from 0 to 5 | Bathrooms: from 2 to 4",
}) {
  return (
    <FavoriteItem
      icon="bi-list-stars"
      name={`Unit: ${name}`}
      address={address}
      additional={additional}
    />
  );
}

function EditButtons({ inEditMode, setInEditMode }) {
  return (
    <div className="ms-auto">
      {inEditMode && (
        <ButtonSecondary className="me-3" onClick={() => setInEditMode(false)}>
          Cancel
        </ButtonSecondary>
      )}
      <ButtonPrimary
        className="fw-bold px-4 me-2"
        onClick={() => setInEditMode((prev) => !prev)}
      >
        {!inEditMode ? "Edit" : "Submit"}
      </ButtonPrimary>
    </div>
  );
}

function InputText({ value, setValue, type = "text", ...props }) {
  return (
    <div className="d-flex align-items-center input rounded shadow-sm border border-wissp-light-gray px-3">
      <input
        type={type}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="form-control border-0 px-0 shadow-none"
        {...props}
      />
    </div>
  );
}

function SectionAccordionWrapper({ children, defaultActiveKey, ...props }) {
  const [opened, setOpened] = useState(defaultActiveKey);
  return (
    <Accordion
      onSelect={(e) => {
        setOpened(e);
      }}
      defaultActiveKey={defaultActiveKey}
      {...props}
    >
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            isOpen: opened.includes(child.props.eventKey),
          })
        )}
    </Accordion>
  );
}
