import React from 'react';
import {
    Row
} from 'react-bootstrap';

export default function StreetViewLoad() {
  return (
      <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
          <h3>Loading...</h3>
      </Row>
  )
}
