import { 
    object as YupObject, 
    string as YupString, 
    number as YupNumber,
    array as YupArray 
} from "yup";

const schema = ({BioLength=500}) => YupObject().shape({
    // BASIC INFORMATION
    FirstName: YupString()
        .transform(value => value.trim())
        .required('First name field is required'),
    LastName: YupString()
        .transform(value => value.trim())
        .required('Last name field is required'),
    City: YupString()
        .transform(value => value.trim())
        .required('City field is required'),
    State: YupString()
        .transform(value => value.trim())
        .required('State field is required'),
    Age: YupNumber()
        .transform(value => (isNaN(value) ? undefined: value))
        .min(16, 'You must be at least 16 years old')
        .required('Age field is required'),
    Gender: YupString()
        .transform(value => value.trim())
        .required('Gender field is required'),
    MaritalStatus: YupString()
        .transform(value => value.trim())
        .required('Relationship field is required'),
    ChildrenInfo: YupNumber()
        .transform(value => (isNaN(value) ? undefined: value))
        .min(0, 'Value has to be equal or greater than 0')
        .required('Children field is required'),
    Phone: YupString()
        .required("Phone number field is required")
        .transform(value => value.trim())
        .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
            "Provide a valid phone number"),
    Email: YupString()
        .transform(value => value.trim())
        .email('Invalid email address')
        .required('Email field is required'),
    Bio: YupString().test('len', `Bio Must be less than or exactly ${BioLength} characters!`, val => val.length <= BioLength),
    // EMPLOYMENT
    Employments: YupArray().of(
        YupObject().shape({
            Employer: YupString().required('Employer field is required'),
            Position: YupString().required('Position field is required'),
            Location: YupString().required('Location field is required'),
            JobStart: YupString().transform(value => value.trim()).required('Job Start field is requried'),
            JobEnd: YupString().transform(value => value.trim()).required('Job End field is required'),
            Description: YupString().notRequired(),
        })
    ),
    // RESIDENTIAL HISTORY
    // FORMER LANDLORDS
    FormerLandlords: YupArray().of(
        YupObject().shape({
            Name: YupString()
                .required('Landlord name is required')
                .transform(value => value.trim()),
            Duration: YupString()
                .required("Specify how long you lived at this place.")
                .transform(value => value.trim()),
            Number: YupString()
                .required("Landlord phone number is required")
                .transform(value => value.trim())
                .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
                    "Provide a valid phone number"),
            Description: YupString()
                .transform(value => value.trim()),
        })
    ),
    // PREVIOUS EVICTIONS
    PreviousEvictions: YupArray().of(
        YupObject().shape({
            Date: YupString().required('Approximate date field is required'),
            Description: YupString().notRequired(),
        })
    )
});

export default schema;
