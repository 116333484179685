import { getRequest, postPutRequest, deleteRequest } from './http-common';
import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../constants/client';

const serviceGetClients = async (token) => {
    const URL = `command/client`;

    return await getRequest(URL, token);
}


const serviceAddClient = async (data, token) => {
    const URL = `command/client`;

    // postBody = {
    //     CLIENT_FIRST_NAME: null,
    //     CLIENT_LAST_NAME: null,
    //     CLIENT_MOBILE: null,
    //     CLIENT_EMAIL: null
    // }

    return await postPutRequest(URL, data, token, 'post');
}

const serviceEditClient = async (data, token) => {
    const URL = `command/client/edit`;

    // postBody = {
    //     CLIENT_ID: null, 
    //     CLIENT_FIRST_NAME: null,
    //     CLIENT_LAST_NAME: null,
    //     CLIENT_MOBILE: null,
    //     CLIENT_EMAIL: null
    // }

    return await postPutRequest(URL, data, token, 'post');
}

const serviceDeleteClient = async (clientID, token) => {
    const URL = `command/client/${clientID}`;

    return await deleteRequest(URL, '', token);
}

export { 
    serviceGetClients,
    serviceAddClient,
    serviceEditClient,
    serviceDeleteClient
};
