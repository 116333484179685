export const NAME = 'name';
export const SQ_FOOTAGE = 'squareFootage';
export const BEDROOM_COUNT = 'bedroomCount';
export const BATHROOM_COUNT = 'bathroomCount';
export const FLOOR_ID = 'floorID';
export const RENT = 'rent';
export const PAYMENT_DETAILS = 'paymentDetails';
export const PAYMENT_DUE_DATE = 'paymentDueDate';
export const VACANT = 'vacant';
export const OCCUPANT_FULL_NAME = 'occupantFullName';
export const OCCUPANT_EMAIL = 'occupantEmail';
export const OCCUPANT_PHONE_NUMBER = 'occupantPhoneNumber';
export const OCCUPANT_LEASE_START = 'occupantLeaseStart';
export const OCCUPANT_LEASE_END = 'occupantLeaseEnd';
export const OCCUPANT_NOTES = 'occupantNotes';
export const MISC_AMENITIES = 'miscAmenities';
export const MISC_ACCESSIBILITIES = 'miscAccessibilities';
export const MISC_NOTES = 'miscNotes';
