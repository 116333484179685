import { createSlice } from '@reduxjs/toolkit';
import { CLIENT } from '../../components/Clients/AddClientModal';

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../../constants/client';
import { NOTIFICATION_ID } from '../../constants/notification';


const initialState = {
    groups: [
        // {
        //     [GROUP_NAME]: "HVC",
        //     [GROUP_DESC]: "This is group description...",
        //     [CLIENTS]: [ 'd0dcbb99-f1b1-4a5e-805d-c05e6b093mnm', 'd0dcbb99-f1b1-4a5e-805d-c05e6b093fb3' ],
        //     [GROUP_ID]: 1
        // },
        // {
        //     [GROUP_NAME]: "Test",
        //     [GROUP_DESC]: "This is group description...",
        //     [CLIENTS]: [ 'd0dcbb99-f1b1-4a5e-805d-c05e6b093mnm' ],
        //     [GROUP_ID]: 2
        // },
        // {
        //     [GROUP_NAME]: "Another Group",
        //     [GROUP_DESC]: "This is group description...",
        //     [CLIENTS]: [ 'd0dcbb99-f1b1-4a5e-805d-c05e6b093mnm' ],
        //     [GROUP_ID]: 3
        // },
    ],
    clients: [
        // {
        //     "clientName": "John Doe",
        //     "clientEmail": "test@test.com",
        //     "clientPhone": "(000) 000-0000",
        //     "clientGender": "Male",
        //     "clientAge": "32",
        //     "clientCity": "Boston",
        //     "clientState": "MA",
        //     "clientType": "Tenant",
        //     "clientNotes": "up to $1000",
        //     "clientGroup": [],
        //     "id": "d0dcbb99-f1b1-4a5e-805d-c05e6b0923g"
        // },
        // {
        //     "clientName": "Mio Fen",
        //     "clientEmail": "test1@test.com",
        //     "clientPhone": "(000) 000-0000",
        //     "clientGender": "Female",
        //     "clientAge": "21",
        //     "clientCity": "Boston",
        //     "clientState": "MA",
        //     "clientType": "Tenant",
        //     "clientNotes": "up to $1000",
        //     "clientGroup": [1],
        //     "clientVerified": true,
        //     "id": "d0dcbb99-f1b1-4a5e-805d-c05e6b093fb3"
        // },
        // {
        //     "clientName": "Eric Smith",
        //     "clientEmail": "test2@test.com",
        //     "clientPhone": "(000) 000-0000",
        //     "clientGender": "Other",
        //     "clientAge": "44",
        //     "clientCity": "Boston",
        //     "clientState": "MA",
        //     "clientType": "Landlord",
        //     "clientNotes": "up to $1000",
        //     "clientGroup": [1, 2, 3],
        //     "id": "d0dcbb99-f1b1-4a5e-805d-c05e6b093mnm"
        // },
    ],
    notifications: [],
    notificationFormDefaultValues: null,
    wereNotificationsDownloaded: false,
    wereGroupsDownloaded: false,
    wereClientsDownloaded: false
}

export const caseManagerSlice = createSlice({
    name: 'caseManagerStore',
    initialState,
    reducers: {
        setWereNotificationsDownloaded(state, action) {
            state.wereNotificationsDownloaded = action.payload;
        },
        setWereGroupsDownloaded(state, action) {
            state.wereGroupsDownloaded = action.payload;
        },
        setWereClientsDownloaded(state, action) {
            state.wereClientsDownloaded = action.payload;
        },
        setGroups(state, action) {
            state.groups = action.payload;
        },
        setClients(state, action) {
            state.clients = action.payload;
        },
        setNotificationFormDefaultValues(state, action) {
            state.notificationFormDefaultValues = action.payload;
        },
        setNotifications(state, action) {
            state.notifications = action.payload;
        },
        deleteClientFromGroup(state, action) {
            state.groups = state.groups.map( group => {
                if(group[GROUP_ID] === action.payload[GROUP_ID]) {

                    return {
                        ...group,
                        [CLIENTS]: group[CLIENTS].filter( clientID => clientID !== action.payload[CLIENT_ID] )
                    }

                }
                return group;
            })
        },
        addClientToGroup(state, action) {
            state.groups = state.groups.map( group => {
                if(group[GROUP_ID] === action.payload[GROUP_ID]) {

                    return {
                        ...group,
                        [CLIENTS]: [...group[CLIENTS], action.payload[CLIENT_ID]]
                    }

                }
                return group;
            })
        },
        addNotification(state, action){
            if(Array.isArray(state.notifications)) {
                state.notifications = [...state.notifications, action.payload];
            } else {
                state.notifications = [action.payload];
            }
        },
        editNotification(state, action){
            state.notifications = state.notifications.map( notification => {
                if(String(notification[NOTIFICATION_ID]) === String(action.payload[NOTIFICATION_ID])) {
                    return {...notification, ...action.payload};
                }
                return notification;
            })
        },
        addClient(state, action) {
            state.clients = [...state.clients, action.payload];

            state.groups = state.groups.map( group => {
                if(action.payload[CLIENT.CLIENT_GROUP].includes(group[GROUP_ID])) {
                    let groupOfClients = [];
                    if(Array.isArray(group[CLIENTS])) {
                        groupOfClients = group[CLIENTS];
                    }
                    return {
                        ...group,
                        [CLIENTS]: [...groupOfClients, action.payload[CLIENT.CLIENT_ID]]
                    }
                }
                return group;
            })

        },
        editClient(state, action) {
            state.clients = state.clients.map( client => {
                if(String(client[CLIENT.CLIENT_ID]) === String(action.payload[CLIENT.CLIENT_ID])) {
                    return action.payload;
                }
                return client;
            });

            // TODO: uncomment when Group property will be added to the endpoint 
            // state.groups = state.groups.map( group => {
            //     if( action.payload[CLIENT.CLIENT_GROUP].includes(group[GROUP_ID]) ) {

            //         if(group[CLIENTS].includes( action.payload[CLIENT.CLIENT_ID] )) {
            //             return group;
            //         } else {
            //             let groupOfClients = [];
            //             if(Array.isArray(group[CLIENTS])) {
            //                 groupOfClients = group[CLIENTS];
            //             }
            //             return {
            //                 ...group,
            //                 [CLIENTS]: [...groupOfClients, action.payload[CLIENT.CLIENT_ID]]
            //             }
            //         }

            //     } else {
            //         return {
            //             ...group,
            //             [CLIENTS]: group[CLIENTS].filter( clientId => clientId !== action.payload[CLIENT.CLIENT_ID])
            //         }
            //     }
            // })
        },
        deleteClient(state, action) {
            state.clients = state.clients.filter( client => String(client[CLIENT.CLIENT_ID]) !== String(action.payload));

            // TODO: uncomment when Group property will be added to the endpoint 
            // state.groups = state.groups.map( group => {
            //     if(action.payload[CLIENT.CLIENT_GROUP].includes(group[GROUP_ID])) {
            //         return {
            //             ...group,
            //             [CLIENTS]: group[CLIENTS].filter( clientId => clientId !== action.payload[CLIENT.CLIENT_ID])
            //         }
            //     }
            //     return group;
            // })
        },
        addGroup(state, action) {
            state.groups = [...state.groups, action.payload]

            // TODO: uncomment when Group property will be added to the endpoint 
            // if(action.payload[CLIENTS]) {
            //     state.clients = state.clients.map( client => {
            //         if(action.payload[CLIENTS].includes(client[CLIENT.CLIENT_ID])) {
            //             return {
            //                 ...client,
            //                 [CLIENT.CLIENT_GROUP]: [...client[CLIENT.CLIENT_GROUP], action.payload[GROUP_ID]]
            //             }
            //         }
            //         return client;
            //     })
            // }
        },
        editGroup(state, action) {
            // TODO: uncomment when Group property will be added to the endpoint 
            // state.clients = state.clients.map( client => {
            //     if(action.payload[CLIENTS].includes(client[CLIENT.CLIENT_ID])) {
            //         return {
            //             ...client,
            //             [CLIENT.CLIENT_GROUP]: [...client[CLIENT.CLIENT_GROUP], action.payload[GROUP_ID]]
            //         }
            //     } else {
            //         return {
            //             ...client,
            //             [CLIENT.CLIENT_GROUP]: client[CLIENT.CLIENT_GROUP].filter( groupId => groupId !== action.payload[GROUP_ID])
            //         }
            //     }
            // })

            state.groups = state.groups.map( group => {
                if(String(group[GROUP_ID]) === String(action.payload[GROUP_ID])) {
                    return action.payload;
                }
                return group;
            });
        },
        deleteGroup(state, action) {
            state.groups = state.groups.filter( group => String(group[GROUP_ID]) !== String(action.payload));
 // TODO: uncomment when Group property will be added to the endpoint 
            // state.clients = state.clients.map( client => {
            //     if( client[CLIENT.CLIENT_GROUP].includes(action.payload) ) {
            //         return {
            //             ...client,
            //             [CLIENT.CLIENT_GROUP]: client[CLIENT.CLIENT_GROUP].filter( groupId => String(groupId) !== String(action.payload))
            //         }
            //     }
            //     return client;
            // })
        },
    }
});

export const caseManagerActions = caseManagerSlice.actions;
export default caseManagerSlice.reducer;
