export default function FavoriteButton({
  isFavorite,
  favoriteHandler,
  id,
  type,
  title = "Save as Favorite",
}) {
  function handleClick() {
    if (isFavorite) {
      favoriteHandler(id, type, "delete");
    } else {
      favoriteHandler(id, type, "add");
    }
  }
  return (
    <div className="w-auto">
      <div
        className="d-inline-flex align-items-center btn btn-primary white py-0 fav-button"
        onClick={handleClick}
      >
        <div className="text-start btn btn-primary colorWhite p-2">
          {/* <span className="p-0 m-0 me-2 ">{title}</span> */}
          {title}
        </div>
        <i
          className={`p-1 bi ${
            isFavorite ? "bi-star-fill text-warning" : "bi-star"
          }  fs-5 cursor-pointer btn btn-primary w-100`}
        ></i>
      </div>
    </div>
  );
}
