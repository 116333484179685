import React, { useState, useEffect, useMemo } from "react";
import { Container, Button, Row, Col, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useQuery from '../../hooks/useQuery';

import ModalFeatures from "./ModalFeatures";
import { Range } from "rc-slider";
import 'rc-slider/assets/index.css';

import { yupResolver } from "@hookform/resolvers/yup";
import {
	object as YupObject,
	string as YupString
} from "yup";

import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";
import HistoryBoxQuery from "./HistoryBoxQuery";
import MiniSearch from "./MiniSearch";
import Backdrop from "../Shared/Backdrop";
import ButtonSecondary from "../Shared/Buttons/ButtonSecondary";

const rangeRootStyles = {
	width: "100%",
	/* height: "100%", */
	padding: 0,
	margin: 0,
	// border: '2px solid black'
}

const trackStyles = [
	{
		background: "#10306C",
		height: "36px",
		borderRadius: 4,
	},
]

const railStyles = {
	height: 36,
	background: "white",
	borderRadius: 4,
	// border: "1px solid #ced4da",
	boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%)",
	left: 1

}

const dotStyles = {
	margin: 0,
	padding: 0,
	height: 28,
	borderRadius: 0,
	width: 4,
	border: "none",
	top: 4,
	background: "#d8d8d8",
}

const activeDotStyles = {
	background: "#f8f8f8",
	// margin: 0,
	// padding: 0,
	// border: "solid 1px white",
	// borderRadius: 0,
	// height: 32,
	// top: 3,
	// width: 2,
}

const handleStyles =
	[
		{
			marginTop: 0,
			border: "solid 2px #10306C",
			borderRadius: '0.25rem',
			height: 36,
			width: '0.75rem'

		},
		{
			marginTop: 0,
			border: "solid 2px #10306C",
			borderRadius: '0.25rem',
			height: 36,
			width: '0.75rem'

		}
	];

const schema = YupObject().shape({
	state: YupString()
		.notRequired(),
	city: YupString()
		.when("state", (state) => {
			if (state) {
				return YupString()
					.required("City is required!")
			}
		}),
	zip: YupString()
		.when("city", {
			is: undefined,
			then: YupString()
				.required("City or ZIP is required!")
				.transform(value => value)
				.matches(/^[0-9]+$/, "Must be only digits")
				.min(5, "Must be exactly 5 digits")
				.max(5, "Must be exactly 5 digits")
			,
		})
})


const MarketplaceSearchMobile = ({
	hasUserSearched,
	states,
	cities,
	setCities,
	getCitiesForSelectedState,
	isCitiesLoaded,
	setIsCitiesLoaded,
	features,
	loading,
	isMobile,
	showSearchModal,
	setShowSearchModal,
	setParams,
	params,
	searchAnimTime,
	searchAnimDelay,
}) => {



	let query = useQuery();
	const qMinBedroomCount = query.get("MinBedroomCount") || 0;
	const qMaxBedroomCount = query.get("MaxBedroomCount") || 5;
	const qMinBathroomCount = query.get("MinBathroomCount") || 0;
	const qMaxBathroomCount = query.get("MaxBathroomCount") || 5;
	const qMinRent = query.get("MinRentPrice") || '';
	const qMaxRent = query.get("MaxRentPrice") || '';
	const qBuildingAmenityIDs = query.getAll("BuildingAmenityIDs");
	const qBuildingAccessibilityIDs = query.getAll("BuildingAccessibilityIDs");
	const qUnitAmenityIDs = query.getAll("UnitAmenityIDs");
	const qUnitAccessibilityIDs = query.getAll("UnitAccessibilityIDs");
	const qZip = query.get("Zip");
	const qCity = query.get("City");
	const qState = query.get("State");

	const qBuildingID = query.get("BuildingID");
	const qUnitID = query.get("UnitID");

	const [maxBedrooms, setMaxBedrooms] = useState(qMaxBedroomCount ? qMaxBedroomCount : 5);
	const [minBedrooms, setMinBedrooms] = useState(qMinBedroomCount ? qMinBedroomCount : 0);
	const [maxBathrooms, setMaxBathrooms] = useState(qMaxBathroomCount ? qMaxBathroomCount : 5);
	const [minBathrooms, setMinBathrooms] = useState(qMinBathroomCount ? qMinBathroomCount : 0);
	const [isCity, setIsCity] = useState(true);

	useEffect(() => {

		if (qState && qState !== "null" && states) {
			reset({
				'city': qCity,
				'state': qState,
				'zip': undefined,
				'minRent': qMinRent,
				'maxRent': qMaxRent
			})
		}

	}, [qState, states])

	const [show, setShow] = useState(false);

	const handleShow = () => setShow(true);

	const formOptions = { resolver: yupResolver(schema), mode: "all" };
	const { register, clearErrors, handleSubmit, watch, setValue, reset, formState } = useForm(formOptions);
	const { errors } = formState;

	const ZipValue = watch("zip", undefined);
	const StateValue = watch("state", undefined);
	const CityValue = watch("city", undefined);
	const [showExpandedMenu, setShowExpandedMenu] = useState(true);

	const searchHandler = () => {
		handleSubmit(onSubmit)();
	}

	useEffect(() => {

		if (ZipValue) {
			setValue('city', undefined);
			setValue('state', undefined);
			setCities([]);
			setIsCitiesLoaded(false);
		}

	}, [ZipValue, setValue]);

	useEffect(() => {

		if (StateValue) {
			getCitiesForSelectedState(StateValue);
		}

		if (!!StateValue) {
			setValue('zip', undefined);
			clearErrors('zip');
		}

	}, [StateValue])

	let history = useHistory();

	useEffect(() => {
		if (qCity || qZip) {
			const data = {
				zip: qZip,
				city: qCity,
				state: qState,
				minRent: qMinRent,
				maxRent: qMaxRent
			}
			onSubmit(data, true)
		}
	}, []);

	const [showHistoryModal, setShowHistoryModal] = useState(false);
	const [localSearchHistory, setLocalSearchHistory] = useState(null);
	useEffect(() => {
		const marketplaceHistory = localStorage.getItem("marketplaceHistory");
		if (marketplaceHistory) {
			setLocalSearchHistory(JSON.parse(marketplaceHistory));
		}
	}, []);

	const loadFavoriteData = (data) => {
		const preparedData = {
			city: data.City,
			state: data.State,
			minRent: data.MinRentPrice,
			maxRent: data.MaxRentPrice
		};

		setMaxBedrooms(data.MaxBedroomCount);
		setMinBedrooms(data.MinBedroomCount);
		setMaxBathrooms(data.MaxBathroomCount);
		setMinBathrooms(data.MinBathroomCount);
		setSelectedFeatures({
			building: { amenities: data.BuildingAmenityIDs || [], accessibilities: data.BuildingAccessibilityIDs || [] },
			unit: { amenities: data.UnitAmenityIDs || [], accessibilities: data.UnitAccessibilityIDs || [] }
		})



		// setIsCity();

		setTimeout(() => onSubmit(preparedData, false), 0);
		// onSubmit(preparedData, false)

		setShowHistoryModal(false);
	}

	const deleteSelectedSearchFromLocalHistory = (index) => {
		setLocalSearchHistory(prevData => prevData.filter((_, localIndex) => localIndex !== index));
		localStorage.setItem("marketplaceHistory", JSON.stringify(JSON.parse(localStorage.getItem("marketplaceHistory")).filter((_, localIndex) => localIndex !== index)));
	}

	const saveSearchDataInLocalStorage = (searchData, firstSearch) => {
		const marketplaceHistory = localStorage.getItem("marketplaceHistory");
		if (marketplaceHistory) {
			const marketplaceHistoryDecoded = JSON.parse(marketplaceHistory).splice(0, 4);
			if (firstSearch) {
				const preparedSearchHistory = [{ ...searchData, BuildingID: qBuildingID, UnitID: qUnitID, TimeStamp: new Date() }, ...marketplaceHistoryDecoded];
				localStorage.setItem("marketplaceHistory", JSON.stringify(preparedSearchHistory));
				setLocalSearchHistory(preparedSearchHistory);
			} else {
				const preparedSearchHistory = [{ ...searchData, BuildingID: null, UnitID: null, TimeStamp: new Date() }, ...marketplaceHistoryDecoded];
				localStorage.setItem("marketplaceHistory", JSON.stringify(preparedSearchHistory));
				setLocalSearchHistory(preparedSearchHistory);
			}

		} else {
			if (firstSearch) {
				const preparedSearchHistory = [{ ...searchData, BuildingID: qBuildingID, UnitID: qUnitID, TimeStamp: new Date() }];
				localStorage.setItem("marketplaceHistory", JSON.stringify(preparedSearchHistory));
				setLocalSearchHistory(preparedSearchHistory);
			} else {
				const preparedSearchHistory = [{ ...searchData, BuildingID: null, UnitID: null, TimeStamp: new Date() }];
				localStorage.setItem("marketplaceHistory", JSON.stringify(preparedSearchHistory));
				setLocalSearchHistory(preparedSearchHistory);
			}
		}
	}

	//TODO: refactor this function, so it won't be using global variables!
	// it will help with local search history bug
	const onSubmit = (unitsData, firstSearch = false) => {
		const {
			city,
			state,
			minRent,
			maxRent,
			zip
		} = unitsData;

		const prepareSearchData = {
			MinBedroomCount: minBedrooms >= 0 ? minBedrooms : qMinBedroomCount || 0,
			MaxBedroomCount: maxBedrooms >= 0 ? maxBedrooms : qMaxBedroomCount || 0,
			MinBathroomCount: minBathrooms >= 0 ? minBathrooms : qMinBathroomCount || 0,
			MaxBathroomCount: maxBathrooms >= 0 ? maxBathrooms : qMaxBathroomCount || 0,
			MinRentPrice: minRent >= 0 ? minRent : qMinRent || 0,
			MaxRentPrice: maxRent >= 0 ? maxRent : qMaxRent || 0,

			BuildingAmenityIDs: selectedFeatures.building.amenities ? selectedFeatures.building.amenities : qBuildingAmenityIDs,
			BuildingAccessibilityIDs: selectedFeatures.building.accessibilities ? selectedFeatures.building.accessibilities : qBuildingAccessibilityIDs,
			UnitAmenityIDs: selectedFeatures.unit.amenities ? selectedFeatures.unit.amenities : qUnitAmenityIDs,
			UnitAccessibilityIDs: selectedFeatures.unit.accessibilities ? selectedFeatures.unit.accessibilities : qUnitAccessibilityIDs
		}

		if (zip) {
			prepareSearchData.Zip = zip
			fetch(`https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zip}%7Ccountry:US&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=EN`)
				.then(res => res.json())
				.then(data => {
					// console.log(data.results.address_components.length)
					// console.log({
					// 	userId: localStorage.getItem('userEmail'),
					// 	state: data.results[0].address_components[2].long_name,
					// 	city: data.results[0].address_components[1].long_name
					// })
				})
				.catch(err => console.warn(err))
		}

		if (city) {
			prepareSearchData.City = city
			prepareSearchData.State = state
			// console.log({
			// 	userId: localStorage.getItem('userEmail'),
			// 	state: prepareSearchData.State,
			// 	city: prepareSearchData.City
			// })
		}





		



		saveSearchDataInLocalStorage(prepareSearchData, firstSearch);

		setShowExpandedMenu(false);
		if (firstSearch) {
			setParams({ ...prepareSearchData, BuildingID: qBuildingID, UnitID: qUnitID })
		} else {
			setParams({ ...prepareSearchData, BuildingID: null, UnitID: null })
		}

	};

	useEffect(() => {
		if (params) {
			// console.log([localStorage.getItem('userEmail'), {
			// 	'state': params.State,
			// 	'city': params.City
			// }])
			// if(hotjar.initialized()){
			// 	hotjar.identify(localStorage.getItem('userEmail'), {
			// 		'state': params.State,
			// 		'city': params.City
			// 	});
			// }
			return history.replace({
				pathname: "/marketplace",
				search: new URLSearchParams(params).toString()
			})
		}
	}, [params])

	const toogleLocation = (state) => {
		setIsCity(state);
	}

	const form = (whiteBg = false) => {
		return (
			<Form
				onSubmit={handleSubmit(onSubmit)}
				className="m-0 p-0"
			>
				<Row className={`m-0 p-3 px-md-3 rounded d-flex flex-column flex-md-row flex-nowrap justify-content-center ${whiteBg ? "bg-light" : "marketplace__search-bg"}`}>
					{/* ZIP or City switch */}
					<Col className="m-0 p-0 pe-md-3 d-flex flex-column aling-items-center justify-content-start" xs={12} md={'auto'}>
						<Row className="m-0 p-0 mb-2 d-md-none" style={{ height: 36 }}>
							<Form.Label column sm={12} className="m-0 p-0 text-center w-100 h-100 fw-bold rounded shadow-sm border-0 overflow-hidden  " style={{ boxShadow: '0 0 1px 0px #10306c' }} >
								{/* Location */}

								<Row className="m-0 align-items-center justify-content-center h-100 " >
									<Col className={`m-0 p-0 d-flex justify-content-center align-items-center h-100 cursor-pointer text-center fw-bold ${isCity ? 'bg-primary text-light' : 'bg-white'}`}
										onClick={() => toogleLocation(true)}
									>
										City
									</Col>

									<Col className={`m-0 p-0 d-flex justify-content-center align-items-center h-100 cursor-pointer text-center fw-bold ${!isCity ? 'bg-primary text-light' : 'bg-white'}`}
										onClick={() => toogleLocation(false)}
									>
										Zip
									</Col>
								</Row>
							</Form.Label>
						</Row>

						<Row className="m-0 p-0 mb-2 d-none d-md-block" style={{ height: 36 }}>
							<Form.Label column sm={12} className="m-0 p-0 text-center w-100 h-100 fw-bold  shadow-sm border-0  " style={{ boxShadow: '0 0 1px 0px #10306c' }} >
								<Row className="m-0 align-items-center justify-content-center h-100 mb-2 " >
									<Col className={`m-0 p-0 px-3 d-flex justify-content-center rounded align-items-center h-100 cursor-pointer text-center fw-bold ${isCity ? 'bg-primary text-light' : 'bg-white'}`}
										onClick={() => toogleLocation(true)}
									>
										City
									</Col>
								</Row>

								<Row className="m-0 align-items-center justify-content-center h-100 " >
									<Col className={`m-0 p-0 px-3 d-flex justify-content-center rounded align-items-center h-100 cursor-pointer text-center fw-bold ${!isCity ? 'bg-primary text-light' : 'bg-white'}`}
										onClick={() => toogleLocation(false)}
									>
										Zip
									</Col>
								</Row>
							</Form.Label>
						</Row>
					</Col>

					{/* LOCATION */}
					<Col className="m-0 p-0 pe-md-3 d-flex flex-column aling-items-center justify-content-start" xs={12} md={3}>
						<Row className={`m-0 p-0 mb-2 ${isCity ? "d-flex" : "d-none"}`}>
							<Form.Select
								aria-label="Select a state"
								className="rounded customSelect shadow-sm border-0"
								{...register("state")}
							>
								<option value="" hidden>
									{qState ? qState : "State"}
								</option>
								{states.map((state) => {
									return (
										<option key={state} value={state}>
											{state}
										</option>
									);
								})}
							</Form.Select>
						</Row>
						<Row className={`m-0 p-0 mb-2 ${isCity ? "d-flex" : "d-none"}`}>
							<Form.Select
								disabled={!isCitiesLoaded}
								aria-label="Select a city"
								className="rounded customSelect shadow-sm border-0"
								{...register("city")}
							>
								<option value="" hidden>
									{qCity ? qCity : "City"}
								</option>
								{cities.map((city) => {
									return (
										<option key={city} value={city}>
											{city}
										</option>
									);
								})}
							</Form.Select>
							{errors.city?.message && (
								<p className="p-0 m-0 mt-1 text-danger text-center" style={{ fontSize: "0.8rem" }}>
									<strong>{errors.city?.message}</strong>
								</p>
							)}
						</Row>

						<Row className={`m-0 mb-2 p-0 ${!isCity ? "d-flex" : "d-none"}`}>
							<Form.Control
								type="text"
								className="rounded shadow-sm border-0"
								placeholder="ZIP Code"
								{...register("zip")}
							/>
							{errors.zip?.message && (
								<p className="p-0 m-0 mt-1 text-danger text-center" style={{ fontSize: "0.8rem" }}>
									<strong>{errors.zip?.message}</strong>
								</p>
							)}
						</Row>
					</Col>

					{/* PRICE */}
					<Col className="m-0 p-0 pe-md-3 d-flex flex-column aling-items-center justify-content-start" xs={12} md={3}>
						<Form.Control
							type="text"
							placeholder={`Min Price`}
							className=" mb-2 rounded shadow-sm border-0"
							{...register("minRent")}
						/>

						<Form.Control
							type="text"
							placeholder={`Max Price`}
							className="mb-2 rounded shadow-sm border-0"
							{...register("maxRent")}
						/>
					</Col>

					{/* BUTTONS */}
					<Col className="m-0 p-0 pe-md-3" xs={12} md={'auto'}>

						<Row className="m-0 mb-2 p-0">
							<Col className="m-0 p-0">
								<Button onClick={handleShow} size="md" className="w-100 rounded px-4" style={{ height: 36 }}>
									<p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>More Options{countOfSelectedFeatures ? ` (${countOfSelectedFeatures})` : ''}&nbsp;<i className="bi bi-plus-lg"></i></p>
								</Button>
							</Col>
						</Row>



						<Row className={`m-0 p-0 d-flex`}>
							<Col className="m-0 p-0">
								<Button disabled={!localSearchHistory} onClick={() => setShowHistoryModal(true)} type="button" size="md" className="w-100 rounded px-4" style={{ height: 36 }}>
									<p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>Search History <i className="bi bi-clock-history"></i></p>
								</Button>
							</Col>
						</Row>


					</Col>


					<Col className="m-0 p-0 mb-2" xs={'auto'}>
						{((isMobile && !hasUserSearched) || !isMobile) && (
							<Row className="m-0 p-0  h-100">
								<Col className="m-0 p-0 h-100 ">
									<Button className="m-0 mt-2 mt-md-0 w-100 h-100 rounded px-4" type="submit" disabled={!ZipValue && !CityValue}>
										{(hasUserSearched && loading) ? (
											<><p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>Loading</p></>
										) : (
											<><p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>Search <i className="bi bi-search"></i></p></>
										)}
									</Button>
								</Col>
							</Row>
						)}
					</Col>


				</Row>
			</Form>
		)
	};

	const [showSearchHistoryButton, setShowSearchHistoryButton] = useState(false);
	useEffect(() => {
		let timeout;

		if (hasUserSearched) {
			setShowExpandedMenu(!hasUserSearched);

			timeout = setTimeout(() => setShowSearchHistoryButton(true), 800);


		}
		return () => {
			clearTimeout(timeout);
		}
	}, [hasUserSearched]);


	const [selectedFeatures, setSelectedFeatures] = useState({
		building: { amenities: [], accessibilities: [] },
		unit: { amenities: [], accessibilities: [] }
	})

	const countOfSelectedFeatures = useMemo(() => {
		const { building: buildingFeatures, unit: unitsFeatures } = selectedFeatures;
		if (!buildingFeatures || !unitsFeatures) return null;
		const buildingFeaturesCount = buildingFeatures.amenities.length + buildingFeatures.accessibilities.length;
		const unitsFeaturesCount = unitsFeatures.amenities.length + unitsFeatures.accessibilities.length;
		return buildingFeaturesCount + unitsFeaturesCount;
	}, [selectedFeatures]);

	const formatFeaturesForHistory = (featuresSet) => {
		if (!featuresSet) return;

		return Object.entries(featuresSet).reduce((acc, [featuresKey, features]) => {
			acc[featuresKey] = features.reduce((acc, feature) => {
				return { ...acc, ...feature };
			}, {})
			return acc;
		}, {})
	}

	return (
		<>
			<Container
				style={{ maxWidth: hasUserSearched ? 1150 : 950, transition: searchAnimTime - 3 + "s", animationDelay: searchAnimDelay + "s", animationPlayState: loading ? "paused" : "running" }}
				className={`m-auto  ${hasUserSearched ? `${isMobile ? 'my-0' : ''} searched p-0 py-3` : 'custom-padding-marketplace'} rounded marketplace-search`}
			>
				{(hasUserSearched && !isMobile) && (
					<Row className="m-0 p-0 position-relative justify-content-center " style={{ height: 68 }}>
						<Col className="m-0 p-0 h-100 position-relative " xs={'auto'}>
							{/* {!showExpandedMenu && ( */}
							<MiniSearch
								qState={qState}
								states={states}
								qCity={qCity}
								cities={cities}
								qMinRent={qMinRent}
								qMaxRent={qMaxRent}
								handleExpandSearch={() => { setShowExpandedMenu(true) }}
								countOfSelectedFeatures={countOfSelectedFeatures}
							/>
							{/* )} */}

							{showExpandedMenu && (
								<>
									<div className="m-0 p-0 position-absolute top-0 w-100" style={{ zIndex: 999 }}>
										{form(true)}
									</div>

									<Backdrop
										show={showExpandedMenu}
										close={() => setShowExpandedMenu(false)}
									/>

								</>
							)}
						</Col>


						{/* <Col className="m-0 d-none d-xl-flex ms-5 align-items-center justify-content-center marketplace__search-bg p-3 rounded animation-transition" style={{opacity: showSearchHistoryButton ? 1 : 0}} xs="auto">
							<ButtonPrimary onClick={() => setShowHistoryModal(true)} type="button">
								<p className="m-0 p-0 fs-6 fw-bold" style={{ color: 'inherit' }}>Search History <i className="bi bi-clock-history"></i></p>
							</ButtonPrimary>
						</Col> */}




					</Row>
				)}

				{(!hasUserSearched) && (
					form()
				)}

				{(hasUserSearched && isMobile) && (
					<Row className="m-0 p-0 px-2">
						<Col className="m-0 p-0" xs={12}>
							<ButtonPrimary onClick={() => setShowSearchModal(true)}>
								{(hasUserSearched && loading) ? (
									<p className="m-0 p-0 fs-6 text-light">Loading...</p>
								) : (
									<p className="m-0 p-0 fs-6 text-light">Search<i className="bi bi-search ms-1"></i></p>
								)
								}
							</ButtonPrimary>
						</Col>
					</Row>)
				}

				<Modal
					show={showSearchModal}
					onHide={() => setShowSearchModal(false)}
					fullscreen
				>
					<Modal.Header className="px-2" closeButton></Modal.Header>

					<Modal.Body className="m-0 p-0">
						{form()}
					</Modal.Body>

					<Modal.Footer className="p-2">
						<Row className="m-0 p-0 w-100">
							<Col className="m-auto p-0" xs={12}>
								<ButtonPrimary onClick={searchHandler}>
									{(hasUserSearched && loading) ? (
										<p className="m-0 p-0 fs-6 text-light">Loading...</p>
									) : (
										<p className="m-0 p-0 fs-6 text-light">Search<i className="bi bi-search ms-1"></i></p>
									)
									}
								</ButtonPrimary>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>

				<ModalFeatures
					show={show}
					setShow={setShow}

					selectedFeatures={selectedFeatures}
					setSelectedFeatures={setSelectedFeatures}
					features={features}
				>
					{/* BEDS and BATHS */}
					<Row className="m-0 p-0 mb-3">
						<h6 className='m-0 p-0 fw-bold'>Select number of bedrooms and bathrooms</h6>
					</Row>
					<Row className="m-0 px-2 row-cols-1 mb-3">
						<Col className="m-0 p-0 pe-sm-3 d-flex flex-column" xs={12} sm={6}>
							<Range
								step={1}
								min={0}
								max={5}
								defaultValue={[minBedrooms, maxBedrooms]}
								style={rangeRootStyles}
								trackStyle={trackStyles}
								activeDotStyle={activeDotStyles}
								handleStyle={handleStyles}
								railStyle={railStyles}
								dotStyle={dotStyles}
								dots={true}
								onChange={(e) => {
									setMaxBedrooms(e[1]);
									setMinBedrooms(e[0]);
								}}
							/>
							<Form.Label column sm={12} className="text-center mt-4">
								Bedrooms from {minBedrooms} to {maxBedrooms}
							</Form.Label>
						</Col>

						<Col className="m-0 p-0 ps-sm-3 d-flex flex-column" xs={12} sm={6}>
							<Range
								step={1}
								min={0}
								max={5}
								defaultValue={[minBathrooms, maxBathrooms]}
								style={rangeRootStyles}
								trackStyle={trackStyles}
								activeDotStyle={activeDotStyles}
								handleStyle={handleStyles}
								railStyle={railStyles}
								dotStyle={dotStyles}
								onChange={(e) => {
									setMaxBathrooms(e[1]);
									setMinBathrooms(e[0]);
								}}
								dots={true}
							/>
							<Form.Label column sm={12} className="text-center mt-4">
								Bathrooms from {minBathrooms} to {maxBathrooms}
							</Form.Label>
						</Col>
					</Row>


				</ModalFeatures>

				<Modal
					show={showHistoryModal}
					onHide={() => setShowHistoryModal(false)}
					centered
					size="xl"
				>
					<Modal.Header className="p-3" closeButton></Modal.Header>

					<Modal.Body className="p-2">
						<Row className="m-0 p-2">
							{
								localSearchHistory && localSearchHistory.map((data, index) => (
									<HistoryBoxQuery
										key={"a" + index}
										data={data}
										deleteHandler={() => { deleteSelectedSearchFromLocalHistory(index) }}
										features={formatFeaturesForHistory(features)}

										onOpen={loadFavoriteData}

									/>
								))
							}
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Row className="m-0 p-0 w-100">
							<Col className="m-auto p-0" xs={'auto'}>
								<ButtonSecondary onClick={() => setShowHistoryModal(false)}>
									Cancel
								</ButtonSecondary>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>

			</Container>
		</>
	);
};

export default MarketplaceSearchMobile;
