import React, { useContext } from 'react';
import {
    Col,
    Row
} from 'react-bootstrap';

import {UserTypeContext} from '../../contexts/UserTypeContext';
import PositionCardForm from './PositionCardForm';
import BluredSection from '../Shared/BluredSection';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import PositionCardFormLoader from './PositionCardFormLoader';


export default function Employment({
    forceEditMode,
    isSubmit,
    tenantData,
    canEdit,
    addPosition,
    employmentFields,
    register,
    errors,
    watch,
    setValue,
    deleteEmploymentHandler,
    onSubmitEmployment,
    onlyView,
    resetForm,
    editedSection,
    currentlyEditedSectionHandler,
    isLoading
}) {
    const { userType } = useContext(UserTypeContext);
    return (
        <Col xs={12} className="m-0 p-0">
            {/* <Row className="darkerGray p-2 text-center m-0 p-0 rounded">
                <h5 className="fw-bold m-0 p-0">EMPLOYMENT</h5>
            </Row> */}

            { !isLoading && employmentFields && !employmentFields.length && (
                <Row className="row-cols-1 m-0 px-3 pt-3">
                    <Col className="m-0 p-0 text-center">
                        <h6 className="m-0 p-0 montserrat fw-bold">
                            { userType === "Tenant" ? "You " : "Tenant "} have not provided any employment history
                        </h6>
                    </Col>
                </Row>
            ) } 
                
            <Row className="row-cols-1 m-0 px-3">

                {/* LOADER */}
                { isLoading && (
                    <Col className="m-0 p-0">
                        <Row className="row-cols-1">
                            <Col className="m-0 p-3">
                                <h4 className="fw-bold">&nbsp;</h4>
                                <Row className="lightGray m-0 p-3 pt-0 position-relative">
                                    <PositionCardFormLoader />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                ) }

                { employmentFields && employmentFields.length ? (
                    <Col className="m-0 p-0">
                        <Row className="row-cols-1">
                            
                            { forceEditMode &&
                                <Col className="m-0 p-3">
                                <h4 className="fw-bold">
                                    Position(s):
                                </h4>
                                <Row className="m-0 p-3 pt-0 position-relative">
                                    {
                                        employmentFields && employmentFields.map( (item, i) => (
                                            <PositionCardForm 
                                                onSubmitEmployment={onSubmitEmployment}
                                                deleteEmploymentHandler={deleteEmploymentHandler}
                                                key={i}
                                                index={i}
                                                id={i}
                                                submitTrigger={isSubmit}
                                                deleteHandler={ () => {} }
                                                tenantData = {tenantData && tenantData.Employments && tenantData.Employments[i]}

                                                forceEditMode={forceEditMode}
                                                canEdit={canEdit}

                                                prefix={`Employments.${i}.`}
                                                item={item}
                                                register={register}
                                                errors={errors}
                                                watch={watch}
                                                setValue={setValue}
                                                resetForm={resetForm}
                                                currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                            />
                                        ))
                                    }
                                </Row>
                                </Col>
                            }

                            { !forceEditMode &&
                                <>
                                    {   tenantData && tenantData.Employments && tenantData.Employments.some( employment => employment?.IsCurrentPoistion) &&
                                        <Col className="m-0 p-0 p-md-3">

                                            <h4 className="fw-bold px-3 p-md-0">
                                                Current Position(s):
                                            </h4>

                                            <Row className="m-0 p-3 pt-0 position-relative">
                                                {
                                                    !onlyView ? ( employmentFields && employmentFields.map( (item, i) => {

                                                        const IsCurrentPosition = tenantData && tenantData.Employments[i]?.IsCurrentPoistion;

                                                        return IsCurrentPosition ? (
                                                            <PositionCardForm 
                                                                onSubmitEmployment={onSubmitEmployment}
                                                                deleteEmploymentHandler={deleteEmploymentHandler}
                                                                key={i}
                                                                index={i}
                                                                id={i}
                                                                submitTrigger={isSubmit}
                                                                deleteHandler={ () => {} }
                                                                tenantData = {tenantData && tenantData.Employments && tenantData.Employments[i]}
                    
                                                                forceEditMode={forceEditMode}
                                                                canEdit={canEdit}
                    
                                                                prefix={`Employments.${i}.`}
                                                                item={item}
                                                                register={register}
                                                                errors={errors}
                                                                watch={watch}
                                                                setValue={setValue}
                                                                resetForm={resetForm}
                                                                editedSection={editedSection}
                                                                currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                                            />
                                                            ) :  null;
                                                    })
                                                    ) : (
                                                    tenantData && tenantData.Employments && tenantData.Employments.map( (item, i) => {

                                                        const IsCurrentPosition = tenantData && tenantData.Employments[i]?.IsCurrentPoistion;

                                                        return IsCurrentPosition ? (
                                                            <PositionCardForm 
                                                                onSubmitEmployment={onSubmitEmployment}
                                                                deleteEmploymentHandler={deleteEmploymentHandler}
                                                                key={i}
                                                                index={i}
                                                                id={i}
                                                                submitTrigger={isSubmit}
                                                                deleteHandler={ () => {} }
                                                                tenantData = {tenantData && tenantData.Employments && tenantData.Employments[i]}
                    
                                                                forceEditMode={forceEditMode}
                                                                canEdit={canEdit}
                    
                                                                prefix={`Employments.${i}.`}
                                                                item={item}
                                                                register={register}
                                                                errors={errors}
                                                                watch={watch}
                                                                setValue={setValue}
                                                                resetForm={resetForm}
                                                                editedSection={editedSection}
                                                                currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                                            />
                                                            ) :  null;
                                                    }))
                                                }
                                            </Row>
                                        </Col>
                                    }

                                    { tenantData && tenantData.Employments &&
                                        <Col className="m-0 p-0 pt-3 p-md-3">
                                        
                                            {   (tenantData.Employments.some( employment => !employment?.IsCurrentPoistion) || tenantData.Employments.length === 0) && 
                                                <h4 className="fw-bold px-3 p-md-0">
                                                    {
                                                        tenantData.Employments.some( employment => !employment?.IsCurrentPoistion) 
                                                            ? 'Previous Position(s):' 
                                                            : tenantData.Employments.length === 0 ? 'Position(s)' : null

                                                    } 
                                                </h4>
                                            }

                                            <Row className="m-0 p-3 pt-0 position-relative">
                                                {
                                                    !onlyView ? (employmentFields && employmentFields.map( (item, i) => {

                                                        const IsCurrentPosition = tenantData && tenantData.Employments[i]?.IsCurrentPoistion;

                                                        return !IsCurrentPosition ? (
                                                            <PositionCardForm 
                                                                onSubmitEmployment={onSubmitEmployment}
                                                                deleteEmploymentHandler={deleteEmploymentHandler}
                                                                key={i}
                                                                index={i}
                                                                id={i}
                                                                submitTrigger={isSubmit}
                                                                deleteHandler={ () => {} }
                                                                tenantData = {tenantData && tenantData.Employments && tenantData.Employments[i]}
                    
                                                                forceEditMode={forceEditMode}
                                                                canEdit={canEdit}
                    
                                                                prefix={`Employments.${i}.`}
                                                                item={item}
                                                                register={register}
                                                                errors={errors}
                                                                watch={watch}
                                                                setValue={setValue}
                                                                resetForm={resetForm}
                                                                editedSection={editedSection}
                                                                currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                                            />
                                                            ) : null;
                                                    })) : (

                                                    tenantData && tenantData.Employments && tenantData.Employments.map( (item, i) => {

                                                        const IsCurrentPosition = tenantData && tenantData.Employments[i]?.IsCurrentPoistion;

                                                        return !IsCurrentPosition ? (
                                                            <PositionCardForm 
                                                                onSubmitEmployment={onSubmitEmployment}
                                                                deleteEmploymentHandler={deleteEmploymentHandler}
                                                                key={i}
                                                                index={i}
                                                                id={i}
                                                                submitTrigger={isSubmit}
                                                                deleteHandler={ () => {} }
                                                                tenantData = {tenantData && tenantData.Employments && tenantData.Employments[i]}
                    
                                                                forceEditMode={forceEditMode}
                                                                canEdit={canEdit}
                    
                                                                prefix={`Employments.${i}.`}
                                                                item={item}
                                                                register={register}
                                                                errors={errors}
                                                                watch={watch}
                                                                setValue={setValue}
                                                                resetForm={resetForm}
                                                                editedSection={editedSection}
                                                                currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                                            />
                                                            ) : null;
                                                    })
                                                    )
                                                }
                                            </Row>
                                        </Col>
                                    }

                                </>
                            }
                        </Row>
                    </Col>
                ) : null } 
            
                {/* <Col className="m-0 p-1 position-relative">
                    <BluredSection show={editedSection !== null}/>
                    {   (canEdit || forceEditMode) && 
                        

                        <ButtonPrimary onClick={addPosition} type="button">
                            <i className="bi bi-plus-lg me-2"></i> Add Position
                        </ButtonPrimary>
                    }

                </Col> */}
            </Row> 
                
            <Row className="row-cols-1 m-0 px-3 justify-content-center">
                <Col className="m-0 p-1 position-relative" xs="auto">
                    <BluredSection show={editedSection !== null}/>
                    {   (canEdit || forceEditMode) && 
                        

                        <ButtonPrimary onClick={addPosition} type="button">
                            <i className="bi bi-plus-lg me-2"></i> Add Position
                        </ButtonPrimary>
                    }

                </Col>
            </Row> 
        </Col>
    )
}
