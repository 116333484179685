import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Image
} from 'react-bootstrap';

import WISSP_Submark_LogoS from '../../assets/logos/WISSP_Submark.png';
import WISSP_Submark_LogoL from '../../assets/logos/WISSP_Submark_Large.png';


export default function SideNavigation ({
    show,
    close,
    smallNav,
    children
}) {
    return (
        <Container className={`sideDrawer sideDrawer--right ${show ? 'sideDrawer--show' : ''} bg-light`}>

            <Row className={`m-0 mb-4 p-0 px-3 px-sm-4 px-md-5 sideDrawer__close custom_header position-relative ${smallNav ? 'shrinked_header' : ''}`}>
                
                <Col xs="auto" className="m-0 p-0 d-flex justify-content-center align-items-center">
                    <Row className="m-0 p-0">
                        <Image className="m-0 p-0 d-block d-md-none" 
                            style={{ objectFit: "cover", height: 46, width: "auto" }}
                            srcSet={`${WISSP_Submark_LogoS} 1x, ${WISSP_Submark_LogoL} 2x`} 
                            alt="WISSP logo"></Image>  
                    </Row>
                </Col>

                <Col className={`m-0 p-0 d-flex justify-content-end align-items-center`}>
                    <Button className="m-0 p-2 border-0 bg-transparent" onClick={close}>
                        <i className="bi bi-x-lg text-light nav-close-icon"></i>
                    </Button>
                </Col>
            </Row>


            { children }

        </Container>
    )
}
