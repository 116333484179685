import { useState } from "react";

export default function useUpdateImages() {
    const [images, setImages] = useState([]);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [downloadedImages, setDownloadedImages] = useState([]);

    
    function imagesToDeleteIDs () {
        const imagesIDs = imagesToDelete.map( image => image.id);
        return imagesIDs;
    }

    function onlyNewImages() {
        const newImages = images.filter( img => !img.isInDB);
        return newImages;
    }

    return {
        images, 
        setImages, 
        imagesToDelete,
        setImagesToDelete,
        imagesToDeleteIDs,
        onlyNewImages,
        downloadedImages, 
        setDownloadedImages,
    }
}
