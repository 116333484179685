import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import { debounce, isEqual, cloneDeep } from "lodash";
import { AuthContext } from "../contexts/AuthContext";
import { getNotificationsSpecs, getNotifications, addNotification } from '../services/notification';
import LoaderWithBackdrop from "../components/Shared/LoaderWithBackdrop";
import placeholder from "../assets/images/avatar-placeholder.svg";
import Group from "../components/CommandCenter/Group";
import Notification from "../components/CommandCenter/Notification";
import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import ButtonSecondary from '../components/Shared/Buttons/ButtonSecondary';
import InputFloating from "../components/Shared/Inputs/InputFloating";
import TextAreaFloating from "../components/Shared/Inputs/TextAreaFloating";
import Category from "../components/CommandCenter/Category";
import AddGroupModal from '../components/CommandCenter/AddGroupModal';
import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import { caseManagerActions } from '../store/CaseManagerSlice';
import { CLIENT } from '../components/Clients/AddClientModal';
import Loader from "../components/Shared/Loader";

import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../constants/client';

import {    
  serviceGetGroups,
  serviceAddGroup,
  serviceDeleteGroup,
  serviceAddClient,
  serviceDeleteClientFromGroup
} from '../services/group';

import {
  serviceGetClients,
} from '../services/client';

import TimePicker from "../components/CommandCenter/TimePicker";

import useWindowSize, { GRID_BREAKPOINTS } from "../hooks/useWindowSize";
import { 
  NOTIFICATION_ID,
  START_DATE,END_DATE,
  NOTIFICATION_TYPE_ID,
  SEND_NOW,
  HOUR,
  DAYS_OF_THE_WEEK,
  DAY,
  GROUPS,
  GROUP_IDS,
  CLIENT_IDS,
  MESSAGE_SPEC,
  MESSAGE_TYPE,
  MESSAGE,
  TITLE,
  IMAGE_BLOB,
  FREQUENCY_PLAN,
  FREQUENCY,
  DESCRIPTION,
  NAME,
  ENABLED
} from '../constants/notification';



const TAB = Object.freeze({
  GROUP: 'group',
  NOTIFICATION: 'notification',
  BOTH: 'both'
});

const CommandCenter = () => {
  const { token } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(TAB.BOTH);
  const [showNewNotificationModal, setShowNewNotificationModal] = useState(false);
  const [newNotificationName, setNewNotificationName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [newNotificationDescription, setNewNotificationDescription] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedNotificationId, setEditedNotificationId] = useState(null);
  const [previousDaysCache, setPreviousDaysCache] = useState({});
  const size = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  
  const [isSaving, setIsSaving] = useState(false);

  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [editGroupWithId, setEditGroupWithId] = useState(null);

  const [isAddingNotification, setIsAddingNotification] = useState(false);

  const dispatch = useDispatch();
  const clients = useSelector(store => store.caseManagerStore.clients);

  const groups = useSelector(store => store.caseManagerStore.groups);

  const defaultNotificationFormValues = useSelector(store => store.caseManagerStore.notificationFormDefaultValues);
  const notificationsStore = useSelector(store => store.caseManagerStore.notifications);
  const wereNotificationsDownloaded = useSelector(store => store.caseManagerStore.wereNotificationsDownloaded);
  const wereClientsDownloaded = useSelector(store => store.caseManagerStore.wereClientsDownloaded);

  const [editingTimeForNotificationID, setEditingTimeForNotificationID] = useState(null);

  const [notifications, setNotifications] = useState([
    // {
    //   id: 'dfsa783-fdsf45-45dffdf',
    //   name: 'Test Notification',
    //   description: 'Lorem ipsum...',
    //   contentType: 'text',
    //   notificationText: 'Dear customers...',
    //   sendVia: ['email'],
    //   recipientsIDs: [],
    //   scheduling: {
    //     startDate: '2023-02-01',
    //     endDate: '2023-06-01',
    //     days: [1, 2, 5],
    //     repeatEvery: 'week',
    //     hour: 11,
    //     dayOfTheMonth: null,
    //     repeatOnDayOfTheWeek: null,
    //     repeatEveryWeekNumber: null,
    //     enabled: true
    //   }
    // }
  ]);


  const setNotificationName = (value) => {
    if( typeof value !== 'string') return;
    setNewNotificationName(value);

    const getNotificationNames = notifications.map( notification => notification.name );
 
    if(getNotificationNames.includes(value.trim())) {
      setErrorMessage("Notification with a given name already exists!");
    } else {
      setErrorMessage(null);
    }
  }

  const deleteBtnHandler= async (e, notificationId)=>{
    e.stopPropagation();

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      customClass: {
        confirmButton: 'btn btn-danger me-2',
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false
    });

    if(result.isConfirmed) {
      setNotifications( notifications => (
        notifications.filter( notification => notification.id !== notificationId) 
      ));
      setIsAddingNotification(false);
    }
  }

  const editBtnHandler=(e, notificationId)=>{
    e.stopPropagation();
    setIsEditMode(true);

    const notification = notifications.find( ({id}) => id === notificationId );
    setEditedNotificationId(notificationId);
    setNewNotificationName(notification.name);
    setNewNotificationDescription(notification.description);
    setShowNewNotificationModal(true);
  }
  
  const addNotificationHandler = ( name, description ) => {
    setNotifications( notifications => [...notifications, 
      {
        id: new Date().getTime(),
        isNew: true,
        name,
        description,
        contentType: '',
        notificationText: '',
        notificationTitle: '',
        sendVia: null,
        recipientsIDs: [],
        scheduling: {
          startDate: '',
          endDate: '',
          days: [],
          repeatEvery: '',
          hour: 10,
          dayOfTheMonth: null,
          repeatOnDayOfTheWeek: null,
          repeatEveryWeekNumber: null,
          enabled: false,
          sendNow: false
        }
    }]);
    setIsAddingNotification(true);
    setShowNewNotificationModal(false);
    setNewNotificationName('');
    setNewNotificationDescription('');
  }

  const updateNotificationHandler = async (n_notificationID, o_parameter, nestedIn) => {

    // console.log('[CommandCenter][updateNotificationHandler] n_notificationID: ', n_notificationID);
    // console.log('[CommandCenter][updateNotificationHandler] o_parameter: ', o_parameter);
    // console.log('[CommandCenter][updateNotificationHandler] nestedIn: ', nestedIn);

    // return;

    if(isAddingNotification) return;
    if(isSaving) return;
    if(!n_notificationID || !o_parameter) throw new Error("Missing arguments!");
    if( typeof o_parameter !== 'object' || Array.isArray(o_parameter) || o_parameter === null ) throw new Error("Second argument has to be object!");

    const searchedNotification = cloneDeep(notificationsStore.find( notification => notification[NOTIFICATION_ID] === n_notificationID ));
    let postBody;

    if(nestedIn) {
      postBody = {
        ...searchedNotification, 
        [nestedIn]: {
          ...searchedNotification[nestedIn],
          ...o_parameter
        }
      }
    } else {
      postBody = {...searchedNotification, ...o_parameter}
    }

    // const key = Object.getOwnPropertyNames(o_parameter)[0];

    // if( isEqual(notification[key], o_parameter[key]) ) {
    //   console.log('Equal'); 
    //   return;
    // };

    // console.log('[CommandCenter] updating: ', postBody);

    setIsSaving(true);
    const result = await addNotification(postBody, token);
    if(result.success){
      SuccessPopup('Saved');
      setNotifications( notifications => notifications.map( notification => {
        if(notification.id === n_notificationID) {
          return {
            id: postBody[NOTIFICATION_ID],
            isNew: false,
            name: postBody[NAME],
            description: postBody[DESCRIPTION],
            contentType: 'text',
            notificationText: postBody[MESSAGE_SPEC][MESSAGE],
            notificationTitle: postBody[MESSAGE_SPEC][TITLE],
            sendVia: postBody[MESSAGE_SPEC][MESSAGE_TYPE],
            recipientsIDs: postBody[GROUPS][GROUP_IDS],
            scheduling: {
              startDate: postBody[START_DATE],
              endDate: postBody[END_DATE],
              days: postBody[FREQUENCY_PLAN][DAYS_OF_THE_WEEK],
              repeatEvery: postBody[FREQUENCY_PLAN][FREQUENCY],
              hour: postBody[FREQUENCY_PLAN][HOUR],
              dayOfTheMonth: null,
              repeatOnDayOfTheWeek: null,
              repeatEveryWeekNumber: null,
              enabled: postBody[ENABLED],
              sendNow: postBody[SEND_NOW]
            }}
        } 
        return notification;
      }))
     
      dispatch(caseManagerActions.editNotification(postBody));
    } else {
      ErrorPopup();
    }
    setIsSaving(false);
    return result;
  }

  const editNotificationHandler = async ( name, description, notificationId ) => {
    if(isAddingNotification) {
      setNotifications( notifications => notifications.map((notification => {
        if(notification.id === notificationId) {
          return {...notification, name, description}
        }
        return notification;
      })));

      setShowNewNotificationModal(false);
      setNewNotificationName('');
      setNewNotificationDescription('');
      setIsEditMode(false);
      setEditedNotificationId(null);

      return;
    };

    const result = await updateNotificationHandler(notificationId, { [NAME]: name, [DESCRIPTION]: description });

    if(result.success){
      setShowNewNotificationModal(false);
      setNewNotificationName('');
      setNewNotificationDescription('');
      setIsEditMode(false);
      setEditedNotificationId(null);
    }
  }

  const selectRepeatHandler = (notificationId, value) => {
    setNotifications( notifications => (
      notifications.map( notification => {
        if( notificationId === notification.id ) {

            const additionalSettings = {};
            if(Number(value) === 1) {
              additionalSettings.days = [0, 1, 2, 3, 4, 5, 6];
              setPreviousDaysCache( prevState => ({...prevState, notificationId: notification.scheduling.days || []}));
            } else {
              additionalSettings.days = previousDaysCache.notificationId || [];
            }

            return {...notification, scheduling: { ...notification.scheduling, repeatEvery: value, ...additionalSettings}};
        }
        return notification;
      }) 
    ));
  }

  const setSchedulingDateHandler = (notificationId, dateType, value) => {
    setNotifications( notifications => (
      notifications.map( notification => {
        if( notificationId === notification.id ) {
            return {...notification, scheduling: { ...notification.scheduling, [dateType]: value}};
        }
        return notification;
      }) 
    ));
  }

  const enableSchedulingBtnHandler = (notificationId) => {

    const searchedNotification = notifications.find( notification => notification.id === notificationId);
    // console.log('[CommandCenter][enableSchedulingBtnHandler]: ', searchedNotification);

    // if(isAddingNotification === false){
    //   updateNotificationHandler(notificationId, { [ENABLED]: !searchedNotification.scheduling.enabled });
    //   return;
    // }

    setNotifications( notifications => (
      notifications.map( notification => {
        if( notificationId === notification.id ) {
            return {...notification, scheduling: { ...notification.scheduling, enabled: !notification.scheduling.enabled}};
        }
        return notification;
      }) 
    ));
  }

  const sendNowBtnHandler = (notificationId) => {
    setNotifications( notifications => (
      notifications.map( notification => {
        if( notificationId === notification.id ) {
            return {...notification, scheduling: { ...notification.scheduling, sendNow: !notification.scheduling.sendNow}};
        }
        return notification;
      }) 
    ));
  }

  const selectSchedulingWeekDayHandler = (notificationId, dayId) => {
    setNotifications( notifications => (
      notifications.map( notification => {
        if( notificationId === notification.id ) {
            const newDays = notification.scheduling.days.includes(dayId) ? notification.scheduling.days.filter( val => val !== dayId) : [...notification.scheduling.days, dayId]; 
            return {...notification, scheduling: { ...notification.scheduling, days: newDays}};
        }
        return notification;
      }) 
    ));
  }

  // const selectContentTypeHandler = (notificationId, value) => {
  //   setNotifications( notifications => (
  //     notifications.map( notification => {
  //       if( notificationId === notification.id ) {
  //           return {...notification, contentType: value};
  //       }
  //       return notification;
  //     }) 
  //   ));
  // }

  const addToRecipientsHandler = (notificationId, recipientId) => {
    // console.log('[CommandCenter][addToRecipientsHandler] notificationID: ', notificationId);
    // console.log('[CommandCenter][addToRecipientsHandler] recipientId: ', recipientId);

    if(!isAddingNotification){
      
      const searchedNotification = cloneDeep(notifications.find( notification => notification.id === notificationId ));
      // console.log('[CommandCenter][addToRecipientsHandler] searchedNotification', searchedNotification);
      let timerInterval;
      if( !searchedNotification.recipientsIDs.includes( Number(recipientId))) {
        // Swal.fire({
        //   position: 'top',
        //   html: `Added!`,
        //   timer: 1500,
        //   timerProgressBar: true,
        //   showConfirmButton: false,
        //   backdrop: false,
        //   willClose: () => {
        //     clearInterval(timerInterval)
        //   }
        // })

        updateNotificationHandler(notificationId, { [GROUP_IDS]: [...searchedNotification.recipientsIDs, recipientId] }, GROUPS);
      } else {
        Swal.fire({
          position: 'top',
          html: `Already there!`,
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
          backdrop: false,
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
      }
      return;

    } else {
      setNotifications( notifications => (
        notifications.map( notification => {
          let timerInterval

          if( notificationId === notification.id ) {
            if( !notification.recipientsIDs.includes(recipientId)) {
              Swal.fire({
                position: 'top',
                html: `Added!`,
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
                backdrop: false,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              })
              return {...notification, recipientsIDs: [...notification.recipientsIDs, recipientId]};
            }

            Swal.fire({
              position: 'top',
              html: `Already there!`,
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
              backdrop: false,
              willClose: () => {
                clearInterval(timerInterval)
              }
            })
          }
          return notification;
        }) 
      ));
    }
  }

  const deleteRecipientHandler = (notificationId, recipientId) => {

    const searchedNotification = cloneDeep(notifications.find( notification => notification.id === notificationId ));

    if(!isAddingNotification){
      updateNotificationHandler(notificationId, { [GROUP_IDS]: searchedNotification.recipientsIDs.filter( id => id !== recipientId ) }, GROUPS);
    } else {
      setNotifications( notifications => (
        notifications.map( notification => {
          if( notificationId === notification.id ) {
              return {...notification, recipientsIDs: notification.recipientsIDs.filter( id => id !== recipientId )};
          }
          return notification;
        }) 
      ));
    }
  }

  // const selectSendViaHandler = (notificationId, value) => {
  //   setNotifications( notifications => (
  //     notifications.map( notification => {
  //       if( notificationId === notification.id ) {
  //         const newSendVia = notification.sendVia.includes(value) ? notification.sendVia.filter( val => val !== value) : [...notification.sendVia, value]; 
  //         return {...notification, sendVia: newSendVia};
  //       }
  //       return notification;
  //     }) 
  //   ));
  // }

  // const setNotificationTextHandler = (notificationId, value) => {
  //   setNotifications( notifications => (
  //     notifications.map( notification => {
  //       if( notificationId === notification.id ) {
  //         return {...notification, notificationText: value};
  //       }
  //       return notification;
  //     }) 
  //   ));
  // }

  const openGroupModal = () => {
    setShowAddGroupModal(true);
  }
  const closeGroupModal = () => {
    setShowAddGroupModal(false);
    setEditGroupWithId(null);
  }

  const addNewGroupHandler = async (groupData) => {
    setIsSaving(true);
    // console.log(groupData); 
    const result = await serviceAddGroup(groupData, token);
    
    setIsSaving(false);

    if(result.success) {
        dispatch(caseManagerActions.addGroup(result.data));
        SuccessPopup("Success! New group was added!");
        closeGroupModal();
    } else {
        ErrorPopup();
    }
    return result;
  }
  const editGroupHandler = async (groupData) => {
    setIsSaving(true);
    // console.log(groupData);
    const result = await serviceAddGroup(groupData, token);
    
    setIsSaving(false);

    if(result.success) {
        dispatch(caseManagerActions.editGroup(result.data));
        SuccessPopup("Success! Client was edited!");
        setEditGroupWithId(null);
        closeGroupModal();
    } else {
        ErrorPopup();
    }
    return result;
  }
  const deleteGroupHandler = async (e, id) => {
    e.stopPropagation();
    const decision = await Swal.fire({
        title: 'Do you want to delete the group?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-danger text-light me-2',
            cancelButton: 'btn btn-secondary '
        },
        
    })

    if(decision.isDismissed) return;

    setIsSaving(true);

    const result = await serviceDeleteGroup(id, token);
    
    setIsSaving(false);

    if(result.success) {
        dispatch(caseManagerActions.deleteGroup(id));
        SuccessPopup("Success! Group was deleted!");
    } else {
        ErrorPopup();
    }
  }
  const onClickEditGroupBtn = (e, id) => {
    e.stopPropagation(); 
    setShowAddGroupModal(true);
    setEditGroupWithId(id);
  }
  const onClickTabBox = ( name ) => {
    setActiveTab(name);
  }
  const editScheduling = (notificationId, newValues) => {
    setNotifications( prevState => prevState.map( notification => {
        if(notification.id === notificationId) {
          return {...notification, scheduling: {...notification.scheduling, ...newValues}}
        }
        return notification;
      })
    )
  }

  const getNotificationsSpecsHandler = useCallback(async () => {
    if(defaultNotificationFormValues) return;
    setIsLoading(true);
    const { data, success } = await getNotificationsSpecs();

    if(success) {
      dispatch(caseManagerActions.setNotificationFormDefaultValues(data));
    } else {
      ErrorPopup();
    }
    setIsLoading(false);

  }, [dispatch, defaultNotificationFormValues]);

  const getNotificationsHandler = useCallback(async () => {
    if(wereNotificationsDownloaded) {
      setNotifications( notificationsStore.map( notification => {
        return {
          id: notification[NOTIFICATION_ID],
          isNew: false,
          name: notification[NAME],
          description: notification[DESCRIPTION],
          contentType: 'text',
          notificationText: notification[MESSAGE_SPEC][MESSAGE],
          notificationTitle: notification[MESSAGE_SPEC][TITLE],
          sendVia: notification[MESSAGE_SPEC][MESSAGE_TYPE],
          recipientsIDs: notification[GROUPS][GROUP_IDS],
          scheduling: {
            startDate: notification[START_DATE], // TODO: format to this: 2023-02-01
            endDate: notification[END_DATE],
            days: notification[FREQUENCY_PLAN][DAYS_OF_THE_WEEK],
            repeatEvery: notification[FREQUENCY_PLAN][FREQUENCY],
            hour: notification[FREQUENCY_PLAN][HOUR],
            dayOfTheMonth: null,
            repeatOnDayOfTheWeek: null,
            repeatEveryWeekNumber: null,
            enabled: notification[ENABLED],
            sendNow: notification[SEND_NOW]
          }}
      }));

      return;
    };

    setIsLoading(true);
    const { data, success } = await getNotifications(token);

    if(success) {
      dispatch(caseManagerActions.setNotifications(data));
      dispatch(caseManagerActions.setWereNotificationsDownloaded(true));

      setNotifications( data.map( notification => {
        return {
          id: notification[NOTIFICATION_ID],
          isNew: false,
          name: notification[NAME],
          description: notification[DESCRIPTION],
          contentType: 'text',
          notificationText: notification[MESSAGE_SPEC][MESSAGE],
          notificationTitle: notification[MESSAGE_SPEC][TITLE],
          sendVia: notification[MESSAGE_SPEC][MESSAGE_TYPE],
          recipientsIDs: notification[GROUPS][GROUP_IDS],
          scheduling: {
            startDate: notification[START_DATE],
            endDate: notification[END_DATE],
            days: notification[FREQUENCY_PLAN][DAYS_OF_THE_WEEK],
            repeatEvery: notification[FREQUENCY_PLAN][FREQUENCY],
            hour: notification[FREQUENCY_PLAN][HOUR],
            dayOfTheMonth: null,
            repeatOnDayOfTheWeek: null,
            repeatEveryWeekNumber: null,
            enabled: notification[ENABLED],
            sendNow: notification[SEND_NOW]
          }}
      }));

    } else {
      ErrorPopup();
    }
    setIsLoading(false);

  }, [dispatch, token, wereNotificationsDownloaded]);

  const getGroupsHandler = useCallback(async () => {
    if(wereNotificationsDownloaded) return;
    setIsLoadingGroups(true);
    const { data, success } = await serviceGetGroups(token);

    if(success) {
      dispatch(caseManagerActions.setGroups(data));
      dispatch(caseManagerActions.setWereGroupsDownloaded(true));
    } else {
      ErrorPopup();
    }
    setIsLoadingGroups(false);

  }, [dispatch, token, wereNotificationsDownloaded]);

  const getClientsHandler = useCallback(async () => {
    if(wereClientsDownloaded) return;
    setIsLoadingClients(true);
    const { data, success } = await serviceGetClients(token);

    if(success) {
      dispatch(caseManagerActions.setClients(data));
      dispatch(caseManagerActions.setWereClientsDownloaded(true));
    } else {
      ErrorPopup();
    }
    setIsLoadingClients(false);

  }, [dispatch, token, wereClientsDownloaded]);

  const saveNewNotificationHandler = async (notificationID, sendNow=false) => {
    if(!notificationID) return;
    const notification = notifications.find( ({id}) => id === notificationID);
    if(!notification) return;

    const postBody = {
      [NAME]: notification.name,
      [DESCRIPTION]: notification.description,
      [START_DATE]: notification.scheduling.startDate,
      [END_DATE]: notification.scheduling.endDate,
      [SEND_NOW]: sendNow,
      [ENABLED]: notification.scheduling.enabled,
      [FREQUENCY_PLAN]: {
          [FREQUENCY]: Number(notification.scheduling.repeatEvery),
          [HOUR]: notification.scheduling.hour,
          [DAYS_OF_THE_WEEK]: notification.scheduling.days
      },
      [GROUPS]: {
          [GROUP_IDS]: notification.recipientsIDs,
          [CLIENT_IDS]: []
      },
      [MESSAGE_SPEC]: {
          [MESSAGE_TYPE]: notification.sendVia,
          [MESSAGE]: notification.notificationText,
          [TITLE]: notification.notificationTitle
      },
      [IMAGE_BLOB]: null
    }

    setIsSaving(true);

    const { data, success } = await addNotification(postBody, token);

    if(success) {
      dispatch(caseManagerActions.addNotification(data));
      setNotifications( prevState => {
        return prevState.map((notification) => {
          if(notification.id === notificationID) {
            return {
              ...notification,
              isNew: false,
              id: data[NOTIFICATION_ID]
            }
          }
          return notification;
        })
      });
      setIsAddingNotification(false);
      SuccessPopup('Notification added!');
    } else {
      ErrorPopup();
    }
    setIsSaving(false);
  }

  const saveNewNotificationAndSaveNow = (notificationID) => {
    saveNewNotificationHandler(notificationID, true);
  }

  const sendNotificationImmediately = async (id) => {
    if(!id || !notifications) return;

    const selectedNotification = notificationsStore.find( (notification) => String(notification[NOTIFICATION_ID]) === String(id));
    // console.log(notifications);
    if(!selectedNotification) {
      ErrorPopup("Error! Couldn't find selected notification.");
      return;
    }

    setIsSaving(true);
    const { data, success } = await addNotification({...selectedNotification, [SEND_NOW]: true}, token);
    if(success) {
      dispatch(caseManagerActions.editNotification({[NOTIFICATION_ID]: id, [SEND_NOW]: true}));

      setNotifications( prevState => {
        return prevState.map((notification) => {
          if(String(notification.id) === String(id)) {
            return {
              ...notification,
              scheduling: {
                ...notification.scheduling,
                sendNow: true
              }
            }
          }
          return notification;
        })
      });

      setIsAddingNotification(false);
      SuccessPopup('Success! Notification scheduled for immediate send.');
    } else {
      ErrorPopup();
    }
    setIsSaving(false);
  }

  const cancelAddNewNotificationHandler = async (e, id) => {
    await deleteBtnHandler(e, id);
    setIsAddingNotification(false);
  }

  const onClickTimePickerHandler = (notificationID) => {
    setEditingTimeForNotificationID(notificationID);
  }

  const closeTimePickerHandler = () => {
    setEditingTimeForNotificationID(null);
  }

  const setTimeHandler = (hour) => {
    const notificationID = editingTimeForNotificationID;
    if(!notificationID && !hour) throw new Error('Missing arguments!');

    setNotifications( prevState => {
      return prevState.map( notification => {
        if(notification.id === notificationID) {
          return {...notification, scheduling: {...notification.scheduling, hour: +hour}}
        }
        return notification;
      })
    });

    closeTimePickerHandler();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serviceUpdateNotificationDebounce = useCallback( debounce( async( notification ) => {
    // console.log('[CommandCenter][serviceUpdateNotificationDebounce] function was invoked!');
    // console.log(notification);
  }, 500), []);

  const temp_onDeleteClient = async (clientID, groupID) => {
    const decision = await Swal.fire({
      title: 'Do you want to remove the client from the group?',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      buttonsStyling: false,
      customClass: {
          confirmButton: 'btn btn-danger text-light me-2',
          cancelButton: 'btn btn-secondary '
      },
    })

    if(decision.isDismissed) return;

    setIsSaving(true);
    const result = await serviceDeleteClientFromGroup({
        [CLIENT_ID]: clientID,
        [GROUP_ID]: groupID
    }, token);

    if(result.success) {

      dispatch(caseManagerActions.deleteClientFromGroup({
        [GROUP_ID]: groupID,
        [CLIENT_ID]: clientID
      }));

      SuccessPopup("Success! Client removed from group.");
    } else {
      ErrorPopup("Error! Something went wrong. Try again later.")
    }


    setIsSaving(false);
  }

  const temp_onAddClient = async (clientID, groupID) => {
    setIsSaving(true);

    const result = await serviceAddClient({
        [CLIENT_ID]: clientID,
        [GROUP_ID]: groupID
    }, token);

    if(result.success) {
      dispatch(caseManagerActions.addClientToGroup({
        [GROUP_ID]: groupID,
        [CLIENT_ID]: clientID
      }));
      SuccessPopup("Success! Added client to group.")
    } else {
      ErrorPopup("Error! Something went wrong. Try again later.")
    }


    setIsSaving(false);
  }


  const editNotificationHandler_NEW = (n_notificationID, o_parameter, autosave=false) => {
    if(!n_notificationID || !o_parameter) throw new Error("Missing arguments!");
    if( typeof o_parameter !== 'object' || Array.isArray(o_parameter) || o_parameter === null ) throw new Error("Second argument has to be object!");

    const notification = notifications.find( ({id}) => id === n_notificationID );
    const key = Object.getOwnPropertyNames(o_parameter)[0];

    // if( isEqual(notification[key], o_parameter[key]) ) {
    //   console.log('Equal'); 
    //   return;
    // };

    const updatedNotification = {...notification, ...o_parameter};

    if(isAddingNotification === false && autosave === true){
      if(key === 'sendVia'){
        updateNotificationHandler(n_notificationID, { [MESSAGE_TYPE]: o_parameter[key] }, MESSAGE_SPEC);
      }
  
      return;
    }
    
    setNotifications( notifications => notifications.map( notification => notification.id === n_notificationID ? updatedNotification : notification ));
  }

  const onSaveContentType = async (notificationID) => {
    const searchedNotification = notifications.find(notification => notification.id === notificationID);

    return await updateNotificationHandler(notificationID, { [MESSAGE]: searchedNotification.notificationText, [TITLE]: searchedNotification.notificationTitle }, MESSAGE_SPEC);
  }

  const onCancelContentType = (notificationID) => {
    const searchedNotificationGlobal = notificationsStore.find(notification => notification[NOTIFICATION_ID] === notificationID);
    setNotifications( notifications => {
      return notifications.map( notification => {
        if(notification.id === notificationID) {
         

          return {
            ...notification,
            notificationText: searchedNotificationGlobal[MESSAGE_SPEC][MESSAGE],
            notificationTitle: searchedNotificationGlobal[MESSAGE_SPEC][TITLE]
          }
        }
        return notification;
      })
    })
  }

  const onSaveScheduling = async (notificationID) => {
    const searchedNotification = notifications.find(notification => notification.id === notificationID);

    const result = await updateNotificationHandler(notificationID, {
      [START_DATE]: searchedNotification.scheduling.startDate,
      [END_DATE]: searchedNotification.scheduling.endDate, 
      [ENABLED]: searchedNotification.scheduling.enabled,
      [FREQUENCY_PLAN]: {
        [DAYS_OF_THE_WEEK]: searchedNotification.scheduling.days,
        [FREQUENCY]: searchedNotification.scheduling.repeatEvery,
        [HOUR]: searchedNotification.scheduling.hour
      }
    });

    return result;
  }

  const onCancelScheduling = (notificationID) => {
    const searchedNotificationGlobal = notificationsStore.find(notification => notification[NOTIFICATION_ID] === notificationID);

    setNotifications( notifications => {
      return notifications.map( notification => {
        if(notification.id === notificationID) {
         
          return {
            ...notification,
            scheduling: {
              ...notification.scheduling,
              enabled: searchedNotificationGlobal[ENABLED],
              startDate: searchedNotificationGlobal[START_DATE],
              endDate: searchedNotificationGlobal[END_DATE],
              days: searchedNotificationGlobal[FREQUENCY_PLAN][DAYS_OF_THE_WEEK],
              repeatEvery: searchedNotificationGlobal[FREQUENCY_PLAN][FREQUENCY],
              hour: searchedNotificationGlobal[FREQUENCY_PLAN][HOUR]
            }
          }
        }
        return notification;
      })
    })
  }

  useEffect(() => {
    getNotificationsSpecsHandler();
    getNotificationsHandler();
    getGroupsHandler();
    getClientsHandler();
  }, []);

  useEffect(() => {
    if (!size.width) return;
    if (size.width >= GRID_BREAKPOINTS.MD) {
      // setIsMobile(false);
      setActiveTab(TAB.BOTH)
    } else {
      // setIsMobile(true);
      setActiveTab(TAB.NOTIFICATION)
    }
  }, [size])

  return (
    <>
      { isSaving && <LoaderWithBackdrop/> }

      { !isLoading && (
        <TimePicker 
          show={editingTimeForNotificationID}
          closeTimePicker={closeTimePickerHandler}
          setTime={setTimeHandler}
          HourRange={defaultNotificationFormValues && defaultNotificationFormValues.HourRange}
        />
      )}

      <AddGroupModal 
        show={showAddGroupModal}
        onHide={closeGroupModal}
        clients={clients}
        groups={groups}
        onEditGroup={editGroupHandler}
        onAddGroup={addNewGroupHandler}
        editGroupWithId={editGroupWithId}

        temp_onAddClient={temp_onAddClient}
        temp_onDeleteClient={temp_onDeleteClient}
      />

      <Modal
        show={showNewNotificationModal}
        onHide={() => setShowNewNotificationModal(false)}
        centered
        size="lg"
      >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="m-0 p-0" >
                    <Container fluid className="p-0 m-0 montserrat">
                      
                    <Row className="mx-2 mx-sm-3 mx-sm-5 mt-5">
                      <Col>
                        <InputFloating 
                          label="Notification Name"
                          formClasses="border rounded shadow-sm border-wissp-light-gray"
                          labelClasses="mb-4"
                          register={{
                            value: newNotificationName,
                            onChange: (e) => setNotificationName(e.target.value),
                          }}
                          errorMessage={errorMessage}
                          showError={true}
                        />
                      </Col>
                    </Row>

                    <Row className="mx-2 mx-sm-3 mx-sm-5 my-0">
                      <Col>
                        <TextAreaFloating 
                          label="Notification Description"
                          labelClasses="mb-5 border rounded border-wissp-light-gray"
                          register={{
                            value: newNotificationDescription,
                            onChange: (e) => setNewNotificationDescription(e.target.value)
                          }}
                        />
                      </Col>
                    </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className='m-0 p-0'>
                  <Row className="m-0 p-2 w-100 justify-content-end">
                    <Col xs={12} sm="auto" className="p-0 mb-1 me-sm-1 mb-sm-0">
                        <ButtonSecondary
                            title="Close"
                            onClick={() => setShowNewNotificationModal(false)}
                        />
                    </Col>
                    <Col xs={12} sm="auto" className="p-0">
                        <ButtonPrimary 
                            disabled={!!errorMessage}
                            title={ isEditMode ? 'Save' : 'Add' }
                            onClick={() => {
                              isEditMode ? editNotificationHandler(newNotificationName, newNotificationDescription, editedNotificationId) : addNotificationHandler(newNotificationName, newNotificationDescription);
                            }}
                        />
                    </Col>
                </Row>

                </Modal.Footer>
      </Modal>

      <DndProvider backend={HTML5Backend}>
        <Container fluid className="p-3 p-lg-4 px-xl-5 d-flex flex-column justify-content-between" style={{height: 'calc(100vh - var(--top-menu-height))'}}>
          
          <Row className="m-0 mb-3 d-md-none">
              <Col className={`p-2 text-center rounded-start ${ activeTab === TAB.GROUP ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.GROUP)}>
                  <h6 className="m-0 fw-bold">Groups</h6>
              </Col>
              <Col className={`p-2 text-center rounded-end ${ activeTab === TAB.NOTIFICATION ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.NOTIFICATION)}>
                  <h6 className="m-0 fw-bold">Notifications</h6>
              </Col>
          </Row>

          <Row className="m-0 mb-4 d-none d-md-flex">
            <Col className="p-0">
              <h6 className="text-center">
                {" "}
                <span className="fw-bold">Note:</span> Drag group rows to
                right panel to add them to a notification recipient list.
              </h6>
            </Col>
          </Row>

          <Row className="">
            <Col className={`${[TAB.GROUP, TAB.BOTH].includes(activeTab) ? 'd-block' : 'd-none'} overflow-scroll-y wissp-scroll-box left-scrollbar`} style={{height: 'calc(100vh - 138px - 8px - var(--top-menu-height))'}}>
              
              <Row className="h-100">
                <Col className=" rounded">
                  <Category className="h-100">

                    { (isLoadingGroups || isLoadingClients) && <div className="h-100" style={{overflowX: 'hidden'}}><Loader /></div> }
                  
                    {!isLoadingGroups && !isLoadingClients && groups && groups.map((data, index) => (
                      <Group key={data[GROUP_ID]} 
                        title="Group" 
                        name={data[GROUP_NAME]}
                        description={data[GROUP_DESC]}
                        clientIds={data[CLIENTS]}
                        clients={clients}

                        index={index} 
                        id={data[GROUP_ID]} 

                        onEdit={onClickEditGroupBtn}
                        onDelete={deleteGroupHandler}
                      />
                    ))}
                  </Category>
                </Col>
              </Row>
            </Col>

            <Col xs={'auto'} className="d-none d-md-block p-0 border-end border-1"></Col>

            <Col className={`${[TAB.NOTIFICATION, TAB.BOTH].includes(activeTab) ? 'd-block' : 'd-none'} overflow-scroll-y wissp-scroll-box`} style={{height: 'calc(100vh - 138px - 8px - var(--top-menu-height))'}}>

              <Row className="h-100">
                <Col className=" rounded">
                  <Category defaultActiveKey={0} className="h-100">
                    { (isLoading && !defaultNotificationFormValues) && <div className="h-100"><Loader /></div> }

                    { (!isLoading && defaultNotificationFormValues) && notifications && notifications.filter( notification => {
                      if(isAddingNotification) {
                        return notification.isNew;
                      } else {
                        return !notification.isNew;
                      }
                    }).reverse().map( (notification, index) => (
                      <Notification {...notification} 
                        key={notification.id}
                        index={index}
                        addToRecipients={addToRecipientsHandler} 
                        // selectContentType={selectContentTypeHandler}
                        deleteRecipient={deleteRecipientHandler}
                        // selectSendVia={selectSendViaHandler}
                        // setNotificationText={setNotificationTextHandler}
                        enableSchedulingBtn={enableSchedulingBtnHandler}
                        selectSchedulingWeekDay={selectSchedulingWeekDayHandler}
                        selectRepeat={selectRepeatHandler}
                        setSchedulingDate={setSchedulingDateHandler}

                        deleteBtnHandler={deleteBtnHandler}
                        editBtnHandler={editBtnHandler}
                        editScheduling={editScheduling}

                        saveNewNotification={saveNewNotificationHandler}
                        cancelAddNewNotification={cancelAddNewNotificationHandler}

                        onClickTimePicker={onClickTimePickerHandler}

                        changeHandler={editNotificationHandler_NEW}
                        sendNotificationImmediately={sendNotificationImmediately}
                        sendNowBtnHandler={sendNowBtnHandler}

                        onSaveContentType={onSaveContentType}
                        onCancelContentType={onCancelContentType}

                        onSaveScheduling={onSaveScheduling}
                        onCancelScheduling={onCancelScheduling}
                        saveNewNotificationAndSaveNow={saveNewNotificationAndSaveNow}
                      />
                    ))}
                  </Category>
                </Col>
              </Row>

            </Col>
          </Row>

          <Row className="mt-3 mt-md-4">
            <Col className={`me-md-4 ${[TAB.GROUP, TAB.BOTH].includes(activeTab) ? 'd-block' : 'd-none'}`}>
              <ButtonPrimary onClick={openGroupModal}><i className="bi bi-plus-lg"></i> Add Group</ButtonPrimary>
            </Col>
            <Col className={`${[TAB.NOTIFICATION, TAB.BOTH].includes(activeTab) ? 'd-block' : 'd-none'}`}>
              <ButtonPrimary onClick={() => setShowNewNotificationModal(true)}><i className="bi bi-plus-lg"></i> Add Notification</ButtonPrimary>
            </Col>
          </Row>
          
        </Container>
      </DndProvider>
    </>
  );
};

export default CommandCenter;

export function GroupMember({
  isLast=false
}) {
  return (
    <Container fluid className="mt-2">
      <Row>
        <Col className="text-start d-flex align-items-center">John Smith</Col>
        <Col className="text-start d-flex align-items-center">Male, in Shelter XYZ, looking for housing in Norwalk, CT</Col>
        <Col className="text-end fw-bold d-flex align-items-center ms-auto text-wissp-violet">GO TO PROFILE</Col>
      </Row>
      {
        !isLast && <hr />
      }
    </Container>
  );
}




function Chip({ text, exit = false }) {
  return (
    <div className="d-flex chip flex-grow-0 align-content-between mx-2 my-1">
      <img src={placeholder} alt="Avatar Placeholder" />
      <span>{text}</span>
      {exit&&<i className="bi bi-x-circle-fill ms-3"></i>}
    </div>
  )
}
