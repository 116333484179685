import React, {useState, useEffect} from 'react'
import {
    Container,
    Row,
    Col,
    Form
} from 'react-bootstrap';


export default function MessageGenerator({
        title="", 
        isInput,
        inputRows=8, 
        optionsBottom,
        message,
        setMessage
    }) {
    const [splittedMessage, setSplittedMessage] = useState([]);

    useEffect(() => {
        setSplittedMessage(message.split("\n\n"));
    }, [message])

    return (

        <Container className="m-0  p-0 montserrat">
            { title && (
                <Row className="m-0 p-0">
                    <h5 className="m-0 mb-4 p-0 fw-bold">{title}</h5>
                </Row>
            )}
            <Row className="m-0 p-0 mb-3">

                {   !isInput && (
                        <Col className="border border-2 m-0 p-3 rounded">
                            
                            {
                                splittedMessage.map( (line, index, arr) => (
                                    <>
                                        <p className="m-0 p-0 fs-6" key={index}>
                                            {line}
                                        </p>
                                        { !(index === arr.length-1) && (
                                                <br key={'br' + index}/>
                                        )}
                                    </>
                                ))
                            }

                        </Col>
                )}

                {   isInput && (
                        <Form.Control 
                            onChange={(e) => setMessage(e.target.value)}
                            rows={inputRows}
                            className="p-2 px-4 fs-6"
                            as="textarea"  
                            value={message}   
                        />
                )}
            </Row>

            {
                optionsBottom && (
                    <Row className="m-0 p-0" >
                        {optionsBottom}
                    </Row>
                )
            }
        </Container>

    )
}
