import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';


export default function SelectFloating({
    label,
    errorClasses="custom_error pt-1",
    formClasses="rounded border shadow-sm customSelect fs-6",
    labelClasses="",
    controlId="floatingSelect",
    errorMessage,
    showError=false,
    register,
    size="lg",
    ariaLabel="Select",
    dataArray,
    dataArrayWithCustomVal,
    optionHidden=false,
    optionEmpty=false,
    children,
    ...rest
}) {
    return (
        <FloatingLabel
            controlId={controlId}
            label={label}
            className={labelClasses}
            >
            <Form.Select 
                size={size}
                aria-label={ariaLabel}
                className={formClasses}
                {...register}
                {...rest}
            >
                {optionHidden && (<option hidden></option>)}
                {optionEmpty && (<option value='' defaultChecked></option>)}
                {dataArray && dataArray.map((val) => {
                    return (
                        <option key={val} value={val}>
                            {val}
                        </option>
                    );
                })}
                {dataArrayWithCustomVal && dataArrayWithCustomVal.map(([name, val]) => {
                    return (
                        <option key={val} value={val}>
                            {name}
                        </option>
                    );
                })}
                {children}
            </Form.Select>

            { showError && (
                    <p className={errorClasses}>
                        { errorMessage }
                    </p>
                )
            }
        </FloatingLabel>
    )
}
