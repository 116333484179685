import Swal from 'sweetalert2';

export default function SuccessPopup(message, visibilityTime) {
    let timerInterval;
    return Swal.fire({
        position: 'top',
        html: `
            <div class="d-flex align-items-center justify-content-center">
                <i class="bi bi-check-circle text-success fs-4 me-2"></i><p class="m-0 fs-6">${message || "Success!"}</p>
            </div>
        `,
        timer: visibilityTime || 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        backdrop: false,
        willClose: () => {
            clearInterval(timerInterval)
        }
    })
}
