import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Accordion, FloatingLabel, Modal, Card } from 'react-bootstrap';

import FiltersBtn from "../components/Shared/Filters/FiltersBtn";
import ButtonWithLeftBorder from "../components/Shared/Buttons/ButtonWithLeftBorder";
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import PhoneWithMask from '../components/Shared/Inputs/PhoneWithMask';
import { STATES } from '../constants/Shared/states';


export default function StyleGuide() {
    const [showModal, setShowModal] = useState(false);
    const [isBtnActive, setIsBtnActive] = useState(false);

    return (
        <Container fluid style={{maxWidth: 1200}} className="m-auto mb-5 p-0">
            <Row className="m-0 p-0 mt-5 mb-3">
                <h2 className="m-0 p-0 fw-bold">1. Headers</h2>
            </Row>
            <Row className="m-0 p-0">
                <p className="m-0 p-0 mb-3 fs-6">h1 and h2 has mb-4, h3 has mb-3</p>
                <h1 className="m-0 p-0 mb-4 fw-bold">Header 1</h1>
                <h2 className="m-0 p-0 mb-4 fw-bold">Header 2</h2>
                <h3 className="m-0 p-0 mb-3 fw-bold">Header 3</h3>
                <h4 className="m-0 p-0 fw-bold">Header 4</h4>
                <h5 className="m-0 p-0 fw-bold">Header 5</h5>
                <h6 className="m-0 p-0 fw-bold">Header 6</h6>
            </Row>
            <hr className="my-3 p-0" />

            {/* PARAGRAPHS */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">2. Paragraphs</h2>
            </Row>
            <Row className="m-0 p-0">
                <p className="m-0 p-0 fs-6">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i>Mauris a urna at ligula pharetra dignissim in ac neque.</i> <strong>Sed viverra nibh pretium lacinia pharetra.</strong> Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                </p>
                <div className="mb-3"></div>
                <p className="m-0 p-0 fs-6 text-center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i>Mauris a urna at ligula pharetra dignissim in ac neque.</i> <strong>Sed viverra nibh pretium lacinia pharetra.</strong> Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                </p>
                <div className="mb-3"></div>
                <p className="m-0 p-0 fs-6 text-end">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i>Mauris a urna at ligula pharetra dignissim in ac neque.</i> <strong>Sed viverra nibh pretium lacinia pharetra.</strong> Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                </p>
            </Row>
            <hr className="my-3 p-0" />

            {/* BUTTONS */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">3. Buttons</h2>
            </Row>
            <Row className="m-0 p-0">
                Home Page Button
                <Col className="m-0 p-0 py-2" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12} sm={3}>
                            <Button className="m-0 p-3 p-sm-4 rounded-hp border-0 btn-purple w-100 ">
                                <h5 className="m-0 p-0 home-btn">Marketplace</h5>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                
                Standard Button
                <Col className="m-0 p-0 py-2" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12} sm={3}>
                            <ButtonPrimary 
                                title="Click me!"
                            />
                        </Col>
                    </Row>
                </Col>

                Button used in top of the form for navigation and other operations (Edit Unit form, Add New Unit form etc.).
                <Col className="m-0 p-0 py-2" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12} sm={3}>
                            <ButtonWithLeftBorder 
                                title='Cancel Edits'
                                additionalButtonClass='border-right-blue'
                                titleMobile={'Cancel'}

                                onClick={() => {}}
                            />
                        </Col>
                    </Row>
                </Col>

                Button used for legend and filter (Property - Unit, Requests).
                <Col className="m-0 p-0 py-2" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12} sm={1}>
                            <Col className={`rounded d-flex align-items-center justify-content-center text-center p-1 px-2 mb-2 cursor-pointer ${"darkerGray m-0"} ${isBtnActive ? 'border-down-green' : ''}`} 
                                xs={"auto"} 
                                // style={{boxShadow: isActive ? 'inset 0 0 0 2px black' : ''}} 
                                onClick={() => setIsBtnActive(prevState=>!prevState)}>
                                <span className={` ${isBtnActive ? "fw-bold" : ''}`} style={{fontSize: ".85rem"}}>{ "All" }</span>
                            </Col>
                        </Col>
                    </Row>
                </Col>





                Button used to open sidebar filters (off-canvas). When some filter is selected then button on the right is shown, when nothing is selected then left button is shown.

                <Col className="m-0 p-0 py-2" xs={12}>
                    <Row className="m-0 p-0">
                            
                            <FiltersBtn 
                                howManyFiltersActive={() => 0}
                                results={null}
                                resetFilters={() => {}}
                                openFilters={() => {}}
                                showResults={false}
                            />
                      
                            <Col className="m-0 p-0 pe-2" xs="auto"></Col>
                     
                            <FiltersBtn 
                                howManyFiltersActive={() => 2}
                                results={null}
                                resetFilters={() => {}}
                                openFilters={() => {}}
                                showResults={false}
                            />
                     
                    </Row>
                </Col>

            </Row>
            <hr className="my-3 p-0" />

            {/* ACCORDION */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">4. Accordion</h2>
            </Row>
            <Row className="m-0 p-0">
                <Accordion defaultActiveKey='0' className="m-0 p-0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Amenities</Accordion.Header>
                        <Accordion.Body>
                            <p className="m-0 p-0 fs-6">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <i>Mauris a urna at ligula pharetra dignissim in ac neque.</i> <strong>Sed viverra nibh pretium lacinia pharetra.</strong> Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Accessibilities</Accordion.Header>
                        <Accordion.Body>
                            <h3 className="m-0 p-0 mb-3 fw-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                            <p className="m-0 p-0 fs-6">
                                <i>Mauris a urna at ligula pharetra dignissim in ac neque.</i> <strong>Sed viverra nibh pretium lacinia pharetra.</strong> Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
            <hr className="my-3 p-0" />

            {/* INPUTS */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">5. Inputs</h2>
            </Row>
            <Row className="m-0 p-0">
                <Col className="m-0 p-0" xs={12}>Standard input:</Col>
                <Col className="m-0 p-2 pb-3 mb-3 bg-light" xs={12} sm={3}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3 "
                    >
                        <Form.Control type="text" placeholder="0" className='rounded border-0 shadow-sm'/>
                        <p className="custom_error pt-1">
                            Error Message 1
                        </p>
                    </FloatingLabel>
                </Col>

            
              
               <Col className="m-0 p-0" xs={12}>Select input:</Col>
              
      
               <Col className="m-0 p-2 mb-3 bg-light" xs={12} sm={3}>
                    <FloatingLabel
                        controlId="State"
                        label={"State"}
                        className="m-2 ms-0"
                    >
                        <Form.Select 
                            size="lg"
                            aria-label="Select state" 
                            className="rounded border-0 customSelect"
                            
                        >
                            <option value='' defaultChecked></option>
                            {STATES.split(",").map((state) => {
                        return (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        );
                    })}
                        </Form.Select>
                    </FloatingLabel>
               </Col>
               <Col className="m-0 p-0" xs={12}>Input with mask:</Col>

               
               <Col className="m-0 p-2 mb-3 bg-light" xs={12} sm={3}>
                <PhoneWithMask 
                        register={{ref: () => {}, onChange: ()=> {}, value: ''}}
                        inputData={{
                            id: 'Phone',
                            label: 'Phone number'
                        }}
                    />
               </Col>

            </Row>
            <hr className="my-3 p-0" />
            
            {/* MODAL */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">6. Modal</h2>
            </Row>
            <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0" xs={12} sm={3}>
                            <ButtonPrimary
                                onClick={() => setShowModal(true)}
                                >
                                Open Full Modal
                            </ButtonPrimary>
                        </Col>
                    </Row>

                <Modal
                    show={showModal}
                    onHide={ () => setShowModal(false) }
                    fullscreen
                    dialogClassName="modal-fullHeight"
                >
                    <Modal.Header closeButton />
                    <Modal.Body className='m-0 p-2'>
                        <p className="m-0 p-0 fs-6">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a urna at ligula pharetra dignissim in ac neque. Sed viverra nibh pretium lacinia pharetra. Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                            Mauris rutrum pulvinar ante, vel consequat risus consequat vel. Nunc nisl erat, hendrerit in fringilla eget, cursus in arcu. Proin nec urna nec arcu cursus cursus quis aliquam diam. Nam vel nibh vestibulum, dapibus ex lobortis, tempus nunc. Donec orci lorem, aliquam ut scelerisque sed, ultricies sed nunc. Praesent convallis magna at pretium fringilla. Integer eu pellentesque felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi porttitor odio id dui euismod luctus. Duis nec felis vel enim malesuada luctus. Vivamus sed placerat mauris, hendrerit fermentum dolor. Suspendisse a ex commodo nibh mollis laoreet. Duis pretium nec lorem sed vulputate.
                            Nullam ultrices quis justo vitae vulputate. Vestibulum gravida hendrerit magna eget commodo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam tincidunt porttitor ligula at fringilla. Integer lobortis sollicitudin aliquet. Donec vitae diam eu quam varius suscipit. Nulla eget odio vel massa tristique molestie. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam in semper odio. Aenean vel metus quis libero lobortis facilisis vitae non orci. Morbi ut nisl eget diam ornare suscipit.
                            Nam at consequat nunc. Cras at cursus nisi. Maecenas auctor leo et lectus mollis, quis tristique nibh ultrices. Duis eu nisi id massa vulputate varius. Proin porta arcu sed diam auctor, a dapibus metus aliquet. Aenean vulputate porta dolor, nec euismod neque. Aenean ac nisi auctor, sodales libero non, semper eros. Sed mauris tellus, imperdiet quis condimentum ut, pretium sed nulla. Etiam dictum dapibus lacus, ac rhoncus tellus venenatis non. Morbi placerat orci porta, varius nisl non, ullamcorper nisi. Aliquam neque metus, accumsan at massa a, consectetur efficitur felis.
                            Integer quis arcu dolor. Etiam sapien mi, convallis et tortor id, aliquam facilisis lectus. Etiam euismod ex sed venenatis egestas. Nullam ligula neque, ultricies sit amet magna eget, volutpat iaculis tortor. Curabitur non molestie leo. Maecenas vitae vehicula erat. Nam eget ex ac turpis fermentum aliquet vitae et risus. Nulla facilisi. Vivamus tempor semper urna, eget faucibus libero dapibus sit amet. Donec tincidunt eros vitae justo volutpat pellentesque. Aliquam erat volutpat. Nunc non facilisis nisl.
                        </p>
                    </Modal.Body>
                    <Modal.Footer className='m-0 p-0'>
                        <Row className="m-0 px-2 py-1 w-100">
                            <Col className="m-0 p-0" xs={6} sm={4} md={3} lg={2}>
                                <ButtonPrimary
                                    onClick={() => setShowModal(false)}
                                    >
                                    Close
                                </ButtonPrimary>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>

            </Row>
            <hr className="my-3 p-0" />

            {/* BOXES */}
            <Row className="m-0 p-0 mb-3">
                <h2 className="m-0 p-0 fw-bold">7. Boxes</h2>
            </Row>
            <Row className="m-0 p-0 mb-3">
                <h5 className="m-0 p-0 fw-bold">7.1. Variant 1 - when image in the background</h5>
            </Row>
            <Row className="m-0 p-0">
                <div className="custom_bg d-flex align-items-center justify-content-center">
                    <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
                        <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
                
                            <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12} sm={10} md={8} lg={7}>
                                <Row className="m-0 p-0 mb-4">
                                    <h3 className="m-0 p-0 fw-bold text-center">Reset Password</h3>
                                </Row>

                                <Row className="m-0 p-0" xs={12}>
                                
                                        
                                        <Row className="m-0 p-0">
                                            <Col className="m-0 p-0 mb-3" xs={12}>
                                                <p className="p-0 m-0 text-center">Please enter your email address. You will receive a link to create a new password via email.</p>
                                            </Col>

                                            <Col className="m-0 p-0" xs={12}>
                                                <FloatingLabel
                                                    controlId="Email"
                                                    label="Email address"
                                                >
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="0"
                                                    className="rounded shadow-sm border-0"
                                                
                                                    />
                                
                                                </FloatingLabel>
                                            </Col>
                        
                                            <Col className="m-0 p-0 mt-4" xs={12}>
                                                <ButtonPrimary title='Reset Password'/>
                                            </Col>
                                
                                        </Row>
                                
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Row>

            <Row className="m-0 p-0 my-3">
                <h5 className="m-0 p-0 fw-bold">7.2. Variant 2</h5>
            </Row>
            <Row className="m-0 p-0">
                <Container fluid style={{maxWidth: 1200}} className="m-auto mb-5 p-0">
                    <Card className="border-0 w-100" style={{ height: "100%" }}>
                            <Card.Body className="p-2 p-md-5 lightGray overflow-hidden">
                    
                                <Row className="m-0 p-0 mb-3 mb-sm-0 justify-content-end">
                                    <Col className="m-0 p-0 pe-1" xs={6} sm={4} md={3} xl={2}>
                                        <ButtonWithLeftBorder 
                                            title='Cancel Edits'
                                            additionalButtonClass='border-right-blue'
                                            titleMobile={'Cancel'}
                                        />
                                    </Col>
                                </Row>
                    
                                <Row className="m-0 p-0 mb-3">
                                    <Col className="m-0 p-0">
                                        <h2 className="fw-bold m-0 p-0">Lorem Ipsum</h2>
                                    </Col>
                                </Row>
                                <Row className="m-0 p-0">
                                <p className="m-0 p-0 fs-6">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a urna at ligula pharetra dignissim in ac neque. Sed viverra nibh pretium lacinia pharetra. Duis rutrum ac libero et ullamcorper. Fusce semper purus facilisis nibh cursus, sit amet faucibus turpis feugiat. Cras ut elit lectus. Praesent vel risus sit amet nulla pulvinar ornare. Donec tincidunt dui vitae massa viverra venenatis eu sed augue. Maecenas quis rutrum massa, sed tempus ligula.
                                    Mauris rutrum pulvinar ante, vel consequat risus consequat vel. Nunc nisl erat, hendrerit in fringilla eget, cursus in arcu. Proin nec urna nec arcu cursus cursus quis aliquam diam. Nam vel nibh vestibulum, dapibus ex lobortis, tempus nunc. Donec orci lorem, aliquam ut scelerisque sed, ultricies sed nunc. Praesent convallis magna at pretium fringilla. Integer eu pellentesque felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi porttitor odio id dui euismod luctus. Duis nec felis vel enim malesuada luctus. Vivamus sed placerat mauris, hendrerit fermentum dolor. Suspendisse a ex commodo nibh mollis laoreet. Duis pretium nec lorem sed vulputate.
                                    </p>
                                </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </Row>

            <hr className="my-3 p-0" />

        </Container>
    )
}
