import { getRequest, postPutRequest, deleteRequest } from './http-common';

const getOwnProfile = async (token) => {
    const URL = `profile/tenant`;

    return await getRequest(URL, token);
}

const getTenantProfile = async (token, tenantID) => {
    const URL = `profile/tenant/${tenantID}`;

    return await getRequest(URL, token);
}

const createTenantProfile = async (token, data) => {
    const URL = 'profile/tenant';

    return await postPutRequest(URL, data, token, 'post');
}

const editTenantProfile = async (token, data) => {
    const URL = 'profile/tenant';

    return await postPutRequest(URL, data, token, 'put');
}

const putTenantPicture = async (data, token) => {
    const URL = 'profile/tenant/pic';

    return await postPutRequest(URL, data, token, 'put');
}

const deleteTenantPicture = async (token) => {
    const URL = 'profile/teneant/pic';

    return await deleteRequest(URL, {}, token);
}

export { 
    getOwnProfile, 
    getTenantProfile, 
    createTenantProfile, 
    editTenantProfile,
    putTenantPicture,
    deleteTenantPicture
};
