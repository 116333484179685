import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

import { getStats } from '../services/user';
import { getSettings, changeSettings } from '../services/settings';

import { AuthContext } from "../contexts/AuthContext";
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import BackNav from "../components/Shared/BackNav";
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';


export default function PasswordChange() {
    const { token } = useContext(AuthContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ userData, setUserData ] = useState(null);
    const history = useHistory();

    const [ userSettings, setUserSettings ] = useState(null);
    const [ userSettingsLocal, setUserSettingsLocal ] = useState(null);
    
    
    const getStatsHandler = useCallback(async () => {
        if(!token) return;

        setIsLoading(true);
        const {success, data} = await getStats(token);

        if(success) {
            setUserData(data);
        } else {
            ErrorPopup();
        }

        setIsLoading(false);
    }, [token])

    const getSettingsHandler = useCallback(async () => {
        if(!token) return;

        setIsLoading(true);
        
        const { success, data } = await getSettings(token);

        if(success) {
            setUserSettings(data);
            setUserSettingsLocal(data);
        } else {
            ErrorPopup();
        }

        setIsLoading(false);
    }, [token])

    const toggleSettingHandler = (settingID) => {
        if( !settingID || !userSettings) return;

        setUserSettingsLocal( prevVal =>  ({
            ...prevVal,
            [settingID]: {
                Description: prevVal[settingID].Description,
                IsActive: !prevVal[settingID].IsActive
            }
        }));
    }

    const isSettingsChanged = (userSettingsFetched, userSettingsLocal) => {
        if(!userSettingsFetched || !userSettingsLocal) return false;

        return Object.entries(userSettingsFetched).some(([key, { IsActive }]) => {
            return IsActive !== userSettingsLocal[key].IsActive;
        });
    }

    const saveSettingsHandler = async () => {
        setIsLoading(true);

        const preapareBody = Object.entries(userSettingsLocal).reduce((acc, [key, { IsActive }]) => {
            
            acc[key] = IsActive;
            return acc;

        }, {});

        const { success } = await changeSettings(preapareBody, token);
        if(success) {
            setUserSettings( userSettingsLocal );
            SuccessPopup("Success! Saved notification settings.");
        } else {
            ErrorPopup();
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getStatsHandler();
        getSettingsHandler();
    }, [token, getStatsHandler, getSettingsHandler]);

    return (
        <Container fluid style={{maxWidth: 1200}} className="p-0 m-auto mb-5 overflow-hidden">

            <BackNav className="m-0 p-0 mb-3 px-2"
                currentPage="settings"
            />

            <Row className="m-0 p-2 py-3 p-sm-5 lightGray rounded">
                <Col className="mb-3 bg-white rounded overflow-hidden" xs={12}>
                    <Row className="py-2 text-center darkerGray">
                        <h5 className="m-0 p-0 fw-bolder text-uppercase">Information</h5>
                    </Row>

                    { isLoading ? (
                        <>
                            <Row className="m-2 p-1 darkerGray skeleton rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row className="m-2 p-1 darkerGray skeleton rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row className="m-2 p-1 darkerGray skeleton rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row className="m-2 p-1 darkerGray skeleton rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row className="m-2 p-1 darkerGray skeleton rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6">&nbsp;</p>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row className="m-2 p-1 lightGray rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6"><strong>Name:</strong></p>
                                </Col>
                                <Col xs={"auto"} sm={6}>
                                    <p className="m-0 p-0 fs-6">{(userData && userData.FirstName && userData.FirstName.trim()) || "-"} {(userData && userData.LastName && userData.LastName.trim()) || "-"}</p>
                                </Col>
                            </Row>

                            <Row className="m-2 p-1 lightGray rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6"><strong>User Type:</strong></p>
                                </Col>
                                <Col xs={"auto"} sm={6}>
                                    <p className="m-0 p-0 fs-6">{(userData && userData.UserType && userData.UserType.trim()) || "-"}</p>
                                </Col>
                            </Row>

                            { userData && userData.UserType !== "Tenant" && (
                                <>
                                    <Row className="m-2 p-1 lightGray rounded">
                                        <Col>
                                            <p className="m-0 p-0 fs-6"><strong>Total Properties:</strong></p>
                                        </Col>
                                        <Col xs={"auto"} sm={6}>
                                            <p className="m-0 p-0 fs-6">{(userData && userData.BuildingCount) || "-"}</p>
                                        </Col>
                                    </Row>

                                    <Row className="m-2 p-1 lightGray rounded">
                                        <Col>
                                            <p className="m-0 p-0 fs-6"><strong>Total Units:</strong></p>
                                        </Col>
                                        <Col xs={"auto"} sm={6}>
                                            <p className="m-0 p-0 fs-6">{(userData && userData.UnitCount) || "-"}</p>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row className="m-2 p-1 lightGray rounded">
                                <Col>
                                    <p className="m-0 p-0 fs-6"><strong>Account Created Date:</strong></p>
                                </Col>
                                <Col xs={"auto"} sm={6}>
                                    <p className="m-0 p-0 fs-6">{(userData && userData.AccountCreationDate && userData.AccountCreationDate.trim()) || "-"}</p>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>

                <Col className="mb-3 bg-white rounded overflow-hidden" xs={12}>
                    <Row className="py-2 text-center darkerGray">
                        <h5 className="m-0 p-0 fw-bolder text-uppercase">Password</h5>
                    </Row>

                    <Row className="m-0 p-0 py-3" xs={12}>
                        <ButtonPrimary 
                            title="Change Password"
                            onClick={() => history.push('/change-password')}
                            type="button"
                        />
                    </Row>
                </Col>

                <Col className="bg-white rounded overflow-hidden" xs={12}>
                    <Row className="py-2 text-center darkerGray">
                        <h5 className="m-0 p-0 fw-bolder text-uppercase">Notifications</h5>
                    </Row>

                    <Row className="m-0 p-0 py-2 py-3">
                        { userSettingsLocal && Object.entries(userSettingsLocal).map(([settingID, { Description, IsActive }]) => (
                            <Form.Check
                                key={`accessiblity ${settingID}`}
                                id={`accessiblity ${settingID}`}
                                value={settingID}
                                type="checkbox"
                                label={Description || '-'}
                                htmlFor={`notification_setting_${settingID}`}
                                onChange={() => toggleSettingHandler(settingID)}
                                checked={ IsActive }
                            />
                        ))}

                        <Col className="m-0 p-0 mt-2" xs={12}>
                            <ButtonPrimary 
                                title="Update"
                                onClick={saveSettingsHandler}
                                type="button"
                                disabled={ !isSettingsChanged(userSettings, userSettingsLocal) }
                            />
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Container>
    )
}
