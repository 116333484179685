import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function EmptyFavorites({
    favoriteType
}){
    const history = useHistory();

    return(
        <Container className='m-0 p-0' fluid>
            <Row className="m-0 p-0 mt-3">
                <Col className="m-0 p-0">
                    <h6 className="fw-bold text-center">Add your favorite {favoriteType} by beginning a search</h6>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-3 justify-content-center">
                <Col className="m-0 p-0" xs="auto">
                    <ButtonPrimary
                        onClick={() => history.push('/marketplace')}
                    >
                        <p className="m-0 p-0 fs-6 text-light"><i className="bi bi-shop-window"></i>&nbsp;Marketplace</p>
                    </ButtonPrimary>
                </Col>
            </Row>
        </Container>
    )
}
