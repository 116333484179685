import React, { useState, useRef, useLayoutEffect } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import StreetViewLoad from './StreetViewLoad';
import StreetViewError from './StreetViewError';
import StreetView from './StreetView';
import { useEffect } from 'react';


const render = (status) => {
    if (status === Status.LOADING) return <StreetViewLoad />
    if (status === Status.FAILURE) return <StreetViewError />
    return null;
}

/**
 * 
 * @param {Object} position { lat: Number, lng: Number }
 */

const getPos = async (position) => {
    if(position){
        try {
            const res = await new window.google.maps.StreetViewService().getPanorama({
                location: position,
                source: "outdoor",
                // preference: "best"
            })
            return res
        } catch (err) {
            const res = await new window.google.maps.StreetViewService().getPanorama({
                location: position,
                // source: "outdoor",
                // preference: "best"
            })
            return res
        }
    }
}

export default function StreetViewWrapper({
    position,
    setMapPov = false,
    mapPov = "empty",
    setMapPos = false,
    mapPos = "empty",
    mapData,
    continueBtn,
    setSuccess
}) {
    const [streetViewAvailable, setStreetViewAvailable] = useState(false);
    useLayoutEffect(() => {
        if (mapPos && mapPos.lat) {
            new window.google.maps.StreetViewService()
                .getPanorama({ location: { lat: mapPos.lat, lng: mapPos.lng }, radius: 50 })
                .then(res =>{
                    setStreetViewAvailable(true);
                    setSuccess(true)
                })
                .catch(() => {
                    setStreetViewAvailable(false);
                    setSuccess(false)
                })
        }
        if (position && position.lat) {
            new window.google.maps.StreetViewService()
                .getPanorama({ location: { lat: position.lat, lng: position.lng }, radius: 50 })
                .then(res =>{
                    setStreetViewAvailable(true);
                })
                .catch(() => {
                    setStreetViewAvailable(false);
                })
        }
    }, [mapPos, position])

    const [correctedPos, setCorrectedPos] = useState()

    const newPosRef = useRef(null)

    useEffect(() => {
        if (position && !newPosRef.current) {
            newPosRef.current = getPos(position);
            newPosRef.current.then(r => {
                setCorrectedPos({
                    lat: r.data.location.latLng.lat(),
                    lng: r.data.location.latLng.lng()
                })
            })

            // new window.google.maps.StreetViewService().getPanorama({
            //     location: position,
            //     source: "outdoor",
            //     // preference: "best"
            // })
            //     .then((res) => {
            //         setCorrectedPos({
            //             lat: res.data.location.latLng.lat(),
            //             lng: res.data.location.latLng.lng()
            //         })
            //     })
            //     .catch(err=>{

            //         setCorrectedPos(position)
            //     })
        }
        // else if(newPosRef.current){
        //     setCorrectedPos({
        //         lat: newPosRef.current.location.latLng.lat(),
        //         lng: newPosRef.current.location.latLng.lng()
        //     })
        // }
    }, [position])

    if(!streetViewAvailable) {
        if(setMapPos){
            setMapPos(position);
        }
        return <StreetViewError message='StreetView for given address and nearest radius is not available.' note='Please add photo representing your building or check if address is typed correctly!'/>
    };

    return (
        <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            render={render}
        >
            <StreetView
                position={correctedPos}
                streetViewAvailable={streetViewAvailable}
                setMapPov={setMapPov}
                mapPov={mapPov}
                setMapPos={setMapPos}
                mapPos={mapPos}
                mapData={mapData}
                continueBtn={continueBtn}
            // mapDetails={mapDetails}
            />
        </Wrapper>
    )
}




