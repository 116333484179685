import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import LazyImage from "../../Shared/LazyImage";
import preparePhotos from "../../../utils/preparePhotos";
import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";
import "react-toggle/style.css";
import {
  CustomAccordionElement,
  Accordion as CustomAccordion,
  useCustomAcordionContext,
} from "../../Shared/Accordion";
import ButtonWithLeftBorder from "../../Shared/Buttons/ButtonWithLeftBorder";
import ManagersForm from "../../BuildingUpdate/ManagersForm";
import { propertiesDataActions } from "../../../store/PropertiesSlice";
import {
  updateBuildingInfo,
  removeManager as removeManagerRequest,
} from "../../../services/building";
import {
  NAME,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  STATE,
  ZIP,
  MISC_AMENITIES,
  MISC_ACCESSIBILITIES,
  LANDLORD_CONTACT_FULLNAME,
  LANDLORD_CONTACT_EMAIL,
  LANDLORD_CONTACT_PHONE,
  MANAGERS,
  MANAGER_ID,
  MANAGER_FULL_NAME,
  MANAGER_EMAIL,
  MANAGER_PHONE,
  LANDLORD_CONTACT_ID,
  MANAGER_FIRST_NAME,
  MANAGER_LAST_NAME,
} from "../../../constants/buildingFormConstants";
import { AuthContext } from "../../../contexts/AuthContext";
import { useDispatch } from "react-redux";
import SuccessPopup from "../../Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../../Shared/InfoPopups/ErrorPopup";
import { inviteManager } from "../../../services/account";
import axios from "axios";
import LogoLoader from "../../Shared/LogoLoader";
import orderBy from "lodash/orderBy";

export default function PropertiesManagers({
  loading = true,
  properties,
  isError,
  filters,
  token,
  userType,
  sort,
  setSort
}) {
  const [data, setData] = useState(null);
  const sortAscending = (propertyArray, key = "Name") => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [key], ["asc"]);
  };

  const sortDescending = (propertyArray, key = "Name") => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [key], ["desc"]);
  };

  useEffect(() => {
    if (properties) {
      let _data = { properties: [...properties], managers: {} };
      properties.forEach((p) => {
        p.Managers?.forEach((m) => {
          if (m.FullName === "self") return;
          if (!_data.managers[m.Email]) {
            _data.managers[m.Email] = {
              ...m,
              properties: [],
            };
          }
          _data.managers[m.Email].properties.push(p);
        });
        _data.managers = Object.values(_data.managers);
      });
      setData(_data);
    }
  }, [properties]);

  useEffect(() => {
    if(filters.Alphabetically === 'asc'){
      const managers = sortAscending(data.managers, "FullName");
      const properties = sortAscending(data.properties);

      setData({managers, properties});
    } else if(filters.Alphabetically === 'desc'){
      const managers = sortDescending(data.managers, "FullName");
      const properties = sortDescending(data.properties);

      setData({managers, properties});
    }
  }, [filters.Alphabetically]);

  const [modalProperty, setModalProperty] = useState(null);
  return (
    <Row className="m-0 p-0">
      {data?.[sort]?.length > 0 && (
        <CustomAccordion>
          {sort === "managers" &&
            data.managers.map((manager, i) => {
              return (
                <CustomAccordionElement
                  className="m-0 p-0"
                  key={i}
                  eventKey={i}
                  customHeaderElement={
                    <CustomManagerHeader
                      manager={manager}
                      eventKey={i}
                      className="mt-4"
                    />
                  }
                >
                  {manager.properties.map((p, i) => (
                    <ManagerProperty
                      property={p}
                      key={i}
                      setModalProperty={setModalProperty}
                    />
                  ))}
                </CustomAccordionElement>
              );
            })}
          {sort === "properties" &&
            data.properties
              .filter((property) => property?.Managers?.some( manager => manager.FullName !== "self" ) )
              .map((property, i) => {
                return (
                  <CustomAccordionElement
                    className="m-0 p-0"
                    key={i}
                    eventKey={i}
                    customHeaderElement={
                      <CustomPropertyHeader
                        eventKey={i}
                        property={property}
                        setModalProperty={setModalProperty}
                        className="mt-4"
                      />
                    }
                  >
                    {property.Managers?.filter(
                      (m, i) => m.FullName !== "self"
                    ).map((m, i) => (
                      <PropertyManager manager={m} key={i} />
                    ))}
                  </CustomAccordionElement>
                );
              })}
        </CustomAccordion>
      )}

      <EditPropertyManagersModal
        property={modalProperty}
        setModalProperty={setModalProperty}
      />
    </Row>
  );
}

function CustomManagerHeader({
  children,
  eventKey,
  className = "",
  manager,
  ...props
}) {
  const [opened] = useCustomAcordionContext();
  const isOpen = opened.includes(eventKey);
  return (
    <div
      className={`m-0 p-0 border-0 w-100 h-100 rounded ${className}`}
      {...props}
    >
      <Container className="rounded lightGray text-start cursor-pointer">
        <Row>
          <Col
            xs="auto"
            className="d-flex justify-content-center align-items-center"
          >
            <p className="my-3 ms-2 fs-4 text-muted">{eventKey + 1}</p>
          </Col>
          <Col className="my-3 px-2 px-sm-5 ps-sm-2">
            <h4>{manager.FullName.replace("$*|*$", " ")}</h4>
            <p className="m-0 p-0 fs-6 text-muted">
              Email: {manager.Email} | Phone Number: {manager.Phone}
            </p>
          </Col>
          <Col xs="auto">
            <p className="my-4">Properties: {manager.properties.length}</p>
          </Col>
          <Col xs="auto" className="d-flex align-items-start my-3 px-2 px-sm-5">
            <i
              className={`bi bi-chevron-down rotate180 ${
                isOpen ? "rotate" : ""
              } fs-4 text-muted`}
            ></i>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CustomPropertyHeader({
  eventKey,
  className = "",
  property,
  setModalProperty,
  ...props
}) {
  const [opened] = useCustomAcordionContext();
  const isOpen = opened.includes(eventKey);
  const preparedPhotos = preparePhotos(property);
  return (
    <div
      className={`m-0 p-0 border-0 w-100 h-100 rounded ${className}`}
      {...props}
    >
      <Container className="rounded lightGray text-start cursor-pointer">
        <Row>
          <Col
            xs="auto"
            className="m-0 p-0 d-none d-lg-flex"
            style={{
              backgroundImage: `url('${property && property.PhotoURL}')`,
              width: 200,
              height: 150,
              backgroundSize: "cover",
              backgroundPosition: "50%,50%",
            }}
          >
            <LazyImage
              src={preparedPhotos[0]}
              style={{
                objectFit: "cover",
                height: 150,
                width: 200,
              }}
            />
          </Col>
          <Col className="my-3 px-2 px-sm-5">
            <h4>{property.Name}</h4>
            <p className="m-0 p-0 fs-6 text-muted">
              <AddressComponent
                address={property.Address}
                city={property.City}
                state={property.State}
                zip={property.Zip}
              />
            </p>
            <p className="d-flex m-0">
              <span>Managers: </span>
              <span className="mx-1">{property.Managers?.filter( manager => manager?.FullName !== 'self').length ?? 0}</span>
            </p>
          </Col>
          <Col
            xs="auto"
            className="d-flex flex-column align-items-end justify-content-between my-3 px-2 px-sm-5"
          >
            <i
              className={`bi bi-chevron-down rotate180 ${
                isOpen ? "rotate" : ""
              } fs-4 text-muted`}
            ></i>
            <ButtonWithLeftBorder
              showIcon={true}
              title="Edit Property Manager Info"
              iconClass="bi-pencil-fill"
              additionalButtonClass="border-right-blue text-start ps-3 pe-3"
              onClick={(e) => {
                e.stopPropagation();
                setModalProperty(property);
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function ManagerProperty({ property, setModalProperty }) {
  return (
    <Container className="lightGray rounded p-3 my-2">
      <Row>
        <Col xs="auto">
          <p className="m-0">Property: {property.Name}</p>
          <AddressComponent address={property.Address} city={property.City} state={property.State} zip={property.Zip}/>
        </Col>
        <Col></Col>
        <Col xs="auto" className="d-flex align-items-center px-2 px-sm-5">
          <ButtonWithLeftBorder
            showIcon={true}
            title="Edit Property Manager Info"
            iconClass="bi-pencil-fill"
            additionalButtonClass="border-right-blue text-start ps-3 pe-3"
            onClick={(e) => {
              e.stopPropagation();
              setModalProperty(property);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

function AddressComponent({ address, city, state, zip }) {
  return (
    <span className="text-muted">
      <i className="bi bi-geo-alt"></i> {address.replace(/\$\*\|\*\$/, " ")},&nbsp;
      {city}, {state}&nbsp;{zip}
    </span>
  );
}

function PropertyManager({ manager }) {
  return (
    <Container className="lightGray rounded p-3 my-2">
      <Row>
        <Col xs="auto">
          <p className="m-0">{manager.FullName.replace("$*|*$", " ")}</p>
          <p className="m-0 p-0 fs-6 text-muted">
            Email: {manager.Email} | Phone Number: {manager.Phone}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function EditPropertyManagersModal({ property, setModalProperty }) {
  const [isSaving, setIsSaving] = useState(false);
  const [managerAccountStatus, setManagerAccountStatus] = useState({});

  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();

  function prepareManagers(data) {
    let Managers = [];

    if (data && Array.isArray(data[MANAGERS])) {
      Managers = data[MANAGERS].map((manager) => ({
        [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
        [MANAGER_FULL_NAME]: `${manager[MANAGER_FIRST_NAME]}$*|*$${manager[MANAGER_LAST_NAME]}`,
        [MANAGER_PHONE]: manager[MANAGER_PHONE],
      }));
    }

    if (data[LANDLORD_CONTACT_PHONE]) {
      const LandlordData = {
        [MANAGER_FULL_NAME]: data[LANDLORD_CONTACT_FULLNAME],
        [MANAGER_EMAIL]: data[LANDLORD_CONTACT_EMAIL],
        [MANAGER_PHONE]: data[LANDLORD_CONTACT_PHONE],
      };

      if (data[LANDLORD_CONTACT_ID]) {
        LandlordData[MANAGER_ID] = data[LANDLORD_CONTACT_ID];
      }

      Managers.push(LandlordData);
    }

    return Managers;
  }
  const checkManagerAccountStatus = async (managers) => {
    const preparedRequests = managers
      .filter((manager) => manager.FullName !== "self")
      .map((manager) => {
        const splittedName = manager[MANAGER_FULL_NAME].split("$*|*$");
        return {
          [MANAGER_FIRST_NAME]: splittedName[0] || manager[MANAGER_FULL_NAME],
          [MANAGER_LAST_NAME]: splittedName[1] || "",
          [MANAGER_EMAIL]: manager[MANAGER_EMAIL],
        };
      })
      .map((manager) => inviteManager(manager, token));

    const result = await axios.all(preparedRequests);

    setManagerAccountStatus(
      result.reduce((acc, { data, Email }) => {
        return { ...acc, [Email]: data };
      }, {})
    );
  };
  const preparedDataForUpdate = (o_newData) => {
    const preparedData = {
      [NAME]: property[NAME],
      BuildingType: "Co-op",
      BuildingID: property.BuildingID,
      Address: property.Address,
      [CITY]: property[CITY],
      [STATE]: property[STATE],
      [ZIP]: property[ZIP],
      Longitude: property.Longitude,
      Latitude: property.Latitude,
      [MANAGERS]: property.Managers,
      [MISC_AMENITIES]: property[MISC_AMENITIES],
      [MISC_ACCESSIBILITIES]: property[MISC_ACCESSIBILITIES],
      Notes: property.Notes,
    };

    return {
      ...preparedData,
      ...o_newData,
    };
  };
  const onSubmitManagersInfo = async (formData) => {
    if (formData.Managers && !formData.Managers.length) return;

    const Managers = { Managers: prepareManagers(formData) };
    const postBody = preparedDataForUpdate(Managers);

    setIsSaving(true);
    const { success, data } = await updateBuildingInfo(
      property.BuildingID,
      postBody,
      token
    );
    setIsSaving(false);

    if (success) {
      SuccessPopup("Property Contact Info Updated!");
      dispatch(
        propertiesDataActions.editProperty({
          BuildingID: property.BuildingID,
          Managers: data.Managers,
        })
      );
      setModalProperty(false);
      checkManagerAccountStatus(data.Managers);
    } else {
      ErrorPopup();
    }
    // TODO: show popup only when there were new managers added? Check if in formData there are managers without BuildingManagerID
  };
  const removeManagerFromDB = async (managerID) => {
    setIsSaving(true);
    const result = await removeManagerRequest(
      property.BuildingID,
      managerID,
      token
    );
    setIsSaving(false);

    const { success } = result;
    if (success) {
      dispatch(
        propertiesDataActions.removeManagerFromProperty({
          BuildingID: property.BuildingID,
          BuildingManagerID: managerID,
        })
      );
      setModalProperty(false);
      SuccessPopup("Success! Manager was removed");
    } else {
      ErrorPopup();
    }

    return success;
  };
  return (
    <Modal
      show={!!property}
      onHide={() => setModalProperty(null)}
      size="lg"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {isSaving && <LogoLoader height="300px" />}
        {!isSaving && (
          <>
            <ManagersForm
              onSubmitManagersInfo={onSubmitManagersInfo}
              building={property}
              onRemoveManager={removeManagerFromDB}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
