import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Modal,
} from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext";
import UploadImage from './../components/Shared/UploadImage';
import OccupantInformation from "../components/UnitUpdate/OccupantInformation";
import AdditionalInformation from "../components/UnitUpdate/AdditionalInformation";
import UpdateUnitBasic from '../components/UnitUpdate/UpdateUnitBasic';
import useUpdateImages from "../hooks/useUpdateImages";
import UpdatingImagesInfo from '../components/Shared/UpdatingImagesInfo';
import { uploadImages, deleteImages } from "../services/imagesRequests";
import FeaturesInformation from "../components/UnitUpdate/FeaturesInformation";
import { arrFromObjVal, objFromArrAndFilledWithValue, arrFromObjKeysFilteredByVal } from '../utils/dataTransformations';

import SuccessPopup from "../components/Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import DecisionModal from "../components/Shared/InfoPopups/DecisionModal";

import ButtonWithLeftBorder from "../components/Shared/Buttons/ButtonWithLeftBorder";

import BackNav from "../components/Shared/BackNav";

import {
    getUnitFeatures,
    updateFeatures,
    updateInfo,
    deleteUnit,
    deleteOccupant,
    editOccupant,
    setFavoriteUnitPhoto
} from '../services/units';
import { getBuilding } from '../services/building';
import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';
import AddNewUnitFormLoader from "../components/AddNewUnitForm/Loader";

import { useSelector, useDispatch } from 'react-redux';
import { propertiesDataActions } from "../store/PropertiesSlice";
import {
    NAME, 
    SQ_FOOTAGE, 
    BEDROOM_COUNT, 
    BATHROOM_COUNT, 
    FLOOR_ID,
    RENT,
    PAYMENT_DETAILS,
    PAYMENT_DUE_DATE,
    OCCUPANT_FULL_NAME,
    OCCUPANT_EMAIL,
    OCCUPANT_PHONE_NUMBER,
    OCCUPANT_LEASE_START,
    OCCUPANT_LEASE_END,
    OCCUPANT_NOTES,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    MISC_NOTES
} from "../constants/unitFormConstants";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function Apartment() {
    let query = useQuery();
    const buildingID = Number.parseInt(query.get('building-id'));
    const unitID = Number.parseInt(query.get("unit-id"));
    const history = useHistory();
    const [accessibilities, setAccessibilities] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [sectionFocus, setSectionFocus] = useState(null)
    const { token } = useContext(AuthContext);
    const [unit, setUnit] = useState(null);
    const [features, setFeatures] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadedImages, setDownloadedImages] = useState([]);
    const [showOnSubmitImagesModal, setShowOnSubmitImagesModal] = useState(false);
    const [isUploadingImages, setIsUploadingImages] = useState(false);
    const [imagesToBeUploaded, setImagesToBeUploaded] = useState(false);
    const [isUploadingImagesSuccess, setIsUploadingImagesSuccess] = useState(null);
    const [isDeletingImages, setIsDeletingImages] = useState(false);
    const [isDeletingImagesSuccess, setIsDeletingImagesSuccess] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [imagesToBeDeleted, setImagesToBeDeleted] = useState(false);
    const {
        images,
        setImages,
        setImagesToDelete,
        imagesToDeleteIDs,
        onlyNewImages
    } = useUpdateImages();

    const dispatch = useDispatch();
    const propertiesStore = useSelector( store => store.propertiesStore.properties );
    const unitFeaturesStore = useSelector( store => store.propertiesStore.unitFeatures );
  
    useEffect(() => {
        if (!isEmpty(unit)) {
            const images = unit.Photos;
            setDownloadedImages(images);
        }

        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
    }, [unit])

    const fetchedFeatures = useCallback(async () => {
        if(unitFeaturesStore) {
            setData(unitFeaturesStore);
            return;
        }
        setLoading(true);
        const response = await getUnitFeatures();
        const { success, data } = response;

        if (success) {
            if (!data) {
                setFeatures([]);
                ErrorPopup("Error! Something went wrong. Received an empty features object.");
            }
            dispatch(propertiesDataActions.setUnitFeatures(data));
            setData(data);
        } else {
            ErrorPopup();
        }

        setLoading(false);

        function setData(data) {
            const transformedObject = {
                Accessibilities: arrFromObjVal(data.Accessibilities),
                Amenities: arrFromObjVal(data.Amenities)
            }

            setFeatures(transformedObject);
        }
    }, []);

    const fetchedUnits = useCallback(async () => {
        const findProperty = propertiesStore.find( property => property.BuildingID === Number(buildingID));
        if(findProperty) {
            setData(findProperty);
            return;
        }

        setLoading(true);

        const response = await getBuilding(buildingID, token);
        const { success, data } = response;

        if (success) {
            dispatch(propertiesDataActions.addProperty(data));
            setData(data);
        } else {
            ErrorPopup();
        }
        setLoading(false);

        function setData(data) {
            const searchedUnit = data.Units.find(unit => {
                return unit.UnitID === Number.parseInt(unitID);
            });

            const prepareAccessibilities = objFromArrAndFilledWithValue(searchedUnit.Accessibilities);
            const prepareAmenities = objFromArrAndFilledWithValue(searchedUnit.Amenities);

            setAccessibilities(prepareAccessibilities);
            setAmenities(prepareAmenities);
            setUnit(searchedUnit);
        }
    }, [token, buildingID, unitID]);

    useEffect(() => {
        if (!unitID) {
            history.goBack()
        }

        fetchedFeatures();
        fetchedUnits();

    }, [fetchedFeatures, fetchedUnits, unitID, history]);

    const updateInfoHandler = async (body, setData) => {
        setIsSaving(true);
        
        const response = await updateInfo(unitID, body, token);
        const { success } = response;

        setIsSaving(false);

        if (success) {
            SuccessPopup("Success! Unit updated successfully!");

            setUnit(prevValues => ({
                ...prevValues,
                ...setData
            }));
            dispatch(propertiesDataActions.editUnitInProperty(body));
        } else {
            ErrorPopup();
        }
    }

    const onSubmitBasics = async (buildingData) => {
        const body = {
            ...unit,
            Name: buildingData[NAME],
            BedroomCount: buildingData[BEDROOM_COUNT],
            BathroomCount: buildingData[BATHROOM_COUNT],
            SquareFootage: buildingData[SQ_FOOTAGE],
            FloorID: buildingData[FLOOR_ID]
        }

        const setDataIfSuccess = {
            Name: buildingData[NAME],
            BedroomCount: buildingData[BEDROOM_COUNT],
            BathroomCount: buildingData[BATHROOM_COUNT],
            SquareFootage: buildingData[SQ_FOOTAGE],
            FloorID: buildingData[FLOOR_ID]
        };

        updateInfoHandler(body, setDataIfSuccess);
    };

    const updateFeaturesHandler = async (body) => {
        setIsSaving(true);

        const response = await updateFeatures(unitID, body, token);
        const { success } = response;

        setIsSaving(false);

        if (success) {
            SuccessPopup("Success! Unit updated successfully!");

            dispatch(propertiesDataActions.editUnitInProperty({
                BuildingID: buildingID,
                UnitID: unitID,
                ...body
            }));

        } else {
            ErrorPopup();
        }

    }

    const onSubmitFeatures = (data) => {
        const accessibilitiesArray = arrFromObjKeysFilteredByVal(accessibilities);
        const amenitiesArray = arrFromObjKeysFilteredByVal(amenities);

        const body = {
            Amenities: amenitiesArray,
            Accessibilities: accessibilitiesArray,
            MiscAccessibilities: data[MISC_ACCESSIBILITIES],
            MiscAmenities: data[MISC_AMENITIES],
        };

        updateFeaturesHandler(body);
    };

    const onHideHandler = () => {
        setShowOnSubmitImagesModal(false);

        setIsUploadingImages(false);
        setImagesToBeUploaded(false);
        setIsUploadingImagesSuccess(null);

        setIsDeletingImages(false);
        setImagesToBeDeleted(false);
        setIsDeletingImagesSuccess(null);
        setImagesToDelete([]);
    }

    const updateFavoritePhoto = async (unit, images, unitID) => {
        

        if(!unit || !unit.Photos || !unit.Photos.length ) return;

        const getOldFavoritePhoto = unit.Photos.find( photo => photo.IsFavorite);
        const getNewFavoritePhoto = images.find( photo => photo.isFavorite);

        if(!getOldFavoritePhoto || !getNewFavoritePhoto) return;
        if((getOldFavoritePhoto && getNewFavoritePhoto) && (getOldFavoritePhoto.PhotoID === getNewFavoritePhoto.id)) return;
        if(!getNewFavoritePhoto.isInDB) return;

        const newFavoritePhotoID = getNewFavoritePhoto.id;

        const result = await setFavoriteUnitPhoto(unitID, newFavoritePhotoID, token);

        if(result.success) {
            dispatch(propertiesDataActions.setUnitFavoritePhotos({
                buildingID,
                unitID,
                photoID: newFavoritePhotoID
            }));
        }

        return result;
    }

    const onFavoriteClickHandler = async (imageID) => {
        const updatedImages = images.map((image) => {
            if(image.id === imageID) { 
                return {
                    ...image,
                    isFavorite: true
                }
            }
            return {
                ...image,
                isFavorite: false
            }
        })

        if(!unit || !unit.Photos || !unit.Photos.length ) return;

        const getOldFavoritePhoto = unit.Photos.find( photo => photo.IsFavorite);
        const getNewFavoritePhoto = updatedImages.find( photo => photo.isFavorite);

        if(!getOldFavoritePhoto || !getNewFavoritePhoto) return;
        if((getOldFavoritePhoto && getNewFavoritePhoto) && (getOldFavoritePhoto.PhotoID === getNewFavoritePhoto.id)) return;
        if(!getNewFavoritePhoto.isInDB) {
            setImages( updatedImages );
            return;
        }

        const newFavoritePhotoID = getNewFavoritePhoto.id;

        setIsSaving(true);
        const result = await setFavoriteUnitPhoto(unitID, newFavoritePhotoID, token);
        setIsSaving(false);
        if(result.success) {
            setImages( updatedImages );
            SuccessPopup("Success! Changes favorite photo.");
            dispatch(propertiesDataActions.setUnitFavoritePhotos({
                buildingID,
                unitID,
                photoID: newFavoritePhotoID
            }));
        }

        return result;
    }

    const onSubmitImages = async () => {
        const unitID = query.get("unit-id");
        const deleteURL = `unit/${unitID}/delete/photos`;
        const uploadURL = `unit/${unitID}/upload/photos`;

        const toUpload = onlyNewImages();
        const toDelete = imagesToDeleteIDs();

        await updateFavoritePhoto(unit, images, unitID);

        if (!toUpload && !toDelete) return;
        const isToDeleteEmpty = !toDelete.length;
        const isToUploadEmpty = !toUpload.length;

        if (isToDeleteEmpty && isToUploadEmpty) return;

        setShowOnSubmitImagesModal(true);
        if (!isToDeleteEmpty) {
            setIsDeletingImages(true);
            setImagesToBeDeleted(true);
        }
        if (!isToUploadEmpty) {
            setIsUploadingImages(true);
            setImagesToBeUploaded(true);
        }

        // DELETING SECTION ----------------------------------------------------------------
        if (!isToDeleteEmpty) {
            const response = await deleteImages(toDelete, unitID, deleteURL, token);
            const { success } = response;
           
            if(success) {
                dispatch(propertiesDataActions.deleteUnitPhotos({
                    BuildingID: buildingID,
                    UnitID: unitID,
                    PhotoIDs: toDelete
                }))
            }

            setIsDeletingImagesSuccess(success);
            setIsDeletingImages(false);
        }

        // UPLOADING SECTION ---------------------------------------------------------------
        if (!isToUploadEmpty) {
            const response = await uploadImages(toUpload, uploadURL, token);
            const { success, data } = response;

            if (success) {
                setDownloadedImages(data.data); // TODO: check if can delete this instrucion?

                dispatch(propertiesDataActions.editUnitInProperty({
                    BuildingID: buildingID,
                    UnitID: unitID,
                    Photos: data.data
                }))

            }

            setIsUploadingImagesSuccess(success);
            setIsUploadingImages(false);
        }
    };

    const onSubmitAdditional = async (buildingData) => {
        const body = {
            ...unit,
            Rent: buildingData[RENT],
            PaymentDetails: buildingData[PAYMENT_DETAILS],
            PaymentDueDate: buildingData[PAYMENT_DUE_DATE],
            Notes: buildingData[MISC_NOTES],
        }

        const setDataIfSuccess = {
            Rent: buildingData[RENT],
            PaymentDetails: buildingData[PAYMENT_DETAILS],
            PaymentDueDate: buildingData[PAYMENT_DUE_DATE],
            Notes: buildingData[MISC_NOTES],
        };

        updateInfoHandler(body, setDataIfSuccess);
    };

    const onDeleteUnit = async () => {
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this unit!"
        })

        if(!decision.isConfirmed) return;

        setIsSaving(true);
        const response = await deleteUnit(unitID, {}, token, 'post');
        const { success } = response;
        setIsSaving(false);

        if (success) {
            dispatch(propertiesDataActions.deleteUnitFromProperty({BuildingID: buildingID, UnitID: unitID}));
            SuccessPopup("Success! Unit was deleted!");

            history.replace(`/building-info?building-id=${query.get('building-id')}`);
        } else {
            ErrorPopup();
        }
    };

    const editOccupantHandler = async (occupantData) => {
        if (!occupantData) return;

        const body = {
            FullName: occupantData[OCCUPANT_FULL_NAME],
            Email: occupantData[OCCUPANT_EMAIL],
            Phone: occupantData[OCCUPANT_PHONE_NUMBER],
            LeaseStart: occupantData[OCCUPANT_LEASE_START] ? new Date(occupantData[OCCUPANT_LEASE_START]).toLocaleDateString('en-US') : null,
            LeaseEnd: occupantData[OCCUPANT_LEASE_END] ? new Date(occupantData[OCCUPANT_LEASE_END]).toLocaleDateString('en-US') : null,
            Comments: occupantData[OCCUPANT_NOTES]
        }

        setIsSaving(true);

        const response = await editOccupant(unitID, body, token);
        const { success } = response;

        setIsSaving(false);

        if (success) {
            setUnit(prevValues => ({
                ...prevValues,
                Vacant: false,
                Tenant: {
                    ...body
                }
            }));
            dispatch(propertiesDataActions.editUnitInProperty({
                UnitID: unitID,
                BuildingID: buildingID,
                Vacant: false,
                Tenant: {
                    ...body
                }
            }));
            SuccessPopup("Success! Occupant information updated succesfully!");
        } else {
            ErrorPopup();
        }
    }

    const deleteOccupantHandler = async () => {
        const response = await deleteOccupant(unitID, token);
        const { success } = response;

        if (success) {
            setUnit(prevValues => ({
                ...prevValues,
                Vacant: true,
                Tenant: null
            }));
            dispatch(propertiesDataActions.editUnitInProperty({
                UnitID: unitID,
                BuildingID: buildingID,
                Vacant: true,
                Tenant: null
            }));
            SuccessPopup("Success! Deleted occupant information.");
        } else {
            ErrorPopup();
        }

        return success;
    }

    const cancelEditHandler = () => {
        history.push(`/building-info?building-id=${buildingID}&active-tab=units`);
    }

    return (
        <>
            {isSaving && <LoaderWithBackdrop />}

            <Modal show={showOnSubmitImagesModal} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <UpdatingImagesInfo
                        onHide={onHideHandler}
                        isUploading={isUploadingImages}
                        imagesToBeUploaded={imagesToBeUploaded}
                        isUploadingImagesSuccess={isUploadingImagesSuccess}

                        isDeletingImages={isDeletingImages}
                        imagesToBeDeleted={imagesToBeDeleted}
                        isDeletingImagesSuccess={isDeletingImagesSuccess}
                    />
                </Modal.Body>
            </Modal>

            <Container fluid style={{maxWidth: 1200}} className="m-auto mb-5 p-0">

                <BackNav className="m-0 p-0 mb-3 px-2"
                        disabled={loading} 
                        currentPage="Edit Unit"
                        pages={[{
                            title: "properties",
                            goTo: "/properties"
                        },
                        {
                            title: "property units",
                            goTo: `/building-info?building-id=${buildingID}&active-tab=units`
                        } 
                        ]}
                />

                { loading && <AddNewUnitFormLoader editUnitLoader={true}/>}

                { !loading && (
                    <Row className="m-0 p-0 p-2 p-md-5 lightGray rounded overflow-hidden">
                        <Col className="m-0 p-0">
                            <Row className="m-0 p-0 justify-content-sm-between">
                                <Col className="m-0 p-0  order-1 " xs="12" md="auto">
                                    <h2 className="fw-bold m-0 p-0 mb-4">Edit Unit Information</h2>
                                </Col>

                                <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2 flex-grow" xs={12} md={6} lg={5}>

                                    <Row className="m-0 p-0 mb-3 mb-sm-0 justify-content-end">
                                        <Col className="m-0 p-0 pe-1" xs={6}>
                                            <ButtonWithLeftBorder 
                                                title='Back'
                                                additionalButtonClass='border-right-blue'
                                                titleMobile={'Back'}

                                                onClick={cancelEditHandler}
                                            />
                                        </Col>

                                        <Col className="m-0 p-0 ps-1" xs={6}>
                                            <ButtonWithLeftBorder 
                                                title='Delete Unit'
                                                titleMobile={'Delete'}

                                                onClick={onDeleteUnit}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <UpdateUnitBasic
                                sectionFocus={sectionFocus}
                                setSectionFocus={setSectionFocus}
                                unit={unit}
                                onSubmitBasics={onSubmitBasics}
                            />

                            <FeaturesInformation 
                                features={features}
                                amenities={amenities}
                                setAmenities={setAmenities}
                                accessibilities={accessibilities}
                                setAccessibilities={setAccessibilities}
                                unit={unit}
                                onSubmit={onSubmitFeatures}
                                sectionFocus={sectionFocus} 
                                setSectionFocus={setSectionFocus}
                            />

                            <Row className="mt-3">
                                <Col>
                                    <UploadImage
                                        onSubmitImages={onSubmitImages}
                                        sectionFocus={sectionFocus}
                                        setSectionFocus={setSectionFocus}
                                        isForm={true}
                                        downloadedPictures={downloadedImages}
                                        images={images}
                                        setImages={setImages}
                                        setImagesToBeDeleted={setImagesToDelete}

                                        onFavoriteClick={onFavoriteClickHandler}
                                    />
                                </Col>
                            </Row>
                    
                            <AdditionalInformation
                                onSubmitAdditional={onSubmitAdditional}
                                sectionFocus={sectionFocus}
                                setSectionFocus={setSectionFocus}
                                unit={unit}
                            />

                            <OccupantInformation
                                onDeleteOccupant={deleteOccupantHandler}
                                onEditOccupant={editOccupantHandler}
                                sectionFocus={sectionFocus}
                                setSectionFocus={setSectionFocus}
                                unit={unit}
                            />
                            
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    )
}