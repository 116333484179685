import React, {useState} from 'react'
import {
    Col,
    Row,
    Spinner
} from 'react-bootstrap';



export default function IframeSection({ iframeLink }) {
    const [loading, setLoading] = useState(true);

    const onLoadHandler = () => {
        setLoading(false);
    }

    return (
    <Row className="m-0 p-0">
        <Row className="m-0 mb-3 p-2 darkerGray rounded">
            <h5 className="m-0 p-0 text-uppercase text-center fw-bold">Complete an application</h5>
        </Row>

        <Row className="m-0 p-2">
            {   (loading || !iframeLink) && (
                    <Col className="m-0 p-0 d-flex justify-content-center align-content-center" style={{height: 600}}>
                        <Spinner animation="border" role="status" className=" align-self-center ">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                )
            }

            { iframeLink && (
                <iframe 
                    onLoad={onLoadHandler}
                    title="TenantTracks Form"
                    width="100%"
                    height="600"
                    src={iframeLink}>
                </iframe>
            )}
        </Row>
    </Row>
    )
}
