import React, {useState, useEffect} from 'react'
import {
    Modal, 
    Row,
    Col,
    Container
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import Backdrop from './Backdrop';
import ButtonPrimary from './Buttons/ButtonPrimary';


export default function TimeRangePicker({show, closeHandler, getTimeRange}) {
    const [timeRange, setTimeRange] = useState([{id: 'firstTimer', start: '', end: ''}]);
    const [error, setError] = useState(null);

    const addTimerHandler = () => {
        setTimeRange( prevValue => [...prevValue, {
            id: uuidv4(),
            start: '',
            end: ''
        }] );
    }

    const subTimerHandler = (id) => {
        if(timeRange.length === 1) return;

        setTimeRange( prevValue => prevValue.filter( (timer) => timer.id !== id));
    }

    const timeInputHandler = (e, id, type) => {
        const start = type === "start" ? e.target.value : '';
        const end = type === "end" ? e.target.value : '';

        setTimeRange( prevValue => prevValue.map( time => {
            if(id === time.id){
                return {
                    ...time,
                    [start ? 'start' : 'end']: start || end
                }
            }
            return time;
        }))
    }

    const acceptBtnHandler = () => {
        const checkIfSomeEmpty = timeRange.some( timer => timer.start === '' || timer.end === '');

        if (checkIfSomeEmpty) {
            setError("Select time range.");
            return;
        };
        getTimeRange(timeRange);
        closeHandler();
    }

    useEffect(() => {
        const checkIfSomeEmpty = timeRange.some( timer => timer.start === '' || timer.end === '');
        if (!checkIfSomeEmpty) {
            setError(null);
        }

    }, [timeRange]);

    return (
        <>
            <Backdrop 
                show={show} 
                close={closeHandler}
            />

            <Modal show={show} 
                backdrop={false}
                onHide={closeHandler}
                aria-labelledby="contained-modal-title-vcenter" 
                centered >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-6">Choose a time for your meeting</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Container className="m-0 p-0">
                        {
                            timeRange.map( (timer) => (
                            <Row key={timer.id} className="m-0 p-0">
                            <Col className="m-0 p-0" xs={8} md={10}>
                                <Container className="m-0 p-0">
                                    <Row className="m-0 p-0 row-cols-1 row-cols-md-2 fs-6">
                                        <Col className="m-0 mb-1 p-2 d-flex flex-column">
                                            <label htmlFor="timeRangeStart"><p className="m-0 p-0 fs-6">From:</p></label>
                                            <input className="rounded border-1 p-1" onChange={(e) => timeInputHandler(e, timer.id, 'start')} value={timeRange.find((tmr)=>tmr.id===timer.id).start} type="time" id="timeRangeStart" name="timeRangeStart" required/>
                                        </Col>

                                        <Col className="m-0 mb-1 p-2 d-flex flex-column">
                                            <label htmlFor="timeRangeEnd"><p className="m-0 p-0 fs-6">To:</p></label>
                                            <input className="rounded border-1 p-1" min={timeRange.find((tmr)=>tmr.id===timer.id).start} onChange={(e) => timeInputHandler(e, timer.id, 'end')} value={timeRange.find((tmr)=>tmr.id===timer.id).end} type="time" id="timeRangeEnd" name="timeRangeEnd" required/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>

                            {   timer.id !== "firstTimer" &&
                                <Col className="m-0 p-0 d-flex align-items-end">
                                    <Container className="m-0 p-0">
                                        <Row className="m-0 p-0 justify-content-end align-items-center">
                                            <Col className="m-0 p-0 py-2 mb-1" xs={'auto'}>
                                                <ButtonPrimary
                                                    onClick={() => subTimerHandler(timer.id)}
                                                    additionalClassName='bg-danger border-danger'
                                                >
                                                    <h6 className="m-0 p-0"><i className="bi bi-x-lg"></i></h6>
                                                </ButtonPrimary>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            }
                            <hr className="mb-2 p-0"/>
                        </Row>
                            ))
                        }


                        <Row className="m-0 p-0 d-flex justify-content-end">
                            <Col className="m-0 p-0">
                                <h6 className="m-0 p-0 text-danger">{error}</h6>
                            </Col>
                            <Col className="m-0 p-0" xs={'auto'}>
                                <ButtonPrimary onClick={addTimerHandler}>
                                    <h6 className="m-0 p-0"><i className="bi bi-plus-lg"></i></h6>
                                </ButtonPrimary>
                            </Col>
                        </Row>


                        <Row className="m-0 p-0 d-flex justify-content-center">
                            <Col className="m-0 p-2" xs={12} md={6}>
                               

                                <ButtonPrimary 
                                    title="OK"
                                    onClick={acceptBtnHandler} 
                                
                                />


                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
            </Modal>
        </>
    )
}
