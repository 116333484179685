import { getRequest, postPutRequest } from './http-common';
import { 
    NOTIFICATION_ID,
    START_DATE,END_DATE,
    NOTIFICATION_TYPE_ID,
    SEND_NOW,
    HOUR,
    DAYS_OF_THE_WEEK,
    DAY,
    GROUPS,
    GROUP_IDS,
    CLIENT_IDS,
    MESSAGE_SPEC,
    MESSAGE_TYPE,
    MESSAGE,
    TITLE,
    IMAGE_BLOB,
    FREQUENCY_PLAN,
    FREQUENCY,
    DESCRIPTION,
    NAME
} from '../constants/notification';

const getNotificationsSpecs = async () => {
    const URL = `command/notification/specs`;

    return await getRequest(URL);
}

const getNotifications = async (token) => {
    const URL = `command/notification`;

    return await getRequest(URL, token);
}

const addNotification = async (data, token) => {
    const URL = `command/notification`;
    const postBody = {
        [NAME]: null,
        [DESCRIPTION]: null,
        [START_DATE]: null,
        [END_DATE]: null,
        [NOTIFICATION_TYPE_ID]: null,
        [SEND_NOW]: null,
        [FREQUENCY_PLAN]: {
            [FREQUENCY]: null,
            [HOUR]: null,
            [DAYS_OF_THE_WEEK]: null
        },
        [GROUPS]: {
            [GROUP_IDS]: [],
            [CLIENT_IDS]: []
        },
        [MESSAGE_SPEC]: {
            [MESSAGE_TYPE]: null,
            [MESSAGE]: null,
            [TITLE]: null
        },
        [IMAGE_BLOB]: null
    }


    return await postPutRequest(URL, data, token, 'post');
}

export { 
    getNotificationsSpecs,
    getNotifications,
    addNotification
};
