import React from 'react';
import { useDrag } from 'react-dnd';
import { Row, Col } from 'react-bootstrap';

import CategoryItem from './CategoryItem';
import Bar from '../Shared/CaseManager/Bar';
import GroupRow from './GroupRow';

import { CLIENT } from '../Clients/AddClientModal';

export default function Group({
    onDelete,
    onEdit,
    clients,
    clientIds,
    ...props}) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'Group',
        item: { id: props.id, name: props.name },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }));

    const getClientData = (id, clients) => {
        if(!id && !clients) return '-';


        // console.log('[Group] id: ', id);
        // console.log('[Group] clients: ', clients);

        return clients.find( client => client[CLIENT.CLIENT_ID] === id );
    }

    return (
        <>
            <div className="p-0" ref={drag}>
                <CategoryItem {...props}
                    showGripIcon={true}
                    style={{transform: isDragging ? "scale(0.96)" : "scale(1)"}}

                    deleteBtnHandler={(e) => onDelete(e, props.id)}
                    editBtnHandler={(e) => onEdit(e, props.id)}

                    // className={`${ isDragging ? "border border-secondary border-3 rounded" : ""}`}
                >
                    { clientIds && clientIds.length !== 0 && (
                        <Bar>
                            <Row className="d-flex justify-content-around fw-bold">
                                <Col className="d-flex flex-column">
                                    <p className="m-0"><i className="bi bi-heart-fill me-3"></i>Client</p>
                                </Col>
                                <Col className="d-flex flex-column">
                                    <p className="m-0"><i className="bi bi-info-circle-fill me-3"></i>Details</p>
                                </Col>
                                <Col className="d-flex flex-column">
                                    <p className="m-0"><i className="bi bi-tools me-3"></i>Action</p>
                                </Col>
                            </Row>
                        </Bar>
                    )}

                    { clientIds && clientIds.map( (clientId, index, arr) => (
                        <GroupRow key={clientId}
                            isLast={arr.length-1 === index}
                            clientData={getClientData(clientId, clients)}
                        />
                    )) }

                    { clientIds && clientIds.length === 0 && (
                        <Row>
                            <Col className="text-center">
                                There are no clients added to this group
                            </Col>
                        </Row>
                    )}

                </CategoryItem>
            </div>
        </>
    );
  }
