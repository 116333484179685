import React from 'react';
import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

export default function UnitInfoLoader() {
    return (
        <Col className="m-0 p-2 border-start shadow-sm" xs={12} md={8} style={{ background: '#f3f3f3' }}>

            <Row className="m-0 p-0 px-1">
                <h4 className="p-0 m-0 mb-2 fw-bolder darkerGray skeleton rounded" style={{ width: 250 }}>&nbsp;</h4>
            </Row>
            
            <Container fluid className="p-1 m-0 montserrat">
                {/* Image loader */}
                <Row style={{ minHeight: 200 }} className="p-0 py-1 m-0">
                    <Col xs={12} md={6} className="m-0 p-0 darkerGray skeleton rounded" style={{ height: 200 }} ></Col>
                </Row>

                <Row className="p-0 m-0 mt-2">
                    <Container fluid className="p-0 m-0">
                        <Row className="p-0 m-0">
                            <Col className="p-0 m-0" xs={12}>
                                <Row className="p-0 m-0 mb-1">
                                    <h6 className="m-0 p-0 fw-bold darkerGray skeleton rounded" style={{width: 200}}>&nbsp;</h6>
                                </Row>
                            </Col>
                            <Col className="p-0 m-0" xs={12} md={6}>
                                <Row className="p-0 m-0 mb-1">
                                    <h6 className="m-0 mb-2 p-0 fw-bold darkerGray skeleton rounded"  style={{width: 175}}>&nbsp;</h6>
                                </Row>
                                <Row className="p-0 m-0 mb-3 mb-md-0">
                                    <Col className="p-0 m-0">
                                        <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 150}}>&nbsp;</h6>
                                        <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 175}}>&nbsp;</h6>
                                    </Col>
                                    <Col className="p-0 m-0">
                                        <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 150}}>&nbsp;</h6>
                                        <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 125}}>&nbsp;</h6>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* Features loader */}
                        <Row className="m-0 p-0 mt-2">
                            <Col className="m-0 p-0 darkerGray skeleton rounded" style={{height: 175}}></Col>
                        </Row>

                        {/*Payment details loader*/}
                        <Row className="m-0 p-0 d-flex flex-column flex-md-row">
                            <Col className="m-0 mt-2 p-0">
                                    <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 150}}>&nbsp;</h6>
                                    <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 100}}>&nbsp;</h6>
                            </Col>
                            <Col className="m-0 mt-2 p-0">
                                <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 150}}>&nbsp;</h6>
                                <h6 className="m-0 p-0 mb-1 fw-bold darkerGray skeleton rounded" style={{width: 100}}>&nbsp;</h6>
                            </Col>
                        </Row>
                    </Container>
                </Row>

            </Container>
        
           
        </Col>
    )
}
