import React, { useRef, useState, useEffect, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import Marker from './Marker';

import BuildingClusterModalPicker from "./BuildingClusterModalPicker";

const SHOW_COMPONENT = {
  BOTH: "both",
  MAP: "map",
  LIST: "list"
}

const defaultProps = {
  center: {
    lat: 38.897957,
    lng: -77.03656,
  },
};

export function MarketplaceMap({
  loading,
  focusOnUnits,
  setHighlightBuildingWithID,
  setVisibleBuildingsId,
  highlightBuildingWithID,
  alreadyRequestesUnitIDs,
  showComponents,
  favorites,
  buildingsPins,
  selectBuildingHandler,

  zoomIntoBuildingWithID
}) {
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(12);
  const [bounds, setBounds] = useState(null);

  const [clickedBuildingID, setClickedBuildingID] = useState(null);
  const [showClusterBuildingPicker, setShowClusterBuildingPicker] = useState(false);

  const points = buildingsPins.map((building) => ({
    type: "Feature",
    properties: {
      cluster: false,
      pointId: building.BuildingID,
      MaxRentPrice: building.MaxRentPrice || 0,
      MinRentPrice: building.MinRentPrice || 0,
      BuildingID: building.BuildingID,
      UnitIDs: building.UnitIDs
    },
    geometry: {
      type: "Point",
      coordinates: [building.Longitude, building.Latitude],
    },
  }));

  const [isZoomedIntoBuilding, setIsZoomedIntoBuilding] = useState(false);
  const [mapPositionBeforeZoom, setMapPositionBeforeZoom] = useState({
    lat: null,
    lng: null,
    zoom: null
  })

  // this useEffect is responsible for zooming behaviour triggered when user clicks on a building in the sideBox
  useEffect(() => {
    if (points) {

      const buildingInfo = points.find(({ properties }) => String(properties.BuildingID) === String(zoomIntoBuildingWithID));

      if (buildingInfo && buildingInfo.geometry && !isZoomedIntoBuilding) {

        setMapPositionBeforeZoom({
          zoom: mapRef.current.zoom,
          lat: mapRef.current.getCenter().lat(),
          lng: mapRef.current.getCenter().lng()
        });

        mapRef.current.panTo({ lng: buildingInfo.geometry.coordinates[0], lat: buildingInfo.geometry.coordinates[1] });
        mapRef.current.setZoom(mapRef.current.zoom + 2 > 20 ? 20 : mapRef.current.zoom + 2);


        setIsZoomedIntoBuilding(true);
      }

      if (!zoomIntoBuildingWithID) {

        if (mapPositionBeforeZoom.zoom && mapPositionBeforeZoom.lat && mapPositionBeforeZoom.lng) {
          setIsZoomedIntoBuilding(false);
          mapRef.current.panTo({ lng: mapPositionBeforeZoom.lng, lat: mapPositionBeforeZoom.lat });
          mapRef.current.setZoom(mapPositionBeforeZoom.zoom);

          setMapPositionBeforeZoom({
            zoom: null,
            lat: null,
            lng: null
          });
        }

      }
    }
  }, [zoomIntoBuildingWithID, points]);


  function getBoundaryPoints(geometries) {
    if (!geometries || !geometries.length) return;
    let mostTop, mostRight, mostBottom, mostLeft = null;
    const initialValue = geometries[0];
    mostTop = initialValue[0];
    mostBottom = initialValue[0];
    mostLeft = initialValue[1];
    mostRight = initialValue[1];

    geometries.forEach(geometry => {
      const lat = geometry[0];
      const lng = geometry[1];

      if (lat > mostTop) mostTop = lat;
      if (lat < mostBottom) mostBottom = lat;
      if (lng < mostLeft) mostLeft = lng;
      if (lng > mostRight) mostRight = lng;
    });

    return [[mostLeft, mostTop], [mostRight, mostBottom]]
  }

  const getDefaultBoundaries = useCallback(() => {
    if (!buildingsPins) return null;
    const geometries = buildingsPins.filter(unit => unit.Latitude && unit.Longitude).map(unit => ([unit.Longitude, unit.Latitude]))
    const boundaryPoints = getBoundaryPoints(geometries);
    return boundaryPoints;
  }, [buildingsPins]);

  const fitBoundsHandler = (boundariesMarkers) => {
    if (!boundariesMarkers) return;

    const bounds = new window.google.maps.LatLngBounds();
    boundariesMarkers.forEach(marker => {
      bounds.extend({
        lat: marker[0],
        lng: marker[1],
      });
    });
    mapRef.current.fitBounds(bounds, 20);
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20, minPoints: 2, generateId: true },
  });

  useEffect(() => {
    if (showComponents !== SHOW_COMPONENT.LIST && clusters && clusters.length) {
      const _visibleBuildingIds = [];

      clusters.forEach(cluster => {
        const { cluster: isCluster } = cluster.properties;

        if (isCluster) {
          const { id } = cluster;
          const leafs = supercluster.getLeaves(id, Infinity);
          const getBuildingsID = leafs.reduce((accumulator, current) => {
            const BuildingID = current.properties.BuildingID;
            if (!accumulator.some(x => x === BuildingID)) {
              accumulator.push(BuildingID)
            }
            return accumulator;
          }, []);
          _visibleBuildingIds.push(...getBuildingsID);
        } else {
          const BuildingID = cluster.properties.BuildingID;
          _visibleBuildingIds.push(BuildingID);
        }
      });

      setVisibleBuildingsId(_visibleBuildingIds);
    }
  }, [clusters, supercluster, setVisibleBuildingsId, showComponents]);

  useEffect(() => {
    focusOnUnits(clusters);
  }, [clusters, focusOnUnits]);

  function createMapOptions(maps) {
    return {
      streetViewControl: true,
      streetViewControlOptions: {
        position: maps.ControlPosition.RIGHT_TOP
      },
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_TOP,
        style: maps.ZoomControlStyle.DEFAULT
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_LEFT
      },
      mapTypeControl: true,
      maxZoom: 20,
    };
  }

  return (
    <>
      <BuildingClusterModalPicker
        BuildingID={clickedBuildingID}
        show={showClusterBuildingPicker}
        onHide={() => setShowClusterBuildingPicker(false)}
        buildingsPins={buildingsPins}
        selectBuildingHandler={selectBuildingHandler}
      />

      <div style={{ height: "100%", width: "100%" }}>
        {loading ? (
          <div className="darkerGray skeleton" style={{ height: "100%", width: "100%" }}></div>
        ) : (
          <GoogleMapReact
            options={createMapOptions}
            bootstrapURLKeys={{
              // id: 'googleMapsAPI',
              key: process.env.REACT_APP_GOOGLE_API_KEY
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={defaultProps.center}
            defaultZoom={10}
            // center={LLValue ? LLValue.center : defaultProps.center}
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;

              const boundariesMarkers = getDefaultBoundaries();
              fitBoundsHandler(boundariesMarkers);
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ]);
            }}
          >
            {clusters.map((cluster) => {


              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } = cluster.properties;

              if (isCluster) {
                const { id } = cluster;
                const leafs = supercluster.getLeaves(id, Infinity);

                const leafsPrices = leafs.reduce((acc, leaf) => {
                  const { MinRentPrice, MaxRentPrice } = leaf.properties;
                  if(!MinRentPrice || !MaxRentPrice){
                    // console.log(leaf.properties)
                    // const allRents = leaf.properties.map(property=>property.Units).map(unit=>unit.Rent)
                    // console.log(allRents)
                  }
                  if (MinRentPrice < acc.MinPrice) acc.MinPrice = MinRentPrice;
                  if (MaxRentPrice > acc.MaxPrice) acc.MaxPrice = MaxRentPrice;
                  return acc;
                }, { MinPrice: Number.MAX_VALUE, MaxPrice: 0 })
                const getPriceRange = [leafsPrices.MinPrice, leafsPrices.MaxPrice];

                const hasClusterRequestedUnit = alreadyRequestesUnitIDs && leafs.some(leaf => leaf.properties.UnitIDs.some(UnitID => alreadyRequestesUnitIDs.includes(UnitID)));

                const getBuildingsID = leafs.reduce((accumulator, current) => {
                  const BuildingID = current.properties.BuildingID;
                  if (!accumulator.some(x => x === BuildingID)) {
                    accumulator.push(BuildingID)
                  }
                  return accumulator;
                }, []);

                const clickHandler = (BuildingsID) => {

                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );

                  mapRef.current.setZoom(expansionZoom);

                  // const getGeometries = leafs.map( leaf => leaf.geometry.coordinates );
                  // const boundaryMarkers = getBoundaryPoints(getGeometries);
                  // fitBoundsHandler(boundaryMarkers);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });

                  if (mapRef.current.zoom === 20) {
                    setClickedBuildingID(BuildingsID);

                    if (BuildingsID.length > 1) {
                      setShowClusterBuildingPicker(true);
                    } else {
                      selectBuildingHandler(BuildingsID);
                    }

                  }
                }

                function onHighlightHandler(buildingsID) {
                  setHighlightBuildingWithID(buildingsID)
                };

                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}

                    BuildingsID={getBuildingsID}
                    highlightBuildingWithID={highlightBuildingWithID}
                    onHighlightHandler={onHighlightHandler}
                    favorites={favorites}

                    pointCount={pointCount}
                    points={points}
                    onClick={clickHandler}
                    getPriceRange={getPriceRange}
                    isCluster={true}
                    alreadyRequested={hasClusterRequestedUnit}
                  />
                );
              }

              function onHighlightHandler(buildingsID) {
                setHighlightBuildingWithID(buildingsID)
              };

              const { BuildingID, UnitIDs, MaxRentPrice, MinRentPrice } = cluster.properties;
              const alreadyRequested = alreadyRequestesUnitIDs && UnitIDs.some(UnitID => alreadyRequestesUnitIDs.includes(UnitID));

              const nonClusterOnClickHandler = (BuildingID) => {
                selectBuildingHandler(BuildingID);
                mapRef.current.panTo({ lat: latitude, lng: longitude });
              }

              return (
                <Marker
                  key={`point-${cluster.properties.pointId}`}
                  lat={latitude}
                  lng={longitude}

                  BuildingsID={[BuildingID]}
                  onHighlightHandler={onHighlightHandler}
                  highlightBuildingWithID={highlightBuildingWithID}

                  pointCount={pointCount}
                  points={points}
                  onClick={nonClusterOnClickHandler}
                  getPriceRange={[MinRentPrice || MaxRentPrice]}
                  isCluster={false}
                  alreadyRequested={alreadyRequested}
                  favorites={favorites}
                />
              );
            })}
          </GoogleMapReact>
        )
        }
      </div>
    </>
  );
}
