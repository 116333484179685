import React, { createContext, useState } from "react";

import { getBuilding } from '../services/building';

export const BuildingsContext = createContext();

const BuildingsContextProvider = (props) => {
  const [buildingsData, setBuildingsData] = useState({});
  const [isSuccess, setIsSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getBuildingByID = async (BuildingID) => {
    if(isBuildingInData(BuildingID)) {
      setIsLoading(false);
      return {success: true, data: buildingsData[BuildingID]};
    }

    setIsLoading(true);
    const response = await getBuilding(BuildingID);
    const { success, data } = response;
  
    setIsLoading(false);

    if(!success) {
      return {success: false, data: data};
    }

    setBuildingsData( prevBuildings => ({
      ...prevBuildings,
      [BuildingID] : data
    }))

    return {success: true, data: data};
  }

  const isBuildingInData = (BuildingID) => {
    return buildingsData.hasOwnProperty(BuildingID);
  }

  return (
    <BuildingsContext.Provider
      value={{
        buildingsData,
        setBuildingsData,
        isSuccess,
        isLoading,
        error,
        getBuildingByID
      }}
    >
      {props.children}
    </BuildingsContext.Provider>
  );
};

export default BuildingsContextProvider;