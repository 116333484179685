import React from 'react';
import { Col } from 'react-bootstrap';

import { FilterRow } from '../index';


export default function ByValue({
    clearFilterHandler,
    activeFilters,
    changeHandler,
    title,
    firstBtnTitle="Low to High",
    secondBtnTitle="High to Low"
}) {
    return (
        <FilterRow
            clearFilter={() => clearFilterHandler(title)}
            title={title}
            value={activeFilters[title]}
        >
                <Col xs={6} className="m-0 p-0">
                    <div onClick={() => changeHandler('fromLowest', title)} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters[title] === "fromLowest" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        {/* <i className="bi bi-sort-alpha-down fs-2"></i> */}
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters[title] === "fromLowest" ? 'text-white' : 'text-dark' }`}>{firstBtnTitle}</p>
                    </div>
                </Col>

                <Col xs={6} className="m-0 p-0">
                    <div onClick={() => changeHandler('fromHighest', title)} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters[title] === "fromHighest" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        {/* <i className="bi bi-sort-alpha-up fs-2"></i> */}
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters[title] === "fromHighest" ? 'text-white' : 'text-dark' }`}>{secondBtnTitle}</p>
                    </div>
                </Col>
        </FilterRow>
    )
}
