import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";

import { managerDataActions } from "../store/ManagerDataSlice";
import { propertiesDataActions } from "../store/PropertiesSlice";
import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { getAllBuildings } from '../services/building';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import BackNav from '../components/Shared/BackNav';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';

export default function AddNewUnit() {
    const { token } = useContext(AuthContext);
    const cancelToken = useMemo(() => axios.CancelToken.source(), []);

    const dispatch = useDispatch();

    const wereAllPropertiesFetched = useSelector(store => store.propertiesStore.wereAllPropertiesFetched);

    const {userType} = useContext(UserTypeContext);


    const getAllBuildingsHandler = useCallback(async () => {
      if ( wereAllPropertiesFetched ) return;
      const settings = {
        cancelToken: cancelToken.token,
      }
  
      const response = await getAllBuildings(token, settings);
      const { success, data } = response;
  
      if(success) {
        const prepareProperties = extrudeProperties(data);
  
        if(userType === "Landlord") {
          const extrudedManagerData = extrudeManagersData(prepareProperties);
  
          const prepareManagerData = Object.entries(extrudedManagerData).map(([_, managerData]) => managerData);
          dispatch(propertiesDataActions.setProperties(prepareProperties));
          dispatch(managerDataActions.setLandlordsInformations(prepareManagerData));
          dispatch(propertiesDataActions.setFetchedAllProperties(true));
        }
      } else {
        ErrorPopup("Error! Could not get building data. Try again later.");
      }
    }, [token, cancelToken, dispatch, userType, wereAllPropertiesFetched]);

    const extrudeProperties = (properties) => {
      if (!properties) return null;
      if (Array.isArray(properties)) return properties;
  
      const extrudedProperties = Object.entries(properties).map( ([key, value]) => {
        if(!value) return null;
        return value.map( property => ({
        ...property,
        LandlordEmail: key 
      }))} ).flat();
      if(!extrudedProperties) return [];
      if(extrudedProperties[0] === null) return [];
  
      return extrudedProperties;
    }

    const extrudeManagersData = (properties) => {
      if(!properties || !Array.isArray(properties)) return {};
  
      return properties.reduce((acc, property) => {
        if(property.Managers) {
          const managersObject = property.Managers.reduce((acc, manager) => {
            return {
              ...acc,
              [manager.Email]: {
                Email: manager.Email,
                FullName: manager.FullName
              }
            }
          }, {});
          return {
            ...acc,
            ...managersObject
          }
        }
        return acc;
      }, {});
    }

    useEffect(() => {
        getAllBuildingsHandler();
    }, [getAllBuildingsHandler]);

    return (
      <Container fluid style={{maxWidth: 1200}} className="m-auto mb-5 p-0">

          <BackNav className="m-0 p-0 mb-3 px-2"
                  currentPage="add"
                  pages={[{
                      title: "properties",
                      goTo: "/properties"
                  }]}
            />

          <Row className="m-0 p-0 w-100">
              <Col className="m-0 p-2 p-lg-5 rounded lightGray d-flex flex-column justify-content-center w-100">
                      <Row className="m-0 p-0 mt-3 mt-lg-0">
                        <Col className="m-0 p-0 mb-4">
                          <h3 className="fw-bold text-center">
                              Are you adding a new property or adding a unit to an existing property?
                          </h3>
                        </Col>
                      </Row>
                      
                      <Row className="m-0 p-0 mb-3 justify-content-center">
                          <Col className="m-0 p-0 mb-3 mb-sm-0 pe-sm-1" xs={12} sm={6}>
                              <Link to="/add-new-building">
                                  <ButtonPrimary
                                    title="Add New Property"
                                    type="button"
                                  />
                              </Link>
                          </Col>

                          <Col className="m-0 p-0 mb-3 mb-sm-0 ps-sm-1" xs={12} sm={6}>
                              <Link to="/add-new-unit-to-existing-property">
                                  <ButtonPrimary
                                    title="Add Unit(s) to Existing Property"
                                    type="button"
                                  />
                              </Link>
                          </Col>
                      </Row>
              </Col>
          </Row>
      </Container>
    )
}
