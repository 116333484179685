import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    properties: [],
    propertyFeatures: null,
    unitFeatures: null,
    wereAllPropertiesFetched: false
}

export const propertiesDataSlice = createSlice({
    name: 'propertiesDataSlice',
    initialState,
    reducers: {
        setPropertyFavoritePhotos(state, action) {
            const { buildingID, photoID } = action.payload;

            state.properties = state.properties.map( property => {
                if( property.BuildingID !== buildingID) return property;
                
                const newPhotos = property.Photos.map( photo => ({
                    ...photo,
                    IsFavorite: photo.PhotoID === photoID
                }))

                return {
                    ...property,
                    Photos: newPhotos
                }
            })
        },
        setUnitFavoritePhotos(state, action) {
            const { buildingID, unitID, photoID } = action.payload;

            state.properties = state.properties.map( property => {
                if( property.BuildingID !== buildingID) return property;

                const temp_units = property.Units.map( unit => {
                    if( unit.UnitID !== unitID ) return unit;

                    const newPhotos = unit.Photos.map( photo => ({
                        ...photo,
                        IsFavorite: photo.PhotoID === photoID
                    }))

                    return {
                        ...unit,
                        Photos: newPhotos
                    }
                })
                return {
                    ...property,
                    Units: temp_units
                };
            })
        },
        setPropertyFeatures(state, action) {
            state.propertyFeatures = action.payload;
        },
        setUnitFeatures(state, action) {
            state.unitFeatures = action.payload;
        },
        setFetchedAllProperties(state, action) {
            state.wereAllPropertiesFetched = action.payload;
        },
        setProperties(state, action) {
            const properties = action.payload;
            state.properties = properties;
        },
        addProperty(state, action) {
            const newProperty = action.payload;
            state.properties = [
                ...state.properties,
                newProperty
            ]
        },
        deleteProperty(state, action) {
            const BuildingID = action.payload;
            const newProperties = state.properties.filter( property => property.BuildingID !== BuildingID);
            state.properties = newProperties;
        },
        editProperty(state, action) {
            const editedProperty = action.payload;
            const { BuildingID, ...dataToUpdate } = editedProperty;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;
                return {
                    ...property,
                    ...dataToUpdate
                }
            })
            state.properties = newProperties;
        },
        removeManagerFromProperty(state, action) {
            const editedProperty = action.payload;
            const { BuildingID, BuildingManagerID } = editedProperty;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;
                const updatedManagers = property.Managers.filter( manager => manager.BuildingManagerID !== BuildingManagerID );

                return {
                    ...property,
                    Managers: updatedManagers
                }
            })
            state.properties = newProperties;
        },
        editDocument(state, action) {
            const editedProperty = action.payload;
            const { BuildingID, BuildingDocumentID, Description } = editedProperty;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;

                const updatedDocuments = property.Documents.map( document => {
                    if( document.BuildingDocumentID !== BuildingDocumentID ) return document;

                    return {
                        ...document,
                        Description
                    }
                })

                return {
                    ...property,
                    Documents: updatedDocuments
                }
            })
            state.properties = newProperties;
        },        
        addDocument(state, action) {
            const editedProperty = action.payload;
            const { BuildingID, NewDocuments } = editedProperty;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;
                return {
                    ...property,
                    Documents: [
                        ...property.Documents,
                        ...NewDocuments
                    ]
                }
            })
            state.properties = newProperties;
        },
        deleteDocuments(state, action) {
            const editedProperty = action.payload;
            const { BuildingID, DocumentsIDs } = editedProperty;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;

                const newDocuments = property.Documents.filter( document => (
                    !DocumentsIDs.includes(document.BuildingDocumentID))
                )

                return {
                    ...property,
                    Documents: newDocuments
                }
            })
            state.properties = newProperties;
        },
        deletePropertyPhotos(state, action) {
            const data = action.payload;
            const { BuildingID, PhotoIDs } = data;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;

                const newDocuments = property.Photos.filter( photo => (
                    !PhotoIDs.includes(photo.PhotoID))
                )

                return {
                    ...property,
                    Photos: newDocuments
                }
            })
            state.properties = newProperties;
        },
        deleteUnitPhotos(state, action) {
            const data = action.payload;
            const { BuildingID, UnitID, PhotoIDs } = data;

            const newProperties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID ) return property;

                const editedUnits = property.Units.map( unit => {
                    if(unit.UnitID !== UnitID) return unit;

                    const newPhotos = unit.Photos.filter( photo => (
                        !PhotoIDs.includes(photo.PhotoID)
                    ))
                    return {
                        ...unit,
                        Photos: newPhotos
                    }
                })

                return {
                    ...property,
                    Units: editedUnits
                }
            })
            state.properties = newProperties;
        },
        addUnitToProperty(state, action) {
            const unit = action.payload;
            const { BuildingID } = unit;

            const properties = state.properties.map( property => {
                if(property.BuildingID !== BuildingID) return property;

                const units = property.Units || []; 

                return {
                    ...property,
                    Units: [
                        ...units,
                        action.payload
                    ]
                }
            })
            state.properties = properties;
        },
        addUnitsToProperty(state, action) {
            const newUnit = action.payload.Units;
            const BuildingID = action.payload.BuildingID;

            const properties = state.properties.map( property => {
                if(property.BuildingID !== BuildingID) return property;

                const units = property.Units || []; 

                return {
                    ...property,
                    Units: [
                        ...units,
                        ...newUnit
                    ]
                }
            })
            state.properties = properties;
        },
        editUnitInProperty(state, action) {
            const editedUnit = action.payload;
            const { BuildingID, UnitID, ...dataToUpdate } = editedUnit;

            const properties = state.properties.map( property => {
                if( property.BuildingID !== Number(BuildingID)) return property;

                const Units = property.Units.map( unit => {
                    if( unit.UnitID !== Number(UnitID) ) return unit;
                    return {
                        ...unit,
                        ...dataToUpdate
                    }
                });

                return {
                    ...property,
                    Units
                }
            })
            state.properties = properties;
        },
        deleteUnitFromProperty(state, action) {
            const unit = action.payload;
            const { BuildingID, UnitID } = unit;

            const properties = state.properties.map( property => {
                if( property.BuildingID !== BuildingID) return property;

                const Units = property.Units.filter( unit => unit.UnitID !== UnitID);

                return {
                    ...property,
                    Units
                }
            })
            state.properties = properties;
        },
        clearPropertiesStore(state) {
            state.properties = [];
            state.wereAllPropertiesFetched = false;
        }
    }
});

export const propertiesDataActions = propertiesDataSlice.actions;
export default propertiesDataSlice.reducer;
