import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Container,
    Card,
    Row,
    Col,
    Form,
    Accordion
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';

import { CreatePortfolioContext } from '../../contexts/CreatePortfolioContext';
import ImageSquare from '../Shared/ImageSquare';
import BasicInfoForm from './BasicInfoForm';
import Employment from './Employment';
import ResidentialHistoryForm from './ResidentialHistoryForm';
import DocumentForm from './DocumentForm';
import Screening from './Screening';
import BluredSection from './../Shared/BluredSection';
import FloatingSubmitBtn from './FloatingSubmitBtn';
import { fileToDataUri } from '../../utils/fileToDataUri';
import schema from '../../schemas/tenantPortfolioSchema';
import { UserTypeContext } from '../../contexts/UserTypeContext';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import SectionAccordion from "../Shared/SectionAccordion";
import ErrorPopup from '../Shared/InfoPopups/ErrorPopup';
import TargetUnit from './TargetUnit';

export default function Portfolio({
        forceEditMode,
        canEdit,
        editBasics,
        editEmployment,
        deleteEmployment,
        createPortfolio,
        uploadPicture,
        deletePicture,
        editResidentialHistory,
        doesProfileExists,
        onlyView=false,
        setIsLoading,
        saveDocumentsHandler,
        files,
        setFiles,
        setFilesToDelete,
        onlyNewFiles,
        changeFileDescriptionHandler,
        deleteFileHandler,
        cancelAddDocumentHandler,
        isLoading=false
    }) {
    const { 
        tenantData, 
        setTenantData,
        readyToSend, 
        setImageHandler,
        image,
    } = useContext(CreatePortfolioContext);

    const { userType } = useContext(UserTypeContext);

    const [editedSection, setEditedSection] = useState(null);

    const currentlyEditedSectionHandler = (sectionName) => {
        if( forceEditMode ) {
            return;
        }

        setEditedSection(sectionName);
    }

    const BioLength = useRef(500);
    
    const formOptions = { resolver: yupResolver(schema({
        BioLength: BioLength.current
    })), mode: "onChange" };

    const { register, control, handleSubmit, reset, formState, watch, setValue } = useForm(formOptions);
    const { errors, isDirty } = formState;
    const { fields: employmentFields, 
            append: employmentAppend,
            remove: employmentRemove } = useFieldArray({ name: 'Employments', control });
    const { fields: formerLandlordsFields, 
            append: formerLandlordsAppend,
            remove: formerLandlordsRemove } = useFieldArray({ name: 'FormerLandlords', control });

    const { fields: previousEvictionsFields, 
            append: previousEvictionsAppend,
            remove: previousEvictionsRemove } = useFieldArray({ name: 'PreviousEvictions', control });

    const resetForm = () => {
        const defaultValues = {
            ...tenantData
        };

        Object.entries(defaultValues).forEach(([key, value]) => {
            if ( value === null || value === undefined  ) {
                if(key === 'Employments' || key === 'FormerLandlords' || key === "PreviousEvictions") {
                    defaultValues[key] = [];
                } else {
                    defaultValues[key] = '';
                }
            }
        })

        const { Employments } = tenantData;

        if (Employments.length) {
            
            const preparedEmployments = Employments && Employments.map( (employment) => {
                const { Description, Duration, Employer, IsCurrentPoistion, Location, Position } = employment;
                return { 
                    Description, 
                    Duration, 
                    Employer, 
                    IsCurrentPoistion, 
                    Location, 
                    Position, 
                    JobStart: splitJobDuration(Duration).JobStart,
                    JobEnd: splitJobDuration(Duration).JobEnd
                }
            })

            defaultValues.Employments = preparedEmployments;
        }
        reset({...defaultValues});

        function splitJobDuration (duration) {
            if(!duration || typeof duration !== 'string') return {JobStart: '', JobEnd: ''};
            const splittedTime = duration.split('-');
            if(splittedTime.length <= 1) return {JobStart: '', JobEnd: ''};
            let jobStart = splittedTime[0].trim();
            let jobEnd = splittedTime[1].trim();

            return {JobStart: jobStart.trim(), JobEnd: jobEnd.trim()};
        }
    }

    // Initialize form with values loaded from server
    useEffect(() => {
        if(doesProfileExists) {
            resetForm();
        }
    }, [doesProfileExists, tenantData, reset]);

    const onSubmitBasic = async () => {
        let result = true;

        await handleSubmit( (d) => {

            result =  false;
            if(isDirty) editBasics(d);
            reset({}, {keepValues: true});
        })();

        return result;
    }

    const onSubmitEmployment = async (index) => {
        let result = true;

        await handleSubmit( (d) => {
            result = false;
            if(isDirty) editEmployment(d, index);
            reset({}, {keepValues: true});
        })();

        return result;
    }

    const onSubmitResidentialHistory = async () => {
        let result = true;

        await handleSubmit( (d) => {
            result = false;
            if(isDirty) editResidentialHistory(d);
            reset({}, {keepValues: true});
        })();

        return result;
    }


    const fileRef = useRef();

    const [isSubmit, setIsSubmit] = useState(false);

    const submitHandler = () => {
        handleSubmit( (d) => {
            createPortfolio(d);
        })();
    }

    useEffect(() => {
        if(isSubmit) setIsSubmit(false);
    }, [isSubmit])

    useEffect(()=> {
        if (readyToSend) {
            createProfileHandler();
            setIsSubmit(false);
        }
    }, [readyToSend]);

    function createProfileHandler () {
        createPortfolio(tenantData);
    }

    const pickImageHandler = (file) => {
        if (!file) {
            // TODO: add error message
            return;
        }

        fileToDataUri(file)
        .then(dataUri => {
            file.size < 10485760 
                ? (/\.(jpe?g|png|gif)$/i.test(file.name) 
                ? addImage(dataUri, file) 
                : ErrorPopup("The image extension is incorrect (correct for: jpg, png, gif)")) 
                : ErrorPopup("Image size is too large (Max file size is up to 10MB)")
        })
    }

    function addImage(e, file) {
        setImageHandler({
                src: e,
                id: uuidv4(),
                fileName: file.name,
                fileSize: ((file.size / 1024) / 1024).toFixed(2),
                show: true
            }
        );
    }

    const openFileInput = () => {
        fileRef.current.click();
    }

    useEffect(() => {
        if(image) {
            currentlyEditedSectionHandler("Image")
        }
    }, [image])

    const returnImageSrc = (data) => {
        const photoBlob = image && image.src;
        if(photoBlob) return photoBlob;

        return data;
    }

    const saveImageHandler = () => {
        const profilePhotoBlob = image;

        fileRef.current.value = null;
        uploadPicture(profilePhotoBlob);
        currentlyEditedSectionHandler(null);
    }

    const closeImageHandler = () => {
        currentlyEditedSectionHandler(null);
        fileRef.current.value = null;
        setImageHandler(null);
    }

    const deleteImageHandler = () => {
        fileRef.current.value = null;
        deletePicture();
    }

    const addEmployment = () => {
        employmentAppend({ 
            Description:'', 
            Duration:'', 
            Employer:'', 
            IsCurrentPoistion: false, 
            Location:'', 
            Position: '', 
            JobStart: '',
            JobEnd: ''
        })
    }

    const removeEmployment = (index) => {
        employmentRemove(index);
        // currentlyEditedSectionHandler(null);
    }

    const deleteEmploymentHandler = async (index, firstTimeCreating=false) => {
        if (firstTimeCreating) {
            removeEmployment(index);
        } else {
            await deleteEmployment(index);
            currentlyEditedSectionHandler(null);
        }
    }

    const addFormerLandlord = () => {
        formerLandlordsAppend({
            Name: '',
            Duration: '',
            Number: '',
            Description: ''
        })
    }

    const removeFormerLandlord = (index) => {
        formerLandlordsRemove(index);
    }

    const addPreviousEviction = () => {
        previousEvictionsAppend({
            Date: '',
            Description: ''
        })
    }

    const removePreviousEviction = (index) => {
        previousEvictionsRemove(index);
    }

    const saveDocument = () => {
        currentlyEditedSectionHandler(null);
        saveDocumentsHandler();
    }

    const cancelAddDocument = () => {
        currentlyEditedSectionHandler(null);
        cancelAddDocumentHandler();
    }

    useEffect(() => {
        if(files && files.length) {
            currentlyEditedSectionHandler("Documents Upload")
        }
    }, [files])

    return (
            <Container fluid className="p-0 m-0 mb-5 mx-auto" style={{maxWidth: "1200px"}}>
                <Card className="border-0 shadow-sm w-100" style={{ height: "100%"}}>

                    <Card.Body className="lightGray overflow-hidden p-0 rounded">
                    
                        <Row className="p-1 m-0 row-cols-1 row-cols-md-2 position-relative">
                            { (userType === "Tenant" || userType === "CaseManager") && (
                                <>
                                    <Col className="row-cols-1 p-1 m-0 mb-4 position-relative" xs={12} sm={5} md={3}>
                                        <BluredSection show={editedSection && editedSection !== "Image"}/>

                                        <ImageSquare 
                                            imageSrc={ tenantData && returnImageSrc(tenantData.ProfilePhotoUrl) }
                                            imageAltText="profile image"
                                            deletePicture={deleteImageHandler}
                                            showDeleteBtn={!image}
                                            canEdit={canEdit}
                                        />

                                        { isLoading && (
                                            <ButtonPrimary
                                                additionalClassName='mt-1 darkerGray skeleton border-light'
                                                onClick={() => {}}
                                                title={<>&nbsp;</>}
                                            />
                                        )}
                                        
                                        { (canEdit || forceEditMode) &&
                                            <>
                                                <ButtonPrimary
                                                    additionalClassName='mt-1'
                                                    onClick={ () => {(!(image) || forceEditMode) ? openFileInput() : saveImageHandler()}}
                                                >
                                                    { forceEditMode ? 
                                                        <><i className="bi bi-pencil-fill me-2"></i>Change Image</> : 
                                                        (!(image) ? 
                                                        <><i className="bi bi-pencil-fill me-2"></i>Change Image</> : 
                                                        <><i className="bi bi-arrow-up me-2"></i>Save Image</>) }
                                                </ButtonPrimary>

                                                {   (!forceEditMode && image) && (
                                                    <ButtonSecondary
                                                        additionalClassName='mt-1'
                                                        onClick={ closeImageHandler }
                                                        title='Cancel'
                                                    />)
                                                }

                                                
                                                <Form.Control 
                                                    type="file" 
                                                    className="d-none"  
                                                    ref={ fileRef }
                                                    onChange={(event) => pickImageHandler(event.target.files[0] || null)} /> 
                                            </>
                                        }
                                    </Col>
                                    <Col className="position-relative flex-grow-1 p-1 p-md-3 montserrat" sm={5}>
                                        <BluredSection show={editedSection && editedSection !== "Basic"}/>
                                        <BasicInfoForm
                                            tenantData = {tenantData}
                                            register={register}
                                            errors={errors}
                                            watch={watch}

                                            forceEditMode={forceEditMode}
                                            canEdit={canEdit}
                                            BioLength={BioLength}

                                            onSubmitBasic={onSubmitBasic}
                                            resetForm={resetForm}
                                            currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                            isLoading={isLoading}
                                        />
                                    </Col>

                                 
                                </>
                            )}
                        </Row>

                        <SectionAccordionWrapper defaultActiveKey={[0]} alwaysOpen>

                            <SectionAccordion
                                title={"Target Unit"}
                                eventKey={"0"}
                                className="my-3"
                                icon={"bi-house-heart-fill"}
                            >
                                <TargetUnit 
                                    tenantData = {tenantData}
                                    register={register}
                                    errors={errors}
                                    watch={watch}

                                    forceEditMode={forceEditMode}
                                    canEdit={canEdit}
                                    BioLength={BioLength}

                                    onSubmitBasic={onSubmitBasic}
                                    resetForm={resetForm}
                                    currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                                    isLoading={isLoading}
                                />
                            </SectionAccordion>

                            <SectionAccordion
                                title={"Employment"}
                                eventKey={"1"}
                                className="my-3"
                                icon={"bi-briefcase-fill"}
                            >
                                <Row className="p-1 m-0 row-cols-1">
                                    <BluredSection show={editedSection && editedSection.split(" ")[0] !== "Employment"}/>
                                    <Employment 
                                        forceEditMode={forceEditMode}
                                        editEmployment={editEmployment}
                                        
                                        deleteEmploymentHandler={deleteEmploymentHandler}

                                        isSubmit={isSubmit}
                                        tenantData={tenantData}
                                        canEdit={canEdit}

                                        addPosition={() => addEmployment()} 

                                        employmentFields={employmentFields}
                                        register={register}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}

                                        onSubmitEmployment={onSubmitEmployment}

                                        onlyView={onlyView}
                                        resetForm={resetForm}

                                        editedSection={editedSection}
                                        currentlyEditedSectionHandler={currentlyEditedSectionHandler}

                                        isLoading={isLoading}
                                    />
                                </Row>
                            </SectionAccordion>

                            <SectionAccordion
                                title={"Residental History"}
                                eventKey={"2"}
                                className="my-3"
                                icon={"bi-house-fill"}
                            >
                                <Row className="m-0 p-1">
                                    <BluredSection show={editedSection && editedSection !== "ResidentialHistory"}/>
                                    <ResidentialHistoryForm 
                                        onSubmitResidentialHistory={onSubmitResidentialHistory}

                                        forceEditMode={forceEditMode}
                                        canEdit={canEdit}

                                        tenantData={tenantData}

                                        previousEvictionsFields={ previousEvictionsFields }
                                        addEviction={ () => addPreviousEviction()}
                                        deleteEvictionHandler={ removePreviousEviction }
                        
                                        formerLandlordsFields={formerLandlordsFields}
                                        addFormerLandlord={ () => addFormerLandlord()}
                                        deleteFormerLandlordHandler={ removeFormerLandlord }

                                        register={register}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}

                                        onlyView={onlyView}
                                        resetForm={resetForm}
                                        currentlyEditedSectionHandler={currentlyEditedSectionHandler}

                                        isLoading={isLoading}
                                    />
                                </Row>
                            </SectionAccordion>

                            <SectionAccordion
                                title={"Documents"}
                                eventKey={"3"}
                                className="my-3"
                                icon={"bi-file-earmark-pdf-fill"}
                            >
                                <Row className="m-0 p-1">
                                    <BluredSection show={editedSection && editedSection.split(" ")[0] !== "Documents"}/>
                                    <DocumentForm 
                                        forceEditMode={forceEditMode}
                                        canEdit={canEdit}
                                        onlyView={onlyView}

                                        tenantData={tenantData}
                                        setTenantData={setTenantData}
                                        setIsLoading={setIsLoading}

                                        files={files}
                                        setFiles={setFiles}
                                        setFilesToDelete={setFilesToDelete}
                                        onlyNewFiles={onlyNewFiles}
                                        changeFileDescriptionHandler={changeFileDescriptionHandler}
                                        deleteFileHandler={deleteFileHandler}
                                        resetForm={resetForm}
                                        
                                        editedSection={editedSection}
                                        currentlyEditedSectionHandler={currentlyEditedSectionHandler}

                                        saveDocumentsHandler={saveDocument}
                                        cancelBtnHandler={cancelAddDocument}

                                        isLoading={isLoading}
                                    />
                                </Row>
                            </SectionAccordion>
                            
                            { doesProfileExists && (
                                <SectionAccordion
                                   title={"Screening Report"}
                                   eventKey={"5"}
                                   className="my-3"
                                   icon={"bi-person-badge-fill"}
                               >
                                    <Row className="m-0 p-1 pb-3">
                                        <BluredSection show={editedSection && editedSection !== "Screening"}/>
                                        <Screening 
                                            onlyView={onlyView}
                                            reportInfo={tenantData}
                                        />
                                    </Row>
                                </SectionAccordion>
                                )
                            }
                        </SectionAccordionWrapper>
                        
                    </Card.Body>
                
                    {   forceEditMode &&
                        <FloatingSubmitBtn 
                            errors={errors}
                            submitHandler={submitHandler}
                        />
                    }

                </Card> 
            </Container>
    )
}

function SectionAccordionWrapper({ children, defaultActiveKey, ...props }) {
    const [opened, setOpened] = useState(defaultActiveKey);
    return (
      <Accordion
        onSelect={(e) => {
          setOpened(e);
        }}
        defaultActiveKey={defaultActiveKey}
        {...props}
      >
        {children &&
          React.Children.map(children, (child) =>

            child ? React.cloneElement(child, {
              isOpen: opened.includes(child.props.eventKey),
            }) : null

          )}

          {/* {children} */}
          
      </Accordion>
    );
  }