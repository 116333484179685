import React, {useState, useEffect, useCallback} from 'react';

export default function Marker(
    {
        pointCount, 
        points, 
        onClick, 
        getPriceRange, 
        isCluster,
        onHighlightHandler,
        BuildingsID,
        highlightBuildingWithID,
        alreadyRequested,
        favorites
    }) {
    const [isHighlighted, setIsHighlighted] = useState(null);

    const mouseEnterHandler = () => {
        onHighlightHandler(BuildingsID);
        setIsHighlighted(true);
    }

    const mouseLeaveHandler = () => {
        onHighlightHandler([]);
        setIsHighlighted(false);
    }

    const isHoveredInSideSection = useCallback(() => {
        if(!highlightBuildingWithID) return;
        return BuildingsID.some( id => id === highlightBuildingWithID[0]);
    }, [highlightBuildingWithID, BuildingsID]);

    useEffect(() => {
        setIsHighlighted(isHoveredInSideSection());
    }, [highlightBuildingWithID, isHoveredInSideSection]);

    const isFavorite = (favorites) => {
        if ( !BuildingsID ) return null;

        if (favorites && favorites.Buildings && favorites.Buildings.some( building => BuildingsID.includes(building.BuildingID))) {
            return 'building';
        }
        if (favorites && favorites.Units && favorites.Units.some( unit => BuildingsID.includes(unit.BuildingID))) {
            return 'unit';
        }
        return null;
    }

    return (
        <>
            {   isCluster &&
                <div
                    onMouseEnter={mouseEnterHandler}
                    onMouseLeave={mouseLeaveHandler}

                    className={`
                        marker-highlight
                        ${isHighlighted ? 'marker-highlight--active' : ''}
                        m-0 p-0 
                        marker
                        cursor-pointer 
                        position-relative 
                        on-hover 
                        text-white 
                        fw-bold 
                        border 
                        ${alreadyRequested ? 'bg-success' : 'bg-primary'}
                        border-3 
                        border-white 
                        rounded-circle 
                        d-flex 
                        justify-content-center 
                        align-items-center
                        `}
                    
                    style={{
                        boxShadow: '0 0 5px 0 rgba(0,0,0, 0.5)',
                        width: `${20 + (pointCount / points.length) * 30}px`,
                        height: `${20 + (pointCount / points.length) * 30}px`,
                    }}

                    onClick={() => onClick(BuildingsID)}
                > 
                    <div 
                        className={`position-absolute ${alreadyRequested ? 'bg-success' : 'bg-primary'} rounded-pill marker-highlight ${isHighlighted ? 'marker-highlight--active' : ''}`}
                        style={{boxShadow: '0 0 5px 0 rgba(0,0,0, 0.5)', zIndex: "999", top: '-1.4rem',  whiteSpace: "nowrap", padding: '1px 5px'}}>
                        <p 
                            className="m-0 p-0 text-white" 
                            style={{fontSize: '.8rem'}}>
                                ${getPriceRange[0]}<span className={`show-on-hover ${isHighlighted ? 'd-inline' : null}`}> - ${getPriceRange[1]}</span>
                        </p>
                    </div>

                    <h5 className="m-0 p-0" style={{fontSize: '.9rem'}}>{pointCount}</h5>                
                    
                </div>
            }

            {
                !isCluster &&
                <div
                    onMouseEnter={mouseEnterHandler}
                    onMouseLeave={mouseLeaveHandler}

                    className={`
                        marker-highlight
                        ${isHighlighted ? 'marker-highlight--active' : ''}
                        m-0 p-0 
                        marker
                        cursor-pointer 
                        position-relative 
                        on-hover 
                        
                        text-white 
                        fw-bold 
                         
                        ${alreadyRequested ? 'bg-success' : 'bg-primary'}
                        
                       
                        rounded-circle 
                        d-flex 
                        justify-content-center 
                        align-items-center`}
                    
                    style={{
                        width: isFavorite(favorites) ? '0rem': '1.5rem',
                        height: '1.5rem'
                    }}

                    onClick={() => onClick(BuildingsID)}
                > 
                    
                    { isFavorite(favorites) === 'building' && (
                        <>
                            <i className={`bi bi-star-fill ${alreadyRequested ? 'text-success' : 'text-warning'} fs-3`}></i>
                            <i className={`bi bi-star ${alreadyRequested ? 'd-none' : 'text-dark'} fs-3 position-absolute`} ></i>

                        </>
                    )}

                    { isFavorite(favorites) === 'unit' && (
                        <>
                            <i className={`bi bi-star-fill ${alreadyRequested ? 'text-success' : 'text-primary'} fs-3`}></i>
                        </>
                    )}
                   
                    <div 
                        className={`position-absolute ${
                            alreadyRequested ? 'bg-success' :  
                            isFavorite(favorites) === 'building' ? 'bg-warning text-dark' : isFavorite(favorites === 'unit') ? 'bg-primary text-white'
                            : 'bg-primary'} 
                            rounded-pill marker-highlight ${isHighlighted ? 'marker-highlight--active' : ''}`}
                        style={{
                            zIndex: "999", top: '-1.4rem',  whiteSpace: "nowrap", padding: '1px 5px'}}>

                        
                        <p 
                            className={`m-0 p-0`}
                            style={{fontSize: '.8rem', color: 'inherit'}}>
                            ${getPriceRange[0]}
                        </p>
                    </div>
                </div>
            }
        </>
    )
}
