import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import { CLIENT } from '../Clients/AddClientModal';

export default function GroupRow({
    isLast=false,
    clientData
  }) {
    const description = (client) => {
      const genderShort = client[CLIENT.CLIENT_GENDER]?.[0] || "";
      return `${genderShort}${genderShort ? ", ": ""}${client[CLIENT.CLIENT_AGE] || ""}${client[CLIENT.CLIENT_AGE] ? "y, " : ""}${client[CLIENT.CLIENT_CITY] || ""}${client[CLIENT.CLIENT_CITY] ? " " : ""}${client[CLIENT.CLIENT_STATE] || ""}`;
  }

    return (
      <Container fluid className="mt-2">
        <Row>
          <Col className="d-flex align-items-center justify-content-center">{ clientData && `${clientData[CLIENT.CLIENT_FIRST_NAME]} ${clientData[CLIENT.CLIENT_LAST_NAME]}` }</Col>
          {/* <Col className="d-flex align-items-center justify-content-center">{ clientData && description(clientData) }</Col> */}
          <Col className="d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center p-2">
                <p className="table-body__text d-block">{clientData && clientData[CLIENT.CLIENT_EMAIL]}</p>
                <p className="table-body__text d-block">{clientData && clientData[CLIENT.CLIENT_PHONE]}</p>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <Row>
                <Col xs="auto">
                    <ButtonPrimary 
                        title="Go To Profile"
                    />
                </Col>
            </Row>
          </Col>
        </Row>
        {
          !isLast && <hr />
        }
      </Container>
    );
  }
