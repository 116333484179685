import React from 'react';
import {
    Col, Row,
} from 'react-bootstrap';

const Message = ({selectedReq}) => {
    

    const prepareMessageForDisplay = (text) => {
        const currentText = text;
        if(!currentText) return '';
        const splitedText = currentText.split('\n');
        const prepareMarkdown = splitedText.map( (row, index) => 
            {
                if (row === '') return <br key={index}/>;
                return <p key={index} className="m-0 p-0 fs-6">{row}</p>;
            }
            
        );
        return prepareMarkdown;
    }

    return (
        <Col className="m-0 py-3 mb-3 white rounded shadow-sm" xs={12}>
            <Row className="m-0 p-0 px-3">
                <Col className="m-0 p-0 mb-3">
                    <p className="m-0 p-0 fs-6 text-start fw-bold"><i className="bi bi-person-circle"></i>&nbsp;{selectedReq ? selectedReq.from : ''}</p>
                </Col>

                {/* MESSAGE */} 
                <Col className="m-0 mb-3 p-0" xs={12}>
                    {/* {(selectedReq && selectedReq.availableDates) && <p className="m-0 p-0 d-inline fs-6"><strong>Message: </strong></p>} */}
                    {   
                        selectedReq 
                        ? prepareMessageForDisplay(selectedReq.message) 
                        : <p className="m-0 p-0 fs-6">There is no messages for this unit!</p>}
                </Col>

                {/* AVAILABLE DATES */}
                { (selectedReq && selectedReq.availableDates) &&
                    <Col className="m-0 mb-2 p-0" xs={12}>
                        <p className="m-0 p-0 d-inline fs-6"><strong>Available for a tour on: </strong></p>
                        {
                            selectedReq && selectedReq.availableDates.map( tourDate => (
                                <p key={tourDate} className="m-0 p-0 fs-6">{tourDate}</p>
                            ))
                        }
                    </Col>
                }
            </Row>
             {/* MESSAGE DATE */}
             <Row className="m-0 p-0 justify-content-end align-items-end flex-grow-1">
                <p className="m-0 p-0 d-inline text-end" style={{fontSize: '.85rem'}}>{selectedReq && selectedReq.date}</p>
            </Row>
        </Col>
    )
}

export default Message;
