import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

import { getTickets } from "../services/tickets";
import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import { determineDate } from '../utils/dateTransformations';
import FiltersBtn from "../components/Shared/Filters/FiltersBtn";
import Filters, { FilterRow } from "../components/Shared/Filters";
import Backdrop from "../components/Shared/Backdrop";

import UnitAndBuildingInfoModal from '../components/Shared/UnitAndBuildingInfoModal';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';

export const FILTER_REQUESTS_BY = Object.freeze({
    NEW: 'New',
    OPEN: 'Open',
    CLOSE: 'Close',
    ALL: 'All',
    NEW_REQ: 'NewRequests'
});

export const CURRENT_PROGRESS = Object.freeze({
    REQ_SENT: 'Request Sent', 
    LL_RES: 'Landlord Responded', 
    T_RES: 'Tenant Responded', 
    M_RES: 'Manager Responded', 
});

const FILTERS = {
    DATE: 'Date',
    STATUS: 'Status'
}

const FILTER_BY = {
    MOST_RECENT: 'mostRecent',
    MOST_PAST: 'mostPast',
    OPEN_MESSAGES: 'openMessages',
    CLOSED_MESSAGES: 'closedMessages'
}


export default function NewMessages() {
    const history = useHistory();
    const { token } = useContext(AuthContext);
    const { userType } = useContext(UserTypeContext);
    const [loading, setLoading] = useState(false);
    const [ticket, setTickets] = useState(null);
    const [showMobileFilterMenu, setShowMobileFilterMenu] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(FILTER_REQUESTS_BY.ALL);
    const [openFilters, setOpenFilters] = useState(false);
    const [activeSort, setActiveSort] = useState({
        Date: FILTER_BY.MOST_RECENT,
    });

    const extrudeTicketsByBuilding = (tickets) => {
        if (!tickets) return null;
    
        const extrudedProperties = Object.entries(tickets).reduce( ( acc, [_, value]) => {
            return {
                ...acc,
                ...value
            }
        }, {});
        if(!extrudedProperties) return {};

        return extrudedProperties;
    }

    const extrudeTickets = (tickets) => {
        if (!tickets) return null;

        const extrudeAllTickets = Object.entries(tickets).reduce( (acc, [_, value]) => {
            if(value) {
                acc.push(...value);
            }
            return acc;
        }, []);

        return extrudeAllTickets;
    }

    const getTicketsHandler = useCallback( async () => {

        setLoading(true);
        const response = await getTickets(token, userType.toLowerCase());
        
        const { success, data } = response;

        if (success) {
            switch(userType) {
                case 'Manager':
                    const extrudedTickets = extrudeTickets(extrudeTicketsByBuilding(data));
                    setTickets(extrudedTickets);
                    break;
                case 'Landlord':
                    setTickets(extrudeTickets(data));
                    break;
                case 'Tenant':
                    setTickets(data);
                    break;
                default:
                    setTickets([]);
            }
        } else {
            ErrorPopup();
        }
        setLoading(false);
    }, [token, userType]);
    

    function checkCurrentProgress(a_progressDescriptions, o_ticket) {
        return a_progressDescriptions.some( desc => o_ticket.CurrentProgress === desc )
    }
    
    function checkTicketStatus(b_isOpen, o_tickets) {
        return o_tickets.Status === b_isOpen;
    }

    useEffect(() => {
        if(token && userType) {
            getTicketsHandler(token, userType);
        }

    }, [token, userType, getTicketsHandler]);

    const goToHistoryInbox = (buildingID, unitID, ticketID) => {
        if(userType === 'Tenant') {
            history.push(`/request?request-id=${ticketID}`);
            return;
        }

        history.push(`/tourRequest/${buildingID}/unit/${unitID}?ticket=${ticketID}`);
    };


    const [showModal, setShowModal] = useState(false);
    const [modalInfoData, setModalInfoData] = useState({});
    const showModalInfo = (BuildingID, UnitID) => {
        setModalInfoData({
            BuildingID,
            UnitID
        });
        setShowModal(true);
    }
    const closeModal = () => {
        // setModalInfoData({});
        setShowModal(null);
    }


    const closeSideDrawers = () => {
        setOpenFilters(false);
        setShowMobileFilterMenu(false);
    }

    const resetFilters = () => {
        setActiveSort({
            Date: FILTER_BY.MOST_RECENT,
        })
    }
    
    const clearFilterHandler = (filterName, defaultValue='') => {
        setActiveSort( prevState => ({
            ...prevState,
            [filterName]: defaultValue
        }))
    }

    const changeHandler = (value, filterName) => {
        setActiveSort( prevState => ({
            ...prevState,
            [filterName]: value
        }))
    }

    const sortByTimeStamp = (arr, order) => {
        if(!arr) return [];
        const ticketsCopy = [...arr];

        return orderBy(ticketsCopy, function(dateObj) {
            let messagesLength = dateObj.Messages?.length;

            if(messagesLength === 0) messagesLength = 1;
            const timeStamp = dateObj.Messages[messagesLength - 1].TimeStamp;

            return new Date(timeStamp);
        }, [order]);
         // return orderBy(ticketsCopy, [function (ticket) { return ticket.Messages[ticket.Messages.length ? ticket.Messages.length - 1 : 0]?.TimeStamp }], [order])
    }

    const filterHandler = useMemo(() => {
        if(!ticket) return [];
        let sortedAndFilteredArr = ticket.filter( ticket => {
            switch(selectedFilter) {
                case FILTER_REQUESTS_BY.ALL:
                    return true;
                case FILTER_REQUESTS_BY.NEW_REQ:
                    return checkCurrentProgress([CURRENT_PROGRESS.REQ_SENT], ticket);
                case FILTER_REQUESTS_BY.CLOSE:
                    return checkTicketStatus(false, ticket);
                case FILTER_REQUESTS_BY.NEW:
                    if(userType === 'Tenant') {
                        return checkCurrentProgress([CURRENT_PROGRESS.LL_RES, CURRENT_PROGRESS.M_RES], ticket);
                    }
                    return checkCurrentProgress([CURRENT_PROGRESS.T_RES], ticket);
                default:
                    return false;
            }
        })
    
        if(activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT) {
            sortedAndFilteredArr = sortByTimeStamp(sortedAndFilteredArr, 'desc');
        } else if(activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST) {
            sortedAndFilteredArr = sortByTimeStamp(sortedAndFilteredArr, 'asc');
        }

        return sortedAndFilteredArr;
      }, [activeSort, ticket, selectedFilter, userType]);

      const setFilterHandler = (filterType) => {
        setSelectedFilter(filterType); 
        closeSideDrawers() 
      }

    const filterSection = () =>  (
        <>
            <RequestTypeButton colors={'bg-white'} isActive={selectedFilter === FILTER_REQUESTS_BY.ALL} title="All" icon="bi-envelope text-dark" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.ALL) }}/>
            <RequestTypeButton colors={'bg-success text-light'} isActive={selectedFilter === FILTER_REQUESTS_BY.NEW} title="New Messages" icon="bi-envelope-plus" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.NEW) }}/>
            <RequestTypeButton colors={'bg-secondary text-light'} isActive={selectedFilter === FILTER_REQUESTS_BY.NEW_REQ} title="New Requests" icon="bi-envelope-exclamation" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.NEW_REQ) }}/>
            <RequestTypeButton colors={'bg-danger text-light'} isActive={selectedFilter === FILTER_REQUESTS_BY.CLOSE} title="Declined" icon="bi-envelope-x" onClick={()=>{ setFilterHandler(FILTER_REQUESTS_BY.CLOSE) }}/>
        </>
    );
    
    return (
        <Container fluid className="p-0 h-100-minusNav" >
             <Backdrop 
                show={openFilters || showMobileFilterMenu}
                close={closeSideDrawers}
            />

            <UnitAndBuildingInfoModal 
                onHide={closeModal}
                showModal={showModal}
                unitAndBuildingIDs={modalInfoData}
            />

            <MobileSideMenu 
                show={showMobileFilterMenu}
                onClose={closeSideDrawers}
            >
                { filterSection() }
            </MobileSideMenu>

            <Filters 
                show={openFilters}
                close={closeSideDrawers}
                resetFilters={resetFilters}
            >
                <FilterRow
                    clearFilter={() => clearFilterHandler(FILTERS.DATE, FILTER_BY.MOST_RECENT )}
                    title={FILTERS.DATE}
                    value={ activeSort[FILTERS.DATE] }
                >
                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeHandler(FILTER_BY.MOST_RECENT, FILTERS.DATE)} className={`h-100 shadow-sm d-flex justify-content-center align-items-center m-1 p-1 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            <i className="bi bi-arrow-up fs-6"></i>
                            <p className={`m-0 p-0 ps-3 fs-6 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_RECENT ? 'text-white' : 'text-dark' }`}>Most Recent</p>
                        </div>
                    </Col>

                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeHandler(FILTER_BY.MOST_PAST, FILTERS.DATE)} className={`h-100 shadow-sm d-flex justify-content-center align-items-center m-1 p-1 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            <i className="bi bi-arrow-down fs-6"></i>
                            <p className={`m-0 p-0 ps-3 fs-6 ${ activeSort[FILTERS.DATE] === FILTER_BY.MOST_PAST ? 'text-white' : 'text-dark' }`}>Most Past</p>
                        </div>
                    </Col>
                </FilterRow>
            </Filters>

            <Row className="m-0 p-0" style={{height: 'inherit'}}>
                <Col xs="auto" className="d-none d-md-flex m-0 p-2 py-3 lightGray flex-row flex-md-column" style={{height: 'auto'}}>
                    { filterSection() }
                </Col>

                <Col className="m-0 p-0 pb-2" style={{height: 'inherit'}}>
                    <Row className="m-0 p-0 bg-light ms-2 py-3 px-2 rounded aling-items-center justify-content-end" >
            
                            <Col className="m-0 p-0 px-2 d-md-none">
                                <Button className="d-flex flex-row rounded justify-content-center aling-items-center shadow-none" onClick={() => { setShowMobileFilterMenu(true)} }>
                                    <i className="bi bi-menu-up"></i>
                                </Button>
                            </Col>

                            <FiltersBtn  
                                showResults={false}
                                resetFilters={resetFilters}
                                openFilters={() => setOpenFilters(true)}
                                disabled={loading}
                                howManyFiltersActive={() => 0}
                                btnTitle={'Sort'}
                            />
                     
                    </Row>
                    <Row className="m-0 p-2 my-2 py-0 overflow-scroll-y wissp-scroll-box me-2" style={{height: 'calc(100% - 72px)'}}>
                        <Col className="m-0 p-0 h-100">
                            { loading && (
                                <>
                                    <MessageBoxLoader showModalInfoButton={ userType === 'Tenant' }/>
                                    <MessageBoxLoader showModalInfoButton={ userType === 'Tenant' }/>
                                    <MessageBoxLoader showModalInfoButton={ userType === 'Tenant' }/>
                                </>
                            )}
                            { (!loading && ticket) && (
                                filterHandler.length 
                                ? filterHandler.map( (ticket, index) => (
                                        <MessageBox
                                            userType={userType}
                                            showModalInfo={showModalInfo}
                                            key={index} 
                                            ticket={ticket}
                                            onClick={goToHistoryInbox}
                                            showModalInfoButton={ userType === 'Tenant' }
                                        />
                                )) 
                                : (
                                    <Row className="m-0 p-0 justify-content-center align-items-center h-100">
                                        <p className="m-0 p-0 fs-6 text-center"> There are no messages...</p>
                                    </Row>
                                )

                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

function RequestTypeButton ({onClick, title, icon, children, isActive, colors}) {
    return (
        <Row className={`m-0 p-1 px-2 mb-1 cursor-pointer fs-6 on-hover-bg-secondary ${ isActive ? 'darkerGray' : ''} rounded`} onClick={onClick}>
            <div className="m-0 p-0 d-flex flex-row align-items-center justify-content-start h-100">
                { children || (
                <>
                    <i className={`bi ${icon} fs-4 me-0 me-md-2 ${colors} p-2 rounded-circle size-2 d-flex justify-content-center align-items-center overflow-hidden`}></i> 
                    <span className="d-inline">{title}</span>
                </>
            )}</div>
        </Row>
    )
}

function MessageBox ({
    ticket,
    onClick,
    showModalInfoButton,
    showModalInfo,
    userType
}) {
    const messageStatus = (ticket) => {
        if(!ticket) return 'bi-envelope-open opacity-0';
        const {CurrentProgress, Status} = ticket;
        if(Status === false) return 'bi-envelope-x bg-danger text-light';
        if( CURRENT_PROGRESS.REQ_SENT === CurrentProgress ) return 'bi-envelope-exclamation bg-secondary text-light';
        if( CURRENT_PROGRESS.T_RES === CurrentProgress ) return 'bi-envelope-plus bg-success text-light';
        return 'bi-envelope-open bg-white';
    }

    const getLatestMessageTimeStamp = (ticket) => {
        if(!ticket) return '';
        const {Messages} = ticket;
        if(!Messages) return '';
        return new Date(Messages.length ? Messages[Messages.length - 1]?.TimeStamp : '');
    }

    const getFullName = (ticket, userType) => {
        if(!ticket || !userType) return '';

        if(userType === 'Tenant') {
            const userID = ticket.Messages[ticket.Messages.length - 1].UserID;
            return `${ticket.Members[userID].FirstName.trim()} ${ticket.Members[userID].LastName.trim()}`
        }

        return `${ticket.Tenant.FirstName.trim()} ${ticket.Tenant.LastName.trim()}`;
    }

    return (
        <Row className="m-0 p-0 ">
            { showModalInfoButton && (
                <Col className="m-0 p-0 me-2 d-flex d-md-none" xs="auto">
                    <Row className="m-0 p-2 px-3 mb-1 lightGray on-hover-bg-secondary rounded cursor-pointer" onClick={() => { showModalInfo(ticket.BuildingID, ticket.UnitID) }} >
                        <Col className="m-0 p-0 d-flex align-items-center">
                            <i className="bi bi-info-circle"></i>
                        </Col>
                    </Row>
                </Col>
            )}

            <Col className="m-0 p-0">
                <Row className={`m-0 p-2 px-3 mb-2 lightGray rounded on-hover-scale cursor-pointer shadow-sm`} onClick={() => {ticket && onClick(ticket.BuildingID, ticket.UnitID, ticket.TicketID)}}>
                    <Col className="m-0 p-0 d-flex align-items-center" lg={3}>
                        <i className={`bi ${messageStatus(ticket)} shadow rounded-circle overflow-hidden p-2 me-1 fs-4 d-flex align-items-center justify-content-center `}></i>
                        <p className="m-0 p-0 fs-6 pb-2 pb-lg-0">
                            
                            &nbsp;
                            { getFullName(ticket, userType) }
                        </p>
                    </Col>

                    <Col className="m-0 p-0">
                        <Row className="m-0 p-0 align-items-center">
                            { showModalInfoButton && (
                                <Col className="m-0 p-0 me-2 d-none d-md-flex" xs="auto">
                                    <Row style={{height: '2.75rem'}} className="m-0 p-2 px-3 mb-1 darkerGray on-hover-bg-secondary rounded cursor-pointer" onClick={(e) => { 
                                        e.stopPropagation();
                                        showModalInfo(ticket.BuildingID, ticket.UnitID);
                                    }} >
                                        <Col className="m-0 p-0 d-flex align-items-center">
                                            <i className="bi bi-info-circle"></i>
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                            <Col className="m-0 p-0">
                                <Row className="m-0 p-0 mb-1">
                                    <p className="m-0 p-0 fs-6">Unit - {ticket && ticket.UnitInfo.Name} listed at ${ticket && ticket.UnitInfo.Rent}</p>
                                </Row>

                                <Row className="m-0 p-0"> 
                                    <p className="m-0 p-0 " style={{fontSize: '.85rem'}}> <span className="text-muted"><i className="bi bi-geo-alt"></i> {ticket && ticket.BuildingName} - {ticket && ticket.UnitInfo.Address.replace(/\$\*\|\*\$/, ' ')}, {ticket && ticket.UnitInfo.City}, {ticket && ticket.UnitInfo.State}</span></p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="m-0 p-0 d-flex align-items-center justify-content-end" md={'auto'}>
                        <p className="m-0 p-0 mt-2 mt-md-0 ps-md-2" style={{fontSize: '.85rem'}}>{determineDate(getLatestMessageTimeStamp(ticket))}</p>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

function MessageBoxLoader ({showModalInfoButton}) {
    return (
        <Row className="m-0 p-0 ">
            { showModalInfoButton && (
                <Col className="m-0 p-0 me-2 d-flex d-md-none" xs="auto">
                    <Row className="m-0 p-2 px-3 mb-1 lightGray rounded" >
                        <Col className="m-0 p-0 d-flex align-items-center">
                            <div className="m-0 p-0 darkerGray rounded skeleton" style={{width: '1rem'}}></div>
                        </Col>
                    </Row>
                </Col>
            )}

            <Col className="m-0 p-0">
                <Row className={`m-0 p-2 px-3 mb-2 shadow-sm lightGray rounded`} style={{minHeight: 65}}>
                    <Col className="m-0 p-0 d-flex align-items-center" lg={3}>
                        <div className="m-0 p-0 darkerGray rounded skeleton w-25" style={{height: '1rem'}}></div>
                    </Col>

                    <Col className="m-0 p-0">
                        <Row className="m-0 p-0 align-items-center">
                            { showModalInfoButton && (
                                <Col className="m-0 p-0 me-2 d-none d-md-flex" xs="auto">
                                    <Row style={{height: '2.75rem'}} className="m-0 p-2 px-3 mb-1 darkerGray skeleton text-light rounded cursor-pointer">
                                        <Col className="m-0 p-0 d-flex align-items-center">
                                            <i className="bi bi-info-circle opacity-0"></i>
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                            <Col className="m-0 p-0 py-1">

                                <Row className="m-0 p-0 mb-1">
                                    <div className="m-0 p-0 darkerGray rounded skeleton" style={{height: '1rem', width: '6rem'}}></div>
                                </Row>

                                <Row className="m-0 p-0"> 
                                    <div className="m-0 p-0 darkerGray rounded skeleton" style={{height: '.85rem', width: '10rem'}}></div>
                                </Row>

                            </Col>
                        </Row>
                    </Col>



                    <Col className="m-0 p-0 d-flex align-items-center justify-content-end" md={'auto'}>
                        <div className="m-0 p-0 mt-2 mt-md-0 ps-md-2 darkerGray rounded skeleton" style={{height: '.85rem', width: '3rem'}}></div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

function MobileSideMenu ({
    show,
    onClose,
    children
}) {
    return (
        <Container className={`p-3 sideDrawer sideDrawer--left d-flex flex-column ${show ? 'sideDrawer--show' : ''} bg-light`}>

            <Row className={'m-0 p-0 mb-3 mb-md-5 sideDrawer__close'}>
                <Col className={`m-0 p-0 d-flex justify-content-end align-items-center`}>
                    <Button className="m-0 p-2 border-0 bg-transparent" onClick={onClose}>
                        <i className="bi bi-x-lg text-dark"></i>
                    </Button>
                </Col>
            </Row>
            
            <Container className="m-0 p-0 flex-grow-1">
                { children }
            </Container>

        </Container>
    )
}
