import React from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function UserReport({ reportValidDays=0 }) {
    return (
        <Row className="m-0 p-0">
            <Row className="m-0 mb-3 p-2 darkerGray rounded">
                <h5 className="m-0 p-0 text-uppercase text-center fw-bold">Report</h5>
            </Row>

            <Row className="m-0 p-2">
                <h6>Your Report will be valid for the next {reportValidDays} days</h6>
            </Row>

            <Row className="m-0 p-2 justify-content-center align-items-center">
                <Col className="m-0 p-0 text-center" xs={12}>
                    <ButtonPrimary 
                        label="Show my report"

                    />
                </Col>
            </Row>
        </Row>
    )
}
