import React from 'react';
import useScrolled from '../../hooks/useScrolled';

export default function ScrollToTopButton() {
    // const { pathname } = useLocation();
    const scrolledVal = useScrolled();

    return (
        <div
        //    className={`position-fixed end-0 translate-middle colorWhite bg-primary ${ pathname !== '/' ? 'bg-primary' : ''} p-2 scrollToTop ${scrolledVal <= .2 ? "off" : ""}`}
            className={`position-fixed end-0 translate-middle colorWhite bg-primary p-2 scrollToTop ${scrolledVal <= .2 ? "off" : ""}`}
            style={{ fontSize: 50, cursor: "pointer", width: 75, height: 75, zIndex: 1 }}
            onClick={()=>{window.scrollTo({top: 0, behavior: "smooth"})}}
            scrolled={scrolledVal}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-arrow-up-square-fill position-absolute top-50 start-50 translate-middle" viewBox="0 0 16 16">
                <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z" />
            </svg>
        </div>
    )
}
