import React from 'react'
import {
    Col,
    Row,
    Form,
    Button
} from 'react-bootstrap';

import DecisionModal from '../Shared/InfoPopups/DecisionModal';
import PhoneWithMask from "../Shared/Inputs/PhoneWithMask";
import InputFloating from '../Shared/Inputs/InputFloating';
import {
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME,
    MANAGER_EMAIL,
    MANAGER_PHONE,
} from '../../constants/buildingFormConstants';

export default function ManagerForm({
    index,
    f_register,
    o_errors,
    prefix,
    removeManager
}) {
    const getError = o_errors.Managers?.[index];

    const deleteManagerHandler = async () => {
        const userDecision = await DecisionModal({message: 'Once deleted, you will not be able to recover this manager!'});

        if ( userDecision.isDismissed || userDecision.isDenied ) return;

        removeManager(index)
    }

    return (
        <Row className="m-0 p-0">

            <Col xs={12} className="m-0 p-0 py-2 d-flex justify-content-end">
                <Button
                    onClick={ deleteManagerHandler }
                    type='button'
                    bsPrefix="darkerGray"
                    variant="secondary"
                    className="fw-bold p-2 ps-3 pe-3 ms-3 bg-danger rounded">
                        X
                </Button>  
            </Col>

            <Col xs={12} md={6} className="p-0 pe-0 pe-md-2">
                <Form.Group className="m-0 p-0 mb-2">
                    <InputFloating 
                        label={"First Name"}
                        type="text"
                        register={f_register(`${prefix}.${MANAGER_FIRST_NAME}`)}
                        showError={true}
                        errorMessage={getError?.[MANAGER_FIRST_NAME]?.message}
                    />
                </Form.Group>
            </Col>

            <Col xs={12} md={6} className="p-0 ps-0 ps-md-2">
                <Form.Group className="m-0 p-0 mb-2">
                    <InputFloating 
                        label={"Last Name"}
                        type="text"
                        register={f_register(`${prefix}.${MANAGER_LAST_NAME}`)}
                        showError={true}
                        errorMessage={getError?.[MANAGER_LAST_NAME]?.message}
                    />
                </Form.Group>
            </Col>


            <Col xs={12} md={6} className="p-0 pe-0 pe-md-2">
                <Form.Group className="m-0 p-0 mb-2">
                    <InputFloating 
                        label={"Email address"}
                        type="email"
                        register={f_register(`${prefix}.${MANAGER_EMAIL}`)}
                        showError={true}
                        errorMessage={getError?.[MANAGER_EMAIL]?.message}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={6} className="p-0 ps-0 ps-md-2">
                <Form.Group className="m-0 p-0 mb-2">
                    <PhoneWithMask 
                        register={f_register(`${prefix}.${MANAGER_PHONE}`)}
                        classes={{floatingLabel: 'm-0'}}

                        label="Phone Number"
                        showError={true}
                        errorMessage={getError?.[MANAGER_PHONE]?.message}
                    />
                </Form.Group>
            </Col>

        </Row>
    )
}
