import React, { useState, useMemo, useRef } from "react";

import {
  Container,
  Row,
  Col,
  Card
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import UnitFlat from './UnitFlat';
import UnitImageMobile from './UnitImageMobile';
import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";
import LazyImage from "../../Shared/LazyImage";
import UnitFlatLoader from "./UnitFlatLoader";
import preparePhotos from "../../../utils/preparePhotos";
import { useElementDimensions } from "../../../hooks/useElementDimensions";

export const CURRENT_PROGRESS = Object.freeze({
  REQ_SENT: 'Request Sent',
  T_RES: 'Tenant Responded'
});

//* require.context to dynamically import all images from given directory with Regex given below ~Kazor
//! /\.(png|jpe?g)$/

export default function PropertiesUnit({
  property,
  sortedTickets,
  loadingTickets,
  alternativeStyle
}) {
  const history = useHistory();
  const [isUnitHover, setIsUnitHover] = useState(false);

  const openBuildingInfo = () => {
    history.push(`/building-info?building-id=${property.BuildingID}`);
  }

  const openAllRequestsHandler = () => {
    history.push(`/building-info?building-id=${property.BuildingID}&active-tab=requests`);
  }

  const extrudeTickets = (buildingID, tickets) => {
    if (!tickets || !buildingID) return [];
    return tickets[buildingID] || [];
  }

  const getNewMessages = (a_progressDescriptions, a_tickets) => {
    return a_tickets.filter(({ CurrentProgress }) => {
      return a_progressDescriptions.some(desc => CurrentProgress === desc)
    })
  }

  const getHowManyNewMessages = useMemo(() => {
    return getNewMessages([CURRENT_PROGRESS.REQ_SENT, CURRENT_PROGRESS.T_RES], extrudeTickets(property.BuildingID, sortedTickets)).length;
  }, [property, sortedTickets]);

  const preparedPhotos = preparePhotos(property);
  const elementRef = useRef(null);
  const { width, height } = useElementDimensions(elementRef)
  // console.log(`Width: ${width}, Height: ${height}`)
  return (
    <Container fluid className="m-0 px-0 d-flex justify-content-center" style={{ minHeight: 300 }} ref={elementRef} >
      <Card className="m-0 p-0 border-0  w-100" style={{ height: "100%", background: 'transparent' }}>
        <Card.Body className={`"m-0 p-0 lightGray rounded overflow-hidden w-100 ${isUnitHover ? "scale-smaller" : ""}`} style={{ transition: '.3s' }}>

          <UnitImageMobile
            unit={property}
            openProperty={openBuildingInfo}
          />

          <Row className="m-0 p-0 w-100" style={{ /*height: 300 */ }}>
            <Col
              xs="auto"
              className="m-0 p-0 d-none d-xl-block cursor-pointer"
              // style={{
              //   backgroundImage: `url('${property && property.PhotoURL}')`,
              //   width: alternativeStyle? 150 : 300,
              //   height: alternativeStyle? 150 : 300,
              //   backgroundSize: "cover",
              //   backgroundPosition: "50%,50%",
              // }}
              onClick={openBuildingInfo}
              onMouseEnter={() => setIsUnitHover(true)}
              onMouseLeave={() => setIsUnitHover(false)}
            >
              <LazyImage src={preparedPhotos[0]} style={{ objectFit: "cover", aspectRatio: '1/1', width: "200px" }} />
            </Col>
            <Col className="px-2 px-lg-3 m-0 d-flex flex-column justify-content-start ">
              <Row className="p-0 m-0 d-flex align-items-center  mb-3">

                <Row className="m-0 p-0 cursor-pointer"
                  onClick={openBuildingInfo}
                  onMouseEnter={() => setIsUnitHover(true)}
                  onMouseLeave={() => setIsUnitHover(false)}
                >
                  <Col className="m-0 p-0">
                    <h5 className="p-0 m-0 text-dark my-sm-2 fs-6">
                      {property.Name}
                    </h5>
                    <p className="m-0 p-0 fs-6 text-muted fs-7">
                      <span className="text-muted"><i className="bi bi-geo-alt"></i> {property.Address.replace(/\$\*\|\*\$/, ' ')}, {property.City}</span>
                    </p>
                  </Col>

                  <Col className="m-0 p-0 d-flex align-items-center justify-content-center" xs={'auto'}>
                    <i className="bi bi-chevron-right fs-6 text-dark"></i>
                  </Col>
                </Row>

              </Row>
              {loadingTickets && <UnitFlatLoader />}

              {(!loadingTickets && !extrudeTickets(property && property.BuildingID, sortedTickets).length) && (

                <Row className="m-0 p-1 p-md-0 d-flex darkerGray rounded">
                  <Col className="m-0 p-0">
                    <p className="m-0 p-1 px-2 fs-7">There are no requests yet...</p>
                  </Col>
                </Row>
              )}

              {!loadingTickets && extrudeTickets(property && property.BuildingID, sortedTickets).map((flat, index, array) => {
                // let unitsLength = units[unitsIndex].singleUnit.length;
                if (index < 2) {
                  return (
                    <UnitFlat
                      key={index}
                      flat={flat}
                    ></UnitFlat>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <Row className="m-0 p-0 justify-content-end align-items-center">
                        <Col className="m-0 p-0" xs='auto'>
                          <p className="m-0 p-0 fs-7 text-end">and <b>{array.length - 2}</b> more request{array.length - 2 === 1 ? '' : 's'}</p>
                        </Col>
                      </Row>

                      <Row key={index} className="p-0 m-0 py-2 d-flex justify-content-end align-items-center">
                        <Col className='m-0 p-0 position-relative' xs='auto'>
                          <ButtonPrimary variant="primary" onClick={openAllRequestsHandler} type="button" title={`All Unit Requests (${array.length})`} additionalClassName="fs-7"/>

                          <span className={`${getHowManyNewMessages ? 'd-block' : 'd-none'} position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger`}>
                            {getHowManyNewMessages}
                            <span className="visually-hidden">New messages</span>
                          </span>

                        </Col>
                      </Row>
                    </div>
                  );
                } else {
                  return null;
                }
              })}


            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}