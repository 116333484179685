import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

import { objFromArrAndFilledWithValue } from '../../utils/dataTransformations';
import FeaturesFields from '../BuildingFormElements/FeaturesFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import {
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    ACCESSIBILITIES,
    AMENITIES
} from '../../constants/buildingFormConstants';

const schema = YupObject().shape({
    [MISC_AMENITIES]: YupString()
        .notRequired(),
    [MISC_ACCESSIBILITIES]: YupString()
        .notRequired(),
});


export default function FeaturesForm({
    features,
    onSubmitFeatures,
    sectionFocus,
    setSectionFocus,
    building
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, handleSubmit, reset } = useForm(formOptions);

    const [amenities, setAmenities] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);

    useEffect(()=>{
        if (building) {
            setAccessibilities(objFromArrAndFilledWithValue(building[ACCESSIBILITIES]));
            setAmenities(objFromArrAndFilledWithValue(building[AMENITIES]));
            reset({
                [MISC_AMENITIES]: building[MISC_AMENITIES] || '',
                [MISC_ACCESSIBILITIES]: building[MISC_ACCESSIBILITIES] || '',
            });
        }
    }, [building, reset])

    return (
        <Form
            onSubmit={handleSubmit( (data) => onSubmitFeatures(data, amenities, accessibilities))}
            className={sectionFocus && sectionFocus !== "Features" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus("Features") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >   
            <FeaturesFields 
                features={features}
                setAmenities={setAmenities}
                setAccessibilities={setAccessibilities}
                amenities={amenities}
                accessibilities={accessibilities}
                register={register}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        title="Update Property Features"
                    />
                </Col>
            </Row>
        </Form>
    )
}
