import React, { useEffect, useState } from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';

import InputFloating from '../Shared/Inputs/InputFloating';
import SelectFloating from '../Shared/Inputs/SelectFloating';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';

const states = 'AL,AK,AZ,AR,CA,CO,CT,DE,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY';

export default function TargetUnit({ isLoading, tenantData, forceEditMode, canEdit, register, errors, watch, BioLength, onSubmitBasic, resetForm, currentlyEditedSectionHandler }) {
    const [editMode, setEditMode] = useState(null);
    // const AdditionalInfoWatch = watch('AdditionalInfo', '');

    useEffect(() => {
        setEditMode(forceEditMode);
    }, [forceEditMode])

    const clickHandler = async (mode) => {
        if(mode === 'save') {
            const result = await onSubmitBasic();
            currentlyEditedSectionHandler(null);
            setEditMode( result );
        } else {
            currentlyEditedSectionHandler("Basic");
            setEditMode( true )
        }
    }

    const cancelBtnHandler = () => {
        setEditMode( false )
        currentlyEditedSectionHandler(null);
        resetForm();
    }

    return (
        <>
            { isLoading && (
                <Col xs={12} sm={'auto'} className="position-relative flex-grow-1 p-0 montserrat" >
                    
                </Col>
            )}

            { !editMode && !isLoading && (
                <Row>
                    <Col>
                        <Row>
                            <Col>
                            <p className="fs-6 my-0 text-dark fw-bold">City</p>
                            <p className="fs-6 text-dark">{tenantData && (tenantData.TargetCity || 'Boston')}</p>
                            </Col>
                            <Col>
                            <p className="fs-6 my-0 text-dark fw-bold">State</p>
                            <p className="fs-6 text-dark">{tenantData && (tenantData.TargetState || 'MA')}</p>
                            </Col>
                            <Col>
                            <p className="fs-6 my-0 text-dark fw-bold">Price range </p>
                            <p className="fs-6 text-dark">
                                {tenantData && tenantData.RentMin && tenantData.RentMax ? `$${tenantData.RentMin || 100} - $${tenantData.RentMax}` : `$100 - $2000`}
                            </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                    <p className="fs-6 my-0 text-dark fw-bold">Additional Information: </p>
                    <p className="fs-6 text-dark">{tenantData && tenantData.AdditionalInfo ? tenantData.AdditionalInfo : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et lacus volutpat, mattis nisi ut, facilisis sapien. Curabitur accumsan, enim eu hendrerit ornare, nibh sapien cursus urna, quis rutrum leo urna quis arcu. Curabitur a urna vitae nisl pretium sagittis. Suspendisse tincidunt arcu venenatis ligula dignissim, vel tempus tellus bibendum. Aliquam consectetur ante id lectus vestibulum, eu dapibus quam blandit. Aliquam erat volutpat. Nulla tellus nisi, gravida eget justo quis, efficitur mattis ipsum."}</p>
                    </Col>
                </Row>
            )
            }
            
            { editMode && !isLoading &&
                <Col className="position-relative flex-grow-1 p-0 montserrat">    
                    <Row className="m-0 p-0 w-100">

                        <Col xs={12} sm={4} lg={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <InputFloating 
                                label="City"
                                register={register("TargetCity")}
                                showError={true}
                                errorMessage={errors.TargetCity?.message}
                            />
                        </Col>

                        <Col xs={12} sm={4} lg={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <SelectFloating 
                                label="State"
                                aria-label="Select state" 
                                register={register("TargetState")}
                                showError={true}
                                errorMessage={errors.TargetState?.message}
                                optionEmpty={true}
                                dataArray={states.split(",")}
                            />
                        </Col>
                        
                        <Col xs={12} sm={4} lg={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <InputFloating 
                                label="Min Rent"
                                type="number"
                                min="0"
                                register={register("RentMin")}
                                showError={true}
                                errorMessage={errors.RentMin?.message}
                            />
                        </Col>
                        <Col xs={12} sm={4} lg={3} className="m-0 p-0 pb-2">
                            <InputFloating 
                                label="Max Rent"
                                type="number"
                                min="0"
                                register={register("RentMax")}
                                showError={true}
                                errorMessage={errors.RentMax?.message}
                            />
                        </Col>

                        <Col xs={12}></Col>

                        <Col xs={12} className="m-0 p-0 pb-2">
                            <TextAreaFloating 
                                label={"Additional Info (optional)" }
                                register={register("AdditionalInfo")}
                                showError={true}
                                errorMessage={errors.AdditionalInfo?.message}
                                style={{ height: 170 }}
                            />

                            {/* <p className="p-0 m-0 pe-2 pt-1 text-end" style={{fontSize: '.85rem'}}>
                                {AdditionalInfoWatch ? AdditionalInfoWatch.length : '0'} / {BioLength.current}
                            </p> */}
                        </Col>
                    </Row>
                    { canEdit && (
                            <>
                                <Row className="m-0 p-0 d-flex d-lg-none justify-content-center align-items-center" >
                                    <Col className="p-0 me-2" xs="auto">
                                        <ButtonSecondary onClick={cancelBtnHandler} title="Cancel"/>
                                    </Col>                                    
                                    <Col className="p-0" xs="auto">
                                        <ButtonPrimary onClick={() => clickHandler('save')}>
                                            <i className="bi bi-arrow-up me-2"></i>Save Target Unit
                                        </ButtonPrimary>
                                    </Col>
                                </Row>

                                <Row className="m-0 d-none d-lg-flex justify-content-center" >
                                    <Col className="p-0 me-2" xs="auto">
                                        <ButtonPrimary onClick={() => clickHandler('save')}>
                                        <i className="bi bi-arrow-up me-2"></i>Save Target Unit
                                        </ButtonPrimary>
                                    </Col>

                                    <Col className="p-0" xs="auto">
                                        <ButtonSecondary onClick={cancelBtnHandler} title="Cancel"/>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </Col>  
            } 

            { !editMode && !isLoading &&
                <Col xs={12} className="p-0" >
                    <Row className='m-0 justify-content-center'>
                        <Col className='p-0' xs="auto">
                            { canEdit && (
                                <div className="" >
                                    <ButtonPrimary onClick={() => clickHandler('edit')}>
                                        <i className="bi bi-pencil-fill me-md-2"></i><span className="d-none d-md-inline">Edit Target Unit</span>
                                    </ButtonPrimary>
                                </div> 
                            )}
                        </Col>
                    </Row>
                </Col>
            }
        </>    
    )
}
