import React from "react";
import {
    Row,
    Col
} from "react-bootstrap";

import {
    MISC_NOTES
} from '../../constants/buildingFormConstants';
import TextAreaFloating from "../Shared/Inputs/TextAreaFloating";


export default function NotesFields({
    register,
}) {
    return (
        <>
            <h3 className="fw-bolder">
                Additional Notes
            </h3>
            <Row className="m-0 p-0 mb-3">
                <Col className="m-0 p-0 mt-1" xs={12}>

                    <TextAreaFloating
                        placeholder={"Additional Notes..."}
                        label={"Additional Notes"}
                        register={register(MISC_NOTES)}
                    />
                </Col>
            </Row>
        </>
    )
}

