import noSV from "../assets/icons/noSV.svg"
import noPhotos from "../assets/icons/noPhotos.svg"

export default function preparePhotos(buildingData) {
    if (!buildingData || !buildingData.Photos) return;
    
    var res = buildingData.Photos;

    if (res?.length) return res.map(el=>el.URL)
    if(!buildingData?.StreetPOV?.Zoom) return [noPhotos];
    res = getStaticStreetView(buildingData);


    return [res];
}


export function prepareSortedPhotos(buildingData) {

    if (!buildingData || buildingData?.Photos.length == 0) return;

    var allPhotos = buildingData.Photos;

    var fav;
    var rest;

    if (allPhotos.length) {
        fav = getFavoritePhoto(allPhotos);
        if (allPhotos.length > 1)
            rest = [...allPhotos].filter(el => el.URL != fav);
    }
    return {
        allPhotos: allPhotos,
        favPhoto: fav,
        restPhotos: rest
    }
}


export function getFavoritePhoto(photos) {
    if (!photos || photos.length === 0) return;

    const fav = photos.find(img => img.IsFavorite).URL;

    return fav ?? photos[0].URL;
}

export function getStaticStreetView(buildingData){
    if(!buildingData || !buildingData.Latitude) return "error"
    const heading = buildingData?.StreetPOV?.Heading ? buildingData?.StreetPOV?.Heading : 180;
    const pitch = buildingData?.StreetPOV?.Pitch ? buildingData?.StreetPOV?.Pitch : 0;
    const fov = buildingData?.StreetPOV?.Zoom ? (180 / Math.pow(2, buildingData.StreetPOV?.Zoom)) : 45;
    const lat = buildingData.Latitude;
    const lng = buildingData.Longitude;

    return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${lat},${lng}&fov=${fov}&heading=${heading}&pitch=${pitch}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
}