import React from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';


export default function TenantInformations({ setTab }) {
    return (
    <Row className="m-0 p-0">
        <Row className="m-0 mb-3 p-2 darkerGray rounded">
            <h5 className="m-0 p-0 text-uppercase text-center fw-bold">Run background check</h5>
        </Row>
        <Row className="m-0 p-3 px-4">
            <p className="m-0 p-0 fs-6">By generating a background check through our integrated service, TenantTracks, you will increase your chances of being approved by a landlord. With this one time fee, your background check will be available for all landlords on our platform for the next 60 days.</p>
        </Row>
        <Row className="m-0 p-2 justify-content-center">
            <Col className="m-0 p-0 text-center" xs={'auto'}>
                <ButtonPrimary 
                    onClick={ setTab }
                    title="Continue"
                />
            </Col>
        </Row>
    </Row>
    )
}
