import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    userType: null,
    tokenExpire: null,
}

export const userSlice = createSlice({
    name: 'marketplaceStore',
    initialState,
    reducers: {
        setToken(state, action) {
            state.token = action.payload
        },
        setUserType(state, action) {
            state.userType = action.payload
        },
        clearUserStore(state) {
            state.token = null;
            state.userType = null;
            state.tokenExpire = null;
        }
    }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
