import React from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

export default function Loader() {
    return (
    <Row className="m-0 p-0 flex-column" style={{minHeight: '60vh'}}>
        <Row className="m-0 mb-3 p-2 darkerGray skeleton rounded" style={{ height: '2.5rem'}}></Row>
        
        <Row className="m-0 p-3 flex-column" >
            <Col xs={12} className="my-2 p-0 darkerGray skeleton rounded w-75" style={{height: '2rem'}}></Col>
            <Col xs={12} className="my-2 p-0 darkerGray skeleton rounded w-50" style={{height: '2rem'}}></Col>
        </Row>
    </Row>
    )
}
