import React from "react";
import { Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import SideNavigationButton from "./SideNavigationButton";


export default function HeaderNavLink ({
  to,
  title="Missing Title",
  isSideNav=false,
  onClick,
  children,
  className='custom_link',
  icon,
  smallNav
}) {
    return (
        <>
            { 
                !isSideNav ? (
                    <Col className="m-0 p-0 h-100" xs={"auto"}>
                        <NavLink
                            to={to}
                            activeClassName="link_active"
                            className={className}
                            onClick={onClick}
                        >
                            <div className="m-0 p-0 d-flex flex-column align-items-center justify-content-center">{ children || (
                                <>
                                    <i className={`bi ${icon}  header-animate-icon ${smallNav ? 'hide' : ''}`}></i>
                                    {title}

                                    {/* <i className={`bi ${icon} fs-4`}></i>
                                    {!smallNav && title} */}
                                </>
                            )}</div>
                        </NavLink>
                    </Col>
                ) : (
                    <SideNavigationButton
                        onClick={onClick}
                        title={title}
                        icon={icon}
                    >
                        { children }
                    </SideNavigationButton>
                )
            }
        </>
    )
}
