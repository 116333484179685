import React, { useLayoutEffect, useEffect, useState, useMemo } from 'react';
import {
    Row,
    Form,
    Col
} from 'react-bootstrap';

import BluredSection from '../Shared/BluredSection';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import DecisionModal from '../Shared/InfoPopups/DecisionModal';
import InputFloating from '../Shared/Inputs/InputFloating';
import SelectFloating from '../Shared/Inputs/SelectFloating';


export default function PositionCardForm({
    index, 
    tenantData, 
    forceEditMode=true, 
    canEdit=false, 
    prefix,
    register,
    errors,
    watch,
    setValue,
    onSubmitEmployment,
    deleteEmploymentHandler,
    resetForm,
    editedSection,
    currentlyEditedSectionHandler
}) {
    const [editMode, setEditMode] = useState(forceEditMode);

    const JobStartWatch = watch(`${prefix}JobStart`, '');
    const JobEndWatch = watch(`${prefix}JobEnd` , '');
    const DescriptionWatch = watch(`${prefix}Description`, '');

    const getError = errors.Employments?.[index];

    const clickHandler = async (mode) => {
        if(mode === 'save') {
            const result = await onSubmitEmployment(index);
            setEditMode( result );
            currentlyEditedSectionHandler(null);
        } else {
            setEditMode( true )
            currentlyEditedSectionHandler(`Employment ${index}`);
        }
    }

    const deletePositionHandler = async () => {
        const userDecision = await DecisionModal({});

        currentlyEditedSectionHandler(null);
        if ( !userDecision.isConfirmed ) return;

        if ( forceEditMode === false ) {
            setEditMode(false);

            deleteEmploymentHandler(index);
        } else {
            deleteEmploymentHandler(index, true)
        }
    }

    const cancelBtnHandler = () => {
        resetForm();
        setEditMode(false);
        currentlyEditedSectionHandler(null);
    }

    useLayoutEffect(() => {
       setEditMode(forceEditMode || !tenantData);
       if(!tenantData) {
            currentlyEditedSectionHandler(`Employment ${index}`);
       }
    }, [forceEditMode])

    const generateYearsArray = useMemo( () => {
        const howManyYears = 100;
        const yearsArray = [];
        const currentYear = new Date().getFullYear();
        const startingYear = currentYear - howManyYears;

        for( let count = currentYear; count >= startingYear; count-- ) {
            yearsArray.push(`${count}`);
        }

        return yearsArray;
    }, []);

    const optionsForJobEnd = useMemo(() => {
        if(!JobStartWatch) return [];

        const yearsArray = generateYearsArray;
        const toIndex = yearsArray.indexOf(JobStartWatch);

        if(toIndex === -1) return [];

        return yearsArray.slice(0, toIndex + 1);

    }, [JobStartWatch]);

    useEffect(() => {
        if(JobEndWatch === "Present") {
            setValue(`${prefix}IsCurrentPoistion`, true);
        } else {
            setValue(`${prefix}IsCurrentPoistion`, false);
        }
        if(JobEndWatch) {
            setValue(`${prefix}Duration`, `${JobStartWatch}-${JobEndWatch}`)
        }
    }, [JobEndWatch]);

    return (
    <>
        <Row className="m-0 p-1 position-relative">
            <BluredSection 
                show={editedSection && editedSection !== `Employment ${index}`}
            />

            <Col className="m-0 p-0 d-flex justify-content-end" xs={12}>
                <Row className="m-0 p-0">

                    { (canEdit && editMode) && (
                        <>
                            <Col className="m-0 p-0 pe-2" xs={'auto'}>
                                <ButtonSecondary 
                                    onClick={ cancelBtnHandler }
                                    title="Cancel"
                                    type="button"
                                />
                            </Col>
                            <Col className="m-0 p-0 pe-2" xs={'auto'}>
                                <ButtonPrimary onClick={() => clickHandler('save')}>
                                    <i className={`bi bi-arrow-up me-2`}></i>Save Position
                                </ButtonPrimary>
                            </Col>
                            { tenantData && (
                                <Col className="m-0 p-0" xs={'auto'}>
                                    <ButtonPrimary 
                                        onClick={ deletePositionHandler }
                                        additionalClassName="bg-danger border-danger"
                                        type="button"
                                    >
                                        <i className="bi bi-x-lg"></i>
                                    </ButtonPrimary>
                                </Col>
                                )
                            }

                        </>
                    )}

                    {   forceEditMode && (
                        <Col className="m-0 p-0 ps-2" xs={'auto'}>
                            <ButtonPrimary 
                                title="X"
                                onClick={ deletePositionHandler }
                                additionalClassName="bg-danger border-danger"
                                type="button"
                            />
                        </Col>

                    )}
                </Row>
            </Col>

            <Col xs={12} className="d-flex d-lg-none m-0 p-0 justify-content-end">
                { (canEdit && !editMode) &&
                    <ButtonPrimary onClick={() => clickHandler('edit')}>
                        <i className={`bi bi-pencil-fill me-2`}></i>Edit Position
                    </ButtonPrimary>
                }
            </Col>

            { !editMode &&
                <Row className="m-0 p-0 pt-3">
                    <Col xs={12} md={6} className="m-0 p-0">
                        <p className="my-1 "><b>Employer: </b>{tenantData && tenantData.Employer}</p>
                        <p className="my-1 "><b>Location: </b>{tenantData && tenantData.Location}</p>
                    </Col>
                    <Col xs={12} md={6} className="m-0 p-0">
                        <p className="my-1 "><b>Position: </b>{tenantData && tenantData.Position}</p>
                        <p className="my-1 "><b>Time at job: </b>{tenantData && tenantData.Duration}</p>
                    </Col>
                    <Col xs={12} className="m-0 p-0">
                        <p className="my-1 "><b>Job Description: </b>{tenantData && tenantData.Description}</p>
                    </Col>
                </Row>
            }

            { editMode && 
                <Form className="m-0 p-0 pt-2 pb-3">
                    <Row className="m-0 p-0">
                        <Col xs={12} sm={6} md={4} className="m-0 p-0 pe-sm-2 pb-2" >
                            <InputFloating 

                                label='Employer'
                                register={register(`${prefix}Employer`)}
                                showError={true}
                                errorMessage={getError?.Employer?.message}
                            />
                        </Col>

                        <Col xs={12} sm={6} md={4} className="m-0 p-0 pe-md-2 pb-2" >
                            <InputFloating 
                                label='Position'
                                register={register(`${prefix}Position`)}
                                showError={true}
                                errorMessage={getError?.Position?.message}
                            />
                        </Col>
                        <Col className="m-0 p-0" xs={4}>
                        </Col>
                        <Col xs={12} md={4} className="m-0 p-0 pe-md-2 pb-2">
                            <InputFloating 
                                label='Location'
                                register={register(`${prefix}Location`)}
                                showError={true}
                                errorMessage={getError?.Location?.message}
                            />
                        </Col>

                        {/* DURATION OF THE JOB*/}
                        <Col xs={12} sm={6} md={3} className="m-0 p-0 pe-sm-2 pb-2">
                            <SelectFloating 
                                label="Job Start"
                                aria-label="Select Job Star" 
                                register={register(`${prefix}JobStart`)}
                                showError={true}
                                errorMessage={getError?.JobStart?.message}
                                optionEmpty={true}
                                dataArray={generateYearsArray}
                            />
                        </Col>

                        <Col xs={12} sm={6} md={3} className="m-0 p-0 pb-2">
                        
                            <SelectFloating 
                                label="Job End"
                                aria-label="Select Job End" 
                                register={register(`${prefix}JobEnd`)}
                                showError={true}
                                errorMessage={getError?.JobEnd?.message}
                                optionEmpty={true}
                                dataArray={['Present', ...optionsForJobEnd]}
                            />

                        </Col>

                        <Col className="m-0 p-0" xs={12}>
                            <InputFloating 
                                label={DescriptionWatch ? "Job Description" : "Describe what you do at this job. If this is a previous position, describe why you left (optional)"}
                                register={register(`${prefix}Description`)}
                                showError={true}
                                errorMessage={getError?.Description?.message}
                            />
                        </Col>
                    </Row>
                </Form>
            }

            { (canEdit && !editMode) && (
                <Row className="m-0 p-0 pe-2 d-none d-lg-flex position-absolute justify-content-end">
                    <Col className="m-0 p-0" xs="auto">
                        
                        <ButtonPrimary onClick={() => clickHandler('edit')}>
                            <i className={`bi bi-pencil-fill me-2`}></i>Edit Position
                        </ButtonPrimary>
                        
                    </Col>
                </Row>
            )}
        </Row>
    </>            
    )
}
