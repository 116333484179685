import React, { useContext } from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';

import UploadFile from '../Shared/UploadFile';
import {AuthContext} from '../../contexts/AuthContext';
import {UserTypeContext} from '../../contexts/UserTypeContext';
import { downloadDocument } from '../../services/tenantDocument'
import DocumentRow from './DocumentRow';
import BluredSection from '../Shared/BluredSection'
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import ErrorPopup from '../Shared/InfoPopups/ErrorPopup';


export default function DocumentForm ({
    forceEditMode, 
    tenantData, 
    onlyView,
    saveDocumentsHandler,
    changeFileDescriptionHandler,
    files,
    setFiles,
    setFilesToDelete,
    onlyNewFiles,
    deleteFileHandler,
    resetForm,
    cancelBtnHandler,
    editedSection,
    currentlyEditedSectionHandler,
    isLoading
}) {
    const { token } = useContext(AuthContext);
    const { userType } = useContext(UserTypeContext);

    const downloadFileFromDB = async (DocumentID, FileName) => {
        const result = await downloadDocument(DocumentID, token);
        const {success, data} = result;

        if (success) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', FileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            ErrorPopup();
        }
    }

    return (
        <Row className={`p-0 m-0 row-cols-1 montserrat`}>
            {/* <Col className="m-0 p-2 darkerGray text-center position-relative rounded">
                <h5 className="fw-bold m-0 p-0 text-uppercase">Documents</h5>
            </Col> */}

            { !onlyView && (
                <Col className="m-0 p-3 pb-1 position-relative">
                    <BluredSection show={editedSection && editedSection !== `Documents Upload`}/>

                    <UploadFile 
                        disabled={isLoading}
                        downloadedFiles={null}
                        totalFiles={(tenantData && tenantData.TenantDocuments && tenantData.TenantDocuments.length) || 0}
                        files={files}

                        setFiles={setFiles}
                        setFilesToBeDeleted={setFilesToDelete}

                        sharedButtonStyles={{
                            background: '#10306c',
                            color: '#FFF',
                        }}
                        uploadFromGoogleButtonStyles={{
                            opacity: 0.6
                        }}
                    />
                </Col>)
            }

            { !onlyView && onlyNewFiles().length && !forceEditMode ? (


                <Col className="m-0 mb-4 p-0 px-3 pb-4 d-flex justify-content-center border-bottom border-2" xs={12}>

                    <Row className="m-0 p-0 justify-content-center align-items-center w-100">
                        <Col className="m-0 p-0 pb-1 pb-md-0 me-md-2" xs={12} md={'auto'}>
                            <ButtonSecondary onClick={cancelBtnHandler} title="Cancel" />
                        </Col>

                        <Col className="m-0 p-0" xs={12} md={'auto'}>
                            <ButtonPrimary 
                                onClick={ saveDocumentsHandler }
                                type="button"
                            >
                                <i className={`bi bi-arrow-down me-3`}></i>Save Document
                            </ButtonPrimary>

                        </Col>
                    </Row>
                </Col>

            ) : null }

            { isLoading && (
                <Col className="m-0 p-1 px-2 darkerGray skeleton rounded" style={{height: 154}}></Col>
            )}
            
            { (tenantData && tenantData.TenantDocuments && tenantData.TenantDocuments.length) ? tenantData.TenantDocuments.map( ({ Description, DocumentID, FileName }) => (
                    <DocumentRow 
                        key={DocumentID}

                        DocumentID={ DocumentID }
                        FileName={ FileName }
                        Description={ Description }
                        downloadFileFromDB={ downloadFileFromDB }
                        changeFileDescription={ changeFileDescriptionHandler }
                        onlyView={ onlyView }
                        deleteFileHandler={ deleteFileHandler }
                        resetForm={resetForm}

                        editedSection={editedSection}
                        currentlyEditedSectionHandler={currentlyEditedSectionHandler}
                    />
                )
            ) : ( !isLoading && (
                <Row className="row-cols-1 m-0 px-3 pt-3">
                    <Col className="m-0 p-0 text-center">
                        <h6 className="m-0 p-0 montserrat fw-bold">
                            { userType === "Tenant" ? "You " : "Tenant "} have not provided any documents
                        </h6>
                    </Col>
                </Row>)
            )
        }
        </Row>
    )
}
