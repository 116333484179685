import React, { createContext, useState, useEffect, useCallback } from "react";

export const CreatePortfolioContext = createContext();

const tenantDataInit = {
    Employments: [],
    FormerLandlords: [],
    PreviousEvictions: []
};

const formCountInit = {
    BasicInfo: 1,
    Employments: {},
    FormerLandlords: {},
    PreviousEvictions: {}
};

const CreatePortfolioContextProvider = (props) => {
    const [tenantData, setTenantData] = useState(tenantDataInit);

    const [saveSectionFlag, setSaveSectionFlag] = useState(false);

    const [formsCount, setFormsCount] = useState(formCountInit)

    const [portfolioErrors, setPortfolioErrors]  = useState({
        BasicInfoErr: [],
        EmploymentsErr: {},
        FormerLandlordsErr: {},
        PreviousEvictionsErr: {}
    });

    const [readyToSend, setReadyToSend] = useState(false);

    const [ image, setImage ] = useState(null);

    const resetCreatePortfolio = () => {
        setTenantData(tenantDataInit);
        setSaveSectionFlag(false);
        setFormsCount(formCountInit);
        setReadyToSend(false);
        setImage(null);
    }

    const setImageHandler = (imageData) => {
        setImage(imageData);
    }

    const decreaseCount = (of, id) => {
        if (of === 'BasicInfo') {
            setFormsCount( prevCount => {
                return {
                    ...prevCount,
                    [of]: 0
                }
            });
            return;
        }

       
        setFormsCount( prevCount => {
            const count = prevCount[of];
            if(count === 0) return prevCount;

            return {
                ...prevCount,
                [of]: {
                    ...prevCount[of],
                    [id]: 0
                } 
            }
        });
    } 

    const setBasicInfo = useCallback((data) => {
        setTenantData( (prevState) => ({
            ...prevState,
            ...data
        }))

        decreaseCount('BasicInfo', 0);
    }, []);

    const setEmployment = (data, id) => {
        setTenantData( (prevState) => ({
            ...prevState,
            Employments: (prevState.Employments ? [...prevState.Employments, data] : [data])
        }))

        decreaseCount('Employments', id);
    }

    const setFormerLandlord = (data, id, index) => {
        setTenantData( (prevState) => ({
                ...prevState,
                FormerLandlords: (prevState.FormerLandlords ? replaceOrPush(prevState.FormerLandlords, data, index) : [data])
        }))

        decreaseCount('FormerLandlords', id);
    }

    const setPreviousEviction = (data, id, index) => {
        setTenantData( (prevState) => ({
            ...prevState,
            PreviousEvictions: (prevState.PreviousEvictions ? replaceOrPush(prevState.PreviousEvictions, data, index) : [data])
        }))

        decreaseCount('PreviousEvictions', id);
    }

    function replaceOrPush (prevData, data, index) {
        const temp_prevData = [...prevData];

        if(temp_prevData[index]){
            temp_prevData.splice(index, 1, data);
        } else {
            temp_prevData.push(data);
        }
       
        return temp_prevData;
    }

    const increaseFormsCount = useCallback((of, id=1) => {
        if (of === 'BasicInfo') {
            setFormsCount( prevCount => {
                return {
                    ...prevCount,
                    [of]: 1
                }
            });
            return;
        }

        setFormsCount( prevCount => ({
            ...prevCount,
            [of]: {
                ...prevCount[of],
                [id]: 1
            }   
        }))
    }, []);

    const decreaseFormsCount = (of, id) => {
        decreaseCount(of, id);
    }

    const checkIfFormsOfType = (type) => {
        const isReady = Object.entries(formsCount[type]).every( ([key, value]) => {
            return value === 0
        })

        return isReady;
    }

    const checkIfBasicInfoReady = () => {
        return !formsCount.BasicInfo;
    }

    const checkIfAllFormsReady = () => {
        return (
            !formsCount.BasicInfo &&
            checkIfFormsOfType('Employments') &&
            checkIfFormsOfType('FormerLandlords') &&
            checkIfFormsOfType('PreviousEvictions')
        );
    }

    const initializeState = (tenantData, searchedDataName) => {
        if (!tenantData) return [];
        const searchedData = tenantData[searchedDataName];

        const count = searchedData.length;
        if (count > 0) {
            // Generate array with given length
            const prepareArray=[];
            for (let i = 1; i <= count; i++){
                prepareArray.push(i);
            }
            return prepareArray;
        }
        return [];
    }
    useEffect(() => {
        if(checkIfAllFormsReady()) {
    
            setReadyToSend(true);
        }
    }, [formsCount]);

    const setBasicInfoErrors = useCallback((errors) => {
        if(!errors) return;
        setPortfolioErrors( prevState => ({
            ...prevState,
            BasicInfoErr: getErrorsMessage(errors)
        }))
    }, []);

    const setEmploymentErrors = useCallback((errors, id) => {
        if(!errors || !id) return;


        setPortfolioErrors( prevState => ({
            ...prevState,
            EmploymentsErr: {
                ...prevState.EmploymentsErr,
                [id]: getErrorsMessage(errors)
            }
        }))
    }, []);

    const setFormerLandlordsErrors = useCallback((errors, id) => {
        if(!errors || !id) return;

        setPortfolioErrors( prevState => ({
            ...prevState,
            FormerLandlordsErr: {
                ...prevState.FormerLandlordsErr,
                [id]: getErrorsMessage(errors)
            }
        }))
    }, []);

    const setPreviousEvictionsErrors = useCallback((errors, id) => {
        if(!errors || !id) return;

        setPortfolioErrors( prevState => ({
            ...prevState,
            PreviousEvictionsErr: {
                ...prevState.PreviousEvictionsErr,
                [id]: getErrorsMessage(errors)
            }
        }))
    }, []);

    const getErrorsMessage = (errorObject) => {
        const errors = Object.entries(errorObject).map(([_, value]) => {
            return value.message;
        })
        return errors;
    }

    return (
        <CreatePortfolioContext.Provider
            value={{
                tenantData,
                setTenantData,
                setBasicInfo,
                setEmployment,
                setFormerLandlord,
                setPreviousEviction,
                resetCreatePortfolio,
                increaseFormsCount,
                decreaseFormsCount,
                readyToSend,
                // restoreformsCount,
                setFormsCount,
                checkIfAllFormsReady,
                checkIfBasicInfoReady,
                checkIfFormsOfType,
                saveSectionFlag,
                setSaveSectionFlag,

                setImageHandler,
                // setImageInTenantData,
                image,

                initializeState,
                portfolioErrors, 
                setPortfolioErrors,
                setBasicInfoErrors,
                setEmploymentErrors,
                setFormerLandlordsErrors,
                setPreviousEvictionsErrors,
            }}
        >
            {props.children}
        </CreatePortfolioContext.Provider>
    );
};

export default CreatePortfolioContextProvider;