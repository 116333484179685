import React from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "react-bootstrap";

import UnitImageMobileLoader from './UnitImageMobileLoader';
import UnitFlatLoader from '../UnitFlatLoader';

export default function PropertiesUnit() {
    return (
      <Container fluid className="m-0 p-3 px-0 d-flex justify-content-center" style={{ minHeight: 300 }}  >
        <Card className="p-0 m-0 border-0  w-100" style={{ height: "100%" }}>
          <Card.Body className="m-0 p-0 lightGray rounded overflow-hidden">
            <UnitImageMobileLoader />
            <Row className="m-0 p-0">
              <Col
                xs="auto"
                className="d-none d-lg-flex justify-content-center align-items-center darkerGray rounded skeleton"
                style={{
                  width: 300,
                  height: 300,
                  backgroundSize: "cover",
                  backgroundPosition: "50%,50%",
                }}
              >
              </Col>
              <Col className="ps-5 pe-5 d-flex flex-column justify-content-start">
                <Row className="p-0 m-0 mt-4 mb-2 darkerGray skeleton rounded w-50 " style={{height: '1.75rem'}}></Row>
                <Row className="p-0 m-0 mb-3 darkerGray skeleton rounded w-75 " style={{height: '1.125rem'}}></Row>
                <UnitFlatLoader />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
