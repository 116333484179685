import React, { useState } from 'react';
import {
    Row,
    Col,
    FloatingLabel,
    Form
} from 'react-bootstrap';

import BluredSection from '../Shared/BluredSection';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';

const charactersLimit = 150;


export default function DocumentRow ({
    DocumentID,
    FileName,
    Description,
    downloadFileFromDB,
    changeFileDescription,
    onlyView,
    deleteFileHandler,
    resetForm,
    editedSection,
    currentlyEditedSectionHandler
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [fileDescription, setFileDecription] = useState(Description || '');    

    const descripitonChangeHandler = (e) => {
        const text = e.target.value;
        if(text.length > charactersLimit) return;
        setFileDecription(text);
    }

    const onDeleteHandler = async (DocumentID) => {
        const result = await deleteFileHandler(DocumentID);
        if(result) currentlyEditedSectionHandler(null);
    }

    const onEditHandler = (fileDescription, DocumentID) => {
        if( !isEditing ) {
            setIsEditing(true);
            currentlyEditedSectionHandler(`Documents ${DocumentID}`)
            return;
        }
        
        setIsEditing(false);

        if( fileDescription.trim() === Description.trim()) return;
        currentlyEditedSectionHandler(null)
        changeFileDescription(fileDescription, DocumentID);
    }

    const cancelBtnHandler = () => {
        resetForm();
        setIsEditing(false);

        currentlyEditedSectionHandler(null);
    }

    return (
        <Col className="m-0 p-1 px-2 position-relative">

            <BluredSection show={editedSection !== null && editedSection !== `Documents ${DocumentID}`}/>

            <Row className="m-0 my-2 p-2 pb-3 darkerGray rounded">
                <Col className="d-flex align-items-center">
                    <p className='m-0 fw-bold'>
                        {FileName}
                    </p>
                </Col>

                {   !onlyView && (
                        <Col xs={"auto"}>
                            { !isEditing && (
                                    <ButtonPrimary onClick={() => onEditHandler(fileDescription, DocumentID)}>
                                        <i className="bi bi-pencil-fill me-sm-2"></i><span className="d-none d-sm-inline">Edit Document</span>
                                    </ButtonPrimary>
                                )
                            }
                           
                            { isEditing && (
                                <ButtonPrimary 
                                    onClick={() => onDeleteHandler(DocumentID)}
                                    additionalClassName="bg-danger border-danger"
                                    type="button"
                                >
                                    <i className="bi bi-x-lg"></i>
                                </ButtonPrimary>
                            )}

                        </Col>
                    )
                }

                { isEditing && !onlyView ? (
                        <Col xs={12} className="mt-2">
                            <FloatingLabel 
                                controlId="floatingTextarea2" 
                                label="Document description (optional)"
                                className="py-1"
                            >
                                <Form.Control
                                    className="border-0 rounded pt-5 pt-sm-4"
                                    as="textarea"
                                    placeholder="0"
                                    style={{ height: '100px' }}

                                    value={fileDescription}
                                    onChange={descripitonChangeHandler}
                                />
                            </FloatingLabel>
                            <p className="p-0 m-0 text-end fs-6">
                                { (fileDescription && fileDescription.length) || '0' } / { charactersLimit }
                            </p>
                        </Col>
                    ) : (
                        <Col xs={12} className="mt-2">
                            <h6 className="m-0 p-2 fw-normal bg-light rounded">{Description || "This document has no description..."}</h6>
                        </Col>
                    )
                }

                { !isEditing && (
                        <Col xs={12} className="m-0 mt-2 p-0">
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <ButtonPrimary onClick={() => downloadFileFromDB(DocumentID, FileName)}>
                                        <i className="bi bi-file-earmark-arrow-down me-2"></i>  Download
                                    </ButtonPrimary>
                                </Col>
                            </Row>
                        </Col>
                    )
                }

                {   (!onlyView && isEditing) && (
                        <Row className="m-0 p-0 justify-content-center align-items-center">
                            <Col className="m-0 p-0 mb-2 mb-sm-0 me-sm-3" xs={12} sm={'auto'}>
                                <ButtonSecondary 
                                    onClick={cancelBtnHandler}
                                    title="Cancel"
                                    type="button"
                                />
                            </Col>

                            <Col className="m-0 p-0" xs={12} sm={'auto'}>
                                <ButtonPrimary onClick={() => onEditHandler(fileDescription, DocumentID)} type='button'>
                                    <i className="bi bi-arrow-up me-2"></i>Save Document
                                </ButtonPrimary>
                            </Col>
                        </Row>
                    )
                }

            </Row>
        </Col>
    )
}
