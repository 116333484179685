import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({children}) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // window.scrollTo({
    //           top: 0,
    //           behavior: "smooth"
    //       })
  }, [location]);

  return <>{children}</>
};

export default ScrollToTop;