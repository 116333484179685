import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import FormerLandlordCard from './FormerLandlordCard';
import PreviousEviction from './PreviousEviction';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';
import FormerLandlordCardLoader from './FormerLandlordCardLoader';
import PreviousEvictionLoader from './PreviousEvictionLoader';


export default function ResidentialHistoryForm ({
    forceEditMode=true, 
    canEdit, 
    tenantData, 
    formerLandlordsFields,
    addFormerLandlord,
    deleteFormerLandlordHandler,
    previousEvictionsFields,
    addEviction,
    deleteEvictionHandler,
    onSubmitResidentialHistory,
    register,
    errors,
    watch,
    setValue,
    onlyView,
    resetForm,
    currentlyEditedSectionHandler,
    isLoading
}) {
    const [editMode, setEditMode] = useState(forceEditMode);
    
    const clickHandler = async (mode) => {
        if(mode === 'save') {
            const result = await onSubmitResidentialHistory();
            setEditMode( result );
            currentlyEditedSectionHandler(null);
        } else {
            setEditMode( true );
            currentlyEditedSectionHandler("ResidentialHistory");
        }
    }

    const cancelBtnHandler = () => {
        resetForm();
        setEditMode(false);
        currentlyEditedSectionHandler(null);
    }
 
    useEffect(() => {
        setEditMode(forceEditMode);
    }, [forceEditMode])

    return (
        <Container fluid className={`w-100 p-0 m-0 montserrat`}>
            {/* <Row className="m-0 p-0 p-2 darkerGray text-center position-relative rounded overflow-hidden">
                <h5 className="fw-bold m-0 p-0">RESIDENTIAL HISTORY</h5>
                { (canEdit && !editMode) && (
                   
                    <Col className="d-flex position-absolute top-0 end-0 p-0 m-0 h-100" xs={'auto'}>
                        <ButtonPrimary onClick={() => clickHandler('edit')}>
                            <i className={`bi bi-pencil-fill me-sm-2`}></i><span className="d-none d-sm-inline">Edit Section</span>
                        </ButtonPrimary>
                    </Col>
                   
                    )
                }
            </Row> */}

            <Row className="row-cols-1 m-0 p-0">
                <Col className="m-0 p-3">
                    <h4 className="fw-bold m-0 p-0">
                        Former Landlords
                    </h4>

                    { isLoading && (<FormerLandlordCardLoader />)}

                    { !isLoading && !onlyView ? ((formerLandlordsFields && formerLandlordsFields.length) ? formerLandlordsFields.map( ( _, i) => (
                        <FormerLandlordCard 
                            key={i}
                            index={i}
                            id={i}
                        
                            data={tenantData && tenantData.FormerLandlords && tenantData.FormerLandlords[i]}

                            editMode={editMode}
                            canEdit={canEdit}
                            onDelete={() => deleteFormerLandlordHandler(i)}

                            prefix={`FormerLandlords.${i}.`}
                            register={register}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                        />
                    )) : ( !isLoading && (
                        <Row className="row-cols-1 m-0 px-3 py-3">
                            <Col className="m-0 p-0 text-center">
                                <h6 className="m-0 p-0 fw-bold">You have not provided any former landlord history</h6>
                            </Col>
                        </Row>)
                    )) : (
                        ( !isLoading && tenantData && tenantData.FormerLandlords && tenantData.FormerLandlords.length) ? tenantData.FormerLandlords.map( ( _, i) => (
                            <FormerLandlordCard 
                                key={i}
                                index={i}
                                id={i}
                            
                                data={tenantData && tenantData.FormerLandlords && tenantData.FormerLandlords[i]}
    
                                editMode={editMode}
                                canEdit={canEdit}
                                onDelete={() => deleteFormerLandlordHandler(i)}
    
                                prefix={`FormerLandlords.${i}.`}
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                            />
                        )) : ( !isLoading && (
                            <Row className="row-cols-1 m-0 px-3 pt-3">
                                <Col className="m-0 p-0 text-center">
                                    <h6 className="m-0 p-0 fw-bold">Tenant have not provided any former landlord history</h6>
                                </Col>
                            </Row>)
                        )
                    )
                    }
                    
                    { editMode && 
                        <Col className="m-0 p-0">
                            <Col className="m-0 p-0" xs={12}>
                                <ButtonPrimary onClick={ addFormerLandlord }>
                                    <i className="bi bi-plus-lg me-2"></i> Add Landlord
                                </ButtonPrimary>
                            </Col> 
                        </Col> 
                    }
                </Col>

                <Col className="m-0 p-3">
                    <h4 className="fw-bold m-0 p-0">
                        Previous Evictions
                    </h4>

                    { isLoading && (<PreviousEvictionLoader />)}

                    {
                        !isLoading && !onlyView ? ((previousEvictionsFields && previousEvictionsFields.length) ? previousEvictionsFields.map( (_, i) => (
                            <PreviousEviction 
                                editMode={editMode}
                                canEdit={canEdit}
                                
                                key={i}
                                id={i}
                                index={i}
                                data={tenantData && tenantData?.PreviousEvictions?.[i]} 
                                onDelete={() => deleteEvictionHandler(i)}

                                prefix={`PreviousEvictions.${i}.`}
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                            /> 
                        )) : ( !isLoading && (
                            <Row className="row-cols-1 m-0 px-3 py-3">
                                <Col className="m-0 p-0 text-center">
                                    <h6 className="m-0 p-0 fw-bold">You have not provided any previous eviction history</h6>
                                </Col>
                            </Row>)
                        )) : (
                            (!isLoading && tenantData && tenantData.PreviousEvictions && tenantData.PreviousEvictions.length) ? tenantData.PreviousEvictions.map( (_, i) => (
                                <PreviousEviction 
                                    editMode={editMode}
                                    canEdit={canEdit}
                                    
                                    key={i}
                                    id={i}
                                    index={i}
                                    data={tenantData && tenantData?.PreviousEvictions?.[i]} 
                                    onDelete={() => deleteEvictionHandler(i)}
    
                                    prefix={`PreviousEvictions.${i}.`}
                                    register={register}
                                    errors={errors}
                                    watch={watch}
                                    setValue={setValue}
                                /> 
                            )) : ( !isLoading && (
                                <Row className="row-cols-1 m-0 px-3 pt-3">
                                    <Col className="m-0 p-0 text-center">
                                        <h6 className="m-0 p-0 fw-bold">Tenant have not provided any previous eviction history</h6>
                                    </Col>
                                </Row>)
                            )
                        )
                    }
                    
                    { editMode && (
                        <Col className="m-0 p-0" xs={12}>
                            <ButtonPrimary onClick={ addEviction }>
                                <i className="bi bi-plus-lg me-2"></i> Add Eviction
                            </ButtonPrimary>
                        </Col> 
                    )}
                </Col>
            </Row>

            { (canEdit && !editMode) && (
                   <Row className="m-0 p-0 mt-3 justify-content-center">
                    <Col className="d-flex p-0 m-0" xs={'auto'}>
                        <ButtonPrimary onClick={() => clickHandler('edit')}>
                            <i className={`bi bi-pencil-fill me-sm-2`}></i><span className="d-none d-sm-inline">Edit Section</span>
                        </ButtonPrimary>
                    </Col>
                   </Row>
                   )
               }

            { (canEdit && editMode) && (
                    <Row className="m-0 mt-3 p-0 px-3 justify-content-center align-items-center">
                        <Col className="m-0 pe-2 p-0 text-center" xs={'auto'}>
                            <ButtonSecondary onClick={cancelBtnHandler} title="Cancel" />
                        </Col>

                        <Col className="m-0 p-0 text-center" xs={'auto'}>
                            <ButtonPrimary onClick={() => clickHandler('save')}>
                                <i className={`bi bi-arrow-up me-2`}></i>Save Section
                            </ButtonPrimary>
                        </Col>
                    </Row>
                )
            }
            
        </Container>
    )
}
