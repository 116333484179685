import React from 'react';
import {
    Row,
    Col,
    Form
} from 'react-bootstrap';


export default function FeaturesFilter ({
    accessibilitiesOptions,
    amenitiesOptions,
    onChangeAccessibilities,
    onChangeAmenities,
    selectedAccessibilities,
    selectedAmenities
}) {
    return (
        <Row>
            <Col>
                <p className="m-0 p-0 fs-6">
                    <b>Accessibilities:</b>
                </p>
                {accessibilitiesOptions?.map((option) => {
                    const [ s_id, name ] = Object.entries(option)[0];
                    const id = parseInt(s_id);
                    return (
                        <Form.Check
                            key={`accessiblity ${id}`}
                            id={`accessiblity ${id}`}
                            value={id}
                            type="checkbox"
                            label={name}
                            htmlFor={`accessiblity ${id}`}
                            onChange={() => onChangeAccessibilities(id)}
                            checked={selectedAccessibilities.includes(id)}
                        />
                    );
                })}
            </Col>
            <Col>
                <p className="m-0 p-0 fs-6">
                    <b>Amenities:</b>
                </p>
                {amenitiesOptions?.map((option) => {
                    const [ s_id, name ] = Object.entries(option)[0];
                    const id = parseInt(s_id);
                    return (
                        <Form.Check
                            key={`amenity ${id}`}
                            id={`amenity ${id}`}
                            value={id}
                            type="checkbox"
                            label={name}
                            htmlFor={`amenity ${id}`}
                            onChange={() => onChangeAmenities(id)}
                            checked={selectedAmenities.includes(id)}
                        />
                    );
                })}
            </Col>
        </Row> 
    )
} 