import React, { useEffect, useRef } from 'react'
import InputMask from 'inputmask';
import InputFloating from './InputFloating';

// Phone mask: {mask: "(999) 999-9999"}
// Price mask: {alias:'numeric', digits:'2', digitsOptional: false, placeholder:'0'}

// inputData is object with and in eg. should look like below:
//  {
//      id: "Phone",
//      label: "Phone number",
//      type: "text",
//      placeholder: "phone",
//  }
//

// classes: 
//  {
//      floatingLabel: '',
//      formControl: ''
//  }
//
export default function InputWithMask({ 
    label,
    register, 
    maskOptions, 
    classes, 
    className,
    showError,
    errorMessage,
    controlId='floatingInput'
 }) {
    const inputRef = useRef(null);
    const registerInput = () => {
        const { ref, ...rest } = register;

        return {
            ...rest,
            ref: (e) => {
                ref(e);
                inputRef.current = e;
            }
        }
    }

    useEffect(()=> {
        const inputElement = inputRef.current;
    
        InputMask(maskOptions).mask(inputElement);
    }, [maskOptions]);

    return (
        // <FloatingLabel
        //     controlId={inputData.id}
        //     label={inputData.label}
        //     className={((classes && classes.floatingLabel) ? classes.floatingLabel : '') + className}
        // >
        //     <Form.Control 
        //         type={inputData.type}
        //         placeholder={inputData.placeholder} 
        //         className={(classes && classes.formControl ) ? classes.formControl : 'rounded border-0 shadow-sm'}
        //         {...(registerInput())}
        //     />
        // </FloatingLabel>

        <InputFloating
            label={label}
            register={{...(registerInput())}}
            showError={showError}
            errorMessage={errorMessage}
            contorlId={controlId}
        />
    )
}
