import React, {useEffect} from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import NotesFields from '../BuildingFormElements/NotesFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import {
    MISC_NOTES
} from '../../constants/buildingFormConstants';

const schema = YupObject().shape({
    [MISC_NOTES]: YupString()
        .notRequired()
});


export default function NotesForm({
    sectionFocus,
    setSectionFocus,
    onSubmitNotes,
    building
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, reset, handleSubmit, formState: { isDirty } } = useForm(formOptions);

    useEffect(() => {
        if (building) {
            reset({
                [MISC_NOTES]: building[MISC_NOTES]
            })
        }

    }, [building, reset]);

    return (
        <Form
            onSubmit={handleSubmit(onSubmitNotes)}
            className={sectionFocus && sectionFocus !== "Notes" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus("Notes") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >   
            <NotesFields 
                register={register}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        disabled={ !isDirty }
                        title="Update Additional Notes"
                    />
                </Col>
            </Row>
        </Form>
    )
}
