import { 
    object as YupObject, 
    string as YupString, 
    boolean as YupBoolean,
    array as YupArray 
} from "yup";

import {
    GOOGLE_AUTOCOMPLETE,
    NAME,
    ADDRESS_LINE_1,
    ADDRESS_LINE_2,
    CITY,
    STATE,
    ZIP,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    LANDLORD_CONTACT_FULLNAME,
    LANDLORD_CONTACT_EMAIL,
    LANDLORD_CONTACT_PHONE,
    MANAGERS,
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME,
    MANAGER_EMAIL,
    MANAGER_PHONE,
    MISC_NOTES
} from '../constants/buildingFormConstants';

const schema = YupObject().shape({
    [GOOGLE_AUTOCOMPLETE]: YupBoolean()
        .notRequired(),
    [NAME]: YupString()
        .required("Required")
        .transform(value => value.trim()),
    [ADDRESS_LINE_1]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required")
                .transform(value => value.trim()),
    }),
    [ADDRESS_LINE_2]: YupString()
        .notRequired()
        .transform(value => value.trim()),
    [CITY]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required")
                .transform(value => value.trim()),
    }),
    [STATE]: YupString()
        .when(GOOGLE_AUTOCOMPLETE, {
            is: false,
            then: YupString()
                .required("Required"),
    }),
    [ZIP]: YupString()
    .when(GOOGLE_AUTOCOMPLETE, {
        is: false,
        then: YupString()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits"),
    }),
    [MISC_AMENITIES]: YupString()
        .notRequired(),
    [MISC_ACCESSIBILITIES]: YupString()
        .notRequired(),

    [LANDLORD_CONTACT_FULLNAME]: YupString()
        .notRequired()
        .transform(value => value.trim()),
    [LANDLORD_CONTACT_EMAIL]: YupString()
        .email("Email must be a valid email")
        .transform(value => value.trim())
        .notRequired(),
    [LANDLORD_CONTACT_PHONE]: YupString()
        .transform(value => value.trim())
        .notRequired(),

    [MANAGERS]: YupArray().of(
        YupObject().shape({
            [MANAGER_FIRST_NAME]: YupString()
                .required('Required')
                .transform(value => value.trim()),
            [MANAGER_LAST_NAME]: YupString()
                .required('Required')
                .transform(value => value.trim()),
            [MANAGER_EMAIL]: YupString()
                .required('Required')
                .email("Provide a valid email")
                .transform(value => value.trim()),
            [MANAGER_PHONE]: YupString()
                .required('Required')
                .transform(value => value.trim())
                .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
                    "Provide a valid phone number"),
        })
    ),
    [MISC_NOTES]: YupString()
        .notRequired()
});

export default schema;
