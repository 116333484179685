import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';


export default function InputFloating({
    label,
    errorClasses="custom_error pt-1",
    formClasses="rounded border shadow-sm",
    additionalClasses,
    labelClasses="",
    controlId="floatingInput",
    type="text",
    errorMessage,
    showError=false,
    register={},
    min,
    max,
    ...rest
}) {
    return (
        <FloatingLabel
            controlId={controlId}
            label={label}
            className={labelClasses}
        >
            <Form.Control type={type} min={min} max={max} placeholder="0" className={`${formClasses} ${additionalClasses}`} {...register} {...rest}/>

            { showError && (
                    <p className={errorClasses}>
                        { errorMessage }
                    </p>
                )
            }

        </FloatingLabel>
    )
}