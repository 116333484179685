import React from 'react';

export default function DropdownMenu({
    open,
    smallNav,
    children,
    onHide
}) {
    return( 
        <>
            { open && (
                <div 
                    className={`m-0 p-0 d-none d-xl-block dropdown bg-light rounded shadow ${ smallNav ? 'shrinked' : '' }`}
                    onMouseLeave={onHide}
                >
                    { children }
                </div>
            )}
        </>
    )
}
