import React from "react";
import {
    Row,
    Col,
    Form,
} from "react-bootstrap";

import ButtonWithLeftBorder from "../Shared/Buttons/ButtonWithLeftBorder";


export default function FormSection1Loader ({editBuildingLoader=false}) {
    return (
        <Row className="m-0 p-0 position-relative">
            <Col className="m-0 p-0 p-2 p-md-5 lightGray" style={{minHeight: '100vh'}}>

                <Row className="m-0 p-0 justify-content-sm-between">
                    <Col className="m-0 p-0 flex-grow-1 order-1 " xs="12" md="auto">
                        <h2 className="m-0 p-0 mb-4 darkerGray skeleton rounded" style={{width: 275}}>&nbsp;</h2>
                    </Col>

                    { !editBuildingLoader && (
                        <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2" xs={12} md={3} xl={2}>
                            <Row className="m-0 p-0 justify-content-end">
                                <Col className="m-0 p-0" xs="6" md={12}>
                                    <ButtonWithLeftBorder 
                                        showIcon={true}
                                        title=""
                                        iconClass=""
                                        additionalButtonClass="ps-3 darkerGray skeleton"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}


                    { editBuildingLoader && (
                        <Col className="m-0 mb-3 p-0 pe-1 order-0 order-md-2 flex-grow" xs={12} md={6} lg={5}>
                            <Row className="m-0 p-0 mb-3 mb-sm-0 justify-content-end">
                                <Col className="m-0 p-0 pe-1" xs={6}>
                                    <ButtonWithLeftBorder 
                                        showIcon={true}
                                        title=""
                                        iconClass=""
                                        additionalButtonClass="ps-3 darkerGray skeleton"
                                    />
                                </Col>

                                <Col className="m-0 p-0 ps-1" xs={6}>
                                <ButtonWithLeftBorder 
                                        showIcon={true}
                                        title=""
                                        iconClass=""
                                        additionalButtonClass="ps-3 darkerGray skeleton"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>

                <Form.Group className="mb-3">
                    <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                </Form.Group>

                <h3 className="mb-3 fw-bolder skeleton darkerGray rounded" style={{width: 200}}>&nbsp;</h3>

                <h6 className="m-0 p-0 mb-1 fw-bolder skeleton darkerGray rounded" style={{width: 250}}>&nbsp;</h6>

                <Row className="m-0 p-0 mb-3">
                    <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                </Row>
                
                <Row className="m-0 p-0 mb-3">
                    <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                </Row>

                <Row className="m-0 p-0 mb-3">
                    <Col xs={12} md={4} className="m-0 px-0">
                        <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                    </Col>
                    <Col xs={12} md={4} className="m-0 px-0 px-md-3">
                        <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                    </Col>
                    <Col xs={12} md={4} className="m-0 px-0">
                        <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                    </Col>
                </Row>
               
               {/* Managers loader */}
                <h3 className="mb-3 fw-bolder skeleton darkerGray rounded" style={{width: 250}}>&nbsp;</h3>
         
                <Row className="m-0 p-0 justify-content-center flex-column flex-md-row flex-lg-row">
                    <Col className="m-0 p-0 mb-2 me-md-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                    <Col className="m-0 p-0 mb-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                </Row>
                <Row className="m-0 p-0 mb-3">
                    <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                </Row>

                {/* Images loader */}
                <h3 className="mb-3 fw-bolder skeleton darkerGray rounded" style={{width: 250}}>&nbsp;</h3>
                <h6 className="m-0 p-0 mb-2 fw-bolder skeleton darkerGray rounded">&nbsp;</h6>
                <Row className="m-0 p-0 justify-content-center flex-column flex-md-row flex-lg-row">
                    <Col className="m-0 p-0 mb-2 me-md-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                    <Col className="m-0 p-0 mb-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                </Row>
                <Row className="m-0 p-0 mb-3">
                    <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 250}}></div>
                </Row>

                {/* Buttons loader */}
                <Row className="m-0 p-0 justify-content-center flex-column flex-md-row flex-lg-row">
                    <Col className="m-0 p-0 mb-2 me-md-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                    <Col className="m-0 p-0 mb-2 me-md-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                    <Col className="m-0 p-0 mb-2 text-center skeleton darkerGray rounded" style={{height: 38}}></Col>
                </Row>
            </Col>
        </Row>
    )
}
