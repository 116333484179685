import { postPutRequest, signInPostRequest, getRequest } from './http-common';

const signupRequest = async (data) => {
    const URL = `account/create`;

    return await postPutRequest( URL, data, '', 'post');
}

const signinRequest = async (attributes) => {
    const URL = `token`;

    return await signInPostRequest(URL, attributes);
}

const initializeResetRequest = async (data) => {
    const URL = `account/reset/request`;

    return await postPutRequest( URL, data, null, 'post');
}

const changePassword = async (data, token) => {
    const URL = `account/reset/update`;

    return await postPutRequest( URL, data, token, 'post');
}

const resetPassword = async (data) => {
    const URL = `account/reset/code`;

    return await postPutRequest( URL, data, null, 'post');
}

const getStats = async (token) => {
    const URL = 'account/stats';

    return await getRequest(URL, token);
}

const getUnregisteredAccountData = async (accountStatusKey) => {
    const URL = `account/invite/${accountStatusKey}`;

    return await getRequest(URL);
}

export { 
    signupRequest,
    signinRequest,
    initializeResetRequest,
    changePassword,
    resetPassword,
    getStats,
    getUnregisteredAccountData
};
