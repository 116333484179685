import {useEffect, useState} from 'react'

export default function useScrolled() {
    const [scrolledVal, setScrolledVal] = useState(null);
    const getScrollVal = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        
        if(height < 100) {
            setScrolledVal(0);
            return;
        }
        
        setScrolledVal(winScroll / height);
    }

    useEffect(() => {
        window.addEventListener('scroll', getScrollVal);
        return () => {
            window.removeEventListener('scroll', getScrollVal)
        }
    }, [])
    return scrolledVal;
}
