import React from "react";

import UploadFile from "../Shared/UploadFile";


export default function DocumentsFields({
    files,
    setFiles,
    filesToDelete,
    setFilesToDelete,
    downloadedFiles,
    isUpdate = false,
    saveDescriptionHandler,
    isForm = false
}) {
    const setUploadFileAttributes = () => {
        const createBuildingAttributes = {
            files,
            setFiles
        }

        const updateBuildingAttributes = {
            ...createBuildingAttributes,
            downloadedFiles: downloadedFiles,
            filesToBeDeleted: filesToDelete,
            setFilesToBeDeleted: setFilesToDelete
        }

        return isUpdate ? updateBuildingAttributes : createBuildingAttributes;
    }

    return (
        <>   
            <h3 className="fw-bolder">
                Property Documents
            </h3>
            <p className="m-0 p-0">
                Please upload all relevant documents related to this building or property. These documents can include:
            </p>
            <ul >
                <li><p className="m-0 p-0">Building Policies</p></li>
                <li><p className="m-0 p-0">Leasing Documents</p></li>
                <li><p className="m-0 p-0">Building Contact List</p></li>
                <li><p className="m-0 p-0">Inspection History &#40;e.g., HQS&#41;</p></li>
            </ul>

            <UploadFile
                {...(setUploadFileAttributes())}
                saveDescriptionHandler={saveDescriptionHandler}
                isProperty={true}
                isForm={isForm}
            />
        </>
    )
}
