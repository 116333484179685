import React from 'react';

import LazyImage from './LazyImage';

export default function ImageSquare({imageSrc, imgPlaceholder, imageAltText, canEdit, deletePicture=()=>{}, loaderMode = false, showDeleteBtn=true}) {
    return (
        <div style={{ height: "0", paddingBottom: "100%", width: "100%"}} className="position-relative">
           
        { (canEdit && !loaderMode && showDeleteBtn) &&
            <div className="position-absolute top-0 left-0 w-100 m-0 d-flex justify-content-between align-items-center p-1 row-cols-auto"
                style={{zIndex: 1}}>
                <div className="d-flex justify-content-center align-items-center p-1 m-0 bg-light rounded cursor-pointer" onClick={deletePicture}>
                    <i className="bi bi-x-lg fs-4"></i>
                </div>
            </div>
        }

            <div className="position-absolute top-0 start-0 end-0 bottom-0">
                { loaderMode ? (
                    <div style={{ objectFit: "cover", height: "100%", width: "100%" }} className="darkerGray skeleton"></div>
                ) : (
                    <LazyImage src={imageSrc || imgPlaceholder} alt={imageAltText} style={{ objectFit: "cover", height: "100%", width: "100%" }} className="d-block" />
                )
                }
            </div>
        </div>
    )
}
