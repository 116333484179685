import React from 'react';
import {
    Row,
} from 'react-bootstrap';

import HeaderLoader from './HeaderLoader';
import ContentLoader from './ContentLoader';


export default function BuildingInfoLoader() {
    return (
        <Row className="m-0 p-0 mb-5 p-2 p-lg-5 lightGray rounded d-flex justify-content-center">

            <HeaderLoader />
            <ContentLoader />

        </Row>
    )
}
