import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFirstDownload: true,
    Buildings: [],
    Queries: [],
    Units: []
}

export const favoriteSlice = createSlice({
    name: 'favoriteDataSlice',
    initialState,
    reducers: {
        setFavorite(state, action) {
            state.isFirstDownload = false;
            state.Buildings = action.payload.Buildings || [];
            state.Queries = action.payload.Queries || [];
            state.Units = action.payload.Units || [];
        },
        clearFavorite(state) {
            state.isFirstDownload = true;
            state.Buildings = [];
            state.Queries = [];
            state.Units = [];
        },
        addFavoriteBuilding(state, action){
            state.Buildings = [...state.Buildings, action.payload];
        },
        deleteFavoriteBuilding(state, action){
            state.Buildings = state.Buildings.filter( building => building.BuildingID !== action.payload )
        },
        addFavoriteUnit(state, action){
            state.Units = [...state.Units, action.payload];
        },
        deleteFavoriteUnit(state, action){
            state.Units = state.Units.filter( unit => unit.UnitID !== action.payload )
        },
        addFavoriteQuery(state, action){
            state.Queries = [...state.Queries, action.payload];
        },
        deleteFavoriteQuery(state, action){
            state.Queries = state.Queries.filter( query => query.QueryID.trim() !== action.payload.trim())
        }
    }
});

export const favoriteActions = favoriteSlice.actions;
export default favoriteSlice.reducer;
