import React, { useState, useEffect } from "react";
import { Row, Col, Button, Carousel, Accordion } from "react-bootstrap";
import { saveAs } from "file-saver";

import { useSelector } from "react-redux";

import ModalCarousel from "../ModalCarousel";
import BuildingInfoLoader from "./BuildingInfoLoader";
import LazyImage from "../LazyImage";
import FeaturesChecklist from "../FeaturesChecklist";
import preparePhotos from "../../../utils/preparePhotos";
import FavoriteButton from "../Buttons/FavoriteButton";

export default function BuildingInfo({
  selectedBuildingData,
  streetViewAvailable,
  showStreetView,
  setShowStreetView,
  setStreetViewClicked,
  isUnitSelected,
  showSelectedUnit,
  showOnlyUnitWithID,
  isLoading,
  alreadyRequestesUnitIDs,
  favoriteHandler,
  features,
  hideStreetMap=false,
  showAllUnits=false
}) {
  const [carouselModalIndex, setCarouselModalIndex] = useState(0);
  const [carouselBuildingIndex, setCarouselBuildingIndex] = useState(0);
  const [showBuildingCarouselModal, setShowBuildingCarouselModal] =
    useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const favorites = useSelector((store) => store.favoriteStore);

  const downloadFileFromDB = (fileSrc, fileName) => {
    saveAs(fileSrc, fileName);
  };

  const handleCloseBuildingCarouselModal = () => {
    setShowBuildingCarouselModal(false);
    setCarouselModalIndex(0);
    setCarouselBuildingIndex(0);
  };

  const carouselHandleSelect = (selectedIndex, e) => {
    setCarouselModalIndex(selectedIndex);
  };

  const buildingCarouselHandleSelect = (selectedIndex, e) => {
    setCarouselBuildingIndex(selectedIndex);
  };

  const handleOpenBuildingCarouselModal = (index) => {
    setShowBuildingCarouselModal(true);
    setCarouselModalIndex(index);
  };

  useEffect(() => {
    if (
      favorites &&
      favorites.Buildings &&
      selectedBuildingData &&
      selectedBuildingData.BuildingID
    ) {
      if (
        favorites.Buildings.find(
          (building) => building.BuildingID === selectedBuildingData.BuildingID
        )
      )
        setIsFavorite(true);
      else {
        setIsFavorite(false);
      }
    }
  }, [favorites, selectedBuildingData]);

  const preparedPhotos = preparePhotos(selectedBuildingData);

  return (
    <>
      <ModalCarousel
        show={showBuildingCarouselModal}
        closeHandler={handleCloseBuildingCarouselModal}
        photoIndex={carouselModalIndex}
        onSelect={carouselHandleSelect}
        photos={preparedPhotos}
        unit={selectedBuildingData}
        building={true}
      />

      {isLoading && <BuildingInfoLoader />}

      {!isLoading && (
        <Col className="m-0 p-2 overflow-hidden" xs={12} md={4}>
          {/* <div className="d-flex mb-2">
            <div className="ms-auto">
              <FavoriteButton
                id={selectedBuildingData.BuildingID}
                type="building"
                isFavorite={isFavorite}
                favoriteHandler={favoriteHandler}
                title="Save Building as Favorite"
              />
            </div>
          </div> */}
          <Row className="p-0 m-0 align-items-center mb-2">
            <Col className="m-0 p-0" xs={"auto"}>
              {/* <FavoriteButton id={selectedBuildingData.BuildingID} type="building" isFavorite={isFavorite} favoriteHandler={favoriteHandler}/> */}
            </Col>

            <Col className="m-0 p-0">
              <h4 className="p-0 m-0 ps-2 fw-bolder text-center text-md-start">
                Building -{" "}
                {selectedBuildingData ? selectedBuildingData.Name : ""}
              </h4>
            </Col>
            <Col xs='auto'>
              <FavoriteButton
                id={selectedBuildingData ? selectedBuildingData.BuildingID : null}
                type="building"
                isFavorite={isFavorite}
                favoriteHandler={favoriteHandler}
                title="Save Building as Favorite"
              />
            </Col>
          </Row>
          <Row style={{ minHeight: "200px" }} className="p-1 m-0 d-flex">
            <Carousel
              activeIndex={carouselBuildingIndex}
              onSelect={buildingCarouselHandleSelect}
            >
              {preparedPhotos &&
                preparedPhotos.map((photo, index) => (
                  <Carousel.Item key={index} className="h-100">
                    <Row className="m-0 p-0">
                      <Col
                        className="m-0 p-0"
                        style={{ height: 200, width: "100%" }}
                      >
                        <LazyImage
                          onClick={() => handleOpenBuildingCarouselModal(index)}
                          src={photo}
                          style={{
                            objectFit: "cover",
                            height: 200,
                            width: "100%",
                          }}
                          alt={`${index} slide`}
                        />
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
            </Carousel>
          </Row>
          {selectedBuildingData && (
            <Row className="m-0 p-0">
              <h6 className="m-0 p-3 fw-bold">
                <i className="bi bi-geo-alt"></i>{" "}
                {selectedBuildingData.Address.replace(/\$\*\|\*\$/, " ")},{" "}
                {selectedBuildingData.City}, {selectedBuildingData.State}{" "}
                {selectedBuildingData.Zip}
              </h6>
            </Row>
          )}

          { !hideStreetMap && (
          <Row className="m-0 p-0">
            {streetViewAvailable ? (
              <Button
                className="m-0 mb-3 px-3 py-1 d-flex justify-content-center align-items-center rounded"
                onClick={() => {
                  setShowStreetView((prevState) => !prevState);
                  setStreetViewClicked(true);
                }}
              >
                {showStreetView ? (
                  <>
                    <i className="bi bi-x-lg pe-2 fs-6"></i>
                    <h6 className="m-0 p-0">Close Street View</h6>
                  </>
                ) : (
                  <>
                    <i className="bi bi-signpost-2 pe-2 fs-6"></i>
                    <h6 className="m-0 p-0">Show Street View</h6>
                  </>
                )}
              </Button>
            ) : (
              <Button
                className="m-0 mb-3 px-3 py-1 d-flex justify-content-center align-items-center rounded"
                style={{ pointerEvents: "none", opacity: 0.7 }}
              >
                <i className="bi bi-x-lg pe-2 fs-6"></i>
                <h6 className="m-0 p-0">No Street View Available</h6>
              </Button>
            )}
          </Row>
          )}

          <Row className="m-0 p-0">
            <Accordion
              // defaultActiveKey='0'
              className="m-0 p-0"
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Amenities</Accordion.Header>
                <Accordion.Body>
                  {selectedBuildingData && features && (
                    <FeaturesChecklist
                      o_allfeatures={features.BuildingAmenities}
                      a_selectedFeatures={selectedBuildingData.Amenities}
                    />
                  )}

                  {selectedBuildingData &&
                    selectedBuildingData.MiscAmenities && (
                      <Col className="mt-3" xs={12}>
                        <span>
                          <b>Additional Amenities: </b>
                        </span>
                        <span>{selectedBuildingData.MiscAmenities}</span>
                      </Col>
                    )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Accessibilities</Accordion.Header>
                <Accordion.Body>
                  {selectedBuildingData && features && (
                    <FeaturesChecklist
                      o_allfeatures={features.BuildingAccessibilities}
                      a_selectedFeatures={selectedBuildingData.Accessibilities}
                    />
                  )}

                  {selectedBuildingData &&
                    selectedBuildingData.MiscAccessibilities && (
                      <Col className="mt-3" xs={12}>
                        <span>
                          <b>Additional Accessibilities: </b>
                        </span>
                        <span>{selectedBuildingData.MiscAccessibilities}</span>
                      </Col>
                    )}
                </Accordion.Body>
              </Accordion.Item>

              {selectedBuildingData &&
              selectedBuildingData.Documents &&
              selectedBuildingData.Documents.length ? (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Documents</Accordion.Header>
                  <Accordion.Body>
                    {selectedBuildingData &&
                      selectedBuildingData.Documents.map((document) => {
                        return (
                          <Row
                            className="m-0 my-2 p-2 pb-3 darkerGray rounded"
                            key={document.id}
                          >
                            <Col className="d-flex align-items-center">
                              <p className="m-0 fw-bold fs-6">
                                {document.OriginalFileName}
                              </p>
                            </Col>
                            <Col xs={12} className="mt-2">
                              <h6 className="m-0 p-2 fw-normal bg-light">
                                {document.Description ||
                                  "This document has no description..."}
                              </h6>
                            </Col>
                            <Col
                              xs={12}
                              className="m-0 mt-2 p-0 d-flex justify-content-center"
                            >
                              <Button
                                onClick={() =>
                                  downloadFileFromDB(
                                    document.URL,
                                    document.OriginalFileName
                                  )
                                }
                                type="button"
                                bsPrefix="darkerGray"
                                variant="secondary"
                                className="fw-bold m-0 p-1 px-3 bg-primary text-light rounded"
                              >
                                Download
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
              {selectedBuildingData && selectedBuildingData.Notes ? (
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Notes</Accordion.Header>
                  <Accordion.Body>
                    <span>{selectedBuildingData.Notes}</span>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>
          </Row>

          <Row className="m-0 p-0 py-3">
            <Col
              className="m-0 mt-2 p-0 px-2"
              xs={12}
              style={{ maxHeight: 200, overflowY: "auto" }}
            >
              {selectedBuildingData &&
                selectedBuildingData.Units &&
                selectedBuildingData.Units.filter( unit => showAllUnits ? true : unit.Vacant).map((unit) => {
                  if (showOnlyUnitWithID && showOnlyUnitWithID !== unit.UnitID)
                    return null;
                  return (
                    <Row
                      key={unit.UnitID}
                      className={`rounded m-0 my-1 p-1 ${
                        isUnitSelected(unit) ? "bg-primary" : "bg-secondary"
                      } w-100 colorWhite cursor-pointer `}
                      onClick={() => showSelectedUnit(unit.UnitID)}
                    >
                      <Col
                        className="m-0 p-1 d-flex align-items-center"
                        style={{ color: "inherit" }}
                      >
                        <h6
                          className="fw-bold mb-0"
                          style={{ color: "inherit" }}
                        >
                          {`Unit: ${unit.Name}`}
                        </h6>
                      </Col>

                      <Col
                        className="m-0 p-1 pe-0 d-flex align-items-center"
                        xs={"auto"}
                        style={{ color: "inherit" }}
                      >
                        {favorites &&
                          favorites.Units &&
                          favorites.Units.find(
                            (favoriteUnit) =>
                              favoriteUnit.UnitID === unit.UnitID
                          ) && <i className="bi bi-star-fill fs-6 pe-1"></i>}
                        {alreadyRequestesUnitIDs &&
                          alreadyRequestesUnitIDs.find(
                            (id) => id === unit.UnitID
                          ) && <i className="bi bi-check2 fs-6"></i>}
                      </Col>

                      <Col
                        className="m-0 p-1 ps-2 d-flex align-items-center"
                        xs={"auto"}
                        style={{ color: "inherit" }}
                      >
                        <h6
                          className="fw-bold mb-0"
                          style={{ color: "inherit" }}
                        >
                          ${unit.Rent}
                        </h6>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}
