import React from "react";
import {
  Row,
  Col,
} from "react-bootstrap";


export default function UnitFlatLoader() {
  return (
    <>
        <Row className="m-0 p-0 mb-2">
        <Col className="m-0 p-0">
            <Row className="m-0 p-1 p-md-0 d-flex darkerGray rounded skeleton">
            <Col className="m-0 px-0 w-100 d-flex align-items-center " style={{height: '2.375rem'}}></Col>
            </Row>
        </Col>
        </Row>

        <Row className="m-0 p-0 mb-2">
            <Col className="m-0 p-0">
            <Row className="m-0 p-1 p-md-0 d-flex darkerGray rounded skeleton">
                <Col className="m-0 px-0 w-100 d-flex align-items-center " style={{height: '2.375rem'}}></Col>
            </Row>
            </Col>
        </Row>
    </>
  );
}
