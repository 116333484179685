import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';


export default function BuildingClusterModalPicker({
    BuildingID,
    show,
    onHide,
    buildingsPins,
    selectBuildingHandler
}) {
    const onSelectBuilding = ( BuildingID ) => {
        selectBuildingHandler(BuildingID);
        onHide();
    }

    const renderBuildingsCards = () => {
        if (!Array.isArray(BuildingID) || !buildingsPins || (buildingsPins.some( val => val === null) || BuildingID.length <= 1)) {
            return;
        }
        const buildingsIDs = [...BuildingID];
        const extractBuildingData = buildingsPins.filter( building =>  buildingsIDs.includes(building.BuildingID));
        return extractBuildingData.map((buildingData) => {
            return (
                <Row key={buildingData.BuildingID} 
                    className="m-0 my-1 p-2 darkerGray cursor-pointer on-hover-bg-primary rounded" 
                    onClick={() => onSelectBuilding(buildingData.BuildingID) }
                >
                    <Col className="m-0 p-0">
                        <h6 className="m-0 p-0">{buildingData.Name}</h6>
                    </Col>
                    <Col className="m-0 p-0" xs={'auto'}>
                        <h6 className="m-0 p-0">{buildingData.MaxRentPrice !== buildingData.MinRentPrice ? 'from' : ''} ${buildingData.MinRentPrice} {buildingData.MaxRentPrice !== buildingData.MinRentPrice  ? `to $${buildingData.MaxRentPrice}` : ''}</h6>
                    </Col>
                </Row>
            )
        });
    }

    return (
    
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 d-flex align-items-center">
                            <h6 className="m-0 p-0">Select Building</h6>
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="mb-0 p-0" style={{ overflowY: 'auto' }} >
                <Container className='m-0 p-3' fluid>
                    <Row className="m-0 p-0 flex-column align-items-center">
                        <Col className="m-0 p-0" xs={12} md={10}>
                            { BuildingID && renderBuildingsCards()}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}