import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  Link,
  NavLink,
} from "react-router-dom";
import orderBy from "lodash/orderBy";
import { getTickets } from "../services/tickets";
import { AuthContext } from "../contexts/AuthContext";
import { UserTypeContext } from "../contexts/UserTypeContext";
import Navigation from "../components/Shared/Navigation";
import { getAllBuildings } from "../services/building";
import { getLandlordsInfo } from "../services/manager";
import { managerDataActions } from "../store/ManagerDataSlice";
import { propertiesDataActions } from "../store/PropertiesSlice";
import PropertiesList from "../components/Properties/PropertiesList";
import PropertiesMap from "../components/Properties/PropertiesMap";
import PropertiesVacancy from "../components/Properties/PropertiesVacancy";
import PropertiesManagers from "../components/Properties/PropertiesManagers";
import Tenants from "../components/Properties/Tenants";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddFirstProperty from "../components/Properties/PropertiesList/AddFirstProperty";

export default function Properties() {
  let { path, url } = useRouteMatch();
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [isError, setIsError] = useState(false);
  const [sort, setSort] = useState("managers");

  const { userType } = useContext(UserTypeContext);

  const dispatch = useDispatch();

  const landlordsInformations = useSelector(
    (store) => store.managerDataStore.landlordsInformations
  );
  const propertiesStore = useSelector(
    (store) => store.propertiesStore.properties
  );
  const wereAllPropertiesFetched = useSelector(
    (store) => store.propertiesStore.wereAllPropertiesFetched
  );
  const cancelToken = useMemo(() => axios.CancelToken.source(), []);

  const [sortedTickets, setSortedTickets] = useState(null);
  const getTicketsHandler = useCallback(async () => {
    setLoadingTickets(true);
    const response = await getTickets(token, userType.toLowerCase());

    const { success, data } = response;

    if (success) {
      switch (userType) {
        case "Manager":
          setSortedTickets(extrudeTickets(data));
          break;
        case "Landlord":
        default:
          setSortedTickets(data);
      }
    } else {
      ErrorPopup();
    }
    setLoadingTickets(false);
  }, [token, userType]);

  const extrudeTickets = (tickets) => {
    if (!tickets) return null;

    const extrudedProperties = Object.entries(tickets).reduce(
      (acc, [_, value]) => {
        return {
          ...acc,
          ...value,
        };
      },
      {}
    );
    if (!extrudedProperties) return {};

    return extrudedProperties;
  };

  useLayoutEffect(() => {
    if (sortedTickets) {
      setLoading(false);
    } else {
      getTicketsHandler();
    }
  }, [sortedTickets, getTicketsHandler]);

  const getLandlordsInfoHandler = useCallback(async () => {
    setLoading(true);

    const response = await getLandlordsInfo(token);
    const { success, data } = response;

    if (success) {
      dispatch(managerDataActions.setLandlordsInformations(data));
    } else {
      ErrorPopup();
    }
    setLoading(false);
  }, [token, dispatch]);

  useEffect(() => {
    if (userType === "Manager") {
      if (!landlordsInformations) {
        getLandlordsInfoHandler();
      }
    }
  }, [token, getLandlordsInfoHandler, landlordsInformations, userType]);

  useEffect(() => {
    return () => {
      cancelToken.cancel("Cancel request");
    };
  }, [cancelToken]);

  const extrudeManagersData = (properties) => {
    if (!properties || !Array.isArray(properties)) return {};

    return properties.reduce((acc, property) => {
      if (property.Managers) {
        const managersObject = property.Managers.reduce((acc, manager) => {
          return {
            ...acc,
            [manager.Email]: {
              Email: manager.Email,
              FullName: manager.FullName,
            },
          };
        }, {});
        return {
          ...acc,
          ...managersObject,
        };
      }
      return acc;
    }, {});
  };

  const getAllBuildingsHandler = useCallback(async () => {
    if (wereAllPropertiesFetched) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const settings = {
      cancelToken: cancelToken.token,
    };

    const response = await getAllBuildings(token, settings);
    const { success, data } = response;

    if (data?.message === "Cancel request") {
      return;
    }

    if (success) {
      const prepareProperties = extrudeProperties(data);

      if (userType === "Landlord") {
        const extrudedManagerData = extrudeManagersData(prepareProperties);
        const prepareManagerData = Object.entries(extrudedManagerData).map(
          ([_, managerData]) => managerData
        );

        dispatch(
          managerDataActions.setLandlordsInformations(prepareManagerData)
        );
        dispatch(propertiesDataActions.setFetchedAllProperties(true));
      }

      // For both managers and landlords
      dispatch(propertiesDataActions.setProperties(prepareProperties));
    } else {
      ErrorPopup();
    }
    setLoading(false);
  }, [token, cancelToken, dispatch, userType]);

  // After clicking on PROPERTIES route, if user
  // immidiately change route, then async has to be cancelled
  // to prevent memory leakage
  useEffect(() => {
    getAllBuildingsHandler();
  }, [token, getAllBuildingsHandler, cancelToken]);

  const extrudeProperties = (properties) => {
    if (!properties) return null;
    if (Array.isArray(properties)) return properties;

    const extrudedProperties = Object.entries(properties)
      .map(([key, value]) => {
        if (!value) return null;
        return value.map((property) => ({
          ...property,
          LandlordEmail: key,
        }));
      })
      .flat().map( building => {
        let minRent=0;
        let maxRent=0;

        // console.log("[TEST]: ", building);

        if(building && Array.isArray(building.Units) && building.Units.length){
          minRent=building.Units.reduce((acc, unit) => {
            if(unit.Rent < acc) return unit.Rent;
            return acc;
          }, Number.MAX_VALUE);
          maxRent=building.Units.reduce((acc, unit) => {
            if(unit.Rent > acc) return unit.Rent;
            return acc;
          }, Number.MIN_VALUE);
        }

        if(!building) return null;

        return {
          ...building,
          MinRentPrice: minRent, 
          MaxRentPrice: maxRent
        }
    });
    
    if (!extrudedProperties) return [];
    if (extrudedProperties[0] === null) return [];

    return extrudedProperties;
  };

  const [activeFilters, setActiveFilters] = useState({
    Alphabetically: "",
    Person: "",
    City: "",
    State: "",
    Requests: "",
    TotalVacancies: "",
    TotalUnits: "",
    ManagersPageStructure: ""
  });

  const [resultCount, setResultCount] = useState(0);

  const changeHandler = (value, filterName) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  const changeMultipleFilters = (values) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      ...values,
    }));
  }

  const clearFilterHandler = (filterName) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      [filterName]: "",
    }));
  };

  const resetFilters = (defaultValues = {}) => {
    const values = {...defaultValues};
    if (activePage === "managers") {
      values.ManagersPageStructure = "managers";
    }

    setActiveFilters({
      Alphabetically: "",
      Person: "",
      City: "",
      State: "",
      Requests: "",
      TotalVacancies: "",
      TotalUnits: "",
      ManagersPageStructure: "",
      ...values
    });
  };

  const howManyFiltersActive = () => {
    return Object.entries(activeFilters).filter(([key, value]) => value).length;
  };

  const sortAscending = (propertyArray, key = "Name") => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [key], ["asc"]);
  };

  const sortDescending = (propertyArray, key = "Name") => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [key], ["desc"]);
  };

  const filterHandler = useMemo(() => {
    if (!propertiesStore) return [];
    let sortedAndFilteredArr = [...propertiesStore];

    if (activeFilters.Alphabetically === "asc") {
      sortedAndFilteredArr = sortAscending(sortedAndFilteredArr);
    }

    if (activeFilters.Alphabetically === "desc") {
      sortedAndFilteredArr = sortDescending(sortedAndFilteredArr);
    }

    if (activeFilters.TotalVacancies === "asc") {
      sortedAndFilteredArr = sortedAndFilteredArr.map(property => {
        const vacantCount = property.Units?.reduce((acc, unit) => acc = acc + (unit.Vacant ? 1 : 0), 0);
        return { ...property, TotalVacancies: vacantCount || 0 };
      });

      sortedAndFilteredArr = sortAscending(sortedAndFilteredArr, "TotalVacancies");
    }

    if (activeFilters.TotalVacancies === "desc") {
      sortedAndFilteredArr = sortedAndFilteredArr.map(property => {
        const vacantCount = property.Units?.reduce((acc, unit) => acc = acc + (unit.Vacant ? 1 : 0), 0);
        return { ...property, TotalVacancies: vacantCount || 0 };
      });

      sortedAndFilteredArr = sortDescending(sortedAndFilteredArr, "TotalVacancies");
    }

    if (activeFilters.TotalUnits === "asc") {
      sortedAndFilteredArr = sortedAndFilteredArr.map(property => {
        const unitsCount = property.Units?.length;
        return { ...property, TotalUnits: unitsCount || 0 };
      });

      sortedAndFilteredArr = sortAscending(sortedAndFilteredArr, "TotalUnits");
    }

    if (activeFilters.TotalUnits === "desc") {
      sortedAndFilteredArr = sortedAndFilteredArr.map(property => {
        const unitsCount = property.Units?.length;
        return { ...property, TotalUnits: unitsCount || 0 };
      });

      sortedAndFilteredArr = sortDescending(sortedAndFilteredArr, "TotalUnits");
    }

    if (activeFilters.State) {
      sortedAndFilteredArr = sortedAndFilteredArr.filter(
        (property) => property.State === activeFilters.State
      );
    }

    if (activeFilters.City) {
      sortedAndFilteredArr = sortedAndFilteredArr.filter((property) =>
        property.City.trim()
          .toLowerCase()
          .includes(activeFilters.City.trim().toLowerCase())
      );
    }

    if (activeFilters.Requests) {
      const status = activeFilters.Requests.toLowerCase() === "opened";

      sortedAndFilteredArr = sortedAndFilteredArr.filter((property) => {
        const { BuildingID } = property;
        const tickets = sortedTickets[BuildingID];
        if (Array.isArray(tickets)) {
          return tickets.some((ticket) => ticket.Status === status);
        }
        return false;
      });
    }

    if (userType === "Manager" && activeFilters.Person !== "") {
      sortedAndFilteredArr = sortedAndFilteredArr.filter(
        (property) => property.LandlordEmail === activeFilters.Person
      );
    }

    if (userType === "Landlord" && activeFilters.Person !== "") {
      sortedAndFilteredArr = sortedAndFilteredArr.filter((property) => {
        if (!property.Managers) return false;
        return property.Managers.some(
          (manager) => manager.Email === activeFilters.Person
        );
      });
    }

    setResultCount(sortedAndFilteredArr.length);
    return sortedAndFilteredArr;
  }, [
    activeFilters.State,
    activeFilters.City,
    activeFilters.Alphabetically,
    activeFilters.Person,
    activeFilters.Requests,
    activeFilters.TotalVacancies,
    activeFilters.TotalUnits,
    propertiesStore,
    userType,
    sortedTickets
  ]);
  const useIsURL = (path) => useLocation().pathname.split("/").at(-1) === path;
  const isMap = useIsURL('map');

  const activePage = useLocation().pathname.split("/").at(-1);

  const hasProperties = useMemo(() => {
    return !(
      wereAllPropertiesFetched &&
      !isError &&
      !loading &&
      !propertiesStore.length
    );
  }, [wereAllPropertiesFetched, isError, loading, propertiesStore]);

  useEffect(() => {
    if (activeFilters.ManagersPageStructure) {
      setSort(activeFilters.ManagersPageStructure);
    }
  }, [activeFilters.ManagersPageStructure]);

  useEffect(() => {
    if (activePage === "managers") {
      resetFilters({ ManagersPageStructure: "managers" });
    } else {
      resetFilters();
    }
  }, [activePage])

  return (
    <Container
      fluid
      // style={{ maxWidth: isMap ? "100%" : 1200 }}
      className="m-auto mb-md-5 p-0 h-100 "
    >
      <Container
        fluid
        style={{ maxWidth: 1200 }}
      >
        {/* <div className="" style={{ maxWidth: 1200, width: '100%' }}> */}
        <Navigation
          activeFilters={activeFilters}
          changeHandler={changeHandler}
          changeMultipleFilters={changeMultipleFilters}
          resetFilters={resetFilters}
          clearFilterHandler={clearFilterHandler}
          howManyFiltersActive={howManyFiltersActive}
          results={resultCount}
          persons={landlordsInformations}
          userType={userType}
          loading={loading}
          hasProperties={hasProperties}
          loadingTickets={loadingTickets}

          activePage={activePage}
        />
        {/* </div> */}
      </Container>
      <Container
        fluid
        style={{ maxWidth: isMap ? "100%" : 1200 }}
        className="p-0"
      >

        <Switch>
          <Route exact path={path}>
            {/* <Container fluid className="h-100"> */}
            <div className="justify-content-center d-flex my-5 py-5 flex-lg-row flex-column">

              <NavIcon to="map" icon="bi-house-door" title="Your Properties" />
              <NavIcon
                to="vacancy"
                icon="bi-door-open"
                title="Open Vacancies"
              />
              <NavIcon to="managers" icon="bi-person" title="Your Managers" />

            </div>
            {/* </Container> */}
          </Route>

          <Route path={`${path}/list`}>
            { propertiesStore && propertiesStore.length ? (
                <PropertiesList
                loading={loading}
                properties={propertiesStore}
                filterHandler={filterHandler}
                isError={isError}
                wereAllPropertiesFetched={wereAllPropertiesFetched}
                sortedTickets={sortedTickets}
                loadingTickets={loadingTickets}
                hasProperties={hasProperties}
              />
            ) : (
              <AddFirstProperty />
            )}

          </Route>

          <Route path={`${path}/map`}>
            { propertiesStore && propertiesStore.length ? (
            <PropertiesMap
              loading={loading}
              properties={filterHandler}
              isError={isError}
              filters={activeFilters}
              token={token}
              userType={userType}
              hasProperties={hasProperties}
            />
            ) : (<AddFirstProperty />)}
          </Route>
          <Route path={`${path}/vacancy`}>
            { propertiesStore && propertiesStore.length ? (
            <PropertiesVacancy
              loading={loading}
              properties={filterHandler}
              isError={isError}
              filters={activeFilters}
              token={token}
              userType={userType}
            />
            ) : (<AddFirstProperty />)}
          </Route>
          <Route path={`${path}/managers`}>
          { propertiesStore && propertiesStore.length ? (
            <PropertiesManagers
              loading={loading}
              properties={propertiesStore}
              isError={isError}
              filters={activeFilters}
              token={token}
              userType={userType}
              sort={sort}
              setSort={setSort}
            />
            ) : (<AddFirstProperty />)}
          </Route>

          <Route path={`${path}/tenants`}>
            <Tenants />
          </Route>
        </Switch>
      </Container>

    </Container>
  );
}

function NavIcon({ title = "", icon = "", to = "" }) {
  return (
    <div className="mx-5 imageHover">
      <NavLink to={`properties/${to}`} className="jumpToLink">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <i className={`bi ${icon}`} style={{ fontSize: "10em" }}></i>
          <h2 className="">{title}</h2>
        </div>
      </NavLink>
    </div>
  );
}
