import { useState, useEffect, useCallback } from 'react'


import { geocodeByPlaceId } from 'react-google-places-autocomplete';

export default function useGoogleAutocomplete() {
    const [googleValue, setGoogleValue] = useState(null);
    const [autocompleteError, setAutocompleteError] = useState(null);
    const [lattitudeAndLongitude, setLattitudeAndLongitude] = useState(null);
    const [addressFromAutocomplete, setAddressFromAutocomplete] = useState(null);

    // const googleAutocompleteHandler = useCallback(async (address) => {
    //     const response = await geocodeByAddressHandler(address);
    //     const {success, data} = response;

    //     if(success){
    //         const { City, State, Zip, Address, LatLong } = data;
    //         const addressComponents = {
    //             City,
    //             State,
    //             Zip,
    //             Address
    //         }

    //         const addressErrors = googleAutocompleteErrors(addressComponents);
    //         const latLongErrors = googleAutocompleteErrors(LatLong);

    //         if (addressErrors.length || latLongErrors.length) {
    //             setAutocompleteError([
    //                 ...addressErrors,
    //                 ...latLongErrors
    //             ]);
    //             return;
    //         } else {
    //             setAutocompleteError(null)
    //         }

    //         setLattitudeAndLongitude(LatLong);
    //         setAddressFromAutocomplete(addressComponents);
    //     } else {
    //         setAutocompleteError(["Internet connection errors."])
    //     }
    // }, []);

    const googleAutocompleteClearState = useCallback(() => {
        setGoogleValue(null);
        setLattitudeAndLongitude(null);
        setAddressFromAutocomplete(null);
        setAutocompleteError(null);
    }, []);

    // useEffect(()=>{
    //     if(googleValue) {
    //         const { label } = googleValue;
    //         if(label){
    //             googleAutocompleteHandler(label);
    //         }
    //     }
    // },[googleValue, googleAutocompleteHandler])

    const getLocationData = (id) => {
        geocodeByPlaceId(id)
            .then(results =>{
                setLattitudeAndLongitude({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
                setAddressFromAutocomplete({
                    street_number: results[0].address_components.find(el => el.types.includes("street_number")).long_name,
                    street: results[0].address_components.find(el => el.types.includes("route")).short_name,
                    City: results[0].address_components.find(el => el.types.includes("locality")).long_name,
                    State: results[0].address_components.find(el => el.types.includes("administrative_area_level_1")).short_name,
                    Zip: results[0].address_components.find(el => el.types.includes("postal_code")).short_name,
                    Address: `${results[0].address_components.find(el => el.types.includes("street_number")).long_name} ${results[0].address_components.find(el => el.types.includes("route")).short_name}`
                })
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        if (googleValue) {
            const place_id = googleValue.value.place_id;
            if (place_id) {
                getLocationData(place_id);
            }
        }
    }, [googleValue])

    return {
        googleValue,
        autocompleteError,
        lattitudeAndLongitude,
        addressFromAutocomplete,

        setGoogleValue,
        setAutocompleteError,

        googleAutocompleteClearState,
        getLocationData
    }
}
