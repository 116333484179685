import React, { useState } from 'react';
import {
    Row,
    Col,
    Tab,
    Form
} from 'react-bootstrap';

import PhoneWithMask from "../Shared/Inputs/PhoneWithMask";
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ManagerForm from './ManagerForm';
import {
    LANDLORD_CONTACT_PHONE,
    MANAGERS
} from '../../constants/buildingFormConstants';

const SELF_MANAGING = 'self';
const PROFFESSIONAL_MANAGING = 'proffesional';

export default function Manager ({
    f_register,
    o_errors,
    a_managerFields,
    f_addManager,
    f_removeManagerFromDB
}) {
    const [ isSelfManaged, setIsSelfManaged ] = useState(true);
    const [ activeTab, setActiveTab] = useState(SELF_MANAGING);

    const isSelfManagedHandler = (value, tabName) => {
        setIsSelfManaged(value);
        setActiveTab(tabName);
    }

    return (
        <>
            <Row className="m-0 p-0">
                <h3 className="fw-bolder m-0 p-0 pb-3">
                    Enter Property Contact Info
                </h3>
            </Row>
            <Row className="m-0 p-0 justify-content-center flex-column flex-md-row flex-lg-row">
                <Col className="m-0 p-0 mb-2 me-md-2 text-center">
                    <input className="acc-variant-success"
                        id="myInfo" 
                        type="radio" 
                        name="isVacant" 
                        onChange={() => isSelfManagedHandler(true, SELF_MANAGING)}
                        checked={isSelfManaged}
                        />
                    <label htmlFor="myInfo" className="w-100 fw-bolder darkerGray rounded fs-6 d-flex justify-content-center align-items-center" style={{height: 38}}>Edit Your Information</label>
                </Col>

                <Col className="m-0 p-0 mb-2 text-center">
                    <input className="acc-variant-success"
                        id="managersInfo" 
                        type="radio" 
                        name="isVacant" 
                        onChange={() => isSelfManagedHandler(false, PROFFESSIONAL_MANAGING)}
                        checked={!isSelfManaged}
                        />
                    <label htmlFor="managersInfo" className="w-100 fw-bolder darkerGray rounded fs-6 d-flex justify-content-center align-items-center" style={{height: 38}}>Edit Property Manager Info</label>
                </Col>
            </Row>

            <Tab.Container activeKey={activeTab}>
                <Tab.Content className="pb-3">

                    <Tab.Pane eventKey={SELF_MANAGING}>
                        <Row className="m-0 p-0">
                            <Form.Group className="m-0 p-0 mb-3">
                                <PhoneWithMask 
                                    register={f_register(LANDLORD_CONTACT_PHONE)}
                                    label="Phone Number"
                                    showError={true}
                                    errorMessage={o_errors[LANDLORD_CONTACT_PHONE]?.message}
                                />
                            </Form.Group>
                        </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey={PROFFESSIONAL_MANAGING}>
                        
                        {
                            a_managerFields && a_managerFields.map( (item, i) => {
                                return <ManagerForm 
                                    key={item.id}
                                    f_register={f_register}
                                    o_errors={o_errors}
        
                                    removeManager={(index) => f_removeManagerFromDB(item.BuildingManagerID, index)}

                                    index={i}
                                    prefix={`${MANAGERS}.${i}.`}

                                />
                            })
                        }

                        <Row className="m-0 p-0  justify-content-center">
                            <Col xs="auto" className="p-0 pe-sm-1">
                                <ButtonPrimary
                                    onClick={ f_addManager }
                                    type="button"
                                >
                                    <h6 className="fw-bolder p-0 m-0 w-100"><i className="bi bi-plus-lg"></i> Add Manager</h6>
                                </ButtonPrimary>
                            </Col>
                        </Row>

                    </Tab.Pane>

                </Tab.Content>
            </Tab.Container>
        </>
    )
}
