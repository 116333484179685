import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';
import ButtonPrimary from '../Buttons/ButtonPrimary';


export default function MessageContainerLoader ({ buttons }) {
    return (
        <>
            <Row className={`m-0 p-0 py-3 py-xl-4`}>
                <h3 className="m-0 p-0 fw-bold ">Tour Request</h3>
            </Row>

            <Row className="m-0 p-0 mb-3 justify-content-center">
                <Row className="m-0 p-0 justify-content-center">
                    <Col xs={6} className="m-0 mb-2 mb-md-0 pe-1 pe-md-2 p-0 d-flex justify-content-center justify-content-md-end">
                        <ButtonPrimary additionalClassName="skeleton darkerGray border-0" disabled>&nbsp;</ButtonPrimary>
                    </Col>

                    <Col xs={6} className="m-0 p-0 mb-2 mb-md-0 ps-1 d-flex justify-content-center justify-content-md-end">
                        <ButtonPrimary additionalClassName="skeleton darkerGray border-0" disabled>&nbsp;</ButtonPrimary>
                    </Col>
                </Row>
            </Row>


            <Row className="m-0 p-0 flex-grow-1" style={{overflowY: "hidden"}}>
                <Col className="m-0 p-3 pb-2 white rounded d-flex flex-column darkerGray skeleton" style={{height: 200}} xs={12}></Col>
            </Row>
        </>
    )
};
