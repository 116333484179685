import React from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Spinner
} from 'react-bootstrap';

export default function UpdatingImagesInfo({
    onHide,
    isUploading,
    imagesToBeUploaded,
    isUploadingImagesSuccess,
    isDeletingImagesSuccess,
    isDeletingImages,
    imagesToBeDeleted,
    isConfirmSection=true
}) {

    const finishedUpdatingHtmlElement = (
        onHide &&
        (
            <Button onClick={onHide}>
                <h5 className="m-0 p-0 fw-bold">OK</h5>
            </Button>
        )
    );

    // Render different content depending if imagesToBeUploaded and imagesToBeDeleted are present or no
    const confirmSection = () => {

        if (imagesToBeUploaded && imagesToBeDeleted) {
            return (
            <>
                {   isUploading ? (
                        <h5 className="text-center p-0 my-2 mt-3">Please wait this might take a while.</h5>
                    ) : (
                        finishedUpdatingHtmlElement
                    )
                }
            </>
            )
        } else if (imagesToBeDeleted && !imagesToBeUploaded) {
            return (
                <>
                    {   isDeletingImages ? (
                            <h5 className="text-center p-0 my-2 mt-3">Please wait this might take a while.</h5>
                        ) : (
                            finishedUpdatingHtmlElement
                        )
                    }
                </>
                )
        } else {
            return (
                <>
                    {   !isDeletingImages && !isUploading ? (
                            finishedUpdatingHtmlElement
                        ) : (
                            <h5 className="text-center p-0 my-2 mt-3">Please wait this might take a while.</h5>
                        )
                    }
                </>
                )
        }
    }

    return (
        <Container fluid className="m-0 p-0">
            <Row className="p-0 m-0 row-cols-1">
                {
                    imagesToBeUploaded && 
                        <>
                            <Col className="m-0 my-2 p-0">
                                <h6 className="m-0 mb-1 p-0 fw-bold">Uploading images:</h6>

                                <Container fluid className="m-0 p-0">
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0 py-3 text-center">
                                            {   isUploading &&
                                                
                                                <Spinner animation="border" role="status"/>
                                            }
                                            {
                                                !isUploading && (
                                                    isUploadingImagesSuccess ? (
                                                        <h6 className="m-0 mb-1 p-0 fw-bold text-success">&#10003; Success! Images were uploaded!</h6>
                                                    ) : (
                                                        <h6 className="m-0 mb-1 p-0 fw-bold text-danger">X Error! Uploading images failed!</h6>
                                                    )
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </>
                }

                {/* DELETION SECTION */}
                {
                    imagesToBeDeleted && 
                        <>
                            <Col className="m-0 my-2 p-0">
                                <h6 className="m-0 mb-1 p-0 fw-bold">Deleting images:</h6>

                                <Container fluid className="m-0 p-0">
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0 py-3 text-center">
                                            {   isDeletingImages &&
                                                
                                                <Spinner animation="border" role="status"/>
                                            }
                                            {
                                                !isDeletingImages && (
                                                    isDeletingImagesSuccess ? (
                                                        <h6 className="m-0 mb-1 p-0 fw-bold text-success">&#10003; Success! Images were deleted!</h6>
                                                    ) : (
                                                        <h6 className="m-0 mb-1 p-0 fw-bold text-danger">X Error! Deleting images failed!</h6>
                                                    )
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Container>

                            </Col>
                        </>
                }
            </Row>
            
            
            {   isConfirmSection &&
                <Row className="m-0 p-0">
                    { confirmSection() }
                </Row>
            }

        </Container>
    )
}
