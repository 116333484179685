import React, { useEffect, useState } from 'react'
import StreetViewWrapper from './StreetViewWrapper'

export default function StreetViewPicker({ pos, setData, continueBtn, setSuccess }) {
    const [streetViewAvailable, setStreetViewAvailable] = useState(true);
    // const [mapDetails, setMapDetails] = useState();
    const [mapPov, setMapPov] = useState();
    const [mapPos, setMapPos] = useState();
    const [mapData, setMapData] = useState();

    // const handleSVchange = (data)=>{
    //     setStreetViewChanged(data)
    // }
    useEffect(() => {
        if (pos && pos.lat) {
            new window.google.maps.StreetViewService()
                .getPanorama({ location: { lat: pos.lat, lng: pos.lng }, radius: 50 })
                .catch(() => {
                    setStreetViewAvailable(false);
                    setSuccess(false);
                })
        } else {
            setStreetViewAvailable(true);
            setSuccess(true);
        }
    }, [pos])


    useEffect(() => {
        setMapData({ ...mapPos, ...mapPov })
        if (setData) {
            setData({ ...mapPos, ...mapPov })
        }
    }, [mapPov, mapPos])


    let dev = false;
    // dev = true;

    return (
        <div>
            {dev && (
                <p>
                    {mapData && Object.keys(mapData).map((key) => {
                        return (
                            <>
                                <p>{key + " " + mapData[key]}</p>
                            </>
                        )
                    })}
                </p>
            )}
            <div style={{ height: 500 }}>
                <StreetViewWrapper
                    position={pos}
                    setMapPov={setMapPov}
                    mapPov={mapPov}
                    setMapPos={setMapPos}
                    mapPos={mapPos}
                    mapData={mapData}
                    continueBtn={continueBtn}
                    setSuccess={setSuccess}
                />
            </div>
        </div>
    )
}
