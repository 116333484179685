import React from 'react';
import {
    Col,
    Button
} from 'react-bootstrap';

//     filterSectionClasses="m-0 p-0 d-flex justify-content-end",
//     acceptButtonClasses="m-0 ms-2 p-0 px-2 bg-transparent d-flex",
//     resetButtonClasses="m-0 p-0 px-2 bg-transparent d-flex border-0",
//     textClass="text-dark",
//     iconClass="bi bi-sliders pe-2 text-dark",

export default function FiltersBtn({
    howManyFiltersActive=()=>{},
    results,
    openFilters,
    showResults=true,
    btnTitle="Filters",
    disabled=false,
    filterSectionClasses="m-0 p-0 d-flex justify-content-end align-items-center",
    iconClass="bi bi-arrow-down-up"
}) {
    return (
        <>
            { showResults && (
                <Col className="m-0 p-0 d-flex" xs={"auto"}>
                    <p className="m-0 pe-2 fs-6">{results} results</p>
                </Col>)
            }
            <Col className={filterSectionClasses} xs={"auto"}>
                <Button onClick={openFilters} 
                    className={`d-flex flex-row rounded justify-content-center aling-items-center shadow-none ${ !howManyFiltersActive() ? "bg-transparent text-dark" : "text-white"}`} 
                    disabled={disabled}
                >
                    <p className={`d-none d-xl-block m-0 pe-2 fs-6 ${ !howManyFiltersActive() ? "" : "text-white"}`}>{ btnTitle }</p>
                    <i className={iconClass}></i>
                </Button>
            </Col>
        </>
    )
}
