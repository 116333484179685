import React from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import TenantInformations from './TenantInformations';
import LoaderWithBackdrop from '../Shared/LoaderWithBackdrop';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';


export default function IframeSection({ 
    continueBtnHandler, 
    backBtnHandler, 
    register, 
    errors,
    isLoading
}) {
    return (
        <Row className="m-0 p-0 position-relative">
            { isLoading && (
                <LoaderWithBackdrop />
                )
            }

            <Row className="m-0 mb-3 p-2 darkerGray rounded">
                <h5 className="m-0 p-0 text-uppercase text-center fw-bold">Personal Information</h5>
            </Row>

            <Row className="m-0 p-2">
                <h6>Please enter your personal information which will be used for the background check</h6>
            </Row>
        
            <Row className="m-0 px-2">
                <TenantInformations 
                    register={register}
                    errors={errors}
                />
            </Row>

            <Row className="m-0 p-2">
                <h6 className="fw-bold mb-3">You will be redirected to the third party service - TenantTracks</h6>
            </Row>

            <Row className="m-0 p-2 justify-content-center align-items-center">
                <Col className="m-0 p-0 pe-2 text-center" xs={'auto'}>
                    <ButtonSecondary
                        onClick={ backBtnHandler } 
                        title="Back"
                    />
                </Col>
                <Col className="m-0 p-0 text-center" xs={'auto'}>
                    <ButtonPrimary 
                        title="Continue"
                        onClick={ continueBtnHandler }
                    />
                </Col>
            </Row>
        </Row>
    )
}
