import React, {useState, useContext} from 'react';
import { Form, Container, Row, Col } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import schema from '../schemas/changePasswordSchema';
import { changePassword } from '../services/user';
import ButtonPrimary from '../components/Shared/Buttons/ButtonPrimary';
import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";
import { AuthContext } from "../contexts/AuthContext";
import InputFloating from '../components/Shared/Inputs/InputFloating';
import LoaderWithBackdrop from '../components/Shared/LoaderWithBackdrop';

import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../components/Shared/InfoPopups/SuccessPopup';

export default function ChangePassword() {
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const [buttonClicked, setButtonClicked] = useState(false);
    const formOptions = { resolver: yupResolver(schema), mode: "all"};

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
      } = useForm(formOptions);

    const goBackHandler = () => {
        history.push('/settings');
    }

    const onSubmitHandler = async (formData) => {
        if(buttonClicked) return;
        setButtonClicked( true );

        const body = {
            OldPassword: formData.oldPassword,
            NewPassword: formData.newPassword
        }

        const response = await changePassword(body, token);
        const { success, data } = response;

        if(success) {

            setButtonClicked( false );
            reset();

            if(data === "Old password does not match") {
                ErrorPopup(data);
                return;
            }

            SuccessPopup("Success! Your password has been successfully changed.");

            history.push('/settings');
        } else {
            ErrorPopup();
            setButtonClicked( false );
        }
    };

    return (
        <div className="custom_bg d-flex align-items-center justify-content-center">
            <Container fluid style={{maxWidth: 1200}} className="m-auto p-0">
                <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
        
                    <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center position-relative" xs={12} sm={10} md={8} lg={7}>
                        { buttonClicked && (
                            <LoaderWithBackdrop />
                        )

                        }

                        <Row className="m-0 p-0 mb-4">
                            <h3 className="m-0 p-0 fw-bold text-center">Change Password</h3>
                        </Row>

                        <Row className="m-0 p-0" xs={12}>
                            <Form onSubmit={handleSubmit(onSubmitHandler)} className="m-0 p-0 w-100">   
                                
                                <Row className="m-0 p-0">
                                   

                                    <Col className="m-0 p-0 mb-3" xs={12}>
                                        <InputFloating 
                                            label="Old Password"
                                            register={register("oldPassword")}
                                            type="password"
                                            errorMessage={errors.oldPassword?.message}
                                            showError={true}
                                        />
                                    </Col>

                                    <Col className="m-0 p-0 mb-3" xs={12}>
                                        <InputFloating 
                                            label="New Password"
                                            register={register("newPassword")}
                                            type="password"
                                            errorMessage={errors.newPassword?.message}
                                            showError={true}
                                        />
                                    </Col>

                                    <Col className="m-0 p-0 mb-4" xs={12}>
                                        <InputFloating 
                                            label="Confirm Password"
                                            register={register("passwordConfirmation")}
                                            type="password"
                                            errorMessage={errors.passwordConfirmation?.message}
                                            showError={true}
                                        />
                                    </Col>
                
                                    <Row className="m-0 p-0 justify-content-between"> 
                                        <Col className="m-0 p-0 pe-1" xs={6} >
                                            <ButtonSecondary title="Back" type="button" onClick={goBackHandler}/>
                                        </Col>
                            
                                        <Col className="m-0 p-0 ps-1" xs={6}>
                                            <ButtonPrimary title='Reset Password'/>
                                        </Col>
                                    </Row>
                        
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
