import React, { useContext, useState } from "react";
import { Accordion as BootstrapAccordion, Card } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";

import "react-toggle/style.css";

const CustomAccordionContext = React.createContext([[2], () => null]);

export function useCustomAcordionContext() {
  const context = useContext(CustomAccordionContext);
  if (!context) {
    throw new Error(
      "Accordion Context cant be used outside of Accordion component!"
    );
  }
  return context;
}

export function Accordion({
  children,
  defaultActiveKey = [0],
  alwaysOpen = false,
  accordionClasses="",
}) {
  const [opened, setOpened] = useState(defaultActiveKey);
  return (
    <CustomAccordionContext.Provider value={[opened, setOpened]}>
      <CustomAccordionWrapper alwaysOpen={alwaysOpen} accordionClasses={accordionClasses}>
        {children}
      </CustomAccordionWrapper>
    </CustomAccordionContext.Provider>
  );
}

function CustomAccordionWrapper({ children, accordionClasses, ...props }) {
  const [opened] = useCustomAcordionContext();
  return (
    <BootstrapAccordion
      defaultActiveKey={opened}
      activeKey={opened}
      {...props}
      className={accordionClasses}
    >
      {children &&
        React.Children.map(children, (child) =>
          child
            ? React.cloneElement(child, {
                isOpen: opened.includes(child.props.eventKey),
              })
            : null
        )}
    </BootstrapAccordion>
  );
}

export function CustomAccordionElement({
  children,
  eventKey = 0,
  className = "",
  customHeaderElement,
}) {
  const [opened, setOpened] = useCustomAcordionContext()

  const decoratedOnClick = useAccordionButton(eventKey, () =>{
    if(opened.includes(eventKey)){
      setOpened(prev=>prev.filter(val=>val!==eventKey))
    }else{
      setOpened(prev=>[...prev, eventKey])
    }
  });
  return (
    <Card className={`${className} position-relative p-0 border-0`}>
      <Card.Header
        style={{ background: "transparent", border: "", outline: "0" }}
        className="p-0 m-0 border-0"
      >
        <div
          // type="button"
          onClick={decoratedOnClick}
          className={`m-0 p-0 border-0 w-100 h-100 rounded ${className}`}
        >
          {customHeaderElement ?? <div>Custom Accordion Header {eventKey}</div>}
        </div>
      </Card.Header>
      <div>
        <BootstrapAccordion.Collapse eventKey={eventKey}>
          <Card.Body className="p-0 ms-3">{children}</Card.Body>
        </BootstrapAccordion.Collapse>
      </div>
    </Card>
  );
}
