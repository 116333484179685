import { getRequest, postPutRequest, deleteRequest } from './http-common';
import { GROUP_ID, GROUP_NAME, GROUP_DESC, CASE_MANAGER_ID } from '../constants/group';
import { CLIENTS, CLIENT_ID, CLIENT_FIRST_NAME, CLIENT_LAST_NAME, CLIENT_MOBILE, CLIENT_EMAIL } from '../constants/client';

const serviceGetGroups = async (token) => {
    const URL = `command/group`;

    return await getRequest(URL, token);
}


const serviceAddGroup = async (data, token) => {
    const URL = `command/group`;

    // postBody = {
    //     GROUP_NAME: null,
    //     GROUP_DESC: null,
    //     CLIENTS: [CLIENT_ID],
    // }

    return await postPutRequest(URL, data, token, 'post');
}

const serviceDeleteGroup = async (groupID, token) => {
    const URL = `command/group/${groupID}`;

    return await deleteRequest(URL, '', token);
}

const serviceAddClient = async (data, token) => {
    const URL = `command/group/client`;

    // postBody = {
    //     GROUP_ID,
    //     GROUP_NAME: null,
    //     GROUP_DESC: null,
    //         CLIENTS: [{
    //         CLIENT_ID: null,
    //         CLIENT_FIRST_NAME: null,
    //         CLIENT_LAST_NAME: null,
    //         CLIENT_MOBILE: null,
    //         CLIENT_EMAIL: null
    //     }],
    // }

    return await postPutRequest(URL, data, token, 'post');
}

const serviceDeleteClientFromGroup = async (data, token) => {
    const URL = `command/group/client`;

    // postBody = {
    //     CLIENT_ID: null,
    //     GROUP_ID: null
    // }

    return await deleteRequest(URL, data, token);
}

export { 
    serviceGetGroups,
    serviceAddGroup,
    serviceDeleteGroup,
    serviceAddClient,
    serviceDeleteClientFromGroup
};
