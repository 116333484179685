import { postPutRequest } from './http-common';

const getInitialSearch = async (data) => {
    const URL = `search`;
    return await postPutRequest( URL, data, '', 'post');
}

const getDataForMapPin = async (buildingID, unitIDs=[]) => {
    const URL = `search/pinData/${buildingID}`;
    return await postPutRequest( URL, unitIDs, '', 'post');
}

export {
    getInitialSearch,
    getDataForMapPin
};
