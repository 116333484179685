import React from "react";

import FeaturesFields from "../BuildingFormElements/FeaturesFields";
import DocumentsFields from "../BuildingFormElements/DocumentsFields";
import NotesFields from "../BuildingFormElements/NotesFields";

export default function FormSection2({
    files,
    setFiles,
    filesToDelete,
    setFilesToDelete,
    downloadedFiles,
    isUpdate = false,

    features,
    setAmenities,
    setAccessibilities,
    amenities,
    accessibilities,
    register,
    saveDescriptionHandler
}) {
    return (
        <>
            <FeaturesFields 
                features={features}
                setAmenities={setAmenities}
                setAccessibilities={setAccessibilities}
                amenities={amenities}
                accessibilities={accessibilities}
                register={register}
            />
           
            <DocumentsFields 
                files={files}
                setFiles={setFiles}
                filesToDelete={filesToDelete}
                setFilesToDelete={setFilesToDelete}
                downloadedFiles={downloadedFiles}
                isUpdate={isUpdate}
                saveDescriptionHandler={saveDescriptionHandler}
            />

            <NotesFields 
                register={register}
            />
        </>
    )
}
