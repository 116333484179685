import { getRequest, postPutRequest } from './http-common';

const getApplication = async (token) => {
    const URL = `tenant/application`;

    return await getRequest(URL, token);
}

const sendApplication = async (data, token) => {
    const URL = 'tenant/application';

    return await postPutRequest(URL, data, token, 'post');    
}

export { 
    getApplication,
    sendApplication
};
