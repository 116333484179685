import React from 'react'
import Backdrop from '../Shared/Backdrop';

export default function FloatingActionButton(props) {
    const {
        isOpen,
        size = 50,
        childrenClass = "",
        className = "",
        transitionDuration = .2 + "s",
        ...rest
    } = props;

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { childrenClass, transitionDuration });
        }
        return child;
    });


    return (
        <>
            <Backdrop show={isOpen} close={props.setClick} />
            <div
                className={`
                end-0 bottom-0 translate-middle-x mb-4 position-fixed
                floating-action-button ${isOpen ? "opened" : ""}
                d-flex flex-column
                ${className}
            `}
                {...rest}
                style={{ fontSize: size, zIndex: 9999 }}
            >
                {
                    isOpen && (
                        <div
                            className="position-absolute fabBG"
                            style={{ transitionDuration: transitionDuration }}
                        ></div>
                    )
                }
                {isOpen && childrenWithProps}
                <FabMainButton
                    icon="bi-three-dots-vertical"
                    className={childrenClass}
                    onClick={() => props.setClick()}
                    transitionDuration={transitionDuration}
                />
            </div>
        </>
    )
}

export function FabMainButton(props) {
    const { icon = "", color = "#fff",transitionDuration, className = "", ...rest } = props;
    return (
        <div
            className={`position-relative cursor-pointer m-auto my-2 ${className} fabMain`}
            style={{ width: "1.5em", height: "1.5em", transitionDuration: transitionDuration }}
            {...rest}
        >
            <i
                style={{ fontSize: ".8em", color: color }}
                className={`bi ${icon} position-absolute start-50 top-50 translate-middle`}
            ></i>
        </div>
    )
}

export function FabButton(props) {

    const { icon = "", color = "#fff", title, ...rest } = props;
    return (
        <div
            className={`position-relative cursor-pointer m-auto my-2 ${props.childrenClass} fabBtn`}
            style={{ width: "1.2em", height: "1.2em", transitionDuration: props.transitionDuration }}
            {...rest}
        >
            <i
                style={{ fontSize: ".6em", color: color }}
                className={`bi ${icon} position-absolute start-50 top-50 translate-middle`}
            ></i>
            {
                title && (
                    <p
                        className="position-absolute top-50 end-100 pe-2 me-2 bg-primary text-white rounded-3"
                        style={{ fontSize: ".4em", textAlign: "end", width: "8em", zIndex: -1 }}
                    >
                        {title}
                    </p>
                )
            }
        </div>
    )
}




