import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Image,
  Carousel,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import useWindowSize, { GRID_BREAKPOINTS } from "../../../hooks/useWindowSize";
import ModalCarousel from "../ModalCarousel";
import StreetViewCard from "../StreetView/StreetViewCard";
import UnitInfoLoader from "./UnitInfoLoader";
import LazyImage from "../LazyImage";
import FeaturesChecklist from "../FeaturesChecklist";
import FavoriteButton from "../Buttons/FavoriteButton";

import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  FacebookIcon,
  LineIcon,
  WhatsappIcon,
} from "react-share";
import { useSelector } from "react-redux";

import preparePhotos from "../../../utils/preparePhotos";

export default function UnitInfo({
  selectedUnitData,
  selectedBuildingData,
  streetViewClicked,
  showStreetView,
  streetViewAvailable,
  toggleStreetView,
  isLoading,
  features,
  favoriteHandler,
  showFavoriteBtn = true,
}) {
  const size = useWindowSize();
  const [showCarouselModal, setShowCarouselModal] = useState(false);
  const [carouselModalIndex, setCarouselModalIndex] = useState(0);
  const [mobileCarouselIndex, setMobileCarouselIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const favorites = useSelector((store) => store.favoriteStore);

  const handleOpenCarouselModal = (index) => {
    setShowCarouselModal(true);
    setCarouselModalIndex(index);
  };

  const handleCloseCarouselModal = () => {
    setShowCarouselModal(false);
    setCarouselModalIndex(0);
    setMobileCarouselIndex(0);
  };

  const carouselHandleSelect = (selectedIndex, e) => {
    setCarouselModalIndex(selectedIndex);
  };

  const mobileCarouselHandleSelect = (selectedIndex, e) => {
    setMobileCarouselIndex(selectedIndex);
  };

  const getFavoritePhoto = (photos) => {
    // console.log("[UnitInfo][getFavoritePhoto]photos: ", photos);

    if (!photos) return;

    let favoritePhotoIndex = 0;
    const photoData = photos.find((photo, index) => {
      if (photo.IsFavorite) favoritePhotoIndex = index;
      return photo.IsFavorite;
    });
    const restPhotos = [
      ...photos.slice(0, favoritePhotoIndex),
      ...(favoritePhotoIndex + 1 > photos.length
        ? []
        : photos.slice(favoritePhotoIndex + 1)),
    ];
    // const restPhotos = photos.filter(photo => !photo.IsFavorite);

    if (!photoData)
      return { favoritePhoto: photos[0] && photos[0].URL, restPhotos: [] };
    return {
      favoritePhoto: photoData.URL,
      restPhotos: restPhotos,
    };
  };

  const [copied, setCopied] = useState(false);

  const generateLink = (unitData) => {
    if (!unitData) return;
    const URL = `${window.location.origin}/marketplace?City=${unitData.City}&State=${unitData.State}&BuildingID=${unitData.BuildingID}&UnitID=${unitData.UnitID}`;
    return URL;
  };

  const copyLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(generateLink(selectedUnitData));
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    if (
      favorites &&
      favorites.Units &&
      selectedUnitData &&
      selectedUnitData.UnitID
    ) {
      if (
        favorites.Units.find((unit) => unit.UnitID === selectedUnitData.UnitID)
      )
        setIsFavorite(true);
      else {
        setIsFavorite(false);
      }
    }
  }, [favorites, selectedUnitData]);

  const preparedPhotos = preparePhotos({
    ...selectedUnitData,
    StreetPOV: selectedBuildingData?.StreetPOV,
  });

  return (
    <>
      <ModalCarousel
        show={showCarouselModal}
        closeHandler={handleCloseCarouselModal}
        photoIndex={carouselModalIndex}
        onSelect={carouselHandleSelect}
        photos={preparedPhotos}
        unit={selectedUnitData}
        building={false}
      />

      {isLoading && <UnitInfoLoader />}

      {!isLoading && (
        <Col
          className="m-0 p-2 border-start shadow-sm overflow-hidden"
          xs={12}
          md={8}
          style={{ background: "#f3f3f3" }}
        >
          {streetViewClicked && size && size.width > GRID_BREAKPOINTS.MD && (
            <div
              className={!showStreetView ? "d-none" : "d-block"}
              style={{ height: "calc(100vh - 6rem -  1rem)" }}
            >
              <StreetViewCard
                streetViewAvailable={streetViewAvailable}
                buildingData={selectedBuildingData}
                onClose={toggleStreetView}
              />
            </div>
          )}

          {selectedUnitData && !showStreetView && (
            <>
              {showFavoriteBtn && (
                <div className="d-flex mb-2">
                  <div className="ms-auto"></div>
                </div>
              )}
              <Row className="p-0 m-0 align-items-center mb-2">
                {/* { showFavoriteBtn && (
                                    <Col className="m-0 p-0" xs={'auto'}>
                                        {isFavorite ? (
                                            <i className="p-1 bi bi-star-fill fs-4 text-warning cursor-pointer" onClick={() => favoriteHandler(selectedUnitData.UnitID, 'unit', 'delete')}></i>
                                        ) : (
                                            <i className="p-1 bi bi-star fs-4 cursor-pointer" onClick={() => favoriteHandler(selectedUnitData.UnitID, 'unit', 'add')}></i>
                                        )}
                                    </Col>
                                )} */}
                <Col className="m-0 p-0">
                  <h4 className="p-0 m-0 ps-2 fw-bolder text-center text-md-start">
                    Unit Name - {selectedUnitData.Name}
                  </h4>
                </Col>
                <Col xs='auto'>
                  <FavoriteButton
                    id={selectedUnitData.UnitID}
                    type="unit"
                    isFavorite={isFavorite}
                    favoriteHandler={favoriteHandler}
                    title="Save Unit as Favorite"
                  />
                </Col>
              </Row>

              <Container fluid className="p-1 m-0 montserrat">
                {/* Pictures for desktop */}
                <Row
                  style={{ height: "200px" }}
                  className="p-0 m-0 d-none d-md-flex"
                >
                  <Col
                    className="m-0 p-0 pe-1 pb-1 h-100"
                    style={{ height: 200 }}
                    xs={6}
                  >
                    <LazyImage
                      onClick={() => handleOpenCarouselModal(0)}
                      src={preparedPhotos && preparedPhotos[0]}
                      style={{ objectFit: "cover", height: 200, width: "100%" }}
                      alt={`Unit main image`}
                    />
                  </Col>

                  <Col xs={6} className="p-0 m-0 h-100">
                    <Row
                      className="p-0 pe-1 m-0 gx-1 h-100 wissp-scroll-box"
                      style={{ overflowY: "auto" }}
                    >
                      {getFavoritePhoto(
                        selectedUnitData.Photos
                      )?.restPhotos.map((img, index) => (
                        <Col
                          xs={6}
                          style={{ height: "50%" }}
                          className="pb-1"
                          key={`${img && img.PhotoID}-${index}`}
                        >
                          <Image
                            onClick={() => handleOpenCarouselModal(index + 1)}
                            src={img && img.URL}
                            style={{
                              objectFit: "cover",
                              height: "100%",
                              width: "100%",
                            }}
                            className="d-block rounded"
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>

                {/* Carousel for mobile views */}
                <Row
                  style={{ height: "200px" }}
                  className="p-0 m-0 d-flex d-md-none"
                >
                  <Carousel
                    activeIndex={mobileCarouselIndex}
                    onSelect={mobileCarouselHandleSelect}
                  >
                    {selectedUnitData.Photos?.map((photo, index) => (
                      <Carousel.Item key={index} className="h-100">
                        <img
                          onClick={() => handleOpenCarouselModal(index)}
                          style={{
                            objectFit: "cover",
                            height: "200px",
                            width: "100%",
                          }}
                          className="d-block w-100 rounded"
                          src={photo.URL}
                          alt={`${index} slide`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Row>

                {/* Informations */}
                <Row className="p-0 m-0 mt-2">
                  <Container fluid className="p-0 m-0">
                    <Row className="p-0 m-0">
                      <Col className="p-0 m-0" xs={12}>
                        <Row className="p-0 m-0 mb-1">
                          <h6 className="p-0 m-0 fw-bolder">
                            Rent: ${selectedUnitData.Rent} per month
                          </h6>
                        </Row>
                      </Col>
                      <Col className="p-0 m-0" xs={12} md={6}>
                        <Row className="p-0 m-0 mb-1">
                          {selectedUnitData.FloorID !== null && (
                            <Row className="p-0 m-0 mb-2">
                              <h6 className="p-0 m-0 ">
                                Square footage: {selectedUnitData.SquareFootage}
                              </h6>
                            </Row>
                          )}
                        </Row>
                        <Row className="p-0 m-0 mb-3 mb-md-0">
                          <Col className="p-0 m-0">
                            <p className="p-0 m-0 fs-6">
                              Bedrooms:{" "}
                              <strong>{selectedUnitData.BedroomCount}</strong>
                            </p>
                            <p className="p-0 m-0 fs-6">
                              Square Footage:{" "}
                              <strong>{selectedUnitData.SquareFootage}</strong>
                            </p>
                          </Col>
                          <Col className="p-0 m-0">
                            <p className="p-0 m-0 fs-6">
                              Bathrooms:{" "}
                              <strong>{selectedUnitData.BathroomCount}</strong>
                            </p>
                            <p className="p-0 m-0 fs-6">
                              Floor:{" "}
                              <strong>
                                {selectedUnitData.FloorID
                                  ? selectedUnitData.FloorID
                                  : "Uncategorized"}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        className="m-0 p-0 d-flex align-items-end"
                        xs={12}
                        md={6}
                      >
                        <Row className="m-0 p-0 w-100">
                          <div className="d-flex justify-content-end">
                            <div className="">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Copy to clipboard!
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="m-0 p-0 px-2 me-1 rounded fw-bold d-flex align-items-center justify-content-center"
                                  style={{ height: 32, width: 105 }}
                                  onClick={copyLink}
                                >
                                  <p className="m-0 p-0 fs-6 text-light">
                                    {copied ? "Copied!" : "Copy Link"}
                                  </p>
                                </Button>
                              </OverlayTrigger>
                            </div>
                            <div className="">
                              <EmailShareButton
                                className="me-1"
                                subject="WISSP unit available"
                                body={`Please see this listing that is now available on WISSP!\n\n${generateLink(
                                  selectedUnitData
                                )}\n\nRegards,\nThe WISSP Team`}
                                url={""}
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </div>

                            <div className="">
                              <FacebookShareButton
                                url={generateLink(selectedUnitData)}
                                hashtag={"#wissp"}
                                description="WISSP"
                                className="me-1"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </div>

                            {/* <Col className="m-0 p-0 mt-1" xs="auto">
                                                <FacebookMessengerShareButton
                                                    url={ generateLink(selectedUnitData) }
                                                    appId="MISSING_ID"
                                                    className="me-1"
                                                >
                                                    <FacebookMessengerIcon size={32} round />
                                                </FacebookMessengerShareButton>
</Col> */}
                            <div className="">
                              <TwitterShareButton
                                url={generateLink(selectedUnitData)}
                                title={"WISSP"}
                                className="me-1"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                            </div>
                            <div className="">
                              <WhatsappShareButton
                                url={generateLink(selectedUnitData)}
                                title={"WISSP"}
                                separator=":: "
                                className="me-1"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </div>
                            <div className="">
                              <LineShareButton
                                url={generateLink(selectedUnitData)}
                                title={"WISSP"}
                                className="me-1"
                              >
                                <LineIcon size={32} round />
                              </LineShareButton>
                            </div>
                          </div>
                        </Row>

                        {/* <LinkedinShareButton  
                                                    url={ generateLink(selectedUnitData) }
                                                    title={"WISSP"}  className="me-1"
                                                >
                                                    <LinkedinIcon size={32} round />
                                                </LinkedinShareButton> */}

                        {/* LinkedinShareButton,
                                                LineShareButton,
                                                WhatsappShareButton, */}
                      </Col>
                    </Row>

                    <Row className="m-0 p-0 mt-2 lightGray">
                      <Accordion defaultActiveKey="0" className="m-0 p-0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Amenities</Accordion.Header>
                          <Accordion.Body>
                            {features && selectedUnitData && (
                              <FeaturesChecklist
                                o_allfeatures={features.UnitAmenities}
                                a_selectedFeatures={selectedUnitData.Amenities}
                              />
                            )}

                            {selectedUnitData &&
                              selectedUnitData.MiscAmenities && (
                                <Col className="mt-3" xs={12}>
                                  <p className="m-0 p-0 fs-6">
                                    <strong>Additional Amenities: </strong>{" "}
                                    {selectedUnitData.MiscAmenities}
                                  </p>
                                  <p className="m-0 p-0 fs-6"></p>
                                </Col>
                              )}
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Accessibilities</Accordion.Header>
                          <Accordion.Body>
                            {features && selectedUnitData && (
                              <FeaturesChecklist
                                o_allfeatures={features.UnitAccessibilities}
                                a_selectedFeatures={
                                  selectedUnitData.Accessibilities
                                }
                              />
                            )}

                            {selectedUnitData &&
                              selectedUnitData.MiscAccessibilities && (
                                <Col className="mt-3" xs={12}>
                                  <span>
                                    <b>Additional Accessibilities: </b>
                                  </span>
                                  <span>
                                    {selectedUnitData.MiscAccessibilities}
                                  </span>
                                </Col>
                              )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Row>

                    {/* PAYMENT DETAILS*/}

                    <Row className="m-0 p-0 d-flex flex-column flex-md-row">
                      {selectedUnitData.PaymentDetails && (
                        <Col className="m-0 mt-2 p-0">
                          <h6 className="fw-bolder m-0 mb-1 mt-1 p-0">
                            Payment Details:
                          </h6>
                          <p className="m-0 p-0 fs-6">
                            {selectedUnitData.PaymentDetails}
                          </p>
                        </Col>
                      )}
                      {selectedUnitData.PaymentDueDate && (
                        <Col className="m-0 mt-2 p-0">
                          <h6 className="fw-bolder m-0 mb-1 mt-1 p-0">
                            Payment Due Date:
                          </h6>
                          <p className="m-0 p-0 fs-6">
                            {selectedUnitData.PaymentDueDate}
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row className="m-0 p-0 d-flex flex-column flex-md-row">
                      {selectedUnitData.Notes && (
                        <Col className="m-0 mt-2 p-0">
                          <h6 className="fw-bolder m-0 mb-1 mt-1 p-0">
                            Additional Notes:
                          </h6>
                          <p className="m-0 p-0 fs-6">
                            {selectedUnitData.Notes}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Row>
              </Container>
            </>
          )}

          {!selectedUnitData && (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <p className="m-0 p-0 fw-bold">
                There are no units added for this property
              </p>
            </div>
          )}
        </Col>
      )}
    </>
  );
}
