import React from 'react';
import {
    Col,
    Row
} from 'react-bootstrap';

export default function MarketplaceLegend() {
    return (
        <Row className="m-0 p-0 align-items-center" style={{maxHeight: 36}}>
            <Col className="m-0 p-0 pe-3" xs="auto">
                <Row className="m-0 p-0">
                    <Col className="p-0 pe-2 m-0" xs={"auto"}>
                        <i className="bi bi-check2 text-success" style={{fontSize: '0.80rem'}}></i>
                    </Col>
                    <Col className="p-0 m-0 d-flex align-items-center">
                        <p className="m-0 p-0" style={{fontSize: '0.80rem'}}>Request Sent</p>
                    </Col>
                </Row>

                <Row className="m-0 p-0">
                    <Col className="p-0 pe-2 m-0" xs={"auto"}>
                        <div className="m-0 p-0 position-relative d-flex">
                            <i className="bi bi-star-fill text-warning"  style={{fontSize: '0.80rem'}}></i>
                            <i className="bi bi-star text-dark position-absolute"  style={{fontSize: '0.80rem'}}></i>
                        </div>
                    </Col>
                    <Col className="p-0 m-0 d-flex align-items-center">
                        <p className="m-0 p-0" style={{fontSize: '0.80rem'}}>Favorite Building</p>
                    </Col>
                </Row>
            </Col>
            
            <Col className="m-0 p-0" xs="auto">
                <Row className="m-0 p-0">
                    <Col className="p-0 pe-2 m-0" xs={"auto"}>
                        <div className="m-0 p-0 position-relative d-flex">
                            <i className="bi bi-star-fill text-primary"  style={{fontSize: '0.80rem'}}></i>
                        </div>
                    </Col>
                    <Col className="p-0 m-0 d-flex align-items-center">
                        <p className="m-0 p-0" style={{fontSize: '0.80rem'}}>Favorite Unit</p>
                    </Col>
                </Row>

                <Row className="m-0 p-0">
                    <Col className="p-0 pe-2 m-0" xs={"auto"}>
                        <div className="m-0 p-0 position-relative d-flex">
                            <i className="bi bi-star-fill text-success"  style={{fontSize: '0.80rem'}}></i>
                        </div>
                    </Col>
                    <Col className="p-0 m-0 d-flex align-items-center">
                        <p className="m-0 p-0" style={{fontSize: '0.80rem'}}>Favorite and Requested</p>
                    </Col>
                </Row>
            </Col>  
        </Row>
    )
}
