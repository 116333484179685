import React from 'react';
import { Button } from 'react-bootstrap';


export default function ButtonPrimary({
    title,
    type="submit",
    className="w-100 rounded fw-bold",
    additionalClassName="",
    onClick=()=>{},
    disabled,
    style,
    children,
    variant="primary"
}) {
    return (
        <Button
            style={style}
            className={ `${className} ${additionalClassName}` }
            variant={variant}
            type={ type }
            disabled={disabled}
            onClick={ onClick }
        >
            { title }
            { children }
        </Button>
    )
}