import { 
    object as YupObject, 
    string as YupString, 
    boolean as YupBoolean,
    number as YupNumber 
} from "yup";

import {
    NAME, 
    SQ_FOOTAGE, 
    BEDROOM_COUNT, 
    BATHROOM_COUNT, 
    FLOOR_ID,
    RENT,
    PAYMENT_DETAILS,
    PAYMENT_DUE_DATE,
    VACANT,
    OCCUPANT_FULL_NAME,
    OCCUPANT_EMAIL,
    OCCUPANT_PHONE_NUMBER,
    OCCUPANT_LEASE_START,
    OCCUPANT_LEASE_END,
    OCCUPANT_NOTES,
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES,
    MISC_NOTES
} from "../constants/unitFormConstants";


const schema = YupObject().shape({
    [NAME]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [SQ_FOOTAGE]: YupNumber()
        .required("Required")
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Value can\'t be less than 0')
        .max(99999, 'Value can\'t be greater than 99999')
        .test(
            "maxDigitsAfterDecimal",
            "Field must have 2 digits after decimal or less",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
    [BEDROOM_COUNT]: YupNumber()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Value can\'t be less than 0')
        .max(5, 'Value can\'t be greater than 5')
        .required('Required'),
    [BATHROOM_COUNT]: YupNumber()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Value can\'t be less than 0')
        .max(5, 'Value can\'t be greater than 5')
        .required('Required'),
    [FLOOR_ID]: YupNumber()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Value can\'t be less than 0')
        .notRequired(),
    [RENT]: YupNumber()
        .required('Required')
        .transform(value => (isNaN(value) ? undefined : value))
        .min(0, 'Rent price can\'t be less than 0')
        .max(99999, 'Rent price can\'t be greater than 99999')
        .test(
            "maxDigitsAfterDecimal",
            "Rent field must have 2 digits after decimal or less",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
    [PAYMENT_DETAILS]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [PAYMENT_DUE_DATE]: YupString()
        .transform(value => value.trim())
        .required("Required"),
    [VACANT]: YupBoolean()
        .notRequired("Specify if property is vacant or occupied"),
    [OCCUPANT_FULL_NAME]: YupString()
        .when(VACANT, {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .required("Name is required"),
        }),
    [OCCUPANT_EMAIL]: YupString()
        .when(VACANT, {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .email('Invalid email address')
                .required('Required'),
        }),
    [OCCUPANT_PHONE_NUMBER]: YupString()
        .when(VACANT, {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
                    "Provide a valid phone number")
                .required("Required"),
        }),
    [OCCUPANT_LEASE_START]: YupString()
        .when(VACANT, {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
    [OCCUPANT_LEASE_END]: YupString()
        .when(VACANT, {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
    [OCCUPANT_NOTES]: YupString()
        .when(VACANT, {
            is: "Occupied",
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
    [MISC_AMENITIES]: YupString()
        .notRequired(),
    [MISC_ACCESSIBILITIES]: YupString()
        .notRequired(),
    [MISC_NOTES]: YupString()
        .notRequired(),
});

export default schema;
