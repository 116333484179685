import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
    Modal,
    Row,
    Col,
    Tab
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { favoriteActions } from '../../../store/FavoriteSlice';
import useWindowSize, { GRID_BREAKPOINTS } from '../../../hooks/useWindowSize';
import ModalStreetViewMobile from '../../Marketplace/ModalStreetViewMobile';
import BuildingInfo from './BuildingInfo';
import UnitInfo from './UnitInfo';
import ButtonPrimary from '../Buttons/ButtonPrimary';

import { UserTypeContext } from '../../../contexts/UserTypeContext';
import { AuthContext } from '../../../contexts/AuthContext';

import {
    addFavoriteBuilding,
    deleteFavoriteBuilding,
    addFavoriteUnit,
    deleteFavoriteUnit
} from '../../../services/favorites';

import { featuresActions } from "../../../store/FeaturesSlice";
import { getFeatures } from "../../../services/marketplace";

import SuccessPopup from '../InfoPopups/SuccessPopup';
import ErrorPopup from '../InfoPopups/ErrorPopup';

const TAB = Object.freeze({
    OCCUPANT: 'occupant',
    UNIT_INFO: 'unitInfo'
});


export default function ModalDetails({ show, onHide, property, showOnlyUnitWithID, isLoading, favorites, hideStreetMap=false, showAllUnits=false }) {
    const size = useWindowSize();
    const [showStreetView, setShowStreetView] = useState(false);
    const [streetViewClicked, setStreetViewClicked] = useState(false);
    const [selectedUnitID, setSelectedUnitID] = useState(null);
    const [selectedUnitData, setSelectedUnitData] = useState(null);
    const [selectedBuildingData, setSelectedBuildingData] = useState(null);
    const [streetViewAvailable, setStreetViewAvailable] = useState(true);
    const [isLoadingFeatures, setIsLoadingFeatures] = useState(false);
    const dispatch =  useDispatch();
    const features = useSelector(store => store.featuresStore);
    const { token } = useContext(AuthContext);
    const {
        userType
    } = useContext(UserTypeContext);
    const [activeTab, setActiveTab] = useState(TAB.OCCUPANT);
    const onClickTabBox = ( name ) => {
        setActiveTab(name);
    }
    useEffect(() => {
        if(show && property ) {
            setSelectedBuildingData(property);
            if(Array.isArray(property.Units)) {
                
                const showUnitWithID = property.Units.find( ({UnitID}) => String(UnitID) === String(showOnlyUnitWithID) );

                let unitData;
                if(showAllUnits) {
                    unitData = property.Units?.[0];
                } else {
                    unitData = property.Units?.find( ({Vacant}) => Vacant !== false );
                }

                if(unitData) {
                    setSelectedUnitID(showOnlyUnitWithID || unitData.UnitID);
                    setSelectedUnitData(showUnitWithID || unitData);
                }
            }
        }
    }, [show, property]);

    const isUnitSelected = (unit) => {
        const result = selectedUnitID === unit.UnitID;
        return result;
    }

    const getUnitByUnitID = (UnitID, property) => {
        return property.Units.find( (unit) => unit.UnitID === UnitID);
    }

    const showSelectedUnit = (UnitID) => {
        setSelectedUnitID(UnitID);
        setSelectedUnitData(getUnitByUnitID(UnitID, property))
    }

    const onHideHandler = () => {
        setSelectedUnitID(null);
        setSelectedUnitData(null);
        setSelectedBuildingData(null);
        setShowStreetView(false);
        setStreetViewClicked(false);

        onHide();
    }

    const toggleStreetView = useCallback(() => {
        setShowStreetView(prevState => !prevState);
    }, []);

    useEffect(() => {
        if (selectedBuildingData && selectedBuildingData.Latitude) {
            new window.google.maps.StreetViewService()
                .getPanorama({ location: { lat: selectedBuildingData.Latitude, lng: selectedBuildingData.Longitude }, radius: 50 })
                .catch(() => {
                    setStreetViewAvailable(false)
                })
        } else {
            setStreetViewAvailable(true);
        }
    }, [selectedBuildingData])

    const [isAddingToFavorites, setIsAddingToFavorites] = useState(null);
    const addOrDeleteFavorite = async (id, type, operation, token) => {

        if( typeof id !== 'number' ) throw new Error(`First argument (id) should be of type number received "${typeof token}"!`)
        if( typeof type !== 'string' ) throw new Error(`Second argument (type) should be of type number received "${typeof type}"!`)
        if( typeof operation !== 'string' ) throw new Error(`Third argument (operation) should be of type number received "${typeof operation}"!`)
        if( typeof token !== 'string') throw new Error(`Fourth argument (token) should be of type "string" received "${typeof token}"!`);

        // if( type.trim() !== 'unit' || type.trim() !== 'building') throw new Error('Second argument (type) should be of value either "building" or "unit"!');
        // if( operation.trim() !== 'add' || operation.trim() !== 'delete') throw new Error('Third argument (operation) should be of value either "add" or "delete"!');

        setIsAddingToFavorites(true);
        let result;

        switch(type) {
            case 'building':
                if(operation === 'add') result = await addFavoriteBuilding(token, id);
                else if (operation === 'delete') result = await deleteFavoriteBuilding(token, id);
                break;
            case 'unit':
                if(operation === 'add') result = await addFavoriteUnit(token, id);
                else if (operation === 'delete') result = await deleteFavoriteUnit(token, id);
                break;
            default:
                setIsAddingToFavorites(false);
                return;
        }

        const { success } = result;
        setIsAddingToFavorites(false);

        if(success) {
            SuccessPopup(operation === 'add' ? `Added ${type} to favorites list!` : `Removed ${type} from favorites list!`);

            switch(type) {
                case 'building':
                    if(operation === 'add') dispatch(favoriteActions.addFavoriteBuilding(selectedBuildingData));
                    else if (operation === 'delete') dispatch(favoriteActions.deleteFavoriteBuilding(selectedBuildingData.BuildingID));
                    break;
                case 'unit':
                    if(operation === 'add') dispatch(favoriteActions.addFavoriteUnit(selectedUnitData));
                    else if (operation === 'delete') dispatch(favoriteActions.deleteFavoriteUnit(selectedUnitData.UnitID));
                    break;
                default:
                    break;
            }
        } else {
            ErrorPopup(operation === 'add' ? "Something went wrong. Couldn't add to favorites..." : "Something went wrong. Couldn't delete from favorites...");
        }
    }

    const getFeaturesHandler = useCallback(async () => {
        if( features.isEmpty ) {
            setIsLoadingFeatures(true);
            const { success, data } = await getFeatures();
            if(success) {
                dispatch(featuresActions.setFeatures(data));
            } else {
                ErrorPopup();
            }
            setIsLoadingFeatures(false);
        }
    }, [dispatch, features.isEmpty]);

    useEffect(() => {
        if(token) {
            getFeaturesHandler();
        }
    }, [token, getFeaturesHandler]);

    return (
        <>
            {hideStreetMap && (
                <ModalStreetViewMobile
                    show={showStreetView && (size && size.width <= GRID_BREAKPOINTS.MD)}
                    onHide={toggleStreetView}
                    buildingData={selectedBuildingData}
                    streetViewAvailable={streetViewAvailable}
                />
            )}

            <Modal show={show}
                onHide={onHideHandler}
                fullscreen
                dialogClassName="modal-fullHeight"
                id="testMain"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row className="m-0 p-0">
                            <Col className="m-0 p-0 d-flex align-items-center">
                                <h5 className="m-0 p-0">
                                    {selectedBuildingData ? `` : 'Select Building'}
                                </h5>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="m-0 p-0" style={{ overflowY: 'scroll' }} >
                    
                    <Row className="m-0 p-0 d-md-none">
                        <Row className="m-0 mt-1">
                            <Col className={`p-3 text-center rounded-start ${ activeTab === TAB.OCCUPANT ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.OCCUPANT)}>
                                <h6 className="m-0 fw-bold">Building Info</h6>
                            </Col>
                            <Col className={`p-3 text-center rounded-end ${ activeTab === TAB.UNIT_INFO ? "bg-primary text-light" : "darkerGray"}`} onClick={() => onClickTabBox(TAB.UNIT_INFO)}>
                                <h6 className="m-0 fw-bold">Unit Info</h6>
                            </Col>
                        </Row>

                        <Tab.Container activeKey={activeTab}>
                            <Tab.Content className="pb-3">
                                <Tab.Pane eventKey={TAB.OCCUPANT}>
                                    <BuildingInfo 
                                        selectedBuildingData={selectedBuildingData}
                                        streetViewAvailable={streetViewAvailable}
                                        showStreetView={showStreetView}
                                        setShowStreetView={setShowStreetView}
                                        setStreetViewClicked={setStreetViewClicked}
                                        isUnitSelected={isUnitSelected}
                                        showSelectedUnit={showSelectedUnit}
                                        showOnlyUnitWithID={showOnlyUnitWithID}
                                        isLoading={isLoading}
            
                                        favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                        favorites={favorites}
                                        features={features}

                                        hideStreetMap={hideStreetMap}
                                        showAllUnits={showAllUnits}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={TAB.UNIT_INFO}>
                                    <UnitInfo 
                                        selectedUnitData={selectedUnitData}
                                        selectedBuildingData={selectedBuildingData}
                                        streetViewClicked={streetViewClicked}
                                        showStreetView={showStreetView}
                                        streetViewAvailable={streetViewAvailable}
                                        toggleStreetView={toggleStreetView}
                                        isLoading={isLoading}
            
                                        favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                                        favorites={favorites}
                                        features={features}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Row>

                    <Row className="m-0 p-0 h-100 d-none d-md-flex" >

                        <BuildingInfo 
                            selectedBuildingData={selectedBuildingData}
                            streetViewAvailable={streetViewAvailable}
                            showStreetView={showStreetView}
                            setShowStreetView={setShowStreetView}
                            setStreetViewClicked={setStreetViewClicked}
                            isUnitSelected={isUnitSelected}
                            showSelectedUnit={showSelectedUnit}
                            showOnlyUnitWithID={showOnlyUnitWithID}
                            isLoading={isLoading}

                            favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                            favorites={favorites}
                            features={features}

                            hideStreetMap={hideStreetMap}
                            showAllUnits={showAllUnits}
                        />

                        <UnitInfo 
                            selectedUnitData={selectedUnitData}
                            selectedBuildingData={selectedBuildingData}
                            streetViewClicked={streetViewClicked}
                            showStreetView={showStreetView}
                            streetViewAvailable={streetViewAvailable}
                            toggleStreetView={toggleStreetView}
                            isLoading={isLoading}

                            favoriteHandler={(id, type, operation) => addOrDeleteFavorite(id, type, operation, token)}
                            favorites={favorites}
                            features={features}
                        />

                    </Row>
                    
                </Modal.Body>

                <Modal.Footer className='m-0 p-0'>
                    <Row className="w-100 justify-content-end">
                        <Col xs={12} sm="auto">
                        <ButtonPrimary 
                            title="Close"
                            onClick={onHide}
                        />
                        </Col>
                    </Row>
                </Modal.Footer>


                {/* { showRequestBtn && (
                    <Modal.Footer className='m-0 p-0'>
                        {(isButtonDisabledCompute) && (
                            <p className="m-0 p-0 px-2 text-danger" style={{ fontSize: '.9rem' }}>
                                {userType === "Landlord" ? "Can't send a request from Landlord account!" : ''}
                                {userType === "Manager" ? "Can't send a request from Manager account!" : ''}
                                {userType === "Tenant" ? "The request was already sent!" : ''}
                                {!userType ? "Log in to send a request!" : ''}
                            </p>
                        )}
    
                        {(userType ? (
                            <Col xs={'auto'}>
                                <ButtonPrimary 
                                    title="Request a Tour"
                                    onClick={openRequestATour}
                                    disabled={isButtonDisabled()}
                                />
                            </Col>
                            ) : (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <div>
                                        <Col xs={12} sm="auto">
                                            <ButtonPrimary 
                                                title="Request a Tour"
                                                onClick={openRequestATour}
                                                disabled={isButtonDisabled()}
                                            />
                                        </Col>
                                    </div>
                                </OverlayTrigger>
                            ))
                        }
                    </Modal.Footer>
                )} */}
            </Modal>
        </>
    )
};