import { useEffect, useState } from "react";

export function PostedAgo({
  time: _time = "",
  className = "",
  text = "Posted",
}) {
  const [time, setTime] = useState(null);
  useEffect(() => {
    const now = new Date();
    const ago = new Date(_time);

    const timeDiff = Math.abs(now - ago);
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    setTime(
      new Intl.RelativeTimeFormat("en", {
        style: "long",
        numeric: "auto",
      }).format(-daysDiff, "day")
    );
  }, [_time]);
  return (
    _time && (
      <div className={`w-auto ${className}`}>
        {text} {time}
      </div>
    )
  );
}
