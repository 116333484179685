import React from 'react';
import {Row, Col} from 'react-bootstrap';

import UploadImage from '../Shared/UploadImage';


export default function ImagesForm({
    images,
    setImages
}){
    return (
        <Row className="mt-3">
            <Col>
                <UploadImage
                    images={images}
                    setImages={setImages}
                />
            </Col>
        </Row>
    )
}
