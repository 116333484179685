import React, {useState, useEffect, useContext} from 'react'
import {
    Row,
    Col,
    Modal,
    Spinner
} from 'react-bootstrap';

import MessageGenerator from '../Shared/MessageGenerator';
import CalendarCarousel from '../Shared/CalendarCarousel';
import { AuthContext } from "../../contexts/AuthContext";
import { createTicket } from '../../services/tickets';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';

import ErrorPopup from '../Shared/InfoPopups/ErrorPopup';
import SuccessPopup from '../Shared/InfoPopups/SuccessPopup';


export default function ModalTourRequest({show, closeHandler, unit, setAlreadyRequestesUnitIDs}) {
    const { token } = useContext(AuthContext);

    const [message, setMessage] = useState("");
    const [selectedDates, setSelectedDates] = useState([]);
    const [housingVoucher, setHousingVoucher] = useState(false);
    const [availability, setAvailabality] = useState(false);
    const [availabilityMessage, setAvailabilityMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const onClose = () => {
        setError('');
        closeHandler();
    }

    const getDatesInArray = (obj) => {
        if(!obj) return;

        const preparedDates = [];

        for( const [_, value] of Object.entries(obj)) {
            const day = value.day[1]; 
            const month = value.month[1]; 
            const dayOfTheMonth = value.dayOfTheMonth; 

            
            preparedDates.push(`${day}, ${month} ${addSuffixToDay(dayOfTheMonth)}`);
        }

        return preparedDates;

        function addSuffixToDay ( n_day ) {
            switch(n_day) {
                case 1:
                case 21:
                case 31:
                    return `${n_day}st`;
                case 2:
                case 22:
                    return `${n_day}nd`;
                case 3:
                    return `${n_day}rd`;
                default:
                    return `${n_day}th`;
            }
        }
    }

    const sendMessageHandler = async () => {
        if(!unit) return;

        const UnitID = unit.UnitID;

        if(selectedDates.length === 0) {
            setError("Select dates!");
            return;
        }

        if(availabilityMessage === '') {
            setError("Select availability!");
            return;
        }

        setIsLoading(true);
        
        const preparedDates = getDatesInArray(selectedDates);

        const data = {
            Text: message + "$*DATES*$" + preparedDates.join('|'),
        }

        const response = await createTicket(token, UnitID, data);
        
        if (response.success) {
            if (response.data?.Error) {
                ErrorPopup("You already sent request for this unit");

            } else {
                SuccessPopup("Success! Message has been sent.");

                setAlreadyRequestesUnitIDs( prevState => [...prevState, UnitID]);
            }

            closeHandler();
        } else {
            ErrorPopup("Error! Could not sent message. Try again later.");
        }
        
        setIsLoading(false);
    }

    useEffect(() => {
        if(unit !== null)
            setMessage(`Hello!\n\nI am interested in looking at your unit ${unit && unit.Name} at the ${unit && unit.BuildingName}.
${housingVoucher ? 'I have a housing voucher that I plan on using.' : ''}${availability ? "\n\n" + availabilityMessage : ''}\n\nThank you!`);

    }, [unit, housingVoucher, availability, availabilityMessage])

    useEffect(() => {
        //Errors reset
        if(selectedDates.length) {
            setError("");
            return;
        }

        if(availabilityMessage) {
            setError("");
            return;
        }
    }, [selectedDates, availabilityMessage])

    useEffect(() => {
        return () => {
            // setMessage("");
            setSelectedDates([]);
            setHousingVoucher(false);
            setAvailabality(false);
            setAvailabilityMessage('');
        }
    }, [show])

    const optionsBottomSettings = [
        {
            labelTitle: "Mornings are better for me for tours.",
            message: "Mornings are typically better for me for tours.",
            settedMessage: availabilityMessage,
            isDisplayed: setAvailabality,
            displayed: availability,
            messageSetter: setAvailabilityMessage
        },
        {
            labelTitle: "Afternoons are better for me for tours.",
            message: "Afternoons are typically better for me for tours.",
            settedMessage: availabilityMessage,
            isDisplayed: setAvailabality,
            displayed: availability,
            messageSetter: setAvailabilityMessage
        },
        {
            labelTitle: "Evenings are better for me for tours.",
            message: "Evenings are typically better for me for tours.",
            settedMessage: availabilityMessage,
            isDisplayed: setAvailabality,
            displayed: availability,
            messageSetter: setAvailabilityMessage
        },
        {
            labelTitle: "I am available the whole day",
            message: "I am available whole day.",
            settedMessage: availabilityMessage,
            isDisplayed: setAvailabality,
            displayed: availability,
            messageSetter: setAvailabilityMessage
        },
    ];

    const optionsBottom = (
        <>
            {   optionsBottomSettings.map( ({isDisplayed, labelTitle, message, messageSetter, settedMessage, displayed}) =>  (
                <Col className="m-0 mb-1 p-0" xs={12} key={labelTitle}>
                    <label className="checkboxContainer fs-6">{labelTitle}
                        <input type="checkbox" name="housingVoucher" onChange={ (e) => {
                            isDisplayed(e.target.checked);
                            messageSetter && messageSetter(message)
                        }}
                        checked={settedMessage && displayed && message === availabilityMessage }
                        />
                        <span style={{ width: '1.25rem', height: '1.25rem'}} className="customCheckmark"></span>
                    </label>
                </Col>))
            }
            <CalendarCarousel selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
        </>
    )
    
    return (
        <Modal show={show} fullscreen={true} onHide={onClose}>
            <Modal.Header closeButton />

            <Modal.Body
                className="d-flex flex-column align-items-center"
            >
                <MessageGenerator 
                    message={message}
                    setMessage={setMessage}
                    optionsBottom={optionsBottom}
                />

            </Modal.Body>

            <Modal.Footer>
                <Row className="m-0 p-0 w-100">
                    <Col className="m-0 p-0" xs={'auto'}>
                        <ButtonSecondary
                            title={'Cancel'}
                            onClick={onClose}
                        />
                    </Col>

                    <Col className="m-0 p-0 d-flex align-items-center justify-content-end" >
                        <p className="m-0 p-0 px-2 text-danger" style={{fontSize: '.85rem'}}>{error}</p>
                    </Col>

                    <Col className="m-0 p-0 d-flex justify-content-end" xs={'auto'}>
                        <ButtonPrimary 
                            onClick={sendMessageHandler}
                            title={'Send Message'}
                        />
                    </Col>
                </Row>
            </Modal.Footer>

            { isLoading && (
                <Row className="position-absolute w-100 h-100 m-0 p-0 d-flex justify-content-center align-items-center" style={{background: 'rgba(0,0,0,0.1)'}}>
                    <Spinner animation="border" role="status"/>
                </Row> 
                )
            }
        </Modal>
    )
}