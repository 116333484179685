import React from 'react'
import {
    Col,
    Row
} from 'react-bootstrap';

import InputFloating from '../Shared/Inputs/InputFloating';
import PhoneWithMask from '../Shared/Inputs/PhoneWithMask';
import SelectFloating from '../Shared/Inputs/SelectFloating';
import { STATES } from '../../constants/Shared/states';

export default function TenantInformations({ register, errors }) {
    return (
        <Col className="position-relative flex-grow-1 p-3 montserrat">    

            <Row className="m-0 p-0 w-100">

                <Col xs={12} sm={6} lg={4} className="m-0 p-0 pb-2 pe-sm-2">
                    <InputFloating 
                        register={register("FirstName")}
                        label="First Name"
                        showError={true}
                        errorMessage={errors.FirstName?.message}
                    />
                </Col>

                <Col xs={12} sm={6} lg={4} className="m-0 p-0 pb-2">
                    <InputFloating 
                        register={register("LastName")}
                        label="Last Name"
                        showError={true}
                        errorMessage={errors.LastName?.message}
                    />
                </Col>

                <Col xs={12}></Col>

                <Col xs={12} lg={6} className="m-0 p-0 pb-2 pe-lg-2">
                    <InputFloating 
                        register={register("AddressLine1")}
                        label="Address"
                        showError={true}
                        errorMessage={errors.AddressLine1?.message}
                    />
                </Col>

                <Col xs={12} lg={6} className="m-0 p-0 pb-2">
                    <InputFloating 
                        register={register("AddressLine2")}
                        label="Address Line 2"
                        showError={true}
                        errorMessage={errors.AddressLine2?.message}
                    />
                </Col>

                <Col xs={12}></Col>

                <Col xs={12} sm={8} lg={7} className="m-0 p-0 pb-2 pe-sm-2">
                    <InputFloating 
                        register={register("City")}
                        label="City"
                        showError={true}
                        errorMessage={errors.City?.message}
                    />
                </Col>

                <Col xs={12} sm={4} lg={2} className="m-0 p-0 pb-2 pe-sm-2">
                    <SelectFloating 
                        label="State"
                        aria-label="Select state" 
                        register={register("State")}
                        showError={true}
                        errorMessage={errors.State?.message}
                        optionEmpty={true}
                        dataArray={STATES.split(",")}
                    />
                </Col>

                <Col xs={12} md={4} lg={3} className="m-0 p-0 pb-2">
                    <InputFloating 
                        register={register("Zip")}
                        label="Zip"
                        showError={true}
                        errorMessage={errors.Zip?.message}
                    />
                </Col>
                
                <Col xs={12}></Col>

                <Col xs={12} sm={6} className="m-0 p-0 pb-2 pb-sm-0 pe-sm-2">
                    <PhoneWithMask 
                        register={register("Phone")}
                        label="Phone Number"
                        showError={true}
                        errorMessage={errors.Phone?.message}
                    />
                </Col>
                <Col xs={12} sm={6} className="m-0 p-0">
                    <InputFloating 
                        register={register("Email")}
                        label="Email"
                        showError={true}
                        errorMessage={errors.Email?.message}
                        type="email"
                    />
                </Col>

            </Row>
        </Col>  
    )
}
