import { indexOf } from 'lodash';
import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import LazyImage from "../Shared/LazyImage";
import NoStreetViewPhoto from '../Shared/NoStreetViewPhoto';

const LEFT = 'left';
const RIGHT = 'right';

export default function MultiCarousel({ images, favoritePhotoIndex, data }) {
    const sliderRef = useRef(null);
    const [sliderIndex, setSliderIndex] = useState(0);
    const onHandleClick = (direction) => {
        if (sliderRef) {
            const itemsPerScreen = getComputedStyle(sliderRef.current).getPropertyValue("--items-per-screen");
            const imagesLength = images.length;
            const maxIndex = Math.ceil(imagesLength / itemsPerScreen);

            if (direction === LEFT) {
                const nextSliderIndex = sliderIndex - 1;
                if (nextSliderIndex >= 0) {
                    setSliderIndex(nextSliderIndex);
                    sliderRef.current.style.setProperty("--slider-index", nextSliderIndex);
                }
            } else if (direction === RIGHT) {
                const nextSliderIndex = sliderIndex + 1;
                if (nextSliderIndex < maxIndex) {
                    setSliderIndex(nextSliderIndex);
                    sliderRef.current.style.setProperty("--slider-index", nextSliderIndex);
                }
            }
        }
    }



    return (
        <Row className="m-0 p-0 multiCarousel__container justify-content-center overflow-hidden flex-grow-1" style={{ minHeight: 100 }}>
            <Col className="m-0 p-0 multiCarousel__handle multiCarousel__handle-left" onClick={() => onHandleClick(LEFT)}>
                <i className="bi bi-chevron-compact-left"></i>
            </Col>

            <Col className="m-0 p-0 multiCarousel__slider" ref={sliderRef}>
                {!images && (
                    <div className="multiCarousel__imgContainer position-relative">
                        <NoStreetViewPhoto />
                    </div>
                )}
                {images && images.map((img, index) => (

                        <div className="multiCarousel__imgContainer position-relative" key={index}>
                            <LazyImage
                                key={indexOf}
                                style={{ objectFit: "cover", height: '100%', width: '100%' }}
                                src={img}
                            />
                            {favoritePhotoIndex === index && (
                                <div className="mx-1 p-1 position-absolute d-flex justify-content-center align-items-center start-0 top-0" style={{ zIndex: 1 }}>
                                    <i className="bi bi-star-fill p-1 bg-dark rounded text-warning"></i>
                                </div>
                            )}
                        </div>
                    )
                )}
            </Col>

            <Col className="m-0 p-0 multiCarousel__handle multiCarousel__handle-right" onClick={() => onHandleClick(RIGHT)}>
                <i className="bi bi-chevron-compact-right"></i>
            </Col>
        </Row>
    )
}