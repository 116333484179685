import React from 'react';
import {
    Row,
    Col,
    Form
} from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import DecisionModal from '../Shared/InfoPopups/DecisionModal';
import InputFloating from '../Shared/Inputs/InputFloating';


export default function PreviousEviction({
    index, 
    editMode, 
    data, 
    onDelete, 
    canDelete=true,
    prefix,
    register,
    errors,
    watch
}) {
    const DescriptionWatch = watch(`${prefix}Description`, "");

    const getError = errors.PreviousEvictions?.[index];

    const deleteHandler = async () => {
        const userDecision = await DecisionModal({})

        if ( !userDecision.isConfirmed ) return;

        onDelete(index);
    }

    return (
        <Row className="p-0 m-0 pt-2 ps-md-3">
            { !editMode &&
                <Row className="m-0 p-0 position-relative row-cols-1">
                
                    <Col className="m-0 p-0">
                        <p className="m-0 "><b>{data && data.Date ? 'Date: ' : ''}</b>{data && data.Date}</p>
                    </Col>

                    <Col className="m-0 p-0">
                        <p className="m-0 ">{data && data.Description}</p>
                    </Col>

                </Row>
            }

            { editMode &&
                <Row className="m-0 p-3">

                    <Col className="m-0 p-0 me-2 " xs={12}>
                
                        { (canDelete && editMode) &&
                            <Row className="justify-content-end">
                                <Col xs="auto">
                                    { (canDelete && editMode) &&
                                        <ButtonPrimary 
                                            onClick={ deleteHandler }
                                            additionalClassName="bg-danger border-danger"
                                            type="button"
                                        >
                                            <i className="bi bi-x-lg"></i>
                                        </ButtonPrimary>
                                    }
                                </Col>
                            </Row>
                        }
                    </Col>

                    <Form >
                        <Row>
                            <Col className="m-0 p-0 py-2" xs={12} sm={6} md={4} lg={3}>
                                <InputFloating 
                                    label="Approximate date"
                                    register={register(`${prefix}Date`)}
                                    showError={true}
                                    errorMessage={getError?.Date?.message}
                                />
                            </Col>
                            
                            <Col className="m-0 p-0" xs={12}>
                                <InputFloating 
                                    label={DescriptionWatch ? "Description" : "Please describe what happened during this eviction. This helps landlords get a better sense of your side of the story (optional)"}
                                    register={register(`${prefix}Description`)}
                                    showError={true}
                                    errorMessage={getError?.Description?.message}
                                />
                            </Col>
                        </Row>   
                    </Form>
                </Row>
            }
        </Row>
    )
}
