import React from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "react-bootstrap";

import ButtonPrimary from "../Shared/Buttons/ButtonPrimary";


export default function FavoriteBoxLoader() {
    return (
      <Container fluid className="m-0 p-0 mb-3 d-flex justify-content-center shadow-sm">
        <Card className="m-0 p-0 border-0  w-100" style={{ height: "100%" }}>
          <Card.Body className={`m-0 p-0 lightGray rounded overflow-hidden w-100`} style={{ transition: '.3s'}}>
            <Row className="m-0 p-0 w-100">

              <Col className="d-flex position-relative align-items-center justify-content-center m-0 p-0 ps-2 ps-sm-3" xs={'auto'}>
                <span className="skeleton rounded"><i className={`bi bi-building fs-3 opacity-0`}></i></span>
              </Col>

              <Col className="px-2 px-sm-3 m-0 d-flex flex-column justify-content-start ">
                <Row className="p-0 m-0 d-flex align-items-center py-3">
                  
                    <Row className="m-0 p-0">

                      <Col className="m-0 p-0">
                        <h5 className="d-inline p-0 m-0 text-dark cursor-pointer skeleton rounded">
                          <span className="opacity-0">Name</span>
                        </h5>
                    
                        <p className="m-0 p-0 text-muted" style={{ fontSize: '0.85rem' }}>
                          <span className="text-muted skeleton rounded"><i className="bi bi-geo-alt opacity-0"></i><span className="opacity-0">&nbsp;Address</span></span>
                        </p>

                        <p className="m-0 p-0 fs-6 text-dark">
                          <span className="skeleton rounded"><span className="opacity-0">Rent: from $---</span></span>
                        </p>
                      </Col>
                    </Row>

                </Row>
              </Col>

              <Col className="d-flex jusity-content-center align-items-center m-0 p-0 px-2 px-sm-3" xs={'auto'}>
                <ButtonPrimary
                  additionalClassName="ms-1 skeleton border-light"
                  disabled
                ><p className="m-0 p-0 opacity-0">Open</p></ButtonPrimary>

                <ButtonPrimary
                  additionalClassName="ms-1 skeleton border-light"
                  disabled
                > 
                  <i className="bi bi-x-lg text-white opacity-0"></i>
                </ButtonPrimary>
              </Col>

        
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
