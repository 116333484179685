import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    Container
} from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CreatePortfolioContext } from '../contexts/CreatePortfolioContext';
import schema from '../schemas/tenantScreeningSchema';

import { 
    getOwnProfile
} from '../services/tenantProfile';
import {AuthContext} from '../contexts/AuthContext';

import Loader from '../components/TenantScreening/Loader';
import GenerateReport from '../components/TenantScreening/GenerateReport';
import UserReport from '../components/TenantScreening/UserReport';
import IframeSection from '../components/TenantScreening/IframeSection';
import DataSection from '../components/TenantScreening/DataSection';

import { getApplication, sendApplication } from '../services/tenantScreening';
import ErrorPopup from '../components/Shared/InfoPopups/ErrorPopup';

const TABS = {
    LOADING: 'loading',
    GENERATE_REPORT: 'generateAReport',
    USER_REPORT: 'userReport',
    PERSONAL_DATA: 'personalData',
    IFRAME: 'iframe'
}

const ERROR_MESSAGES_TT = {
    NO_APPLICATION: 'no application initiated',
}


//TODO: add errors when success if false
export default function TenantScreening() {
    const { token } = useContext(AuthContext);
    const [ tab, setTab ] = useState(TABS.LOADING);
    const [ iframeLink, setIframeLink ] = useState(null);
    const [ termsAccept, setTermsAccept ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);

    const { 
        setTenantData,
        tenantData
    } = useContext(CreatePortfolioContext);

    const formOptions = { resolver: yupResolver(schema), mode: "onChange" };
    const { register, handleSubmit, reset, formState, } = useForm(formOptions);
    const { errors } = formState;

    const sendScreeningRequestHandler = async ( userData, token ) => {
        setIsLoading(true);
        const requestBody = {
            emailAddress: userData.Email,
            firstName: userData.FirstName,
            lastName: userData.LastName,
            phoneNumber: extractPhoneNumber(userData.Phone),
            businessAddress: {
                addressLine1: userData.AddressLine1,
                addressLine2: userData.AddressLine2,
                locality: userData.City,
                region: userData.State,
                postalCode: userData.Zip
            }
        }
        
        const response = await sendApplication(requestBody, token);
        

        const {success, data} = response;
        if ( success ) {
            if (!data.error) {
                setIframeLink(data.url);
                setTab(TABS.IFRAME);
            }
        }
        setIsLoading(false);
        
        function extractPhoneNumber (number) {
            return number.replace(/[^0-9]/g, '');
        }
    }

    const dataSectionHandler = async () => {
        handleSubmit( async (data) => {
            sendScreeningRequestHandler(data, token);
        })();
    }

    const getOwnProfileHandler = useCallback(async () => {
        const response = await getOwnProfile(token);

       
        
        if (response.success) {
            const defaultData = {
                Employments: [],
                FormerLandlords: [],
                PreviousEvictions: [],
                ...response.data
            }

            Object.entries(defaultData).forEach(([key, value]) => {
                if ( value === null || value === undefined  ) {
                    if(key === 'Employments' || key === 'FormerLandlords' || key === "PreviousEvictions") {
                        defaultData[key] = [];
                    } else {
                        defaultData[key] = '';
                    }
                }
            })

            const { Employments } = defaultData;

            if (Employments.length) {
                const preparedEmployments = Employments && Employments.map( (employment) => {
                    const { Description, Duration, Employer, IsCurrentPoistion, Location, Position } = employment;
                    return { 
                        Description, 
                        Duration, 
                        Employer, 
                        IsCurrentPoistion, 
                        Location, 
                        Position, 
                        JobStart: splitJobDuration(Duration).JobStart,
                        JobEnd: splitJobDuration(Duration).JobEnd
                    }
                })

                defaultData.Employments = preparedEmployments;
            }

            setTenantData(defaultData);
        } else {
            ErrorPopup("Error! Could not get profile data. Try again later.");
        }

        function splitJobDuration (duration) {
            if(!duration || typeof duration !== 'string') return {JobStart: '', JobEnd: ''};
            const splittedTime = duration.split('-');
            if(splittedTime.length <= 1) return {JobStart: '', JobEnd: ''};
            let jobStart = splittedTime[0].trim();
            let jobEnd = splittedTime[1].trim();
    
            return {JobStart: jobStart.trim(), JobEnd: jobEnd.trim()};
        }
    }, [setTenantData, token]);

    const getApplicationHandler = async () => {
        const result = await getApplication(token);
        const { data } = result;
        

        if( data.error === ERROR_MESSAGES_TT.NO_APPLICATION) {
            setTab(TABS.GENERATE_REPORT);
        }

        // application was initialized
        if ( !data.error ) {
            setTab(TABS.IFRAME);
            setIframeLink(data.url)
        }
    }

    useEffect(() => {
        //TODO: when report endpoint will be ready then check if report is setted, if
        // yes then omit if's below

        if(tenantData && tenantData.City) {
            const defaultValues = {
                FirstName: tenantData.FirstName || '',
                LastName: tenantData.LastName || '',
                City: tenantData.City || '',
                State: tenantData.State || '',
                Phone: tenantData.Phone || '',
                Email: tenantData.Email || ''
            }

            reset(defaultValues);
        }
        else {
            getOwnProfileHandler();
        }

    }, [tenantData, reset])

    useEffect(() => {
        getApplicationHandler();
    }, [])

    return (
        <>
            <Container fluid className="p-0 pb-4 m-0 my-3 my-sm-5 mx-auto lightGray  rounded" style={{maxWidth: "1200px"}}>
            
                {   tab === TABS.LOADING && (
                        <Loader />
                    )   
                }

                {   tab === TABS.GENERATE_REPORT && (
                        <GenerateReport setTab={ () => setTab(TABS.PERSONAL_DATA) } />
                    )
                }

                {   tab === TABS.USER_REPORT && (
                        <UserReport reportValidDays={60}/>
                    )
                }

                {   tab === TABS.PERSONAL_DATA && (
                        <DataSection 
                            continueBtnHandler={ dataSectionHandler }
                            backBtnHandler={ () => setTab(TABS.GENERATE_REPORT) }
                            register={register}
                            errors={errors}

                            termsAcceptHandler={() => setTermsAccept( prevState => !prevState)}
                            termsAccept={termsAccept}
                            isLoading={isLoading}
                        />
                    )
                }

                {   tab === TABS.IFRAME && (
                        <IframeSection iframeLink={iframeLink} />
                    )
                }

            </Container>
        </>
    )
}
