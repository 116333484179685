import React, { useContext, useState, useLayoutEffect, useRef } from "react";
import { Container, Row, Col, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import { UserTypeContext } from "../contexts/UserTypeContext";
import UserTypeTile from "../components/Signup/UserTypeTile";
import Footer from "../components/Shared/Footer";
import CaseIcon from "../assets/user_icons/case.png"
import LandlordIcon from "../assets/user_icons/landlord.png"
import NavigatorIcon from "../assets/user_icons/navigator.png"
import PropertyIcon from "../assets/user_icons/property.png"
import TenantIcon from "../assets/user_icons/tenant.png"
import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";
import useWindowSize, { GRID_BREAKPOINTS } from "../hooks/useWindowSize";

const userTypes = {
  tenant: {
    title: "Tenant",
    description: "A tenant can add information about themselves, get a credit check, and message landlords.",
    src: TenantIcon,
    classes: "top-100 start-0 translate-bottom-left",
    interaction: true
  },
  landlord: {
    title: "Landlord",
    description: "A landlord can add and post units on the marketplace. They can reply to messages from tenants.",
    src: LandlordIcon,
    classes: "top-100 start-100 translate-bottom-right",
    interaction: true
  },
  manager: {
    title: "Property Manager",
    description: "A property manager can add units from properties assigned to them and reply to messages from tenants.",
    src: PropertyIcon,
    classes: "top-0 start-100 translate-top-right",
    interaction: true
  },
  casemanager: {
    title: "Case Manager",
    src: CaseIcon,
    classes: "top-0 start-0 translate-top-left ",
    interaction: false,
    description: "A case manager can create groups of tenants and send notifications about available units to them.",
    tooltip: "Coming soon!"
  },
  navigator: {
    title: "Housing Navigator",
    src: NavigatorIcon,
    classes: "top-50 start-50 translate-middle",
    interaction: false,
    tooltip: "Coming soon!"
  },
  
}

const Signup = () => {
  const { setUserType } = useContext(UserTypeContext);

  const handleClick = (user) => {
    setUserType(user);
  };
  const [type, setType] = useState(null);

  const [select, setSelect] = useState("tenant");

  const [userModal, setUserModal] = useState(false)

  const handleClose = () => setUserModal(false)

  const handleOpen = () => setUserModal(true)

  const setHandle = (k) => {
    setSelect(k)
    handleOpen()
  }

  useLayoutEffect(() => {
    if (type) {
      const elArr = document.getElementsByClassName(type);
      [].forEach.call(elArr, (el) => {
        el.classList.add("active")
      })
    }
    return () => {
      const activePaths = document.querySelectorAll(".active")
      if (activePaths) {
        [].forEach.call(activePaths, (el, i) => {
          el.classList.remove("active");
        })
      }
    }
  }, [type])

  const size = useWindowSize();

  return (
    <>
      <div className="custom_bg d-flex align-items-center justify-content-center">
        <Container fluid style={{ maxWidth: 1200 }} className="p-0 m-auto">

          <Row className="m-0 p-2 py-sm-5 d-flex align-items-center justify-content-center" >
            <Col className="m-0 p-3 py-5 px-sm-5 bg-white-opaque rounded justify-content-center" xs={12}>

              <Row className="m-0 p-0 mb-4">
                <Col className="m-0 p-0" xs={12}>
                  <h3 className="m-0 p-0 fw-bold text-center">Create your WISSP Account</h3>
                </Col>
              </Row>

              <Row className=" justify-content-center" style={{ margin: '0 -0.5rem 0 -0.5rem' }}>
                {size.width <= GRID_BREAKPOINTS.LG ? <>
                  <UserTypeTile
                    onClick={() => handleClick("Tenant")}
                    title="I'm a Tenant"
                    description="A tenant can add information about themselves, get a credit check, and message landlords."
                  />

                  <UserTypeTile
                    onClick={() => handleClick("Landlord")}
                    title="I'm a Landlord"
                    description="A landlord can add and post units on the marketplace. They can reply to messages from tenants."
                  />

                  <UserTypeTile
                    onClick={() => handleClick("Manager")}
                    title="I'm a Property Manager"
                    description="A property manager can add units from properties assigned to them and reply to messages from tenants."
                  />

                  {/* <UserTypeTile
                    onClick={() => handleClick("casemanager")}
                    title="I'm a Case Manager"
                    description="A case manager can create groups of tenants and send notifications about available units to them."
                  /> */}

                </>
                  :
                  <Col className="" style={{ height: 600 }}>
                    <div className="h-100 m-auto position-relative" style={{ width: "90%" }}>
                      {
                        Object.keys(userTypes).map((key, i) => {
                          return (
                            <>
                              <UserType
                                title={userTypes[key].title}
                                description={userTypes[key].description}
                                src={userTypes[key].src}
                                classes={userTypes[key].classes}
                                typeKey={key}
                                hover={setType}
                                setter={setHandle}
                                interaction={userTypes[key].interaction}
                                delay={i}
                                toolTipText={userTypes[key].tooltip}
                              />
                            </>
                          )
                        })
                      }
                      <UserMap />
                      <Modal
                        show={userModal}
                        onHide={handleClose}
                        centered
                      >
                        <Modal.Body>
                          <Row>
                            <Col xs="auto" className="d-flex align-items-center justify-content-center">
                              <img src={userTypes[select].src} height="100" className="m-auto" alt=""/>
                            </Col>
                            <Col>
                              <p className="fw-bold">
                                {userTypes[select].title}
                              </p>
                              <p className="">
                                {userTypes[select].description}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="w-auto float-end">
                                <ButtonSecondary additionalClassName="m-auto w-auto" onClick={handleClose}>
                                  Close
                                </ButtonSecondary>
                                <ButtonPrimary additionalClassName="ms-2 w-auto" onClick={() => {
                                  setUserType(() => select.charAt(0).toUpperCase() + select.slice(1))
                                }}>
                                  <Link to="/signup-details" className="wNav colorWhite colorWhiteHover">
                                    Register
                                  </Link>
                                </ButtonPrimary>
                              </div>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </Col>
                }

              </Row>
            </Col>
          </Row>

        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Signup;



function UserType(props) {
  const { src = "", classes = "", title = "", typeKey, interaction, delay = 0, toolTipText = "" } = props;
  const userRef = useRef(null);

  useLayoutEffect(() => {
    let timer;
    if (userRef.current != null) {
      timer = setTimeout(() => {
        userRef.current.classList.add("done")
      }, 200 + 200 * delay)
    }
    return ()=>{
      clearTimeout(timer);
    }
  }, [userRef, delay])

  const imgStyle = {
    height: 100
  }

  return (
    <>
      {
        toolTipText ?
          <div
            onMouseOver={() => props.hover(typeKey)}
            onMouseOut={() => props.hover(null)}
            onClick={() => {
              if (interaction) {
                props.setter(typeKey)
              }
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{toolTipText}</Tooltip>}
              delay={{ show: 250, hide: 400 }}
            >
              <div
                className={`position-absolute ${classes} ${interaction ? "userType" : "userTypeNone"} userPopIn`}
                style={{ width: 200, zIndex: 5 }}
              >
                <div
                  className="userTypeInit"
                  ref={userRef}
                >
                  <img src={src} style={imgStyle} className="m-auto d-block" alt=""/>
                  <p className="text-center fw-bold">{title}</p>
                </div>
              </div>
            </OverlayTrigger>
          </div>
          :
          <div
            className={`position-absolute ${classes} ${interaction ? "userType" : "userTypeNone"} userPopIn`}
            style={{ width: 200, zIndex: 5 }}
            onMouseOver={() => props.hover(typeKey)}
            onMouseOut={() => props.hover(null)}
            onClick={() => {
              if (interaction) {
                props.setter(typeKey)
              }
            }}
          >
            <div
              className="userTypeInit"
              ref={userRef}
            >
              <img src={src} style={imgStyle} className="m-auto d-block" alt=""/>
              <p className="text-center fw-bold">{title}</p>
            </div>
          </div>
      }
    </>
  )
}



function UserMap() {
  const defaults = {
    start: 50,
    end: 830,
    top: 50,
    bottom: 520,
    center_y: 270,
    center_x: 440,
  }
  const positions = {
    tenant: {
      x: defaults.start,
      y: defaults.bottom,
    },
    case: {
      x: defaults.start,
      y: defaults.top,
    },
    navigator: {
      x: defaults.center_x,
      y: defaults.center_y,
    },
    manager: {
      x: defaults.end,
      y: defaults.top,
    },
    landlord: {
      x: defaults.end,
      y: defaults.bottom,
    },
  }
  return (
    <svg className="position-absolute top-50 start-50 translate-middle userMapFadeIn" xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="90%"
      viewBox="0 0 900 600"
      preserveAspectRatio="none"
      stroke="black" stroke-dasharray="8 4" stroke-width="5" fill="none"
      strokeDashoffset={0}
    >
      <mask id="icons">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        {/* Case */}
        <rect x="0" y="0" width="150" height="130" rx="30" fill="black" />
        {/* Property */}
        <rect x="750" y="0" width="150" height="130" rx="30" fill="black" />
        {/* Tenant */}
        <rect x="0" y="450" width="150" height="130" rx="30" fill="black" />
        {/* Landlord */}
        <rect x="750" y="450" width="150" height="130" rx="30" fill="black" />
        {/* Navigator */}
        <rect x="360" y="240" width="150" height="130" rx="30" fill="black" />
      </mask>
      <g mask="url(#icons)">
        <path
          d={`
            M ${positions.case.x} ${positions.case.y}
            Q -40 300
            ${positions.tenant.x} ${positions.tenant.y}
        `}
          className="case tenant pathReg"
        />
        <path
          d={`
            M ${positions.case.x} ${positions.case.y}
            Q 450 100
            ${positions.manager.x} ${positions.manager.y}
        `}
          className="case manager pathReg"
        />
        <path d={`
          M ${positions.case.x} ${positions.case.y}
          Q 100 300
          ${positions.navigator.x} ${positions.navigator.y}
        `}
          className="case navigator pathReg"
        />
        <path
          d={`
            M ${positions.navigator.x} ${positions.navigator.y}
            Q 250 250
              250 400
            T ${positions.tenant.x} ${positions.tenant.y}
          `}
          className="tenant navigator pathReg"
        />
        <path
          d={`
            M ${positions.tenant.x} ${positions.tenant.y}
            Q 850 600
            ${positions.manager.x} ${positions.manager.y}
          `}
          className="tenant manager pathReg"
        />
        <path
          d={`
            M ${positions.tenant.x} ${positions.tenant.y}
            Q 450 600
            ${positions.landlord.x} ${positions.landlord.y}
          `}
          className="tenant landlord pathReg"
          />
        <path
          d={`
            M ${positions.navigator.x} ${positions.navigator.y}
            Q 800 300
            ${positions.manager.x} ${positions.manager.y}
          `}
          className="navigator manager pathReg"
          />
        <path
          d={`
            M ${positions.navigator.x} ${positions.navigator.y}
            Q 600 250
              600 400
            T ${positions.landlord.x} ${positions.landlord.y}
          `}
          className="navigator landlord pathReg"
          />
        <path
          d={`
            M ${positions.landlord.x} ${positions.landlord.y}
            Q 900 300
            ${positions.manager.x} ${positions.manager.y}
          `}
          className="landlord manager pathReg"
          />
        <path
          d={`
            M ${positions.landlord.x} ${positions.landlord.y}
            Q 50 600
            ${positions.case.x} ${positions.case.y}
          `}
          className="landlord case pathReg"
          />
      </g>
    </svg>
  )
}


