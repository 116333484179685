import React, {useEffect} from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString, array as YupArray } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from "react-hook-form";

import ManagersFields from '../BuildingFormElements/ManagersFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import {
    LANDLORD_CONTACT_FULLNAME,
    LANDLORD_CONTACT_EMAIL,
    LANDLORD_CONTACT_PHONE,
    MANAGERS,
    MANAGER_ID,
    MANAGER_FULL_NAME,
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME,
    MANAGER_EMAIL,
    MANAGER_PHONE,
    LANDLORD_CONTACT_ID,
} from '../../constants/buildingFormConstants';

const schema = YupObject().shape({
    [LANDLORD_CONTACT_FULLNAME]: YupString()
        .notRequired()
        .transform(value => value.trim()),
    [LANDLORD_CONTACT_EMAIL]: YupString()
        .email("Email must be a valid email")
        .transform(value => value.trim())
        .notRequired(),
    [LANDLORD_CONTACT_PHONE]: YupString()
        .transform(value => value.trim())
        .notRequired(),

    [MANAGERS]: YupArray().of(
        YupObject().shape({
            [MANAGER_FIRST_NAME]: YupString()
                .required('Required')
                .transform(value => value.trim()),
            [MANAGER_LAST_NAME]: YupString()
                .required('Required')
                .transform(value => value.trim()),

            [MANAGER_EMAIL]: YupString()
                .required('Required')
                .email("Provide a valid email")
                .transform(value => value.trim()),
            [MANAGER_PHONE]: YupString()
                .required('Required')
                .transform(value => value.trim())
                .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 
                    "Provide a valid phone number"),
        })
    ),
});


export default function ManagersForm({
    sectionFocus, 
    setSectionFocus, 
    onSubmitManagersInfo,
    building,
    onRemoveManager
}) {
    // console.log('[ManagerForm] building: ', building);

    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, handleSubmit, formState, control, reset } = useForm(formOptions);
    const { errors, isDirty } = formState;
    const { fields: managerFields,
        append: managerAppend,
        remove: managerRemove } = useFieldArray({ name: MANAGERS, control })

    const addManager = () => {
        managerAppend({
            [MANAGER_EMAIL]: '',
            [MANAGER_PHONE]: '',
            [MANAGER_FIRST_NAME]: '',
            [MANAGER_LAST_NAME]: '',
        })
    }

    const removeManager = (index) => {
        managerRemove(index);
    }

    const removeManagerFromDB = async (managerID, index) => {
        if(!managerID) {
            removeManager(index);
            return
        }
            
        onRemoveManager(managerID);
    }

    const prepareManagerDataForForm = (managers) => {
        if(!managers) throw new Error('Missing argument!');

        return managers.map( manager => {
            const splittedName = manager[MANAGER_FULL_NAME].split("$*|*$");

            return {
                ...manager,
                [MANAGER_FIRST_NAME]: splittedName[0] || manager[MANAGER_FULL_NAME],
                [MANAGER_LAST_NAME]: splittedName[1] || ""
            }
        })
    }

    useEffect(() => {
        if (building) {
            const userEmail = localStorage.getItem('userEmail');

            const defaultValues = {
                [MANAGERS]: building[MANAGERS] || [],
                [LANDLORD_CONTACT_FULLNAME]: 'self',
                [LANDLORD_CONTACT_EMAIL]: userEmail,
                [LANDLORD_CONTACT_PHONE]: '',
                [LANDLORD_CONTACT_ID]: null,
            };

            const Managers = building[MANAGERS];
            if (Managers && Managers.length) {
                const landlordData = [];
                const managersData = [];

                Managers.forEach(manager => {
                    if (manager[MANAGER_EMAIL] === userEmail) {
                        landlordData.push(manager);
                    } else {
                        managersData.push(manager);
                    }
                })

                if (landlordData.length) {
                    defaultValues[LANDLORD_CONTACT_FULLNAME] = landlordData[0][MANAGER_FULL_NAME];
                    defaultValues[LANDLORD_CONTACT_EMAIL] = landlordData[0][MANAGER_EMAIL];
                    defaultValues[LANDLORD_CONTACT_PHONE] = landlordData[0][MANAGER_PHONE];
                    defaultValues[LANDLORD_CONTACT_ID] = landlordData[0][MANAGER_ID];
                }

                defaultValues[MANAGERS] = prepareManagerDataForForm(managersData);
            }

            reset(defaultValues);
        }

    }, [building, reset]);

    return (
        <Form
            onSubmit={handleSubmit(onSubmitManagersInfo)}
            className={sectionFocus && sectionFocus !== "Managers" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus&&setSectionFocus("Managers") }}
            onMouseLeave={() => { setSectionFocus&&setSectionFocus(null) }}
        >   
            <ManagersFields 
                f_register={register}
                o_errors={errors}
                a_managerFields={managerFields}
                f_addManager={addManager}
                f_removeManagerFromDB={removeManagerFromDB}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        disabled={ !isDirty }
                        type="submit"
                        title="Update Property Contact Information"
                    />
                </Col>
            </Row>
        </Form>
    )
}
