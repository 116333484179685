import { getRequest, postPutRequest } from './http-common';

const getTickets = async (token, userType) => {
    const URL = `ticket/${userType}`;

    return await getRequest(URL, token);
}

const getUnitTickets = async (token, unitID) => {
    const URL = `ticket/unit/${unitID}`;

    return await getRequest(URL, token);
}

const createTicket = async (token, unitID, data) => {
    const URL = `ticket/${unitID}/create`;

    return await postPutRequest(URL, data, token, 'post');
}

const respondToTicket = async (token, ticketID, data) => {
    const URL = `ticket/${ticketID}/respond`;

    return await postPutRequest(URL, data, token, 'post');
}

export { getTickets, getUnitTickets, createTicket, respondToTicket };
