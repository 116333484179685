import React from 'react';
import {
    Row
} from 'react-bootstrap';

import PropertiesUnit from './PropertiesUnit';
import PropertiesUnitLoader from './Loader';
import AddFirstProperty from './AddFirstProperty';


export default function PropertiesList({
    loading = true,
    properties,
    filterHandler,
    isError,
    wereAllPropertiesFetched,
    sortedTickets,
    loadingTickets,
    hasProperties,
    alternativeStyle,
    setHighlightBuildingWithID
}) {

    // console.log('[PropertiesList] hasProperties: ', hasProperties)
    
    const mouseEnterHandler = (buildingID) => {
        setHighlightBuildingWithID([buildingID]);
    };

    const mouseLeaveHandler = () => {
        setHighlightBuildingWithID([]);
    };
    return (
        <Row className="m-0 p-0">
            {loading && (
                <PropertiesUnitLoader />
            )}

            {(!loading && properties) && (
                filterHandler.map((property, index) => (
                    <Row className="m-0 p-0"
                        key={index}
                        onMouseEnter={()=>mouseEnterHandler(property.BuildingID) }
                        onMouseLeave={()=>mouseLeaveHandler()}
                    >
                        <PropertiesUnit
                            property={property}
                            sortedTickets={sortedTickets}
                            loadingTickets={loadingTickets}
                            alternativeStyle={alternativeStyle}
                        />
                    </Row>
                ))
            )}

            {
                // ( wereAllPropertiesFetched && !isError && !loading && !properties.length ) && (
                !hasProperties && !loading && (
                    <AddFirstProperty />
                )
            }

            {
                (!wereAllPropertiesFetched && isError) && (
                    <h5 className="my-5 py-5 text-center">Something went wrong... Try again later.</h5>
                )
            }

        </Row>
    )
}
