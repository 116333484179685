import React from 'react';
import {
  Row
} from "react-bootstrap";

import LazyImage from '../../Shared/LazyImage';
import preparePhotos from '../../../utils/preparePhotos';

export default function UnitImage({ unit, openProperty }) {
  const SRC = preparePhotos(unit)
  return (
    <Row className="d-block d-xl-none cursor-pointer"  style={{ height: "200px" }} onClick={openProperty}>
      {/* TODO pictures to be loaded from API */}

      {/* Correct src for image: units[unitsIndex].imagePath */}
      <LazyImage
        src={SRC}
        style={{ objectFit: "cover", height: "200px", width: "100%" }}
      />
    </Row>
  )
}
