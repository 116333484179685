import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';

import ButtonPrimary from '../Buttons/ButtonPrimary';


export default function BuildingInfoLoader(){
    return (
        <Col className="m-0 p-2" xs={12} md={4}>
            {/* Name loader */}
            <Row className="p-0 m-0 px-3 pb-1">
                <h4 className="p-0 m-0 mb-2 fw-bolder darkerGray skeleton rounded">&nbsp;</h4>
            </Row>

            {/* Image loader */}
            <Row style={{ minHeight: 200 }} className="p-1 px-3 m-0">
                <Col xs={12} className="m-0 p-0 darkerGray skeleton rounded" style={{ height: 200 }} ></Col>
            </Row>
            
            {/* Address loader */}
            <Row className="m-0 p-0 px-3 my-3">
                <h6 className="m-0 p-0 fw-bold darkerGray skeleton rounded">&nbsp;</h6>
            </Row>
            
            {/* Street View Loader */}
            <Row className="m-0 p-0 px-3 mb-3">
                <ButtonPrimary 
                    title={<>&nbsp;</>}
                    additionalClassName="darkerGray skeleton border-light"
                    onClick={() => {}}
                />
            </Row>

            {/* Features loader */}
            <Row className="m-0 p-0 px-3">
               <Col className="m-0 p-0 darkerGray skeleton rounded" style={{height: 107}}></Col>
            </Row>

            {/* Units list loader */}
            <Row className="m-0 p-0 py-3">
                <Col className="m-0 mt-2 p-0 px-3" xs={12} style={{ maxHeight: 200, overflowY: 'auto' }}>
                    <Row className={` m-0 my-1 p-1 darkerGray skeleton rounded`} style={{height: 35}}></Row>
                    <Row className={` m-0 my-1 p-1 darkerGray skeleton rounded`} style={{height: 35}}></Row>   
                </Col>
            </Row>
        </Col>
    )
}
