import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Button
} from 'react-bootstrap';


export default function AddFirstProperty() {
    const history = useHistory();
    const onClickHandler = () => {
        history.replace('/add-new-building');
    }

    return (
        <Col className="m-0 my-5 p-0">
            <Row className="m-0 p-0">
                <h5 className="p-0 pb-2 text-center">You don't have any properties.</h5>
            </Row>
            <Row className="m-0 p-0">
                <Col className="m-0 p-0 text-center">
                    <Button onClick={onClickHandler}>
                        <h6 className="m-0 p-0 px-3">Add Property</h6>
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}