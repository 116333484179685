import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Tab, Form } from 'react-bootstrap';

import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import ButtonSecondary from '../Shared/Buttons/ButtonSecondary';


export default function PortfolioModal({
    show,
    onHide,

    firstName,
    lastName,
    phone,
    email,
    id,

    onShowExamplePortfolio
}) {
    return(
        <Modal
            show={show}
            onHide={onHide}
            centered
            // fullscreen
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="m-0 p-3 px-2 px-md-3" >
                
                <Row className="m-0 mb-2 p-0 ">
                    <Col className="m-0 p-0 ">
                        <p className="m-0 p-0 fs-6"><b>Name:</b> {firstName} {lastName}</p>
                    </Col>
                </Row>
                <Row className="m-0 mb-2 p-0 ">
                    <Col className="m-0 p-0 ">
                        <p className="m-0 p-0 fs-6"><b>Phone:</b> {phone}</p>
                    </Col>
                </Row>
                <Row className="m-0 mb-4 p-0 ">
                    <Col className="m-0 p-0 ">
                        <p className="m-0 p-0 fs-6"><b>Email:</b> {email}</p>
                    </Col>
                </Row>

                <Row className="m-0 p-0 ">
                    <Col className="m-0 p-0 ">
                        <p className="m-0 p-0 text-danger" style={{fontSize: "0.85rem"}}><i className="bi bi-info-circle me-2"></i>This user have not registered yet. Once user sign up you will be able to see user's Portfolio.</p>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className='m-0 p-0'>
                <Row className="m-0 p-2 w-100 justify-content-end">
                    {/* <Col xs={12} sm="auto" className="p-0 mb-1 me-sm-1 mb-sm-0">
                        <ButtonSecondary
                            title="Close"
                            type="button"
                            onClick={onHide}
                        />
                    </Col> */}
                    <Col xs={12} sm="auto" className="p-0 mb-1 me-sm-1 mb-sm-0">
                        <ButtonPrimary 
                            title="Show Example Portfolio"
                            type="button"
                            onClick={() => onShowExamplePortfolio(id)}
                        />
                    </Col>
                    <Col xs={12} sm="auto" className="p-0">
                        <ButtonPrimary 
                            title="Sent Invite"
                            onClick={() => {}}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
