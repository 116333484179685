import http, { postPutRequest } from './http-common';

const uploadImages = async (imagesData, url, token) => {
    const preparedData = imagesData.map( ({id, fileName, fileSize, isFavorite, show, src}) => {
        return {
            id,
            fileName,
            fileSize,
            IsFavorite: isFavorite,
            show,
            src
        }
    })

    const dataJson = JSON.stringify(preparedData);

    try {
        const response = await http.post(url, dataJson, {
            headers: {
                Authorization: `Bearer ${token}`
            }}
        )
        return {success: true, data: response};
    } catch (err) {
        return {success: false, data: err};
    }
}

const deleteImage = async (PhotoID, UnitID, url, token) => {
    const preparedData = {
        UnitID,
        PhotoID
    }

    const dataJson = JSON.stringify(preparedData);

    try {
        const response = await http.post(`${url}/${PhotoID}`, dataJson, {
            headers: {
                Authorization: `Bearer ${token}`
            }}
        )
        return {success: true, data: response};
    } catch (err) {
        return {success: false, data: err};
    }
}

const deleteImages = async (PhotoIDs, UnitID, url, token, isBuilding = false) => {
    let preparedData;
    if (isBuilding) {
        preparedData = {
            BuildingID: UnitID,
            PhotoIDs: PhotoIDs
        }
    } else {
        preparedData = {
            UnitID,
            PhotoIDs: PhotoIDs
        }
    }
    
    const preparedQuery = PhotoIDs.map( photoID => `photoIDs=${photoID}`)
        .join('&');
    
    const dataJson = JSON.stringify(preparedData);

    try {
        // const response = await http.post(`${url}?${preparedQuery}`, dataJson, {
        // const response = await http.delete(`${url}`, PhotoIDs, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }}
        // )
        // return {success: true, data: response};


        const response = await postPutRequest(url, PhotoIDs, token, 'post');
        return {success: true, data: response};

    } catch (err) {
        return {success: false, data: err};
    }
}

export {uploadImages, deleteImage, deleteImages};