import React, { useContext } from "react";
import { Container, Button, Row, Col, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import useLogout from "../hooks/useLogout";
import useScrolled from "../hooks/useScrolled";
import useWindowSize, { GRID_BREAKPOINTS } from "../hooks/useWindowSize";

import { AuthContext } from "../contexts/AuthContext";

import TenantTracksLogoS from '../assets/logos/TenantTracks.png';
import TenantTracksLogoL from '../assets/logos/TenantTracks@2x.png';
import TheHousingCollectiveS from '../assets/logos/TheHousingCollective.png';
import TheHousingCollectiveL from '../assets/logos/TheHousingCollective@2x.png';
import ProsperiKeyS from '../assets/logos/ProsperiKey.png';
import ProsperiKeyL from '../assets/logos/ProsperiKey@2x.png';
import Thumbnail from '../assets/thumbnail.png';

import TransparentBox from "../components/Shared/TransparentBox";
import ThumbnailVideo from "../components/Shared/ThumbnailVideo";
import Footer from "../components/Shared/Footer";

const Home = () => {
  const { token } = useContext(AuthContext);
  const { logoutHandler } = useLogout();

  const scrolled = useScrolled();

  return (
    <>
    <div className="new_home_bg">
      <div className="home_bg" style={{ backgroundPositionY: scrolled * 1000 * -1 + "px", opacity: 1 - scrolled * 2.5 }}></div>
      <ScrollIndicator />
      <Container fluid className="pt-1 pt-sm-4 pt-md-0">

        <HousingBox
          token={token}
          logoutHandler={logoutHandler}
        />

        <VideoBox />
        <AdvantageBox />
        <PartnersBox />

      </Container>
    </div>
    <Footer />
    </>
  );
};

export default Home;

function HousingBox(props) {
  return (
    <Row className="ps-2 mb-5 mb-sm-4 mb-md-3 mb-md-auto ps-sm-auto d-flex align-items-center justify-content-center justify-content-md-start full-height" >
      <Col
        xs={11}
        sm={{ offset: 1, span: 9 }}
        md={{ offset: 1, span: 8 }}
        lg={{ offset: 1, span: 6 }}
        xl={{ offset: 1, span: 4 }}
        className="p-0 position-relative"
        style={{ zIndex: 1 }}
      >
        <TransparentBox title="Housing Made Easy">
          <Row className="m-0 p-0 justify-content-center">
            <Col className="m-0 p-0" xs={11} sm={11} lg={10}>
              <p className="m-0 p-0 mb-4 mb-sm-5 home-desc">
                Whether you’re looking for a great unit or a great tenant, WISSP is here to help. With a streamlined platform and TenantTracks integration, WISSP minimizes fees and saves you time!
              </p>
            </Col>
          </Row>

          <Row className="m-0 p-0 d-flex justify-content-center font-helvetica">

            <Col className="m-0 p-0 pb-3 pb-md-0 pe-md-3" xs={12} md={6} xxl={5}>
              <NavLink exact to="/marketplace" >
                <Button className="m-0 p-3 p-sm-4 rounded-hp border-0 btn-purple w-100 ">
                  <h5 className="m-0 p-0 home-btn">Marketplace</h5>
                </Button>
              </NavLink>
            </Col>

            <Col className="m-0 p-0 ps-md-3" xs={12} md={6} xxl={5}>

              {!props.token && (
                <NavLink exact to="/signup">
                  <Button className="m-0 p-3 p-sm-4 rounded-hp border-0 text-dark btn-lighter-blue w-100">
                    <h5 className="m-0 p-0 home-btn">Register</h5>
                  </Button>
                </NavLink>
              )
              }

              {props.token && (
                <Button className="m-0 p-3 p-sm-4 rounded-hp border-0 text-dark btn-lighter-blue w-100" onClick={props.logoutHandler}>
                  <h5 className="m-0 p-0 home-btn">Logout</h5>
                </Button>
              )
              }
            </Col>
          </Row>
        </TransparentBox>
      </Col>
    </Row>
  )
}



function VideoBox() {
  return (
    <Row className="ps-2 ps-sm-auto pt-5 pt-sm-auto d-flex align-items-center justify-content-center pb-5"  >
      <Col
        xs={11}
        sm={10}
        className="p-0 position-relative"
        style={{ zIndex: 1 }}
      >
        <TransparentBox title="" beforeBg="bg-light-purple">
          <Col
            fluid="xs"
            md={9}
            xl={6}
          >
            <ThumbnailVideo customImage={Thumbnail} link="https://www.youtube.com/watch?v=FGeGdGyLfCc" />
          </Col>
          <Col
            
            fluid="xs"
            md={9}
            xl={6}
          >
            <h2 className="custom_heading mt-sm-1 mt-md-3 mb-4 sm-text-left text-center px-0">
              Our Vision
            </h2>
            <p className="m-0 p-0 home-desc">
              There is a national need to find ways of improving access to housing in the United States. WISSP, or the Widely-Integrated Social Services Platform, is designed to be a one-stop shop for tenants and landlords working together to find and offer affordable housing. It seamlessly integrates technologies that lower the difficulty of searching and applying for housing, including easy in-platform communication, document-sharing, and background checks. Working together with multiple housing non-profit and landlord groups, we hope to offer WISSP in Connecticut and beyond.
            </p>
          </Col>
        </TransparentBox>
      </Col>
    </Row>
  )
}


function AdvantageBox() {
  const winSize = useWindowSize();
  return (
    <Row
      className="ps-2 ps-sm-auto pt-5 pt-sm-auto d-flex align-items-center justify-content-center justify-content-md-start pb-5 mt-5"
    >
      <Col
        className="p-0 position-relative mx-auto"
        style={{ zIndex: 1 }}
      >
        <Row className="advantages-box justify-content-center" 
        >
          <Col xs={12} md={5} xl={3}>
            <TransparentBox
              title="Affordable"
              beforeBg="bg-light-purple"
              titleBefore={(
                <i className="bi bi-piggy-bank color-light-purple" style={{ fontSize: 100 }}></i>
              )}
              bgItem={(
                <i className="bi bi-piggy-bank color-light-purple" style={{ fontSize: 500 }}></i>
              )}
            >
              <Col>
                <p className="m-0 p-0 home-desc text-center">
                  Our innovative model allows prospective tenants to purchase and share their own background check data, lowering the costs associated with rental applications. We will also offer property owners and landlords free access to our platform during our beta period.
                </p>
              </Col>
            </TransparentBox>
          </Col>
          <Col xs={12} md={5} xl={3} className=""
            style={{ transform: winSize.width >= GRID_BREAKPOINTS.XL ? "translateY(-50px)" : "" }}
          >
            <TransparentBox
              title="Safe"
              beforeBg="bg-light-purple"
              titleBefore={(
                <i className="bi bi-shield-check color-light-purple" style={{ fontSize: 100 }}></i>
              )}
              bgItem={(
                <i className="bi bi-shield-check color-light-purple" style={{ fontSize: 500 }}></i>
              )}
            >
              <Col>
                <p className="m-0 p-0 home-desc text-center">
                  We are developing WISSP in collaboration with established industry partners and nonprofits in Connecticut. Our platform ensures that only allowable data under fair housing regulations will be shared between tenants and property owners.
                </p>
              </Col>
            </TransparentBox>
          </Col>
          <Col xs={12} md={5} xl={3}>
            <TransparentBox
              title="Easy"
              beforeBg="bg-light-purple"
              titleBefore={(
                <i className="bi bi-award color-light-purple" style={{ fontSize: 100 }}></i>
              )}
              bgItem={(
                <i className="bi bi-award color-light-purple" style={{ fontSize: 500 }}></i>
              )}
            >
              <Col>
                <p className="m-0 p-0 home-desc text-center">
                  Signing up for WISSP and using it to either search for housing as a tenant or post a unit as a property owner is simple and easy. Messaging is streamlined in-app and sharing of information is as easy as sending a request for communication. Advertising an open unit is similarly as easy as clicking a button and all previous data is saved thereby eliminating the hassle of reentering information.
                </p>
              </Col>
            </TransparentBox>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}



function PartnersBox() {
  return (
    <Row className="ps-2 ps-sm-auto pt-5 pt-sm-auto d-flex align-items-center justify-content-center justify-content-md-start pb-5"  >
      <Col
        xs={11}
        sm={{ offset: 1, span: 9 }}
        md={{ offset: 1, span: 10 }}
        className="p-0 position-relative"
        style={{ zIndex: 1 }}
      >
        <TransparentBox title="Made Possible By Our Partners" beforeBg="bg-light-purple">
          <Col className="pb-3 px-5 px-md-0 text-center" xs={12} sm={6} md={3}>
            <a target={"_blank"} href="https://tenanttracks.com/" rel="noreferrer" >
              <Image style={{ objectFit: "cover", height: "auto", width: "100%" }} srcSet={`${TenantTracksLogoS} 1x, ${TenantTracksLogoL} 2x`} alt="..." className="grayToHover"></Image>
            </a>
          </Col>
          <Col className="pb-3 px-5 px-sm-0 text-center" xs={12} sm={6} md={3}>
            <a target={"_blank"} href="https://thehousingcollective.org/" rel="noreferrer" >
              <Image style={{ objectFit: "cover", height: "auto", width: "100%" }} srcSet={`${TheHousingCollectiveS} 1x, ${TheHousingCollectiveL} 2x`} alt="..." className="grayToHover"></Image>
            </a>
          </Col>
          <Col className="pb-3 px-5 px-sm-0 text-center" xs={12} sm={6} md={3}>
            <a target={"_blank"} href="https://www.prosperikey.org/" rel="noreferrer" >
              <Image style={{ objectFit: "cover", height: "auto", width: "100%" }} srcSet={`${ProsperiKeyS} 1x, ${ProsperiKeyL} 2x`} alt="..." className="grayToHover"></Image>
            </a>
          </Col>
        </TransparentBox>
      </Col>
    </Row>
  )
}


export function ScrollIndicator() {

  const scrolledVal = useScrolled();

  return (
    <div
      className={`position-fixed end-50 colorWhite btn-purple p-2 scrollToTop ${scrolledVal >= .01 ? "off" : ""} scrollIndicator`}
      style={{ fontSize: 50, width: 75, height: 75 }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-chevron-down position-absolute top-50 start-50 translate-middle" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  )
}
