import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { UserTypeContext } from '../../contexts/UserTypeContext';

import ErrorPopup from "../Shared/InfoPopups/ErrorPopup";


const PrivateRoute = ({ component: Component, users, ...rest }) => {
  const { token, logout } = useContext(AuthContext);
  const { userType } = useContext(UserTypeContext);

  const checkAuth = () => {
    const tokenExpire = Number(localStorage.getItem("tokenExpTime"));
    const timeStamp = Date.now();

    if (token && (tokenExpire > timeStamp)) {
      return true;
    }

    ErrorPopup("Session expired! Please login again.");
    logout();
    return false;
  };

  const checkUserType = () => {
    if ( !users ) return false;

    const result = users.some( user => user === userType );
    return result;
  }

  return (
    <>
      { checkUserType() ? (
          <Route
            {...rest}
            render={(props) =>
              checkAuth() ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                  }}
                />
              )
            }
          />
        ) : (
          <Redirect to="/"/>
        )
      }
    </>
  );
};

export default PrivateRoute;
