import { configureStore } from '@reduxjs/toolkit';

import MarketplaceSlice from './MarketplaceSlice';
import UserSlice from './UserSlice';
import ManagerDataSlice from './ManagerDataSlice';
import PropertiesSlice from './PropertiesSlice';
import RequestsSlice from './RequestsSlice';
import FavoriteSlice from './FavoriteSlice';
import FeaturesSlice from './FeaturesSlice';
import CaseManagerSlice from './CaseManagerSlice';

const store = configureStore({
    reducer: { 
        marketplaceStore: MarketplaceSlice,
        userStore: UserSlice,
        managerDataStore: ManagerDataSlice,
        propertiesStore: PropertiesSlice,
        requestsStore: RequestsSlice,
        favoriteStore: FavoriteSlice,
        featuresStore: FeaturesSlice,
        caseManagerStore: CaseManagerSlice
    }
})

export default store;
