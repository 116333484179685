import { createSlice } from '@reduxjs/toolkit';
import { getTickets } from '../../services/tickets';

const initialState = {
    tickets: null,
    loading: false,
    error: false
}

export const requestsSlice = createSlice({
    name: 'requestsSlice',
    initialState,
    reducers: {
        setRequests(state, action) {
            state.tickets = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        clearRequestsStore(state) {
            state.tickets = null;
            state.loading = false;
            state.error = false;
        }
    }
});

export const getTicketsHandler = (token, userType) => {
    return async (dispatch) => {
        dispatch(requestsSlice.actions.setLoading(true));
        dispatch(requestsSlice.actions.setError(false));
        const response = await getTickets(token, userType);
        const { success, data } = response;

        if (success) {
            switch(userType) {
                case 'Manager':
                    dispatch(requestsSlice.actions.setRequests(extrudeTickets(data)));
                    break;
                case 'Landlord':
                    dispatch(requestsSlice.actions.setRequests(data));
                    break;
                default:
                    dispatch(requestsSlice.actions.setRequests(data));
            }
        } else {
            dispatch(requestsSlice.actions.setError(true));
        }

        dispatch(requestsSlice.actions.setLoading(false));
    }
}

export const requestsActions = requestsSlice.actions;
export default requestsSlice.reducer;


function extrudeTickets (tickets) {
    if (!tickets) return null;

    const extrudedProperties = Object.entries(tickets).reduce( ( acc, [_, value]) => {
        return {
            ...acc,
            ...value
        }
    }, {});
    if(!extrudedProperties) return {};

    return extrudedProperties;
}