import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LazyImage from './LazyImage'

import WisspLogo from "../../assets/logos/WISSP_Full.png"

const CompanyDetails = [
    "Source Development Hub, LLC",
    "PO Box 209000",
    "New Haven, CT 06520"
]
// const Legal = [
//     "Privacy Policy",
//     "Terms Of Service",
//     "Software Licensing",
//     "Cookies"
// ]
const Socials = [
    { social: "Linkedin", icon: "bi-linkedin", link: "https://www.linkedin.com/in/source-development-hub-llc-436066178?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BtvZfpJU0RBe0SrhbSB0KKw%3D%3D" },
    { social: "Twitter", icon: "bi-twitter", link: "https://twitter.com/srcdevhub" },
    { social: "YouTube", icon: "bi-youtube", link: "https://www.youtube.com/channel/UCftGwplv6JmodMKZx5-KEcw" },
]


export default function Footer() {
    return (
        <Container fluid className='darkBlue position-relative py-3'>
            <div className='m-auto' style={{ maxWidth: 1600 }} >
                <Row className="p-0 px-3 color-white colorWhite justify-content-center">
                    
                    <Col xs="auto" className="mb-2 me-sm-5 d-flex justify-content-center align-items-center">
                        <LazyImage src={WisspLogo} style={{ width: 150 }} />
                    </Col>

                    <Col xs={12} sm={"auto"} className="mb-2 mb-md-auto me-sm-5">
                        <p className="colorWhite fw-bolder mb-1 fs-6">
                            Company Details
                        </p>
                        {CompanyDetails.map( (n, index) => (
                            <p key={'cd_'+index} className="colorWhite my-0 mb-1  fs-6">
                                {n}
                            </p>
                        ))}
                    </Col>

                    <Col xs={12} sm={"auto"}>
                        <Row>
                        <p className="colorWhite fw-bolder mb-1 fs-6">
                            Visit us on
                        </p>
                        </Row>

                        {Socials.map((n) => ( <Row key={n.social}>
                            <Col>
                            <p className="colorWhite my-0 mb-1  fs-6 cursor-pointer d-inline" onClick={ () => window.open(n.link, '_blank').focus() }>
                                <i className={`bi ${n.icon} me-2`}></i>
                                {n.social}
                            </p> </Col> </Row>
                        ))}
                        
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
