import React, { useState, useEffect, useContext } from "react";
import { Accordion, Container, Card, Row, Col, Modal } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";
import LazyImage from "../../Shared/LazyImage";
// import PropertiesUnitLoader from "./Loader";
import preparePhotos from "../../../utils/preparePhotos";
// import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";
// import OccupantFields from "../../UnitFormElements/OccupantFields";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import OccupantInformation from "../../UnitUpdate/OccupantInformation";
import {
  NAME,
  SQ_FOOTAGE,
  BEDROOM_COUNT,
  BATHROOM_COUNT,
  FLOOR_ID,
  RENT,
  PAYMENT_DETAILS,
  PAYMENT_DUE_DATE,
  OCCUPANT_FULL_NAME,
  OCCUPANT_EMAIL,
  OCCUPANT_PHONE_NUMBER,
  OCCUPANT_LEASE_START,
  OCCUPANT_LEASE_END,
  OCCUPANT_NOTES,
  MISC_AMENITIES,
  MISC_ACCESSIBILITIES,
  MISC_NOTES,
} from "../../../constants/unitFormConstants";
import {
  getUnitFeatures,
  updateFeatures,
  updateInfo,
  deleteUnit,
  deleteOccupant,
  editOccupant,
  setFavoriteUnitPhoto,
} from "../../../services/units";
import { AuthContext } from "../../../contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { propertiesDataActions } from "../../../store/PropertiesSlice";
import SuccessPopup from "../../Shared/InfoPopups/SuccessPopup";
import ErrorPopup from "../../Shared/InfoPopups/ErrorPopup";
import LogoLoader from "../../Shared/LogoLoader";

export default function PropertiesVacancy({
  loading = true,
  properties,
  isError,
  filters,
  token,
  userType,
}) {
  return (
    <Row className="m-0 p-0">
      {!loading && properties && (
        <SectionAccordionWrapper defaultActiveKey={[0]} alwaysOpen>
          {properties
          .filter(p => Array.isArray(p.Units) && p.Units.length )
          .map((property, i) => {
            return (
              <PropertyAccordion
                property={property}
                className="m-0 p-0 my-4"
                key={i}
                eventKey={i}
              >
                {property.Units.map((u, i) => (
                  <Unit unit={u} key={i} building={property} />
                ))}
              </PropertyAccordion>
            );
          })}
        </SectionAccordionWrapper>
      )}
    </Row>
  );
}

function Property({ property, accordionHanlder, isOpen }) {
  const preparedPhotos = preparePhotos(property);

  const UnitsStats = getUnitsStats(property);

  return (
    <Container
      onClick={accordionHanlder}
      className="rounded lightGray text-start cursor-pointer"
      
    >
      <Row>
        <Col
          xs="auto"
          className="m-0 p-0 d-none d-lg-flex"
          style={{
            backgroundImage: `url('${property && property.PhotoURL}')`,
            width: 200,
            height: 150,
            backgroundSize: "cover",
            backgroundPosition: "50%,50%",
          }}
        >
          <LazyImage
            src={preparedPhotos[0]}
            style={{
              objectFit: "cover",
              height: 150,
              width: 200,
            }}
          />
        </Col>
        <Col className="my-3 px-2 px-sm-5">
          <h4>{property.Name}</h4>
          <p className="m-0 p-0 fs-6 text-muted">
            <AddressComponent address={property.Address} city={property.City} />
          </p>
          <p className="mt-1 mb-0">{property.Units.length} Units:</p>
          <p className="d-flex m-0 fs-6 text-muted">
            <span>Occupied: </span>
            <span className="mx-1">{UnitsStats.occupied}</span>
            {/* <hr className="verticalDivider" /> */}
            <span>Vacant: </span>
            <span className="mx-1">{UnitsStats.vacant}</span>
          </p>
        </Col>
        <Col xs="auto" className="d-flex align-items-start my-3 px-2 px-sm-5">
          <i
            className={`bi bi-chevron-down rotate180 ${
              isOpen ? "rotate" : ""
            } fs-4 text-muted`}
          ></i>

          {/* <ButtonPrimary
            onClick={accordionHanlder}
            className={`${property.Units.length ? "" : "disabled"}`}
          >
            {!isOpen ? "Expand" : "Hide"}
          </ButtonPrimary> */}
        </Col>
      </Row>
    </Container>
  );
}

function getUnitsStats(property) {
  let occupied = 0;
  let vacant = 0;
  property.Units.forEach((u) => {
    if (u.Vacant) {
      vacant++;
    } else {
      occupied++;
    }
  });
  return {
    occupied,
    vacant,
  };
}

function CustomHeader({
  children,
  eventKey,
  className = "",
  property,
  isOpen,
  ...props
}) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    // console.log(`Event Key: ${eventKey}`)
    console.log("click")
  );

  return (
    <div
      // type="button"
      // onClick={decoratedOnClick}
      className={`m-0 p-0 border-0 w-100 h-100 rounded ${className}`}
      {...props}
    >
      <Property
        property={property}
        accordionHanlder={decoratedOnClick}
        isOpen={isOpen}
      />
    </div>
  );
}

export function PropertyAccordion({
  children,
  eventKey = 0,
  className = "",
  isOpen = "false",
  property,
}) {
  return (
    <Card className={`${className} position-relative p-0 border-0`}>
      <Card.Header
        style={{ background: "transparent", border: "", outline: "0" }}
        className="p-0 m-0 border-0"
      >
        <CustomHeader
          eventKey={eventKey}
          className={isOpen ? "lightGray" : ""}
          property={property}
          isOpen={isOpen}
        />
      </Card.Header>
      <div>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="p-0 ms-3">{children}</Card.Body>
        </Accordion.Collapse>
      </div>
    </Card>
  );
}

function SectionAccordionWrapper({ children, defaultActiveKey, ...props }) {
  const [opened, setOpened] = useState(defaultActiveKey);
  return (
    <Accordion
      onSelect={(e) => {
        setOpened(e);
      }}
      defaultActiveKey={defaultActiveKey}
      {...props}
    >
      {children &&
        React.Children.map(children, (child) =>
          child
            ? React.cloneElement(child, {
                isOpen: opened.includes(child.props.eventKey),
              })
            : null
        )}

      {/* {children} */}
    </Accordion>
  );
}

function Unit({ unit, building }) {
  const [vacant, setVacant] = useState(unit.Vacant);
  const [vacantModal, setVacantModal] = useState(false);
  return (
    <Container
      className={`lightGray rounded p-3 my-2 ${
        vacant ? "border-left-green" : "border-left-red"
      }`}
    >
      <Row>
        <Col xs="auto">
          <p className="m-0">Unit: {unit.Name}</p>
          <AddressComponent address={unit.Address} city={unit.City} />
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          {/* {unit.Vacant ? "Vacant":"Occupied"} */}
          {unit.Vacant ? (
            <>
              <p className="m-0">Unit is Vacant</p>
              {/* <p className="m-0"></p> */}
            </>
          ) : (
            <>
              <p className="m-0">Occupant:</p>
              <p className="m-0">
                {unit.Tenant?.FullName ?? "Missing Name"} | {" "}
                {unit.Tenant?.Phone ?? "No Phone Number Provided"}
              </p>
            </>
          )}
        </Col>
        <Col xs="auto" className="d-flex align-items-end px-2 px-sm-5">
          {/* <ButtonPrimary onClick={() => setEdit((p) => !p)}>
            Edit Occupation
          </ButtonPrimary> */}
          <Toggle
            checked={vacant}
            className="custom-toggle"
            onChange={() => {
              setVacantModal((p) => !p);
            }}
            icons={false}
          />
        </Col>
      </Row>
      <OccupationModal
        unit={unit}
        show={vacantModal}
        setShow={setVacantModal}
        setVacant={setVacant}
        building={building}
      />
    </Container>
  );
}

function AddressComponent({ address, city }) {
  return (
    <span className="text-muted">
      <i className="bi bi-geo-alt"></i> {address.replace(/\$\*\|\*\$/, " ")},{" "}
      {city}
    </span>
  );
}

function OccupationModal({ unit, show, setShow, building, setVacant }) {
  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const editOccupantHandler = async (occupantData) => {
    if (!occupantData) return;

    const body = {
      FullName: occupantData[OCCUPANT_FULL_NAME],
      Email: occupantData[OCCUPANT_EMAIL],
      Phone: occupantData[OCCUPANT_PHONE_NUMBER],
      LeaseStart: occupantData[OCCUPANT_LEASE_START]
        ? new Date(occupantData[OCCUPANT_LEASE_START]).toLocaleDateString(
            "en-US"
          )
        : null,
      LeaseEnd: occupantData[OCCUPANT_LEASE_END]
        ? new Date(occupantData[OCCUPANT_LEASE_END]).toLocaleDateString("en-US")
        : null,
      Comments: occupantData[OCCUPANT_NOTES],
    };

    setLoading(true);

    const response = await editOccupant(unit.UnitID, body, token);
    const { success } = response;

    setLoading(false);

    if (success) {
      dispatch(
        propertiesDataActions.editUnitInProperty({
          UnitID: unit.UnitID,
          BuildingID: building.BuildingID,
          Vacant: false,
          Tenant: {
            ...body,
          },
        })
      );
      setVacant(false);
      setShow(false);
      SuccessPopup("Success! Occupant information updated succesfully!");
    } else {
      ErrorPopup();
    }
  };
  const deleteOccupantHandler = async () => {
    const response = await deleteOccupant(unit.UnitID, token);
    const { success } = response;

    if (success) {
      dispatch(
        propertiesDataActions.editUnitInProperty({
          UnitID: unit.UnitID,
          BuildingID: building.BuildingID,
          Vacant: true,
          Tenant: null,
        })
      );
      setVacant(true);
      setShow(false);
      SuccessPopup("Success! Deleted occupant information.");
    } else {
      ErrorPopup();
    }

    return success;
  };
  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
      <Modal.Header closeButton />
      <Modal.Body>
        {loading && <LogoLoader  height="300px"/>}
        {!loading && (
          <OccupantInformation
            sectionFocus={false}
            setSectionFocus={() => {}}
            unit={unit}
            onDeleteOccupant={deleteOccupantHandler}
            onEditOccupant={editOccupantHandler}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
