import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    properties: {},
    landlordsInformations: null,
    managerInfo: null
}

export const managerDataSlice = createSlice({
    name: 'managerDataSlice',
    initialState,
    reducers: {
        setProperties(state, action) {
            state.properties = action.payload;
        },
        setLandlordsInformations(state, action) {
            state.landlordsInformations = action.payload;
        },
        setManagerInfo(state, action) {
            state.managerInfo = action.payload;
        },
        clearManagerStore(state) {
            state.properties = {};
            state.landlordsInformations = null;
            state.managerInfo = null;
        }
    }
});

export const managerDataActions = managerDataSlice.actions;
export default managerDataSlice.reducer;
