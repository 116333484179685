import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';


export default function RequestLoader() {
    return (
        <Row className={`m-0 p-0 my-1 rounded darkerGray skeleton`}>
            <Container className="m-0 p-0" fluid>
                <Row className="m-0 p-2 pb-0 row-cols-1">
                    <Col className="m-0 p-0"><h6 className="m-0 p-0 fw-bold w-25">&nbsp;</h6></Col>
                    <Col className="m-0 p-0"><p className="m-0 p-0 fs-6 text-light w-50">&nbsp;</p></Col>
                    <Col className="m-0 p-0 d-flex justify-content-end text-center"><p className="m-0 p-0 text-light w-25" style={{ fontSize: '.85rem' }}>&nbsp;</p></Col>
                </Row>
            </Container>
        </Row >
    )
}
