import React from 'react'
import { Row } from 'react-bootstrap'

export default function TransparentBox(props) {

    const { title, beforeBg, className, titleBefore, bgItem, bgItemOpacity = .1, ...rest } = props;

    return (
        <div className='w-100 h-100 position-relative'>
            <div className={`trans-box-bg ${beforeBg ? beforeBg : "bg-light-blue"}`}></div>
            <Row
                {...rest}
                className={`overflow-hidden m-0 p-4 pb-5 bg-white-opaque w-100 h-100 rounded-hp ${!title ? "pt-5" : ""} ${className && className}`}
            >
                <div className="px-0 position-relative">
                    {bgItem && (
                        <div
                            className='position-absolute start-0 top-0 w-100 h-100'
                            style={{ opacity: bgItemOpacity, pointerEvents: "none" }}
                        >
                            {bgItem}
                        </div>
                    )}
                    {titleBefore && (
                        <Row className="m-0 p-0 py-0">
                            <div className="d-flex justify-content-center align-content-center">
                                {titleBefore}
                            </div>
                        </Row>
                    )}
                    {title && (
                        <Row className={`m-0 p-0 py-0  ${titleBefore ? "pb-sm-4" : "py-sm-4"}`}>
                            <h2 className="custom_heading mt-sm-1 mt-md-4 mb-5 sm-text-left text-center px-0">
                                {title}
                            </h2>
                        </Row>
                    )}
                    {props.children ? (
                        <Row className="m-0 p-0 justify-content-around align-items-center">
                            {props.children}
                        </Row>
                    ) : <></>}
                </div>
            </Row>
        </div>
    )
}
