import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import useSize from '../../hooks/useSize';

const SHORT = 'short';
const LONG = 'long';

const DAY_NAMES = {
    short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
}
const MONTHS_NAMES = {
    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    long: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
};

const MOBILE_DAY_WIDTH = 80;
const DESKTOP_DAY_WIDTH = 140;

export default function CalendarCarousel({numberOfDays=14, selectedDates, setSelectedDates}) {
    const daysContainer = useRef(null);
    const sizeOfContainer = useSize(daysContainer);

    const [dates, setDates] = useState([]);
    const [firstVisibleDate, setFirstVisibleDate] = useState(0);
    const [numberOfDaysShow, setNumberOfDaysShow] = useState(0);
    const [namesVersion, setNamesVersion] = useState(LONG);
    const [dayWidth, setDayWidth] = useState(DESKTOP_DAY_WIDTH)

    const calcNumberOfDaysShow = (containerWidth, dayWidth) => {
        const newNumOfDays = Math.floor(containerWidth / dayWidth);

        return newNumOfDays;
    }

    const prepareDates = useCallback(() => {
        const currentDate = new Date();

        const dates = [];

        for(let i = 0; i < numberOfDays; i++) {
            const nextDate = new Date(currentDate);
            nextDate.setDate(nextDate.getDate() + i);

            const nextDay = nextDate.getDay();
            const nextDayOfTheMonth = nextDate.getDate();
            const nextDayYear = nextDate.getFullYear();
            const nextDayMonth = nextDate.getMonth();

            const id = `${nextDayYear}-${nextDayMonth}-${nextDayOfTheMonth}`;

            dates.push({
                year: nextDayYear,
                month: [nextDayMonth, MONTHS_NAMES[namesVersion][nextDayMonth]],
                day: [nextDay, DAY_NAMES[namesVersion][nextDay]],
                dayOfTheMonth: nextDayOfTheMonth,
                id: id 
            })
        }

        setDates(dates);
    }, [numberOfDays, namesVersion]);

    const dateSelectHandler = (date) => {
        setSelectedDates( prevDates => {
            const dateExists = prevDates.findIndex((prevDate) => prevDate.id === date.id);
            if(dateExists !== -1) return prevDates.filter(prevDate => prevDate.id !== date.id);
            return [...prevDates, date];
        })
    }

    const moveCarouselBackHandler = () => {
        setFirstVisibleDate( prevValue => {
            if (!prevValue) return prevValue;
            return prevValue - 1
        })
    }

    const moveCarouselForwardHandler = () => {
        setFirstVisibleDate( prevValue => {
            if(prevValue >= dates.length - numberOfDaysShow) return prevValue;
            return prevValue + 1;
        })
    }

    useEffect(() => {
        prepareDates();
    }, [prepareDates])

    useEffect(() => {
        if (sizeOfContainer) {
            const width = sizeOfContainer.width;
            const mobileOrDesktopDayWidth = width < 300 ? MOBILE_DAY_WIDTH : DESKTOP_DAY_WIDTH;
            const namesVersion = width < 300 ? SHORT : LONG;

            const newNumberOfDaysShow = calcNumberOfDaysShow(width, mobileOrDesktopDayWidth + 8);
            setFirstVisibleDate( prevState => prevState <= newNumberOfDaysShow ? prevState : prevState - newNumberOfDaysShow);
            setNumberOfDaysShow( !newNumberOfDaysShow ? 1 : newNumberOfDaysShow);
            setDayWidth(mobileOrDesktopDayWidth);
            setNamesVersion(namesVersion);
        }
    }, [sizeOfContainer])

    return (
        <Row className="m-0 mt-5 p-0 d-flex justify-content-between">
            <Col xs={1} className={`d-flex align-items-center m-0 p-0 pb-5 fs-1 ${!firstVisibleDate ? 'text-light': ''}` } onClick={ moveCarouselBackHandler }>
                <i className="bi bi-caret-left"></i>
            </Col>

            <Col xs={10} ref={daysContainer} className="m-0 p-0 pb-5 d-flex justify-content-around align-items-center row-cols-auto flex-nowrap overflow-hidden">
                
                { dates.map( (date) => (
                    <Col key={date.id} 
                        className={`p-0 m-0 h-100 bg-light overflow-hidden shadow-sm rounded`} 
                        style={{width: dayWidth}} 
                        onClick={ () => dateSelectHandler(date)}
                    >
                        <Container className={`p-0 m-0 h-100 d-flex flex-column justify-content-between align-items-center ${selectedDates.findIndex( selectedDate => selectedDate.id === date.id) !== -1 ? 'inner-shadow-primary' : ''} `}>
                            <Row className="p-2 px-3 m-0 w-100 bg-primary text-light text-center">
                                <h6 className="p-0 m-0 user-select-none">{date.month[1]}</h6>
                            </Row>
                            <Row className="p-0 pt-2 m-0">
                                <h4 className="p-0 m-0 fw-bold user-select-none">{date.dayOfTheMonth}</h4>
                            </Row>
                            <Row className="p-2 px-3 m-0 w-100 text-center">
                                <h6 className="p-0 m-0 user-select-none">{date.day[1]}</h6>
                            </Row>
                        </Container>
                    </Col>
                    )).slice(firstVisibleDate, firstVisibleDate + numberOfDaysShow)
                }
            </Col>

            <Col xs={1} className={`d-flex align-items-center justify-content-end m-0 p-0 pb-5 fs-1 ${(firstVisibleDate + numberOfDaysShow === dates.length) ? 'text-light' : ''}`} onClick={ moveCarouselForwardHandler }>
                <i className="bi bi-caret-right"></i>
            </Col>
        </Row>
    )
}
