import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from "react";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import { featuresActions } from "../store/FeaturesSlice";

import { getBuilding } from "../services/building";

import PropertyUnits from "../components/Marketplace/PropertyUnits";
import MarketplaceSearchMobile from "../components/Marketplace/MarketplaceSearchMobile";
import SideMenu from "../components/Marketplace/SideMenu/index";
import ModalDetails from "../components/Marketplace/ModalDetails";
import MarketplaceLegend from "../components/Marketplace/MarketplaceLegend";
import FiltersBtn from "../components/Shared/Filters/FiltersBtn";

import ButtonSecondary from "../components/Shared/Buttons/ButtonSecondary";

import Swal from "sweetalert2";
import { MarketplaceMap } from "../components/Marketplace/MarketplaceMap";
import { getFeatures } from "../services/marketplace";
import { geoStates, activeCities } from "../services/marketplace";
import { getTickets } from '../services/tickets';
import { TicketsContext } from '../contexts/TicketsContext';
import { UserTypeContext } from '../contexts/UserTypeContext';
import { AuthContext } from '../contexts/AuthContext';

import { favoriteActions } from "../store/FavoriteSlice";
import { getFavorites, deleteFavoriteBuilding, deleteFavoriteUnit, addFavoriteQuery, deleteFavoriteQuery } from '../services/favorites';
import { getInitialSearch } from '../services/search';

import Backdrop from "../components/Shared/Backdrop";
import Filters from "../components/Shared/Filters";
import ByValue from "../components/Shared/Filters/Categories/ByValue";

import useWindowSize, { GRID_BREAKPOINTS } from "../hooks/useWindowSize";

import useQuery from "../hooks/useQuery";
import ButtonPrimary from "../components/Shared/Buttons/ButtonPrimary";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";

const SHOW_COMPONENT = {
  BOTH: "both",
  MAP: "map",
  LIST: "list"
}

const SIDE_MENU_TABS = {
  BUILDINGS: "buildings",
  UNITS: "units"
}

export default function Marketplace(props) {
  const [activeSideMenuTab, setActiveSideMenuTab] = useState(SIDE_MENU_TABS.BUILDINGS);

  const legendBar = useRef(null);
  const favorites = useSelector(store => store.favoriteStore);
  const [hasUserSearched, setHasUserSearched] = useState(false);

  const { searchAnimTime = 4, searchAnimDelay = 0 } = props;
  const { tickets, setTickets } = useContext(TicketsContext);
  const { userType } = useContext(UserTypeContext);
  const { token } = useContext(AuthContext);
  const size = useWindowSize();
  const [states, setStates] = useState([]);
  const [LLValue, setLLValue] = useState(null);
  const [loading, setLoading] = useState();

  const [expandedProperties, setExpandedProperties] = useState([]);



  let query = useQuery();
  const qBuildingID = query.get("BuildingID");
  const qUnitID = query.get("UnitID");

  // useEffect(() => {
  //   if (collapseCard) setCollapseCard(false);
  // }, [collapseCard]);
  // const collapseHandler = () => {
  //   setCollapseCard(true);
  //   setExpandedProperties([]);
  //   sideMenuWrapperRef.current.scrollTo({
  //     top: 0,
  //     behavior: "smooth"
  //   })
  // }
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [unitsForPropertyId, setUnitsForPropertyId] = useState(null);

  const expandPropertyHandler = (buildingID) => {
    // TODO: send request ID to download building info

    getBuildingInformation(buildingID);

    const toggleProperty = (buildingID, arr) => {
      const alreadyInArray = arr.includes(buildingID);
      return alreadyInArray ? arr.filter(id => id !== buildingID) : [...arr, buildingID];
    }

    // TODO: check if it is needed!
    setExpandedProperties((prevState) => toggleProperty(buildingID, prevState))
    setUnitsForPropertyId(buildingID);

    setShowPropertyDetails(true);
    setActiveSideMenuTab(SIDE_MENU_TABS.UNITS);
  }

  const backButtonHandler = () => {
    setShowPropertyDetails(false);
    setUnitsForPropertyId(null);
    setActiveSideMenuTab(SIDE_MENU_TABS.BUILDINGS);

    // setUnitsForPropertyId(null);
    setExpandedProperties([]);
  }


  const [cities, setCities] = useState([]);
  const [isCitiesLoaded, setIsCitiesLoaded] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  // const [showModalUnitDetails, setShowModalUnitDetails] = useState(false);
  const [visibleBuildingsId, setVisibleBuildingsId] = useState([]);


  const [highlightBuildingWithID, setHighlightBuildingWithID] = useState(null);

  const [buildings, setBuildings] = useState(null);
  const [showComponents, setShowComponents] = useState(SHOW_COMPONENT.BOTH);
  const [isMobile, setIsMobile] = useState(false);

  const highlighBuildingWithIDHandler = (BuildingID) => {
    // console.log(BuildingID)
    setHighlightBuildingWithID(BuildingID);
  }

  useEffect(() => {
    if (!size.width) return;
    if (size.width >= GRID_BREAKPOINTS.MD) {
      setIsMobile(false);
      showComponentsHandler(SHOW_COMPONENT.BOTH)
    } else {
      setIsMobile(true);
      showComponentsHandler(SHOW_COMPONENT.LIST) // TODO: should be always list!
    }
  }, [size])

  const [features, setFeatures] = useState({
    buildingAccessibilitiesOptions: [],
    buildingAmenitiesOptions: [],
    unitAccessibilitiesOptions: [],
    unitAmenitiesOptions: []
  });

  const showComponentsHandler = (name) => {
    setShowComponents(name);
  }

  const getLL = (city, state) => {

    geocodeByAddress(`${city} ${state} "United States"`)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLLValue(() => ({
          center: {
            lat,
            lng,
          },
        }));
      });
  };

  const [alreadyRequestesUnitIDs, setAlreadyRequestesUnitIDs] = useState(null);

  const getTicketsHandler = async () => {
    if (userType === 'Tenant') {
      if (!tickets) {
        const response = await getTickets(token, 'tenant');
        const { success, data } = response;

        if (success) {
          setAlreadyRequestesUnitIDs(extractUnitsID(data));
          setTickets(data);
        }

      } else {
        setAlreadyRequestesUnitIDs(extractUnitsID(tickets));
      }
    }

    function extractUnitsID(data) {
      return data.filter(ticket => ticket.Status).map(ticket => ticket.UnitID);
    }
  }

  useEffect(() => {
    const fetchStatesData = async () => {
      const response = await geoStates();
      const { success, data } = response;

      if (success) {
        setStates(data);
      } else {
        ErrorPopup("Error! Could not get states data. Try again later.");
      }
    };

    const fetchAmenitiesAndAccessibilitiesData = async () => {
      const response = await getFeatures();

      const { success, data } = response;

      if (success) {
        const { BuildingAccessibilities, BuildingAmenities, UnitAccessibilities, UnitAmenities } = data;

        const a_BuildingAccessibilities = Object.entries(BuildingAccessibilities).map(([key, value]) => ({ [key]: value }));
        const a_BuildingAmenities = Object.entries(BuildingAmenities).map(([key, value]) => ({ [key]: value }));
        const a_UnitAccessibilities = Object.entries(UnitAccessibilities).map(([key, value]) => ({ [key]: value }));
        const a_UnitAmenities = Object.entries(UnitAmenities).map(([key, value]) => ({ [key]: value }));

        dispatch(featuresActions.setFeatures(data));
        setFeatures({
          buildingAccessibilitiesOptions: a_BuildingAccessibilities,
          buildingAmenitiesOptions: a_BuildingAmenities,
          unitAccessibilitiesOptions: a_UnitAccessibilities,
          unitAmenitiesOptions: a_UnitAmenities
        })
      } else {
        ErrorPopup("Error! Could not get features. Try again later.");
      }
    };

    fetchStatesData();
    fetchAmenitiesAndAccessibilitiesData();
  }, []);

  useEffect(() => {
    getTicketsHandler();
  }, [userType])



  const focusOnUnits = useCallback((selectedUnits) => {

  }, []);







  async function getCitiesForSelectedState(state) {
    setIsCitiesLoaded(false);

    const response = await activeCities(state);
    const { success, data } = response;

    if (success) {
      let formattedCities = data.map(formatData);
      setCities(formattedCities);
      setIsCitiesLoaded(true);
    } else {
      ErrorPopup("Error! Could not get cities. Try again later.");
    }

    function formatData(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
    };
  }

  const selectUnitHandler = (unit) => {
    // console.log(unit)
    if (!unit) return;
    setSelectedUnit(unit);


    setParams(prevValue => ({
      ...prevValue,
      BuildingID: unit.BuildingID,
      UnitID: unit.UnitID
    }));

  }

  const selectBuildingHandler = (buildingID) => {
    // console.log("selectBuildingHandler")
    const temp_buildingID = Array.isArray(buildingID) ? buildingID : buildingID ? [Number(buildingID)] : null;
    setSelectedBuilding(temp_buildingID);

    setParams(prevValue => ({
      ...prevValue,
      BuildingID: buildingID,
      UnitID: null
    }));
  }

  const closeModalUnitDetails = () => {
    setSelectedUnit(null);
    setSelectedBuilding(null);

    setParams(prevValue => ({
      ...prevValue,
      BuildingID: null,
      UnitID: null
    }));

  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = 'scroll'
    }
  }, [])

  const [showFilters, setShowFilters] = useState(false);
  const [resultCount, setResultCount] = useState(0);

  const openFilters = () => {
    setShowFilters(true);
  }

  const closeFilters = () => {
    setShowFilters(false);
  }

  const computedBuildings = useMemo(() => {
    if (!buildings) return;

    const properties = buildings.map(building => {
      const numberOfVacant = building.UnitIDs.length;

      return {
        ...building,
        numberOfVacant
      }
    })

    return properties;
  }, [buildings]);

  const [activeFilters, setActiveFilters] = useState({
    Price: '',
    Vacant: '',
    Date: ''
  });

  const changeHandler = (value, filterName) => {
    setActiveFilters(prevState => ({
      ...prevState,
      [filterName]: value
    }))
  }

  const clearFilterHandler = (filterName) => {
    setActiveFilters(prevState => ({
      ...prevState,
      [filterName]: ''
    }))
  }

  const resetFilters = () => {
    setActiveFilters({
      Price: '',
      Vacant: '',
      Date: ''
    })
  }

  const howManyFiltersActive = () => {
    return Object.entries(activeFilters).filter(([key, value]) => value).length;
  }

  const sortFromLowest = (propertyArray, key, transformFcn = (x) => x) => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [function (a) { return transformFcn(a[key]) }], ['asc'])
  }

  const sortFromHighest = (propertyArray, key, transformFcn = (x) => x) => {
    if (!propertyArray) return propertyArray;

    return orderBy(propertyArray, [function (a) { return transformFcn(a[key]) }], ['desc'])
  }

  const filterHandler = useMemo(() => {
    if (!computedBuildings) return [];
    let sortedAndFilteredArr = [...computedBuildings];

    if (activeFilters.Price === 'fromLowest') {
      sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'MinRentPrice');
    }

    if (activeFilters.Price === 'fromHighest') {
      sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'MaxRentPrice');
    }

    if (activeFilters.Vacant === 'fromLowest') {
      sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'numberOfVacant');
    }

    if (activeFilters.Vacant === 'fromHighest') {
      sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'numberOfVacant');
    }

    if (activeFilters.Date === 'fromLowest') {
      sortedAndFilteredArr = sortFromLowest(sortedAndFilteredArr, 'LastVacantDate', (a) => new Date(a).getTime());
    }

    if (activeFilters.Date === 'fromHighest') {
      sortedAndFilteredArr = sortFromHighest(sortedAndFilteredArr, 'LastVacantDate', (a) => new Date(a).getTime());
    }

    setResultCount(sortedAndFilteredArr.length);
    return sortedAndFilteredArr;
  }, [activeFilters.Price, activeFilters.Vacant, activeFilters.Date, computedBuildings]);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [params, setParams] = useState(null);

  const dispatch = useDispatch();

  const [animationInProgress, setAnimationInProgress] = useState(false);

  useEffect(() => {
    if (animationInProgress) {

      const timer = setTimeout(() => {
        setAnimationInProgress(false);
      }, 1200)

      return () => {
        clearTimeout(timer)
      }

    }
  }, [animationInProgress]);

  const [lastSearchedParams, setLastSearchedParams] = useState(null);

  const [buildingsPins, setBuildingPins] = useState([]);

  const getInitialSearchHandler = async (searchParams) => {
    if (!searchParams) return;

    setLoading(true);
    setHasUserSearched(true);
    if (!hasUserSearched) setAnimationInProgress(true);
    if (isMobile) {
      showComponentsHandler(SHOW_COMPONENT.LIST);
    }
    setShowSearchModal(false);

    const { success, data } = await getInitialSearch(searchParams);

    if (success) {
      setBuildingPins(data.Buildings || []);

      if (isMobile) {
        setVisibleBuildingsId();
      }

      const extrudeVisibleBuildingIDs = data.Buildings ? data.Buildings.map(({ BuildingID }) => BuildingID) : [];

      // dispatch(marketplaceActions.setUnits(onlyNonOccupiedUnits)); // TODO: check where it is used and if it is needed here

      setVisibleBuildingsId(extrudeVisibleBuildingIDs);

      setBuildings(data.Buildings);

      getLL(searchParams.City, searchParams.State) // TODO: check if it is still used

    } else {
      ErrorPopup();
    }
    setLoading(false);
  }

  const [isLoadingBuildingDetailedInfo, setIsLoadingBuildingDetailedInfo] = useState(false);
  const [buildingsDetails, setBuildingsDetails] = useState([]);

  const getBuildingInformation = async (BuildingID) => {
    if (!BuildingID) return;
    if (buildingsDetails.some(building => building.BuildingID === BuildingID)) return;

    setIsLoadingBuildingDetailedInfo(true);
    // const {success, data} = await getDataForMapPin(BuildingID);

    const { success, data } = await getBuilding(BuildingID, token);

    if (success) {
      setBuildingsDetails(prevBuildings => [
        ...prevBuildings,
        data
      ]);
    } else {
      //TODO: show error
    }
    setIsLoadingBuildingDetailedInfo(false);
  }

  useEffect(() => {

    if (params) {
      if (JSON.stringify(lastSearchedParams) !== JSON.stringify({ ...params, BuildingID: null, UnitID: null })) {


        setLastSearchedParams({ ...params, BuildingID: null, UnitID: null });

        setHasUserSearched(true);
        setLoading(true);

        setTimeout(() => {
          // searchUnits(params);
          getInitialSearchHandler(params);
        }, (searchAnimTime))

      }
    }
  }, [params])

  const endRef = useRef(null);

  const getFavoritesHandler = async (token) => {
    if (!token) return;

    // setIsLoading(true);
    const { success, data } = await getFavorites(token);

    if (success) {
      dispatch(favoriteActions.setFavorite(data));
    } else {
      ErrorPopup("Error! Could not get favorites.");
    }

    // setIsLoading(false);
  }

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteFavorite = async (id, type) => {
    if (typeof id !== 'number') throw new Error('First argument has to be of type number!');
    if (typeof type !== 'string') throw new Error('Second argument has to be of type string!');

    setIsDeleting(true);

    let result = { success: null, data: null };
    if (type === 'unit') result = await deleteFavoriteUnit(token, id);
    if (type === 'building') result = await deleteFavoriteBuilding(token, id);
    if (type === 'query') result = await deleteFavoriteQuery(token, id);
    const { success, data } = result;

    if (success) {

      if (type === 'unit') dispatch(favoriteActions.deleteFavoriteUnit(id));
      if (type === 'building') dispatch(favoriteActions.deleteFavoriteBuilding(id));
      if (type === 'query') dispatch(favoriteActions.deleteFavoriteQuery(id));

    } else {
      ErrorPopup(`Error! Something went wrong. Couldn't delete ${type} from favorites...`);
    }

    setIsDeleting(false);
  }

  useEffect(() => {
    if (token) {
      getFavoritesHandler(token);
    }
  }, [token]);

  useEffect(() => {

    if (!loading && endRef && endRef.current) {
      // endRef.current?.scrollIntoView({behavior: "smooth"});
      // window.scrollTo(0,document.body.scrollHeight);
      // document.body.scrollIntoView(0)
    }
    // const scrollDown = setTimeout(()=>{
    //   endRef.current?.scrollIntoView({behavior: "smooth"});
    // }, loading ? ((searchAnimTime + 2) * 1000) : 0)


  }, [endRef, endRef.current, loading])

  useEffect(() => {

    if (buildingsDetails && buildingsDetails.length && qBuildingID !== "null") {

      selectBuildingHandler(qBuildingID);

      if (qUnitID === "null") {
        selectUnitHandler(buildingsDetails.find(building => String(building.BuildingID) === qBuildingID)?.Units?.find(({ Vacant }) => Vacant));
      } else {
        selectUnitHandler(buildingsDetails.find(building => String(building.BuildingID) === qBuildingID)?.Units?.find(({ UnitID }) => String(UnitID) === qUnitID));
      }


    } else if (qBuildingID && qBuildingID !== "null") {
      setSelectedBuilding([qBuildingID]);
      if (qUnitID !== "null") {
        setSelectedUnit({ UnitID: qUnitID });
      }
    }

  }, [qBuildingID, qUnitID, buildingsDetails])


  const [isSavingQuery, setIsSavingQuery] = useState(false);
  const saveQueryHandler = async () => {

    if (!params) return;

    const preparedQueryData = {
      MinBedroomCount: params.MinBedroomCount || 0,
      MaxBedroomCount: params.MaxBedroomCount || 5,
      MinBathroomCount: params.MinBathroomCount || 0,
      MaxBathroomCount: params.MaxBathroomCount || 5,
      MinRentPrice: params.MinRentPrice || '',
      MaxRentPrice: params.MaxRentPrice || '',

      BuildingAmenityIDs: params.BuildingAmenityIDs || [],
      BuildingAccessibilityIDs: params.BuildingAccessibilityIDs || [],
      UnitAmenityIDs: params.UnitAmenityIDs || [],
      UnitAccessibilityIDs: params.UnitAccessibilityIDs || [],

      Zip: params.Zip || null,
      City: params.City || null,
      State: params.State || null
    }

    setIsSavingQuery(true);
    const { success, data } = await addFavoriteQuery(token, preparedQueryData);
    setIsSavingQuery(false);


    if (success) {
      let timerInterval

      Swal.fire({
        position: 'top',
        html: `Added query to favorites list!`,
        timer: 1200,
        timerProgressBar: true,
        showConfirmButton: false,
        // toast:true,
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      dispatch(favoriteActions.addFavoriteQuery({ ...preparedQueryData, QueryID: data }));

    } else {
      ErrorPopup("Error! Could not add to favorites. Try again later.");
    }
  }

  const [renderMap, setRenderMap] = useState(!isMobile);
  useEffect(() => {
    // Mainly for mobile - without it map won't render
    if (loading) {
      setRenderMap(false);
    }

    if (!loading && [SHOW_COMPONENT.MAP, SHOW_COMPONENT.BOTH].includes(showComponents)) {
      setRenderMap(true);
    }

  }, [showComponents, loading, isMobile])

  const [showLegendModal, setShowLegendModal] = useState(false);

  return (
    <Container
      fluid
      className={`bg-light p-0 custom_bg_marketplace ${hasUserSearched ? 'custom_bg_marketplace--big_nav' : ''} d-flex flex-column position-relative`}
    >
      <ModalDetails
        BuildingID={selectedBuilding}
        onHide={closeModalUnitDetails}
        alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
        setAlreadyRequestesUnitIDs={setAlreadyRequestesUnitIDs}
        UnitID={selectedUnit && selectedUnit.UnitID}

        favorites={favorites}
        deleteFavorite={deleteFavorite}
      />

      <Backdrop
        show={showFilters}
        close={closeFilters}
      />
      <Filters
        show={showFilters}
        close={closeFilters}
        resetFilters={resetFilters}
        howManyFiltersActive={howManyFiltersActive}
      >
        <ByValue
          clearFilterHandler={clearFilterHandler}
          activeFilters={activeFilters}
          changeHandler={changeHandler}
          title="Price"
        />

        {activeSideMenuTab !== SIDE_MENU_TABS.UNITS && (
          <ByValue
            clearFilterHandler={clearFilterHandler}
            activeFilters={activeFilters}
            changeHandler={changeHandler}
            title="Vacant"
          />
        )}

        <ByValue
          clearFilterHandler={clearFilterHandler}
          activeFilters={activeFilters}
          changeHandler={changeHandler}
          title="Date"
          firstBtnTitle="Oldest"
          secondBtnTitle="Newest"
        />
      </Filters>

      <Modal
        show={showLegendModal}
        onHide={() => setShowLegendModal(false)}
        centered
      >
        <Modal.Body>
          <h6 className="fw-bold">Legend:</h6>
          <MarketplaceLegend />
        </Modal.Body>

        <Modal.Footer>
          <ButtonPrimary
            title="Close"
            onClick={() => setShowLegendModal(false)}
          />
        </Modal.Footer>
      </Modal>

      {!hasUserSearched && (
        <MarketplaceTitle />
      )}

      <MarketplaceSearchMobile
        token={token}
        getLL={getLL}
        setHasUserSearched={setHasUserSearched}
        hasUserSearched={hasUserSearched}
        setLoading={setLoading}
        states={states}
        cities={cities}
        setCities={setCities}
        getCitiesForSelectedState={getCitiesForSelectedState}
        isCitiesLoaded={isCitiesLoaded}
        setIsCitiesLoaded={setIsCitiesLoaded}

        features={features}

        howManyFiltersActive={howManyFiltersActive}
        resultCount={resultCount}
        resetFilters={resetFilters}
        openFilters={openFilters}
        loading={loading}

        isMobile={isMobile}

        showSearchModal={showSearchModal}
        setShowSearchModal={setShowSearchModal}
        params={params}
        setParams={setParams}
        searchAnimTime={searchAnimTime}
        searchAnimDelay={searchAnimDelay}
      />

      {/* <Row className={`overflow-hidden mx-md-0 mapTransition ${(hasUserSearched && !loading) ? "off" : ""}`} style={{ transition: searchAnimTime +"s", transitionDelay: searchAnimDelay +"s"}}> */}
      <Row className={`overflow-hidden mx-0 mapTransition ${size.width < GRID_BREAKPOINTS.MD ? "disableAnim" : ""} ${(hasUserSearched) ? "off" : ""}`} style={{ transition: searchAnimTime + "s", transitionDelay: searchAnimDelay + "s" }}>
        {/* {(hasUserSearched && !loading) ? ( */}
        {(hasUserSearched) ? (
          <>
            <Col xs={12} md={6}
              id="Map"
              className={`p-2 pb-0 lightGray p-md-0 ${[SHOW_COMPONENT.MAP, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'd-block' : 'd-none'}`}
              style={{ height: [SHOW_COMPONENT.MAP].includes(showComponents) ? "calc(100vh - 70px - 54px - var(--header-height-mobile))" : "calc(100vh - 100px - var(--header-height-mobile))", overflowY: "auto" }}
            >
              {renderMap && (
                <MarketplaceMap
                  buildingsPins={buildingsPins}

                  selectBuildingHandler={selectBuildingHandler}

                  alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                  highlightBuildingWithID={highlightBuildingWithID}
                  setHighlightBuildingWithID={highlighBuildingWithIDHandler}
                  setVisibleBuildingsId={setVisibleBuildingsId}

                  focusOnUnits={focusOnUnits}

                  zoomIntoBuildingWithID={unitsForPropertyId}


                  LLValue={LLValue}
                  loading={loading || animationInProgress}

                  isMobile={isMobile}
                  showComponents={showComponents}
                  params={params}

                  favorites={favorites}
                />)}
            </Col>

            <Col
              xs={12} md={6}
              className={`lightGray p-2 p-md-3 ${[SHOW_COMPONENT.LIST, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'd-flex flex-column' : 'd-none'} ${[SHOW_COMPONENT.LIST].includes(showComponents) ? "pb-5" : "pb-3"}`}
              style={{ height: [SHOW_COMPONENT.LIST].includes(showComponents) ? "calc(100vh - 64px - var(--header-height-mobile))" : "calc(100vh - 100px - var(--header-height-mobile))" }}
            >
              <Row className="m-0 pb-2 pb-sm-3 p-0" ref={legendBar}>
                <Row className="m-0 p-0 justify-items-between align-items-center">

                  <Col className="marketplace-legend--desktop m-0 p-0 pe-1" xs={'auto'} >
                    <MarketplaceLegend />
                  </Col>

                  <Col className="marketplace-legend--mobile m-0 p-0 pe-1" xs={'auto'}>
                    <ButtonPrimary
                      onClick={() => setShowLegendModal(true)}
                      className="w-100 rounded"
                      additionalClassName={'disable-outline'}
                    >
                      <p className='m-0 p-0 fs-6' style={{ color: 'inherit' }}><i className='bi-stars'></i>&nbsp;<span className='d-none d-xl-inline'>Legend</span></p>
                    </ButtonPrimary>
                  </Col>

                  <Col className="m-0 p-0 flex-grow-1" xs={'auto'}>
                    <Row className="m-0 p-0 justify-content-end flex-nowrap">


                      <Col className={`m-0 p-0 pe-1 opacity-transition ${showPropertyDetails ? 'opacity-transition--show' : ''}`} xs={'auto'}>
                        <ButtonSecondary
                          onClick={backButtonHandler}
                          className="w-100 rounded"
                          additionalClassName={'disable-outline'}
                          disabled={!showPropertyDetails}
                        >
                          <p className='m-0 p-0 fs-6' style={{ color: 'inherit' }}><i className='bi bi-chevron-left'></i>&nbsp;<span>Back</span></p>
                        </ButtonSecondary>
                      </Col>


                      <Col className="m-0 p-0 pe-1" xs="auto">
                        <ButtonPrimary
                          onClick={saveQueryHandler}
                          variant="warning"
                          className="w-100 rounded"
                          disabled={isSavingQuery || !userType}
                        >
                          <i className='d-block d-xl-none bi bi-star'></i>
                          <p className='d-none d-xl-block m-0 ps-2 fs-6' style={{ color: 'inherit' }}>{isSavingQuery ? 'Saving...' : 'Save Search'}</p>

                        </ButtonPrimary>
                      </Col>

                      <FiltersBtn
                        howManyFiltersActive={howManyFiltersActive}
                        results={resultCount}
                        openFilters={openFilters}
                        disabled={!(!loading && buildings && buildings.length)}
                        showResults={false}
                        btnTitle={"Sort"}
                        iconClass={"bi bi-arrow-down-up"}
                      />
                    </Row>
                  </Col>
                </Row>
              </Row>

              <Row className="m-0 p-0 flex-grow-1 flex-nowrap overflow-hidden">
                <Col xs={12}
                  style={{ overflowY: 'scroll' }}
                  className={`m-0 p-0 h-100 wissp-scroll-box marketplace-side-menu-tab ${activeSideMenuTab === SIDE_MENU_TABS.UNITS ? 'marketplace-side-menu-tab--animate' : ''}`}
                >
                  <Row className={`m-0 pe-1 flex-column flex-nowrap h-100`}>
                    <SideMenu
                      loading={loading || animationInProgress}
                      alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                      selectUnitHandler={selectUnitHandler}
                      selectBuildingHandler={selectBuildingHandler}
                      highlightBuildingWithID={highlightBuildingWithID}
                      visibleBuildingsId={visibleBuildingsId}
                      setHighlightBuildingWithID={setHighlightBuildingWithID}

                      filterHandler={filterHandler}

                      howManyFiltersActive={howManyFiltersActive}
                      resultCount={resultCount}
                      resetFilters={resetFilters}
                      openFilters={openFilters}
                      isMobile={isMobile}

                      expandPropertyHandler={expandPropertyHandler}
                      favorites={favorites}
                    />
                  </Row>
                </Col>
                <Col xs={12}
                  className={`m-0 p-0 h-100 overflow-auto marketplace-side-menu-tab ${activeSideMenuTab === SIDE_MENU_TABS.UNITS ? 'marketplace-side-menu-tab--animate' : ''}`}
                >
                  <Row className={`m-0 pe-1`}>
                    <PropertyUnits
                      buildings={buildingsDetails}
                      isLoading={isLoadingBuildingDetailedInfo}

                      unitsForPropertyId={unitsForPropertyId}
                      expandedProperties={expandedProperties}

                      activeFilters={activeFilters}

                      alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                      selectUnitHandler={selectUnitHandler}
                      favorites={favorites}
                    />
                  </Row>
                </Col>
              </Row>

            </Col>
          </>
        ) : null}
      </Row>


      {(hasUserSearched && isMobile) && (
        <Row className="m-0 p-2 py-2 w-100 position-fixed bottom-0 start-0 bg-light">
          <Col className="m-0 me-2 p-0">
            <Button className={`w-100 rounded ${[SHOW_COMPONENT.LIST, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'fw-bolder' : 'fw-normal'}`}
              onClick={() => showComponentsHandler(SHOW_COMPONENT.LIST)}
            >List</Button>
          </Col>

          <Col className="m-0 p-0">
            <Button className={`w-100 rounded ${[SHOW_COMPONENT.MAP, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'fw-bolder' : 'fw-normal'}`}
              onClick={() => showComponentsHandler(SHOW_COMPONENT.MAP)}
            >Map</Button>
          </Col>
        </Row>
      )}
      <div ref={endRef}></div>
    </Container>
  );
}



export function MarketplaceTitle() {

  const title = "Unlock your next home!"

  return (
    <Row className="d-flex m-0 p-0 py-5 top-0 w-100 position-absolute">
      <h1 className="text-center marketplace-title">
        {title.split("").map((el, i) => <span key={i} style={{
          animationDelay: Math.random() * 1.5 + .2 + "s"
        }}>{el}</span>)}
      </h1>
    </Row>
  )
}
