import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import {
  Container,
  Row
} from "react-bootstrap";
import { Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { favoriteActions } from "../store/FavoriteSlice";
import { featuresActions } from "../store/FeaturesSlice";

import UnitAndBuildingInfoModal from "../components/Shared/UnitAndBuildingInfoModal";
import { orderBy, cloneDeep } from "lodash";

import { getFavorites, deleteFavoriteBuilding, deleteFavoriteUnit, deleteFavoriteQuery } from '../services/favorites';
import { getTickets } from '../services/tickets';
import { getFeatures } from "../services/marketplace";

import { AuthContext } from "../contexts/AuthContext"; 
import { UserTypeContext } from '../contexts/UserTypeContext';
import { TicketsContext } from '../contexts/TicketsContext';

import FavoriteNavigation from "../components/Favorite/FavoriteNavigation";
import FavoriteBox from "../components/Favorite/FavoriteBox";
import FavoriteBoxQuery from '../components/Favorite/FavoriteBoxQuery';
import Backdrop from '../components/Shared/Backdrop';
import EmptyFavorites from "../components/Favorite/EmptyFavorites";
import FavoriteBoxLoader from "../components/Favorite/FavoriteBoxLoader";

import ModalDetails from "../components/Marketplace/ModalDetails";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";

const FAVORITE_TYPE = Object.freeze({
  BUILDINGS: 'Buildings',
  QUERIES: 'Queries',
  UNITS: 'Units'
});

const LOCATION = Object.freeze({
  BUILDINGS: 'buildings',
  UNITS: 'units',
  QUERIES: 'queries'
})

export default function Favorites() {
  let { path, url } = useRouteMatch();
  const favorites = useSelector(store => store.favoriteStore);
  const isFirstDownload = useSelector(store => store.favoriteStore.isFirstDownload);
  const { userType } = useContext(UserTypeContext);
  const { tickets, setTickets } = useContext(TicketsContext);

  const features = useSelector(store => store.featuresStore);

  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const { pathname } = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeaturesLoading, setIsFeaturesLoading] = useState(false);
  const [alreadyRequestesUnitIDs, setAlreadyRequestesUnitIDs] = useState(null);

  const [resultCount, setResultCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState({
    Alphabetically: '',
    City: '',
    State: '',
    Status: '',
    MinPrice: '',
    MaxPrice: '',
    Amenities: [],
    Accessibilities: [],

    MinBedrooms: '',
    MaxBedrooms: '',
    MinBathrooms: '',
    MaxBathrooms: '',
    
    BuildingAmenityIDs: [],
    BuildingAccessibilityIDs: [],
    UnitAmenityIDs: [],
    UnitAccessibilityIDs: []
  });

  const changeHandler = (value, filterName) => {
    setActiveFilters( prevState => ({
      ...prevState,
      [filterName]: value
    }))
  }

  const featuresChangeHandler = (filterName, featureName) => {
    setActiveFilters( prevState => ({
      ...prevState,
      [filterName]: prevState[filterName].includes(featureName) ? prevState[filterName].filter( _featureName => _featureName !== featureName) : [...prevState[filterName], featureName]
    }))
  }

  const resetFilters = () => {
    setActiveFilters({
      Alphabetically: '',
      City: '',
      State: '',
      Status: '',
      MinPrice: '',
      MaxPrice: '',
      Amenities: [],
      Accessibilities: [],

      MinBedrooms: '',
      MaxBedrooms: '',
      MinBathrooms: '',
      MaxBathrooms: '',
      BuildingAmenityIDs: [],
      BuildingAccessibilityIDs: [],
      UnitAmenityIDs: [],
      UnitAccessibilityIDs: []
    })
  }
  const clearFilterHandler = (filterName, defaultValue='') => {
    setActiveFilters( prevState => ({
      ...prevState,
      [filterName]: defaultValue
    }))
  }
  const howManyFiltersActive = () => {
    return Object.entries(activeFilters).filter( ([_, value]) => {
      if(Array.isArray(value)) return value.length > 0;
      return !!value;
    }).length;
  }

  const getFavoritesHandler = useCallback(async (token) => {
    if(!token || !favorites.isFirstDownload) return;

    setIsLoading(true);
    const { success, data } = await getFavorites(token);

    if(success) {
      dispatch(favoriteActions.setFavorite(data));
    } else {
      ErrorPopup();
    }

    setIsLoading(false);
  }, [dispatch, favorites.isFirstDownload]);

  const deleteFavorite = async (id, type) => {
    setIsDeleting(true);

    let result = {success: null, data: null};
    if(type === 'unit') result = await deleteFavoriteUnit(token, id);
    if(type === 'building') result = await deleteFavoriteBuilding(token, id);
    if(type === 'query') result = await deleteFavoriteQuery(token, id);
    const { success } = result;

    if(success) {
      
      if(type === 'unit') dispatch(favoriteActions.deleteFavoriteUnit(id));
      if(type === 'building') dispatch(favoriteActions.deleteFavoriteBuilding(id));
      if(type === 'query') dispatch(favoriteActions.deleteFavoriteQuery(id));

    } else {
      ErrorPopup(`Error! Couldn't delete ${type} from favorites. Try again later.`);
    }

    setIsDeleting(false);
  }

  const [showModal, setShowModal] = useState(false);
  const [modalInfoData, setModalInfoData] = useState({});

  const showModalInfo = (BuildingID, UnitID) => {
    setModalInfoData({
        BuildingID,
        UnitID
    });
    setShowModal(true);
  }

  const closeModal = () => {
    setModalInfoData({});
    setShowModal(null);
  }

  const filtersRenderData = useMemo(() => {
    if( !favorites ) return {};
    
    const filterData = {
      Buildings: {
        Accessibilities: [],
        Amenities: [],
        State: [],
        City: []
      },
      Units: {
        Accessibilities: [],
        Amenities: [],
        State: [],
        City: []
      },
      Queries: {
        State: [],
        City: [],
        BuildingAmenityIDs: [],
        BuildingAccessibilityIDs: [],
        UnitAmenityIDs: [],
        UnitAccessibilityIDs: []
      }
    }

    if( favorites.Buildings ) {
      const buildingsExtractedData = extractDataForFilters(favorites.Buildings);
      filterData.Buildings.Accessibilities = Array.from(new Set(buildingsExtractedData.Accessibilities));
      filterData.Buildings.Amenities = Array.from(new Set(buildingsExtractedData.Amenities));
      filterData.Buildings.State = Array.from(new Set(buildingsExtractedData.State));
      filterData.Buildings.City = Array.from(new Set(buildingsExtractedData.City));
    }

    if( favorites.Units ) {
      const unitsExtractedData = extractDataForFilters(favorites.Units);
      filterData.Units.Accessibilities = Array.from(new Set(unitsExtractedData.Accessibilities));
      filterData.Units.Amenities = Array.from(new Set(unitsExtractedData.Amenities));
      filterData.Units.State = Array.from(new Set(unitsExtractedData.State));
      filterData.Units.City = Array.from(new Set(unitsExtractedData.City));
    }

    if( favorites.Queries ) {
      const queriesExtractedData = extractDataForFilters(favorites.Queries, { 
        BuildingAccessibilityIDs: [], 
        BuildingAmenityIDs: [], 
        UnitAccessibilityIDs: [], 
        UnitAmenityIDs: [], 
        City: [], 
        State: [] 
      });

      filterData.Queries.BuildingAccessibilityIDs = Array.from(new Set(queriesExtractedData.BuildingAccessibilityIDs));
      filterData.Queries.BuildingAmenityIDs = Array.from(new Set(queriesExtractedData.BuildingAmenityIDs));
      filterData.Queries.UnitAccessibilityIDs = Array.from(new Set(queriesExtractedData.UnitAccessibilityIDs));
      filterData.Queries.UnitAmenityIDs = Array.from(new Set(queriesExtractedData.UnitAmenityIDs));
      filterData.Queries.State = Array.from(new Set(queriesExtractedData.State));
      filterData.Queries.City = Array.from(new Set(queriesExtractedData.City));
    }

    function extractDataForFilters( a_dataToReadFrom, initialData={ Accessibilities: [], Amenities: [], City: [], State: [] } ) {
      if(!a_dataToReadFrom) { return initialData || {} };

      return a_dataToReadFrom.reduce( (acc, data) => {
        Object.entries(initialData).forEach(([key,_]) => {
          if(data[key] && Array.isArray(data[key])) {
            acc[key].push(...data[key])
          } else if (data[key]) {
            acc[key].push(data[key])
          }
        })
        return acc;
      }, initialData );
    }

    return filterData;
  }, [favorites]);

  const sortAscending = (propertyArray) => {
    if(!propertyArray) return propertyArray;

    return orderBy(propertyArray, ['Name'], ['asc'])
  }

  const sortDescending = (propertyArray) => {
    if(!propertyArray) return propertyArray;

    return orderBy(propertyArray, ['Name'], ['desc'])
  }

  const filteredFavorites = useMemo(() => {
    setIsLoading(false);
    if( !favorites ) return [];

    let filteredData = [];

    switch( true ) {
      case pathname.search(LOCATION.UNITS) !== -1:
        filteredData = prepareFilteredData( favorites[FAVORITE_TYPE.UNITS], FAVORITE_TYPE.UNITS, activeFilters);
        break;
      case pathname.search(LOCATION.BUILDINGS) !== -1:
        filteredData = prepareFilteredData( favorites[FAVORITE_TYPE.BUILDINGS], FAVORITE_TYPE.BUILDINGS, activeFilters);
        break;
      case pathname.search(LOCATION.QUERIES) !== -1:
        filteredData = prepareFilteredData( favorites[FAVORITE_TYPE.QUERIES], FAVORITE_TYPE.QUERIES, activeFilters);
        break;
      default:
        break;
    }

    setResultCount(filteredData.length);
    return filteredData;

    function prepareFilteredData( a_data, favoriteType, activeFilters){
      if(!Array.isArray(a_data)) return [];

      let sortedAndFilteredArr = cloneDeep(a_data);
      
      if(activeFilters.Alphabetically === 'asc') {
        sortedAndFilteredArr = sortAscending(sortedAndFilteredArr);
      }
  
      if(activeFilters.Alphabetically === 'desc') {
        sortedAndFilteredArr = sortDescending(sortedAndFilteredArr);
      }

      if(activeFilters.MinPrice) {
        switch(favoriteType) {
          case FAVORITE_TYPE.UNITS: 
            sortedAndFilteredArr = sortedAndFilteredArr.filter( unit => unit.Rent > activeFilters.MinPrice );
            break;
          case FAVORITE_TYPE.BUILDINGS: 
            sortedAndFilteredArr = sortedAndFilteredArr.filter( building => building.Units.reduce((acc, unit)=> {
              if(unit.Rent < acc) acc = unit.Rent;
              return acc;
            }, Number.MAX_VALUE) >= activeFilters.MinPrice );
            break;

          case FAVORITE_TYPE.QUERIES:
            sortedAndFilteredArr = sortedAndFilteredArr.filter( query => activeFilters.MinPrice <= (query.MaxRentPrice || Number.MAX_VALUE))
            break;

          default:
            break;
        }
      } 

      if(activeFilters.MaxPrice) {
        switch(favoriteType) {
          case FAVORITE_TYPE.UNITS: 
            sortedAndFilteredArr = sortedAndFilteredArr.filter( unit => unit.Rent <= activeFilters.MaxPrice );
            break;
          case FAVORITE_TYPE.BUILDINGS: 
            sortedAndFilteredArr = sortedAndFilteredArr.filter( building => building.Units.reduce((acc, unit)=> {
              if(unit.Rent > acc) acc = unit.Rent;
              return acc;
            }, Number.MIN_VALUE) < activeFilters.MaxPrice );
            break;

          case FAVORITE_TYPE.QUERIES:
            sortedAndFilteredArr = sortedAndFilteredArr.filter( query => (activeFilters.MaxPrice >= (query.MinRentPrice || 0)) && (activeFilters.MaxPrice <= (query.MaxRentPrice || Number.MAX_VALUE)))
            break;

          default:
            break;
        }
      } 

      // UNITS
      if(favoriteType === FAVORITE_TYPE.UNITS && activeFilters.MinBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.BathroomCount >= activeFilters.MinBathrooms);
      }
      if(favoriteType === FAVORITE_TYPE.UNITS && activeFilters.MaxBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.BathroomCount <= activeFilters.MaxBathrooms);
      }
      if(favoriteType === FAVORITE_TYPE.UNITS && activeFilters.MinBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.BedroomCount >= activeFilters.MinBedrooms);
      }
      if(favoriteType === FAVORITE_TYPE.UNITS && activeFilters.MaxBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.BedroomCount <= activeFilters.MaxBedrooms);
      }

      // BUILDINGS
      if(favoriteType === FAVORITE_TYPE.BUILDINGS && activeFilters.MinBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.Units.some( data => data.BathroomCount >= activeFilters.MinBathrooms));
      }
      if(favoriteType === FAVORITE_TYPE.BUILDINGS && activeFilters.MaxBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.Units.some( data => data.BathroomCount <= activeFilters.MaxBathrooms));
      }
      if(favoriteType === FAVORITE_TYPE.BUILDINGS && activeFilters.MinBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.Units.some( data => data.BedroomCount >= activeFilters.MinBedrooms));
      }
      if(favoriteType === FAVORITE_TYPE.BUILDINGS && activeFilters.MaxBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.Units.some( data => data.BedroomCount <= activeFilters.MaxBedrooms));
      }

      // QUERIES
      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.MinBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => activeFilters.MinBathrooms <= data.MaxBathroomCount );
      }
      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.MaxBathrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => (activeFilters.MaxBathrooms >= data.MinBathroomCount) );
      }
      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.MinBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => activeFilters.MinBedrooms <= data.MaxBedroomCount );
      }
      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.MaxBedrooms) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => (activeFilters.MaxBedrooms >= data.MinBedroomCount) );
      }

      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.BuildingAmenityIDs && activeFilters.BuildingAmenityIDs.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ BuildingAmenityIDs }) => {
          return activeFilters.BuildingAmenityIDs.every( feature => {
            return BuildingAmenityIDs && BuildingAmenityIDs.includes(feature)
          });
        } );
      }

      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.BuildingAccessibilityIDs && activeFilters.BuildingAccessibilityIDs.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ BuildingAccessibilityIDs }) => {
          return activeFilters.BuildingAccessibilityIDs.every( feature => {
            return BuildingAccessibilityIDs && BuildingAccessibilityIDs.includes(feature)
          });
        } );
      }

      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.UnitAmenityIDs && activeFilters.UnitAmenityIDs.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ UnitAmenityIDs }) => {
          return activeFilters.UnitAmenityIDs.every( feature => {
            return UnitAmenityIDs && UnitAmenityIDs.includes(feature)
          });
        } );
      }

      if(favoriteType === FAVORITE_TYPE.QUERIES && activeFilters.UnitAccessibilityIDs && activeFilters.UnitAccessibilityIDs.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ UnitAccessibilityIDs }) => {
          return activeFilters.UnitAccessibilityIDs.every( feature => {
            return UnitAccessibilityIDs && UnitAccessibilityIDs.includes(feature)
          });
        } );
      }












  
      if(activeFilters.State) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.State === activeFilters.State);
      }
  
      if(activeFilters.City) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => data.City === activeFilters.City);
      }

      if(activeFilters.Status && favoriteType === FAVORITE_TYPE.UNITS) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( data => (activeFilters.Status === 'Vacant') === data.Vacant );
      }

      if(activeFilters.Accessibilities && activeFilters.Accessibilities.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ Accessibilities }) => {
          return activeFilters.Accessibilities.every( feature => Accessibilities && Accessibilities.includes(feature));
        } );
      }

      if(activeFilters.Amenities && activeFilters.Amenities.length) {
        sortedAndFilteredArr = sortedAndFilteredArr.filter( ({ Amenities }) => {
          return activeFilters.Amenities.every( feature => Amenities && Amenities.includes(feature));
        } );
      }

      return sortedAndFilteredArr;
    }
  }, [activeFilters, favorites, pathname]);

  const getFeaturesHandler = useCallback(async () => {
    if( features.isEmpty ) {
      setIsFeaturesLoading(true);
      const { success, data } = await getFeatures();
      if(success) {
        dispatch(featuresActions.setFeatures(data));
      } else {
        ErrorPopup();
      }
      setIsFeaturesLoading(false);
    }
  }, [dispatch, features.isEmpty]);

  useEffect(() => {
    const getTicketsHandler = async () => {
      if (userType === 'Tenant') {
        if (!tickets) {
          const response = await getTickets(token, 'tenant');
          const { success, data } = response;
  
          if (success) {
            setAlreadyRequestesUnitIDs(extractUnitsID(data));
            setTickets(data);
          }
  
        } else {
          setAlreadyRequestesUnitIDs(extractUnitsID(tickets));
        }
      }
  
      function extractUnitsID(data) {
        return data.filter(ticket => ticket.Status).map(ticket => ticket.UnitID);
      }
    }

    getTicketsHandler();
  }, [userType])

  useEffect(() => {
    if(token) {
      getFavoritesHandler(token);
      getFeaturesHandler();
    }
  }, [token, getFavoritesHandler, getFeaturesHandler]);

  return (
  <>
    { userType !== 'Tenant' && (
      <UnitAndBuildingInfoModal 
          onHide={closeModal}
          showModal={showModal}
          unitAndBuildingIDs={modalInfoData}
          favorites={favorites}
      />
    )}

    { userType === 'Tenant' && (
      <ModalDetails
        BuildingID={ modalInfoData.BuildingID ? [modalInfoData.BuildingID] : null }
        onHide={ closeModal }
        alreadyRequestesUnitIDs={ alreadyRequestesUnitIDs}
        setAlreadyRequestesUnitIDs={ setAlreadyRequestesUnitIDs }
        UnitID={ modalInfoData.UnitID ? modalInfoData.UnitID : null }
        favorites={favorites}
      />
    )}

    <Backdrop 
      show={isDeleting}
    />

    <Container fluid style={{maxWidth: 1200}} className="m-auto mb-md-5 px-2">
      
      <FavoriteNavigation 
        results={resultCount}
        activeFilters={activeFilters}
        changeHandler={changeHandler}
        featuresChangeHandler={featuresChangeHandler}
        resetFilters={resetFilters}
        clearFilterHandler={clearFilterHandler}
        howManyFiltersActive={howManyFiltersActive}
        loading={isLoading || isFeaturesLoading}

        filtersRenderData={filtersRenderData}
        features={features}
      />

      <Switch>

        <Route exact path={path}>
          <Redirect to={`${url}/units`}/>
        </Route>

        <Route path={`${path}/units`}>

        { ((isLoading || isFeaturesLoading) || isFirstDownload) ? (
            <Row className="m-0 p-0 mt-3">
              <FavoriteBoxLoader/>
              <FavoriteBoxLoader/>
            </Row>
          ) : 

             !isLoading && filteredFavorites && !isFirstDownload && !filteredFavorites.length ? (
            <EmptyFavorites 
              favoriteType='units'
            />
          ) : (
            <Row className="m-0 p-0 mt-3">
              { activeFilters.Status === 'Occupied' ? (<h6 className="m-0 mb-3 p-0 fw-bold text-danger text-center">These units are no longer available</h6>) : '' }


              { filteredFavorites && filteredFavorites.map( (favoriteUnit, index) => (
                <FavoriteBox 
                  key={'Unit_'+index}
                  data={{
                    name: `Unit: ${favoriteUnit.Name}`,
                    address: `${favoriteUnit.Address}, ${favoriteUnit.City}, ${favoriteUnit.State}`,
                    City: favoriteUnit.City,
                    State: favoriteUnit.State,
                    UnitID: favoriteUnit.UnitID,
                    BuildingID: favoriteUnit.BuildingID,
                    Rent: favoriteUnit.Rent,
                    type: "unit"
                  }}
                  openHandler={() => showModalInfo(favoriteUnit.BuildingID, favoriteUnit.UnitID)}
                  deleteHandler={() => deleteFavorite(favoriteUnit.UnitID, 'unit')}
                />
              )
              )}
            </Row>
          ) }



        </Route>

        <Route path={`${path}/buildings`}>

          <Row className="m-0 p-0">

            { (isLoading || isFeaturesLoading) && isFirstDownload && (
              <Row className="m-0 p-0 mt-3">
                <FavoriteBoxLoader/>
                <FavoriteBoxLoader/>
              </Row>
            )}
            { !isLoading && !isFeaturesLoading && !isFirstDownload && filteredFavorites && !filteredFavorites.length ? (
              <EmptyFavorites 
                favoriteType='buildings'
              />
            ) : (
              <Row className="m-0 p-0 mt-3">
                { filteredFavorites && filteredFavorites.map( (favoriteBuilding, index) => (
                  <FavoriteBox 
                    key={'Building_'+index}
                    data={{
                      name: `Property: ${favoriteBuilding.Name}`,
                      address: `${favoriteBuilding.Address}, ${favoriteBuilding.City}, ${favoriteBuilding.State}`,
                      City: favoriteBuilding.City,
                      State: favoriteBuilding.State,
                      UnitID: null,
                      BuildingID: favoriteBuilding.BuildingID,
                      type: "building",
                      Units: favoriteBuilding.Units
                    }}
                    openHandler={() => showModalInfo(favoriteBuilding.BuildingID, null)}
                    deleteHandler={() => deleteFavorite(favoriteBuilding.BuildingID, 'building')}
                  />
                ))}
              </Row>
            ) }
          </Row>
        </Route>

        <Route path={`${path}/queries`}>
          <Row className="m-0 p-0">

            { (isLoading || isFeaturesLoading) && isFirstDownload && (
              <Row className="m-0 p-0 mt-3">
                <FavoriteBoxLoader/>
                <FavoriteBoxLoader/>
              </Row>
            )}
            { !isLoading && !isFeaturesLoading && !isFirstDownload && filteredFavorites && !filteredFavorites.length ? (
              <EmptyFavorites 
                favoriteType='queries'
              />
            ) : (
              <Row className="m-0 p-0 mt-3">
                { filteredFavorites && filteredFavorites.map( (favoriteQuery, index) => (
                  <FavoriteBoxQuery 
                    key={'Query_'+index}
                    data={favoriteQuery}
                    features={features}

                    deleteHandler={() => deleteFavorite(favoriteQuery.QueryID, 'query')}
                  />
                ))}
              </Row>
            ) }

          </Row>

        </Route>

      </Switch>
    
    </Container>
  </>
  );
}