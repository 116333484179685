import React, {  useEffect,  } from "react";
import {
 
    Form,

} from "react-bootstrap";

import DecisionModal from "../Shared/InfoPopups/DecisionModal";
import { object as YupObject, string as YupString, boolean as YupBoolean } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';

import OccupantFields from "../UnitFormElements/OccupantFields";
import { 
    VACANT, 
    OCCUPANT_FULL_NAME, 
    OCCUPANT_EMAIL, 
    OCCUPANT_PHONE_NUMBER, 
    OCCUPANT_LEASE_START, 
    OCCUPANT_LEASE_END, 
    OCCUPANT_NOTES 
} from '../../constants/unitFormConstants';



const schema = YupObject().shape({
    [VACANT]: YupBoolean()
        .required("Required"),
    [OCCUPANT_FULL_NAME]: YupString()
        .when("vacant", {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .required("Name is required"),
        }),
    [OCCUPANT_EMAIL]: YupString()
        .when("vacant", {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .email('Invalid email address')
                .required('Required'),
        }),
    [OCCUPANT_PHONE_NUMBER]: YupString()
        .when("vacant", {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
                    "Provide a valid phone number")
                .required("Required"),
        }),
    [OCCUPANT_LEASE_START]: YupString()
        .when("vacant", {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
    [OCCUPANT_LEASE_END]: YupString()
        .when("vacant", {
            is: false,
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
    [OCCUPANT_NOTES]: YupString()
        .when("vacant", {
            is: "Occupied",
            then: YupString()
                .transform(value => value.trim())
                .notRequired()
        }),
});

export default function OccupantInformation({
    onEditOccupant,
    onDeleteOccupant,
    sectionFocus,
    setSectionFocus,
    unit,
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { register, setValue, watch, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const vacantWatch = watch(VACANT, true);
    const watchOccupantLeaseStart = watch(OCCUPANT_LEASE_START, '');
    const watchOccupantLeaseEnd = watch(OCCUPANT_LEASE_END, '');

    const deleteOccupantHandler = async () => {
        if (unit && unit.Tenant && unit.Tenant.FullName && unit.Tenant.Email && unit.Tenant.Phone) {
            const decision = await DecisionModal({
                message: "Switching to vacant will delete informations about current occupant"
            });

            if (!decision.isConfirmed) return;
            
            const isSuccess = await onDeleteOccupant();
            if (isSuccess) {
                reset({
                    [VACANT]: true,
                    [OCCUPANT_FULL_NAME]: '',
                    [OCCUPANT_EMAIL]: '',
                    [OCCUPANT_PHONE_NUMBER]: '',
                    [OCCUPANT_LEASE_START]: '',
                    [OCCUPANT_LEASE_END]: '',
                    [OCCUPANT_NOTES]: '',
                });
            };
        }
        setValue(VACANT, true);
    }

    useEffect(() => {
        if (unit) {
            if (unit.Tenant) {
                reset({
                    [VACANT]: unit.Vacant,
                    [OCCUPANT_FULL_NAME]: unit.Tenant.FullName || '',
                    [OCCUPANT_EMAIL]: unit.Tenant.Email || '',
                    [OCCUPANT_PHONE_NUMBER]: unit.Tenant.Phone || '',
                    [OCCUPANT_LEASE_START]: unit.Tenant.LeaseStart ? new Date(unit.Tenant.LeaseStart) : '',
                    [OCCUPANT_LEASE_END]: unit.Tenant.LeaseEnd ? new Date(unit.Tenant.LeaseEnd) : '',
                    [OCCUPANT_NOTES]: unit.Tenant.Comments || '',
                });

            } else {
                reset({
                    [VACANT]: unit.Vacant,
                })
            }
        }
    }, [unit, reset]);

    return (
        <Form
            onSubmit={handleSubmit(onEditOccupant)}
            className={`${sectionFocus && sectionFocus !== "Occupant Information" ? "d-blurred prev-blur" : "prev-blur"} m-0 p-0`}
            onMouseEnter={() => { setSectionFocus("Occupant Information") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >
            <OccupantFields 
                deleteOccupantHandler={deleteOccupantHandler}
                vacantWatch={vacantWatch}
                setValue={setValue}
                register={register}
                errors={errors}
                watchOccupantLeaseStart={watchOccupantLeaseStart}
                watchOccupantLeaseEnd={watchOccupantLeaseEnd}
                isEditMode={true}
                s_registerNameForVacant={VACANT}
                s_registerNameForOccupantFullName={OCCUPANT_FULL_NAME}
                s_registerNameForOccupantPhoneNumber={OCCUPANT_PHONE_NUMBER}
                s_registerNameForOccupantEmail={OCCUPANT_EMAIL}
                s_registerNameForOccupantLeaseStart={OCCUPANT_LEASE_START}
                s_registerNameForOccupantLeaseEnd={OCCUPANT_LEASE_END}
                s_registerNameForOccupantNotes={OCCUPANT_NOTES}
            />
        </Form>

    )
}
