import React, { useState, useEffect, useContext, useCallback } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";
import { UserTypeContext } from "../../contexts/UserTypeContext";

import GuardedNavLink from "../Header/GuardedNavLink";
import { GUARDED, NORMAL } from "../../constants/Header/linkTypes";
import { MANAGER, LANDLORD, TENANT, CASE_MANAGER } from "../../constants/Shared/userTypes";
import SideNavigation from '../Header/SideNavigation';
import Backdrop from "./Backdrop";
import HeaderNavLink from '../Header/HeaderNavLink';
import DropdownMenu from "./DropdownMenu";
import useLogout from "../../hooks/useLogout";

import WISSP_Full_LogoS from '../../assets/logos/WISSP_Full.png';
import WISSP_Full_LogoL from '../../assets/logos/WISSP_Full_Large.png';
import WISSP_Submark_LogoS from '../../assets/logos/WISSP_Submark.png';
import WISSP_Submark_LogoL from '../../assets/logos/WISSP_Submark_Large.png';

const navigationLinks = [
  // {
  //   type: GUARDED,
  //   to: "/command-center",
  //   title: "Command Center",
  //   icon: "bi-kanban",
  //   allowedUserTypes: [CASE_MANAGER],
  // },
  // {
  //   type: GUARDED,
  //   to: "/clients",
  //   title: "Clients",
  //   icon: "bi-file-earmark-person",
  //   allowedUserTypes: [CASE_MANAGER],
  // },
  {
    type: GUARDED,
    to: "/portfolio",
    title: "Portfolio",
    icon: 'bi-person-lines-fill',
    allowedUserTypes: [TENANT],
  },
  {
    type: GUARDED,
    to: "/tenant-screening",
    title: "Screening",
    icon: 'bi-journal-check',
    allowedUserTypes: [TENANT],
  },
  {
    type: GUARDED,
    to: "/messages",
    title: "Requests",
    icon: 'bi-envelope',
    allowedUserTypes: [TENANT],
  },
  {
    type: GUARDED,
    to: "/manager-profile",
    title: "Profile",
    icon: 'bi-person-check',
    allowedUserTypes: [MANAGER],
  },
  {
    type: GUARDED,
    to: "/properties",
    title: "Properties",
    icon: 'bi-house-door',
    allowedUserTypes: [LANDLORD, MANAGER],
  },
  {
    type: GUARDED,
    to: "/messages",
    title: "Messages",
    icon: "bi-envelope",
    allowedUserTypes: [LANDLORD, MANAGER],
  },
  {
    type: GUARDED,
    to: "/add",
    title: "Add New Unit",
    icon: 'bi-plus-square',
    allowedUserTypes: [LANDLORD],
  },
  {
    type: GUARDED,
    to: "/favorites",
    title: "Favorites",
    icon: "bi-star",
    allowedUserTypes: [LANDLORD, MANAGER, TENANT, CASE_MANAGER],
  },
  {
    type: NORMAL,
    to: "/marketplace",
    icon: 'bi-shop-window',
    title: "Marketplace",
  },
  // {
  //   type: GUARDED,
  //   to: "/team",
  //   title: "Team",
  //   icon: "bi-people",
  //   allowedUserTypes: [LANDLORD, MANAGER, TENANT, CASE_MANAGER],
  // },
  {
    type: NORMAL,
    to: "/about-us",
    icon: 'bi-info-circle',
    title: "About Us"
  }
];


export default function Header() {
  const { token, initials } = useContext(AuthContext); //TODO: Create Redux Store then delete context
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [ openDropdown, setOpenDropdown] = useState(false);
  const [ smallNav, setSmallNav ] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { userType } = useContext(UserTypeContext); //TODO: Create Redux Store then delete context

  const closeDropdown = () => {
    setOpenDropdown(false);
  }

  const { logoutHandler } = useLogout();

  const handleLogout = (e) => {
    e.stopPropagation();
    setOpenDropdown(false);
    closeDropdown();
    closeSideMenuHandler();
    logoutHandler();
  };

  const compute_initials = (userType) => {
    if(userType === LANDLORD) return initials || "LL";
    if(userType === MANAGER) return initials || "M";
    if(userType === TENANT) return initials || "T";
    if(userType === CASE_MANAGER) return initials || "CM";
  }

  const openSideMenuHandler = () => {
    setShowSideMenu(true);
  }

  const closeSideMenuHandler = () => {
    setShowSideMenu(false);
  }

  const routeTo = (path) => {
    setOpenDropdown(false);
    closeSideMenuHandler();
    closeDropdown();
    history.push(`${path}`)
  }

  useEffect(() => {
    if(location) {

      if((location.pathname === '/marketplace' && location.search) ||
         (location.pathname === '/properties/map') ||
         (location.pathname === '/team/teams-and-relationships')) {
        setSmallNav(true);
      } else {
        const timer = setTimeout(() => {
          setSmallNav(false);
        }, 0)

        return () => {
          clearTimeout(timer);
        }

      }

    }

  }, [location])

  const prepareNavigation = (isSideNav=false) => {
    return (
      <>
        {navigationLinks.map((navigationLink) => {
          const { type, to, title, icon } = navigationLink;
          if (type === GUARDED) {
            const { allowedUserTypes } = navigationLink;
            return (
              <GuardedNavLink
                key={title}
                to={to}
                title={title}
                allowedUserTypes={allowedUserTypes}
                className={`custom_link ${ smallNav ? 'small' : ''}`}
                isSideNav={isSideNav}
                onClick={() => routeTo(to)}
                icon={icon}
                smallNav={smallNav}
              />
            );
          } else {
            return (
              <HeaderNavLink
                key={title}
                to={to}
                activeClassName="link_active"
                className={`custom_link ${ smallNav ? 'small' : ''}`}
                isSideNav={isSideNav}
                onClick={() => routeTo(to)}
                icon={icon}
                title={title}
                smallNav={smallNav}
              />
            );
          }
        })}

        {token && (
          <>
          
              <GuardedNavLink
                to={'/settings'}
                title={"Settings"}
                allowedUserTypes={[TENANT, LANDLORD, MANAGER]}
                className={`custom_link d-block d-xl-none ${ smallNav ? 'small' : ''}`}
                isSideNav={true}
                onClick={(e) => {
                  e.stopPropagation();
                  routeTo('/settings') 
                }}
                icon={'bi-gear'}
              />
         
         
              <HeaderNavLink
                to="/"
                isSideNav={true}
                onClick={handleLogout}
                className={`custom_link d-block d-xl-none ${ smallNav ? 'small' : ''}`}
                icon={'bi-door-open'}
                title={"Logout"}
              />
          </>
        )} 


        {!token && (
          <HeaderNavLink
            to="/login"
            isSideNav={isSideNav}
            className={`custom_link ${ smallNav ? 'small' : ''}`}
            onClick={() => routeTo("/login")}
            icon={'bi-door-closed'}
            title={"Login"}
            smallNav={smallNav}
          />
        )}
      </>
    )
  }

  const onScrollHandler = useCallback(() => {
    const scrollY = window.scrollY;
    if( scrollY > 70 && smallNav === false ) {
      setSmallNav(true);
    } else if ( scrollY <= 0 && smallNav === true) {
      setSmallNav(false);
    }
  }, [smallNav]);

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    }
  }, [onScrollHandler]);

  return (
    <>
      <Backdrop 
        show={showSideMenu}
        close={closeSideMenuHandler}
      />

      <SideNavigation
        show={showSideMenu}
        close={closeSideMenuHandler}
        smallNav={smallNav}
      >
        <Row className="m-0 p-0 px-3 row-cols-1">
          { prepareNavigation(true) }
        </Row>
      </SideNavigation>

      <Container fluid className={`p-0 m-0 py-2 custom_header ${smallNav ? 'shrinked_header' : ''}`}>
        <Row className="m-0 p-0 px-3 px-sm-4 px-md-5 flex-row flex-nowrap aling-items-center justify-content-between h-100">

          <Col xs="auto" className="m-0 p-0 d-flex justify-content-center align-items-center">

            <Link to="/" className="m-0 p-0">
                <Row className="m-0 p-0 d-block d-md-none" style={{ height: 46 }}>
                  
                  <Image className="m-0 p-0 img_header" 
                    srcSet={`${WISSP_Submark_LogoS} 1x, ${WISSP_Submark_LogoL} 2x`} 
                    alt="WISSP logo"
                  />

                </Row>

                <Row className="m-0 p-0 d-none d-md-block" style={{ height: smallNav ? 46 : 100, transition: '0.4s' }}>
                  
                  <Image className="m-0 p-0 img_header"
                    srcSet={`${WISSP_Full_LogoS} 1x, ${WISSP_Full_LogoL} 2x`} 
                    alt="WISSP logo"
                  /> 

                </Row>
            </Link>

          </Col>

          <Col className="m-0 p-0 overflow-hidden header-navigation-desktop">
            <Row className="m-0 p-0 w-100 h-100 justify-content-end align-items-center ">
              { prepareNavigation(false) }
            </Row>
          </Col>

          <Col xs="auto" className="m-0 p-0 d-flex align-items-center">

              { token && (
                <Row className="m-0 p-0 cursor-pointer " onClick={() => setOpenDropdown( prevState => !prevState)}>
                  <div className={`d-none d-md-flex ms-2 align-items-center justify-content-center bg-light text-primary header-initials ${ smallNav ? '' : 'big'}`} >
                    {compute_initials(userType)}
                  </div>

                  <div className="d-flex d-md-none align-items-center justify-content-center bg-light text-primary header-initials">
                    {compute_initials(userType)}
                  </div>

                  <DropdownMenu open={openDropdown} smallNav={smallNav} onHide={() => setOpenDropdown(false)}>

                    <GuardedNavLink
                      to={'/settings'}
                      title={"Settings"}
                      allowedUserTypes={[TENANT, LANDLORD, MANAGER]}
                      isSideNav={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        routeTo('/settings') 
                      }}
                      className={`custom_link ${ smallNav ? 'small' : ''}`}
                      icon={"bi-gear"}
                    />

                    <HeaderNavLink
                      to="/"
                      isSideNav={true}
                      onClick={handleLogout}
                      className={`custom_link ${ smallNav ? 'small' : ''}`}
                      title={"Logout"}
                      icon={"bi-door-open"}
                    />

                  </DropdownMenu>
                </Row>
              )}

            <i className="d-xxl-none bi ms-3 bi-list menu-icon" onClick={openSideMenuHandler}></i>

          </Col>

        </Row>
      </Container>

      <Container fluid className={`p-0 m-0 position-relative custom_header ${smallNav ? 'shrinked_header' : ''}`} style={{zIndex: 0}}></Container>
    </>
  );
}
