import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LazyImage from '../Shared/LazyImage';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import preparePhotos from '../../utils/preparePhotos';
import { determineHowLongAgo } from '../../utils/dateTransformations';
import { PostedAgo } from '../Shared/PostedAgo';

export default function UnitCard({
    alreadyRequestesUnitIDs,
    unit,
    selectUnitHandler,
    favorites,
    StreetPOV,
    building
}) {
    const isFavorite = (favorites, UnitID) => {
        if (!UnitID || !favorites) return null;

        if (favorites.Units && favorites.Units.some(unit => unit.UnitID === UnitID)) {
            return 'unit';
        }

        return null;
    }

    const preparedPhotos = preparePhotos({ ...unit, StreetPOV })
    // console.log(building)
    // console.log(unit.UnitID)
    

    return (
        <Row className="m-0 p-0 mb-2 bg-white rounded">
            <Col xs={6} sm={4}
                className="m-0 p-0 position-relative cursor-pointer"
                style={{ height: 200 }}
                onClick={() => selectUnitHandler(unit,building.BuildingID)}
            >
                <LazyImage
                    src={preparedPhotos && preparedPhotos[0]}
                    style={{ objectFit: "cover", height: 200, width: "100%" }}
                />

                <Row className="m-0 p-2 position-absolute top-0 w-100 d-flex justify-content-between align-items-center">
                    <Col className="m-0 p-0">
                        {isFavorite(favorites, unit?.UnitID) === 'unit' && (
                            <div className="m-0 p-0 position-relative d-flex">
                                <i className="bi bi-star-fill text-primary fs-4"></i>
                                <i className="bi bi-star text-light fs-4 position-absolute" ></i>
                            </div>
                        )}
                    </Col>

                    <Col className="m-0 p-0" xs="auto">
                        {unit && unit.LastVacantDate && (
                            <div className="m-0 p-1 bg-light rounded">
                                <p className="m-0 p-0 fw-bold" style={{ fontSize: '0.7rem' }}> {determineHowLongAgo(new Date(unit.LastVacantDate).getTime())}</p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Col>

            <Col className="m-0 p-0 p-2 d-flex flex-column" xs={6} sm={8}>

                <Row className="m-0 p-0 align-items-center justify-content-between">
                    <Col className="m-0 p-0 me-2" xs={"auto"}>
                        <h5 className="m-0 p-0 fw-bold">{unit?.Name}</h5>
                    </Col>
                    <Col className="m-0 p-0" xs={"auto"}>
                        <h5 className="m-0 p-0 fw-bold">Rent: ${unit?.Rent}</h5>
                    </Col>
                </Row>

                <Row className="m-0 p-0 py-1">
                    <p className="m-0 p-0" style={{ fontSize: '0.85rem' }}>
                        {unit?.FloorID || '--'}&nbsp;fl<span className="text-muted"> | </span>
                        {unit?.SquareFootage || '--'}&nbsp;sqft<span className="text-muted"> | </span>
                        {unit?.BedroomCount || '--'}&nbsp;bds<span className="text-muted"> | </span>
                        {unit?.BathroomCount || '--'}&nbsp;ba
                    </p>
                </Row>

                {/* AMENITIES AND ACCESSIBILITIES */}
                <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 d-flex" xs={12}>
                            <p className="m-0 p-0 pe-1 fs-6" style={{ color: 'inherit' }}>
                                <b>Amenities: </b>
                            </p>
                            <p className="m-0 p-0 fs-6"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    color: 'inherit'
                                }}
                            >
                                {unit?.Amenities.map((amenity, index, arr) => `${amenity}${index < arr.length - 1 ? ", " : ""}`)}
                            </p>

                        </Col>


                    </Row>

                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 d-flex" xs={12}>
                            <p className="m-0 p-0 pe-1 fs-6" style={{ color: 'inherit' }}>
                                <b>Accessibilities: </b>
                            </p>
                            <p className="m-0 p-0 fs-6"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    color: 'inherit'
                                }}
                            >
                                {unit?.Accessibilities.map((accessibility, index, arr) => `${accessibility}${index < arr.length - 1 ? ", " : ""}`)}
                            </p>

                        </Col>


                    </Row>
                </Row>
                <Row className="mt-2 d-block d-xl-none">
                    <PostedAgo time={unit?.LastVacantDate} className='ms-auto' />
                </Row>
                <Row className="m-0 p-0 flex-fill align-items-end justify-content-between">
                    <Col className='d-none d-xl-block'>
                        <PostedAgo time="2023-03-12" className='ms-auto' />
                    </Col>
                    <Col className="m-0 p-0" xs={"auto"}>
                        {alreadyRequestesUnitIDs && alreadyRequestesUnitIDs.some(id => id === unit.UnitID) && (<i className="bi bi-check2 text-success fs-4 ps-1"></i>)}
                    </Col>

                    <Col className="m-0 p-0" xs={"auto"}>
                        <ButtonPrimary
                            onClick={() => selectUnitHandler(unit,building.BuildingID)}
                            title={`Show more information`}
                        />
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}
