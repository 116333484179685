import React, { useState, useEffect, useRef } from 'react';

import {
    Row,
    Col,
    Form,
    Container
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import ImageCard from './ImageCard';
import { fileToDataUri } from '../../utils/fileToDataUri';
import ButtonPrimary from './Buttons/ButtonPrimary';
import DecisionModal from './InfoPopups/DecisionModal';
import ErrorPopup from './InfoPopups/ErrorPopup';

/**
 * Component to manage images. Allows to delete, upload and set favorite image.
 * Can work as form or non-form
 * @param {Function} onSubmitImages in argument images data will be passed, { toUpload: [], toDelete: []}
 * @param {Array} downloadedPictures pictures that should be displayed in component
 */
export default function UploadImage({
    onSubmitImages,
    sectionFocus,
    setSectionFocus,
    favoriteImgId,
    downloadedPictures,
    isForm = false,
    isProperty = false,
    images = [],
    setImages,
    setImagesToBeDeleted,
    imagesToDelete,
    onFavoriteClick,
    isImagesToUpload=true
}) {
    const imagePickerRef = useRef(null);
    const [imgCount, setImgCount] = useState(0);
    const [IMGerr, setIMGerr] = useState(false);

    useEffect(() => {
        if ((images.length) >= 10) {
            setIMGerr(true);
        } else {
            setIMGerr(false);
        }
    }, [images]);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        onSubmitImages();
    }

    function toggleStar(imageID) {
        if(!isForm) {
            setImages( prevImages => prevImages.map((image) => {
                    if(image.id === imageID) { 
                        return {
                            ...image,
                            isFavorite: true
                        }
                    }
                    
                    return {
                        ...image,
                        isFavorite: false
                    }
                })
            );
        } else {
            onFavoriteClick(imageID);
        }
    }

    function addImage(e, file) {
        if (!IMGerr) {
            setImgCount((imgCount) => imgCount + 1);
            setImages((prevImages) => {
                return [
                    ...prevImages,
                    {
                        src: e,
                        id: uuidv4(),
                        fileName: file.name,
                        fileSize: ((file.size / 1024) / 1024).toFixed(2),
                        isFavorite: images.length === 0 ? true : false,
                        show: false,
                        isInDB: false
                    },
                ];
            });
        }
        if (IMGerr) {
            ErrorPopup("Error! You have reached 10/10 image capacity.");
        }
    }

    async function removeImage(id) {

        const dataOfImageToDelete = images.find(image => image.id === id);

        // if (imgCount === 1) {
        //     ErrorPopup("There has to be at least one image! First upload new image then try to delete this one again.");
        //     return;
        // }
        const decision = await DecisionModal({
            message: "Once deleted, you will not be able to recover this picture!"
        });
        if (decision.isDenied || decision.isDismissed) return;

        else {
            // Delete image from local state 'images' and save it into 
            // 'imagesToBeDeleted' state if this image is in DB
            deleteImageFromState(id);

            if (dataOfImageToDelete.isInDB) {
                setImagesToBeDeleted(prevState => [...prevState, dataOfImageToDelete]);
            }
        }

        function deleteImageFromState(id) {

            const updatedImages = images.filter((image, index) => {
                return image.id !== id;
            });

            const wasImageStared = dataOfImageToDelete.isFavorite;
            if (wasImageStared && updatedImages[0]) {
                updatedImages[0].isFavorite = true;
            }

            setImgCount((imgCount) => imgCount - 1);
            setImages(updatedImages);
        }
    }

    // TODO: write function that will recover image from delete State if delete post failed ?

    const onChange = (file) => {
        if (!file) {
            return;
        }

        fileToDataUri(file)
            .then(dataUri => {
                file.size < 10485760 ? 
                    (/\.(jpe?g|png|gif)$/i.test(file.name) ? 
                    addImage(dataUri, file) : 
                    ErrorPopup("The image extension is incorrect (correct for: jpg, png, gif)")) : 
                    ErrorPopup("Image size is too large (Max file size is up to 10MB)") 
            })
        imagePickerRef.current.value = null;
    }

    // Add downloaded images to local image state
    useEffect(() => {
        if (Array.isArray(downloadedPictures)) {

            const transformImagesObjects = downloadedPictures.map((img, index) => {
                return {
                    id: img.PhotoID,
                    show: img.show || false,
                    src: img.URL,
                    isFavorite: img.IsFavorite,
                    fileName: img.fileName || `Image${img.PhotoID}`,
                    fileSize: img.fileSize || null,
                    isInDB: true
                }

                // function checkIfIsStared(index) {
                //     // TODO: change this behaviour when it will work at backend
                //     if (!index) return true;

                //     // for buildings only
                //     if (favoriteImgId) {
                //         return favoriteImgId === img.PhotoID
                //     }
                // }

            })

            setImgCount(transformImagesObjects.length);
            setImages(transformImagesObjects);
        }

    }, [downloadedPictures, favoriteImgId])

    const uploadFromDeviceHandler = () => {
        imagePickerRef.current.click();
    }

    const uploadImagesStructure = (
        <>
            <h3 className="fw-bolder">{isProperty ? 'Property' : 'Unit'} images</h3>
            <Row className="m-0 p-0">
                <p className="m-0 p-0">Upload up to ten images, and star the one you consider your {isProperty ? "property's" : "unit’s"} primary (front-page)</p>
                <Row className="p-0 m-0 mb-3 gy-2 row-cols-1 row-cols-sm-2">
                    <Col className="p-0 pe-sm-1">
                        <ButtonPrimary
                            onClick={uploadFromDeviceHandler}
                            type="button"
                        >
                            <i className="bi bi-plus-lg"></i> Upload from Device
                        </ButtonPrimary>

                        <Form.Control type="file" className="d-none" ref={imagePickerRef} onChange={(event) => onChange(event.target.files[0] || null)} />
                    </Col>

                    <Col className="p-0 ps-sm-1">
                        <ButtonPrimary
                            onClick={uploadFromDeviceHandler}
                            type="button"
                            disabled={true}
                        >
                            <i className="bi bi-plus-lg"></i> Upload from Google Drive
                        </ButtonPrimary>
                    </Col>
                </Row>

                <Container style={{ maxHeight: 300, overflowY: "auto" }} className="m-0 overflow-scroll-y wissp-scroll-box" fluid>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1 gy-1 d-flex justify-content-between">
                        {images.map((item, index) => {
                            return (
                                <ImageCard
                                    key={item.id}
                                    item={item}
                                    toggleStar={toggleStar}
                                    removeImage={removeImage}
                                    index={index}
                                />
                            )
                        })}
                    </Row>
                </Container>

                <Row className="m-0 p-0 mt-3">
                    <p className="p-0 m-0 text-center">
                        Image{imgCount > 2 ? "s" : ""}: {imgCount}/10
                    </p>
                    {/* {images.length === 0 && (<p className="custom_error text-center">Image is Required</p>)} */}
                </Row>
            </Row>
        </>
    );

    return (
        <>
            {isForm && (
                <Form
                    onSubmit={onSubmitHandler}
                    className={sectionFocus && sectionFocus !== "Images" ? "d-blurred prev-blur" : "prev-blur"}
                    onMouseEnter={() => { setSectionFocus && setSectionFocus("Images") }}
                    onMouseLeave={() => { setSectionFocus && setSectionFocus(null) }}
                >
                    {uploadImagesStructure}

                    <Row className="mt-3">
                        <Col className="text-center">
                            <ButtonPrimary 
                                title="Update Images"
                                disabled={ !isImagesToUpload && !imagesToDelete.length }
                            />
                        </Col>
                    </Row>

                </Form>)}

            {!isForm && (
                <>
                    {uploadImagesStructure}
                </>
            )}
        </>
    )
}
