import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from "uuid";

import { fileToDataUri } from '../../../utils/fileToDataUri';
import FileRow from './FileRow';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import DecisionModal from "../InfoPopups/DecisionModal";
import ErrorPopup from "../InfoPopups/ErrorPopup";


export default function UploadFile({
    downloadedFiles,
    files = [],
    setFiles,
    setFilesToBeDeleted,
    saveDescriptionHandler,
    fileCountLimit = 15,
    totalFiles = 0,
    disabled=false
}) {
    const filePickerRef = useRef(null);
    const [fileCount, setFileCount] = useState(0);
    const [fileErr, setFileErr] = useState(false);

    useEffect(() => {
        if(files && !files.length) setFileCount(0)
    }, [totalFiles, files])

    useEffect(() => {
        if ((files.length + totalFiles) >= fileCountLimit) {
            setFileErr(true);
        } else {
            setFileErr(false);
        }
    }, [files, fileCountLimit, totalFiles]);

    const uploadFromDeviceHandler = () => {
        filePickerRef.current.click();
    }

    function addFile(e, file) {
        if (!fileErr) {
            setFileCount((fileCount) => fileCount + 1);
            setFiles((prevFiles) => {
                return [
                    ...prevFiles,
                    {
                        src: e,
                        id: uuidv4(),
                        fileName: file.name,
                        fileSize: ((file.size / 1024) / 1024).toFixed(2),
                        isInDB: false,
                        description: ''
                    },
                ];
            });
        }
        if (fileErr) {
            ErrorPopup(`Error! File capacity reached (${fileCount + totalFiles}/${fileCountLimit})`);
        }
    }
    
    async function removeFileHandler(id) {
        const dataOfFileToDelete = files.find( file => file.id === id);
        
        const userDecision = dataOfFileToDelete.isInDB ? await DecisionModal({
            message: "Once deleted, you will not be able to recover this document!"
        }) : { isDenied : false };
        
        if( userDecision.isDenied || userDecision.isDismissed ) return;

        else { 
            deleteFileFromState(id);

            if(dataOfFileToDelete.isInDB) {
                setFilesToBeDeleted( prevState => [...prevState, dataOfFileToDelete]);
            }
        }

        function deleteFileFromState (id) {
            const updatedFiles = files.filter((file, index) => {
                return file.id !== id;
            });
            setFileCount((fileCount) => fileCount - 1);
            setFiles(updatedFiles);
        }
    }

    const onChange = (file) => {
        if (!file) return;
        fileToDataUri(file)
            .then(dataUri => {
                if (file.size > 10485760) { 
                    ErrorPopup("Image size is too large (Max file size is up to 10MB)"); 
                    return;
                }
                if (!(/\.(docx?|pdf)$/i.test(file.name))) {
                    ErrorPopup("The file extension is incorrect (correct ones are: doc, docx, pdf)"); 
                    return;
                }
                addFile(dataUri, file)
            })

        filePickerRef.current.value = null;
    }

    // Add downloaded files to local file state
    useEffect(() => {
        if (Array.isArray(downloadedFiles)) {

            const transformFilesObjects = downloadedFiles.map((file, index) => {

                return {
                    id: file.BuildingDocumentID || uuidv4(),
                    src: file.URL,
                    fileName: file.OriginalFileName || `File${file.FileID}`,
                    fileSize: file.fileSize || null,
                    isInDB: true,
                    description: file.Description || ''
                }
            })

            setFileCount(transformFilesObjects.length);
            setFiles(transformFilesObjects);
        }

    }, [downloadedFiles])

    const changeFileDescription = (description, id, save=true) => {
        const updatedFile = files.find( file => file.id === id )
        updatedFile.description = description;

        const updatedFiles = files.map( file => { 
            if(file.id === id) {
                return {
                    ...updatedFile
                }
            }
            return file
        } )
        
        if(save)saveDescriptionHandler(updatedFile);

        setFiles( updatedFiles )
    }


    return (
        <>
            <Row className="row-cols-1 row-cols-md-2 gy-1 d-flex justify-content-between">
                <Col>
                    <ButtonPrimary
                        onClick={uploadFromDeviceHandler}
                        type="button"
                        disabled={disabled}
                    >
                        <i className="bi bi-plus-lg"></i> Upload from Device
                    </ButtonPrimary>
                    <Form.Control type="file" className="d-none" ref={filePickerRef} onChange={(event) => onChange(event.target.files[0] || null)} />
                </Col>
                <Col className="">
                    <ButtonPrimary
                        onClick={uploadFromDeviceHandler}
                        type="button"
                        disabled={true}
                    >
                        <i className="bi bi-plus-lg"></i> Upload from Google Drive
                    </ButtonPrimary>
                </Col>
            </Row>
            <Row className="m-0 my-3 p-0">
                <Col className="m-0 p-0 text-end">
                    <h6 className="m-0 p-0"><i className="bi bi-info-circle-fill"></i> {fileCount + totalFiles}/{fileCountLimit} files uploaded</h6>
                </Col>
            </Row>
            { (files && files.length) ? (
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        {
                            files.map((file, index) => (
                                    <FileRow 
                                        key={index}
                                        index={index}
                                        
                                        id={file.id}
                                        src={file.src}
                                        fileName={file.fileName}
                                        fileSize={file.fileSize}
                                        isInDB={file.isInDB}
                                        description={file.description}

                                        removeFileHandler={removeFileHandler}
                                        changeFileDescription={changeFileDescription}
                                    />
                                )
                            )
                        }
                    </Col>
                </Row> ) : null
            }
        </>
    );
}
