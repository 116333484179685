import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useLayoutEffect
} from "react";
import {
  getFavorites,
  deleteFavoriteBuilding,
  deleteFavoriteUnit,
  addFavoriteQuery,
  deleteFavoriteQuery,
} from "../../../services/favorites";
import { getTickets } from "../../../services/tickets";
import ErrorPopup from "../../../components/Shared/InfoPopups/ErrorPopup";
import { favoriteActions } from "../../../store/FavoriteSlice";

import { Row, Col } from "react-bootstrap";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";

// import { getTicketsHandler } from "../../../store/RequestsSlice";

import useWindowSize, { GRID_BREAKPOINTS } from "../../../hooks/useWindowSize";

import { MarketplaceMap } from "../../Marketplace/MarketplaceMap";
import PropertyUnits from "../../Marketplace/PropertyUnits";
import SideMenu from "../../Marketplace/SideMenu";

import orderBy from "lodash/orderBy";
import { getBuilding } from "../../../services/building";

import MarketplaceLegend from "../../Marketplace/MarketplaceLegend";
import ButtonPrimary from "../../Shared/Buttons/ButtonPrimary";
import ButtonSecondary from "../../Shared/Buttons/ButtonSecondary";
import FiltersBtn from "../../Shared/Filters/FiltersBtn";
import ModalDetails from "../../Shared/PropertyDetails/ModalDetails";
import {Button} from "react-bootstrap";

import List from '../PropertiesList'

const defaultPosition = {
  lat: 39.828175,
  lng: -98.5795,
};

export default function PropertiesMap({
  // loading,
  properties,
  filters,
  isError,
  token,
  userType,
  hasProperties
}) {
  const legendBar = useRef(null);

  const dispatch = useDispatch();
  const requests = useSelector((store) => store.requestsStore.tickets);
  const isLoading = useSelector((store) => store.requestsStore.loading);
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(defaultPosition);
  const [showLegendModal, setShowLegendModal] = useState(false);
  const backButtonHandler = () => {
    setShowPropertyDetails(false);
    setUnitsForPropertyId(null);
    setActiveSideMenuTab(SIDE_MENU_TABS.BUILDINGS);

    // setUnitsForPropertyId(null);
    setExpandedProperties([]);
  };

  // useEffect(() => {
  //   dispatch(getTicketsHandler(token, userType));
  // }, []);
  const closeModalUnitDetails = () => {
    setSelectedUnit(null);
    setSelectedBuilding(null);

    setParams((prevValue) => ({
      ...prevValue,
      BuildingID: null,
      UnitID: null,
    }));
  };
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (!size.width) return;
    if (size.width >= GRID_BREAKPOINTS.MD) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);
  const SIDE_MENU_TABS = {
    BUILDINGS: "buildings",
    UNITS: "units",
  };

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [buildingsPins, setBuildingPins] = useState(properties);
  const [params, setParams] = useState(null);
  const [alreadyRequestesUnitIDs, setAlreadyRequestesUnitIDs] = useState(null);
  const [highlightBuildingWithID, setHighlightBuildingWithID] = useState(null);
  const [visibleBuildingsId, setVisibleBuildingsId] = useState([]);
  const [unitsForPropertyId, setUnitsForPropertyId] = useState(null);
  const [LLValue, setLLValue] = useState(null);
  const [isLoadingBuildingDetailedInfo, setIsLoadingBuildingDetailedInfo] =
    useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [activeSideMenuTab, setActiveSideMenuTab] = useState(
    SIDE_MENU_TABS.BUILDINGS
  );
  const [expandedProperties, setExpandedProperties] = useState([]);
  const [buildingsDetails, setBuildingsDetails] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [buildings, setBuildings] = useState(properties);
  const [lastSearchedParams, setLastSearchedParams] = useState(null);

  const [previewModal, setPreviewModal] = useState(false);
  const openBuildingPreview = () => {
    setPreviewModal(true);
  }
  const closeBuildingPreview = () => {
    setPreviewModal(false);
  }


  useEffect(() => {
    if (params) {
      if (
        JSON.stringify(lastSearchedParams) !==
        JSON.stringify({ ...params, BuildingID: null, UnitID: null })
      ) {
        setLastSearchedParams({ ...params, BuildingID: null, UnitID: null });

        // setHasUserSearched(true);
        // setLoading(true);

        // setTimeout(() => {
        //   // searchUnits(params);
        //   getInitialSearchHandler(params);
        // }, (searchAnimTime))
      }
    }
  }, [params]);
  useEffect(() => {
    if (properties?.length) {
      getLL(properties[0].City, properties[0].State);
      setBuildingPins(properties);
      setLoading(false);
    }

  }, [properties]);
  const favorites = useSelector((store) => store.favoriteStore);
  const [activeFilters, setActiveFilters] = useState({
    Price: "",
    Vacant: "",
    Date: "",
  });
  const computedBuildings = useMemo(() => {
    if (!buildings) return;

    const properties = buildings.map((building) => {
      const numberOfVacant = building.Units ? building.Units.length : 0;

      return {
        ...building,
        numberOfVacant,
      };
    });

    return properties;
  }, [buildings]);
  const getLL = (city, state) => {
    geocodeByAddress(`${city} ${state} "United States"`)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLLValue(() => ({
          center: {
            lat,
            lng,
          },
        }));
      });
  };
  const selectBuildingHandler = (buildingID) => {
    const temp_buildingID = Array.isArray(buildingID)
      ? buildingID[0]
      : buildingID;

    // setSelectedBuilding(temp_buildingID);

    setProperty( properties.find(property => String(property.BuildingID) === String(temp_buildingID)) );
    openBuildingPreview(true);

    setParams((prevValue) => ({
      ...prevValue,
      BuildingID: buildingID,
      UnitID: null,
    }));
  };

  const selectUnitHandler = (unit, building = undefined) => {


    if (!unit) return;
    setSelectedUnit(unit);

    setSelectedBuilding(prev => building ? building : prev);

    setParams((prevValue) => ({
      ...prevValue,
      BuildingID: unit.BuildingID,
      UnitID: unit.UnitID,
    }));
  };
  const sortFromHighest = (propertyArray, key, transformFcn = (x) => x) => {
    if (!propertyArray) return propertyArray;

    return orderBy(
      propertyArray,
      [
        function (a) {
          return transformFcn(a[key]);
        },
      ],
      ["desc"]
    );
  };
  const highlighBuildingWithIDHandler = (BuildingID) => {
    setHighlightBuildingWithID(BuildingID);
  };
  const focusOnUnits = useCallback((selectedUnits) => { }, []);
  // const sortFromLowest = (propertyArray, key, transformFcn = (x) => x) => {
  //   if (!propertyArray) return propertyArray;

  //   return orderBy(
  //     propertyArray,
  //     [
  //       function (a) {
  //         return transformFcn(a[key]);
  //       },
  //     ],
  //     ["asc"]
  //   );
  // };
  // const howManyFiltersActive = () => {
  //   return Object.entries(activeFilters).filter(([key, value]) => value).length;
  // };
  // const filterHandler = useMemo(() => {
  //   if (!computedBuildings) return [];
  //   let sortedAndFilteredArr = [...computedBuildings];

  //   if (activeFilters.Price === "fromLowest") {
  //     sortedAndFilteredArr = sortFromLowest(
  //       sortedAndFilteredArr,
  //       "MinRentPrice"
  //     );
  //   }

  //   if (activeFilters.Price === "fromHighest") {
  //     sortedAndFilteredArr = sortFromHighest(
  //       sortedAndFilteredArr,
  //       "MaxRentPrice"
  //     );
  //   }

  //   if (activeFilters.Vacant === "fromLowest") {
  //     sortedAndFilteredArr = sortFromLowest(
  //       sortedAndFilteredArr,
  //       "numberOfVacant"
  //     );
  //   }

  //   if (activeFilters.Vacant === "fromHighest") {
  //     sortedAndFilteredArr = sortFromHighest(
  //       sortedAndFilteredArr,
  //       "numberOfVacant"
  //     );
  //   }

  //   if (activeFilters.Date === "fromLowest") {
  //     sortedAndFilteredArr = sortFromLowest(
  //       sortedAndFilteredArr,
  //       "LastVacantDate",
  //       (a) => new Date(a).getTime()
  //     );
  //   }

  //   if (activeFilters.Date === "fromHighest") {
  //     sortedAndFilteredArr = sortFromHighest(
  //       sortedAndFilteredArr,
  //       "LastVacantDate",
  //       (a) => new Date(a).getTime()
  //     );
  //   }

  //   setResultCount(sortedAndFilteredArr.length);
  //   return sortedAndFilteredArr;
  // }, [
  //   activeFilters.Price,
  //   activeFilters.Vacant,
  //   activeFilters.Date,
  //   computedBuildings,
  // ]);

  // const resetFilters = () => {
  //   setActiveFilters({
  //     Price: "",
  //     Vacant: "",
  //     Date: "",
  //   });
  // };
  const getBuildingInformation = async (BuildingID) => {
    if (!BuildingID) return;
    if (buildingsDetails.some((building) => building.BuildingID === BuildingID))
      return;

    setIsLoadingBuildingDetailedInfo(true);
    // const {success, data} = await getDataForMapPin(BuildingID);

    const { success, data } = await getBuilding(BuildingID, token);

    if (success) {
      setBuildingsDetails((prevBuildings) => [...prevBuildings, data]);
    } else {
      //TODO: show error
    }
    setIsLoadingBuildingDetailedInfo(false);
  };
  const expandPropertyHandler = (buildingID) => {
    // TODO: send request ID to download building info

    getBuildingInformation(buildingID);

    const toggleProperty = (buildingID, arr) => {
      const alreadyInArray = arr.includes(buildingID);
      return alreadyInArray
        ? arr.filter((id) => id !== buildingID)
        : [...arr, buildingID];
    };

    // TODO: check if it is needed!
    setExpandedProperties((prevState) => toggleProperty(buildingID, prevState));
    setUnitsForPropertyId(buildingID);

    setShowPropertyDetails(true);
    setActiveSideMenuTab(SIDE_MENU_TABS.UNITS);
  };
  const openFilters = () => {
    setShowFilters(true);
  };
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteFavorite = async (id, type) => {
    if (typeof id !== "number")
      throw new Error("First argument has to be of type number!");
    if (typeof type !== "string")
      throw new Error("Second argument has to be of type string!");

    setIsDeleting(true);

    let result = { success: null, data: null };
    if (type === "unit") result = await deleteFavoriteUnit(token, id);
    if (type === "building") result = await deleteFavoriteBuilding(token, id);
    if (type === "query") result = await deleteFavoriteQuery(token, id);
    const { success, data } = result;

    if (success) {
      if (type === "unit") dispatch(favoriteActions.deleteFavoriteUnit(id));
      if (type === "building")
        dispatch(favoriteActions.deleteFavoriteBuilding(id));
      if (type === "query") dispatch(favoriteActions.deleteFavoriteQuery(id));
    } else {
      ErrorPopup(
        `Error! Something went wrong. Couldn't delete ${type} from favorites...`
      );
    }

    setIsDeleting(false);
  };

  const [sortedTickets, setSortedTickets] = useState(null);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const extrudeTickets = (tickets) => {
    if (!tickets) return null;

    const extrudedProperties = Object.entries(tickets).reduce(
      (acc, [_, value]) => {
        return {
          ...acc,
          ...value,
        };
      },
      {}
    );
    if (!extrudedProperties) return {};

    return extrudedProperties;
  };
  const getTicketsHandler = useCallback(async () => {
    setLoadingTickets(true);
    const response = await getTickets(token, userType.toLowerCase());

    const { success, data } = response;

    if (success) {
      switch (userType) {
        case "Manager":
          setSortedTickets(extrudeTickets(data));
          break;
        case "Landlord":
        default:
          setSortedTickets(data);
      }
    } else {
      ErrorPopup();
    }
    setLoadingTickets(false);
  }, [token, userType]);
  useLayoutEffect(() => {
    if (sortedTickets) {
      setLoading(false);
    } else {
      getTicketsHandler();
    }
  }, [sortedTickets, getTicketsHandler]);
  const SHOW_COMPONENT = {
    BOTH: "both",
    MAP: "map",
    LIST: "list"
  }
  const [showComponents, setShowComponents] = useState(SHOW_COMPONENT.BOTH);

  const showComponentsHandler = (name) => {
    setShowComponents(name);
  }
  useEffect(() => {
    if (!size.width) return;
    if (size.width >= GRID_BREAKPOINTS.MD) {
      setIsMobile(false);
      showComponentsHandler(SHOW_COMPONENT.BOTH)
    } else {
      setIsMobile(true);
      showComponentsHandler(SHOW_COMPONENT.LIST) // TODO: should be always list!
    }
  }, [size]);

  useEffect(() => {
    // const hideBeforeAnimationEnd = setTimeout(() => { setLoading(false) }, 300);
    document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = 'scroll'
      // clearTimeout(hideBeforeAnimationEnd);
    }
  }, [previewModal])


  return (
    <>
      <ModalDetails
        show={previewModal}
        onHide={closeBuildingPreview}
        property={property}

        hideStreetMap={false}
        showAllUnits={true}
      />

      <Row className={`overflow-hidden mx-0 pt-3 w-100 disableAnim pe-1`}
        style={{
          height: "calc(100vh - 54px - var(--header-height-mobile))",
          overflowY: "auto",
        }}
      >
        <Col
          xs={12}
          md={6}
          id="Map"
          className={`h-100 p-2 pb-0 lightGray p-md-0 ${[SHOW_COMPONENT.MAP, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'd-block' : 'd-none'}`}
        >
          <MarketplaceMap
            buildingsPins={buildingsPins}
            selectBuildingHandler={selectBuildingHandler}
            alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
            highlightBuildingWithID={highlightBuildingWithID}
            setHighlightBuildingWithID={setHighlightBuildingWithID}
            setVisibleBuildingsId={setVisibleBuildingsId}
            focusOnUnits={focusOnUnits}
            zoomIntoBuildingWithID={unitsForPropertyId}
            LLValue={LLValue}
            loading={loading}
            isMobile={isMobile}
            showComponents={"both"}
            params={params}
            favorites={favorites}
          />
        </Col>

        <Col
          xs={12}
          md={6}
          style={{ overflowY: 'scroll' }}
          className={`h-100 lightGray p-2 p-md-3 d-flex flex-column pb-5 wissp-scroll-box`}
        >
          <Row className={`m-0 pb-2 pb-sm-3 p-0 ${[SHOW_COMPONENT.LIST, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'd-flex flex-column' : 'd-none'}`} ref={legendBar}>
            <Row className="m-0 p-0 justify-items-between align-items-center">
              <List
                loading={loading}
                properties={properties}
                filterHandler={properties}
                sortedTickets={sortedTickets}
                loadingTickets={loadingTickets}
                alternativeStyle
                setHighlightBuildingWithID={setHighlightBuildingWithID}
                hasProperties={hasProperties}
              />
            </Row>
          </Row>
        </Col>

        {(isMobile) && (
          <Row className="m-0 p-2 py-2 w-100 position-fixed bottom-0 start-0 bg-light">
            <Col className="m-0 me-2 p-0">
              <Button className={`w-100 rounded ${[SHOW_COMPONENT.LIST, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'fw-bolder' : 'fw-normal'}`}
                onClick={() => showComponentsHandler(SHOW_COMPONENT.LIST)}
              >List</Button>
            </Col>

            <Col className="m-0 p-0">
              <Button className={`w-100 rounded ${[SHOW_COMPONENT.MAP, SHOW_COMPONENT.BOTH].includes(showComponents) ? 'fw-bolder' : 'fw-normal'}`}
                onClick={() => showComponentsHandler(SHOW_COMPONENT.MAP)}
              >Map</Button>
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
}
