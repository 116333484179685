import React from 'react';
import InputWithMask from './InputWithMask';

export default function PhoneWithMask({ 
    inputData, 
    register, 
    classes, 
    className,
    showError,
    errorMessage,
    ...restProps 
}) {
   
    const defaultInputData = {
        id: 'Phone',
        label: 'Phone number',
        type: 'tel',
        placeholder: 'Phone number',
        ...inputData
    };

    return (
        <InputWithMask 
            inputData={defaultInputData}
            register={register}
            maskOptions={{ mask: '(999) 999-9999'}}
            classes={classes}
            className={className}
            showError={showError}
            errorMessage={errorMessage}
            {...restProps}
        />
    )
}
