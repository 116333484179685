import React from 'react';

import { MarketplaceNoMatch } from './MarketplaceNoMatch';
import Loader from '../../Shared/Loader';
import PropertyCard from '../PropertyCard';


export default function SideMenu({
    loading,
    alreadyRequestesUnitIDs,
    selectUnitHandler,
    selectBuildingHandler,
    highlightBuildingWithID,
    visibleBuildingsId,
    setHighlightBuildingWithID,
    filterHandler,
    collapseCard,
    expandPropertyHandler,

    favorites
}) {
    const highlightHandler = (buildingID, building) => {
        // console.log(building)
        setHighlightBuildingWithID(buildingID)
    }
    return (
        <>
            { !loading ? (
                filterHandler.map((building) => (
                    <PropertyCard 
                        key={building.BuildingID}

                        building={building}
                        selectUnitHandler={selectUnitHandler} 
                        selectBuildingHandler={selectBuildingHandler}
                        highlightBuildingWithID={highlightBuildingWithID}
                        visibleBuildingsId={visibleBuildingsId}
                        setHighlightBuildingWithID={highlightHandler}
                        alreadyRequestesUnitIDs={alreadyRequestesUnitIDs}
                        collapseCard={collapseCard}
                        expandPropertyHandler={expandPropertyHandler}
                        favorites={favorites}
                    />
                ))) : (
                    <Loader />
                )
            }

            {(!loading && !filterHandler.length) && <MarketplaceNoMatch />}
        </>
    )
} 
