import React from 'react';
import ReactToggle from "react-toggle";
import "react-toggle/style.css";

export default function Toggle({ isChecked, onChange, className="custom-toggle", additionalClassName, icons=false, ...props}) {
    return (
        <ReactToggle
            checked={isChecked}
            className={`${className} ${additionalClassName}`}
            onChange={onChange}
            icons={icons}
            {...props}
        />
    )
}
