import React, { useState } from "react";
import {
  Row,
  Col,
  Nav,
  Form,
  Button
} from "react-bootstrap";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";

import Filters, { FilterRow } from "../Shared/Filters";
import FiltersBtn from "../Shared/Filters/FiltersBtn";
import Backdrop from "../Shared/Backdrop";
import Alphabetically from "../Shared/Filters/Categories/Alphabetically";

const LOCATION = Object.freeze({
  BUILDINGS: 'buildings',
  UNITS: 'units',
  QUERIES: 'queries'
})

export default function FavoriteNavigation({
  results,
  activeFilters,
  changeHandler,
  featuresChangeHandler,
  resetFilters,
  clearFilterHandler,
  howManyFiltersActive,
  loading,
  filtersRenderData,
  features
}) {
    const [ showFilters, setShowFilters ] = useState(false);
    let { url } = useRouteMatch();

    const { pathname } = useLocation();

    const openFilters = () => {
      setShowFilters(true);
    }

    const closeFilters = () => {
      setShowFilters(false);
    }

    const isLocation = (pathname, equalTo) => {
      if(!pathname) return null;
     
      return pathname.search(equalTo) === -1 ? false : true;
    }

    const filterFields = (o_data, locationType) => {
      if(!o_data) return null;

      return (
        <>
          <FilterRow 
              clearFilter={() => clearFilterHandler('City')}
              title={'City'}
              value={ activeFilters.City }
          >
              <Form.Select 
                  size="lg"
                  aria-label="Select city" 
                  className="rounded border-0 customSelect shadow-sm fs-6"
                  value={ activeFilters.City }
                  onChange={ (e) => changeHandler(e.target.value, 'City') }
              >
                  <option value='' defaultChecked>All</option>
                  {o_data.City && o_data.City.map((city) => {
                      return (
                          <option key={city} value={city}>
                              {city}
                          </option>
                      );
                  })}
              </Form.Select>
          </FilterRow>

          <FilterRow 
              clearFilter={() => clearFilterHandler('State')}
              title={'State'}
              value={ activeFilters.State }
          >
              <Form.Select 
                  size="lg"
                  aria-label="Select state" 
                  className="rounded border-0 customSelect shadow-sm fs-6"
                  value={ activeFilters.State }
                  onChange={ (e) => changeHandler(e.target.value, 'State') }
              >
                  <option value='' defaultChecked>All</option>
                  {o_data.State && o_data.State.map((state) => {
                      return (
                          <option key={state} value={state}>
                              {state}
                          </option>
                      );
                  })}
              </Form.Select>
          </FilterRow>

          { locationType === LOCATION.UNITS && (
              <FilterRow
                  clearFilter={() => clearFilterHandler('Status')}
                  title="Status"
                  value={activeFilters.Status}
              >
                <Col xs={6} className="m-0 p-0">
                    <div onClick={ () => changeHandler('Vacant', 'Status') } className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Status === 'Vacant' ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        {/* <i className="bi bi-sort-alpha-down fs-6"></i> */}
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Status === 'Vacant' ? 'text-white' : 'text-dark' }`}>Vacant</p>
                    </div>
                </Col>

                <Col xs={6} className="m-0 p-0">
                    <div onClick={ () => changeHandler('Occupied', 'Status') } className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Status === 'Occupied' ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        {/* <i className="bi bi-sort-alpha-up fs-6"></i> */}
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Status === 'Occupied' ? 'text-white' : 'text-dark' }`}>Occupied</p>
                    </div>
                </Col>
              </FilterRow>
          )}

          { locationType !== LOCATION.QUERIES && (
            <>
              <Row className="m-0 p-0 mb-3">
                <Row  className="m-0 p-0">
                    <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                        <h6 className="m-0 p-0 fw-normal">Accessibilities</h6>
                        <Button 
                          className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                          disabled={!(activeFilters.Accessibilities && activeFilters.Accessibilities.length)} 
                          onClick={() => clearFilterHandler('Accessibilities', [])}
                        >
                            <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                        </Button>
                    </Col>
                </Row>
                <Row  className="m-0 p-0 px-1">
                  {o_data.Accessibilities && o_data.Accessibilities.map((option, index) => {
                    return (
                        <Form.Check
                            key={`accessiblity ${index}`}
                            id={`accessiblity ${index}`}
                            type="checkbox"
                            label={option}
                            htmlFor={`accessiblity ${index}`}
    
                            onChange={() => featuresChangeHandler('Accessibilities', option)}
                            checked={activeFilters.Accessibilities.includes(option)}
                        />
                    );
                  })}
                </Row>
              </Row>
          
              <Row className="m-0 p-0 mb-3">
                <Row  className="m-0 p-0">
                    <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                      <h6 className="m-0 p-0 fw-normal">Amenities</h6>
                      <Button 
                        className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                        disabled={!(activeFilters.Amenities && activeFilters.Amenities.length)} 
                        onClick={() => clearFilterHandler('Amenities', [])}
                      >
                          <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                      </Button>
                    </Col>
                </Row>
    
                <Row  className="m-0 p-0 px-1">
                  {o_data.Amenities && o_data.Amenities.map((option, index) => {
                    return (
                        <Form.Check
                            key={`accessiblity ${index}`}
                            id={`accessiblity ${index}`}
                            type="checkbox"
                            label={option}
                            htmlFor={`accessiblity ${index}`}
    
                            onChange={() => featuresChangeHandler('Amenities', option)}
                            checked={activeFilters.Amenities.includes(option)}
                        />
                    );
                  })}
                </Row>
              </Row>
            </>
          )}

          { locationType === LOCATION.QUERIES && (
            <>
              {/* BEDROOMS */}
              {/* <FilterRow 
                clearFilter={() => {
                  clearFilterHandler('MinBedrooms');
                  clearFilterHandler('MaxBedrooms')
                }}
                title={'Bedrooms'}
                value={ activeFilters.MinBedrooms || activeFilters.MaxBedrooms }
              >
                <Col className="m-0 p-0 pe-2">
                  <Form.Control
                      type="number"
                      placeholder="Min Bedrooms"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MinBedrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MinBedrooms') }
                      min={0}
                  />
                </Col>
                <Col className="m-0 p-0">
                  <Form.Control
                      type="number"
                      placeholder="Max Bedrooms"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MaxBedrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MaxBedrooms') }
                      min={0}
                  />
                </Col>
              </FilterRow> */}

              {/* BATHROOMS */}
              {/* <FilterRow 
                clearFilter={() => {
                  clearFilterHandler('MinBathrooms');
                  clearFilterHandler('MaxBathrooms')
                }}
                title={'Bathrooms'}
                value={ activeFilters.MinBathrooms || activeFilters.MaxBathrooms }
              >
                <Col className="m-0 p-0 pe-2">
                  <Form.Control
                      type="number"
                      placeholder="Min Bathrooms"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MinBathrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MinBathrooms') }
                      min={0}
                  />
                </Col>
                <Col className="m-0 p-0">
                  <Form.Control
                      type="number"
                      placeholder="Max Bathrooms"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MaxBathrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MaxBathrooms') }
                      min={0}
                  />
                </Col>
              </FilterRow> */}

              {/* Building Amenities */}
              { (o_data.BuildingAmenityIDs && o_data.BuildingAmenityIDs.length) ? (
                <Row className="m-0 p-0 mb-3">
                  <Row  className="m-0 p-0">
                      <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                          <h6 className="m-0 p-0 fw-normal">Building Amenities</h6>
                          <Button 
                            className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                            disabled={!(activeFilters.BuildingAmenityIDs && activeFilters.BuildingAmenityIDs.length)} 
                            onClick={() => clearFilterHandler('BuildingAmenityIDs', [])}
                          >
                              <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                          </Button>
                      </Col>
                  </Row>
                  <Row  className="m-0 p-0 px-1">
                    {o_data.BuildingAmenityIDs && o_data.BuildingAmenityIDs.map((option, index) => {
                      return (
                          <Form.Check
                              key={`accessiblity ${index}`}
                              id={`accessiblity ${index}`}
                              type="checkbox"
                              label={features && features.BuildingAmenities && features.BuildingAmenities[option]}
                              htmlFor={`accessiblity ${index}`}
      
                              onChange={() => featuresChangeHandler('BuildingAmenityIDs', option)}
                              checked={activeFilters.BuildingAmenityIDs.includes(option)}
                          />
                      );
                    })}
                  </Row>
                </Row>
              ) : null }


              {/* Building Accessibilities */}
              { (o_data.BuildingAccessibilityIDs && o_data.BuildingAccessibilityIDs.length) ? (
                <Row className="m-0 p-0 mb-3">
                  <Row  className="m-0 p-0">
                      <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                          <h6 className="m-0 p-0 fw-normal">Building Accessibilities</h6>
                          <Button 
                            className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                            disabled={!(activeFilters.BuildingAccessibilityIDs && activeFilters.BuildingAccessibilityIDs.length)} 
                            onClick={() => clearFilterHandler('BuildingAccessibilityIDs', [])}
                          >
                              <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                          </Button>
                      </Col>
                  </Row>
                  <Row  className="m-0 p-0 px-1">
                    {o_data.BuildingAccessibilityIDs && o_data.BuildingAccessibilityIDs.map((option, index) => {
                      return (
                          <Form.Check
                              key={`accessiblity ${index}`}
                              id={`accessiblity ${index}`}
                              type="checkbox"
                              label={features && features.BuildingAccessibilities && features.BuildingAccessibilities[option]}
                              htmlFor={`accessiblity ${index}`}
      
                              onChange={() => featuresChangeHandler('BuildingAccessibilityIDs', option)}
                              checked={activeFilters.BuildingAccessibilityIDs.includes(option)}
                          />
                      );
                    })}
                  </Row>
                </Row>
              ) : null }


              {/* Unit Amenities */}
              { (o_data.UnitAmenityIDs && o_data.UnitAmenityIDs.length) ? (
                <Row className="m-0 p-0 mb-3">
                  <Row  className="m-0 p-0">
                      <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                          <h6 className="m-0 p-0 fw-normal">Unit Amenities</h6>
                          <Button 
                            className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                            disabled={!(activeFilters.UnitAmenityIDs && activeFilters.UnitAmenityIDs.length)} 
                            onClick={() => clearFilterHandler('UnitAmenityIDs', [])}
                          >
                              <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                          </Button>
                      </Col>
                  </Row>
                  <Row  className="m-0 p-0 px-1">
                    {o_data.UnitAmenityIDs && o_data.UnitAmenityIDs.map((option, index) => {
                      return (
                          <Form.Check
                              key={`accessiblity ${index}`}
                              id={`accessiblity ${index}`}
                              type="checkbox"
                              label={features && features.UnitAmenities && features.UnitAmenities[option]}
                              htmlFor={`accessiblity ${index}`}
      
                              onChange={() => featuresChangeHandler('UnitAmenityIDs', option)}
                              checked={activeFilters.UnitAmenityIDs.includes(option)}
                          />
                      );
                    })}
                  </Row>
                </Row>
              ) : null }


              {/* Unit Accessibilities */}
              { (o_data.UnitAccessibilityIDs && o_data.UnitAccessibilityIDs.length) ? (
                <Row className="m-0 p-0 mb-3">
                  <Row  className="m-0 p-0">
                      <Col  className="m-0 mb-2 p-0 px-2 d-flex flex-row justify-content-between align-items-center">
                          <h6 className="m-0 p-0 fw-normal">Unit Accessibilities</h6>
                          <Button 
                            className="m-0 p-0 bg-transparent px-2 border-0 rounded" 
                            disabled={!(activeFilters.UnitAccessibilityIDs && activeFilters.UnitAccessibilityIDs.length)} 
                            onClick={() => clearFilterHandler('UnitAccessibilityIDs', [])}
                          >
                              <p className="m-0 p-0 " style={{fontSize: "0.85rem"}}><i className="bi bi-x"></i> Clear</p>
                          </Button>
                      </Col>
                  </Row>
                  <Row  className="m-0 p-0 px-1">
                    {o_data.UnitAccessibilityIDs && o_data.UnitAccessibilityIDs.map((option, index) => {
                      return (
                          <Form.Check
                              key={`accessiblity ${index}`}
                              id={`accessiblity ${index}`}
                              type="checkbox"
                              label={features && features.UnitAccessibilities && features.UnitAccessibilities[option]}
                              htmlFor={`accessiblity ${index}`}
      
                              onChange={() => featuresChangeHandler('UnitAccessibilityIDs', option)}
                              checked={activeFilters.UnitAccessibilityIDs.includes(option)}
                          />
                      );
                    })}
                  </Row>
                </Row>
              ) : null }

            </>
          )}
          
        </>
      )
    }

    return (
      <>
        <Backdrop 
          show={showFilters}
          close={closeFilters}
        />

        { howManyFiltersActive &&  (
          <Filters 
            show={showFilters}
            close={closeFilters}
            resetFilters={resetFilters}
            howManyFiltersActive={howManyFiltersActive}
          >
            { !isLocation(pathname, LOCATION.QUERIES) && (
            <Alphabetically 
              clearFilterHandler={clearFilterHandler}
              activeFilters={activeFilters}
              changeHandler={changeHandler} 
            />
            )}

            
            <FilterRow 
              clearFilter={() => {
                clearFilterHandler('MinPrice');
                clearFilterHandler('MaxPrice')
              }}
              title={'Price'}
              value={ activeFilters.MinPrice || activeFilters.MaxPrice }
            >
              <Col className="m-0 p-0 pe-2">
                <Form.Control
                    type="number"
                    placeholder="Min"
                    className="rounded border-0 shadow-sm fs-6"
                    style={{height: 40}}
                    value={ activeFilters.MinPrice }
                    onChange={ (e) => changeHandler(e.target.value, 'MinPrice') }
                    min={0}
                />
              </Col>
              <Col className="m-0 p-0">
                <Form.Control
                    type="number"
                    placeholder="Max"
                    className="rounded border-0 shadow-sm fs-6"
                    style={{height: 40}}
                    value={ activeFilters.MaxPrice }
                    onChange={ (e) => changeHandler(e.target.value, 'MaxPrice') }
                    min={0}
                />
              </Col>
            </FilterRow>
           

            {/* { !isLocation(pathname, LOCATION.QUERIES) && ( */}
              <FilterRow 
                clearFilter={() => {
                  clearFilterHandler('MinBedrooms');
                  clearFilterHandler('MaxBedrooms')
                }}
                title={'Bedrooms'}
                value={ activeFilters.MinBedrooms || activeFilters.MaxBedrooms }
              >
                <Col className="m-0 p-0 pe-2">
                  <Form.Control
                      type="number"
                      placeholder="Min"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MinBedrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MinBedrooms') }
                      min={0}
                  />
                </Col>
                <Col className="m-0 p-0">
                  <Form.Control
                      type="number"
                      placeholder="Max"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MaxBedrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MaxBedrooms') }
                      min={0}
                  />
                </Col>
              </FilterRow>
            {/* )} */}

            {/* { !isLocation(pathname, LOCATION.QUERIES) && ( */}
              <FilterRow 
                clearFilter={() => {
                  clearFilterHandler('MinBathrooms');
                  clearFilterHandler('MaxBathrooms')
                }}
                title={'Bathrooms'}
                value={ activeFilters.MinBathrooms || activeFilters.MaxBathrooms }
              >
                <Col className="m-0 p-0 pe-2">
                  <Form.Control
                      type="number"
                      placeholder="Min"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MinBathrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MinBathrooms') }
                      min={0}
                  />
                </Col>
                <Col className="m-0 p-0">
                  <Form.Control
                      type="number"
                      placeholder="Max"
                      className="rounded border-0 shadow-sm fs-6"
                      style={{height: 40}}
                      value={ activeFilters.MaxBathrooms }
                      onChange={ (e) => changeHandler(e.target.value, 'MaxBathrooms') }
                      min={0}
                  />
                </Col>
              </FilterRow>
            {/* )} */}


            { isLocation(pathname, LOCATION.UNITS) && filterFields(filtersRenderData.Units, LOCATION.UNITS) }

            { isLocation(pathname, LOCATION.BUILDINGS) && filterFields(filtersRenderData.Buildings, LOCATION.BUILDINGS) }

            { isLocation(pathname, LOCATION.QUERIES) && filterFields(filtersRenderData.Queries, LOCATION.QUERIES) }

          </Filters>

          )
        }

        <Row className="m-0 p-0 pt-3 montserrat justify-content-center justify-content-sm-between">
          <Col className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-sm-start" xs={"auto"}>
            <Nav className="d-flex align-items-center">
          
              <NavLink
                to={`${url}/units`}
                className={`text-dark wNav`}
                activeClassName="activeNav"
                onClick={resetFilters}
              >
                Units
              </NavLink>
            
              <hr className="verticalDivider"></hr>
    
              <NavLink
                to={`${url}/buildings`}
                className="text-dark wNav"
                activeClassName="activeNav"
                onClick={resetFilters}
              >
                Buildings
              </NavLink>

              <hr className="verticalDivider"></hr>

              <NavLink
                to={`${url}/queries`}
                className="text-dark wNav"
                activeClassName="activeNav"
                onClick={resetFilters}
              >
                Queries
              </NavLink>

              <hr className="d-none d-sm-block verticalDivider"></hr>

            </Nav>
          </Col>

          { howManyFiltersActive && (
          <Col className="m-0 p-0 d-flex align-items-center justify-content-end justify-content-sm-between flex-grow-1 flex-grow-sm-0" xs={"auto"}>
            <FiltersBtn 
              howManyFiltersActive={howManyFiltersActive}
              results={results}
              resetFilters={resetFilters}
              openFilters={openFilters}
              disabled={loading}
            />
          </Col>
          )}

        </Row>
      </>
    )
}
