import React from 'react';
import { Col } from 'react-bootstrap';

import { FilterRow } from '../index';


export default function Alphabetically({
    clearFilterHandler,
    activeFilters,
    changeHandler
}) {
    return (
        <FilterRow
            clearFilter={() => clearFilterHandler('Alphabetically')}
            title="Alphabetically"
            value={activeFilters.Alphabetically}
        >
                <Col xs={6} className="m-0 p-0">
                    <div onClick={() => changeHandler('asc', 'Alphabetically')} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Alphabetically === "asc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        <i className="bi bi-sort-alpha-down fs-6"></i>
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Alphabetically === "asc" ? 'text-white' : 'text-dark' }`}>From A to Z</p>
                    </div>
                </Col>

                <Col xs={6} className="m-0 p-0">
                    <div onClick={() => changeHandler('desc', 'Alphabetically')} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Alphabetically === "desc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                        <i className="bi bi-sort-alpha-up fs-6"></i>
                        <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Alphabetically === "desc" ? 'text-white' : 'text-dark' }`}>From Z to A</p>
                    </div>
                </Col>
        </FilterRow>
    )
}
