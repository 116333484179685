import React from 'react';
import { Row, Col } from 'react-bootstrap';


export default function BasicFieldsLoader({
    existingUnits=true
}){
    return (
        <Row className="p-0 m-0 mt-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            <Col className="m-0 p-0" xs={12}>
                <h3 className="fw-bolder skeleton darkerGray rounded w-25">&nbsp;</h3>
            </Col>

            { existingUnits && (
                <Col className="m-0 p-0 mb-3 w-100" xs={12}>
                    <Row className="m-0 p-0">
                        <Col className="m-0 p-0 py-sm-1" xs={12}>
                            <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
                        </Col>
                    </Row>
                </Col>
            )}

            <Col className="m-0 p-0 pe-sm-2 mb-3" >
                <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
            </Col>
            <Col className="m-0 p-0 pe-md-2 mb-3" >
                <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
            </Col>
            <Col className="m-0 p-0 pe-sm-2 pe-md-0 pe-lg-2 mb-3">
                <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
            </Col>
            <Col className="m-0 p-0 pe-sm-0 pe-md-2 mb-3">
                <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
            </Col>
            <Col className="m-0 p-0 pe-sm-2 pe-lg-0 mb-3" >
                <div className="m-0 p-0 darkerGray skeleton rounded" style={{height: 58}}></div>
            </Col>
        </Row>
    )
}
