import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

import ButtonSecondary from './Buttons/ButtonSecondary';
import {
    MANAGER_EMAIL,
    MANAGER_FULL_NAME
} from '../../constants/buildingFormConstants';
import Bar from './Bar';

const MANAGER_STATUS_FIELDS = Object.freeze({
    ACCOUNT_EXISTS: 'AccountExists',
    ERROR: 'Error',
    INVITE_SENT: 'InviteSent'
});


export default function AccountExistsStatusModal({
    openManagersModal,
    setOpenManagersModal,
    building,
    userEmail,
    managerAccountStatus,
    modalTitle="Managers Status",
    children
}) {
    return (
        <Modal show={openManagersModal} onHide={() => setOpenManagersModal(false)} fullscreen centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Bar className="me-17">
                    <Col xs={4} className="px-2 py-2 text-center fw-bold border-end border-1 border-white cursor-pointer">
                        <i className="bi bi-person-lines-fill me-2"></i>
                        Name
                    </Col>
                    <Col xs={4} className="px-2 py-2 text-center fw-bold border-end border-1 border-white">
                        <i className="bi bi-envelope-at me-2"></i>
                        Email
                    </Col>
                    <Col xs={4} className="px-2 py-2 text-center fw-bold border-end border-1 border-white">
                        <i className="bi bi-question-square me-2"></i>
                        Status
                    </Col>
                </Bar>
                    <div className="pe-2 overflow-scroll-y wissp-scroll-box" style={{height: 'calc(100vh - 240px)'}}>

                            { children }
 
                            {!children && building?.Managers?.filter( manager => manager[MANAGER_EMAIL] !== userEmail).map(( manager, index ) => {
                                    return (
                                        <Row className="m-0 border-bottom" key={index}>
                                            <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">
                                                {manager[MANAGER_FULL_NAME]?.replace('$*|*$', ' ')}
                                            </Col>
                                            <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">
                                                {manager[MANAGER_EMAIL]}
                                            </Col>
                                            <Col className="px-2 py-2 text-center border-end border-1 d-flex align-items-center ">

                                                { managerAccountStatus[manager[MANAGER_EMAIL]]?.[MANAGER_STATUS_FIELDS.ACCOUNT_EXISTS] === true && (
                                                    <div className="d-flex align-items-center">
                                                        <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-person-check text-success fs-5 pe-2"></i>User exists and will be authorized to edit your property</p>
                                                    </div>
                                                )}

                                                { (managerAccountStatus[manager[MANAGER_EMAIL]]?.[MANAGER_STATUS_FIELDS.ACCOUNT_EXISTS] === false) && (
                                                    <div className="d-flex align-items-center">
                                                        <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-person-fill-add text-danger fs-5 pe-2"></i>User does not exist, invitation has been sent</p>
                                                    </div>
                                                )}

                                                { !managerAccountStatus[manager[MANAGER_EMAIL]] && (
                                                    <p className="m-0 d-flex align-items-center fs-6"><i className="bi bi-question-square-fill text-secondary fs-5 pe-2"></i>Loading...</p>
                                                )}

                                            </Col>
                                        </Row>
                                )
                                
                            })}

                    </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonSecondary onClick={() => setOpenManagersModal(false)} additionalClassName="" className="rounded-2">
                    Close
                </ButtonSecondary>
            </Modal.Footer>
        </Modal>
    )
}