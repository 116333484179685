import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Nav,
  Form,
  Dropdown
} from "react-bootstrap";
import { NavLink, useRouteMatch } from "react-router-dom";

import Filters, { FilterRow } from "./Filters";
import FiltersBtn from "./Filters/FiltersBtn";
import Backdrop from "./Backdrop";
import { MANAGER, LANDLORD } from '../../constants/Shared/userTypes';
import Alphabetically from "./Filters/Categories/Alphabetically";
import { STATES } from '../../constants/Shared/states';

import useWindowSize from '../../hooks/useWindowSize'
import { GRID_BREAKPOINTS } from "../../hooks/useWindowSize";


export default function Navigation({
  results,
  persons = [],
  activeFilters,
  changeHandler,
  resetFilters,
  clearFilterHandler,
  howManyFiltersActive,
  userType,
  hasProperties,
  loading,
  loadingTickets,
  activePage,
  changeMultipleFilters
}) {
  const [showFilters, setShowFilters] = useState(false);
  const isLandlord = userType === LANDLORD;
  let { url } = useRouteMatch();

  const openFilters = () => {
    setShowFilters(true);
  }

  const closeFilters = () => {
    setShowFilters(false);
  }
  const navigationRoutes = [
    {
      access: [LANDLORD, MANAGER],
      path: 'map',
      title: 'Your Properties'
    },
    
    {
      access: [LANDLORD, MANAGER],
      path: 'vacancy',
      title: 'Open Vacancies'
    },
    {
      access: [LANDLORD, MANAGER],
      path: 'managers',
      title: 'Your Managers'
    },
  ]
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (!size.width) return;
    if (size.width >= GRID_BREAKPOINTS.MD) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  return (
    <>
      <Backdrop
        show={showFilters}
        close={closeFilters}
      />

      {howManyFiltersActive && (
        <Filters
          show={showFilters}
          close={closeFilters}
          resetFilters={resetFilters}
          howManyFiltersActive={howManyFiltersActive}
        >
          {['managers'].includes(activePage) && (
            <FilterRow
                clearFilter={() => changeHandler("managers", "ManagersPageStructure")}
                title="Sort By"
                value={activeFilters.ManagersPageStructure}
            >
                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeHandler("managers", "ManagersPageStructure")} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.ManagersPageStructure === "managers" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-down pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.ManagersPageStructure === "managers" ? 'text-white' : 'text-dark' }`}>Managers</p>
                        </div>
                    </Col>

                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeHandler("properties", "ManagersPageStructure")} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.ManagersPageStructure === "properties" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-up pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.ManagersPageStructure === "properties" ? 'text-white' : 'text-dark' }`}>Properties</p>
                        </div>
                    </Col>
            </FilterRow>
          )}
          
          {['map', 'list', 'vacancy', 'managers'].includes(activePage) && (
            <FilterRow
              clearFilter={() => clearFilterHandler('Alphabetically')}
              title="Alphabetically"
              value={activeFilters.Alphabetically}
            >
              <Col xs={6} className="m-0 p-0">
                  <div onClick={() => changeMultipleFilters({TotalVacancies: '', TotalUnits: '', Alphabetically: 'asc'})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Alphabetically === "asc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                      <i className="bi bi-sort-alpha-down fs-6"></i>
                      <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Alphabetically === "asc" ? 'text-white' : 'text-dark' }`}>From A to Z</p>
                  </div>
              </Col>

              <Col xs={6} className="m-0 p-0">
                  <div onClick={() => changeMultipleFilters({TotalVacancies: '', TotalUnits: '', Alphabetically: 'desc'})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.Alphabetically === "desc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                      <i className="bi bi-sort-alpha-up fs-6"></i>
                      <p className={`m-0 p-0 ps-3 fs-6 ${ activeFilters.Alphabetically === "desc" ? 'text-white' : 'text-dark' }`}>From Z to A</p>
                  </div>
              </Col>
            </FilterRow>
          )}
          {['map', 'list'].includes(activePage) && (
            <FilterRow
              clearFilter={() => clearFilterHandler('Person')}
              title={isLandlord ? "Property Manager" : "Landlord"}
              value={activeFilters.Person}
            >
              <Form.Select
                size="lg"
                aria-label={isLandlord ? "Select Property Manager" : "Select Landlord"}
                className="rounded border-0 customSelect shadow-sm fs-6"
                value={activeFilters.Person}
                onChange={(e) => changeHandler(e.target.value, 'Person')}
              >
                <option value='' defaultChecked></option>
                {persons && persons.filter( person => person.FullName !== 'self').map((person,i) => (
                  <option key={i} value={person.Email}>{person.FullName?.replace('$*|*$', ' ')}</option>
                ))}
              </Form.Select>
            </FilterRow>
          )}

          {['map', 'list', 'vacancy'].includes(activePage) && (
            <FilterRow
              clearFilter={() => clearFilterHandler('City')}
              title={'City'}
              value={activeFilters.City}
            >
              <Form.Control
                type="text"
                placeholder=""
                className="rounded border-0 shadow-sm fs-6"
                style={{ height: 46 }}
                value={activeFilters.City}
                onChange={(e) => changeHandler(e.target.value, 'City')}
              />
            </FilterRow>
          )}
          { ['map', 'list', 'vacancy'].includes(activePage) && (
            <FilterRow
              clearFilter={() => clearFilterHandler('State')}
              title={'State'}
              value={activeFilters.State}
            >
              <Form.Select
                size="lg"
                aria-label="Select state"
                className="rounded border-0 customSelect shadow-sm fs-6"
                value={activeFilters.State}
                onChange={(e) => changeHandler(e.target.value, 'State')}
              >
                <option value='' defaultChecked></option>
                {STATES.split(",").map((state,i) => {
                  return (
                    <option key={i} value={state}>
                      {state}
                    </option>
                  );
                })}
              </Form.Select>
            </FilterRow>
          )}
          {['map', 'list'].includes(activePage) && (
            <FilterRow
              clearFilter={() => clearFilterHandler('Requests')}
              title={'Requests'}
              value={activeFilters.Requests}
            >
              <Form.Select
                size="lg"
                aria-label="Select requests status"
                className="rounded border-0 customSelect shadow-sm text-capitalize fs-6"
                value={activeFilters.Requests}
                onChange={(e) => changeHandler(e.target.value, 'Requests')}
              >
                <option value='' defaultChecked>All</option>
                {['opened', 'closed'].map((state,i) => {
                  return (
                    <option key={i} value={state} className="text-capitalize">
                      {state}
                    </option>
                  );
                })}
              </Form.Select>
            </FilterRow>
          )}

          {['vacancy'].includes(activePage) && (
            <FilterRow
                clearFilter={() => clearFilterHandler('TotalVacancies')}
                title="Total Vacancies"
                value={activeFilters.TotalVacancies}
            >
                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeMultipleFilters({TotalVacancies: 'asc', TotalUnits: '', Alphabetically: ''})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.TotalVacancies === "asc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-down pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.TotalVacancies === "asc" ? 'text-white' : 'text-dark' }`}>From Lowest</p>
                        </div>
                    </Col>

                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeMultipleFilters({TotalVacancies: 'desc', TotalUnits: '', Alphabetically: ''})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.TotalVacancies === "desc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-up pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.TotalVacancies === "desc" ? 'text-white' : 'text-dark' }`}>From Highest</p>
                        </div>
                    </Col>
            </FilterRow>
          )}

          {['vacancy'].includes(activePage) && (
            <FilterRow
                clearFilter={() => clearFilterHandler('TotalUnits')}
                title="Total Units"
                value={activeFilters.TotalUnits}
            >
                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeMultipleFilters({TotalUnits: 'asc', TotalVacancies: '', Alphabetically: ''})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.TotalUnits === "asc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-down pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.TotalUnits === "asc" ? 'text-white' : 'text-dark' }`}>From Lowest</p>
                        </div>
                    </Col>

                    <Col xs={6} className="m-0 p-0">
                        <div onClick={() => changeMultipleFilters({TotalUnits: 'desc', TotalVacancies: '', Alphabetically: ''})} className={`shadow-sm d-flex justify-content-center align-items-center m-1 p-2 ${ activeFilters.TotalUnits === "desc" ? 'bg-primary text-white' : 'bg-white text-dark'} rounded cursor-pointer`}>
                            {/* <i className="bi bi-sort-alpha-up pe-3 fs-6"></i> */}
                            <p className={`m-0 p-0 fs-6 ${ activeFilters.TotalUnits === "desc" ? 'text-white' : 'text-dark' }`}>From Highest</p>
                        </div>
                    </Col>
            </FilterRow>
          )}
        </Filters>
      )
      }

      <Row className="m-0 p-0 pt-3 montserrat justify-content-center justify-content-sm-between">
        <Col className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-sm-start" xs={"auto"}>
          {/* TODO Add Nav Component that will automatically do boilerplate like HR etc */}
          <Nav className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
            { navigationRoutes.map((route, i) => (
              route.access.includes(userType) && (
                <div className="d-flex" key={i}>
                  <NavLink
                    to={`${url}/${route.path}`}
                    className={`${isMobile?'px-2': ''} text-dark wNav ${!hasProperties || loading ? "disableLink" : ""}`}
                    activeClassName="activeNav"

                  >
                    {route.title}
                  </NavLink>
                  <hr className="d-none d-sm-block verticalDivider"></hr>
                </div>
              )
            ))}
          </Nav>
        </Col>

        {howManyFiltersActive && (
          <Col className="m-0 p-0 d-flex align-items-center justify-content-end justify-content-sm-between flex-grow-1 flex-grow-sm-0" xs={"auto"}>
            <FiltersBtn
              howManyFiltersActive={howManyFiltersActive}
              results={results}
              resetFilters={resetFilters}
              openFilters={openFilters}
              disabled={loading}
            />
          </Col>
        )}

      </Row>
    </>
  )
}
