import React from 'react'
import {
    Row,
    Col,
    Form
} from "react-bootstrap";
import { object as YupObject, string as YupString } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

import DocumentsFields from '../BuildingFormElements/DocumentsFields';
import ButtonPrimary from '../Shared/Buttons/ButtonPrimary';
import {
    MISC_AMENITIES,
    MISC_ACCESSIBILITIES
} from '../../constants/buildingFormConstants';

const schema = YupObject().shape({
    [MISC_AMENITIES]: YupString()
        .notRequired(),
    [MISC_ACCESSIBILITIES]: YupString()
        .notRequired(),
});


export default function DocumentsForm({
    saveDescriptionHandler,
    downloadedFiles,
    setFilesToDelete,
    filesToDelete,
    setFiles,
    files,
    onSubmitDocuments,
    sectionFocus,
    setSectionFocus,
    isFilesToUpload
}) {
    const formOptions = { resolver: yupResolver(schema), mode: "all" };
    const { handleSubmit } = useForm(formOptions);

    return (
        <Form
            onSubmit={handleSubmit(onSubmitDocuments)}
            className={sectionFocus && sectionFocus !== "Documents" ? "d-blurred prev-blur m-0 p-0" : "prev-blur m-0 p-0"}
            onMouseEnter={() => { setSectionFocus("Documents") }}
            onMouseLeave={() => { setSectionFocus(null) }}
        >   
            <DocumentsFields 
                files={files}
                setFiles={setFiles}
                filesToDelete={filesToDelete}
                setFilesToDelete={setFilesToDelete}
                downloadedFiles={downloadedFiles}
                isUpdate={true}
                saveDescriptionHandler={saveDescriptionHandler}
            /> 

            <Row className="mb-3">
                <Col className="text-center">
                    <ButtonPrimary 
                        title="Update Property Documents"
                        disabled={!isFilesToUpload && !filesToDelete.length}
                    />
                </Col>
            </Row>
        </Form>
    )
}
