import React, { useRef, useEffect } from 'react';

export default React.memo(function StreetView({
    position,
    pov = { heading: 180, pitch: 0 },
    streetViewAvailable,
    setMapPov,
    // mapData,
    setMapPos,
    // mapData,
    mapData,
    mapPov
}) {
    const ref = useRef();
    
    useEffect(() => {
        let newPov;
        let newZoom;
        if (mapPov) {
            newZoom = mapPov.Zoom ? mapPov.Zoom : 1
            newPov = mapPov ? {
                heading: mapPov.Heading,
                pitch: mapPov.Pitch,
            } : {
                heading: 180,
                pitch: 0
            }
        }
     
        if (streetViewAvailable) {
            const map = new window.google.maps.StreetViewPanorama(ref.current, {
                position,
                pov: newPov,
                zoom: newZoom
            })
            
            if (setMapPov && setMapPos) {
              
                var povChange = map.addListener("pov_changed", () => {
                   
                    const newPov = map.getPov();
                 
                    // setMapDetails(prev=>({...prev, lat: newPos.lat(), lng: newPos.lng()}))
                    // setMapDetails(newPos.lat())
                    // const prepareData = {
                    //     ...mapDetails,
                    //     zoom: newPov.z,
                    // }
                    
                    const prepareData = {
                        ...newPov
                    }
                    
                    if (mapData?.heading !== prepareData.heading || mapData?.pitch !== prepareData.pitch || mapData?.zoom !== prepareData.zoom) {
                        setMapPov(prepareData)
                    }
                })
                var posChange = map.addListener("position_changed", () => {
                  
                    const newPos = map.getPosition();
                  
                    const prepareData = {
                        lat: newPos.lat(),
                        lng: newPos.lng()
                    }

                    if (mapData?.lat !== prepareData.lat || mapData?.lng !== prepareData.lng) {
                        setMapPos(prepareData)
                    }

                })
                return () => {
                    window.google.maps.event.removeListener(povChange)
                    window.google.maps.event.removeListener(posChange)
                }
            }
        }
    })
    
    return (
        streetViewAvailable ?
            <div ref={ref} id="street-view" className="h-100 rounded"></div>
            :
            <div className="h-100 d-flex align-items-center justify-content-center">
                <h6 className="m-0 p-0 text-center">
                    There is no Street View for this property
                </h6>
            </div>
    )


}, (prev, next) => (
    prev.position === next.position
    &&
    prev.pov === next.pov
    &&
    prev.streetViewAvailable === next.streetViewAvailable
))