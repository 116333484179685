import React, { createContext, useState, useEffect } from "react";

export const UserTypeContext = createContext();

const UserTypeContextProvider = (props) => {
  const [userType, setUserType] = useState(localStorage.getItem("userType"));

  useEffect(()=> {
    setUserType(localStorage.getItem("userType"));

    return () => {
      setUserType("");
    }
  }, [])


  return (
    <UserTypeContext.Provider
      value={{
        userType,
        setUserType,
      }}
    >
      {props.children}
    </UserTypeContext.Provider>
  );
};

export default UserTypeContextProvider;