import Swal from 'sweetalert2';

export default async function DecisionModal({
    title,
    message,
    confirmButtonText,
    confirmButton,
    cancelButton
}) {
    return await Swal.fire({
        html: `
        <div class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="montserrat fw-bold mb-3">${title || "Are you sure?"}</h5>
            <p class="m-0 montserrat fs-6">${message || "Once deleted, you will not be able to recover this data!"}</p>
        </div>
        `,
        confirmButtonText: confirmButtonText || 'Delete',
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
            confirmButton: confirmButton || 'btn btn-danger text-light me-2',
            cancelButton: cancelButton || 'btn btn-secondary '
        },
    })
}