import React from 'react';
import {Row, Col} from 'react-bootstrap';

import Features from '../Shared/Features';
import TextAreaFloating from '../Shared/Inputs/TextAreaFloating';


export default function FeaturesForm ({
    features,
    amenities,
    setAmenities,
    accessibilities,
    setAccessibilities,
    f_register,
    s_registerNameMiscAmenities,
    s_registerNameMiscAccessibilities
}) {
    return (
        <Row className="m-0 p-0">
            <Col className="p-0 m-0" xs={12}>
                <h3 className="p-0 fw-bolder">Amenities</h3>
                <Features
                    dataset={features && features.Amenities}
                    changeHandler={setAmenities}
                    values={amenities}
                />
            </Col>
            <Col className="m-0 p-0 mt-1" xs={12}>
                <TextAreaFloating 
                    placeholder="Additional Amenities..."
                    label="Additional Amenities"
                    register={f_register(s_registerNameMiscAmenities)}
                />
            </Col>
            <Col className="p-0 m-0 mt-3" xs={12}>
                <h3 className="p-0 fw-bolder">Accessibilities</h3>
                <Features
                    dataset={features && features.Accessibilities}
                    changeHandler={setAccessibilities}
                    values={accessibilities}
                />
            </Col>
            <Col className="p-0 m-0 mt-1" xs={12}>
                <TextAreaFloating 
                        placeholder="Additional Accessibilities..."
                        label="Additional Accessibilities"
                        register={f_register(s_registerNameMiscAccessibilities)}
                    />
            </Col>
        </Row>
    )
}
