import React, { createContext, useCallback, useState, useContext } from "react";
import LogoLoader from "../components/Shared/LogoLoader";

import { UserTypeContext } from "./UserTypeContext";
import { signinRequest } from '../services/user';
import { useDispatch } from "react-redux";
import { favoriteActions }  from "../store/FavoriteSlice";
import ErrorPopup from "../components/Shared/InfoPopups/ErrorPopup";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const dispatch = useDispatch();

  const { setUserType } = useContext(UserTypeContext);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [loading, setLoading] = useState(false);
  const [initials, setInitials] = useState(localStorage.getItem("initials") || null);

  const login = async (credentials) => {
    const { email, password } = credentials;

    setLoading(true);

    const attributes = {
      username: email,
      password: password,
    }

    const response = await signinRequest(attributes);
    const {success, data} = response;

    if(success) {
      const { access_token, expires_in, usertype, initials } = data;

      let timeStamp = Date.now();
      let tokenExpTime = timeStamp + (expires_in * 1000);

      usertype && setUserType(usertype);
      localStorage.setItem("token", access_token);
      localStorage.setItem("userType", usertype);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("tokenExpTime", tokenExpTime);
      localStorage.setItem("initials", initials);

      setToken(access_token);
      setInitials(initials);
    } else {
      ErrorPopup("Invalid Login Or Password");
    }
    setLoading(false);
  };

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpTime");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("initials");
    setInitials(null);
    setToken("");;
    setLoading(false);
    dispatch( favoriteActions.clearFavorite() );
  }, [dispatch]);

  if (loading) return <LogoLoader />;

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        token,
        initials
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;